import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DesktopDatePicker, DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { useTranslation } from 'react-i18next';
import isToday from 'dayjs/plugin/isToday';
// eslint-disable-next-line no-restricted-imports
import { DateTimeValidationError } from '@mui/x-date-pickers/models/validation';

dayjs.extend(isToday);

type InputDatePickerProps = {
	value: string;
	onChange: (value: dayjs.Dayjs | null) => void;
	labelDate: string;
	labelTime: string;
	minDate?: string;
	onError?: (error: DateTimeValidationError, value: dayjs.Dayjs | null) => void;
};

const InputDatePicker = ({ value, onChange, labelDate, labelTime, minDate, onError }: InputDatePickerProps) => {
	const { i18n, t } = useTranslation(['reservation']);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
			<DesktopDatePicker
				slotProps={{ textField: { size: 'small' } }}
				format={t('common:dateFormat')}
				minDate={dayjs(minDate)}
				value={dayjs(value)}
				label={labelDate}
				onChange={onChange}
				sx={{ width: '100%' }}
				onError={onError}
			/>
			<DesktopTimePicker
				slotProps={{ textField: { size: 'small' } }}
				value={dayjs(value)}
				onChange={onChange}
				label={labelTime}
				sx={{ width: '100%' }}
				onError={onError}
				minTime={dayjs(value).isToday() ? dayjs(minDate) : undefined}
			/>
		</LocalizationProvider>
	);
};

export { InputDatePicker };

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Stack from '@mui/material/Stack';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import IconEditOutlined from '@tricentis/aura/components/IconEditOutlined.js';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import IconCalendarOutlined from '@tricentis/aura/components/IconCalendarOutlined.js';
import { DedicatedIpsReleaseDateModal } from './dedicated-ips-release-date-modal';
import { COST_IN_CLOUD_CREDITS_PER_DAY_PER_IP } from '../utils';
import { CloudZoneRead, useGetV4SubscriptionQuery, useGetV4ZonesQuery } from '@neoload/api';

export const DedicatedIpsReleaseDateInfo = ({ selectedZoneId }: { selectedZoneId?: string }) => {
	const { t, i18n } = useTranslation(['zone']);
	const [open, setOpen] = useState(false);
	const { data: subscriptionData, isLoading: isSubscriptionLoading } = useGetV4SubscriptionQuery({
		fields: ['cloudCredits'],
	});
	const { data: zonesData, isLoading: isZonesLoading, error: zonesError } = useGetV4ZonesQuery({ type: 'CLOUD' });
	const isLoading = isSubscriptionLoading || isZonesLoading;

	const cloudZones = useMemo(() => zonesData?.items.filter((zone) => zone.type === 'CLOUD') ?? [], [zonesData]);
	const cloudCredits = subscriptionData?.cloudCredits;
	const expirationDate = getExpirationDate(cloudZones);
	const totalCostInCloudCredits = useMemo(
		() => computeTotalCostInCloudCredits(cloudZones, expirationDate),
		[cloudZones, expirationDate],
	);

	return (
		// In some component tests i18n may be undefined, so we need to have a fallback to 'en'.
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n?.language ?? 'en'}>
			<Stack direction='row' alignItems='center'>
				<Tooltip
					title={
						selectedZoneId
							? t('cloudZone.dedicatedIps.releaseDateInfo.totalCostTooltipWithDescription', {
									totalCostInCloudCredits: totalCostInCloudCredits ?? '??',
									costInCloudCreditsPerDayPerIp: COST_IN_CLOUD_CREDITS_PER_DAY_PER_IP,
								})
							: t('cloudZone.dedicatedIps.releaseDateInfo.totalCostTooltip', {
									totalCostInCloudCredits: totalCostInCloudCredits ?? '??',
									costInCloudCreditsPerDayPerIp: COST_IN_CLOUD_CREDITS_PER_DAY_PER_IP,
								})
					}
					arrow={true}
				>
					<span>
						{selectedZoneId ? (
							<IconButton>
								<IconCalendarOutlined sx={{ fontSize: '20px' }} />
							</IconButton>
						) : (
							<IconButton disabled>
								<InfoOutlined sx={{ fontSize: '18px' }} />
							</IconButton>
						)}
					</span>
				</Tooltip>
				<Box>
					<Typography aria-label='release-date-info'>
						{!selectedZoneId && (
							<Box
								sx={{
									color: (theme) => theme.palette.secondary.main,
								}}
								component='span'
							>
								{t('cloudZone.dedicatedIps.releaseDateInfo.label') + ': '}
							</Box>
						)}
						{isLoading && <CircularProgress size={16} />}
						{!expirationDate && 'N/A'}
						{!!expirationDate && (
							<Tooltip title={dayjs(expirationDate).format('LLL')} arrow={true}>
								<span>{dayjs(expirationDate).format('L')}</span>
							</Tooltip>
						)}
					</Typography>
				</Box>
				<Tooltip title={t('cloudZone.dedicatedIps.releaseDateInfo.editTooltip')}>
					<span>
						<IconButton
							disabled={
								!expirationDate ||
								!cloudCredits ||
								zonesError !== undefined ||
								zonesData?.items.some(
									(zone): zone is CloudZoneRead =>
										'dedicatedIpsDetails' in zone && zone.dedicatedIpsDetails?.status !== 'READY',
								)
							}
							onClick={() => setOpen(!open)}
							aria-label={t('cloudZone.dedicatedIps.releaseDateInfo.editTooltip')}
						>
							<IconEditOutlined />
						</IconButton>
					</span>
				</Tooltip>
			</Stack>
			{expirationDate && cloudCredits && (
				<DedicatedIpsReleaseDateModal
					open={open}
					doClose={() => setOpen(false)}
					expirationDate={expirationDate}
					cloudCredits={cloudCredits}
				/>
			)}
		</LocalizationProvider>
	);
};

const getExpirationDate = (cloudZones: CloudZoneRead[]) => cloudZones.at(0)?.dedicatedIpsDetails?.expirationDate;
const computeTotalCostInCloudCredits = (
	cloudZones: CloudZoneRead[],
	expirationDate: string | undefined,
): number | null => {
	if (!expirationDate) {
		return null;
	}
	const totalReservedIpsCount = cloudZones
		.map(({ dedicatedIpsDetails }) => dedicatedIpsDetails)
		.filter((dedicatedIpsDetails) => !!dedicatedIpsDetails)
		.map(({ reservedIpsCount }) => reservedIpsCount)
		.reduce((previous, current) => previous + current, 0);

	const days = dayjs(expirationDate).diff(dayjs(), 'days') + 1;

	const totalCostInCloudCredits = totalReservedIpsCount * days * COST_IN_CLOUD_CREDITS_PER_DAY_PER_IP;
	return Math.round(totalCostInCloudCredits * 100) / 100;
};

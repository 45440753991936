import { useState } from 'react';
import { CreateTestModal, ModalTestExecution, ScheduleTestModal, TestsDataGrid } from '@neoload/ui';
import { Test, useGetV4TestsQuery } from '@neoload/api';
import { useCurrentWorkspace, useUrlSearchParams } from '@neoload/hooks';

const TestsPage = () => {
	const [{ createTest: createTestParameter }, setUrlSearchParams] = useUrlSearchParams('createTest');
	const [openTestExecutionModal, setOpenTestExecutionModal] = useState({ open: false, testId: '' });
	const [openScheduleTestModal, setOpenScheduleTestModal] = useState({ open: false, testId: '' });
	const [currentWorkspace, isCurrentWorkspaceSelected] = useCurrentWorkspace();

	const { data: { total } = { total: undefined } } = useGetV4TestsQuery(
		{
			workspaceId: currentWorkspace.id,
			pageSize: 1,
			pageNumber: 0,
		},
		{ skip: !isCurrentWorkspaceSelected },
	);

	const openCreateModal = () => {
		setUrlSearchParams({ createTest: 'true' });
	};
	const closeCreateModal = () => {
		setUrlSearchParams({ createTest: null });
	};

	const runTestCallback = (test: Test) => {
		setOpenTestExecutionModal({ open: true, testId: test.id });
	};
	const scheduleTestCallback = (test: Test) => {
		setOpenScheduleTestModal({ open: true, testId: test.id });
	};

	return (
		<>
			<TestsDataGrid runTest={runTestCallback} scheduleTest={scheduleTestCallback} openCreateModal={openCreateModal} />
			<CreateTestModal handleClose={closeCreateModal} open={createTestParameter === 'true'} totalTests={total} />
			<ModalTestExecution
				open={openTestExecutionModal.open}
				close={() => setOpenTestExecutionModal({ testId: '', open: false })}
				testId={openTestExecutionModal.testId}
			/>
			{openScheduleTestModal.open && (
				<ScheduleTestModal
					close={() => setOpenScheduleTestModal({ testId: '', open: false })}
					workspaceId={currentWorkspace.id}
					testId={openScheduleTestModal.testId}
				/>
			)}
		</>
	);
};

export { TestsPage };

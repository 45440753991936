const login = {
	email: 'Business email',
	password: 'Password',
	accounts: 'Accounts',
	forgotPassword: 'Forgot password?',
	sso: 'Sign in with SSO',
	login: 'Sign in',
	invalidCredentials: 'Invalid credentials!',
	accountSelectionTitle: 'Select an account',
	initializingAccount: 'Initializing the "{{accountName}}" account ...',
	aria: {
		togglePasswordVisibility: 'toggle password visibility',
	},
	legal: {
		text: 'Legal information',
		link: 'https://www.tricentis.com/legal-information',
	},
	privacy: {
		text: 'Privacy policy',
		link: 'https://www.tricentis.com/legal-information/privacy-policy',
	},
	tricentis: {
		text: 'www.tricentis.com',
		link: 'https://www.tricentis.com',
	},
};

export { login };

import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router';
import { TilesGrid, TilesGridCell, TileSize } from './tiles-grid';
import { RunChip, ResultStatusData } from '../data-grid/run-chip';
import { CommonRoutes } from '@neoload/utils';

type Result = {
	name: string;
	id: string;
};

type Test = {
	name: string;
	id: string;
};

export type SummaryTilesGridProps = {
	result: Result;
	test?: Test;
	status: ResultStatusData;
	startDate: string;
	endDate: string;
	duration: string;
	project: string;
	scenario: string;
};

const getTileSizeX7 = (columnRatio: number, rowRatio: number, index: number): TileSize => {
	if (columnRatio === rowRatio) {
		return index === 0 ? 'fullWidth' : 'halfWidth';
	}
	if (columnRatio < rowRatio) {
		return 'fullWidth';
	}
	if (index === 0) {
		return 'fullWidth';
	}
	return 'halfWidth';
};

const getTileSizeX8 = (columnRatio: number, rowRatio: number, index: number): TileSize => {
	if (columnRatio === rowRatio) {
		return 'halfWidth';
	}
	if (columnRatio < rowRatio) {
		return 'fullWidth';
	}
	if (index <= 1) {
		return 'halfWidth';
	}
	return 'halfWidth';
};
export const SummaryTilesGrid = ({
	result,
	test,
	status,
	startDate,
	endDate,
	duration,
	project,
	scenario,
}: SummaryTilesGridProps) => {
	const { t } = useTranslation(['common']);
	const cells: TilesGridCell[] = [];
	cells.push(
		{
			uid: '1',
			renderValue: () => (
				<Link component={RouterLink} to={CommonRoutes.results.overview(result.id)}>
					{result.name}
				</Link>
			),
			label: t('summary.result'),
			highlight: true,
		},
		{
			uid: '2',
			renderValue: () => <RunChip resultStatusData={status} withLabel={true} size='small' />,
			label: t('summary.status'),
		},
		{
			uid: '3',
			value: startDate,
			label: t('summary.startDate'),
		},
		{
			uid: '4',
			value: endDate,
			label: t('summary.endDate'),
		},
		{
			uid: '5',
			value: duration,
			label: t('summary.duration'),
		}
	);
	if (test) {
		cells.push({
			uid: '6',
			renderValue: () => (
				<Link component={RouterLink} to={CommonRoutes.tests.test(test.id)}>
					{test.name}
				</Link>
			),
			label: t('summary.test'),
			highlight: true,
		});
	}
	cells.push(
		{
			uid: '7',
			value: project,
			label: t('summary.project'),
		},
		{
			uid: '8',
			value: scenario,
			label: t('summary.scenario'),
		}
	);
	return <TilesGrid cells={cells} getTileSize={cells.length === 8 ? getTileSizeX8 : getTileSizeX7} />;
};

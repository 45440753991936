import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useRef, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import Typography from '@mui/material/Typography';
import { ConfigurationFormData } from './../../types.d';
import { configurationErrorToString } from './../../configuration-helpers';
import { InputFile } from '../input-file';
import { useSetSnackbars } from '@neoload/hooks';
import { usePostV4CheckoutsMutation } from '@neoload/api';

type ButtonSelectionGitProps = {
	isInformationLoading: boolean;
	openGitModal: () => void;
};

const ButtonSelectionGit = ({ isInformationLoading, openGitModal }: ButtonSelectionGitProps) => {
	const { t } = useTranslation(['test']);
	const [cloneRepository] = usePostV4CheckoutsMutation();
	const { getValues } = useFormContext<ConfigurationFormData>();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const fileUploadInputRef = useRef<HTMLInputElement>(null);
	const menuOpen = Boolean(anchorEl);
	const { showError, showInfo } = useSetSnackbars();
	const reference = getValues('project.reference');
	const repositoryName = getValues('project.repositoryName');
	const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorEl(null);
	};

	const openGitModalAndCloseMenu = () => {
		openGitModal();
		setAnchorEl(null);
	};

	const cloneQuickly = () => {
		const scmRepositoryId = getValues('project.repositoryId');
		if (scmRepositoryId === undefined || reference === undefined) {
			showError({ text: t('common:errors.otherError.content') });
			return;
		}

		cloneRepository({
			checkoutInput: {
				reference,
				testId: getValues('test.id'),
				scmRepositoryId,
			},
		})
			.unwrap()
			.then(() => {
				showInfo({
					text: t('configuration.snackbar.uploading', { name: `${repositoryName}/${reference}` }),
					id: 'upload',
				});
			})
			.catch((error) => {
				const message = configurationErrorToString(error.data, t);
				showError({
					text: t('configuration.snackbar.uploadFail', {
						name: `${repositoryName}/${reference}`,
						error: message,
					}),
					id: 'upload',
				});
			})
			.finally(() => {
				closeMenu();
			});
	};

	return (
		<>
			<ButtonGroup sx={{ flexShrink: 0 }}>
				<Tooltip arrow title={`${repositoryName}/${reference}`}>
					<Button
						size='small'
						variant='outlined'
						disabled={isInformationLoading}
						onClick={() => cloneQuickly()}
						data-trackingid='git-quick-clone-button'
					>
						{t('configuration.project.git.button.clone')}
					</Button>
				</Tooltip>
				<Button size='small' onClick={openMenu} disabled={isInformationLoading} aria-label={t('expand')}>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Menu
				open={menuOpen}
				anchorEl={anchorEl}
				onClose={closeMenu}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuItem disabled>
					<Typography variant='subtitle2'>{t('configuration.project.git.gitRepository')}</Typography>
				</MenuItem>
				<MenuItem
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
					}}
					onClick={() => cloneQuickly()}
					data-trackingid='git-clone-button'
				>
					{t('configuration.project.git.button.clone')}
					<Typography color='secondary' variant='caption'>
						{repositoryName}/{reference}
					</Typography>
				</MenuItem>
				<MenuItem
					sx={{ display: 'flex' }}
					onClick={openGitModalAndCloseMenu}
					data-trackingid='git-change-project-button'
				>
					{t('configuration.project.git.button.change')}
				</MenuItem>
				<MenuItem disabled>
					<Typography variant='subtitle2'>{t('configuration.project.sourceLocal')}</Typography>
				</MenuItem>
				<MenuItem
					onClick={() => {
						fileUploadInputRef.current?.click();
					}}
					data-trackingid='git-to-file-project-button'
				>
					{t('configuration.project.file.button')}
					<InputFile ref={fileUploadInputRef} onChange={closeMenu} />
				</MenuItem>
			</Menu>
		</>
	);
};

export { ButtonSelectionGit };

import Stack from '@mui/material/Stack';
import SectionTitle from '@tricentis/aura/components/SectionTitle.js';
import Button from '@mui/material/Button';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileForm, ChangePasswordModal } from '@neoload/ui';

const ProfilePage = () => {
	const { t } = useTranslation(['menu']);
	const [changePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false);
	const closeModal = useCallback(() => setChangePasswordModalOpen(false), [setChangePasswordModalOpen]);
	return (
		<>
			<Stack spacing={0}>
				<Stack p={1} direction='row' justifyContent='space-between'>
					<SectionTitle title={t('profileDetails')} />
					<Stack justifyContent='center' sx={{ paddingRight: 2 }}>
						<Button variant='outlined' size='small' onClick={() => setChangePasswordModalOpen(true)}>
							{t('profile:changePassword.changePassword')}
						</Button>
					</Stack>
				</Stack>
				<Stack p={4}>
					<ProfileForm />
				</Stack>
			</Stack>
			{changePasswordModalOpen && <ChangePasswordModal open={true} onClose={closeModal} />}
		</>
	);
};
export { ProfilePage };

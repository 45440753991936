import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Grid2 from '@mui/material/Unstable_Grid2';

type ProjectConfigurationProps = {
	children: ReactNode;
	name: string | ReactNode;
	disabled?: boolean;
	actions?: ReactNode;
};

const TestConfiguration = ({ children, disabled, name, actions }: ProjectConfigurationProps) => {
	const theme = useTheme();
	const { t } = useTranslation(['test']);

	return (
		<Stack
			direction='column'
			sx={{
				width: '100%',
				backgroundColor: 'transparent',
				padding: 2,
			}}
		>
			<Grid2 container sx={{ marginBottom: 1 }}>
				<Grid2 xs={4}>{typeof name === 'string' ? <Typography variant='h6'>{name}</Typography> : name}</Grid2>
				<Grid2 xs={4} textAlign='center'>
					{disabled && (
						<Typography
							role='alert'
							variant='caption'
							sx={{
								color: theme.palette.text.disabled,
							}}
						>
							{t('configuration.disabledText')}
						</Typography>
					)}
				</Grid2>
				<Grid2 xs={4}>
					{actions && (
						<Stack
							direction='row'
							justifyContent='flex-end'
							alignItems='center'
							gap={2}
							aria-label='test-configuration-actions'
						>
							{actions}
						</Stack>
					)}
				</Grid2>
			</Grid2>
			<Box aria-label='test-configuration-section'>{children}</Box>
		</Stack>
	);
};

export { TestConfiguration };

import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { memo } from 'react';
import { useTheme } from '@mui/material/styles';
import { PopulationItem } from './population-item';
import { PopulationDetails } from './population-details';
import { useGetCustomPopulation } from '@neoload/hooks';

type PopulationAccordionProps = {
	name: string;
	index: number;
};

const PopulationAccordion = memo(({ name, index }: PopulationAccordionProps) => {
	const theme = useTheme();

	const population = useGetCustomPopulation(name);

	if (!population) {
		return false;
	}

	return (
		<Accordion
			disableGutters
			defaultExpanded={index === 0}
			sx={{
				backgroundColor: 'transparent',
				boxShadow: 'none',
				borderRadius: `${theme.aura.borderRadius.xs}px`,
				borderColor: theme.palette.divider,
				borderWidth: '1px',
				borderStyle: 'solid',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:before': {
					display: 'none',
				},
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<PopulationItem population={population} />
			</AccordionSummary>
			<AccordionDetails>
				<PopulationDetails population={population} />
			</AccordionDetails>
		</Accordion>
	);
});

export { PopulationAccordion };

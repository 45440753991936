import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Popper, { PopperProps } from '@mui/material/Popper';
import { useTheme } from '@mui/material';
import { Controller, useWatch } from 'react-hook-form';
import { ScenarioRadioGroup, ScenarioTypes } from './scenario-radio-group';
import { ScenarioParameters } from './scenario-parameters';
import { CustomScenarios } from './custom/custom-scenarios';
import { TestConfiguration } from '../test-configuration';
import { ConfigurationFormData } from '../types.d';
import { timeUtils } from '@neoload/utils';
import { Scenario } from '@neoload/api';

const renderPopperComponent = (props: PopperProps) => (
	<Popper {...props} style={{ maxWidth: 'fit-content' }} placement='bottom-start' />
);

const LoadScenarios = () => {
	const selectedScenario = useWatch<ConfigurationFormData, 'scenario.selected'>({ name: 'scenario.selected' });
	const scenarioType = useWatch<ConfigurationFormData, 'scenario.type'>({ name: 'scenario.type' });
	const projectScenarios = useWatch<ConfigurationFormData, 'scenario.fromProject'>({
		name: 'scenario.fromProject',
	});
	const { t } = useTranslation(['test']);
	const disabled = useWatch<ConfigurationFormData, 'configurationDisabled'>({ name: 'configurationDisabled' });

	const theme = useTheme();

	function displayVuAndDuration(option: Scenario) {
		const duration = option.duration
			? timeUtils.durationFull(option.duration)
			: t('configuration.scenarios.undetermined');
		// eslint-disable-next-line unicorn/prefer-spread
		return option.maximumVu?.toString().concat(' ', t('configuration.scenarios.vus'), ', ', duration);
	}

	return (
		<TestConfiguration name={t('configuration.scenarios.title')} disabled={disabled}>
			<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
				<ScenarioRadioGroup disabled={disabled} />
				{!disabled && scenarioType === ScenarioTypes.FromProject && projectScenarios.length > 0 && (
					<Controller
						name='scenario.selected'
						rules={{ required: true }}
						render={({ field }) => (
							<Autocomplete
								{...field}
								disableClearable
								size='small'
								onChange={(_, data) => field.onChange(data)}
								data-testid='loadScenario'
								sx={{ width: '310px', marginBottom: 2 }}
								renderInput={(params) => <TextField {...params} label={t('configuration.scenarios.label')} />}
								options={projectScenarios}
								getOptionLabel={(option) => option.name}
								getOptionDisabled={(option) => !option.maximumVu}
								isOptionEqualToValue={(option, value) => option.name === value.name}
								PopperComponent={renderPopperComponent}
								renderOption={(props, option) => (
									<li {...props}>
										<Typography
											variant='body1'
											sx={{
												marginRight: 2,
												textOverflow: 'ellipsis',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												maxWidth: '300px',
											}}
										>
											{option.name}
										</Typography>
										<Typography variant='body2' sx={{ marginLeft: 'auto', color: theme.palette.text.secondary }}>
											{option.maximumVu ? displayVuAndDuration(option) : t('configuration.scenarios.unsupported')}
										</Typography>
									</li>
								)}
							/>
						)}
					/>
				)}
				{!disabled && scenarioType === ScenarioTypes.FromProject && selectedScenario && (
					<ScenarioParameters virtualUsers={selectedScenario.maximumVu} duration={selectedScenario.duration} />
				)}
				{!disabled && scenarioType === ScenarioTypes.Custom && <CustomScenarios />}
			</Box>
		</TestConfiguration>
	);
};

export { LoadScenarios };

import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import i18n from 'i18next';
import { withLazyFetching, WithLazyFetchingProps } from './with-lazy-fetching';
import { useWidgetDataFetcher } from './hooks/use-widget-data-fetcher';
import { Spinner } from '../../../common/spinner';
import { KpiTilesGrid } from '../../../common/kpi-tiles-grid';
import { DashboardTileErrorContent } from '../tiles/dashboard-tile-error-content';
import { DashboardTileUnloadedContent } from '../tiles/dashboard-tile-unloaded-content';
import { CsvDownloadContext } from '../tiles/tile/csv-download-context';
import { TextCell } from '../../../common/table-grid';
import {
	GetV4ResultsByResultIdStatisticsApiArg,
	GetV4ResultsByResultIdStatisticsApiResponse,
	useLazyGetV4ResultsByResultIdStatisticsQuery,
	WidgetDashboardTile,
} from '@neoload/api';

type KpiFetcherProps = {
	widgetTile: WidgetDashboardTile;
} & WithLazyFetchingProps;

const f = (options: Intl.NumberFormatOptions = {}) =>
	new Intl.NumberFormat(i18n.language, { compactDisplay: 'short', ...options });
const secondUnit: Intl.NumberFormatOptions = { style: 'unit', unit: 'second', unitDisplay: 'narrow' };
const columns = [
	{ label: i18n.t('kpi.testName', { ns: 'common' }), type: 'text' },
	{ label: i18n.t('kpi.testId', { ns: 'common' }), type: 'text' },
	{ label: i18n.t('kpi.resultName', { ns: 'common' }), type: 'text' },
	{ label: i18n.t('kpi.resultId', { ns: 'common' }), type: 'text' },
	{ label: i18n.t('kpi.avgDuration', { ns: 'common' }), type: 'text' },
	{ label: i18n.t('kpi.elementsPerSec', { ns: 'common' }), type: 'text' },
	{ label: i18n.t('kpi.passed', { ns: 'common' }), type: 'text' },
	{ label: i18n.t('kpi.failed', { ns: 'common' }), type: 'text' },
];

export const InternalKpiFetcher = ({ shouldStartFetching, widgetTile }: KpiFetcherProps) => {
	const { t } = useTranslation();
	const [triggerGetResultStatistics] = useLazyGetV4ResultsByResultIdStatisticsQuery();
	const { setDataToDownload } = useContext(CsvDownloadContext);
	const [data, error, loadingState] = useWidgetDataFetcher<
		GetV4ResultsByResultIdStatisticsApiArg,
		GetV4ResultsByResultIdStatisticsApiResponse
	>(
		widgetTile,
		{
			resultId: widgetTile.resultId,
		},
		shouldStartFetching,
		triggerGetResultStatistics
	);

	useEffect(() => {
		function getTransactionValues(): TextCell[][] {
			return [
				[
					{ text: widgetTile.testName ?? '' },
					{ text: widgetTile.testId ?? '' },
					{ text: widgetTile.resultName },
					{ text: widgetTile.resultId },
					{ text: f(secondUnit).format(data?.totalTransactionDurationAverage ?? 0) },
					{ text: f().format(data?.totalTransactionCountPerSecond ?? 0) },
					{ text: f().format(data?.totalTransactionCountSuccess ?? 0) },
					{ text: f().format(data?.totalTransactionCountFailure ?? 0) },
				],
			];
		}
		function getRequestValues(): TextCell[][] {
			return [
				[
					{ text: widgetTile.testName ?? '' },
					{ text: widgetTile.testId ?? '' },
					{ text: widgetTile.resultName },
					{ text: widgetTile.resultId },
					{ text: f(secondUnit).format(data?.totalRequestDurationAverage ?? 0) },
					{ text: f().format(data?.totalRequestCountPerSecond ?? 0) },
					{ text: f().format(data?.totalRequestCountSuccess ?? 0) },
					{ text: f().format(data?.totalRequestCountFailure ?? 0) },
				],
			];
		}
		if (loadingState === 'LOADED' && widgetTile.filter.type === 'KEY_METRICS' && data) {
			if (widgetTile.filter.elementType === 'TRANSACTION') {
				const transactionValues = getTransactionValues();
				setDataToDownload({ columns, values: transactionValues });
			} else if (widgetTile.filter.elementType === 'REQUEST') {
				const requestValues = getRequestValues();
				setDataToDownload({ columns, values: requestValues });
			}
		}
	}, [loadingState, data, setDataToDownload, widgetTile]);

	if (loadingState === 'UNLOADED') {
		return <DashboardTileUnloadedContent />;
	}
	if (loadingState === 'LOADING') {
		return <Spinner />;
	}

	if (error || !data) {
		if (error) {
			console.error(
				t('widget.error.global', {
					tileId: widgetTile.id,
				}),
				error
			);
		}
		return <DashboardTileErrorContent />;
	}

	if (widgetTile.filter.type !== 'KEY_METRICS') {
		return <DashboardTileErrorContent />;
	}

	switch (widgetTile.filter.elementType) {
		case 'TRANSACTION': {
			return (
				<KpiTilesGrid
					averageDuration={f(secondUnit).format(data.totalTransactionDurationAverage)}
					countPerSecond={f().format(data.totalTransactionCountPerSecond)}
					totalPassed={f().format(data.totalTransactionCountSuccess)}
					totalFailed={f().format(data.totalTransactionCountFailure)}
				/>
			);
		}
		case 'REQUEST': {
			return (
				<KpiTilesGrid
					averageDuration={f(secondUnit).format(data.totalRequestDurationAverage)}
					countPerSecond={f().format(data.totalRequestCountPerSecond)}
					totalPassed={f().format(data.totalRequestCountSuccess)}
					totalFailed={f().format(data.totalRequestCountFailure)}
				/>
			);
		}
		default: {
			return <DashboardTileErrorContent />;
		}
	}
};

export const VisibleForTesting = InternalKpiFetcher;
export const KpiFetcher = withLazyFetching(InternalKpiFetcher);

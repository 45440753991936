import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MemberComboboxItem } from './member-combobox-item';
import { MemberComboboxPaperComponent } from './member-combobox-paper-component';
import { AddMembersFormFields } from '../workspace-add-member-modal';
import { User } from '@neoload/api';

type MemberComboboxProps = {
	availableUsers: User[];
};

const filterOptions = (options: User[], { inputValue }: { inputValue: string }) =>
	options.filter(
		(option) =>
			`${option.firstName} ${option.lastName}`.toLowerCase().includes(inputValue.toLowerCase()) ||
			option.email.toLowerCase().includes(inputValue.toLowerCase()),
	);

const MemberCombobox = ({ availableUsers }: MemberComboboxProps) => {
	const { t } = useTranslation('workspace');
	const { watch, setValue } = useFormContext<AddMembersFormFields>();
	const selectedUsers = watch('selectedUsers');

	return (
		<Autocomplete
			sx={{ width: '100%' }}
			multiple
			options={availableUsers}
			value={selectedUsers}
			disableCloseOnSelect
			isOptionEqualToValue={(option, value) => option.id === value.id}
			getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
			renderOption={(props, option, { selected }) => (
				<MemberComboboxItem props={props} selected={selected} member={option} />
			)}
			/* When passing custom props to the paperComponent it re-renders on selection and scrolls back to the top
			So passing custom props to slotProps, but there is a typing issue in Autocomplete, see https://github.com/mui/material-ui/issues/31073
			@ts-expect-error auto complete does not accept custom props*/
			PaperComponent={MemberComboboxPaperComponent}
			// @ts-expect-error auto complete does not accept custom props
			slotProps={{ paper: { availableUsers } }}
			onChange={(_event, updatedSelectedUsers) => setValue('selectedUsers', updatedSelectedUsers)}
			renderInput={(params) => <TextField {...params} autoFocus label={t('members.users')} size='small' />}
			//there is no option for empty state so using loading text to show when no users are available
			loading={availableUsers.length === 0}
			loadingText={t('members.allUsersAreMembers')}
			filterOptions={filterOptions}
		/>
	);
};

export { MemberCombobox };

import { RepositoryPage, useGetV4ScmRepositoriesQuery } from '@neoload/api';
import { GitRepoSettings, Spinner } from '@neoload/ui';

const defaultPage: RepositoryPage = {
	items: [],
	pageNumber: 0,
	pageSize: 0,
	total: 0,
};

const GitRepoPage = () => {
	const { data = defaultPage, isLoading, isFetching } = useGetV4ScmRepositoriesQuery();

	if (isLoading) {
		return <Spinner />;
	}

	return <GitRepoSettings gitRepoPage={data} isFetching={isFetching} />;
};

export { GitRepoPage };

import { GridColDef } from '@mui/x-data-grid/models';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import { ComponentPropsWithoutRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridSortItem, useGridApiRef } from '@mui/x-data-grid-pro';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { MemberManagementPanel } from './member-management-panel';
import { WorkspaceMembersDatagridEmptyState } from './workspace-members-datagrid-empty-state';
import { WorkspaceAddMemberModal } from './workspace-add-member-modal';
import { Datagrid } from '../../../common/data-grid/datagrid';
import { DEFAULT_GRID_PROPS, DEFAULT_PAGINATION_MODEL, onColumnChange } from '../../../common/datagrid';
import { ActionsCell } from '../../../common/data-grid/actions/actions-cell';
import { DatagridAction } from '../../../common/data-grid/actions/data-grid-actions';
import {
	useDeleteV4UsersByUserIdWorkspacesAndWorkspaceIdMutation,
	usePatchV4WorkspacesByWorkspaceIdMutation,
	USER_ROLE,
	Workspace,
	WorkspaceMember,
	WorkspaceMemberPage,
} from '@neoload/api';
import { useColumnsState, useCrudBatchMessage } from '@neoload/hooks';

const columnsStateKey = 'TOKENS_COLUMNS_STATE';

type WorkspacesMembersDataGridProps = {
	workspaceMembers: WorkspaceMemberPage;
	isFetching: boolean;
	workspace: Pick<Workspace, 'id' | 'enabledForAllUsers'>;
	hasAdminPermissions: boolean;
};

const WorkspaceMembersDataGrid = ({
	workspaceMembers,
	isFetching,
	workspace,
	hasAdminPermissions,
}: WorkspacesMembersDataGridProps) => {
	const { t } = useTranslation(['workspace']);
	const apiRef = useGridApiRef();
	const [paginationModel, setPaginationModel] = useState(DEFAULT_PAGINATION_MODEL);
	const { remove } = useCrudBatchMessage('workspace', 'members');
	const [removeUser] = useDeleteV4UsersByUserIdWorkspacesAndWorkspaceIdMutation();
	const [patchWorkspace, { isLoading: isPatching }] = usePatchV4WorkspacesByWorkspaceIdMutation();
	const { update: updateWorkspace } = useCrudBatchMessage('workspace');
	const [isAddMembersModalOpened, setIsAddMembersModalOpened] = useState(false);

	const enableForAllUsers = async (enabledForAllUsers: boolean) => {
		await updateWorkspace([workspace], async (workspace) =>
			patchWorkspace({ workspaceId: workspace.id, workspacePatchInput: { enabledForAllUsers } }).unwrap(),
		);
	};

	const removeMember = async (memberId: string, workspaceId: string) => {
		await remove([memberId], async (memberId) => removeUser({ userId: memberId, workspaceId }).unwrap());
	};

	const getDeleteTooltip = () =>
		t(workspace.enabledForAllUsers ? 'members.removeMembersDisabledForAllUsers' : 'members.removeUser');

	const deleteAction: DatagridAction = {
		icon: <DeleteOutlined />,
		text: getDeleteTooltip(),
		tooltip: getDeleteTooltip(),
		action: () => ({}),
		singleItem: (member: WorkspaceMember) => ({
			disabled: workspace.enabledForAllUsers,
			tooltip: getDeleteTooltip(),
			action: () => {
				void (async () => {
					await removeMember(member.id, workspace.id);
				})();
			},
		}),
	};

	const columns: GridColDef<WorkspaceMember>[] = [
		{ field: 'email', headerName: t('tabs.members.grid.email'), minWidth: 350 },
		{ field: 'firstName', headerName: t('tabs.members.grid.firstName'), minWidth: 350 },
		{ field: 'lastName', headerName: t('tabs.members.grid.lastName'), flex: 1 },
		{
			field: 'role',
			headerName: t('tabs.members.grid.role'),
			minWidth: 150,
			valueFormatter: (value) => t(USER_ROLE[value]),
		},
		{
			field: 'actions',
			type: 'actions',
			sortable: false,
			resizable: false,
			disableReorder: true,
			align: 'center',
			width: 40,
			filterable: false,
			renderCell: (params) => <ActionsCell rowData={params.row} actions={[deleteAction]} />,
		},
	];

	const defaultGridSort: GridSortItem = { field: 'email', sort: 'asc' };
	const initialState = {
		sorting: {
			sortModel: [defaultGridSort],
		},
	};

	const { updatedInitialState, updatedColumns, storeColumnState } = useColumnsState(
		columnsStateKey,
		initialState,
		columns,
		apiRef,
	);

	const componentsProps: { toolbar: ComponentPropsWithoutRef<typeof Toolbar> } = {
		toolbar: {
			displaySearchBox: true,
			hideFiltersIcon: true,
			displayColumnOptions: false,
			syncLocalStorage: {
				datagridId: 'neoloadStaticZonesDataGrid',
				isSyncEnabled: true,
			},
			title: t('tabs.members.title'),
			mainActions: [
				{
					children: (
						<MemberManagementPanel
							enabledForAllUsers={workspace.enabledForAllUsers}
							includeAllUsers={enableForAllUsers}
							isPatching={isPatching}
							openAddMembersModal={() => setIsAddMembersModalOpened(true)}
						/>
					),
				},
			],
		},
	};

	return (
		<>
			{workspaceMembers.items.length === 0 ? (
				<WorkspaceMembersDatagridEmptyState
					openAddMembersModal={() => setIsAddMembersModalOpened(true)}
					includeAllUsers={() => enableForAllUsers(true)}
				/>
			) : (
				<Datagrid
					{...DEFAULT_GRID_PROPS}
					{...onColumnChange(storeColumnState)}
					apiRef={apiRef}
					checkboxSelection={false}
					rows={workspaceMembers.items}
					loading={isFetching}
					initialState={updatedInitialState}
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					columns={updatedColumns}
					disableRowSelectionOnClick={false}
					slotProps={componentsProps}
					slots={{
						toolbar: Toolbar,
					}}
				/>
			)}
			{hasAdminPermissions && (
				<WorkspaceAddMemberModal
					opened={isAddMembersModalOpened}
					closeModal={() => setIsAddMembersModalOpened(false)}
					workspaceId={workspace.id}
					workspaceMembers={workspaceMembers}
				/>
			)}
		</>
	);
};
export { WorkspaceMembersDataGrid };

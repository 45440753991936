import { useNavigate, useParams } from 'react-router';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useEffect } from 'react';
import {
	ComponentTabPanel,
	DEFAULT_PAGINATION_MODEL,
	DeletionPoliciesPanel,
	Spinner,
	SubscriptionPanel,
	WebhooksDataGrid,
	WorkspaceHeader,
	WorkspaceMembersDataGrid,
} from '@neoload/ui';
import {
	createNeoLoadError,
	isFieldUniquenessError,
	isForbiddenError,
	WorkspaceGenericParams,
	WorkspaceRoutes,
	workspaceTabs,
} from '@neoload/utils';
import { useDocumentTitleHandler, useFeatureEnabled, useGetMe, useSetSnackbars } from '@neoload/hooks';
import {
	useDeleteV4WorkspacesByWorkspaceIdMutation,
	useGetV4WorkspacesByWorkspaceIdMembersQuery,
	useGetV4WorkspacesByWorkspaceIdQuery,
	usePatchV4WorkspacesByWorkspaceIdMutation,
	UserWorkspace,
	WorkspaceMemberPage,
	WorkspacePage,
} from '@neoload/api';

const defaultMembersPage: WorkspaceMemberPage = {
	total: 0,
	pageNumber: 0,
	pageSize: DEFAULT_PAGINATION_MODEL.pageSize,
	items: [],
};

const findAmongMyWorkspaces = (workspaces: UserWorkspace[], workspaceId: string): UserWorkspace => {
	const found = workspaces.find((w) => w.id === workspaceId);
	if (!found) {
		throw createNeoLoadError({ status: 404, data: undefined });
	}
	return found;
};

const WorkspacePage = () => {
	const { workspaceId = '' } = useParams<WorkspaceGenericParams>();
	const navigate = useNavigate();
	const { t } = useTranslation(['workspace']);
	const theme = useTheme();
	const { showInfo, showError } = useSetSnackbars();
	const { tab = 'members' } = useParams<WorkspaceGenericParams>() as WorkspaceGenericParams;
	const selectedTab: number = workspaceTabs.indexOf(tab);
	const [{ isFeatureEnabled: webhookFeatureEnabled }] = useFeatureEnabled('webhooks');
	const [{ isFeatureEnabled: isDeletionPoliciesEnabled }] = useFeatureEnabled('deletion-policies');
	const [{ isFeatureEnabled: isSubscriptionEnabled }] = useFeatureEnabled('workspace-subscription');

	const [{ hasAdminPermissions, hasTesterPermissions, workspaces }] = useGetMe();
	const [updateWorkspace] = usePatchV4WorkspacesByWorkspaceIdMutation();
	const [removeWorkspace, { isLoading: isDeleting }] = useDeleteV4WorkspacesByWorkspaceIdMutation();
	const {
		data: workspaceMembers = defaultMembersPage,
		isLoading,
		isFetching: isMembersFetching,
		error: membersError,
	} = useGetV4WorkspacesByWorkspaceIdMembersQuery({ workspaceId }, { skip: !hasAdminPermissions });
	const { data: fullWorkspace, error: resultsError } = useGetV4WorkspacesByWorkspaceIdQuery(
		{ workspaceId },
		{ skip: !hasAdminPermissions },
	);
	const workspace = hasAdminPermissions ? fullWorkspace : findAmongMyWorkspaces(workspaces, workspaceId);
	const isDeletionPoliciesTabEnabled =
		hasTesterPermissions && workspaces.some((workspace) => workspace.id === workspaceId);
	const isWebhookTabAllowed = webhookFeatureEnabled && hasAdminPermissions;

	useEffect(() => {
		if (tab === 'members' && !hasAdminPermissions) {
			navigate(WorkspaceRoutes.subscriptions(workspaceId), { replace: true });
		}
	}, [hasAdminPermissions, navigate, tab, workspaceId]);

	const deleteWorkspace = async (workspaceId: string) => {
		await removeWorkspace({ workspaceId: workspaceId })
			.unwrap()
			.then(() => {
				showInfo({ text: t('messages.deleteSuccess') });
				navigate(WorkspaceRoutes.base, { replace: true });
			})
			.catch((error) =>
				showError({ text: t(isForbiddenError(error) ? 'messages.deleteErrorForbidden' : 'messages.deleteError') }),
			);
	};

	const editWorkspaceName = (name: string) => {
		updateWorkspace({
			workspaceId,
			workspacePatchInput: { name },
		})
			.unwrap()
			.catch((error) =>
				showError({
					text: t(isFieldUniquenessError(error) ? 'messages.updateNameAlreadyExists' : 'messages.updateNameError'),
				}),
			);
	};

	const handleTabChange = (_event: React.SyntheticEvent, nextValue: number) => {
		navigate(WorkspaceRoutes.generic(workspaceId, workspaceTabs[nextValue]), { replace: true });
	};
	useDocumentTitleHandler(t('title'));
	const membersTabStyle = { width: '160px', display: hasAdminPermissions ? 'show' : 'none' };
	const policiesTabStyle = { width: '160px', display: isDeletionPoliciesEnabled ? 'show' : 'none' };
	const webhooksTabStyle = { width: '160px', display: isWebhookTabAllowed ? 'show' : 'none' };
	const subscriptionTabStyle = { width: '160px', display: isSubscriptionEnabled ? 'show' : 'none' };

	const panelStyle = { flexGrow: 1, minHeight: 0 };

	if (resultsError) {
		throw createNeoLoadError(resultsError);
	}

	if (membersError) {
		throw createNeoLoadError(membersError);
	}

	if (isLoading || !workspace) {
		return <Spinner />;
	}

	return (
		<Stack height='100%' width='100%'>
			<WorkspaceHeader
				workspace={workspace}
				membersCount={workspaceMembers.total}
				onEditWorkspaceName={editWorkspaceName}
				onDeleteWorkspace={deleteWorkspace}
				isDeleting={isDeleting}
			/>
			<Stack sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
				<Tabs value={selectedTab} onChange={handleTabChange}>
					<Tab label={t('tabs.members.title')} data-testid='workspace-members-tab' sx={membersTabStyle} />
					<Tab label={t('tabs.subscription.tabTitle')} data-testid='subscriptions-tab' sx={subscriptionTabStyle} />
					<Tab label={t('tabs.webhooks.title')} data-testid='webhooks-tab' sx={webhooksTabStyle} />
					{isDeletionPoliciesTabEnabled ? (
						<Tab label={t('tabs.deletionPolicies.title')} data-testid='deletion-policies-tab' sx={policiesTabStyle} />
					) : (
						<Tooltip arrow title={t('tabs.deletionPolicies.disabledTooltip')}>
							{/* span needed else Tooltip is not displayed when tab is disabled */}
							<span>
								<Tab
									disabled
									label={t('tabs.deletionPolicies.title')}
									data-testid='deletion-policies-tab'
									sx={policiesTabStyle}
								/>
							</span>
						</Tooltip>
					)}
				</Tabs>
			</Stack>
			<ComponentTabPanel value={selectedTab} index={0} data-testid='workspace-members-panel' sx={panelStyle}>
				<WorkspaceMembersDataGrid
					workspaceMembers={workspaceMembers}
					isFetching={isMembersFetching}
					workspace={workspace}
					hasAdminPermissions={hasAdminPermissions}
				/>
			</ComponentTabPanel>
			<ComponentTabPanel value={selectedTab} index={1} data-testid='subscription-panel' sx={panelStyle}>
				<SubscriptionPanel
					workspaceId={workspaceId}
					isQuotaLimitEnabled={fullWorkspace?.quotaLimitEnabled}
					isAdmin={hasAdminPermissions}
				/>
			</ComponentTabPanel>
			<ComponentTabPanel value={selectedTab} index={2} data-testid='webhooks-panel' sx={panelStyle}>
				<WebhooksDataGrid workspaceId={workspaceId} />
			</ComponentTabPanel>
			<ComponentTabPanel value={selectedTab} index={3} data-testid='deletion-policies-panel' sx={panelStyle}>
				<DeletionPoliciesPanel workspaceId={workspaceId} />
			</ComponentTabPanel>
		</Stack>
	);
};
export { WorkspacePage };

import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { useState } from 'react';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { DynamicZoneFormModal, DynamicZoneFormProps } from './dynamic-zone-form-modal';
import { CommonZonesDatagrid, OneOfZone, ZoneDatagridActionGetter } from '../common-zones-datagrid';
import { useCommonZonesColumns } from '../use-common-zones-columns';
import { DynamicZoneRead, useDeleteV4ZonesByZoneIdMutation, ZoneRead } from '@neoload/api';
import { useConfirmModal, useGetMe } from '@neoload/hooks';

const DynamicZonesDatagrid = () => {
	const { openDeleteConfirmModal } = useConfirmModal();
	const [deleteZone] = useDeleteV4ZonesByZoneIdMutation();
	const [{ hasAdminPermissions }] = useGetMe();
	const { t } = useTranslation('zone');

	const [modalState, setModalState] = useState<Pick<DynamicZoneFormProps, 'open' | 'editedZoneId'>>({
		open: false,
		editedZoneId: undefined,
	});

	const close = () =>
		setModalState({
			open: false,
			editedZoneId: undefined,
		});

	const createDynamicZone = () => {
		setModalState({
			open: true,
			editedZoneId: undefined,
		});
	};

	const updateDynamicZone = (zone: ZoneRead) => {
		setModalState({
			open: true,
			editedZoneId: zone.zoneId,
		});
	};

	const actionGetters: ZoneDatagridActionGetter<OneOfZone>[] = hasAdminPermissions
		? [
				{
					icon: <EditOutlined />,
					text: t('common:edit'),
					action: () => ({}),
					tooltip: t('editDynamicZone'),
					singleItem: (zone: OneOfZone) => ({
						action: (event?: React.MouseEvent<HTMLButtonElement>) => {
							event?.stopPropagation();
							updateDynamicZone(zone);
						},
						disabled: false,
					}),
				},
				{
					icon: <DeleteOutlined />,
					text: t('common:delete'),
					color: 'error',
					action: () => ({}),
					singleItem: ({ name, zoneId, controllers, loadGenerators }: DynamicZoneRead) => {
						const disabled = controllers.length + loadGenerators.length > 0;
						return {
							disabled,
							text: t(disabled ? 'delete.busy' : 'common:delete'),
							action: (event?: React.MouseEvent<HTMLButtonElement>) => {
								event?.stopPropagation();
								openDeleteConfirmModal({
									title: t('delete.header', { type: t('tabs.dynamic'), name }),
									content: t('delete.text', { type: t('tabs.dynamic'), name }),
									handleConfirm: () => deleteZone({ zoneId }).unwrap(),
								});
							},
						};
					},
				},
			]
		: [];

	const commonColumns = useCommonZonesColumns<DynamicZoneRead>(actionGetters);

	const columns: GridColDef<DynamicZoneRead>[] = [
		commonColumns[0],
		{
			field: 'providerName',
			minWidth: 300,
			flex: 0.5,
			headerName: t('columns.providerName'),
			valueGetter: (_, row) => row.provider.name,
		},
		commonColumns[1],
		commonColumns[2],
	];

	return (
		<>
			<CommonZonesDatagrid
				type='DYNAMIC'
				columns={columns}
				{...(hasAdminPermissions && { onAdd: createDynamicZone, actionGetters: actionGetters })}
			/>
			<DynamicZoneFormModal {...modalState} close={close} />
		</>
	);
};

export { DynamicZonesDatagrid };

import DialogContent from '@mui/material/DialogContent';
import React, { useEffect, useRef } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { DescriptionReservationModal } from './common/description-reservation-modal';
import { CreateReservationForm } from './common/reservation-helper';
import { VusReservationModal } from './common/vus-reservation-modal';
import { ActionsReservationModal } from './common/actions-reservation-modal';
import { ReservationDetailsModal } from './common/reservation-details-modal';
import { TitleReservationModal } from './common/title-reservation-modal';
import { ReservationLoadZones } from './common/reservation-load-zones';

type CreateModalReservationLayoutProps = {
	close: () => void;
	defaultValue: CreateReservationForm;
};

const CreateReservationLayoutModal = ({ close, defaultValue }: CreateModalReservationLayoutProps) => {
	const { t } = useTranslation(['reservation']);
	const { ...methods } = useForm<CreateReservationForm>({
		mode: 'onChange',
		defaultValues: defaultValue,
	});
	const name = useWatch<CreateReservationForm, 'name'>({ name: 'name', control: methods.control });
	const workspaceId = useWatch<CreateReservationForm, 'workspaceId'>({ name: 'workspaceId', control: methods.control });
	const error = useWatch<CreateReservationForm, 'error'>({ name: 'error', control: methods.control });
	const controller = useWatch<CreateReservationForm, 'zones.controller'>({
		name: 'zones.controller',
		control: methods.control,
	});
	const lgZones = useWatch<CreateReservationForm, 'zones.items'>({
		name: 'zones.items',
		control: methods.control,
	});
	const isLGZoneSelected = lgZones.some((element) => element.number > 0);
	const webVus = useWatch<CreateReservationForm, 'webVus.needed'>({ name: 'webVus.needed', control: methods.control });
	const sapVus = useWatch<CreateReservationForm, 'sapVus.needed'>({ name: 'sapVus.needed', control: methods.control });
	const missingVus = webVus === 0 && sapVus === 0;
	const errorMessageRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		errorMessageRef.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
	}, [error]);

	const disableSubmit = name === '' || workspaceId === '' || controller === '' || !isLGZoneSelected || missingVus;
	return (
		<FormProvider {...methods}>
			<DialogContent sx={{ padding: '12px 24px', width: '700px' }}>
				<Stack useFlexGap gap={3}>
					<Stack useFlexGap gap={3} marginTop={3}>
						{error !== '' && (
							<Alert
								ref={errorMessageRef}
								sx={{ marginBottom: 2, marginTop: 0, alignItems: 'center' }}
								severity='error'
							>
								<Typography variant='body2'>{error}</Typography>
							</Alert>
						)}
						<TitleReservationModal />
						<DescriptionReservationModal />
					</Stack>
					<ReservationDetailsModal />
					<ReservationLoadZones />
					<VusReservationModal />
				</Stack>
			</DialogContent>
			<ActionsReservationModal close={close} disableSubmit={disableSubmit} labelSubmit={t('create.confirm')} />
		</FormProvider>
	);
};

export { CreateReservationLayoutModal };

// eslint-disable-next-line no-restricted-imports
import styled from '@mui/material/styles/styled';
import TextField, { TextFieldProps } from '@mui/material/TextField';

/**
 * TextField with custom styles to avoir having spinner buttons that are here by default
 * Useful for number types textfields
 */
export const NumberTextfieldWithoutSpinners = styled(TextField)<TextFieldProps>(() => ({
	/*eslint-disable @typescript-eslint/naming-convention*/
	//Webkit (Chrome, Safari, Edge)
	'& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
		display: 'none',
	},
	//Firefox
	'& input[type=number]': {
		MozAppearance: 'textfield',
	},
	/*eslint-enable @typescript-eslint/naming-convention*/
}));

import ZeroState from '@tricentis/aura/components/ZeroState.js';
import Typography from '@mui/material/Typography';
import { ButtonProps } from '@mui/material/Button';
import { Key, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ServerError from '../../../../assets/ServerError.svg';
import { CreateResourceButton } from '../../../common/button/create-resource-button';

type ButtonPropsWithKey = ButtonProps & { key: Key };

type DeletionPoliciesEmptyStateProps = {
	createAction: MouseEventHandler<HTMLButtonElement>;
};

const DeletionPoliciesEmptyState = ({ createAction }: DeletionPoliciesEmptyStateProps) => {
	const { t } = useTranslation(['workspace']);

	const actions: ButtonPropsWithKey[] = [
		{
			key: 'createDeletionPolicy',
			variant: 'contained',
			onClick: createAction,
		},
	];
	const title = t('tabs.deletionPolicies.zero.title');

	return (
		<Box sx={{ flexGrow: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<ZeroState
				illustration={<img src={ServerError} alt={title} />}
				title={title}
				actions={actions.map(({ key, ...others }) => (
					<CreateResourceButton {...others} key={key}>
						{t('tabs.deletionPolicies.createButton')}
					</CreateResourceButton>
				))}
				containerSx={{ backgroundColor: 'transparent' }}
			>
				<Typography variant='body2'>{t('tabs.deletionPolicies.zero.content')}</Typography>
			</ZeroState>
		</Box>
	);
};

export { DeletionPoliciesEmptyState };

import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import i18n from 'i18next';
import { withLazyFetching, WithLazyFetchingProps } from './with-lazy-fetching';
import { useWidgetDataFetcher } from './hooks/use-widget-data-fetcher';
import { Spinner } from '../../../common/spinner';
import { DashboardTileErrorContent } from '../tiles/dashboard-tile-error-content';
import { Cell, TableGrid } from '../../../common/table-grid';
import { DashboardTileUnloadedContent } from '../tiles/dashboard-tile-unloaded-content';
import {
	ColumnDefinition,
	columnDefinitionsToColumns,
	sortToSortColumnIndex,
	sortToSortDirection,
} from '../dashboard-common';
import { CsvDownloadContext } from '../tiles/tile/csv-download-context';
import {
	ErrorCountByElement,
	ErrorsCountFilter,
	GetV4ResultsByResultIdEventsErrorsApiArg,
	GetV4ResultsByResultIdEventsErrorsApiResponse,
	useLazyGetV4ResultsByResultIdEventsErrorsQuery,
	WidgetDashboardTile,
} from '@neoload/api';

type ErrorsByElementFetcherProps = {
	widgetTile: WidgetDashboardTile;
} & WithLazyFetchingProps;
const columns = [
	{ label: i18n.t('errors.userPath', { ns: 'dashboard' }) },
	{ label: i18n.t('errors.element', { ns: 'dashboard' }) },
	{ label: i18n.t('errors.parent', { ns: 'dashboard' }) },
	{ label: i18n.t('errors.errorCount', { ns: 'dashboard' }) },
];
export const InternalErrorsByElementFetcher = ({ shouldStartFetching, widgetTile }: ErrorsByElementFetcherProps) => {
	const { t } = useTranslation(['dashboard']);
	const [triggerEventsErrorsByElement] = useLazyGetV4ResultsByResultIdEventsErrorsQuery();
	const { setDataToDownload } = useContext(CsvDownloadContext);
	const { pageSize, pageNumber, sort } = widgetTile.filter as ErrorsCountFilter;

	const [data, error, loadingState] = useWidgetDataFetcher<
		GetV4ResultsByResultIdEventsErrorsApiArg,
		GetV4ResultsByResultIdEventsErrorsApiResponse
	>(
		widgetTile,
		{
			errorsType: 'ELEMENT',
			resultId: widgetTile.resultId,
			pageSize,
			pageNumber,
			sort,
		},
		shouldStartFetching,
		triggerEventsErrorsByElement
	);
	useEffect(() => {
		if (loadingState === 'LOADED' && data) {
			setDataToDownload({
				columns,
				values: errorsByElementArrayToTable(data.items as ErrorCountByElement[]),
			});
		}
	}, [loadingState, data, setDataToDownload]);

	if (loadingState === 'UNLOADED') {
		return <DashboardTileUnloadedContent />;
	}
	if (loadingState === 'LOADING') {
		return <Spinner />;
	}
	if (error || !data) {
		if (error) {
			console.error(
				t('widget.error.global', {
					tileId: widgetTile.id,
				}),
				error
			);
		}
		return <DashboardTileErrorContent />;
	}

	const columnDefs: ColumnDefinition[] = [
		{
			name: 'USERPATH',
			i18nKey: 'errors.userPath',
		},
		{
			name: 'ELEMENT',
			i18nKey: 'errors.element',
		},
		{
			name: 'PARENT',
			i18nKey: 'errors.parent',
		},
		{
			name: 'COUNT',
			i18nKey: 'errors.errorCount',
			align: 'right',
		},
	];

	return (
		<TableGrid
			columns={columnDefinitionsToColumns(columnDefs, t)}
			sortColumnIndex={sortToSortColumnIndex(columnDefs, sort)}
			sortDirection={sortToSortDirection(sort)}
			values={errorsByElementArrayToTable(data.items as ErrorCountByElement[])}
		/>
	);
};

export const VisibleForTesting = InternalErrorsByElementFetcher;
export const ErrorsByElementFetcher = withLazyFetching(InternalErrorsByElementFetcher);

function errorsByElementArrayToTable(errorsByElements: ErrorCountByElement[]): Cell[][] {
	const rows = [];
	for (const errorByElement of errorsByElements) {
		rows.push(errorsByElementToTableRow(errorByElement));
	}
	return rows;
}

function errorsByElementToTableRow(errorsByElement: ErrorCountByElement): Cell[] {
	return [
		{ text: errorsByElement.userPath },
		{ text: errorsByElement.element },
		{ text: errorsByElement.parent },
		{ text: errorsByElement.count.toString() },
	];
}

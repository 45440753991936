import dayjs from 'dayjs';

const reservationsDateSorter = (v1: string, v2: string) => {
	const [v1a] = v1.split('-');
	const [v2a] = v2.split('-');
	const day1 = dayjs(v1a);
	const day2 = dayjs(v2a);
	if (day1.isBefore(day2)) {
		return -1;
	} else if (day1.isAfter(day2)) {
		return 1;
	}
	return 0;
};

export { reservationsDateSorter };

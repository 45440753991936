import { useNavigate, useParams } from 'react-router';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import SectionTitle from '@tricentis/aura/components/SectionTitle.js';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { ZonesGenericParams, ZonesRoutes, zonesTabs } from '@neoload/utils';
import { ComponentTabPanel, DynamicZonesDatagrid, StaticZonesDataGrid, CloudZonesDatagrid } from '@neoload/ui';
import { useDocumentTitleHandler } from '@neoload/hooks';
const ZonesPage = () => {
	const navigate = useNavigate();
	const { t } = useTranslation(['zone']);
	const theme = useTheme();
	const { tab = 'cloud' } = useParams<ZonesGenericParams>() as ZonesGenericParams;
	const selectedTab: number = zonesTabs.indexOf(tab);
	const handleTabChange = (_event: React.SyntheticEvent, nextValue: number) => {
		navigate(ZonesRoutes.generic(zonesTabs[nextValue]), { replace: true });
	};
	useDocumentTitleHandler(t('title'));
	const tabStyle = { width: '160px' };
	return (
		<Stack height='100%' width='100%'>
			<Box sx={{ padding: 1 }}>
				<SectionTitle title={t('title')} />
			</Box>
			<Stack sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
				<Tabs value={selectedTab} onChange={handleTabChange}>
					<Tab label={t('tabs.cloud')} data-testid='zones-cloud-tab' sx={tabStyle} />
					<Tab label={t('tabs.static')} data-testid='zones-static-tab' sx={tabStyle} />
					<Tab label={t('tabs.dynamic')} data-testid='zones-dynamic-tab' sx={tabStyle} />
				</Tabs>
			</Stack>
			<ComponentTabPanel
				value={selectedTab}
				index={0}
				data-testid='zones-cloud-panel'
				sx={{ flexGrow: 1, minHeight: 0 }}
			>
				<CloudZonesDatagrid />
			</ComponentTabPanel>
			<ComponentTabPanel
				value={selectedTab}
				index={1}
				data-testid='zones-static-panel'
				sx={{ flexGrow: 1, minHeight: 0 }}
			>
				<StaticZonesDataGrid />
			</ComponentTabPanel>
			<ComponentTabPanel
				value={selectedTab}
				index={2}
				data-testid='zones-dynamic-panel'
				sx={{ flexGrow: 1, minHeight: 0 }}
			>
				<DynamicZonesDatagrid />
			</ComponentTabPanel>
		</Stack>
	);
};
export { ZonesPage };

import { useTranslation } from 'react-i18next';
import { WorkspacesDataGrid } from '@neoload/ui';
import { useDeleteV4WorkspacesByWorkspaceIdMutation } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';
import { isForbiddenError } from '@neoload/utils';

const WorkspacesPage = () => {
	const { t } = useTranslation(['workspace']);
	const { showInfo, showError } = useSetSnackbars();
	const [removeWorkspace, { isLoading: isDeleting }] = useDeleteV4WorkspacesByWorkspaceIdMutation();

	const deleteWorkspace = async (workspaceId: string) => {
		await removeWorkspace({ workspaceId: workspaceId })
			.unwrap()
			.then(() => showInfo({ text: t('messages.deleteSuccess') }))
			.catch((error) =>
				showError({ text: t(isForbiddenError(error) ? 'messages.deleteErrorForbidden' : 'messages.deleteError') }),
			);
	};

	return <WorkspacesDataGrid onDeleteWorkspace={deleteWorkspace} isDeleting={isDeleting} />;
};
export { WorkspacesPage };

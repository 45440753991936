import Tooltip from '@tricentis/aura/components/Tooltip.js';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { getClickableLink } from './external-url-utils';

export type ExternalUrlPlainLinkProps = {
	href?: string;
	title?: string;
};

const ExternalUrlPlainLink = ({ href, title }: ExternalUrlPlainLinkProps) => {
	if (!href) {
		return null;
	}

	const clickableHref = getClickableLink(href);

	return (
		<Stack direction='row' justifyContent='center' alignItems='center' sx={{ width: '100%', height: '100%' }}>
			<Tooltip arrow title={title ?? clickableHref}>
				<IconButton
					component={Link}
					href={clickableHref}
					target='_blank'
					data-testid='external-url-plain-link'
					color='primary'
				>
					<LinkOutlinedIcon />
				</IconButton>
			</Tooltip>
		</Stack>
	);
};

export { ExternalUrlPlainLink };

import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useFormContext } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { WebhookFiltersKeys, WebhookFiltersType } from './types';
import { WebhookFilters } from '@neoload/api';

type WebhookFilterComponentProps<T extends WebhookFiltersType> = {
	operators: T['operator'][];
	filterKey: WebhookFiltersKeys;
	valueInputComponent: ReactNode;
	disabled?: boolean;
	tooltip?: string;
};

export const WebhookFilterComponent = <T extends WebhookFiltersType>({
	operators,
	filterKey,
	valueInputComponent,
	disabled = false,
	tooltip,
}: WebhookFilterComponentProps<T>) => {
	const { register, watch, setValue, trigger } = useFormContext<WebhookFilters>();

	const enabled = watch(`${filterKey}.enabled`);
	const operator = watch(`${filterKey}.operator`);

	//there is no cross validation in react-hook-form so trigger manually (here enabled property influences value 's validation)
	useEffect(() => {
		void trigger(`${filterKey}.value`);
	}, [trigger, filterKey, enabled]);

	const { t } = useTranslation('workspace', { keyPrefix: 'tabs.webhooks.filters' });
	return (
		<Grid
			display='grid'
			container
			gap={2}
			columns={3}
			gridTemplateColumns='168px 168px 168px'
			alignItems='top'
			data-testid={`${filterKey}.filterComponent`}
		>
			<Tooltip arrow title={tooltip}>
				<FormControlLabel
					control={
						<Checkbox
							size='small'
							checked={enabled}
							onChange={(changeEvent) => setValue(`${filterKey}.enabled`, changeEvent.currentTarget.checked)}
							disabled={disabled}
						/>
					}
					label={t(filterKey)}
					sx={{ maxHeight: '40px' }}
				/>
			</Tooltip>
			<FormControl>
				<InputLabel id={`${filterKey}operator`}>{t('operator')}</InputLabel>
				<Select<T['operator']>
					{...register(`${filterKey}.operator`)}
					defaultValue={operator}
					size='small'
					label={t('operator')}
					labelId={`${filterKey}operator`}
				>
					{operators.map((operator) => (
						<MenuItem key={operator} value={operator}>
							{t(`operator${operator}`)}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{valueInputComponent}
		</Grid>
	);
};

import Stack from '@mui/material/Stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { WebhookFilterComponent } from './webhook-filter-component';
import { DurationValueInput } from './value-inputs/duration-value-input';
import { MaxVusValueInput } from './value-inputs/max-vus-value-input';
import { NameFilterValueInput } from './value-inputs/name-filter-value-input';
import { QualityStatusValueInput } from './value-inputs/quality-status-value-input';
import {
	ByDurationFilter,
	ByMaxVUsFilter,
	ByNameFilter,
	ByQualityStatusFilter,
	PostWebhookRequest,
} from '@neoload/api';

type WebhookFiltersPanelProps = {
	webhookTrigger: PostWebhookRequest['trigger'];
};

export const WebhookFiltersPanel = ({ webhookTrigger }: WebhookFiltersPanelProps) => {
	const { t } = useTranslation('workspace', { keyPrefix: 'tabs.webhooks.filters' });
	return (
		<Stack direction='column' gap={2} sx={{ marginTop: 2, marginBottom: 2 }}>
			<WebhookFilterComponent<ByNameFilter>
				operators={['CONTAINS', 'DOES_NOT_CONTAIN']}
				filterKey='byTestName'
				valueInputComponent={<NameFilterValueInput filterKey='byTestName' maxLength={100} />}
			/>
			<WebhookFilterComponent<ByNameFilter>
				operators={['CONTAINS', 'DOES_NOT_CONTAIN']}
				filterKey='byProjectName'
				valueInputComponent={<NameFilterValueInput filterKey='byProjectName' maxLength={256} />}
			/>
			<WebhookFilterComponent<ByNameFilter>
				operators={['CONTAINS', 'DOES_NOT_CONTAIN']}
				filterKey='byScenarioName'
				valueInputComponent={<NameFilterValueInput filterKey='byScenarioName' maxLength={100} />}
			/>
			<WebhookFilterComponent<ByQualityStatusFilter>
				operators={['IS', 'IS_NOT']}
				filterKey='byQualityStatus'
				valueInputComponent={<QualityStatusValueInput />}
				disabled={webhookTrigger === 'ON_RESULT_STARTED'}
				tooltip={webhookTrigger === 'ON_RESULT_STARTED' ? t('qualityStatusFilterDisabledTooltip') : undefined}
			/>
			<WebhookFilterComponent<ByMaxVUsFilter>
				operators={['IS_GREATER', 'IS_LOWER']}
				filterKey='byMaxVus'
				valueInputComponent={<MaxVusValueInput />}
			/>
			<WebhookFilterComponent<ByDurationFilter>
				operators={['IS_LONGER', 'IS_SHORTER']}
				filterKey='byDuration'
				valueInputComponent={<DurationValueInput />}
			/>
		</Stack>
	);
};

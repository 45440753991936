import { DataGridProps } from '@mui/x-data-grid';
import { getGridNumericOperators } from '@mui/x-data-grid-pro';

const numericOperatorsList = new Set(['>', '<']);
const numericOperators = getGridNumericOperators().filter((operator) => numericOperatorsList.has(operator.value));

const dataGridStyle: DataGridProps['sx'] = {
	border: 'none',
	'& .MuiDataGrid-pinnedColumns': {
		boxShadow: 'unset',
	},

	'& .MuiDataGrid-pinnedColumnHeaders': {
		boxShadow: 'unset',
	},
};

export { numericOperators, dataGridStyle };

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { useFormContext, useWatch } from 'react-hook-form';
import { TestExecutionZone } from './test-execution-zone';
import { isZoneError, isZoneErrorIncompatibleVersion, isZoneErrorLg } from './../../test-execution-helpers';
import { getPartialErrorZones } from '../../../zones/zone-helpers';
import { TestExecutionForm } from '../../types.d';
import { calcWidth, gap, width } from '../../constant';
import { useGetV4ZonesQuery, Zone } from '@neoload/api';

const TestExecutionZones = () => {
	const { t } = useTranslation(['test']);
	const { getValues } = useFormContext<TestExecutionForm>();
	const theme = useTheme();
	const { data: zonesPage = { items: [], total: 0 }, error: ncpError } = useGetV4ZonesQuery({
		type: undefined,
	});
	const zonesPageData = ncpError ? getPartialErrorZones(ncpError) : zonesPage;
	const missingDedicatedIpByZone = getValues('resources.missingDedicatedIpByZone');
	const minimumResourcesVersion = getValues('resources.minimumVersion');
	const resourcesVersion = getValues('resources.version');

	const reservationId = getValues('resources.reservationId');
	const lgZones = reservationId
		? getValues('reservation.selectedReservation.lgCountByZoneId')
		: getValues('resources.zones');

	const resourceControllerZone = getValues('resources.controllerZoneId');
	const controllerZone = reservationId
		? getValues('reservation.selectedReservation.controllerZoneId')
		: resourceControllerZone;

	const errors = useWatch<TestExecutionForm, 'errors'>({ name: 'errors' });

	const getErrorMessage = (key: string) => {
		if (isZoneErrorIncompatibleVersion(errors) && minimumResourcesVersion) {
			return t('testExecution.errors.' + key, { context: 'version', version: minimumResourcesVersion });
		}
		if (isZoneErrorLg(errors) && resourcesVersion) {
			return t('testExecution.errors.' + key, { context: 'version', version: resourcesVersion });
		}

		return t('testExecution.errors.' + key);
	};

	const hasMissingIps = missingDedicatedIpByZone && Object.keys(missingDedicatedIpByZone).length > 0;
	const countMissingIpsForZone = (zoneId: string) => missingDedicatedIpByZone?.[zoneId];

	const getWarningMessage = () => {
		if (
			missingDedicatedIpByZone?.[resourceControllerZone] &&
			missingDedicatedIpByZone?.[resourceControllerZone] === 1
		) {
			return t('testExecution.notEnoughDedicatedIp.warning.controller');
		}
		return t('testExecution.notEnoughDedicatedIp.warning.lg');
	};

	const zones = zonesPageData?.items.filter(
		(zone) => Object.keys(lgZones).includes(zone.zoneId) || zone.zoneId === controllerZone,
	);

	/* eslint-disable @typescript-eslint/naming-convention */
	const HOOK_TYPE_BY_ZONE_TYPE: {
		[K in Zone['type']]: string;
	} = {
		CLOUD: 'configuration.zones.types.ncp',
		STATIC: 'configuration.zones.types.static',
		DYNAMIC: 'configuration.zones.types.dynamic',
	};
	/* eslint-enable @typescript-eslint/naming-convention */

	return (
		<Box sx={{ marginTop: theme.spacing(2) }}>
			<Box sx={{ display: 'flex', gap: gap(theme) }}>
				<Box width={width}>
					<Typography variant='subtitle2' color={theme.palette.text.primary}>
						{t('testExecution.zones')}
					</Typography>
					<Typography variant='caption' color={theme.palette.text.primary}>
						({t(HOOK_TYPE_BY_ZONE_TYPE[getValues('resources.zoneType')])})
					</Typography>
				</Box>
				<Box width={calcWidth(theme)}>
					{zones?.map((zone) => (
						<TestExecutionZone
							key={zone.zoneId}
							zoneName={zone.name}
							nbLgsRequired={lgZones[zone.zoneId]}
							defaultController={zone.zoneId === controllerZone}
							nbLgsZone={zone.loadGenerators.filter((z) => z.version === resourcesVersion).length}
							missingDedicatedIps={countMissingIpsForZone(zone.zoneId)}
						/>
					))}
				</Box>
			</Box>
			{isZoneError(errors) &&
				errors.map((error) => (
					<Alert sx={{ marginTop: 2, alignItems: 'center' }} severity='error' key={error.type}>
						<Typography variant='body2'>{getErrorMessage(error.type)}</Typography>
					</Alert>
				))}
			{hasMissingIps && (
				<Alert sx={{ marginTop: 2, alignItems: 'center' }} severity='warning'>
					<Typography variant='body2'>{getWarningMessage()}</Typography>
				</Alert>
			)}
		</Box>
	);
};

export { TestExecutionZones };

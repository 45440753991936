import { SimpleTreeView } from '@mui/x-tree-view';
import ExpandMoreIconOutlined from '@mui/icons-material/ExpandMoreOutlined';
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { SyntheticEvent, useState } from 'react';
import { StatisticTreeItem } from './statistic-tree-item';

export const findNode = <T extends object>(
	elements: TreeNode<T>[],
	nodeId: string | null,
): TreeNodeSearchResult<T> | undefined => {
	if (nodeId === null) {
		return undefined;
	}
	for (const element of elements) {
		if (element.id === nodeId) {
			return { node: element.data, path: [] };
		}
		const elementChildren = findNode(element.children, nodeId);
		if (elementChildren) {
			return { node: elementChildren.node, path: [element.data, ...elementChildren.path] };
		}
	}
	return undefined;
};

export type TreeSelection = {
	expandedIds: string[];
	nodeId: string;
};

export type TreeNode<T> = {
	label: string;
	id: string;
	children: TreeNode<T>[];
	data: T;
};

export type TreeNodeSearchResult<T> = {
	node: T;
	path: T[];
};

type StatisticTreeProps<T> = {
	label?: string;
	defaultValue?: TreeSelection;
	rootNodes: TreeNode<T>[];
	onChange: (selected: TreeNodeSearchResult<T>) => void;
};

export const StatisticTree = <T extends object>({
	defaultValue,
	rootNodes,
	onChange,
	label,
}: StatisticTreeProps<T>) => {
	const { t } = useTranslation(['dashboard'], { keyPrefix: 'tile.edition.addSeries' });
	const [selectedNode, setSelectedNode] = useState<string | null>(defaultValue?.nodeId ?? null);
	const [expandedNodes, setExpandedNodes] = useState<string[]>(defaultValue?.expandedIds ?? []);
	if (rootNodes.length === 0) {
		return (
			<Box sx={{ display: 'flex', alignItems: 'center', height: '200px', justifyContent: 'center' }}>
				<Typography variant='body2' color={(theme) => theme.palette.text.secondary}>
					{t('empty')}
				</Typography>
			</Box>
		);
	}

	const handleSelect = (_: SyntheticEvent, nodeId: string | null) => {
		setSelectedNode(nodeId);
		const element = findNode(rootNodes, nodeId);
		if (element) {
			onChange(element);
		}
	};
	const handleExpand = (_: SyntheticEvent, expandedIds: string[]) => {
		setExpandedNodes(expandedIds);
	};

	return (
		<SimpleTreeView
			aria-label={label}
			selectedItems={selectedNode}
			onSelectedItemsChange={handleSelect}
			expandedItems={expandedNodes}
			onExpandedItemsChange={handleExpand}
			slots={{
				collapseIcon: ExpandMoreIconOutlined,
				expandIcon: ChevronRightOutlined,
			}}
		>
			{rootNodes.map((element) => (
				<StatisticTreeItem key={element.id} {...element} />
			))}
		</SimpleTreeView>
	);
};

import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useGetMe, useCurrentWorkspace, useCrudBatchMessage } from '@neoload/hooks';
import { useGetV4TestsQuery, usePatchV4ResultsByResultIdMutation, Test } from '@neoload/api';

type ResultMoveDialogProps = {
	isOpen: boolean;
	onClose: () => void;
	movingResultIds: string[];
};

const PAGE_SIZE = 200;

const ResultMoveDialog = ({ isOpen, onClose, movingResultIds }: ResultMoveDialogProps) => {
	const { t } = useTranslation(['result']);

	const [{ workspaces = [] }] = useGetMe();
	const [{ id: currentWorkspaceId }] = useCurrentWorkspace();
	const [updateTestResults, { isLoading: loading }] = usePatchV4ResultsByResultIdMutation();

	const [selectedWorkspace, setSelectedWorkspace] = useState(currentWorkspaceId);
	const [selectedTest, setSelectedTest] = useState<{ id: string; name: string } | undefined>(undefined);
	const [tests, setTests] = useState<{ items: Test[]; page: number }>({ items: [], page: 0 });

	const {
		data: testPage,
		isFetching: isFetchingTests,
		isError,
	} = useGetV4TestsQuery(
		{
			workspaceId: selectedWorkspace,
			pageNumber: tests.page,
			pageSize: PAGE_SIZE,
		},
		{ skip: !isOpen },
	);

	useEffect(() => {
		if (testPage) {
			setTests((previous) => {
				const items = [...previous.items, ...testPage.items];
				const page = testPage.items.length === PAGE_SIZE ? previous.page + 1 : previous.page;
				return { items, page };
			});
		}
	}, [testPage]);

	useEffect(() => {
		setSelectedWorkspace(currentWorkspaceId);
	}, [currentWorkspaceId]);

	useEffect(() => {
		setSelectedTest(undefined);
		setTests({ items: [], page: 0 });
	}, [selectedWorkspace]);

	const { update } = useCrudBatchMessage(
		'result',
		'actions.moveTestResults',
		selectedTest ? [{ testName: selectedTest.name }] : [],
	);

	const close = () => {
		setSelectedTest(undefined);
		setSelectedWorkspace(currentWorkspaceId);
		onClose();
	};

	const moveTest = async (workspace: string, test: string) => {
		await update(movingResultIds, (resultId) =>
			updateTestResults({ resultId, testResultInput: { workspaceId: workspace, testId: test } }).unwrap(),
		);
		close();
	};

	const onWorkspaceChange = (event: SelectChangeEvent<string>) => {
		setSelectedWorkspace(event.target.value);
	};

	const title = t('actions.moveTestResults.title', { count: movingResultIds.length });
	const label = t('actions.moveTestResults.label', { count: movingResultIds.length });
	const dashboardWarning = t('actions.moveTestResults.dashboardWarning');
	const targetWorkspace = t('actions.moveTestResults.targetWorkspace');
	const targetTest = t('actions.moveTestResults.targetTest');
	const move = t('actions.moveTestResults.move');
	const moveAnyway = t('actions.moveTestResults.moveAnyway');
	const cancel = t('common:cancel');

	return (
		<Dialog open={isOpen} onClose={close} fullWidth={true} aria-labelledby='move-result-modal-title'>
			<DialogTitle id='move-result-modal-title'>{title}</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 3,
						marginTop: 1,
					}}
				>
					{label}
					<Box>
						<FormControl fullWidth required>
							<InputLabel id='move-dialog-workspaces'>{targetWorkspace}</InputLabel>
							<Select<string>
								autoFocus
								size='small'
								label={targetWorkspace}
								labelId='move-dialog-workspaces'
								defaultValue={currentWorkspaceId}
								onChange={onWorkspaceChange}
							>
								{workspaces.map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					<Box>
						<FormControl fullWidth required>
							<Autocomplete<{ id: string; name: string }>
								loading={isFetchingTests}
								loadingText={t('actions.moveTestResults.loadingTests')}
								size='small'
								options={tests.items.map(({ id, name }) => ({ id, name }))}
								getOptionLabel={(option) => option.name}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								noOptionsText={t('actions.moveTestResults.noTest')}
								value={selectedTest ?? null}
								onChange={(_, value) => {
									setSelectedTest(value ?? undefined);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label={targetTest}
										required
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{isFetchingTests && <CircularProgress color='inherit' size={20} />}
													{params.InputProps.endAdornment}
												</>
											),
										}}
									/>
								)}
							/>
							{isError && <FormHelperText error>{t('actions.moveTestResults.loadingTestsError')}</FormHelperText>}
						</FormControl>
					</Box>
					{currentWorkspaceId !== selectedWorkspace && (
						<Alert sx={{ display: 'flex', alignItems: 'center' }} severity='warning'>
							{dashboardWarning}
						</Alert>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => close()} size='small' disabled={loading} data-trackingid='result-move-cancel'>
					{cancel}
				</Button>
				<Button
					onClick={async () => {
						selectedTest && (await moveTest(selectedWorkspace, selectedTest.id));
					}}
					size='small'
					variant='contained'
					disabled={loading || !selectedTest}
					startIcon={loading && <CircularProgress size={24.5} color='inherit' />}
					data-trackingid='result-move-ok'
					color={currentWorkspaceId === selectedWorkspace ? 'primary' : 'warning'}
				>
					{currentWorkspaceId === selectedWorkspace ? move : moveAnyway}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export { ResultMoveDialog };

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import IconInfoOutlined from '@tricentis/aura/components/IconInfoOutlined.js';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { DeepPartial, useFormContext } from 'react-hook-form';
import { ComparisonTileEditionFormFields } from '../../tile-edition-form';
import { ValuesComparisonDashboardTile } from '@neoload/api';

//Difference type without possibility of 'NONE' value, used for the combobox since the none value is handled via the switch button
type RequiredDifferenceType = Exclude<ValuesComparisonDashboardTile['differenceType'], 'NONE'>;

type DifferenceItem = {
	labelI18key: string;
	indicationLabelI18key: string;
	indicationColor: 'error' | 'success';
};

const differenceItems = new Map<RequiredDifferenceType, DifferenceItem>([
	[
		'VALUE',
		{
			labelI18key: 'absolute',
			indicationLabelI18key: 'absoluteIndication',
			indicationColor: 'success',
		},
	],
	[
		'PERCENTAGE',
		{
			labelI18key: 'percentage',
			indicationLabelI18key: 'percentageIndication',
			indicationColor: 'error',
		},
	],
	[
		'BOTH',
		{
			labelI18key: 'both',
			indicationLabelI18key: 'bothIndication',
			indicationColor: 'success',
		},
	],
]);
export const ComparisonDifferenceSelector = () => {
	const { t } = useTranslation(['dashboard']);
	const theme = useTheme();
	const { watch, setValue } = useFormContext<DeepPartial<ComparisonTileEditionFormFields>>();
	const formDifferenceType = watch('differenceType');
	const defaultSelectValue =
		formDifferenceType !== undefined && formDifferenceType !== 'NONE' ? formDifferenceType : 'VALUE';

	//This state is used to know which value we should take when the form has 'NONE' value and we enable the switch. If the combobox has been previously
	//selected, this state is used to restore to this value, otherwise defaults to 'VALUE'
	//The type RequiredDifferenceType is used, which cannot be 'NONE', although the form can have none value if the switch is disabled
	const [selectedDifference, setSelectedDifference] = useState<RequiredDifferenceType>(defaultSelectValue);

	const differenceEnabled = formDifferenceType !== 'NONE';

	return (
		<Grid display='flex' flexDirection='column' gap={2}>
			<Grid display='flex' alignItems='center'>
				<FormControlLabel
					sx={{ marginLeft: 0, marginRight: 1 }}
					control={
						<Switch
							defaultChecked={differenceEnabled}
							onChange={(change) => setValue('differenceType', change.target.checked ? selectedDifference : 'NONE')}
						/>
					}
					label={t('valuesComparison.settings.showDifference')}
				/>
				<Tooltip title={t('valuesComparison.settings.differenceHelperText')} arrow={true}>
					<IconInfoOutlined sx={{ color: (theme) => theme.palette.action.active, fontSize: '1em' }} />
				</Tooltip>
			</Grid>
			{differenceEnabled && (
				<FormControl fullWidth variant='outlined'>
					<InputLabel>{t('valuesComparison.settings.differenceLabel')}</InputLabel>
					<Select
						fullWidth
						label={t('valuesComparison.settings.differenceLabel')}
						renderValue={(value: RequiredDifferenceType) => {
							const item = differenceItems.get(value);
							return item && t(`valuesComparison.settings.${item.labelI18key}`);
						}}
						value={selectedDifference}
						onChange={(change) => {
							const value: RequiredDifferenceType = change.target.value as RequiredDifferenceType;
							setValue('differenceType', value);
							setSelectedDifference(value);
						}}
						inputProps={{
							/* eslint-disable-next-line @typescript-eslint/naming-convention */
							'data-testid': 'difference-select',
						}}
					>
						{[...differenceItems.keys()].map((value) => {
							const item = differenceItems.get(value);
							return (
								item && (
									<MenuItem key={value} value={value}>
										<ListItemText>{t(`valuesComparison.settings.${item.labelI18key}`)}</ListItemText>
										<Typography variant='body2' color={theme.palette[item.indicationColor].main}>
											{t(`valuesComparison.settings.${item.indicationLabelI18key}`)}
										</Typography>
									</MenuItem>
								)
							);
						})}
					</Select>
				</FormControl>
			)}
		</Grid>
	);
};

import { DragEvent, memo, ReactNode, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import { useTheme } from '@mui/material';
import { useColorScheme } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';
import { useProjectUpload } from './use-project-upload';
import { ConfigurationFormData } from '../types.d';

type DragDropFormProps = {
	children: ReactNode;
};

const DragDropForm = memo(({ children }: DragDropFormProps) => {
	const handleFileChange = useProjectUpload();
	const { getValues } = useFormContext<ConfigurationFormData>();
	const theme = useTheme();
	const { mode } = useColorScheme();
	const [active, setActive] = useState(false);
	// with 2 div, we have 2 event dragenter and 1 event dragleave
	// this way we know when the user is inside the dropzone
	// because entered is 1 and not 0
	const [entered, setEntered] = useState(0);

	const activeColor =
		mode === 'light' ? alpha(theme.palette.action.selected, 0.7) : alpha(theme.palette.action.selected, 0.6);

	const projectMaxSize = getValues('projectMaxSize');
	const testId = getValues('test.id');

	const handleDrag = function (event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		if (event.type === 'dragenter') {
			setEntered((previousState) => previousState + 1);
		} else if (event.type === 'dragleave') {
			setEntered((previousState) => previousState - 1);
		}
	};

	const handleDrop = async (event: DragEvent) => {
		event.preventDefault();
		event.stopPropagation();
		const file = event.dataTransfer.files[0];
		if (file && projectMaxSize && testId) {
			await handleFileChange(file, projectMaxSize, testId);
		}
		setActive(false);
		setEntered(0);
	};

	useEffect(() => {
		if (entered === 0) {
			setActive(false);
		} else {
			setActive(true);
		}
	}, [entered, setActive]);

	return (
		<Box
			sx={{
				backgroundColor: active ? activeColor : theme.palette.background.paper,
				opacity: '0.9',
				border: active ? `2px dashed ${theme.palette.primary.main}` : '2px solid transparent',
				borderRadius: '6px',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&>*': active ? { pointerEvents: 'none' } : '',
				display: 'flex',
				direction: 'column',
				height: '100%',
			}}
			onDragEnter={handleDrag}
			onDragLeave={handleDrag}
			onDragOver={handleDrag}
			onDrop={handleDrop}
		>
			<Box
				sx={{
					display: 'flex',
					direction: 'column',
					height: '100%',
					width: '100%',
				}}
				onDragEnter={() => {
					setEntered((previousState) => previousState + 1);
				}}
				onDragLeave={() => {
					setEntered((previousState) => previousState - 1);
				}}
			>
				{children}
			</Box>
		</Box>
	);
});

export { DragDropForm };

import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import { translatePasswordError, PasswordFormProps } from './protected-helpers';
import { ConfigurationFormData } from '../../types';
import { usePostV4TestsByTestIdProjectPasswordMutation } from '@neoload/api';

type ModalPasswordProps = {
	close: () => void;
	testId: string;
};

const emptyForm: PasswordFormProps = {
	password: { value: '' },
};

const ModalPassword = ({ close, testId }: ModalPasswordProps) => {
	const { t } = useTranslation(['test']);
	const projectName = useWatch<ConfigurationFormData, 'project.name'>({ name: 'project.name' });
	const [form, setForm] = useState<PasswordFormProps>(emptyForm);
	const [postPassword, { isLoading: loading }] = usePostV4TestsByTestIdProjectPasswordMutation();
	const { setValue } = useFormContext<ConfigurationFormData>();

	const getPasswordError = (password: string) => {
		const validation = translatePasswordError(password);
		return validation ? t(validation) : undefined;
	};

	const cancel = () => {
		close();
		setForm(emptyForm);
	};

	const isFormValid = () => !getPasswordError(form.password.value);

	const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = event.target;
		setForm({
			...form,
			password: {
				value,
				error: getPasswordError(value),
			},
		});
	};

	const apply = () => {
		postPassword({ testId, updatePasswordRequest: { password: form.password.value } })
			.unwrap()
			.then(() => {
				setValue('project.securityStatus', 'ENABLED');
				close();
			})
			.catch(() => {
				setForm({
					...form,
					password: {
						...form.password,
						error: t('configuration.project.modalPassword.invalidPassword'),
					},
				});
			});
	};

	const onPressEnter = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter' && isFormValid()) {
			event.preventDefault();
			apply();
		}
	};

	return (
		<Dialog open={true} onClose={() => close()} maxWidth={false}>
			<DialogTitle>{t('configuration.project.enterPassword')}</DialogTitle>
			<DialogContent>
				<Stack sx={{ paddingY: 2, gap: 3 }}>
					<Typography variant='body2'>
						{t('configuration.project.modalPassword.description', { projectName: projectName })}
					</Typography>
					<FormControl onKeyDown={onPressEnter} fullWidth required>
						<TextField
							autoFocus
							label={t('configuration.project.modalPassword.placeholder')}
							placeholder={t('configuration.project.modalPassword.placeholder')}
							required
							onChange={onPasswordChange}
							type='password'
							value={form.password.value}
							helperText={form.password.error}
							error={!!form.password.error}
						/>
					</FormControl>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button color='info' onClick={cancel} data-trackingid='password-button-cancel'>
					{t('common:cancel')}
				</Button>
				<Button
					disabled={!isFormValid() || loading}
					onClick={() => apply()}
					variant='contained'
					color='info'
					data-trackingid='password-button-confirm'
					startIcon={loading ? <CircularProgress size={24.5} color='inherit' /> : ''}
				>
					{t('configuration.project.modalPassword.apply')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export { ModalPassword };

import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import { convertAsCodeHumanReadableTimeSpecificationToISO8601 } from './custom-scenarios-helpers';
import { CustomPopulation, ExecutionPolicies, RampUpPopulation, RampUpRange, VuDistributions } from '@neoload/api';

dayjs.extend(dayjsDuration);

type Point = [number, number];

// iteration is equal to 5 minutes
const ITERATION_DURATION = 300 * 1000;

const hasNoStepRampUp = (population: CustomPopulation) =>
	population.stepRampUp === '0s' || population.stepRampUp === '0m' || population.stepRampUp === '0h';

const populationTotalDuration = (population: CustomPopulation) => {
	if (population.executionPolicy === ExecutionPolicies.DURATION) {
		return dayjs.duration(convertAsCodeHumanReadableTimeSpecificationToISO8601(population.duration)).asMilliseconds();
	}
	return Number(population.duration) * ITERATION_DURATION;
};

const addStepRampUpLessThan1VuPerStep = (
	points: Point[],
	vusEachStep: number,
	stepRampUpInSeconds: number,
	startVus = 0,
	startDuration = 0,
	totalDuration = 0,
	maxVus = 0,
) => {
	const secondsForOneVu = 1 / vusEachStep;
	const max = Math.min(maxVus, Math.ceil(stepRampUpInSeconds / secondsForOneVu));

	for (let index = 0; index <= max; index++) {
		const currentDuration = Math.ceil(startDuration + (index - 1) * 1000 * secondsForOneVu);

		if (currentDuration > totalDuration) {
			break;
		}
		if (startDuration <= currentDuration && currentDuration >= 0) {
			points.push([currentDuration, startVus + index]);
		}
	}
};

const addStepRampUpMoreThan1VuPerStep = (
	points: Point[],
	vusEachStep: number,
	stepRampUpInSeconds: number,
	startVus = 0,
	startDuration = 0,
	totalDuration = 0,
) => {
	for (let index = 0; index <= stepRampUpInSeconds; index++) {
		const currentDuration = startDuration + (index - 1) * 1000;
		if (currentDuration > totalDuration) {
			break;
		}
		if (currentDuration >= 0) {
			points.push([currentDuration, Math.round(startVus + index * vusEachStep)]);
		}
	}
};

const addStepRampUp = (
	population: CustomPopulation,
	points: Point[],
	maxVus: number,
	startVus = 0,
	startDuration = 0,
	totalDuration = 0,
) => {
	const stepRampUpInSeconds = dayjs
		.duration(convertAsCodeHumanReadableTimeSpecificationToISO8601(population.stepRampUp))
		.asSeconds();
	const vusEachStep = (maxVus - startVus) / stepRampUpInSeconds;

	if (vusEachStep >= 1) {
		addStepRampUpMoreThan1VuPerStep(points, vusEachStep, stepRampUpInSeconds, startVus, startDuration, totalDuration);
	} else {
		addStepRampUpLessThan1VuPerStep(
			points,
			vusEachStep,
			stepRampUpInSeconds,
			startVus,
			startDuration,
			totalDuration,
			maxVus,
		);
	}
};

const generatePointsForConstantLoad = (population: CustomPopulation) => {
	const points: Point[] = [];
	const totalDuration = populationTotalDuration(population);

	if (
		population.executionPolicy !== ExecutionPolicies.ITERATION &&
		asCodeHumanReadableDurationToSeconds(population.stepRampUp) >
			asCodeHumanReadableDurationToSeconds(population.duration)
	) {
		return points;
	}

	if (hasNoStepRampUp(population)) {
		points.push([0, population.vus]);
	} else {
		addStepRampUp(population, points, population.vus, 0, 0, totalDuration);
	}

	addFinalPoint(points, totalDuration, population, population.vus);

	return points;
};

const getRampUpDuration = (population: RampUpPopulation) => {
	if (population.executionPolicy === ExecutionPolicies.DURATION) {
		return dayjs
			.duration(
				convertAsCodeHumanReadableTimeSpecificationToISO8601(
					population.rampUp.every.amount + population.rampUp.every.unit,
				),
			)
			.asMilliseconds();
	}

	return Number(population.rampUp.every.amount) * ITERATION_DURATION;
};

const asCodeHumanReadableDurationToSeconds = (duration: string) =>
	dayjs.duration(convertAsCodeHumanReadableTimeSpecificationToISO8601(duration)).asSeconds();

const generatePointsForRampUpLoad = (population: RampUpPopulation) => {
	const totalDuration = populationTotalDuration(population);

	const points: Point[] = [];

	// more ramp up users than max users, we only keep max users
	if (population.rampUp.users >= population.vus) {
		points.push([0, population.vus], [totalDuration, population.vus]);
		return points;
	}

	// ramp up every is not iterations and ramp up every is higher than duration,
	if (
		population.rampUp.every.unit !== RampUpRange.ITERATIONS &&
		asCodeHumanReadableDurationToSeconds(population.rampUp.every.amount + population.rampUp.every.unit) >
			asCodeHumanReadableDurationToSeconds(population.duration)
	) {
		points.push([0, population.rampUp.users], [totalDuration, population.rampUp.users]);

		return points;
	}

	if (
		population.executionPolicy !== ExecutionPolicies.ITERATION &&
		asCodeHumanReadableDurationToSeconds(population.stepRampUp) >
			asCodeHumanReadableDurationToSeconds(population.duration)
	) {
		return points;
	}

	if (hasNoStepRampUp(population)) {
		points.push([0, population.rampUp.users]);
	} else {
		addStepRampUp(population, points, population.rampUp.users, 0, 0, totalDuration);
	}

	const rampUpDuration = getRampUpDuration(population);

	let cumulativeDuration = 0;
	let cumulativeUsers = population.rampUp.users;

	while (cumulativeDuration < totalDuration) {
		cumulativeDuration += rampUpDuration;

		let totalDurationReached = false;
		if (cumulativeDuration >= totalDuration) {
			cumulativeDuration = totalDuration;
			totalDurationReached = true;
		}

		// if the total duration is reached, we add the last point and break the loop
		if (totalDurationReached) {
			points.push([cumulativeDuration, cumulativeUsers]);
			break;
		}

		cumulativeUsers += population.rampUp.users;

		// if the cumulative users are higher than the max users, we add the last point and break the loop
		if (cumulativeUsers > population.vus) {
			if (hasNoStepRampUp(population)) {
				points.push([cumulativeDuration, population.vus]);
			} else {
				addStepRampUp(
					population,
					points,
					population.vus,
					cumulativeUsers - population.rampUp.users,
					cumulativeDuration,
					totalDuration,
				);
			}
			break;
		}

		if (hasNoStepRampUp(population)) {
			points.push([cumulativeDuration, cumulativeUsers]);
		} else {
			addStepRampUp(
				population,
				points,
				cumulativeUsers,
				cumulativeUsers - population.rampUp.users,
				cumulativeDuration,
				totalDuration,
			);
		}
	}

	addFinalPoint(points, totalDuration, population, population.vus);

	return points;
};

const addFinalPoint = (points: Point[], totalDuration: number, population: CustomPopulation, maxVus: number) => {
	const lastIndex = points.at(-1);
	if (lastIndex === undefined) {
		return;
	}

	// current vu is max vu
	if (lastIndex[1] === maxVus) {
		points.push([totalDuration, population.vus]);
	}
};

export const generatePointsForPopulation = (population: CustomPopulation) =>
	population.vuDistribution === VuDistributions.CONSTANT
		? generatePointsForConstantLoad(population)
		: generatePointsForRampUpLoad(population);

import Box from '@mui/material/Box';
import SectionTitle from '@tricentis/aura/components/SectionTitle.js';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { useState } from 'react';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { WorkspacesDeleteDialog } from './workspaces-delete-dialog';
import { WorkspaceFormModal } from './workspace-form-modal';
import { MembersCount } from './members/members-count';
import { styleFlexCenter } from '../results/details/styles-utils';
import { Workspace } from '@neoload/api';
import { WorkspaceRoutes } from '@neoload/utils';
import { useGetMe } from '@neoload/hooks';

const defaultWorkspaceId = '5e3acde2e860a132744ca916';

export type WorkspaceHeaderProps = {
	workspace: Pick<Workspace, 'id' | 'name' | 'description' | 'enabledForAllUsers'>;
	membersCount: number;
	onEditWorkspaceName: (name: string) => void;
	onDeleteWorkspace: (workspaceId: string) => void;
	isDeleting: boolean;
};

type DisplayedModal = 'NONE' | 'DELETE_MODAL' | 'EDIT_MODAL';

type WorkspaceModalState = {
	workspace?: Pick<Workspace, 'id' | 'name' | 'description' | 'enabledForAllUsers'>;
	displayedModal: DisplayedModal;
};

const WorkspaceHeader = ({
	workspace,
	membersCount,
	onEditWorkspaceName,
	onDeleteWorkspace,
	isDeleting,
}: WorkspaceHeaderProps) => {
	const { t } = useTranslation(['workspace']);
	const navigate = useNavigate();
	const [{ workspaces: userWorkspaces, hasAdminPermissions }] = useGetMe();

	const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
	const menuOpen = Boolean(menuAnchorEl);
	const [workspaceModalState, setWorkspaceModalState] = useState<WorkspaceModalState>({
		displayedModal: 'NONE',
	});

	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setMenuAnchorEl(null);
	};

	const openDeleteModal = () => {
		setWorkspaceModalState({
			displayedModal: 'DELETE_MODAL',
			workspace: workspace,
		});
		handleCloseMenu();
	};

	const openEditModal = () => {
		setWorkspaceModalState({
			displayedModal: 'EDIT_MODAL',
			workspace: workspace,
		});
		handleCloseMenu();
	};

	const closeModal = () =>
		setWorkspaceModalState({
			displayedModal: 'NONE',
		});

	const onWorkspaceDialogSubmit = (workspaceId: string): void => {
		onDeleteWorkspace(workspaceId);
		closeModal();
	};

	const isMember = (workspaceId: string) => userWorkspaces.some((userWorkspace) => userWorkspace.id === workspaceId);

	return (
		<>
			<Grid container overflow='hidden' wrap='nowrap' padding={2} data-testid='result-header'>
				<Grid item xs='auto' sx={styleFlexCenter}>
					<Box sx={{ maxWidth: '1000px' }}>
						<SectionTitle
							onClickBack={(_event) => navigate(WorkspaceRoutes.base, { replace: true })}
							title={workspace.name}
							description={workspace.description}
							label={t('header.name')}
							editable={hasAdminPermissions}
							slotProps={{
								input: {
									onApply: (editedName: Record<string, string>) => {
										onEditWorkspaceName(editedName.source);
									},
								},
							}}
						/>
					</Box>
				</Grid>

				<Grid item xs />
				<Grid item xs='auto' sx={styleFlexCenter}>
					{hasAdminPermissions && (
						<MembersCount enabledForAllUsers={workspace.enabledForAllUsers} membersCount={membersCount} />
					)}
					{hasAdminPermissions && (
						<Tooltip arrow title={t('header.menu.tooltip')}>
							<IconButton
								onClick={handleMenuClick}
								data-testid='workspace-header-menu-button'
								aria-label={t('header.menu.button')}
							>
								<MoreVertOutlined />
							</IconButton>
						</Tooltip>
					)}
					<Menu
						open={menuOpen}
						anchorEl={menuAnchorEl}
						onClose={handleCloseMenu}
						data-testid='workspace-header-menu'
						sx={{ marginTop: 1 }}
					>
						<MenuItem onClick={openEditModal} data-testid='edit-workspace'>
							<ListItemIcon>
								<EditOutlined />
							</ListItemIcon>
							<ListItemText>{t('header.menu.edit', { count: 1 })}</ListItemText>
						</MenuItem>
						<MenuItem
							sx={(theme) => ({ color: theme.palette.error.main })}
							onClick={openDeleteModal}
							data-testid='delete-workspace'
							disabled={workspace.id === defaultWorkspaceId}
						>
							<ListItemIcon>
								<DeleteOutlined sx={(theme) => ({ color: theme.palette.error.main })} />
							</ListItemIcon>
							<ListItemText>{t('header.menu.delete')}</ListItemText>
						</MenuItem>
					</Menu>
				</Grid>
			</Grid>
			{workspaceModalState.workspace && (
				<WorkspacesDeleteDialog
					workspace={workspaceModalState.workspace}
					isMember={isMember(workspaceModalState.workspace.id)}
					isOpen={workspaceModalState.displayedModal === 'DELETE_MODAL'}
					isDeleting={isDeleting}
					onSubmit={onWorkspaceDialogSubmit}
					onCancel={closeModal}
				/>
			)}
			{workspaceModalState.workspace && (
				<WorkspaceFormModal
					opened={workspaceModalState.displayedModal === 'EDIT_MODAL'}
					closeModal={closeModal}
					editedWorkspace={workspaceModalState.workspace}
				/>
			)}
		</>
	);
};

export { WorkspaceHeader };

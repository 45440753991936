/* eslint-disable @typescript-eslint/naming-convention */
import Highcharts from 'highcharts';
import { Theme } from '@mui/material/styles';
import { i18n } from '../../../i18n';

const oneDay = 24 * 3600 * 1000;
const customFormatDurationDays = (timestamp: number): string => {
	if (timestamp < oneDay) {
		return '';
	}
	const days = Math.floor(timestamp / oneDay);
	return i18n.t('common:time.day', { count: days }) + ' ';
};
Highcharts.dateFormats.D = customFormatDurationDays;

export const buildTitleText = (leftSerieName: string, rightSerieName: string, theme: Theme): string => {
	const font = `font-family:${theme.typography.subtitle2.fontFamily};font-size:${theme.typography.subtitle2.fontSize};font-weight:${theme.typography.fontWeightMedium};color:${theme.palette.text.primary}`;
	return `<span style="${font};float:left">${leftSerieName}</span><span style="${font};float:right">${rightSerieName}</span>`;
};
export const tooltipIconSquare = '<span style="color:{point.color}">■</span>';
export const tooltipIconCircle = '<span style="color:{point.color}">●</span>';
export const axisLabelStyle = (theme: Theme, useHtml = false): Highcharts.XAxisLabelsOptions => ({
	style: {
		fontFamily: theme.typography.caption.fontFamily,
		fontSize: theme.typography.caption.fontSize?.toString(),
		color: theme.palette.text.secondary,
	},
	useHTML: useHtml,
});
export const body2Style = (theme: Theme): Highcharts.CSSObject => ({
	fontFamily: theme.typography.body2.fontFamily,
	fontSize: theme.typography.body2.fontSize?.toString(),
	color: theme.palette.text.secondary,
});

export const getCommonChartOptions = (theme: Theme, useHtmlForXAxis = false): Highcharts.Options => ({
	chart: {
		animation: { duration: 100 },
		backgroundColor: 'rgba(0,0,0,0)',
	},
	xAxis: {
		crosshair: true,
		type: 'datetime',
		dateTimeLabelFormats: {
			second: '%M:%S',
			minute: '%k:%M:%S',
			hour: '%D%k:%M:%S',
			day: '%D%k:%M:%S',
			week: '%D%k:%M:%S',
		},
		labels: axisLabelStyle(theme, useHtmlForXAxis),
		min: 0,
		lineColor: theme.palette.text.disabled,
		tickColor: theme.palette.text.disabled,
	},
	tooltip: {
		shared: true,
		hideDelay: 0,
		style: body2Style(theme),
		useHTML: true,
		padding: 12,
		headerFormat: '<span>{point.key:%D%k:%M:%S}</span><br/>',
		backgroundColor: theme.palette.background.paper,
	},
	legend: {
		itemStyle: body2Style(theme),
		itemHoverStyle: {
			color: theme.palette.text.primary,
		},
		itemHiddenStyle: {
			color: theme.palette.text.disabled,
		},
		margin: 0,
		useHTML: true,
	},
	noData: {
		style: body2Style(theme),
	},
	loading: {
		style: body2Style(theme),
	},
	lang: {
		locale: i18n.language,
		noData: i18n.t('common:chart.noData'),
		loading: i18n.t('common:chart.loading'),
	},
	credits: { enabled: false },
	exporting: {
		buttons: {
			contextButton: {
				enabled: false,
			},
		},
	},
});

export const visibleForTesting = { customFormatDurationDays };

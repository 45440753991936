import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { SeriesListItem } from '../series-list-item';
import { DashboardSeries } from '@neoload/api';

type SeriesListAccordionProps = {
	seriesList: DashboardSeries[];
};

export const SeriesListAccordion = ({ seriesList }: SeriesListAccordionProps) => {
	const { t } = useTranslation(['dashboard']);

	return (
		<Accordion disableGutters defaultExpanded={true} elevation={0}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>{t('tile.edition.sections.series')}</AccordionSummary>
			<AccordionDetails>
				<Stack spacing={1}>
					{seriesList.map((series) => (
						<SeriesListItem series={series} key={series.id} />
					))}
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};

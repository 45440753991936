import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { ComponentProps, forwardRef } from 'react';

type ProfileTextFieldProps = { loading: boolean } & ComponentProps<typeof TextField>;

const ProfileTextField = forwardRef<HTMLDivElement, ProfileTextFieldProps>(
	({ loading, ...textFieldProps }: ProfileTextFieldProps, ref) => (
		<Stack
			spacing={0.5}
			sx={(theme) => ({
				padding: `${theme.spacing(0.5)} 0;`,
			})}
		>
			{loading ? <Skeleton /> : <TextField {...textFieldProps} ref={ref} />}
		</Stack>
	)
);

export { ProfileTextField };

import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSetSnackbars } from '@neoload/hooks';

type ZoneIdWithCopyButtonProps = {
	zoneId: string;
};
const ZoneIdWithCopyButton = ({ zoneId }: ZoneIdWithCopyButtonProps) => {
	const { t } = useTranslation(['zone']);
	const { showInfo } = useSetSnackbars();
	const copyToClipboard = () =>
		navigator.clipboard.writeText(zoneId).then(() => {
			showInfo({ text: t('details.clipboardSuccess') });
		});

	return (
		<>
			<Typography variant='body2' display='inline'>
				{zoneId}
			</Typography>
			<Tooltip arrow title={t('common:clipboard')}>
				<IconButton onClick={copyToClipboard} sx={{ padding: 0, marginLeft: 2 }}>
					<ContentCopyOutlined />
				</IconButton>
			</Tooltip>
		</>
	);
};

export { ZoneIdWithCopyButton };

import { emptySplitApiV4 as api } from './api-v4';
const injectedRtkApi = api.injectEndpoints({
	endpoints: (build) => ({
		postV4Checkouts: build.mutation<PostV4CheckoutsApiResponse, PostV4CheckoutsApiArg>({
			query: (queryArg) => ({ url: `/v4/checkouts`, method: 'POST', body: queryArg.checkoutInput }),
		}),
		getV4CheckoutsByCheckoutId: build.query<GetV4CheckoutsByCheckoutIdApiResponse, GetV4CheckoutsByCheckoutIdApiArg>({
			query: (queryArg) => ({ url: `/v4/checkouts/${queryArg.checkoutId}` }),
		}),
		postV4DashboardsByDashboardIdTilesAndTileIdSeries: build.mutation<
			PostV4DashboardsByDashboardIdTilesAndTileIdSeriesApiResponse,
			PostV4DashboardsByDashboardIdTilesAndTileIdSeriesApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/dashboards/${queryArg.dashboardId}/tiles/${queryArg.tileId}/series`,
				method: 'POST',
				body: queryArg.dashboardSeriesPostRequest,
			}),
		}),
		deleteV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesId: build.mutation<
			DeleteV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdApiResponse,
			DeleteV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/dashboards/${queryArg.dashboardId}/tiles/${queryArg.tileId}/series/${queryArg.seriesId}`,
				method: 'DELETE',
			}),
		}),
		patchV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesId: build.mutation<
			PatchV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdApiResponse,
			PatchV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/dashboards/${queryArg.dashboardId}/tiles/${queryArg.tileId}/series/${queryArg.seriesId}`,
				method: 'PATCH',
				body: queryArg.dashboardSeriesPatchRequest,
			}),
		}),
		getV4DashboardsByDashboardIdTiles: build.query<
			GetV4DashboardsByDashboardIdTilesApiResponse,
			GetV4DashboardsByDashboardIdTilesApiArg
		>({
			query: (queryArg) => ({ url: `/v4/dashboards/${queryArg.dashboardId}/tiles` }),
		}),
		postV4DashboardsByDashboardIdTiles: build.mutation<
			PostV4DashboardsByDashboardIdTilesApiResponse,
			PostV4DashboardsByDashboardIdTilesApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/dashboards/${queryArg.dashboardId}/tiles`,
				method: 'POST',
				body: queryArg.dashboardTilesPostOneRequest,
			}),
		}),
		deleteV4DashboardsByDashboardIdTiles: build.mutation<
			DeleteV4DashboardsByDashboardIdTilesApiResponse,
			DeleteV4DashboardsByDashboardIdTilesApiArg
		>({
			query: (queryArg) => ({ url: `/v4/dashboards/${queryArg.dashboardId}/tiles`, method: 'DELETE' }),
		}),
		patchV4DashboardsByDashboardIdTiles: build.mutation<
			PatchV4DashboardsByDashboardIdTilesApiResponse,
			PatchV4DashboardsByDashboardIdTilesApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/dashboards/${queryArg.dashboardId}/tiles`,
				method: 'PATCH',
				body: queryArg.dashboardTilesPatchAllRequest,
			}),
		}),
		deleteV4DashboardsByDashboardIdTilesAndDashboardTileId: build.mutation<
			DeleteV4DashboardsByDashboardIdTilesAndDashboardTileIdApiResponse,
			DeleteV4DashboardsByDashboardIdTilesAndDashboardTileIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/dashboards/${queryArg.dashboardId}/tiles/${queryArg.dashboardTileId}`,
				method: 'DELETE',
			}),
		}),
		patchV4DashboardsByDashboardIdTilesAndDashboardTileId: build.mutation<
			PatchV4DashboardsByDashboardIdTilesAndDashboardTileIdApiResponse,
			PatchV4DashboardsByDashboardIdTilesAndDashboardTileIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/dashboards/${queryArg.dashboardId}/tiles/${queryArg.dashboardTileId}`,
				method: 'PATCH',
				body: queryArg.dashboardTilesPatchOneRequest,
			}),
		}),
		getV4Dashboards: build.query<GetV4DashboardsApiResponse, GetV4DashboardsApiArg>({
			query: (queryArg) => ({
				url: `/v4/dashboards`,
				params: { workspaceId: queryArg.workspaceId, resultId: queryArg.resultId, sort: queryArg.sort },
			}),
		}),
		postV4Dashboards: build.mutation<PostV4DashboardsApiResponse, PostV4DashboardsApiArg>({
			query: (queryArg) => ({ url: `/v4/dashboards`, method: 'POST', body: queryArg.dashboardInput }),
		}),
		getV4DashboardsByDashboardId: build.query<
			GetV4DashboardsByDashboardIdApiResponse,
			GetV4DashboardsByDashboardIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/dashboards/${queryArg.dashboardId}` }),
		}),
		deleteV4DashboardsByDashboardId: build.mutation<
			DeleteV4DashboardsByDashboardIdApiResponse,
			DeleteV4DashboardsByDashboardIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/dashboards/${queryArg.dashboardId}`, method: 'DELETE' }),
		}),
		patchV4DashboardsByDashboardId: build.mutation<
			PatchV4DashboardsByDashboardIdApiResponse,
			PatchV4DashboardsByDashboardIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/dashboards/${queryArg.dashboardId}`,
				method: 'PATCH',
				body: queryArg.dashboardPatchRequest,
			}),
		}),
		postV4DashboardsByDashboardIdDuplication: build.mutation<
			PostV4DashboardsByDashboardIdDuplicationApiResponse,
			PostV4DashboardsByDashboardIdDuplicationApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/dashboards/${queryArg.dashboardId}/duplication`,
				method: 'POST',
				body: queryArg.dashboardDuplicateRequest,
			}),
		}),
		getV4DashboardsByDashboardIdPdf: build.query<
			GetV4DashboardsByDashboardIdPdfApiResponse,
			GetV4DashboardsByDashboardIdPdfApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/dashboards/${queryArg.dashboardId}/pdf`,
				params: { locale: queryArg.locale, timezone: queryArg.timezone },
			}),
		}),
		postV4DashboardsByDashboardIdPublicToken: build.mutation<
			PostV4DashboardsByDashboardIdPublicTokenApiResponse,
			PostV4DashboardsByDashboardIdPublicTokenApiArg
		>({
			query: (queryArg) => ({ url: `/v4/dashboards/${queryArg.dashboardId}/public-token`, method: 'POST' }),
		}),
		deleteV4DashboardsByDashboardIdPublicToken: build.mutation<
			DeleteV4DashboardsByDashboardIdPublicTokenApiResponse,
			DeleteV4DashboardsByDashboardIdPublicTokenApiArg
		>({
			query: (queryArg) => ({ url: `/v4/dashboards/${queryArg.dashboardId}/public-token`, method: 'DELETE' }),
		}),
		getV4DeletionPolicies: build.query<GetV4DeletionPoliciesApiResponse, GetV4DeletionPoliciesApiArg>({
			query: (queryArg) => ({ url: `/v4/deletion-policies`, params: { workspaceId: queryArg.workspaceId } }),
		}),
		postV4DeletionPolicies: build.mutation<PostV4DeletionPoliciesApiResponse, PostV4DeletionPoliciesApiArg>({
			query: (queryArg) => ({ url: `/v4/deletion-policies`, method: 'POST', body: queryArg.postDeletionPolicyFields }),
		}),
		postV4DeletionPoliciesExecution: build.mutation<
			PostV4DeletionPoliciesExecutionApiResponse,
			PostV4DeletionPoliciesExecutionApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/deletion-policies/execution`,
				method: 'POST',
				body: queryArg.postDeletionPolicyFields,
				params: { mode: queryArg.mode },
			}),
		}),
		getV4DeletionPoliciesByDeletionPolicyId: build.query<
			GetV4DeletionPoliciesByDeletionPolicyIdApiResponse,
			GetV4DeletionPoliciesByDeletionPolicyIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/deletion-policies/${queryArg.deletionPolicyId}` }),
		}),
		deleteV4DeletionPoliciesByDeletionPolicyId: build.mutation<
			DeleteV4DeletionPoliciesByDeletionPolicyIdApiResponse,
			DeleteV4DeletionPoliciesByDeletionPolicyIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/deletion-policies/${queryArg.deletionPolicyId}`, method: 'DELETE' }),
		}),
		patchV4DeletionPoliciesByDeletionPolicyId: build.mutation<
			PatchV4DeletionPoliciesByDeletionPolicyIdApiResponse,
			PatchV4DeletionPoliciesByDeletionPolicyIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/deletion-policies/${queryArg.deletionPolicyId}`,
				method: 'PATCH',
				body: queryArg.patchDeletionPolicyFields,
			}),
		}),
		getV4InfrastructureProviders: build.query<
			GetV4InfrastructureProvidersApiResponse,
			GetV4InfrastructureProvidersApiArg
		>({
			query: () => ({ url: `/v4/infrastructure-providers` }),
		}),
		postV4InfrastructureProviders: build.mutation<
			PostV4InfrastructureProvidersApiResponse,
			PostV4InfrastructureProvidersApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/infrastructure-providers`,
				method: 'POST',
				body: queryArg.createProviderRequest,
				params: { dryRun: queryArg.dryRun },
			}),
		}),
		deleteV4InfrastructureProvidersById: build.mutation<
			DeleteV4InfrastructureProvidersByIdApiResponse,
			DeleteV4InfrastructureProvidersByIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/infrastructure-providers/${queryArg.id}`, method: 'DELETE' }),
		}),
		patchV4InfrastructureProvidersById: build.mutation<
			PatchV4InfrastructureProvidersByIdApiResponse,
			PatchV4InfrastructureProvidersByIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/infrastructure-providers/${queryArg.id}`,
				method: 'PATCH',
				body: queryArg.patchProviderRequest,
				params: { dryRun: queryArg.dryRun },
			}),
		}),
		getV4License: build.query<GetV4LicenseApiResponse, GetV4LicenseApiArg>({
			query: () => ({ url: `/v4/license` }),
		}),
		postV4License: build.mutation<PostV4LicenseApiResponse, PostV4LicenseApiArg>({
			query: (queryArg) => ({ url: `/v4/license`, method: 'POST', body: queryArg.body }),
		}),
		postV4LicenseActivationRequests: build.mutation<
			PostV4LicenseActivationRequestsApiResponse,
			PostV4LicenseActivationRequestsApiArg
		>({
			query: () => ({ url: `/v4/license/activation-requests`, method: 'POST' }),
		}),
		postV4LicenseDeactivationRequests: build.mutation<
			PostV4LicenseDeactivationRequestsApiResponse,
			PostV4LicenseDeactivationRequestsApiArg
		>({
			query: () => ({ url: `/v4/license/deactivation-requests`, method: 'POST' }),
		}),
		postV4LicenseForcedReleases: build.mutation<
			PostV4LicenseForcedReleasesApiResponse,
			PostV4LicenseForcedReleasesApiArg
		>({
			query: (queryArg) => ({ url: `/v4/license/forced-releases`, method: 'POST', body: queryArg.postForcedRelease }),
		}),
		getV4LicenseLeases: build.query<GetV4LicenseLeasesApiResponse, GetV4LicenseLeasesApiArg>({
			query: (queryArg) => ({ url: `/v4/license/leases`, params: { workspaceId: queryArg.workspaceId } }),
		}),
		postV4LicenseLeases: build.mutation<PostV4LicenseLeasesApiResponse, PostV4LicenseLeasesApiArg>({
			query: (queryArg) => ({ url: `/v4/license/leases`, method: 'POST', body: queryArg.postOfflineLease }),
		}),
		getV4LicenseLeasesByLeaseIdentifier: build.query<
			GetV4LicenseLeasesByLeaseIdentifierApiResponse,
			GetV4LicenseLeasesByLeaseIdentifierApiArg
		>({
			query: (queryArg) => ({ url: `/v4/license/leases/${queryArg.leaseIdentifier}` }),
		}),
		postV4LicenseReleases: build.mutation<PostV4LicenseReleasesApiResponse, PostV4LicenseReleasesApiArg>({
			query: (queryArg) => ({ url: `/v4/license/releases`, method: 'POST', body: queryArg.body }),
		}),
		getV4Me: build.query<GetV4MeApiResponse, GetV4MeApiArg>({
			query: () => ({ url: `/v4/me` }),
		}),
		patchV4Me: build.mutation<PatchV4MeApiResponse, PatchV4MeApiArg>({
			query: (queryArg) => ({ url: `/v4/me`, method: 'PATCH', body: queryArg.patchMeRequest }),
		}),
		getV4MeFeatures: build.query<GetV4MeFeaturesApiResponse, GetV4MeFeaturesApiArg>({
			query: () => ({ url: `/v4/me/features` }),
		}),
		putV4MePassword: build.mutation<PutV4MePasswordApiResponse, PutV4MePasswordApiArg>({
			query: (queryArg) => ({ url: `/v4/me/password`, method: 'PUT', body: queryArg.putPasswordRequest }),
		}),
		getV4MePreferences: build.query<GetV4MePreferencesApiResponse, GetV4MePreferencesApiArg>({
			query: () => ({ url: `/v4/me/preferences` }),
		}),
		patchV4MePreferences: build.mutation<PatchV4MePreferencesApiResponse, PatchV4MePreferencesApiArg>({
			query: (queryArg) => ({ url: `/v4/me/preferences`, method: 'PATCH', body: queryArg.patchPreferencesRequest }),
		}),
		getV4MeTokens: build.query<GetV4MeTokensApiResponse, GetV4MeTokensApiArg>({
			query: () => ({ url: `/v4/me/tokens` }),
		}),
		postV4MeTokens: build.mutation<PostV4MeTokensApiResponse, PostV4MeTokensApiArg>({
			query: (queryArg) => ({ url: `/v4/me/tokens`, method: 'POST', body: queryArg.createTokenRequest }),
		}),
		deleteV4MeTokensByToken: build.mutation<DeleteV4MeTokensByTokenApiResponse, DeleteV4MeTokensByTokenApiArg>({
			query: (queryArg) => ({ url: `/v4/me/tokens/${queryArg.token}`, method: 'DELETE' }),
		}),
		getV4Reservations: build.query<GetV4ReservationsApiResponse, GetV4ReservationsApiArg>({
			query: (queryArg) => ({
				url: `/v4/reservations`,
				params: { searchInterval: queryArg.searchInterval, status: queryArg.status, workspaceId: queryArg.workspaceId },
			}),
		}),
		postV4Reservations: build.mutation<PostV4ReservationsApiResponse, PostV4ReservationsApiArg>({
			query: (queryArg) => ({ url: `/v4/reservations`, method: 'POST', body: queryArg.body }),
		}),
		getV4ReservationsByReservationId: build.query<
			GetV4ReservationsByReservationIdApiResponse,
			GetV4ReservationsByReservationIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/reservations/${queryArg.reservationId}` }),
		}),
		deleteV4ReservationsByReservationId: build.mutation<
			DeleteV4ReservationsByReservationIdApiResponse,
			DeleteV4ReservationsByReservationIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/reservations/${queryArg.reservationId}`, method: 'DELETE' }),
		}),
		patchV4ReservationsByReservationId: build.mutation<
			PatchV4ReservationsByReservationIdApiResponse,
			PatchV4ReservationsByReservationIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/reservations/${queryArg.reservationId}`,
				method: 'PATCH',
				body: queryArg.patchReservation,
			}),
		}),
		getV4ResultsByResultIdElements: build.query<
			GetV4ResultsByResultIdElementsApiResponse,
			GetV4ResultsByResultIdElementsApiArg
		>({
			query: (queryArg) => ({ url: `/v4/results/${queryArg.resultId}/elements` }),
		}),
		getV4ResultsByResultIdElementsValues: build.query<
			GetV4ResultsByResultIdElementsValuesApiResponse,
			GetV4ResultsByResultIdElementsValuesApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/elements/values`,
				params: {
					elementIds: queryArg.elementIds,
					elementType: queryArg.elementType,
					intervalId: queryArg.intervalId,
					pageNumber: queryArg.pageNumber,
					pageSize: queryArg.pageSize,
					population: queryArg.population,
					sort: queryArg.sort,
					userPath: queryArg.userPath,
					zone: queryArg.zone,
				},
			}),
		}),
		getV4ResultsByResultIdElementsAndElementIdPercentiles: build.query<
			GetV4ResultsByResultIdElementsAndElementIdPercentilesApiResponse,
			GetV4ResultsByResultIdElementsAndElementIdPercentilesApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/elements/${queryArg.elementId}/percentiles`,
				params: { intervalId: queryArg.intervalId, userPathId: queryArg.userPathId },
			}),
		}),
		getV4ResultsByResultIdElementsAndElementIdTimeseries: build.query<
			GetV4ResultsByResultIdElementsAndElementIdTimeseriesApiResponse,
			GetV4ResultsByResultIdElementsAndElementIdTimeseriesApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/elements/${queryArg.elementId}/timeseries`,
				params: {
					statistics: queryArg.statistics,
					intervalId: queryArg.intervalId,
					requestToken: queryArg.requestToken,
					userPathId: queryArg.userPathId,
				},
			}),
		}),
		getV4ResultsByResultIdEvents: build.query<
			GetV4ResultsByResultIdEventsApiResponse,
			GetV4ResultsByResultIdEventsApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/events`,
				params: {
					fromOffset: queryArg.fromOffset,
					pageNumber: queryArg.pageNumber,
					pageSize: queryArg.pageSize,
					sort: queryArg.sort,
					toOffset: queryArg.toOffset,
					types: queryArg.types,
				},
			}),
		}),
		postV4ResultsByResultIdEvents: build.mutation<
			PostV4ResultsByResultIdEventsApiResponse,
			PostV4ResultsByResultIdEventsApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/events`,
				method: 'POST',
				body: queryArg.storeEventInput,
			}),
		}),
		getV4ResultsByResultIdEventsContentsAndContentId: build.query<
			GetV4ResultsByResultIdEventsContentsAndContentIdApiResponse,
			GetV4ResultsByResultIdEventsContentsAndContentIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/events/contents/${queryArg.contentId}`,
				params: { contentLimit: queryArg.contentLimit },
			}),
		}),
		getV4ResultsByResultIdEventsErrors: build.query<
			GetV4ResultsByResultIdEventsErrorsApiResponse,
			GetV4ResultsByResultIdEventsErrorsApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/events/errors`,
				params: {
					errorsType: queryArg.errorsType,
					pageNumber: queryArg.pageNumber,
					pageSize: queryArg.pageSize,
					sort: queryArg.sort,
				},
			}),
		}),
		getV4ResultsByResultIdEventsAndEventId: build.query<
			GetV4ResultsByResultIdEventsAndEventIdApiResponse,
			GetV4ResultsByResultIdEventsAndEventIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/results/${queryArg.resultId}/events/${queryArg.eventId}` }),
		}),
		deleteV4ResultsByResultIdEventsAndEventId: build.mutation<
			DeleteV4ResultsByResultIdEventsAndEventIdApiResponse,
			DeleteV4ResultsByResultIdEventsAndEventIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/results/${queryArg.resultId}/events/${queryArg.eventId}`, method: 'DELETE' }),
		}),
		patchV4ResultsByResultIdEventsAndEventId: build.mutation<
			PatchV4ResultsByResultIdEventsAndEventIdApiResponse,
			PatchV4ResultsByResultIdEventsAndEventIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/events/${queryArg.eventId}`,
				method: 'PATCH',
				body: queryArg.patchEventInput,
			}),
		}),
		getV4ResultsByResultIdIntervals: build.query<
			GetV4ResultsByResultIdIntervalsApiResponse,
			GetV4ResultsByResultIdIntervalsApiArg
		>({
			query: (queryArg) => ({ url: `/v4/results/${queryArg.resultId}/intervals` }),
		}),
		postV4ResultsByResultIdIntervals: build.mutation<
			PostV4ResultsByResultIdIntervalsApiResponse,
			PostV4ResultsByResultIdIntervalsApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/intervals`,
				method: 'POST',
				body: queryArg.intervalPostRequest,
				params: { ignoreIncompatibilities: queryArg.ignoreIncompatibilities },
			}),
		}),
		deleteV4ResultsByResultIdIntervalsAndIntervalId: build.mutation<
			DeleteV4ResultsByResultIdIntervalsAndIntervalIdApiResponse,
			DeleteV4ResultsByResultIdIntervalsAndIntervalIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/intervals/${queryArg.intervalId}`,
				method: 'DELETE',
			}),
		}),
		patchV4ResultsByResultIdIntervalsAndIntervalId: build.mutation<
			PatchV4ResultsByResultIdIntervalsAndIntervalIdApiResponse,
			PatchV4ResultsByResultIdIntervalsAndIntervalIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/intervals/${queryArg.intervalId}`,
				method: 'PATCH',
				body: queryArg.intervalPatchRequest,
				params: { ignoreIncompatibilities: queryArg.ignoreIncompatibilities },
			}),
		}),
		getV4ResultsByResultIdLogs: build.query<GetV4ResultsByResultIdLogsApiResponse, GetV4ResultsByResultIdLogsApiArg>({
			query: (queryArg) => ({ url: `/v4/results/${queryArg.resultId}/logs`, params: { sort: queryArg.sort } }),
		}),
		getV4ResultsByResultIdMonitors: build.query<
			GetV4ResultsByResultIdMonitorsApiResponse,
			GetV4ResultsByResultIdMonitorsApiArg
		>({
			query: (queryArg) => ({ url: `/v4/results/${queryArg.resultId}/monitors` }),
		}),
		getV4ResultsByResultIdMonitorsValues: build.query<
			GetV4ResultsByResultIdMonitorsValuesApiResponse,
			GetV4ResultsByResultIdMonitorsValuesApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/monitors/values`,
				params: { intervalId: queryArg.intervalId, monitorIds: queryArg.monitorIds },
			}),
		}),
		getV4ResultsByResultIdMonitorsAndMonitorIdTimeseries: build.query<
			GetV4ResultsByResultIdMonitorsAndMonitorIdTimeseriesApiResponse,
			GetV4ResultsByResultIdMonitorsAndMonitorIdTimeseriesApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/monitors/${queryArg.monitorId}/timeseries`,
				params: { intervalId: queryArg.intervalId, requestToken: queryArg.requestToken },
			}),
		}),
		getV4ResultsByResultIdSlas: build.query<GetV4ResultsByResultIdSlasApiResponse, GetV4ResultsByResultIdSlasApiArg>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/slas`,
				params: {
					slaType: queryArg.slaType,
					elementType: queryArg.elementType,
					pageNumber: queryArg.pageNumber,
					pageSize: queryArg.pageSize,
					slaStatus: queryArg.slaStatus,
					sort: queryArg.sort,
				},
			}),
		}),
		getV4ResultsByResultIdStatistics: build.query<
			GetV4ResultsByResultIdStatisticsApiResponse,
			GetV4ResultsByResultIdStatisticsApiArg
		>({
			query: (queryArg) => ({ url: `/v4/results/${queryArg.resultId}/statistics` }),
		}),
		getV4ResultsByResultIdTimeseries: build.query<
			GetV4ResultsByResultIdTimeseriesApiResponse,
			GetV4ResultsByResultIdTimeseriesApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}/timeseries`,
				params: {
					series: queryArg.series,
					fixedWindowDuration: queryArg.fixedWindowDuration,
					intervalId: queryArg.intervalId,
					requestToken: queryArg.requestToken,
				},
			}),
		}),
		postV4ResultsByResultIdIntervalGeneration: build.mutation<
			PostV4ResultsByResultIdIntervalGenerationApiResponse,
			PostV4ResultsByResultIdIntervalGenerationApiArg
		>({
			query: (queryArg) => ({ url: `/v4/results/${queryArg.resultId}/interval-generation`, method: 'POST' }),
		}),
		getV4Results: build.query<GetV4ResultsApiResponse, GetV4ResultsApiArg>({
			query: (queryArg) => ({
				url: `/v4/results`,
				params: {
					workspaceId: queryArg.workspaceId,
					nameOrDescription: queryArg.nameOrDescription,
					pageNumber: queryArg.pageNumber,
					pageSize: queryArg.pageSize,
					projectNames: queryArg.projectNames,
					sort: queryArg.sort,
					startedBefore: queryArg.startedBefore,
					status: queryArg.status,
					stoppedAfter: queryArg.stoppedAfter,
					testIds: queryArg.testIds,
				},
			}),
		}),
		getV4ResultsSearchCriteria: build.query<GetV4ResultsSearchCriteriaApiResponse, GetV4ResultsSearchCriteriaApiArg>({
			query: (queryArg) => ({ url: `/v4/results/search-criteria`, params: { workspaceId: queryArg.workspaceId } }),
		}),
		getV4ResultsByResultId: build.query<GetV4ResultsByResultIdApiResponse, GetV4ResultsByResultIdApiArg>({
			query: (queryArg) => ({ url: `/v4/results/${queryArg.resultId}` }),
		}),
		deleteV4ResultsByResultId: build.mutation<DeleteV4ResultsByResultIdApiResponse, DeleteV4ResultsByResultIdApiArg>({
			query: (queryArg) => ({ url: `/v4/results/${queryArg.resultId}`, method: 'DELETE' }),
		}),
		patchV4ResultsByResultId: build.mutation<PatchV4ResultsByResultIdApiResponse, PatchV4ResultsByResultIdApiArg>({
			query: (queryArg) => ({
				url: `/v4/results/${queryArg.resultId}`,
				method: 'PATCH',
				body: queryArg.testResultInput,
			}),
		}),
		getV4ResultsByResultIdContexts: build.query<
			GetV4ResultsByResultIdContextsApiResponse,
			GetV4ResultsByResultIdContextsApiArg
		>({
			query: (queryArg) => ({ url: `/v4/results/${queryArg.resultId}/contexts` }),
		}),
		postV4ResultsByResultIdReport: build.mutation<
			PostV4ResultsByResultIdReportApiResponse,
			PostV4ResultsByResultIdReportApiArg
		>({
			query: (queryArg) => ({ url: `/v4/results/${queryArg.resultId}/report`, method: 'POST' }),
		}),
		getV4ScmRepositories: build.query<GetV4ScmRepositoriesApiResponse, GetV4ScmRepositoriesApiArg>({
			query: () => ({ url: `/v4/scm-repositories` }),
		}),
		postV4ScmRepositories: build.mutation<PostV4ScmRepositoriesApiResponse, PostV4ScmRepositoriesApiArg>({
			query: (queryArg) => ({ url: `/v4/scm-repositories`, method: 'POST', body: queryArg.createRepository }),
		}),
		getV4ScmRepositoriesByRepositoryId: build.query<
			GetV4ScmRepositoriesByRepositoryIdApiResponse,
			GetV4ScmRepositoriesByRepositoryIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/scm-repositories/${queryArg.repositoryId}` }),
		}),
		deleteV4ScmRepositoriesByRepositoryId: build.mutation<
			DeleteV4ScmRepositoriesByRepositoryIdApiResponse,
			DeleteV4ScmRepositoriesByRepositoryIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/scm-repositories/${queryArg.repositoryId}`, method: 'DELETE' }),
		}),
		patchV4ScmRepositoriesByRepositoryId: build.mutation<
			PatchV4ScmRepositoriesByRepositoryIdApiResponse,
			PatchV4ScmRepositoriesByRepositoryIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/scm-repositories/${queryArg.repositoryId}`,
				method: 'PATCH',
				body: queryArg.updateRepository,
			}),
		}),
		getV4ScmRepositoriesByRepositoryIdReferences: build.query<
			GetV4ScmRepositoriesByRepositoryIdReferencesApiResponse,
			GetV4ScmRepositoriesByRepositoryIdReferencesApiArg
		>({
			query: (queryArg) => ({ url: `/v4/scm-repositories/${queryArg.repositoryId}/references` }),
		}),
		postV4Sessions: build.mutation<PostV4SessionsApiResponse, PostV4SessionsApiArg>({
			query: (queryArg) => ({ url: `/v4/sessions`, method: 'POST', body: queryArg.createSessionRequest }),
		}),
		deleteV4Sessions: build.mutation<DeleteV4SessionsApiResponse, DeleteV4SessionsApiArg>({
			query: () => ({ url: `/v4/sessions`, method: 'DELETE' }),
		}),
		getV4Information: build.query<GetV4InformationApiResponse, GetV4InformationApiArg>({
			query: () => ({ url: `/v4/information` }),
		}),
		getV4Settings: build.query<GetV4SettingsApiResponse, GetV4SettingsApiArg>({
			query: () => ({ url: `/v4/settings` }),
		}),
		patchV4Settings: build.mutation<PatchV4SettingsApiResponse, PatchV4SettingsApiArg>({
			query: (queryArg) => ({ url: `/v4/settings`, method: 'PATCH', body: queryArg.patchSettingsInput }),
		}),
		getV4Subscription: build.query<GetV4SubscriptionApiResponse, GetV4SubscriptionApiArg>({
			query: (queryArg) => ({ url: `/v4/subscription`, params: { fields: queryArg.fields } }),
		}),
		getV4WorkspacesByWorkspaceIdSubscription: build.query<
			GetV4WorkspacesByWorkspaceIdSubscriptionApiResponse,
			GetV4WorkspacesByWorkspaceIdSubscriptionApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/workspaces/${queryArg.workspaceId}/subscription`,
				params: { fields: queryArg.fields, from: queryArg['from'], to: queryArg.to },
			}),
		}),
		postV4TestExecutions: build.mutation<PostV4TestExecutionsApiResponse, PostV4TestExecutionsApiArg>({
			query: (queryArg) => ({
				url: `/v4/test-executions`,
				method: 'POST',
				body: queryArg.testExecutionInput,
				params: { dryRun: queryArg.dryRun },
			}),
		}),
		getV4TestExecutionsByTestExecutionId: build.query<
			GetV4TestExecutionsByTestExecutionIdApiResponse,
			GetV4TestExecutionsByTestExecutionIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/test-executions/${queryArg.testExecutionId}` }),
		}),
		deleteV4TestExecutionsByTestExecutionId: build.mutation<
			DeleteV4TestExecutionsByTestExecutionIdApiResponse,
			DeleteV4TestExecutionsByTestExecutionIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/test-executions/${queryArg.testExecutionId}`, method: 'DELETE' }),
		}),
		deleteV4TestExecutionsByTestExecutionIdForced: build.mutation<
			DeleteV4TestExecutionsByTestExecutionIdForcedApiResponse,
			DeleteV4TestExecutionsByTestExecutionIdForcedApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/test-executions/${queryArg.testExecutionId}/forced`,
				method: 'DELETE',
				params: { markTerminated: queryArg.markTerminated },
			}),
		}),
		getV4Tests: build.query<GetV4TestsApiResponse, GetV4TestsApiArg>({
			query: (queryArg) => ({
				url: `/v4/tests`,
				params: {
					workspaceId: queryArg.workspaceId,
					name: queryArg.name,
					pageNumber: queryArg.pageNumber,
					pageSize: queryArg.pageSize,
					sort: queryArg.sort,
				},
			}),
		}),
		postV4Tests: build.mutation<PostV4TestsApiResponse, PostV4TestsApiArg>({
			query: (queryArg) => ({ url: `/v4/tests`, method: 'POST', body: queryArg.testInput }),
		}),
		getV4TestsByTestId: build.query<GetV4TestsByTestIdApiResponse, GetV4TestsByTestIdApiArg>({
			query: (queryArg) => ({ url: `/v4/tests/${queryArg.testId}` }),
		}),
		deleteV4TestsByTestId: build.mutation<DeleteV4TestsByTestIdApiResponse, DeleteV4TestsByTestIdApiArg>({
			query: (queryArg) => ({
				url: `/v4/tests/${queryArg.testId}`,
				method: 'DELETE',
				params: { deleteResults: queryArg.deleteResults },
			}),
		}),
		patchV4TestsByTestId: build.mutation<PatchV4TestsByTestIdApiResponse, PatchV4TestsByTestIdApiArg>({
			query: (queryArg) => ({ url: `/v4/tests/${queryArg.testId}`, method: 'PATCH', body: queryArg.patchTestInput }),
		}),
		getV4TestsByTestIdProject: build.query<GetV4TestsByTestIdProjectApiResponse, GetV4TestsByTestIdProjectApiArg>({
			query: (queryArg) => ({ url: `/v4/tests/${queryArg.testId}/project` }),
		}),
		postV4TestsByTestIdProjectPassword: build.mutation<
			PostV4TestsByTestIdProjectPasswordApiResponse,
			PostV4TestsByTestIdProjectPasswordApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/tests/${queryArg.testId}/project/password`,
				method: 'POST',
				body: queryArg.updatePasswordRequest,
			}),
		}),
		getV4TestsByTestIdScenariosAndScenarioName: build.query<
			GetV4TestsByTestIdScenariosAndScenarioNameApiResponse,
			GetV4TestsByTestIdScenariosAndScenarioNameApiArg
		>({
			query: (queryArg) => ({ url: `/v4/tests/${queryArg.testId}/scenarios/${queryArg.scenarioName}` }),
		}),
		putV4TestsByTestIdScenariosAndScenarioName: build.mutation<
			PutV4TestsByTestIdScenariosAndScenarioNameApiResponse,
			PutV4TestsByTestIdScenariosAndScenarioNameApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/tests/${queryArg.testId}/scenarios/${queryArg.scenarioName}`,
				method: 'PUT',
				body: queryArg.scenarioAsCode,
			}),
		}),
		getV4TestsByTestIdTrends: build.query<GetV4TestsByTestIdTrendsApiResponse, GetV4TestsByTestIdTrendsApiArg>({
			query: (queryArg) => ({ url: `/v4/tests/${queryArg.testId}/trends` }),
		}),
		getV4TestsByTestIdTrendsConfiguration: build.query<
			GetV4TestsByTestIdTrendsConfigurationApiResponse,
			GetV4TestsByTestIdTrendsConfigurationApiArg
		>({
			query: (queryArg) => ({ url: `/v4/tests/${queryArg.testId}/trends/configuration` }),
		}),
		putV4TestsByTestIdTrendsConfiguration: build.mutation<
			PutV4TestsByTestIdTrendsConfigurationApiResponse,
			PutV4TestsByTestIdTrendsConfigurationApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/tests/${queryArg.testId}/trends/configuration`,
				method: 'PUT',
				body: queryArg.trendsConfiguration,
				params: { dryRun: queryArg.dryRun },
			}),
		}),
		patchV4TestsByTestIdTrendsConfiguration: build.mutation<
			PatchV4TestsByTestIdTrendsConfigurationApiResponse,
			PatchV4TestsByTestIdTrendsConfigurationApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/tests/${queryArg.testId}/trends/configuration`,
				method: 'PATCH',
				body: queryArg.trendsConfigurationPatchRequest,
			}),
		}),
		getV4TestsByTestIdTrendsElements: build.query<
			GetV4TestsByTestIdTrendsElementsApiResponse,
			GetV4TestsByTestIdTrendsElementsApiArg
		>({
			query: (queryArg) => ({ url: `/v4/tests/${queryArg.testId}/trends/elements` }),
		}),
		getV4UsersByUserIdWorkspaces: build.query<
			GetV4UsersByUserIdWorkspacesApiResponse,
			GetV4UsersByUserIdWorkspacesApiArg
		>({
			query: (queryArg) => ({ url: `/v4/users/${queryArg.userId}/workspaces` }),
		}),
		putV4UsersByUserIdWorkspaces: build.mutation<
			PutV4UsersByUserIdWorkspacesApiResponse,
			PutV4UsersByUserIdWorkspacesApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/users/${queryArg.userId}/workspaces`,
				method: 'PUT',
				body: queryArg.putUserWorkspace,
			}),
		}),
		deleteV4UsersByUserIdWorkspacesAndWorkspaceId: build.mutation<
			DeleteV4UsersByUserIdWorkspacesAndWorkspaceIdApiResponse,
			DeleteV4UsersByUserIdWorkspacesAndWorkspaceIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/users/${queryArg.userId}/workspaces/${queryArg.workspaceId}`,
				method: 'DELETE',
			}),
		}),
		getV4Users: build.query<GetV4UsersApiResponse, GetV4UsersApiArg>({
			query: () => ({ url: `/v4/users` }),
		}),
		postV4Users: build.mutation<PostV4UsersApiResponse, PostV4UsersApiArg>({
			query: (queryArg) => ({ url: `/v4/users`, method: 'POST', body: queryArg.userCreationRequest }),
		}),
		getV4UsersByUserId: build.query<GetV4UsersByUserIdApiResponse, GetV4UsersByUserIdApiArg>({
			query: (queryArg) => ({ url: `/v4/users/${queryArg.userId}` }),
		}),
		deleteV4UsersByUserId: build.mutation<DeleteV4UsersByUserIdApiResponse, DeleteV4UsersByUserIdApiArg>({
			query: (queryArg) => ({ url: `/v4/users/${queryArg.userId}`, method: 'DELETE' }),
		}),
		patchV4UsersByUserId: build.mutation<PatchV4UsersByUserIdApiResponse, PatchV4UsersByUserIdApiArg>({
			query: (queryArg) => ({ url: `/v4/users/${queryArg.userId}`, method: 'PATCH', body: queryArg.userPatchRequest }),
		}),
		getV4Webhooks: build.query<GetV4WebhooksApiResponse, GetV4WebhooksApiArg>({
			query: (queryArg) => ({ url: `/v4/webhooks`, params: { workspaceId: queryArg.workspaceId } }),
		}),
		postV4Webhooks: build.mutation<PostV4WebhooksApiResponse, PostV4WebhooksApiArg>({
			query: (queryArg) => ({ url: `/v4/webhooks`, method: 'POST', body: queryArg.postWebhookRequest }),
		}),
		postV4WebhooksValidation: build.mutation<PostV4WebhooksValidationApiResponse, PostV4WebhooksValidationApiArg>({
			query: (queryArg) => ({ url: `/v4/webhooks/validation`, method: 'POST', body: queryArg.validationRequest }),
		}),
		getV4WebhooksByWebhookId: build.query<GetV4WebhooksByWebhookIdApiResponse, GetV4WebhooksByWebhookIdApiArg>({
			query: (queryArg) => ({ url: `/v4/webhooks/${queryArg.webhookId}` }),
		}),
		deleteV4WebhooksByWebhookId: build.mutation<
			DeleteV4WebhooksByWebhookIdApiResponse,
			DeleteV4WebhooksByWebhookIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/webhooks/${queryArg.webhookId}`, method: 'DELETE' }),
		}),
		patchV4WebhooksByWebhookId: build.mutation<PatchV4WebhooksByWebhookIdApiResponse, PatchV4WebhooksByWebhookIdApiArg>(
			{
				query: (queryArg) => ({
					url: `/v4/webhooks/${queryArg.webhookId}`,
					method: 'PATCH',
					body: queryArg.patchWebhookRequest,
				}),
			},
		),
		getV4Workspaces: build.query<GetV4WorkspacesApiResponse, GetV4WorkspacesApiArg>({
			query: () => ({ url: `/v4/workspaces` }),
		}),
		postV4Workspaces: build.mutation<PostV4WorkspacesApiResponse, PostV4WorkspacesApiArg>({
			query: (queryArg) => ({ url: `/v4/workspaces`, method: 'POST', body: queryArg.workspacePostRequest }),
		}),
		getV4WorkspacesByWorkspaceId: build.query<
			GetV4WorkspacesByWorkspaceIdApiResponse,
			GetV4WorkspacesByWorkspaceIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/workspaces/${queryArg.workspaceId}` }),
		}),
		deleteV4WorkspacesByWorkspaceId: build.mutation<
			DeleteV4WorkspacesByWorkspaceIdApiResponse,
			DeleteV4WorkspacesByWorkspaceIdApiArg
		>({
			query: (queryArg) => ({ url: `/v4/workspaces/${queryArg.workspaceId}`, method: 'DELETE' }),
		}),
		patchV4WorkspacesByWorkspaceId: build.mutation<
			PatchV4WorkspacesByWorkspaceIdApiResponse,
			PatchV4WorkspacesByWorkspaceIdApiArg
		>({
			query: (queryArg) => ({
				url: `/v4/workspaces/${queryArg.workspaceId}`,
				method: 'PATCH',
				body: queryArg.workspacePatchInput,
			}),
		}),
		getV4WorkspacesByWorkspaceIdMembers: build.query<
			GetV4WorkspacesByWorkspaceIdMembersApiResponse,
			GetV4WorkspacesByWorkspaceIdMembersApiArg
		>({
			query: (queryArg) => ({ url: `/v4/workspaces/${queryArg.workspaceId}/members` }),
		}),
		getV4Zones: build.query<GetV4ZonesApiResponse, GetV4ZonesApiArg>({
			query: (queryArg) => ({ url: `/v4/zones`, params: { type: queryArg['type'] } }),
		}),
		postV4Zones: build.mutation<PostV4ZonesApiResponse, PostV4ZonesApiArg>({
			query: (queryArg) => ({ url: `/v4/zones`, method: 'POST', body: queryArg.postZoneFields }),
		}),
		patchV4Zones: build.mutation<PatchV4ZonesApiResponse, PatchV4ZonesApiArg>({
			query: (queryArg) => ({
				url: `/v4/zones`,
				method: 'PATCH',
				body: queryArg.patchAllZonesFields,
				params: { dryRun: queryArg.dryRun },
			}),
		}),
		getV4ZonesByZoneId: build.query<GetV4ZonesByZoneIdApiResponse, GetV4ZonesByZoneIdApiArg>({
			query: (queryArg) => ({ url: `/v4/zones/${queryArg.zoneId}` }),
		}),
		putV4ZonesByZoneId: build.mutation<PutV4ZonesByZoneIdApiResponse, PutV4ZonesByZoneIdApiArg>({
			query: (queryArg) => ({ url: `/v4/zones/${queryArg.zoneId}`, method: 'PUT', body: queryArg.putZoneFields }),
		}),
		deleteV4ZonesByZoneId: build.mutation<DeleteV4ZonesByZoneIdApiResponse, DeleteV4ZonesByZoneIdApiArg>({
			query: (queryArg) => ({ url: `/v4/zones/${queryArg.zoneId}`, method: 'DELETE' }),
		}),
		patchV4ZonesByZoneId: build.mutation<PatchV4ZonesByZoneIdApiResponse, PatchV4ZonesByZoneIdApiArg>({
			query: (queryArg) => ({
				url: `/v4/zones/${queryArg.zoneId}`,
				method: 'PATCH',
				body: queryArg.patchZoneFields,
				params: { dryRun: queryArg.dryRun },
			}),
		}),
	}),
	overrideExisting: false,
});
export { injectedRtkApi as nlwApiV4 };
export type PostV4CheckoutsApiResponse = /** status 201 Entity successfully created. */ CheckoutResult;
export type PostV4CheckoutsApiArg = {
	checkoutInput: CheckoutInput;
};
export type GetV4CheckoutsByCheckoutIdApiResponse = /** status 200 Entity successfully retrieved. */ Checkout;
export type GetV4CheckoutsByCheckoutIdApiArg = {
	/** ID. */
	checkoutId: string;
};
export type PostV4DashboardsByDashboardIdTilesAndTileIdSeriesApiResponse =
	/** status 201 Entity successfully created. */ DashboardSeriesRead;
export type PostV4DashboardsByDashboardIdTilesAndTileIdSeriesApiArg = {
	/** ID. */
	dashboardId: string;
	/** ID. */
	tileId: string;
	dashboardSeriesPostRequest: DashboardSeriesPostRequest;
};
export type DeleteV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdApiResponse =
	/** status 202 Entity successfully deleted. */ void;
export type DeleteV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdApiArg = {
	/** ID. */
	dashboardId: string;
	/** ID. */
	seriesId: string;
	/** ID. */
	tileId: string;
};
export type PatchV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdApiResponse =
	/** status 200 Entity successfully updated. */ DashboardSeriesRead;
export type PatchV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdApiArg = {
	/** ID. */
	dashboardId: string;
	/** ID. */
	seriesId: string;
	/** ID. */
	tileId: string;
	dashboardSeriesPatchRequest: DashboardSeriesPatchRequest;
};
export type GetV4DashboardsByDashboardIdTilesApiResponse =
	/** status 200 Entity successfully retrieved. */ DashboardTilePageRead;
export type GetV4DashboardsByDashboardIdTilesApiArg = {
	/** ID. */
	dashboardId: string;
};
export type PostV4DashboardsByDashboardIdTilesApiResponse =
	/** status 201 Entity successfully created. */ EmptyDashboardTileRead;
export type PostV4DashboardsByDashboardIdTilesApiArg = {
	/** ID. */
	dashboardId: string;
	dashboardTilesPostOneRequest: DashboardTilesPostOneRequest;
};
export type DeleteV4DashboardsByDashboardIdTilesApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4DashboardsByDashboardIdTilesApiArg = {
	/** ID. */
	dashboardId: string;
};
export type PatchV4DashboardsByDashboardIdTilesApiResponse =
	/** status 200 Entity successfully updated. */ DashboardTilePageRead;
export type PatchV4DashboardsByDashboardIdTilesApiArg = {
	/** ID. */
	dashboardId: string;
	dashboardTilesPatchAllRequest: DashboardTilesPatchAllRequest;
};
export type DeleteV4DashboardsByDashboardIdTilesAndDashboardTileIdApiResponse =
	/** status 202 Entity successfully deleted. */ void;
export type DeleteV4DashboardsByDashboardIdTilesAndDashboardTileIdApiArg = {
	/** ID. */
	dashboardId: string;
	/** ID. */
	dashboardTileId: string;
};
export type PatchV4DashboardsByDashboardIdTilesAndDashboardTileIdApiResponse =
	/** status 200 Entity successfully updated. */ DashboardTileRead;
export type PatchV4DashboardsByDashboardIdTilesAndDashboardTileIdApiArg = {
	/** ID. */
	dashboardId: string;
	/** ID. */
	dashboardTileId: string;
	dashboardTilesPatchOneRequest: DashboardTilesPatchOneRequest;
};
export type GetV4DashboardsApiResponse = /** status 200 Entity successfully retrieved. */ DashboardPage;
export type GetV4DashboardsApiArg = {
	/** ID. */
	workspaceId: string;
	/** Filter by result ID */
	resultId?: string;
	/** Update in which order the elements are returned, by default elements are sorted with createdAt in descending order, sort configuration is done by writing the field name prefixed with a + or a - for ascending or descending order
    Available fields are: createdAt, name, updatedAt */
	sort?: string;
};
export type PostV4DashboardsApiResponse = /** status 201 Entity successfully created. */ Dashboard;
export type PostV4DashboardsApiArg = {
	dashboardInput: DashboardInput;
};
export type GetV4DashboardsByDashboardIdApiResponse = /** status 200 Entity successfully retrieved. */ Dashboard;
export type GetV4DashboardsByDashboardIdApiArg = {
	/** ID. */
	dashboardId: string;
};
export type DeleteV4DashboardsByDashboardIdApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4DashboardsByDashboardIdApiArg = {
	/** ID. */
	dashboardId: string;
};
export type PatchV4DashboardsByDashboardIdApiResponse = /** status 200 Entity successfully updated. */ Dashboard;
export type PatchV4DashboardsByDashboardIdApiArg = {
	/** ID. */
	dashboardId: string;
	dashboardPatchRequest: DashboardPatchRequest;
};
export type PostV4DashboardsByDashboardIdDuplicationApiResponse =
	/** status 201 Entity successfully created. */ Dashboard;
export type PostV4DashboardsByDashboardIdDuplicationApiArg = {
	/** ID. */
	dashboardId: string;
	dashboardDuplicateRequest: DashboardDuplicateRequest;
};
export type GetV4DashboardsByDashboardIdPdfApiResponse = /** status 200 Success. */ Blob;
export type GetV4DashboardsByDashboardIdPdfApiArg = {
	/** ID. */
	dashboardId: string;
	/** Locale language is set by default to english */
	locale?: 'EN';
	/** The displayed time zone.The value can be like Europe/Paris or Asia/Tokyo based on TimeZone Identifier. By default set to Europe/Paris. */
	timezone?: string;
};
export type PostV4DashboardsByDashboardIdPublicTokenApiResponse =
	/** status 201 Entity successfully created. */ DashboardPublicToken;
export type PostV4DashboardsByDashboardIdPublicTokenApiArg = {
	/** ID. */
	dashboardId: string;
};
export type DeleteV4DashboardsByDashboardIdPublicTokenApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4DashboardsByDashboardIdPublicTokenApiArg = {
	/** ID. */
	dashboardId: string;
};
export type GetV4DeletionPoliciesApiResponse =
	/** status 200 Entity successfully retrieved. */ DeletionPoliciesPageRead;
export type GetV4DeletionPoliciesApiArg = {
	/** ID. */
	workspaceId: string;
};
export type PostV4DeletionPoliciesApiResponse = /** status 201 Entity successfully created. */ DeletionPolicyRead;
export type PostV4DeletionPoliciesApiArg = {
	postDeletionPolicyFields: PostDeletionPolicyFields;
};
export type PostV4DeletionPoliciesExecutionApiResponse =
	/** status 201 Entity successfully created. */ DeletionPolicyExecution;
export type PostV4DeletionPoliciesExecutionApiArg = {
	/** Execution mode specifies how the deletion policy will be executed. For now only DRY_RUN is supported. */
	mode?: 'DRY_RUN';
	postDeletionPolicyFields: PostDeletionPolicyFields;
};
export type GetV4DeletionPoliciesByDeletionPolicyIdApiResponse =
	/** status 200 Entity successfully retrieved. */ DeletionPolicyRead;
export type GetV4DeletionPoliciesByDeletionPolicyIdApiArg = {
	/** ID. */
	deletionPolicyId: string;
};
export type DeleteV4DeletionPoliciesByDeletionPolicyIdApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4DeletionPoliciesByDeletionPolicyIdApiArg = {
	/** ID. */
	deletionPolicyId: string;
};
export type PatchV4DeletionPoliciesByDeletionPolicyIdApiResponse =
	/** status 200 Entity successfully updated. */ DeletionPolicyRead;
export type PatchV4DeletionPoliciesByDeletionPolicyIdApiArg = {
	/** ID. */
	deletionPolicyId: string;
	patchDeletionPolicyFields: PatchDeletionPolicyFields;
};
export type GetV4InfrastructureProvidersApiResponse =
	/** status 200 Entity successfully retrieved. */ InfrastructureProviderPage;
export type GetV4InfrastructureProvidersApiArg = void;
export type PostV4InfrastructureProvidersApiResponse = /** status 200 Provider successfully tested */
	| InfrastructureProviderWithConnectivityStatus
	| /** status 201 Provider successfully tested and created */ InfrastructureProviderWithConnectivityStatus;
export type PostV4InfrastructureProvidersApiArg = {
	/** When true configuration will be tested without saving it, testers and admins are allowed to use dryRun = true but only admin can use dryRun = false */
	dryRun?: boolean;
	createProviderRequest: CreateProviderRequestWrite;
};
export type DeleteV4InfrastructureProvidersByIdApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4InfrastructureProvidersByIdApiArg = {
	/** Unique identifier of an infrastructure provider. */
	id: string;
};
export type PatchV4InfrastructureProvidersByIdApiResponse =
	/** status 200 Entity successfully updated. */ InfrastructureProviderWithConnectivityStatus;
export type PatchV4InfrastructureProvidersByIdApiArg = {
	/** Unique identifier of an infrastructure provider. */
	id: string;
	/** When true configuration will be tested without saving it, testers and admins are allowed to use dryRun = true but only admin can use dryRun = false */
	dryRun?: boolean;
	patchProviderRequest: PatchProviderRequestWrite;
};
export type GetV4LicenseApiResponse = /** status 200 Entity successfully retrieved. */ License;
export type GetV4LicenseApiArg = void;
export type PostV4LicenseApiResponse = /** status 201 Entity successfully created. */ License;
export type PostV4LicenseApiArg = {
	body: {
		file: Blob;
	};
};
export type PostV4LicenseActivationRequestsApiResponse = /** status 200 Success */ Blob;
export type PostV4LicenseActivationRequestsApiArg = void;
export type PostV4LicenseDeactivationRequestsApiResponse = /** status 200 Success */ Blob;
export type PostV4LicenseDeactivationRequestsApiArg = void;
export type PostV4LicenseForcedReleasesApiResponse = /** status 201 Entity successfully created. */ object;
export type PostV4LicenseForcedReleasesApiArg = {
	postForcedRelease: PostForcedRelease;
};
export type GetV4LicenseLeasesApiResponse = /** status 200 Entity successfully retrieved. */ LeasePage;
export type GetV4LicenseLeasesApiArg = {
	/** Filter leases from the specified workspace. Leave empty will return the leases from all workspaces (Only available for admins). Testers can only retrieve leases from workspaces they are members of and must enter a workspace ID. */
	workspaceId?: string;
};
export type PostV4LicenseLeasesApiResponse = /** status 201 Entity successfully created. */ Lease;
export type PostV4LicenseLeasesApiArg = {
	postOfflineLease: PostOfflineLease;
};
export type GetV4LicenseLeasesByLeaseIdentifierApiResponse = /** status 200 Success. */ Blob;
export type GetV4LicenseLeasesByLeaseIdentifierApiArg = {
	/** Generated lease identifier that can be retrieved by GET leases */
	leaseIdentifier: string;
};
export type PostV4LicenseReleasesApiResponse = /** status 201 Entity successfully created. */ object;
export type PostV4LicenseReleasesApiArg = {
	body: {
		releaseFile: Blob;
	};
};
export type GetV4MeApiResponse = /** status 200 Entity successfully retrieved. */ CurrentUser;
export type GetV4MeApiArg = void;
export type PatchV4MeApiResponse = /** status 200 Entity successfully updated. */ CurrentUser;
export type PatchV4MeApiArg = {
	patchMeRequest: PatchMeRequest;
};
export type GetV4MeFeaturesApiResponse = /** status 200 Entity successfully retrieved. */ Features;
export type GetV4MeFeaturesApiArg = void;
export type PutV4MePasswordApiResponse = /** status 204 Update successful. */ void;
export type PutV4MePasswordApiArg = {
	putPasswordRequest: PutPasswordRequest;
};
export type GetV4MePreferencesApiResponse = /** status 200 Entity successfully retrieved. */ Preferences;
export type GetV4MePreferencesApiArg = void;
export type PatchV4MePreferencesApiResponse = /** status 200 Entity successfully updated. */ Preferences;
export type PatchV4MePreferencesApiArg = {
	patchPreferencesRequest: PatchPreferencesRequest;
};
export type GetV4MeTokensApiResponse = /** status 200 Entity successfully retrieved. */ TokenPage;
export type GetV4MeTokensApiArg = void;
export type PostV4MeTokensApiResponse = /** status 201 Entity successfully created. */ Token;
export type PostV4MeTokensApiArg = {
	createTokenRequest: CreateTokenRequest;
};
export type DeleteV4MeTokensByTokenApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4MeTokensByTokenApiArg = {
	/** Token to delete */
	token: string;
};
export type GetV4ReservationsApiResponse = /** status 200 Entity successfully retrieved. */ ReservationPage;
export type GetV4ReservationsApiArg = {
	/** Every reservation which intersect with this period will be returned */
	searchInterval?: string[];
	/** Filter by status of the reservation */
	status?: ('AVAILABLE' | 'PARTIALLY_AVAILABLE' | 'PLANNED' | 'RUNNING' | 'ENDED')[];
	/** Filter by Workspace id */
	workspaceId?: string;
};
export type PostV4ReservationsApiResponse = /** status 201 Entity successfully created. */ Reservation;
export type PostV4ReservationsApiArg = {
	body: PostReservation | ScheduleReservation;
};
export type GetV4ReservationsByReservationIdApiResponse = /** status 200 Entity successfully retrieved. */ Reservation;
export type GetV4ReservationsByReservationIdApiArg = {
	/** UUID. */
	reservationId: string;
};
export type DeleteV4ReservationsByReservationIdApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4ReservationsByReservationIdApiArg = {
	/** UUID. */
	reservationId: string;
};
export type PatchV4ReservationsByReservationIdApiResponse = /** status 200 Entity successfully updated. */ Reservation;
export type PatchV4ReservationsByReservationIdApiArg = {
	/** UUID. */
	reservationId: string;
	patchReservation: PatchReservation;
};
export type GetV4ResultsByResultIdElementsApiResponse =
	/** status 200 Entity successfully retrieved. */ GetUserPathElementsResponse;
export type GetV4ResultsByResultIdElementsApiArg = {
	/** UUID. */
	resultId: string;
};
export type GetV4ResultsByResultIdElementsValuesApiResponse =
	/** status 200 Specified Test Result Elements Values */ GetResultElementValuesResponse;
export type GetV4ResultsByResultIdElementsValuesApiArg = {
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
	/** Filter by element ids. This parameter is incompatible with `elementType`.
    
    The element id can NOT be all-transactions, all-pages, all-requests or all-actions. Please use `elementType` and read the TotalValue in the response.
    
    _Default :_ elementType=TRANSACTION  (when both elementType and elementIds are empty) */
	elementIds?: string[];
	/** Category of items to return. This parameter is incompatible with `elementIds`.
    
    _Default :_ elementType=TRANSACTION  (when both elementType and elementIds are empty) */
	elementType?: 'TRANSACTION' | 'PAGE' | 'REQUEST' | 'ACTION';
	/** Filter by time. The values returned will be computed only over the time interval corresponding to the given intervalId. Interval filtering is not possible on very large Results and will throw an error with more details */
	intervalId?: string;
	/** Index of the page to return */
	pageNumber?: number;
	/** Number of returned elements by page. Zero will return one page with all elements (no pagination). */
	pageSize?: number;
	/** Compute values by filtering only on the specified Population. Default is empty. Empty will include all Populations. */
	population?: string;
	/** Update in which order the elements are returned, by default elements are sorted with name in ascending order, sort configuration is done by writing the field name prefixed with a + or a - for ascending or descending order
    Available fields are: parent, minimumDuration, durationPercentile99, maximumDuration, maximumTtfb, averageTtfb, elementsPerSecond, userPath, errorRate, averageDuration, slaStatus, minimumTtfb, name, elementCount, durationPercentile90, id, errorCount, durationPercentile50, durationPercentile95 */
	sort?: string;
	/** Compute values by filtering only on the specified User Path. Default is empty. Empty will include all User Paths. */
	userPath?: string;
	/** Compute values by filtering only on the specified Zone. Default is empty. Empty will include all Zones. */
	zone?: string;
};
export type GetV4ResultsByResultIdElementsAndElementIdPercentilesApiResponse =
	/** status 200 Entity successfully retrieved. */ FetchElementPercentilesResponse;
export type GetV4ResultsByResultIdElementsAndElementIdPercentilesApiArg = {
	elementId: string;
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
	/** Filter by time.
    
    The percentiles returned will be computed only over the time interval corresponding to the given intervalId.
    
    Available only
    - before raw data removal (within 30 days)
    - after 30 days only if this request has been performed at least once. */
	intervalId?: string;
	/** Compute values by filtering only on the specified User Path. Default is empty. Empty will include all user paths. */
	userPathId?: string;
};
export type GetV4ResultsByResultIdElementsAndElementIdTimeseriesApiResponse =
	/** status 200 Entity successfully retrieved. */ ElementTimeSeries;
export type GetV4ResultsByResultIdElementsAndElementIdTimeseriesApiArg = {
	elementId: string;
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
	/** List of statistics to get. <br>
     Available statistics are:<br>
    MINIMUM_DURATION : Minimum response time, in seconds.<br />
    AVERAGE_DURATION : Average response time, in seconds.<br />
    MAXIMUM_DURATION : Maximum response time, in seconds.<br />
    ELEMENT_COUNT : Number of full executions of an element. If the element is interrupted (because of error or end of test), then the count number is not incremented.<br />
    ELEMENTS_PER_SECOND : Number of full executions of an element per second.<br />
    ERROR_COUNT : Number of errors.<br />
    ERRORS_PER_SECOND : Average errors per second.<br />
    ERROR_RATE : Percentage of failed iterations out of count.<br />
    MINIMUM_TTFB : Minimum time to first byte, in seconds.<br />
    AVERAGE_TTFB : Average time to first byte, in seconds.<br />
    MAXIMUM_TTFB : Maximum time to first byte, in seconds.<br />
    THROUGHPUT : Throughput in byte/s.<br />
     */
	statistics: UserPathElementTimeSeriesStatistic[];
	/** Filter by time. The points returned will be in the time interval corresponding to the given intervalId. */
	intervalId?: string;
	/** The request token supplied by the previous response in field <i>nextRequestToken</i>. If you have already queried a running result, this will allow you to get the following points */
	requestToken?: string;
	/** Filter on a specific user path */
	userPathId?: string;
};
export type GetV4ResultsByResultIdEventsApiResponse = /** status 200 Entity successfully retrieved. */ EventPage;
export type GetV4ResultsByResultIdEventsApiArg = {
	/** UUID. */
	resultId: string;
	/** Filter by Events that started after this offset (ISO 8601 format).
    This offset must be lower than toOffset if it is defined */
	fromOffset?: string;
	/** Index of the page to return */
	pageNumber?: number;
	/** Number of returned elements by page */
	pageSize?: number;
	/** Update in which order the elements are returned, by default elements are sorted with offset in descending order, sort configuration is done by writing the field name prefixed with a + or a - for ascending or descending order
    Available fields are: code, offset, fullName, source */
	sort?: string;
	/** Filter by Events that started before this offset (ISO 8601 format).
    This offset must be higher than fromOffset if it is defined */
	toOffset?: string;
	/** Filter with Event types */
	types?: ('ERROR' | 'SLA' | 'OBJECTIVE' | 'ALERT' | 'CUSTOM' | 'ANNOTATION')[];
};
export type PostV4ResultsByResultIdEventsApiResponse = /** status 201 Entity successfully created. */ EventWithDetails;
export type PostV4ResultsByResultIdEventsApiArg = {
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
	storeEventInput: StoreEventInput;
};
export type GetV4ResultsByResultIdEventsContentsAndContentIdApiResponse =
	/** status 200 Entity successfully retrieved. */ EventContent;
export type GetV4ResultsByResultIdEventsContentsAndContentIdApiArg = {
	/** Unique identifier representing a specific content. */
	contentId: string;
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
	/** A limit, in number of characters, to avoid getting a huge content. */
	contentLimit?: number;
};
export type GetV4ResultsByResultIdEventsErrorsApiResponse = /** status 200 Entity successfully retrieved. */ ErrorsPage;
export type GetV4ResultsByResultIdEventsErrorsApiArg = {
	/** Type of errors */
	errorsType: 'LG' | 'ELEMENT' | 'CODE';
	/** Unique identifier representing a specific Test Result */
	resultId: string;
	/** Index of the page to return */
	pageNumber?: number;
	/** Number of returned elements by page */
	pageSize?: number;
	/** Update in which order the elements are returned, by default elements are sorted with count in descending order, sort configuration is done by writing the field name prefixed with a + or a - for ascending or descending order
    Available fields are: code, count, userpath, lg, element */
	sort?: string;
};
export type GetV4ResultsByResultIdEventsAndEventIdApiResponse =
	/** status 200 Entity successfully retrieved. */ EventWithDetails;
export type GetV4ResultsByResultIdEventsAndEventIdApiArg = {
	/** Unique identifier representing a specific Event. */
	eventId: string;
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
};
export type DeleteV4ResultsByResultIdEventsAndEventIdApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4ResultsByResultIdEventsAndEventIdApiArg = {
	/** Unique identifier representing a specific Event. */
	eventId: string;
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
};
export type PatchV4ResultsByResultIdEventsAndEventIdApiResponse =
	/** status 200 Entity successfully updated. */ EventWithDetails;
export type PatchV4ResultsByResultIdEventsAndEventIdApiArg = {
	/** Unique identifier representing a specific Event. */
	eventId: string;
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
	patchEventInput: PatchEventInput;
};
export type GetV4ResultsByResultIdIntervalsApiResponse = /** status 200 Entity successfully retrieved. */ IntervalPage;
export type GetV4ResultsByResultIdIntervalsApiArg = {
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
};
export type PostV4ResultsByResultIdIntervalsApiResponse = /** status 201 Entity successfully created. */ Interval;
export type PostV4ResultsByResultIdIntervalsApiArg = {
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
	/** Ignore warnings about incompatibilities with Percentiles and/or Monitors and create the interval anyway. */
	ignoreIncompatibilities?: boolean;
	intervalPostRequest: IntervalPostRequest;
};
export type DeleteV4ResultsByResultIdIntervalsAndIntervalIdApiResponse =
	/** status 202 Entity successfully deleted. */ void;
export type DeleteV4ResultsByResultIdIntervalsAndIntervalIdApiArg = {
	/** Unique ID of the Interval. */
	intervalId: string;
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
};
export type PatchV4ResultsByResultIdIntervalsAndIntervalIdApiResponse =
	/** status 200 Entity successfully updated. */ Interval;
export type PatchV4ResultsByResultIdIntervalsAndIntervalIdApiArg = {
	/** Unique ID of the Interval. */
	intervalId: string;
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
	/** Ignore warnings about incompatibilities with Percentiles and/or Monitors and create the interval anyway. */
	ignoreIncompatibilities?: boolean;
	intervalPatchRequest: IntervalPatchRequest;
};
export type GetV4ResultsByResultIdLogsApiResponse = /** status 200 Entity successfully retrieved. */ ResultLogsResponse;
export type GetV4ResultsByResultIdLogsApiArg = {
	/** UUID. */
	resultId: string;
	/** Update in which order the elements are returned, by default elements are sorted with date in ascending order, sort configuration is done by writing the field name prefixed with a + or a - for ascending or descending order
    Available fields are: date */
	sort?: string;
};
export type GetV4ResultsByResultIdMonitorsApiResponse =
	/** status 200 Entity successfully retrieved. */ GetMonitorElementsResponse;
export type GetV4ResultsByResultIdMonitorsApiArg = {
	/** UUID. */
	resultId: string;
};
export type GetV4ResultsByResultIdMonitorsValuesApiResponse =
	/** status 200 Entity successfully retrieved. */ MonitorsValuesPage;
export type GetV4ResultsByResultIdMonitorsValuesApiArg = {
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
	/** Filter by time. The values returned will be computed only over the time interval corresponding to the given intervalId. */
	intervalId?: string;
	/** Filter by monitor ids. If not specified, will return values for all monitors. */
	monitorIds?: string[];
};
export type GetV4ResultsByResultIdMonitorsAndMonitorIdTimeseriesApiResponse =
	/** status 200 Entity successfully retrieved. */ MonitorTimeSeries;
export type GetV4ResultsByResultIdMonitorsAndMonitorIdTimeseriesApiArg = {
	/** Unique identifier representing a specific Monitor. */
	monitorId: string;
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
	/** Unique identifier representing a specific interval */
	intervalId?: string;
	/** The request token supplied by the previous response in field <i>nextRequestToken</i>. If you have already queried a running result, this will allow you to get the following points */
	requestToken?: string;
};
export type GetV4ResultsByResultIdSlasApiResponse = /** status 200 Entity successfully retrieved. */ SlaPage;
export type GetV4ResultsByResultIdSlasApiArg = {
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
	/** Type of SLA */
	slaType: 'PER_RUN' | 'PER_TIME_INTERVAL';
	/** Type of element to filter */
	elementType?: ('SCENARIO' | 'TRANSACTION' | 'PAGE' | 'ACTION' | 'REQUEST')[];
	/** Index of the page to return */
	pageNumber?: number;
	/** Number of returned elements by page */
	pageSize?: number;
	/** Type of SLA status to filter */
	slaStatus?: ('SUCCESS' | 'WARN' | 'FAILED')[];
	/** Update in which order the elements are returned, by default elements are sorted with slaStatus in descending order, sort configuration is done by writing the field name prefixed with a + or a - for ascending or descending order
    Available fields are: userPathName, slaStatus, parentName, failPercentage, elementType, value, warnPercentage, elementName */
	sort?: string;
};
export type GetV4ResultsByResultIdStatisticsApiResponse =
	/** status 200 Entity successfully retrieved. */ TestResultStatistics;
export type GetV4ResultsByResultIdStatisticsApiArg = {
	/** UUID. */
	resultId: string;
};
export type GetV4ResultsByResultIdTimeseriesApiResponse =
	/** status 200 Entity successfully retrieved. */ ResultTimeseries;
export type GetV4ResultsByResultIdTimeseriesApiArg = {
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
	/** List of series to get. */
	series: ('userLoad' | 'requestErrors' | 'requestAvgDuration' | 'requestCountPerSecond')[];
	/** The window duration (ISO 8601 format). Example : PT10M will return the points for the latest 10 minutes. If not specified, will return all points from the beginning. This is only available during runtime */
	fixedWindowDuration?: 'PT10M' | 'PT30M';
	/** Filter by time. The points returned will be in the time interval corresponding to the given intervalId. */
	intervalId?: string;
	/** The request token supplied by the previous response in field <i>nextRequestToken</i>. If you have already queried a running result, this will allow you to get the following points */
	requestToken?: string;
};
export type PostV4ResultsByResultIdIntervalGenerationApiResponse =
	/** status 201 Entity successfully created. */ IntervalPage;
export type PostV4ResultsByResultIdIntervalGenerationApiArg = {
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
};
export type GetV4ResultsApiResponse = /** status 200 Test Results page */ TestResultPage;
export type GetV4ResultsApiArg = {
	/** Filter by Workspace id */
	workspaceId: string;
	/** Filter by Test Result name or description */
	nameOrDescription?: string;
	/** Index of the page to return */
	pageNumber?: number;
	/** Number of returned elements by page */
	pageSize?: number;
	/** Filter by Project names. You can retrieve Project names by querying on search-critera. */
	projectNames?: string[];
	/** Update in which order the elements are returned, by default elements are sorted with startDate in descending order, sort configuration is done by writing the field name prefixed with a + or a - for ascending or descending order
    Available fields are: duration, statTotalTransactionDurationAverage, name, project, qualityStatus, statTotalRequestCountPerSecond, statTotalGlobalCountFailure, startDate, status */
	sort?: string;
	/** Filter by Test Results that started before this date (ISO8601 date-time format, i.e "YYYY-MM-DDThh:mm:ss.sTZD")This date must come after the stoppedAfter date if it is defined */
	startedBefore?: string;
	/** Filter by Test Result statuses. Not specified is equivalent to INIT, STARTING, RUNNING,TERMINATED. */
	status?: ('INIT' | 'STARTING' | 'RUNNING' | 'TERMINATED' | 'PASSED' | 'FAILED' | 'COMPUTING' | 'UNKNOWN')[];
	/** Filter by Test Results that stopped or that are still running after this date (ISO8601 date-time format, i.e "YYYY-MM-DDThh:mm:ss.sTZD")This date must come before the startedBefore date if it is defined */
	stoppedAfter?: string;
	/** Filter by Test ids. To select orphan Test Results you must use the word "Empty". You can retrieve Test ids by querying on search-critera. */
	testIds?: string[];
};
export type GetV4ResultsSearchCriteriaApiResponse =
	/** status 200 Entity successfully retrieved. */ TestResultSearchCriteria;
export type GetV4ResultsSearchCriteriaApiArg = {
	/** Unique identifier representing a specific Workspace. */
	workspaceId: string;
};
export type GetV4ResultsByResultIdApiResponse = /** status 200 Entity successfully retrieved. */ TestResult;
export type GetV4ResultsByResultIdApiArg = {
	/** UUID. */
	resultId: string;
};
export type DeleteV4ResultsByResultIdApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4ResultsByResultIdApiArg = {
	/** UUID. */
	resultId: string;
};
export type PatchV4ResultsByResultIdApiResponse = /** status 200 Entity successfully updated. */ TestResult;
export type PatchV4ResultsByResultIdApiArg = {
	/** UUID. */
	resultId: string;
	testResultInput: TestResultInput;
};
export type GetV4ResultsByResultIdContextsApiResponse = /** status 200 Entity successfully retrieved. */ ResultContext;
export type GetV4ResultsByResultIdContextsApiArg = {
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
};
export type PostV4ResultsByResultIdReportApiResponse = /** status 200 Entity successfully retrieved. */
	| Dashboard
	| /** status 201 Entity successfully created. */ Dashboard;
export type PostV4ResultsByResultIdReportApiArg = {
	/** Unique identifier representing a specific Test Result. */
	resultId: string;
};
export type GetV4ScmRepositoriesApiResponse = /** status 200 Entity successfully retrieved. */ RepositoryPage;
export type GetV4ScmRepositoriesApiArg = void;
export type PostV4ScmRepositoriesApiResponse = /** status 201 Entity successfully created. */ RepositoryWithReferences;
export type PostV4ScmRepositoriesApiArg = {
	createRepository: CreateRepository;
};
export type GetV4ScmRepositoriesByRepositoryIdApiResponse =
	/** status 200 Entity successfully retrieved. */ RepositoryWithReferences;
export type GetV4ScmRepositoriesByRepositoryIdApiArg = {
	/** UUID. */
	repositoryId: string;
};
export type DeleteV4ScmRepositoriesByRepositoryIdApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4ScmRepositoriesByRepositoryIdApiArg = {
	/** Unique ID of the repository. */
	repositoryId: string;
};
export type PatchV4ScmRepositoriesByRepositoryIdApiResponse =
	/** status 200 Entity successfully updated. */ RepositoryWithReferences;
export type PatchV4ScmRepositoriesByRepositoryIdApiArg = {
	/** ID of the repository to update. */
	repositoryId: string;
	updateRepository: UpdateRepository;
};
export type GetV4ScmRepositoriesByRepositoryIdReferencesApiResponse =
	/** status 200 Entity successfully retrieved. */ ReferencePage;
export type GetV4ScmRepositoriesByRepositoryIdReferencesApiArg = {
	/** UUID. */
	repositoryId: string;
};
export type PostV4SessionsApiResponse =
	/** status 201 The session has been successfully created */ CreateSessionResponse;
export type PostV4SessionsApiArg = {
	createSessionRequest: CreateSessionRequest;
};
export type DeleteV4SessionsApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4SessionsApiArg = void;
export type GetV4InformationApiResponse = /** status 200 Entity successfully retrieved. */ InformationResponse;
export type GetV4InformationApiArg = void;
export type GetV4SettingsApiResponse = /** status 200 Entity successfully retrieved. */ Settings;
export type GetV4SettingsApiArg = void;
export type PatchV4SettingsApiResponse = /** status 200 Entity successfully updated. */ Settings;
export type PatchV4SettingsApiArg = {
	patchSettingsInput: PatchSettingsInput;
};
export type GetV4SubscriptionApiResponse = /** status 200 Entity successfully retrieved. */ Subscription;
export type GetV4SubscriptionApiArg = {
	/** Fields which should be returned in the response, when empty everything is returned */
	fields?: ('vuhs' | 'cloudCredits' | 'totalSavedResultDuration' | 'monthlySavedResultDuration')[];
};
export type GetV4WorkspacesByWorkspaceIdSubscriptionApiResponse =
	/** status 200 Entity successfully retrieved. */ WorkspaceSubscription;
export type GetV4WorkspacesByWorkspaceIdSubscriptionApiArg = {
	/** ID. */
	workspaceId: string;
	/** Fields which should be returned in the response, when empty everything is returned</br>If either webVu or sapVu is asked, the other one is also returned */
	fields?: ('vuhs' | 'cloudCredits' | 'webVu' | 'sapVu' | 'concurrentRuns')[];
	/** Date from when the lease will be done (ISO8601)
    This date can not be in the past
    Current date will be used if left empty */
	from?: string;
	/** Date from when the lease should be released (ISO8601)
    This date should be after "from" date
    Default date is "from" date plus 30 minutes */
	to?: string;
};
export type PostV4TestExecutionsApiResponse = /** status 201 Entity successfully created. */ TestExecution;
export type PostV4TestExecutionsApiArg = {
	/** If true it will only execute checks and will not trigger a real execution */
	dryRun?: boolean;
	testExecutionInput: TestExecutionInput;
};
export type GetV4TestExecutionsByTestExecutionIdApiResponse =
	/** status 200 Entity successfully retrieved. */ GetTestExecutionResponse;
export type GetV4TestExecutionsByTestExecutionIdApiArg = {
	/** UUID. */
	testExecutionId: string;
};
export type DeleteV4TestExecutionsByTestExecutionIdApiResponse =
	/** status 202 The Test Execution has been successfully cancelled */ void;
export type DeleteV4TestExecutionsByTestExecutionIdApiArg = {
	/** UUID. */
	testExecutionId: string;
};
export type DeleteV4TestExecutionsByTestExecutionIdForcedApiResponse =
	/** status 202 The Test Execution has been successfully cancelled */ void;
export type DeleteV4TestExecutionsByTestExecutionIdForcedApiArg = {
	/** UUID. */
	testExecutionId: string;
	/** If true it will mark TERMINATED the associate test result if it is still in a RUNNING state despite not having been updated recently. Use this only if you believe the test result is wrongfully in a RUNNING state */
	markTerminated?: boolean;
};
export type GetV4TestsApiResponse = /** status 200 Entity successfully retrieved. */ TestPage;
export type GetV4TestsApiArg = {
	/** Filter with workspace ID */
	workspaceId: string;
	/** Filter by test name */
	name?: string;
	/** Index of the page to return */
	pageNumber?: number;
	/** Number of returned elements by page */
	pageSize?: number;
	/** Update in which order the elements are returned, by default elements are sorted with updatedAt in descending order, sort configuration is done by writing the field name prefixed with a + or a - for ascending or descending order
    Available fields are: userModifierName, name, lastRunDate, projectName, updatedAt */
	sort?: string;
};
export type PostV4TestsApiResponse = /** status 201 Entity successfully created. */ Test;
export type PostV4TestsApiArg = {
	testInput: TestInput;
};
export type GetV4TestsByTestIdApiResponse = /** status 200 Entity successfully retrieved. */ Test;
export type GetV4TestsByTestIdApiArg = {
	/** UUID. */
	testId: string;
};
export type DeleteV4TestsByTestIdApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4TestsByTestIdApiArg = {
	/** UUID. */
	testId: string;
	/** When true related Results will be deleted (including locked Results) and related Dashboards will be deleted too */
	deleteResults?: boolean;
};
export type PatchV4TestsByTestIdApiResponse = /** status 200 Entity successfully updated. */ Test;
export type PatchV4TestsByTestIdApiArg = {
	/** UUID. */
	testId: string;
	patchTestInput: PatchTestInput;
};
export type GetV4TestsByTestIdProjectApiResponse = /** status 200 Entity successfully retrieved. */ Project;
export type GetV4TestsByTestIdProjectApiArg = {
	/** UUID. */
	testId: string;
};
export type PostV4TestsByTestIdProjectPasswordApiResponse = /** status 204 Password updated successfully */ void;
export type PostV4TestsByTestIdProjectPasswordApiArg = {
	/** UUID. */
	testId: string;
	updatePasswordRequest: UpdatePasswordRequest;
};
export type GetV4TestsByTestIdScenariosAndScenarioNameApiResponse =
	/** status 200 The scenario with the as-code format. */ ScenarioAsCode;
export type GetV4TestsByTestIdScenariosAndScenarioNameApiArg = {
	scenarioName: 'custom';
	/** UUID. */
	testId: string;
};
export type PutV4TestsByTestIdScenariosAndScenarioNameApiResponse =
	/** status 200 The scenario with the as-code format. */ ScenarioAsCode;
export type PutV4TestsByTestIdScenariosAndScenarioNameApiArg = {
	scenarioName: 'custom';
	/** UUID. */
	testId: string;
	scenarioAsCode: ScenarioAsCode;
};
export type GetV4TestsByTestIdTrendsApiResponse = /** status 200 Entity successfully retrieved. */ TrendPage;
export type GetV4TestsByTestIdTrendsApiArg = {
	/** UUID. */
	testId: string;
};
export type GetV4TestsByTestIdTrendsConfigurationApiResponse =
	/** status 200 Entity successfully retrieved. */ TrendsConfiguration;
export type GetV4TestsByTestIdTrendsConfigurationApiArg = {
	/** UUID. */
	testId: string;
};
export type PutV4TestsByTestIdTrendsConfigurationApiResponse =
	/** status 200 Entity successfully updated. */ TrendsConfiguration;
export type PutV4TestsByTestIdTrendsConfigurationApiArg = {
	/** UUID. */
	testId: string;
	/** If true the configuration will not be persisted, but the response will contain updated fields such as objective values */
	dryRun?: boolean;
	trendsConfiguration: TrendsConfiguration;
};
export type PatchV4TestsByTestIdTrendsConfigurationApiResponse =
	/** status 200 Entity successfully updated. */ TrendsConfiguration;
export type PatchV4TestsByTestIdTrendsConfigurationApiArg = {
	/** UUID. */
	testId: string;
	trendsConfigurationPatchRequest: TrendsConfigurationPatchRequest;
};
export type GetV4TestsByTestIdTrendsElementsApiResponse =
	/** status 200 Entity successfully retrieved. */ TrendElementPage;
export type GetV4TestsByTestIdTrendsElementsApiArg = {
	/** UUID. */
	testId: string;
};
export type GetV4UsersByUserIdWorkspacesApiResponse =
	/** status 200 Entity successfully retrieved. */ UserWorkspacePage;
export type GetV4UsersByUserIdWorkspacesApiArg = {
	/** ID. */
	userId: string;
};
export type PutV4UsersByUserIdWorkspacesApiResponse = /** status 200 Entity successfully updated. */ UserWorkspace;
export type PutV4UsersByUserIdWorkspacesApiArg = {
	/** ID. */
	userId: string;
	putUserWorkspace: PutUserWorkspace;
};
export type DeleteV4UsersByUserIdWorkspacesAndWorkspaceIdApiResponse =
	/** status 202 Entity successfully deleted. */ void;
export type DeleteV4UsersByUserIdWorkspacesAndWorkspaceIdApiArg = {
	/** ID. */
	userId: string;
	/** ID. */
	workspaceId: string;
};
export type GetV4UsersApiResponse = /** status 200 Entity successfully retrieved. */ UserPage;
export type GetV4UsersApiArg = void;
export type PostV4UsersApiResponse = /** status 201 Entity successfully created. */ User;
export type PostV4UsersApiArg = {
	userCreationRequest: UserCreationRequest;
};
export type GetV4UsersByUserIdApiResponse = /** status 200 Entity successfully retrieved. */ User;
export type GetV4UsersByUserIdApiArg = {
	/** ID. */
	userId: string;
};
export type DeleteV4UsersByUserIdApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4UsersByUserIdApiArg = {
	/** ID. */
	userId: string;
};
export type PatchV4UsersByUserIdApiResponse = /** status 200 Entity successfully updated. */ User;
export type PatchV4UsersByUserIdApiArg = {
	/** ID. */
	userId: string;
	userPatchRequest: UserPatchRequest;
};
export type GetV4WebhooksApiResponse = /** status 200 Entity successfully retrieved. */ WebhookPage;
export type GetV4WebhooksApiArg = {
	/** Unique identifier representing a Workspace. */
	workspaceId: string;
};
export type PostV4WebhooksApiResponse = /** status 201 Entity successfully created. */ Webhook;
export type PostV4WebhooksApiArg = {
	postWebhookRequest: PostWebhookRequest;
};
export type PostV4WebhooksValidationApiResponse = /** status 201 Entity successfully created. */ ValidationResult;
export type PostV4WebhooksValidationApiArg = {
	validationRequest: ValidationRequest;
};
export type GetV4WebhooksByWebhookIdApiResponse = /** status 200 Entity successfully retrieved. */ Webhook;
export type GetV4WebhooksByWebhookIdApiArg = {
	/** UUID. */
	webhookId: string;
};
export type DeleteV4WebhooksByWebhookIdApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4WebhooksByWebhookIdApiArg = {
	/** UUID. */
	webhookId: string;
};
export type PatchV4WebhooksByWebhookIdApiResponse = /** status 200 Entity successfully updated. */ Webhook;
export type PatchV4WebhooksByWebhookIdApiArg = {
	/** UUID. */
	webhookId: string;
	patchWebhookRequest: PatchWebhookRequest;
};
export type GetV4WorkspacesApiResponse = /** status 200 Entity successfully retrieved. */ WorkspacePage;
export type GetV4WorkspacesApiArg = void;
export type PostV4WorkspacesApiResponse = /** status 201 Entity successfully created. */ Workspace;
export type PostV4WorkspacesApiArg = {
	workspacePostRequest: WorkspacePostRequest;
};
export type GetV4WorkspacesByWorkspaceIdApiResponse = /** status 200 Entity successfully retrieved. */ Workspace;
export type GetV4WorkspacesByWorkspaceIdApiArg = {
	/** ID. */
	workspaceId: string;
};
export type DeleteV4WorkspacesByWorkspaceIdApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4WorkspacesByWorkspaceIdApiArg = {
	/** ID. */
	workspaceId: string;
};
export type PatchV4WorkspacesByWorkspaceIdApiResponse = /** status 200 Entity successfully updated. */ Workspace;
export type PatchV4WorkspacesByWorkspaceIdApiArg = {
	/** ID. */
	workspaceId: string;
	workspacePatchInput: WorkspacePatchInput;
};
export type GetV4WorkspacesByWorkspaceIdMembersApiResponse =
	/** status 200 Entity successfully retrieved. */ WorkspaceMemberPage;
export type GetV4WorkspacesByWorkspaceIdMembersApiArg = {
	/** ID. */
	workspaceId: string;
};
export type GetV4ZonesApiResponse = /** status 200 Entity successfully retrieved. */ ZonePageRead;
export type GetV4ZonesApiArg = {
	/** Filter by type of the zone (STATIC | DYNAMIC | CLOUD) */
	type?: 'STATIC' | 'DYNAMIC' | 'CLOUD';
};
export type PostV4ZonesApiResponse = /** status 201 Entity successfully created. */ ZoneWithSettingsRead;
export type PostV4ZonesApiArg = {
	postZoneFields: PostZoneFields;
};
export type PatchV4ZonesApiResponse = /** status 200 Entity successfully updated. */ PatchAllZonesResponse;
export type PatchV4ZonesApiArg = {
	/** When set to true no change will be persisted, but fields such as modificationCostInCloudCredits for Cloud zones will be computed */
	dryRun?: boolean;
	patchAllZonesFields: PatchAllZonesFields;
};
export type GetV4ZonesByZoneIdApiResponse = /** status 200 Entity successfully retrieved. */ ZoneWithSettingsRead;
export type GetV4ZonesByZoneIdApiArg = {
	zoneId: string;
};
export type PutV4ZonesByZoneIdApiResponse = /** status 200 Entity successfully updated. */ ZoneWithSettingsRead;
export type PutV4ZonesByZoneIdApiArg = {
	zoneId: string;
	putZoneFields: PutZoneFields;
};
export type DeleteV4ZonesByZoneIdApiResponse = /** status 202 Entity successfully deleted. */ void;
export type DeleteV4ZonesByZoneIdApiArg = {
	zoneId: string;
};
export type PatchV4ZonesByZoneIdApiResponse = /** status 200 Entity successfully updated. */ ZoneWithSettingsRead;
export type PatchV4ZonesByZoneIdApiArg = {
	zoneId: string;
	/** When true no change will be persisted, but fields such as modificationCostInCloudCredits for Cloud zones will be computed */
	dryRun?: boolean;
	patchZoneFields: PatchZoneFields;
};
export type CheckoutResult = {
	/** Checkout Id to keep track of the progress of it, Id valid up to 1 hour after the checkout is done */
	id: string;
};
export type ApiError = {
	/** Define the category of the error, some categories can have additional fields */
	category: string;
	/** Description of the error */
	description: string;
};
export type InvalidPropertyValueErrorDescription = {
	/** Message describing the validation issue */
	message: string;
	/** Dynamic values used in the message */
	messageProperties: {
		[key: string]: string;
	};
	/** Validation type */
	validationType: string;
};
export type InvalidPropertyValueError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'INVALID_PROPERTY_VALUE';
	/** Description of the error */
	description?: string;
	/** Validation errors */
	errors?: InvalidPropertyValueErrorDescription[];
};
export type UnauthorizedError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'UNAUTHORIZED';
	/** Description of the error */
	description?: string;
};
export type ForbiddenError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'FORBIDDEN';
	/** Description of the error */
	description?: string;
};
export type RequestTimeoutError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'REQUEST_TIMEOUT';
	/** Description of the error */
	description?: string;
};
export type TooManyRequestsError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'TOO_MANY_REQUESTS';
	/** Description of the error */
	description?: string;
};
export type InternalServerError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'INTERNAL_SERVER_ERROR';
	/** Description of the error */
	description?: string;
};
export type CheckoutInput = {
	/** Branch or tag name available in the given repository */
	reference: string;
	/** Id of the repository which will be used for the checkout */
	scmRepositoryId: string;
	/** Id of the test where will be updated the repository */
	testId: string;
};
export type GitUploadSettings = {
	/** Reference name, prefixed with tags/ for tags */
	reference: string;
	/** ID */
	repositoryId: string;
	source: 'GIT';
	repositoryName?: string;
};
export type PayloadTooLargeError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'PAYLOAD_TOO_LARGE';
	/** Description of the error */
	description?: string;
};
export type CheckoutApiError = InvalidPropertyValueError | PayloadTooLargeError;
export type Checkout = {
	/** ID */
	id: string;
	/** Date and time in UTC when the entity was created. */
	createdAt: string;
	scmParameters: GitUploadSettings;
	/** Status of the checkout, checkout which are in status ERROR or CANCELLED will be removed automatically at a later date */
	status: 'WAITING_UPLOAD' | 'UPLOADING' | 'DONE' | 'ERROR' | 'CANCELLED';
	error?: CheckoutApiError;
};
export type NotFoundError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'NOT_FOUND';
	/** Description of the error */
	description?: string;
};
export type EmptyFilter = {
	type: 'EMPTY';
};
export type UserPathElementTimeSeriesStatistic =
	| 'MINIMUM_DURATION'
	| 'AVERAGE_DURATION'
	| 'MAXIMUM_DURATION'
	| 'ELEMENT_COUNT'
	| 'ELEMENTS_PER_SECOND'
	| 'ERROR_COUNT'
	| 'ERRORS_PER_SECOND'
	| 'ERROR_RATE'
	| 'MINIMUM_TTFB'
	| 'AVERAGE_TTFB'
	| 'MAXIMUM_TTFB'
	| 'THROUGHPUT';
export type DashboardUserPathElementTimeSeriesStatistic = {
	name: UserPathElementTimeSeriesStatistic;
};
export type DashboardUserPathElementTimeSeriesStatisticRead = {
	name: UserPathElementTimeSeriesStatistic;
	/** Dashboard Series common units */
	xAxisUnit:
		| 'SECONDS'
		| 'PERCENTAGE'
		| 'ERRORS_PER_SECOND'
		| 'ERRORS'
		| 'ELEMENTS'
		| 'ELEMENTS_PER_SECOND'
		| 'BITS_PER_SECOND'
		| 'EMPTY';
	/** Dashboard Series common units */
	yAxisUnit:
		| 'SECONDS'
		| 'PERCENTAGE'
		| 'ERRORS_PER_SECOND'
		| 'ERRORS'
		| 'ELEMENTS'
		| 'ELEMENTS_PER_SECOND'
		| 'BITS_PER_SECOND'
		| 'EMPTY';
};
export type ElementsTimeseriesFilter = {
	/** Unique identifier representing a specific Element. <br>
    Could be a UUID, a UUID with index or one of the following: <br>
    all-transactions <br>
    all-requests <br>
    all-pages <br>
    all-actions <br>
    init-container <br>
    actions-container <br>
    end-container <br>
     */
	elementId: string;
	statistic: DashboardUserPathElementTimeSeriesStatistic;
	type: 'ELEMENTS_TIMESERIES';
	userPathId?: string;
};
export type ElementsTimeseriesFilterRead = {
	/** Unique identifier representing a specific Element. <br>
    Could be a UUID, a UUID with index or one of the following: <br>
    all-transactions <br>
    all-requests <br>
    all-pages <br>
    all-actions <br>
    init-container <br>
    actions-container <br>
    end-container <br>
     */
	elementId: string;
	statistic: DashboardUserPathElementTimeSeriesStatisticRead;
	type: 'ELEMENTS_TIMESERIES';
	userPathId?: string;
};
export type DashboardUserPathElementPercentilesSeriesStatistic = {
	name: 'DURATION_PERCENTILES';
};
export type DashboardUserPathElementPercentilesSeriesStatisticRead = {
	name: 'DURATION_PERCENTILES';
	/** Dashboard Series common units */
	xAxisUnit:
		| 'SECONDS'
		| 'PERCENTAGE'
		| 'ERRORS_PER_SECOND'
		| 'ERRORS'
		| 'ELEMENTS'
		| 'ELEMENTS_PER_SECOND'
		| 'BITS_PER_SECOND'
		| 'EMPTY';
	/** Dashboard Series common units */
	yAxisUnit:
		| 'SECONDS'
		| 'PERCENTAGE'
		| 'ERRORS_PER_SECOND'
		| 'ERRORS'
		| 'ELEMENTS'
		| 'ELEMENTS_PER_SECOND'
		| 'BITS_PER_SECOND'
		| 'EMPTY';
};
export type ElementsPercentilesFilter = {
	/** Unique identifier representing a specific Element. <br>
    Could be a UUID, a UUID with index or one of the following: <br>
    all-transactions <br>
    all-requests <br>
    all-pages <br>
    all-actions <br>
    init-container <br>
    actions-container <br>
    end-container <br>
     */
	elementId: string;
	statistic: DashboardUserPathElementPercentilesSeriesStatistic;
	type: 'ELEMENTS_PERCENTILES';
	userPathId?: string;
};
export type ElementsPercentilesFilterRead = {
	/** Unique identifier representing a specific Element. <br>
    Could be a UUID, a UUID with index or one of the following: <br>
    all-transactions <br>
    all-requests <br>
    all-pages <br>
    all-actions <br>
    init-container <br>
    actions-container <br>
    end-container <br>
     */
	elementId: string;
	statistic: DashboardUserPathElementPercentilesSeriesStatisticRead;
	type: 'ELEMENTS_PERCENTILES';
	userPathId?: string;
};
export type ElementsValuesFilter = {
	elementType: 'TRANSACTION' | 'PAGE' | 'REQUEST' | 'ACTION';
	pageSize: number;
	sort: string;
	type: 'ELEMENTS_VALUES';
};
export type DashboardMonitorElementTimeSeriesStatistic = {
	name: 'STEP' | 'AVERAGE';
};
export type DashboardMonitorElementTimeSeriesStatisticRead = {
	name: 'STEP' | 'AVERAGE';
	xAxisUnit: string;
	yAxisUnit: string;
};
export type MonitorsTimeseriesFilter = {
	/** ID */
	monitorId: string;
	statistic: DashboardMonitorElementTimeSeriesStatistic;
	type: 'MONITORS_TIMESERIES';
};
export type MonitorsTimeseriesFilterRead = {
	/** ID */
	monitorId: string;
	statistic: DashboardMonitorElementTimeSeriesStatisticRead;
	type: 'MONITORS_TIMESERIES';
};
export type SlaFilterField = {
	elementType?: ('SCENARIO' | 'TRANSACTION' | 'PAGE' | 'ACTION' | 'REQUEST')[];
	slaStatus?: ('SUCCESS' | 'WARN' | 'FAILED')[];
};
export type DashboardSlaFilter = {
	filter: SlaFilterField;
	/** SLA page size */
	pageSize: number;
	/** Update in which order the elements are returned, by default elements are sorted with elementName in ascending order, sort configuration is done by writing the field name prefixed with a + or a - for ascending or descending order
    Available fields are: userPathName, slaStatus, parentName, failPercentage, elementType, value, warnPercentage, elementName */
	sort: string;
};
export type DashboardSlaFilterRead = {
	filter: SlaFilterField;
	/** SLA page size */
	pageSize: number;
	/** Update in which order the elements are returned, by default elements are sorted with elementName in ascending order, sort configuration is done by writing the field name prefixed with a + or a - for ascending or descending order
    Available fields are: userPathName, slaStatus, parentName, failPercentage, elementType, value, warnPercentage, elementName */
	sort: string;
	type: 'SLA_PER_INTERVAL';
};
export type ErrorsCountFilter = {
	/** Aggregation type */
	aggregationType: 'CODE' | 'LG_NAME' | 'ELEMENT_ID';
	/** Page number */
	pageNumber: number;
	/** Page size */
	pageSize: number;
	/** Update in which order the elements are returned, by default elements are sorted with count in ascending order, sort configuration is done by writing the field name prefixed with a + or a - for ascending or descending order
    Available fields are: code, count, userpath, lg, element */
	sort: string;
	type: 'ERRORS_COUNT';
};
export type KeyMetricsFilter = {
	elementType: 'REQUEST' | 'TRANSACTION';
	type: 'KEY_METRICS';
};
export type DashboardFilter =
	| ({
			type: 'EMPTY';
	  } & EmptyFilter)
	| ({
			type: 'ELEMENTS_TIMESERIES';
	  } & ElementsTimeseriesFilter)
	| ({
			type: 'ELEMENTS_PERCENTILES';
	  } & ElementsPercentilesFilter)
	| ({
			type: 'ELEMENTS_VALUES';
	  } & ElementsValuesFilter)
	| ({
			type: 'MONITORS_TIMESERIES';
	  } & MonitorsTimeseriesFilter)
	| ({
			type: 'SLA_PER_INTERVAL';
	  } & DashboardSlaFilter)
	| ({
			type: 'ERRORS_COUNT';
	  } & ErrorsCountFilter)
	| ({
			type: 'KEY_METRICS';
	  } & KeyMetricsFilter);
export type DashboardFilterRead =
	| ({
			type: 'EMPTY';
	  } & EmptyFilter)
	| ({
			type: 'ELEMENTS_TIMESERIES';
	  } & ElementsTimeseriesFilterRead)
	| ({
			type: 'ELEMENTS_PERCENTILES';
	  } & ElementsPercentilesFilterRead)
	| ({
			type: 'ELEMENTS_VALUES';
	  } & ElementsValuesFilter)
	| ({
			type: 'MONITORS_TIMESERIES';
	  } & MonitorsTimeseriesFilterRead)
	| ({
			type: 'SLA_PER_INTERVAL';
	  } & DashboardSlaFilterRead)
	| ({
			type: 'ERRORS_COUNT';
	  } & ErrorsCountFilter)
	| ({
			type: 'KEY_METRICS';
	  } & KeyMetricsFilter);
export type DashboardSeries = {
	/** ID */
	id: string;
	/** The color of the series. */
	color: string;
	filter: DashboardFilter;
	/** The legend of the series. */
	legend: string;
	/** ID */
	resultId: string;
	resultName: string;
	/** Defines whether the series should be displayed or hidden. */
	visible: boolean;
	/** The duration of the series in ISO 8601 format. */
	duration?: string;
	/** UTC Datetime of the start of this series */
	startDate?: string;
	/** ID */
	testId?: string;
	testName?: string;
};
export type DashboardSeriesRead = {
	/** ID */
	id: string;
	/** The color of the series. */
	color: string;
	filter: DashboardFilterRead;
	/** The legend of the series. */
	legend: string;
	/** ID */
	resultId: string;
	resultName: string;
	/** Defines whether the series should be displayed or hidden. */
	visible: boolean;
	/** The duration of the series in ISO 8601 format. */
	duration?: string;
	/** UTC Datetime of the start of this series */
	startDate?: string;
	/** ID */
	testId?: string;
	testName?: string;
};
export type DashboardSeriesPostRequest = {
	/** The color of the series. */
	color: string;
	filter: DashboardFilter;
	/** The legend of the series. */
	legend: string;
	/** ID */
	resultId: string;
	resultName: string;
	/** Defines whether the series should be displayed or hidden. */
	visible: boolean;
};
export type DashboardSeriesPostRequestRead = {
	/** The color of the series. */
	color: string;
	filter: DashboardFilterRead;
	/** The legend of the series. */
	legend: string;
	/** ID */
	resultId: string;
	resultName: string;
	/** Defines whether the series should be displayed or hidden. */
	visible: boolean;
};
export type DashboardSeriesPatchRequest = {
	/** The color of the series. */
	color?: string;
	/** Defines whether the series should be displayed or hidden. */
	visible?: boolean;
};
export type EmptyDashboardTile = {
	/** ID */
	id: string;
	height: number;
	title: string;
	width: number;
	x: number;
	y: number;
};
export type EmptyDashboardTileRead = {
	/** ID */
	id: string;
	height: number;
	title: string;
	type: 'EMPTY';
	width: number;
	x: number;
	y: number;
};
export type TextDashboardTile = {
	/** ID */
	id: string;
	height: number;
	/** A text content to display inside the tile. */
	text: string;
	title: string;
	width: number;
	x: number;
	y: number;
};
export type TextDashboardTileRead = {
	/** ID */
	id: string;
	height: number;
	/** A text content to display inside the tile. */
	text: string;
	title: string;
	type: 'TEXT';
	width: number;
	x: number;
	y: number;
};
export type SeriesDashboardTile = {
	/** ID */
	id: string;
	height: number;
	/** A list of series to build a line chart. */
	series: DashboardSeries[];
	title: string;
	width: number;
	x: number;
	y: number;
};
export type SeriesDashboardTileRead = {
	/** ID */
	id: string;
	height: number;
	/** A list of series to build a line chart. */
	series: DashboardSeriesRead[];
	title: string;
	type: 'SERIES';
	width: number;
	x: number;
	y: number;
};
export type WidgetDashboardTile = {
	/** ID */
	id: string;
	filter: DashboardFilter;
	height: number;
	/** ID */
	resultId: string;
	resultName: string;
	title: string;
	/** The type of visualisation for this widget. */
	visualization: 'LINE_CHART' | 'TABLE' | 'TEXT' | 'PIE_CHART' | 'COUNTER' | 'SUMMARY' | 'CATEGORY';
	width: number;
	x: number;
	y: number;
	/** ID */
	testId?: string;
	testName?: string;
};
export type WidgetDashboardTileRead = {
	/** ID */
	id: string;
	filter: DashboardFilterRead;
	height: number;
	/** ID */
	resultId: string;
	resultName: string;
	title: string;
	type: 'WIDGET';
	/** The type of visualisation for this widget. */
	visualization: 'LINE_CHART' | 'TABLE' | 'TEXT' | 'PIE_CHART' | 'COUNTER' | 'SUMMARY' | 'CATEGORY';
	width: number;
	x: number;
	y: number;
	/** ID */
	testId?: string;
	testName?: string;
};
export type ValuesComparisonColumn = {
	/** Name of the column */
	columnName: string;
	/** Test result id */
	resultId: string;
	/** Interval id */
	intervalId?: string;
	/** Set the visibility of the column */
	visible?: boolean;
};
export type ValuesComparisonIdMapping = {
	/** Mapping between result ids and element or monitor ids */
	metricIdByItemIds: {
		[key: string]: string;
	};
	/** This is the path to the element from the root of the tree */
	path: string[];
};
export type UserPathElementValueStatistic =
	| 'DURATION_PERCENTILE_99'
	| 'DURATION_PERCENTILE_95'
	| 'DURATION_PERCENTILE_90'
	| 'DURATION_PERCENTILE_50'
	| 'MINIMUM_DURATION'
	| 'AVERAGE_DURATION'
	| 'MAXIMUM_DURATION'
	| 'ELEMENT_COUNT'
	| 'ELEMENTS_PER_SECOND'
	| 'ERROR_COUNT'
	| 'ERROR_RATE'
	| 'MINIMUM_TTFB'
	| 'AVERAGE_TTFB'
	| 'MAXIMUM_TTFB';
export type ElementComparisonRow = {
	/** The type of the element */
	elementType: 'TRANSACTION' | 'ACTION' | 'PAGE' | 'REQUEST';
	/** This is the path to the element or monitor from the root of the tree */
	path: string[];
	/** Name of the row */
	rowName: string;
	rowType: 'ELEMENT';
	statistic: UserPathElementValueStatistic;
	/** Compute value by filtering only on the specified User Path. Default is empty. Empty will include all User Paths */
	userPathId?: string;
	/** Set the visibility of the row */
	visible?: boolean;
};
export type MonitorComparisonRow = {
	/** This is the path to the element or monitor from the root of the tree */
	path: string[];
	/** Name of the row */
	rowName: string;
	rowType: 'MONITOR';
	/** Statistic of the monitor */
	statistic: 'MIN' | 'AVG' | 'MAX';
	/** Set the visibility of the row */
	visible?: boolean;
};
export type ValueComparisonRow = ElementComparisonRow | MonitorComparisonRow;
export type ValuesComparisonDashboardTile = {
	/** ID */
	id: string;
	/** The columns of the table */
	columns: ValuesComparisonColumn[];
	differenceType: 'PERCENTAGE' | 'VALUE' | 'BOTH' | 'NONE';
	height: number;
	/** The metrics mapping of the table */
	idMapping: ValuesComparisonIdMapping[];
	/** The rows of the table */
	rows: ValueComparisonRow[];
	title: string;
	width: number;
	x: number;
	y: number;
};
export type ValuesComparisonDashboardTileRead = {
	/** ID */
	id: string;
	/** The columns of the table */
	columns: ValuesComparisonColumn[];
	differenceType: 'PERCENTAGE' | 'VALUE' | 'BOTH' | 'NONE';
	height: number;
	/** The metrics mapping of the table */
	idMapping: ValuesComparisonIdMapping[];
	/** The rows of the table */
	rows: ValueComparisonRow[];
	title: string;
	type: 'VALUES_COMPARISON';
	width: number;
	x: number;
	y: number;
};
export type DashboardTile =
	| ({
			type: 'EMPTY';
	  } & EmptyDashboardTile)
	| ({
			type: 'TEXT';
	  } & TextDashboardTile)
	| ({
			type: 'SERIES';
	  } & SeriesDashboardTile)
	| ({
			type: 'WIDGET';
	  } & WidgetDashboardTile)
	| ({
			type: 'VALUES_COMPARISON';
	  } & ValuesComparisonDashboardTile);
export type DashboardTileRead =
	| ({
			type: 'EMPTY';
	  } & EmptyDashboardTileRead)
	| ({
			type: 'TEXT';
	  } & TextDashboardTileRead)
	| ({
			type: 'SERIES';
	  } & SeriesDashboardTileRead)
	| ({
			type: 'WIDGET';
	  } & WidgetDashboardTileRead)
	| ({
			type: 'VALUES_COMPARISON';
	  } & ValuesComparisonDashboardTileRead);
export type DashboardTilePage = {
	/** Items in the page */
	items: DashboardTile[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type DashboardTilePageRead = {
	/** Items in the page */
	items: DashboardTileRead[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type DashboardTilesPostOneRequest = {
	height: number;
	title: string;
	width: number;
	x: number;
	y: number;
};
export type DashboardTilesPatchAllRequestItem = {
	/** ID */
	id: string;
	height: number;
	width: number;
	x: number;
	y: number;
};
export type DashboardTilesPatchAllRequest = {
	/** Tiles with coordinates */
	items?: DashboardTilesPatchAllRequestItem[];
};
export type DashboardWidgetTilePatchOneRequest = {
	filter: DashboardFilter;
	/** Test result id to which the widget refers */
	resultId: string;
	/** Test result name to which the widget refers */
	resultName: string;
	/** Type of the tile */
	type: 'WIDGET';
	/** The type of visualisation for this widget */
	visualization: 'LINE_CHART' | 'TABLE' | 'TEXT' | 'PIE_CHART' | 'COUNTER' | 'SUMMARY' | 'CATEGORY';
	/** Test settings id to which the widget refers if exists */
	testId?: string;
	/** Test settings name to which the widget refers if exists */
	testName?: string;
	/** Tile title */
	title?: string;
};
export type DashboardWidgetTilePatchOneRequestRead = {
	filter: DashboardFilterRead;
	/** Test result id to which the widget refers */
	resultId: string;
	/** Test result name to which the widget refers */
	resultName: string;
	/** Type of the tile */
	type: 'WIDGET';
	/** The type of visualisation for this widget */
	visualization: 'LINE_CHART' | 'TABLE' | 'TEXT' | 'PIE_CHART' | 'COUNTER' | 'SUMMARY' | 'CATEGORY';
	/** Test settings id to which the widget refers if exists */
	testId?: string;
	/** Test settings name to which the widget refers if exists */
	testName?: string;
	/** Tile title */
	title?: string;
};
export type DashboardTextTilePatchOneRequest = {
	/** A text content to display inside the tile. */
	text: string;
	/** Type of the tile */
	type: 'TEXT';
	/** Tile title */
	title?: string;
};
export type DashboardEmptyTilePatchOneRequest = {
	/** Tile title */
	title?: string;
	/** Type of the tile */
	type?: 'EMPTY';
};
export type DashboardValuesComparisonTilePatchOneRequest = {
	/** The list of columns of the tile. Each column represents a Result filtered or not on a specific Interval */
	columns: ValuesComparisonColumn[];
	/** The list of rows of the tile. Each row represents a metric of an Element or a Monitor. */
	rows: ValueComparisonRow[];
	/** Tile title */
	title: string;
	/** Type of the tile */
	type: 'VALUES_COMPARISON';
	differenceType?: 'PERCENTAGE' | 'VALUE' | 'BOTH' | 'NONE';
};
export type DashboardTilesPatchOneRequest =
	| DashboardWidgetTilePatchOneRequest
	| DashboardTextTilePatchOneRequest
	| DashboardEmptyTilePatchOneRequest
	| DashboardValuesComparisonTilePatchOneRequest;
export type DashboardTilesPatchOneRequestRead =
	| DashboardWidgetTilePatchOneRequestRead
	| DashboardTextTilePatchOneRequest
	| DashboardEmptyTilePatchOneRequest
	| DashboardValuesComparisonTilePatchOneRequest;
export type Dashboard = {
	/** ID */
	id: string;
	/** Date and time in UTC when the entity was created. */
	createdAt: string;
	/** Entity name */
	name: string;
	ownerFullName: string;
	/** ID */
	ownerId: string;
	shareStatus: 'UNSHARED' | 'SHARED';
	/** Date and time in UTC when the entity was updated for the last time */
	updatedAt: string;
	/** ID */
	workspaceId: string;
	publicToken?: string;
	/** ID */
	resultId?: string;
	/** ID */
	userIdUpdate?: string;
};
export type DashboardPage = {
	/** Items in the page */
	items: Dashboard[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type DashboardInput = {
	/** Dashboard name */
	name: string;
	/** Unique identifier representing a Workspace */
	workspaceId: string;
};
export type DashboardPatchRequest = {
	/** Name */
	name?: string;
	/** ShareStatus */
	shareStatus?: 'UNSHARED' | 'SHARED';
};
export type DashboardDuplicateRequest = object;
export type DashboardPublicToken = {
	/** Dashboard public token */
	token: string;
};
export type FieldUniquenessError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'FIELD_UNIQUENESS';
	/** Description of the error */
	description?: string;
	/** The field name which should the value should be different from the existing ones */
	fieldName?: string;
};
export type DeletionPolicyFilterByTest = {
	/** Filter the results by this test unique identifier */
	testId: string;
	type: 'TEST';
};
export type DeletionPolicyFilterByTestRead = {
	/** Filter the results by this test unique identifier */
	testId: string;
	type: 'TEST';
	/** Name of the test */
	testName?: string;
};
export type DeletionPolicyFilterByProject = {
	/** Filter the results with this project name */
	projectName: string;
	/** Filter the results with this scenario name */
	scenarioName: string;
	type: 'PROJECT';
};
export type DeletionPolicyFilterByResultName = {
	/** Regular expression applied on result name. To make the pattern case-insensitive, prefix it with `(?i)` */
	resultNamePattern: string;
	type: 'RESULT_NAME';
};
export type DeletionPolicyFilter =
	| ({
			type: 'TEST';
	  } & DeletionPolicyFilterByTest)
	| ({
			type: 'PROJECT';
	  } & DeletionPolicyFilterByProject)
	| ({
			type: 'RESULT_NAME';
	  } & DeletionPolicyFilterByResultName);
export type DeletionPolicyFilterRead =
	| ({
			type: 'TEST';
	  } & DeletionPolicyFilterByTestRead)
	| ({
			type: 'PROJECT';
	  } & DeletionPolicyFilterByProject)
	| ({
			type: 'RESULT_NAME';
	  } & DeletionPolicyFilterByResultName);
export type DeletionPolicySettingCount = {
	/** Number of results to keep */
	retentionCount: number;
	type: 'COUNT';
};
export type DeletionPolicySettingDuration = {
	/** Duration in days after which results are definitively deleted (ISO 8601 format), minimum duration is 1 day */
	retentionDuration: string;
	type: 'DURATION';
};
export type DeletionPolicySetting =
	| ({
			type: 'COUNT';
	  } & DeletionPolicySettingCount)
	| ({
			type: 'DURATION';
	  } & DeletionPolicySettingDuration);
export type DeletionPolicy = {
	/** Unique ID of the deletion policy */
	id: string;
	deletionFilter: DeletionPolicyFilter;
	deletionSetting: DeletionPolicySetting;
	/** Date and time in UTC when the entity was updated for the last time */
	updatedAt: string;
	/** Unique ID of the Workspace. This deletion policy will apply only on the results of this workspace. */
	workspaceId: string;
	/** Identifier of the user who modified the entity */
	userModifierName?: string;
};
export type DeletionPolicyRead = {
	/** Unique ID of the deletion policy */
	id: string;
	deletionFilter: DeletionPolicyFilterRead;
	deletionSetting: DeletionPolicySetting;
	/** Date and time in UTC when the entity was updated for the last time */
	updatedAt: string;
	/** Unique ID of the Workspace. This deletion policy will apply only on the results of this workspace. */
	workspaceId: string;
	/** Identifier of the user who modified the entity */
	userModifierName?: string;
};
export type DeletionPoliciesPage = {
	/** Items in the page */
	items: DeletionPolicy[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type DeletionPoliciesPageRead = {
	/** Items in the page */
	items: DeletionPolicyRead[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type PostDeletionPolicyFields = {
	deletionFilter: DeletionPolicyFilter;
	deletionSetting: DeletionPolicySetting;
	/** Unique ID of the Workspace. This deletion policy will apply only on the results of this workspace. */
	workspaceId: string;
};
export type PostDeletionPolicyFieldsRead = {
	deletionFilter: DeletionPolicyFilterRead;
	deletionSetting: DeletionPolicySetting;
	/** Unique ID of the Workspace. This deletion policy will apply only on the results of this workspace. */
	workspaceId: string;
};
export type DeletionPolicyExecution = {
	/** The number of results that match both filter and settings. Excluding results marked protected against deletion (lock). */
	resultsDeleted: number;
	/** The number of results that match only the filter. Including results marked protected against deletion (lock). */
	totalResultNumber: number;
};
export type PatchDeletionPolicyFields = {
	deletionFilter?: DeletionPolicyFilter;
	deletionSetting?: DeletionPolicySetting;
};
export type PatchDeletionPolicyFieldsRead = {
	deletionFilter?: DeletionPolicyFilterRead;
	deletionSetting?: DeletionPolicySetting;
};
export type InfrastructureProvider = {
	/** ID of the provider */
	id: string;
	/** The cluster API URL, this is the URL NeoLoad Web uses to connect with the cluster */
	apiUrl: string;
	/** Name of the provider */
	name: string;
	/** The namespace where NeoLoad Web will deploy the workloads */
	namespace: string;
	/** Type of the provider */
	type: 'KUBERNETES' | 'OPENSHIFT';
	/** Kubernetes annotations that will be applied to the workloads */
	annotations?: string;
	/** Kubernetes labels that will be applied to the workloads */
	labels?: string;
	/** Kubernetes node selectors that will be applied to the workloads */
	nodeSelectors?: string;
	/** Kubernetes tolerations that will be applied to the workloads, must be formatted as YAML */
	tolerations?: string;
};
export type InfrastructureProviderPage = {
	/** Items in the page */
	items: InfrastructureProvider[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type ProviderConnectivityStatus = {
	/** Provide a high level overview of the connectivity status. */
	status: 'READY' | 'RBAC_ERROR' | 'CONFIG_ERROR' | 'INTERNAL_ERROR' | 'OTHER_ERROR';
	/** Maybe present when `status` is not READY. If present, it provides detailed traces of the connectivity issue. */
	causeList?: string[];
	/** Only provided when `status` is not READY. It provides a short description of the connectivity issue. */
	details?: string;
};
export type InfrastructureProviderWithConnectivityStatus = {
	/** ID of the provider */
	id: string;
	/** The cluster API URL, this is the URL NeoLoad Web uses to connect with the cluster */
	apiUrl: string;
	connectivityStatus: ProviderConnectivityStatus;
	/** Name of the provider */
	name: string;
	/** The namespace where NeoLoad Web will deploy the workloads */
	namespace: string;
	/** Type of the provider */
	type: 'KUBERNETES' | 'OPENSHIFT';
	/** Kubernetes annotations that will be applied to the workloads */
	annotations?: string;
	/** Kubernetes labels that will be applied to the workloads */
	labels?: string;
	/** Kubernetes node selectors that will be applied to the workloads */
	nodeSelectors?: string;
	/** Kubernetes tolerations that will be applied to the workloads, must be formatted as YAML */
	tolerations?: string;
};
export type MethodNotAllowedError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'METHOD_NOT_ALLOWED';
	/** Description of the error */
	description?: string;
};
export type CreateProviderRequest = {
	/** The cluster API URL, this is the URL NeoLoad Web uses to connect with the cluster */
	apiUrl: string;
	/** Name of the provider */
	name: string;
	/** The namespace where NeoLoad Web will deploy the workloads */
	namespace: string;
	/** Type of the provider */
	type: 'KUBERNETES' | 'OPENSHIFT';
	/** Kubernetes annotations that will be applied to the workloads */
	annotations?: string;
	/** Kubernetes labels that will be applied to the workloads */
	labels?: string;
	/** Kubernetes node selectors that will be applied to the workloads */
	nodeSelectors?: string;
	/** Kubernetes tolerations that will be applied to the workloads, must be formatted as YAML */
	tolerations?: string;
};
export type CreateProviderRequestWrite = {
	/** A Kubernetes service account token secret that will be used to authenticate on the cluster API. */
	apiToken: string;
	/** The cluster API URL, this is the URL NeoLoad Web uses to connect with the cluster */
	apiUrl: string;
	/** Name of the provider */
	name: string;
	/** The namespace where NeoLoad Web will deploy the workloads */
	namespace: string;
	/** Type of the provider */
	type: 'KUBERNETES' | 'OPENSHIFT';
	/** Kubernetes annotations that will be applied to the workloads */
	annotations?: string;
	/** Kubernetes labels that will be applied to the workloads */
	labels?: string;
	/** Kubernetes node selectors that will be applied to the workloads */
	nodeSelectors?: string;
	/** Kubernetes tolerations that will be applied to the workloads, must be formatted as YAML */
	tolerations?: string;
};
export type InvalidResourceStateError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'CONFLICT';
	/** Description of the error */
	description?: string;
	/** Dynamic values used in the description */
	messageProperties?: {
		[key: string]: string;
	};
};
export type PatchProviderRequest = {
	/** Kubernetes annotations that will be applied to the workloads */
	annotations?: string;
	/** The cluster API URL, this is the URL NeoLoad Web uses to connect with the cluster */
	apiUrl?: string;
	/** Kubernetes labels that will be applied to the workloads */
	labels?: string;
	/** Name of the provider */
	name?: string;
	/** The namespace where NeoLoad Web will deploy the workloads */
	namespace?: string;
	/** Kubernetes node selectors that will be applied to the workloads */
	nodeSelectors?: string;
	/** Kubernetes tolerations that will be applied to the workloads, must be formatted as YAML */
	tolerations?: string;
	/** Type of the provider */
	type?: 'KUBERNETES' | 'OPENSHIFT';
};
export type PatchProviderRequestWrite = {
	/** Kubernetes annotations that will be applied to the workloads */
	annotations?: string;
	/** A Kubernetes service account token secret that will be used to authenticate on the cluster API. */
	apiToken?: string;
	/** The cluster API URL, this is the URL NeoLoad Web uses to connect with the cluster */
	apiUrl?: string;
	/** Kubernetes labels that will be applied to the workloads */
	labels?: string;
	/** Name of the provider */
	name?: string;
	/** The namespace where NeoLoad Web will deploy the workloads */
	namespace?: string;
	/** Kubernetes node selectors that will be applied to the workloads */
	nodeSelectors?: string;
	/** Kubernetes tolerations that will be applied to the workloads, must be formatted as YAML */
	tolerations?: string;
	/** Type of the provider */
	type?: 'KUBERNETES' | 'OPENSHIFT';
};
export type LicenseQuota = {
	available: number;
	total: number;
};
export type License = {
	/** Address of company for which the license was generated. */
	address: string;
	/** True if installed by NeoLoad team, false if manually installed using a file */
	automaticallyInstalled: boolean;
	/** Name of the company for which the license was generated. */
	company: string;
	/** Status of the installed license. */
	licenseStatus: 'ACTIVE' | 'EXPIRED' | 'NOT_ACTIVATED' | 'DEACTIVATED';
	/** Name of the NeoLoad license. */
	name: string;
	sapVuQuota: LicenseQuota;
	webVuQuota: LicenseQuota;
	/** Number of tests you can run in parallel on the license. */
	concurrentRuns?: number;
	/** Edition of the NeoLoad license. */
	edition?: string;
	/** Expiration date of the license installed. If license is expired you can no longer run tests. License perpetual have no expiration date. */
	expirationDate?: string;
	/** Version of the NeoLoad license. */
	version?: string;
};
export type PostForcedRelease = {
	/** The identifier of the lease to force release. */
	leaseIdentifier: string;
	/** The key sent by NeoLoad support. */
	supportResponse: string;
};
export type SimpleWorkspace = {
	/** The unique id of the workspace */
	id: string;
	/** The name of the workspace */
	name: string;
};
export type Lease = {
	/** ID of the lease */
	id: string;
	/** Date and time in UTC when the lease expires. */
	expirationDate: string;
	/** Host name of the lease owner. */
	hostName: string;
	/** Type of the lease. */
	leaseType: 'ONLINE_LEASE' | 'ONLINE_LEASE_FOR_RESERVATION' | 'OFFLINE_LEASE';
	/** Owner of the lease. */
	owner: string;
	/** Number of SAP Vus leased. */
	sapVus: number;
	/** Number of Web Vus leased. */
	webVus: number;
	workspace: SimpleWorkspace;
	/** Client ID of the product (NeoLoad or NeoLoad Web), the lease has been made for. */
	clientId?: string;
	/** Number of concurrent runs in the lease. */
	concurrentRuns?: number;
	/** Description of the lease. */
	description?: string;
};
export type LeasePage = {
	/** Items in the page */
	items: Lease[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type PostOfflineLease = {
	/** Client ID of the product (NeoLoad or NeoLoad Web), the lease is made for. You must copy this ID from the license panel of the NeoLoad installation. */
	clientId: string;
	/** Number of concurrent runs to lease. If the license does not have a limit for concurrent runs, this value will be ignored and the lease will not have concurrent runs limitation. */
	concurrentRuns: number;
	/** Duration of the lease (ISO 8601 format), rounded up to the next hour. */
	duration: string;
	/** Number of SAP Vus to lease. */
	sapVus: number;
	/** Number of Web Vus to lease. */
	webVus: number;
	/** Unique identifier representing a Workspace. The VUs and concurrent runs will be deducted from this workspace. */
	workspaceId: string;
	/** Description of the lease. */
	description?: string;
};
export type AccountWithStatus = {
	/** ID of the Account */
	id: string;
	/** Initialization status of this account */
	initializationStatus: 'INITIALIZING' | 'INITIALIZED' | 'ERROR';
	/** Name of the Account */
	name: string;
};
export type UserWorkspace = {
	/** ID of the Workspace */
	id: string;
	/** Is workspace enabled for all users */
	enabledForAllUsers: boolean;
	/** Name of the Workspace */
	name: string;
	/** Description of the workspace */
	description?: string;
};
export type CurrentUser = {
	/** ID of the User */
	id: string;
	account: AccountWithStatus;
	/** Email of the User */
	email: string;
	/** Role of the user */
	role: 'NONE' | 'GUEST' | 'TESTER' | 'NEOLOAD_ADMIN' | 'ACCOUNT_ADMIN';
	/** Status of the User */
	status: 'PENDING' | 'ACTIVE';
	/** Workspaces of the user */
	workspaces: UserWorkspace[];
	/** First name of the User */
	firstName?: string;
	/** Last name of the User */
	lastName?: string;
	/** Date and time in UTC when the user logged in for the last time. */
	lastSuccessfulLogin?: string;
	/** Login of the User */
	login?: string;
};
export type PatchMeRequest = {
	/** First name of the User */
	firstName?: string;
	/** Last name of the User */
	lastName?: string;
};
export type Features = {
	/** The list of currently enabled features for the current user. */
	enabledFeatures: string[];
};
export type PutPasswordRequest = {
	/** Password of the current user */
	currentPassword: string;
	/** New password for current user */
	newPassword: string;
};
export type Preferences = {
	/** NeoLoad Web theme currently used. */
	theme: 'LIGHT' | 'DARK';
	/** Id of the currently selected workspace id. */
	currentWorkspaceId?: string;
};
export type PatchPreferencesRequest = {
	/** Id of the currently selected workspace id. */
	currentWorkspaceId?: string;
	/** UI theme variant applied to NeoLoad Web. */
	theme?: 'LIGHT' | 'DARK';
};
export type Token = {
	/** Name of this token */
	name: string;
	/** Token */
	token: string;
};
export type TokenPage = {
	/** Items in the page */
	items: Token[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type CreateTokenRequest = {
	/** Name of the token that will be created. */
	name: string;
};
export type Reservation = {
	/** ID of the reservation */
	id: string;
	/** Zone ID */
	controllerZoneId: string;
	/** Date when the reservation ends. */
	endDate: string;
	/** The number of load generators reserved for a specific zone. */
	lgCountByZoneId: {
		[key: string]: number;
	};
	/** Title of the reservation. */
	name: string;
	/** Date when the reservation begins. */
	startDate: string;
	/** Status of a reservation:
     - AVAILABLE: The reservation is available and ready to be used
     - PARTIALLY_AVAILABLE: The reservation is available and ready to be used, but some load generators could not be reserved
     - PLANNED: The reservation is planned at a future date and can not be used at the moment
     - RUNNING: The reservation is currently being used
     - ENDED: The reservation has ended and is no longer available */
	status: 'AVAILABLE' | 'PARTIALLY_AVAILABLE' | 'PLANNED' | 'RUNNING' | 'ENDED';
	/** Unique identifier representing a Workspace */
	workspaceId: string;
	/** Controller version of the selected controller. Empty when infrastructure is NCP or Dynamic */
	controllerVersion?: string;
	/** Date and time in UTC when the entity was created. */
	createdAt?: string;
	/** Description of the reservation. */
	description?: string;
	/** Test ID is only present if it's a scheduled test */
	testId?: string;
	/** Date and time in UTC when the entity was updated for the last time */
	updatedAt?: string;
	/** Identifier of the user who modified the entity */
	userModifierName?: string;
	/** Count of VU which is reserved for each Vu type. Allowed keys are 'WEB' and 'SAP'. */
	vuCountByVuType?: {
		[key: string]: number;
	};
};
export type ReservationPage = {
	/** Items in the page */
	items: Reservation[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type PostReservation = {
	/** Zone ID */
	controllerZoneId: string;
	/** Date when the reservation ends. */
	endDate: string;
	/** The number of load generators reserved for a specific zone. */
	lgCountByZoneId: {
		[key: string]: number;
	};
	/** Title of the reservation. */
	name: string;
	/** Unique identifier representing a Workspace */
	workspaceId: string;
	/** Description of the reservation. */
	description?: string;
	/** Date when the reservation begins. */
	startDate?: string;
	/** Count of VU which will be reserved for each Vu type. Allowed keys are 'WEB' and 'SAP'. */
	vuCountByVuType?: {
		[key: string]: number;
	};
};
export type ScheduleReservation = {
	/** Schedule a test on a specific date, the configuration of the test will be used to create the reservation */
	testId: string;
	/** Zone ID */
	controllerZoneId?: string;
	/** Description of the reservation. */
	description?: string;
	/** Date when the reservation ends. */
	endDate?: string;
	/** The number of load generators reserved for a specific zone. */
	lgCountByZoneId?: {
		[key: string]: number;
	};
	/** Title of the reservation. */
	name?: string;
	/** Date when the reservation begins. */
	startDate?: string;
	/** Count of VU which will be reserved for each Vu type. Allowed keys are 'WEB' and 'SAP'. */
	vuCountByVuType?: {
		[key: string]: number;
	};
};
export type PatchReservation = {
	/** Zone ID */
	controllerZoneId?: string;
	/** Description of the reservation. */
	description?: string;
	/** Date when the reservation ends. */
	endDate?: string;
	/** The number of load generators reserved for a specific zone. */
	lgCountByZoneId?: {
		[key: string]: number;
	};
	/** Title of the reservation. */
	name?: string;
	/** Date when the reservation begins. */
	startDate?: string;
	/** Count of VU which will be reserved for each Vu type. Allowed keys are 'WEB' and 'SAP'. */
	vuCountByVuType?: {
		[key: string]: number;
	};
};
export type UserPathElementStatistic =
	| 'DURATION_PERCENTILES'
	| 'MINIMUM_DURATION'
	| 'AVERAGE_DURATION'
	| 'MAXIMUM_DURATION'
	| 'ELEMENT_COUNT'
	| 'ELEMENTS_PER_SECOND'
	| 'ERROR_COUNT'
	| 'ERRORS_PER_SECOND'
	| 'ERROR_RATE'
	| 'MINIMUM_TTFB'
	| 'AVERAGE_TTFB'
	| 'MAXIMUM_TTFB'
	| 'THROUGHPUT';
export type UserPathElement = {
	/** Unique identifier representing a specific Element. <br>
    Could be a UUID, a UUID with index or one of the following: <br>
    all-transactions <br>
    all-requests <br>
    all-pages <br>
    all-actions <br>
    init-container <br>
    actions-container <br>
    end-container <br>
     */
	id: string;
	/** List of children for this element */
	children: UserPathElement[];
	/** Name of the element */
	name: string;
	/** List of available statistics for this element */
	statistics: UserPathElementStatistic[];
	/** Type of the element */
	type:
		| 'USER_PATH'
		| 'SHARED_CONTAINERS'
		| 'INIT_CONTAINER'
		| 'ACTIONS_CONTAINER'
		| 'END_CONTAINER'
		| 'TRANSACTION'
		| 'REQUEST'
		| 'PAGE'
		| 'ACTION'
		| 'DELAY'
		| 'IF_THEN_ELSE_CONTAINER'
		| 'THEN'
		| 'ELSE'
		| 'TRY_CATCH_CONTAINER'
		| 'TRY'
		| 'CATCH'
		| 'SWITCH_CONTAINER'
		| 'SWITCH_CASE'
		| 'SWITCH_DEFAULT'
		| 'OTHER';
};
export type GetUserPathElementsResponse = {
	rootElements?: UserPathElement[];
};
export type ResultElementValue = {
	/** Unique identifier. */
	id: string;
	/** Entity name */
	name: string;
	statisticsValues: {
		/** 99th percentile of the transaction duration, in seconds. */
		DURATION_PERCENTILE_99?: number;
		/** 95th percentile of the transaction duration, in seconds. */
		DURATION_PERCENTILE_95?: number;
		/** 90th percentile of the transaction duration, in seconds. */
		DURATION_PERCENTILE_90?: number;
		/** 50th percentile of the transaction duration, in seconds. */
		DURATION_PERCENTILE_50?: number;
		/** Minimum response time, in seconds. */
		MINIMUM_DURATION?: number;
		/** Average response time, in seconds. */
		AVERAGE_DURATION?: number;
		/** Maximum response time, in seconds. */
		MAXIMUM_DURATION?: number;
		/** Number of full executions of an element. If the element is interrupted (because of error or end of test), then the count number is not incremented. */
		ELEMENT_COUNT?: number;
		/** Number of full executions of an element per second. */
		ELEMENTS_PER_SECOND?: number;
		/** Number of errors. */
		ERROR_COUNT?: number;
		/** Percentage of failed iterations out of count. */
		ERROR_RATE?: number;
		/** Minimum time to first byte, in seconds. */
		MINIMUM_TTFB?: number;
		/** Average time to first byte, in seconds. */
		AVERAGE_TTFB?: number;
		/** Maximum time to first byte, in seconds. */
		MAXIMUM_TTFB?: number;
	};
	/** Parent element of the item. */
	parent?: string;
	/** SLA status of the item if this item has SLA. */
	slaStatus?: 'SUCCESS' | 'WARN' | 'FAILED';
	/** The name of the user path in which the item is. */
	userPath?: string;
};
export type TotalValue = {
	/** The ID of the element representing all elements. Can be all-transactions, all-pages, all-requests or all-actions. */
	id: string;
	/** Entity name */
	name: string;
	statisticsValues: {
		/** 99th percentile of the transaction duration, in seconds. */
		DURATION_PERCENTILE_99?: number;
		/** 95th percentile of the transaction duration, in seconds. */
		DURATION_PERCENTILE_95?: number;
		/** 90th percentile of the transaction duration, in seconds. */
		DURATION_PERCENTILE_90?: number;
		/** 50th percentile of the transaction duration, in seconds. */
		DURATION_PERCENTILE_50?: number;
		/** Minimum response time, in seconds. */
		MINIMUM_DURATION?: number;
		/** Average response time, in seconds. */
		AVERAGE_DURATION?: number;
		/** Maximum response time, in seconds. */
		MAXIMUM_DURATION?: number;
		/** Number of full executions of an element. If the element is interrupted (because of error or end of test), then the count number is not incremented. */
		ELEMENT_COUNT?: number;
		/** Number of full executions of an element per second. */
		ELEMENTS_PER_SECOND?: number;
		/** Number of errors. */
		ERROR_COUNT?: number;
		/** Percentage of failed iterations out of count. */
		ERROR_RATE?: number;
		/** Minimum time to first byte, in seconds. */
		MINIMUM_TTFB?: number;
		/** Average time to first byte, in seconds. */
		AVERAGE_TTFB?: number;
		/** Maximum time to first byte, in seconds. */
		MAXIMUM_TTFB?: number;
	};
	/** Parent element of the item. */
	parent?: string;
	/** SLA status of the item if this item has SLA. */
	slaStatus?: 'SUCCESS' | 'WARN' | 'FAILED';
	/** The name of the user path in which the item is. */
	userPath?: string;
};
export type GetResultElementValuesResponse = {
	/** Items in the page */
	items: ResultElementValue[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
	totalValue?: TotalValue;
};
export type PercentileValue = {
	/** The percentile */
	percentile: number;
	/** The duration in milliseconds, with a 0.1 accuracy */
	value: number;
};
export type FetchElementPercentilesResponse = {
	points: PercentileValue[];
};
export type ElementPoint = {
	/** Offset relative to the result start date (ISO 8601 format). */
	offset: string;
	statisticsValues: {
		/** Minimum response time, in seconds. */
		MINIMUM_DURATION?: number;
		/** Average response time, in seconds. */
		AVERAGE_DURATION?: number;
		/** Maximum response time, in seconds. */
		MAXIMUM_DURATION?: number;
		/** Number of full executions of an element. If the element is interrupted (because of error or end of test), then the count number is not incremented. */
		ELEMENT_COUNT?: number;
		/** Number of full executions of an element per second. */
		ELEMENTS_PER_SECOND?: number;
		/** Number of errors. */
		ERROR_COUNT?: number;
		/** Average errors per second. */
		ERRORS_PER_SECOND?: number;
		/** Percentage of failed iterations out of count. */
		ERROR_RATE?: number;
		/** Minimum time to first byte, in seconds. */
		MINIMUM_TTFB?: number;
		/** Average time to first byte, in seconds. */
		AVERAGE_TTFB?: number;
		/** Maximum time to first byte, in seconds. */
		MAXIMUM_TTFB?: number;
		/** Throughput in byte/s. */
		THROUGHPUT?: number;
	};
};
export type ElementTimeSeries = {
	/** The list of points */
	points: ElementPoint[];
	/** The duration between points (ISO 8601 format) */
	samplingInterval: string;
	/** True if the points list contains all points since the beginning of the result */
	isFromScratch?: boolean;
	/** The next request token. This is present when fetching on a running result, in order to use it on the next fetch, to get the following points */
	nextRequestToken?: string;
};
export type Event = {
	/** The unique id of the Event. */
	id: string;
	/** Event duration (ISO 8601 format). */
	duration: string;
	/** Indicates that the event has a screenshot attached. */
	hasScreenshot: boolean;
	/** The offset (ISO 8601 format) of the Event from the beginning of the test */
	offset: string;
	/** The name of the element that raised the Event. */
	source: string;
	/** The type of the Event. */
	type: 'ERROR' | 'SLA' | 'OBJECTIVE' | 'ALERT' | 'CUSTOM' | 'ANNOTATION';
	/** The code of the Event, it could be an HTTP response code in case of error event, a SLA warning (SLA w) or SLA critical (SLA c), an Objective passed (Objective p) or Objective failed (Objective f),
    a Monitoring warning (Monitoring w) or Monitoring critical (Monitoring c). */
	code?: string;
	/** The unique id of the element that raised the event. */
	elementId?: string;
	/** The full name of the Event. */
	fullName?: string;
};
export type EventPage = {
	/** Items in the page */
	items: Event[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type AlertEventDetails = {
	/** Accuracy of the alert */
	accuracy?: number;
	/** Expected value of the alert */
	expectedValue?: number;
	/** Monitor of the alert */
	monitor?: string;
	/** Monitored machine of the alert */
	monitoredMachine?: string;
	/** Population name of the alert */
	populationName?: string;
	/** Severity of the alert */
	severity?: string;
	/** Sla profile id of the alert */
	slaProfileId?: string;
	/** Sla type of the alert */
	slaType?: string;
	/** Status of the alert */
	status?: string;
	/** Threshold condition id of the alert */
	thresholdConditionId?: string;
	/** Threshold id of the alert */
	thresholdId?: string;
	/** User path id of the alert */
	userPathId?: string;
	/** Value of the alert */
	value?: string;
	/** Vuname of the alert */
	vuName?: string;
	/** Zone id of the alert */
	zoneId?: string;
};
export type AlertEventWithDetails = {
	/** The unique id of the Event. */
	id: string;
	details: AlertEventDetails;
	/** Event duration (ISO 8601 format). */
	duration: string;
	/** Indicates that the event has a screenshot attached. */
	hasScreenshot: boolean;
	/** The offset (ISO 8601 format) of the Event from the beginning of the test */
	offset: string;
	/** The name of the element that raised the Event. */
	source: string;
	/** The type of the Event. */
	type: 'ALERT' | 'SLA' | 'OBJECTIVE';
	/** The code of the Event, it could be an HTTP response code in case of error event, a SLA warning (SLA w) or SLA critical (SLA c), an Objective passed (Objective p) or Objective failed (Objective f),
    a Monitoring warning (Monitoring w) or Monitoring critical (Monitoring c). */
	code?: string;
	/** The unique id of the element that raised the event. */
	elementId?: string;
	/** The full name of the Event. */
	fullName?: string;
};
export type CustomEventDetails = {
	/** Description of the Custom Event */
	description?: string;
	/** Url of the Custom Event */
	url?: string;
};
export type CustomEventWithDetails = {
	/** The unique id of the Event. */
	id: string;
	details: CustomEventDetails;
	/** Event duration (ISO 8601 format). */
	duration: string;
	/** Indicates that the event has a screenshot attached. */
	hasScreenshot: boolean;
	/** The offset (ISO 8601 format) of the Event from the beginning of the test */
	offset: string;
	/** The name of the element that raised the Event. */
	source: string;
	/** The type of the Event. */
	type: 'CUSTOM';
	/** The code of the Event, it could be an HTTP response code in case of error event, a SLA warning (SLA w) or SLA critical (SLA c), an Objective passed (Objective p) or Objective failed (Objective f),
    a Monitoring warning (Monitoring w) or Monitoring critical (Monitoring c). */
	code?: string;
	/** The unique id of the element that raised the event. */
	elementId?: string;
	/** The full name of the Event. */
	fullName?: string;
};
export type AssertionResult = {
	localizedMessage?: string;
	type?: 'PASSED' | 'FAILURE' | 'ERROR';
};
export type ErrorEventDetails = {
	/** Assertions of the error. */
	assertionResults?: AssertionResult[];
	/** Iteration number of the error. */
	iterationNumber?: number;
	/** Lg name of the error. */
	lgName?: string;
	/** Population name of the error. */
	populationName?: string;
	/** Request of the error. */
	request?: string;
	/** Request duration of the error. */
	requestDuration?: number;
	/** Transaction of the error. */
	transaction?: string;
	/** User path id of the error. */
	userPathId?: string;
	/** User path instance of the error. */
	userPathInstance?: number;
	/** Virtual user step of the error. */
	virtualUserStep?: string;
	/** Zone id of the error. */
	zoneId?: string;
};
export type RequestOrResponseDetails = {
	/** The identifier of the content, it could be use in the endpoint /{resultId}/events/contents/{contentId} to retrieve the content. */
	contentId?: string;
	/** The headers of the request or the headers of the response. */
	headers?: string;
	/** The HTTP request method plus the URL for the request or the response code for the response. */
	statusLine?: string;
};
export type ErrorEventWithDetails = {
	/** The unique id of the Event. */
	id: string;
	details: ErrorEventDetails;
	/** Event duration (ISO 8601 format). */
	duration: string;
	/** Indicates that the event has a screenshot attached. */
	hasScreenshot: boolean;
	/** The offset (ISO 8601 format) of the Event from the beginning of the test */
	offset: string;
	/** The name of the element that raised the Event. */
	source: string;
	/** The type of the Event. */
	type: 'ERROR';
	/** The code of the Event, it could be an HTTP response code in case of error event, a SLA warning (SLA w) or SLA critical (SLA c), an Objective passed (Objective p) or Objective failed (Objective f),
    a Monitoring warning (Monitoring w) or Monitoring critical (Monitoring c). */
	code?: string;
	currentRequest?: RequestOrResponseDetails;
	currentResponse?: RequestOrResponseDetails;
	/** The unique id of the element that raised the event. */
	elementId?: string;
	firstIterationCurrentRequest?: RequestOrResponseDetails;
	firstIterationCurrentResponse?: RequestOrResponseDetails;
	firstIterationPreviousRequest?: RequestOrResponseDetails;
	firstIterationPreviousResponse?: RequestOrResponseDetails;
	/** The full name of the Event. */
	fullName?: string;
	previousRequest?: RequestOrResponseDetails;
	previousResponse?: RequestOrResponseDetails;
	/** The identifier of the screenshot attached to this error. */
	screenshotId?: string;
};
export type AnnotationEventDetails = {
	/** Color of the Annotation */
	color?: string;
	/** Description of the Annotation Event */
	description?: string;
};
export type AnnotationEventWithDetails = {
	/** The unique id of the Event. */
	id: string;
	details: AnnotationEventDetails;
	/** Event duration (ISO 8601 format). */
	duration: string;
	/** Indicates that the event has a screenshot attached. */
	hasScreenshot: boolean;
	/** The offset (ISO 8601 format) of the Event from the beginning of the test */
	offset: string;
	/** The name of the element that raised the Event. */
	source: string;
	/** The type of the Event. */
	type: 'ANNOTATION';
	/** The code of the Event, it could be an HTTP response code in case of error event, a SLA warning (SLA w) or SLA critical (SLA c), an Objective passed (Objective p) or Objective failed (Objective f),
    a Monitoring warning (Monitoring w) or Monitoring critical (Monitoring c). */
	code?: string;
	/** The unique id of the element that raised the event. */
	elementId?: string;
	/** The full name of the Event. */
	fullName?: string;
};
export type EventWithDetails =
	| ({
			type: 'ALERT';
	  } & AlertEventWithDetails)
	| ({
			type: 'CUSTOM';
	  } & CustomEventWithDetails)
	| ({
			type: 'ERROR';
	  } & ErrorEventWithDetails)
	| ({
			type: 'ANNOTATION';
	  } & AnnotationEventWithDetails)
	| ({
			type: 'OBJECTIVE';
	  } & AlertEventWithDetails)
	| ({
			type: 'SLA';
	  } & AlertEventWithDetails);
export type StoreCustomEventInput = {
	/** Full name of the Custom Event */
	fullName: string;
	/** The start offset (ISO 8601 format) of the Custom Event from the beginning of the Result */
	startOffset: string;
	/** Type of the Custom Event Timeframe */
	timeframe: 'INSTANT' | 'TIME_RANGE';
	type: 'CUSTOM';
	/** Code of the Custom Event */
	code?: string;
	details?: CustomEventDetails;
	/** The end offset (ISO 8601 format) of the Custom Event from the beginning of the Result */
	endOffset?: string;
	/** Source of the Custom Event */
	source?: string;
};
export type StoreAnnotationEventInput = {
	/** Full name of the Annotation */
	fullName: string;
	/** The start offset (ISO 8601 format) of the Annotation from the beginning of the Result */
	startOffset: string;
	type: 'ANNOTATION';
	details?: AnnotationEventDetails;
	/** Source of the Annotation */
	source?: string;
};
export type StoreEventInput = StoreCustomEventInput | StoreAnnotationEventInput;
export type EventContent = {
	/** The unique id of the Content. */
	id: string;
	/** The Content itself. For a screenshot, it will be encoded as a base 64 String. */
	stringContent: string;
	/** Indicates that the Content was too long and has been truncated during runtime. */
	truncated: boolean;
};
export type ErrorCountByCode = {
	/** Error code */
	code: string;
	/** Number of errors */
	count: number;
};
export type ErrorCountByCodePage = {
	/** Items in the page */
	items: ErrorCountByCode[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type ErrorCountByElement = {
	/** Number of errors */
	count: number;
	/** Element name */
	element: string;
	/** Parent name */
	parent: string;
	/** Userpath name */
	userPath: string;
};
export type ErrorCountByElementPage = {
	/** Items in the page */
	items: ErrorCountByElement[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type ErrorCountByLg = {
	/** Number of errors */
	count: number;
	/** Load Generator on which the error was encountered */
	lg: string;
};
export type ErrorCountByLgPage = {
	/** Items in the page */
	items: ErrorCountByLg[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type ErrorsPage =
	| ({
			type: 'CODE';
	  } & ErrorCountByCodePage)
	| ({
			type: 'ELEMENT';
	  } & ErrorCountByElementPage)
	| ({
			type: 'LG';
	  } & ErrorCountByLgPage);
export type PatchCustomEventInput = {
	/** Type of the Event */
	type: 'CUSTOM';
	details?: CustomEventDetails;
	/** The offset (ISO 8601 format) of the Event from the beginning of the Result */
	endOffset?: string;
};
export type PatchAnnotationEventInput = {
	/** Type of the Event */
	type: 'ANNOTATION';
	details?: AnnotationEventDetails;
	/** The offset (ISO 8601 format) of the end of the Annotation from the beginning of the Result. If EndOffset equals StartOffset it means that the Annotation is about a single moment. */
	endOffset?: string;
	/** The name of the Annotation. */
	fullName?: string;
	/** The Source of the Annotation. */
	source?: string;
	/** The offset (ISO 8601 format) of the start of the Annotation from the beginning of the Result */
	startOffset?: string;
};
export type PatchEventInput = PatchCustomEventInput | PatchAnnotationEventInput;
export type Interval = {
	/** Unique ID of the Interval. */
	id: string;
	/** The offset (ISO 8601 format) of the <b>end of the Interval</b> from the beginning of the result. */
	endOffset: string;
	/** Name of the Interval. */
	name: string;
	/** The offset (ISO 8601 format) of the <b>beginning of the Interval</b> from the beginning of the result. */
	startOffset: string;
	/** Color of the Interval as an hexadecimal string. */
	color?: string;
	/** Description of the Interval. */
	description?: string;
	/** Whether the interval is hidden on the overview graphs */
	hidden?: boolean;
	/** Identifier of the user who modified the entity */
	userModifierName?: string;
};
export type IntervalPage = {
	/** Items in the page */
	items: Interval[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type IntervalPostRequest = {
	/** The offset (ISO 8601 format) of the <b>end of the Interval</b> from the beginning of the result. */
	endOffset: string;
	/** Name of the Interval. */
	name: string;
	/** The offset (ISO 8601 format) of the <b>beginning of the Interval</b> from the beginning of the result. */
	startOffset: string;
	/** Color of the Interval as an hexadecimal string. */
	color?: string;
	/** Description of the Interval. */
	description?: string;
	/** Hide the interval on the overview graphs */
	hidden?: boolean;
};
export type IntervalPatchRequest = {
	/** Color of the Interval as an hexadecimal string. */
	color?: string;
	/** Description of the Interval. */
	description?: string;
	/** The offset (ISO 8601 format) of the <b>end of the Interval</b> from the beginning of the result. */
	endOffset?: string;
	/** Hide the interval on the overview graphs */
	hidden?: boolean;
	/** Name of the Interval. */
	name?: string;
	/** The offset (ISO 8601 format) of the <b>beginning of the Interval</b> from the beginning of the result. */
	startOffset?: string;
};
export type ResultLogEntry = {
	/** Date and time in UTC when this log occurs. */
	date: string;
	/** Level of log. */
	level: 'INFO' | 'ERROR';
	/** Text of the log line. */
	line: string;
};
export type ResultLogsResponse = {
	/** Items in the page */
	items: ResultLogEntry[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type MonitorElement = {
	/** ID of the element */
	id: string;
	/** List of children for this element */
	children: MonitorElement[];
	/** Returns true if a timeseries can be drawn for this monitor */
	drawable: boolean;
	/** Name of the element */
	name: string;
	/** Unit in which this monitor's values are expressed */
	unit?: string;
};
export type GetMonitorElementsResponse = {
	rootElements?: MonitorElement[];
};
export type MonitorValues = {
	/** Monitor unique identifier. */
	id: string;
	/** Monitor name. */
	name: string;
	/** Average of values for the monitor. */
	avg?: number;
	/** Maximum value for the monitor. */
	max?: number;
	/** Minimum value for the monitor. */
	min?: number;
};
export type MonitorsValuesPage = {
	/** Items in the page */
	items: MonitorValues[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type MonitorPoint = {
	/** Offset relative to the result start date (ISO 8601 format). */
	offset: string;
	/** Value of the monitor in the time range. */
	value: number;
};
export type MonitorTimeSeries = {
	/** The list of points */
	points: MonitorPoint[];
	/** The duration between points (ISO 8601 format) */
	samplingInterval: string;
	/** True if the points list contains all points since the beginning of the result */
	isFromScratch?: boolean;
	/** The next request token. This is present when fetching on a running result, in order to use it on the next fetch, to get the following points */
	nextRequestToken?: string;
};
export type SlaThresholdCondition = {
	/** SLA Threshold operator */
	operator: 'LESS_THAN' | 'GREATER_THAN' | 'EQUALS' | 'BETWEEN';
	/** SLA Threshold severity */
	severity: 'WARNING' | 'CRITICAL';
	/** SLA Threshold max */
	max?: number;
	/** SLA Threshold min */
	min?: number;
};
export type SlaThreshold = {
	/** Id of SLA Threshold */
	id: string;
	/** Conditions of SLA Threshold */
	conditions: SlaThresholdCondition[];
	/** Family of SLA Threshold */
	family: 'PER_RUN' | 'PER_TIME_INTERVAL';
	/** Identifier of SLA Threshold */
	identifier:
		| 'AVERAGE_REQUEST_RESPONSE_TIME'
		| 'AVERAGE_PAGE_RESPONSE_TIME'
		| 'AVERAGE_CONTAINER_RESPONSE_TIME'
		| 'PERCENTILE_CONTAINER_RESPONSE_TIME'
		| 'AVERAGE_HITS_PER_SECOND'
		| 'AVERAGE_THROUGHPUT_PER_SECOND'
		| 'TOTAL_ERRORS'
		| 'TOTAL_HITS'
		| 'TOTAL_THROUGHPUT'
		| 'AVERAGE_RESPONSE_TIME'
		| 'ERRORS_PER_SECOND'
		| 'ERROR_PERCENTILE';
};
export type SlaPerInterval = {
	/** Id of element */
	elementId: string;
	/** Name of element */
	elementName: string;
	/** Type of element */
	elementType: 'SCENARIO' | 'TRANSACTION' | 'PAGE' | 'ACTION' | 'REQUEST';
	/** Percentage of intervals failed */
	failPercentage: number;
	/** Name of parent */
	parentName: string;
	/** Id of population */
	populationId: string;
	slaThreshold: SlaThreshold;
	/** Status of SLA */
	status: 'SUCCESS' | 'WARN' | 'FAILED';
	/** Id of User path */
	userPathId: string;
	/** Name of User path */
	userPathName: string;
	/** Percentage of intervals warning */
	warnPercentage: number;
	/** Id of zone */
	zoneId: string;
};
export type SlaPerIntervalPage = {
	/** Items in the page */
	items: SlaPerInterval[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type SlaPerRun = {
	/** Id of element */
	elementId: string;
	/** Name of element */
	elementName: string;
	/** Type of element */
	elementType: 'SCENARIO' | 'TRANSACTION' | 'PAGE' | 'ACTION' | 'REQUEST';
	/** Name of parent */
	parentName: string;
	slaThreshold: SlaThreshold;
	/** Status of SLA */
	status: 'SUCCESS' | 'WARN' | 'FAILED';
	/** Name of User path */
	userPathName: string;
	/** Value of SLA */
	value: number;
};
export type SlaPerRunPage = {
	/** Items in the page */
	items: SlaPerRun[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type SlaPage =
	| ({
			type: 'PER_TIME_INTERVAL';
	  } & SlaPerIntervalPage)
	| ({
			type: 'PER_RUN';
	  } & SlaPerRunPage);
export type TestResultStatistics = {
	totalGlobalCountFailure: number;
	/** Total size of http traffic received in bytes. */
	totalGlobalDownloadedBytes: number;
	/** Average size of http traffic received in bytes per second. */
	totalGlobalDownloadedBytesPerSecond: number;
	/** Total number of times when the 'Actions' Container was run for each Virtual User and encountered an error. */
	totalIterationCountFailure: number;
	/** Total number of times when the 'Actions' Container was run for each Virtual User without encountering any error. */
	totalIterationCountSuccess: number;
	/** Total number of requests in the scenario that encountered errors. */
	totalRequestCountFailure: number;
	/** Total number of requests in the scenario that are performed per second. */
	totalRequestCountPerSecond: number;
	/** Total number of requests in the scenario that did not encounter any error. */
	totalRequestCountSuccess: number;
	/** Average time in seconds for all requests of the scenario to be performed (from first byte sent to last byte received). */
	totalRequestDurationAverage: number;
	/** Total number of the scenario Transactions executed with errors. */
	totalTransactionCountFailure: number;
	/** Total number of the scenario Transactions executed per second. */
	totalTransactionCountPerSecond: number;
	/** Total number of the scenario Transactions executed without encountering any error. */
	totalTransactionCountSuccess: number;
	/** Average time in seconds for all Transactions of the scenario to be executed. */
	totalTransactionDurationAverage: number;
	/** Last value received for the number of requests in the scenario that are performed per second. Only present when the result is running. */
	lastRequestCountPerSecond?: number;
	/** Last value received for the average time in seconds for all Transactions of the scenario. Only present when the result is running. */
	lastTransactionDurationAverage?: number;
	/** Last value received for the total number of Virtual Users executed in the test. Only present when the result is running. */
	lastVirtualUserCount?: number;
};
export type Point = {
	/** Start offset (ISO 8601 format). */
	offset: string;
	/** Average response time in seconds of all requests */
	requestAvgDuration?: number;
	/** Number of all requests per second */
	requestCountPerSecond?: number;
	/** Errors */
	requestErrors?: number;
	/** User Load */
	userLoad?: number;
};
export type ResultTimeseries = {
	/** The list of points */
	points: Point[];
	/** The duration between points (ISO 8601 format) */
	samplingInterval: string;
	/** True if the points list contains all points since the beginning of the result */
	isFromScratch?: boolean;
	/** The next request token. This is present when fetching on a running result, in order to use it on the next fetch, to get the following points */
	nextRequestToken?: string;
};
export type ExternalUrl = {
	/** Url of the link */
	url: string;
	/** Label of the link */
	title?: string;
};
export type SimpleTestSettingsDefinition = {
	/** The unique id of the test */
	id: string;
	/** The name of the test */
	name: string;
};
export type TestResult = {
	/** The unique id of the Test Result. */
	id: string;
	/** First and Last name of the person who launched the test. */
	author: string;
	/** Indicates that the Test Result is in debug mode */
	debug: boolean;
	/** Test Result duration (ISO 8601 format). During running, if the result don't have a planned duration, the value is PT0S. */
	duration: string;
	/** Total number of Load Generators used in the Test Result. */
	lgCount: number;
	/** Indicates the load injection provider used for running the test. */
	loadInjectionProvider: 'NEOLOAD' | 'JMETER';
	/** Indicates that the Test Result is protected against automatic deletion. */
	locked: boolean;
	/** Name of the Test Result. */
	name: string;
	/** Name of the project. */
	project: string;
	/** Quality Status of the Test Result. */
	qualityStatus: 'PASSED' | 'FAILED' | 'COMPUTING' | 'UNKNOWN';
	/** Name of the scenario. */
	scenario: string;
	/** Date and time in UTC when the test started. */
	startDate: string;
	/** True when the Result has been started from the platform NeoLoad Web or from the NeoLoad CLI. False when it has been started from the Controller GUI or NeoLoadCMD. */
	startedByNlw: boolean;
	/** Status of the Test Result. */
	status: 'INIT' | 'STARTING' | 'RUNNING' | 'TERMINATED' | 'UNKNOWN';
	/** Reason that caused the test to end. */
	terminationReason:
		| 'POLICY'
		| 'VARIABLE'
		| 'MANUAL'
		| 'LG_AVAILABILITY'
		| 'LICENSE'
		| 'FAILED_TO_START'
		| 'CANCELLED'
		| 'RESERVATION_ENDED'
		| 'UNKNOWN';
	/** Maximum number of VUs required by the scenario when it starts. Default to 0 if unknown. */
	vusCount: number;
	/** Unique identifier representing a Workspace. */
	workspaceId: string;
	/** Description of the Test Result. */
	description?: string;
	/** Date and time in UTC when the test stopped. During running, this is the planned end date. It is empty during running when the duration is unknown. */
	endDate?: string;
	errors?: number;
	externalUrl?: ExternalUrl;
	requestPerSecond?: number;
	/** The pace at which statistics will be updated during runtime (ISO 8601 format). */
	statisticsSamplingInterval?: string;
	test?: SimpleTestSettingsDefinition;
	transactionTime?: number;
};
export type TestResultPage = {
	/** Items in the page */
	items: TestResult[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type SimpleScenario = {
	/** The name of the scenario. */
	name: string;
};
export type ProjectScenarios = {
	/** The name of the project. */
	name: string;
	/** The list of scenario names for the project. */
	scenarios: SimpleScenario[];
};
export type TestResultSearchCriteria = {
	/** The list of project names attached to Test Results that belong to the specified workspace. */
	projects: ProjectScenarios[];
	/** The list of tests attached to Test Results that belong to the specified workspace. */
	tests: SimpleTestSettingsDefinition[];
};
export type TestResultInput = {
	/** Description of the Test Result. */
	description?: string;
	externalUrl?: ExternalUrl;
	/** Locks a Test Result (avoid automatic deletion). */
	locked?: boolean;
	/** Name of the Test Result. */
	name?: string;
	/** Quality Status of the Test Result. */
	qualityStatus?: 'PASSED' | 'FAILED' | 'UNKNOWN';
	/** Unique identifier of the test. */
	testId?: string;
	/** Unique identifier representing a Workspace. */
	workspaceId?: string;
};
export type ResultContext = {
	/** List of population names of this result. */
	populations: string[];
	/** List of user path of this result. */
	userPaths: string[];
	/** List of zone names of this result. */
	zones: string[];
};
export type ScmCredentials = {
	/** Login for private repository */
	login: string;
};
export type Repository = {
	/** ID */
	id: string;
	/** Name of the repository */
	name: string;
	/** Full url of the scm repository */
	url: string;
	credentials?: ScmCredentials;
	/** SCM infrastructure type used */
	type?: 'GIT';
};
export type RepositoryPage = {
	/** Items in the page */
	items: Repository[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type Reference = {
	name: string;
};
export type RepositoryWithReferences = {
	/** ID */
	id: string;
	/** Name of the repository */
	name: string;
	/** References (branches) of the repository */
	references: Reference[];
	/** Full url of the scm repository */
	url: string;
	credentials?: ScmCredentials;
	/** SCM infrastructure type used */
	type?: 'GIT';
};
export type ScmCredentialsWithPassword = {
	/** Login for private repository */
	login: string;
	/** Password (or private access token) for private repository */
	password: string;
} | null;
export type CreateRepository = {
	/** Name of the repository */
	name: string;
	/** Full url of the scm repository */
	url: string;
	credentialsWithPassword?: ScmCredentialsWithPassword;
	/** SCM infrastructure type used */
	type?: 'GIT';
};
export type UpdateRepository = {
	credentialsWithPassword?: ScmCredentialsWithPassword;
	/** Name of the repository */
	name?: string;
	/** SCM infrastructure type used */
	type?: 'GIT';
	/** Full url of the scm repository */
	url?: string;
};
export type ReferencePage = {
	/** Items in the page */
	items: Reference[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type Account = {
	/** ID of the Account */
	id: string;
	/** Name of the Account */
	name: string;
};
export type CreateSessionResponse = {
	/** Accounts of this user.
    Contains all accounts if account id was not specified in request and user has multiple accounts.
    Contains the account used to create the session if the user has only one account or the account id was specified in the request. */
	accounts: Account[];
};
export type CreateSessionRequest = {
	/** User login */
	login: string;
	/** Password */
	password: string;
	/** ID of the Account */
	accountId?: string;
};
export type InformationResponse = {
	/** The files API URL */
	fileStorageUrl: string;
	/** Maximum number of Load Generator which can be used per project size
     Limitation applied only with NCP infrastructure when using multiple zones */
	maximumLgCountPerProjectSize: {
		[key: string]: number;
	};
	/** Project maximum size in bytes */
	projectMaxSize: number;
	/** The public API URL */
	publicApiUrl: string;
	/** The version of the application */
	version: string;
};
export type PasswordPolicy = {
	/** Minimum count of digit characters in the password */
	minimumDigits: number;
	/** Password minimum length */
	minimumLength: number;
	/** Minimum count of lower case characters in the password */
	minimumLowerCaseCharacters: number;
	/** Minimum count of special characters in the password */
	minimumSpecialCharacters: number;
	/** Minimum count of upper case characters in the password */
	minimumUpperCaseCharacters: number;
	/** All characters considered as special characters */
	specialCharacters: string;
};
export type Settings = {
	passwordPolicy: PasswordPolicy;
	/** Current reservation mode */
	reservationModeStatus: 'RESERVATION_MODE_ENABLED' | 'RESERVATION_MODE_DISABLED';
};
export type PatchSettingsInput = {
	/** Reservation mode status */
	reservationModeStatus?: 'RESERVATION_MODE_ENABLED' | 'RESERVATION_MODE_DISABLED';
};
export type CloudCredits = {
	/** Number of available Cloud Credits */
	available: number;
	/** Number of initial Cloud Credits */
	initial: number;
};
export type MonthlySavedResultDuration = {
	/** Number of available minutes of current month for saved results. Deleted results duration are not subtracted from this value. */
	availableInMinutes: number;
	/** Total number of minutes of current month for saved results. This total is computed by adding : available credits, reserved credits and used credits. Deleted results duration are not subtracted from this value. */
	totalInMinutes: number;
};
export type TotalSavedResultDuration = {
	/** Number of available minutes for all saved results */
	availableInMinutes: number;
	/** Total number of minutes for all saved results. This total is computed by adding : available credits, reserved credits and used credits */
	totalInMinutes: number;
};
export type Vuhs = {
	/** Number of available VUHs */
	available: number;
	/** Number of initial VUHs */
	initial: number;
};
export type Subscription = {
	/** Expiration date of Neoload SaaS plan. A null value implies that the plan is inactive or expired. */
	expirationDate: string | null;
	cloudCredits?: CloudCredits;
	monthlySavedResultDuration?: MonthlySavedResultDuration;
	totalSavedResultDuration?: TotalSavedResultDuration;
	vuhs?: Vuhs;
};
export type PartialError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'PARTIAL_ERROR';
	/** Description of the error */
	description?: string;
	/** Errors encountered during execution */
	errors?: ApiError[];
	/** Result of the successful part of the request */
	result?: object;
};
export type SubscriptionPartialError = PartialError & {
	/** Fixed value, it define the schema */
	category?: 'PARTIAL_ERROR';
	/** Description of the error */
	description?: string;
	/** Errors encountered during execution */
	errors?: ApiError[];
	result?: Subscription;
};
export type ConcurrentRuns = {
	/** Currency available */
	available: number;
	/** Maximum currency available in the license */
	licenseMaximum: number;
	/** Maximum currency available in the workspace */
	workspaceMaximum: number;
};
export type SapVus = {
	/** Currency available */
	available: number;
	/** Maximum currency available in the license */
	licenseMaximum: number;
	/** Maximum currency available in the workspace */
	workspaceMaximum: number;
};
export type WebVus = {
	/** Currency available */
	available: number;
	/** Maximum currency available in the license */
	licenseMaximum: number;
	/** Maximum currency available in the workspace */
	workspaceMaximum: number;
};
export type WorkspaceSubscription = {
	cloudCredits?: CloudCredits;
	concurrentRuns?: ConcurrentRuns;
	sapVu?: SapVus;
	vuhs?: Vuhs;
	webVu?: WebVus;
};
export type WorkspaceSubscriptionPartialError = PartialError & {
	/** Fixed value, it define the schema */
	category?: 'PARTIAL_ERROR';
	/** Description of the error */
	description?: string;
	/** Errors encountered during execution */
	errors?: ApiError[];
	result?: WorkspaceSubscription;
};
export type TestExecutionConfiguration = {
	/** The zone id where will be located the controller */
	controllerZoneId: string;
	/** Count of LG for each zone id used */
	lgCountByZoneId: {
		[key: string]: number;
	};
	/** Project name of the test run */
	projectName: string;
	/** Scenario name of the test run */
	scenarioName: string;
	/** Test setting id which will be used to configure the test execution */
	testId: string;
	/** Count of VU needed for each Vu type to run the test. Allowed keys are 'WEB' and 'SAP'. */
	vuCountByVuType: {
		[key: string]: number;
	};
	/** The zone type of the controller and LG */
	zoneType: 'STATIC' | 'DYNAMIC' | 'NEOLOAD_CLOUD';
	/** Minimum resources version compatible with this project, may be unknown if NeoLoad Web version is older than the NeoLoad GUI version used. */
	minimumResourcesVersion?: string;
	/** Duration of the scenario, duration is absent in iteration or when the scenario have an unlimited duration */
	testDuration?: string;
};
export type TestExecutionResource = {
	/** The zone where was located the controller */
	controllerZoneId: string;
	/** Count of LG for each zone id used */
	lgCountByZoneId: {
		[key: string]: number;
	};
	/** True when the test execution is in Cloud over consumption. Present when Cloud infrastructure is used. */
	cloudOverConsumption?: boolean;
	/** Count of concurrency consumed for each consumable concurrency type. Allowed keys are 'VUH' and 'CLOUD_CREDITS'. */
	consumedCurrencyByConcurrencyType?: {
		[key: string]: number;
	};
	/** Maximum duration of the execution. */
	maximumDuration?: string;
	/** Missing dedicated IPs by Cloud zone */
	missingDedicatedIpByZone?: {
		[key: string]: number;
	};
	/** ID of the reservation used for the test */
	reservationId?: string;
	/** Resource version used for the test run */
	resourcesVersion?: string;
	/** Count of concurrency used for each reusable concurrency type. Allowed keys are 'WEB' and 'SAP'. */
	usedConcurrencyByConcurrencyType?: {
		[key: string]: number;
	};
};
export type TestExecution = {
	/** ID of the test execution, it is the same id which will be used for the test results */
	id: string;
	configuration: TestExecutionConfiguration;
	/** Test execution and result name */
	name: string;
	resources: TestExecutionResource;
	/** Unique identifier representing a Workspace. */
	workspaceId: string;
};
export type TestExecutionPartialError = PartialError & {
	/** Fixed value, it define the schema */
	category?: 'PARTIAL_ERROR';
	/** Description of the error */
	description?: string;
	/** Errors encountered during execution */
	errors?: ApiError[];
	result?: TestExecution;
};
export type TestExecutionInput = {
	/** Test setting id which will be used to configure the test execution */
	testId: string;
	/** Test execution description */
	description?: string;
	/** Test duration */
	duration?: string;
	/** Test execution name */
	name?: string;
	/** Requested Reservation ID */
	reservationId?: string;
	/** Requested SAP VU count */
	sapVu?: number;
	/** Requested Web VU count */
	webVu?: number;
};
export type GetTestExecutionResponse = {
	/** ID of the test execution, it is the same id which will be used for the test results */
	id: string;
	/** Workflow status of the test execution */
	step?:
		| 'INITIALIZING'
		| 'PREPARING_CONTROLLER'
		| 'FAILED_TO_PREPARE_CONTROLLER'
		| 'PREPARING_LGS'
		| 'FAILED_TO_PREPARE_LGS'
		| 'SENDING_PROJECT'
		| 'FAILED'
		| 'PREPARING_TEST'
		| 'STARTING_TEST'
		| 'STARTED_TEST'
		| 'CANCELLED';
};
export type Test = {
	/** ID */
	id: string;
	/** Date and time in UTC when the entity was created. */
	createdAt: string;
	/** Whether the project if fully configured and ready to run. */
	isConfigured: boolean;
	/** The load injection provider to use for running this test. */
	loadInjectionProvider: 'NEOLOAD' | 'JMETER';
	/** Test name, name need to be unique between all tests in a same workspace */
	name: string;
	/** Test Result name template. Variable ${runID} is available, it's a value which is incremented for each run done on the test */
	testResultNameTemplate: string;
	/** Date and time in UTC when the entity was updated for the last time */
	updatedAt: string;
	/** Unique identifier representing a Workspace. */
	workspaceId: string;
	/** Controller environment variables. Allows to override controller.properties with a key NL_PROPERTY_[CATEGORY]_[MY_PROPERTY] and a value [VALUE] */
	controllerEnvironmentVariables?: {
		[key: string]: string;
	};
	/** Controller zone, the zoneId is given */
	controllerZoneId?: string;
	/** Test description */
	description?: string;
	/** Date and time in UTC when the last run on this test was done. */
	lastRunDate?: string;
	/** Load Generators zones, the key is the zoneId */
	lgsByZone?: {
		[key: string]: number;
	};
	/** Project name used in the test setting */
	projectName?: string;
	/** Selected scenario in the uploaded project or test name for selecting the custom scenario */
	selectedScenarioName?: string;
	/** Identifier of the user who modified the entity */
	userModifierName?: string;
};
export type TestPage = {
	/** Items in the page */
	items: Test[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type TestInput = {
	/** Test name, name need to be unique between all tests in a same workspace */
	name: string;
	/** Unique identifier representing a Workspace */
	workspaceId: string;
	/** Test description */
	description?: string;
	/** The load injection provider to use for running this test. */
	loadInjectionProvider?: 'NEOLOAD' | 'JMETER';
};
export type PatchTestInput = {
	/** Controller environment variables. Allows to override controller.properties with a key NL_PROPERTY_[CATEGORY]_[MY_PROPERTY] and a value [VALUE] */
	controllerEnvironmentVariables?: {
		[key: string]: string;
	};
	/** Controller zone, the zoneId should be given */
	controllerZoneId?: string;
	/** Test description */
	description?: string;
	/** Load Generators zones, the key is the zoneId */
	lgsByZone?: {
		[key: string]: number;
	};
	/** Test name, name need to be unique between all tests in a same workspace */
	name?: string;
	/** Selected scenario in the uploaded project or test name for selecting the custom scenario */
	selectedScenarioName?: string;
	/** Test Result name template. Variable ${runID} is available, it's a value which is incremented for each run done on the test */
	testResultNameTemplate?: string;
	/** Unique identifier representing a Workspace */
	workspaceId?: string;
};
export type Population = {
	/** Name */
	name: string;
};
export type Scenario = {
	/** Name */
	name: string;
	/** Scenario duration (ISO 8601 format). */
	duration?: string;
	/** Maximum number of VU which can be expected during the scenario execution */
	maximumVu?: number;
};
export type FileUploadSettings = {
	source: 'FILE';
};
export type UploadSettings = FileUploadSettings | GitUploadSettings;
export type Project = {
	/** Name */
	name: string;
	/** Date and time in UTC when the entity was updated for the last time */
	updatedAt: string;
	/** List of population name */
	populations?: Population[];
	/** Scenarios extracted from the project */
	scenarios?: Scenario[];
	/** Security status of the project, if left empty, the project is not secured */
	securityStatus?: 'ENABLED' | 'DISABLED' | 'INVALID_PASSWORD';
	/** Size of the project in bytes */
	size?: number;
	uploadSettings?: UploadSettings;
	/** Identifier of the user who modified the entity */
	userModifierName?: string;
};
export type UpdatePasswordRequest = {
	/** The password to update */
	password: string;
};
export type RequiredText = string;
export type Time = string;
export type Iterations = string;
export type Duration = Time | Iterations;
export type StartAfterOptions = Time | string;
export type StopAfterOptions = Time | 'current_iteration';
export type ConstantLoad = {
	duration: Duration;
	users: number;
	rampup?: Time;
	start_after?: StartAfterOptions;
	stop_after?: StopAfterOptions;
};
export type PositiveNumber = number;
export type RampupLoad = {
	duration: Duration;
	increment_every: Duration;
	increment_users: number;
	min_users: PositiveNumber;
	increment_rampup?: Time;
	max_users?: PositiveNumber;
	start_after?: StartAfterOptions;
	stop_after?: StopAfterOptions;
};
export type PeaksPhase = {
	duration: Duration;
	users: PositiveNumber;
};
export type PeaksLoad = {
	duration: Duration;
	maximum: PeaksPhase;
	minimum: PeaksPhase;
	start: 'minimum' | 'maximum';
	start_after?: StartAfterOptions;
	step_rampup?: Time;
	stop_after?: StopAfterOptions;
};
export type CustomLoad = {
	steps: {
		when: Duration;
		users: PositiveNumber;
	}[];
	rampup?: Time;
	start_after?: StartAfterOptions;
	stop_after?: StopAfterOptions;
};
export type ScenarioAsCode = {
	apm_configuration?: {
		dynatrace_tags?: string[];
		dynatrace_anomaly_rules?: {
			metric_id: RequiredText;
			operator: 'BELOW' | 'ABOVE';
			value: RequiredText;
			severity: 'AVAILABILITY' | 'CUSTOM_ALERT' | 'ERROR' | 'INFO' | 'PERFORMANCE' | 'RESOURCE_CONTENTION';
		}[];
	};
	description?: string;
	excluded_urls?: string[];
	monitoring?: {
		before?: Time;
		after?: Time;
	};
	populations?: (
		| {
				name: RequiredText;
				constant_load: ConstantLoad;
		  }
		| {
				name: RequiredText;
				rampup_load: RampupLoad;
		  }
		| {
				name: RequiredText;
				peaks_load: PeaksLoad;
		  }
		| {
				name: RequiredText;
				custom_load: CustomLoad;
		  }
	)[];
	rendezvous_policies?: {
		name: RequiredText;
		when: 'manual' | string | PositiveNumber;
		timeout?: Time;
	}[];
	sla_profile?: string;
	store_variables_for_raw_data?: boolean;
};
export type TrendMetadata = {
	/** ID */
	resultId: string;
	/** The name of the result test. */
	resultName: string;
	/** Status of the test. */
	resultQualityStatus: 'PASSED' | 'FAILED' | 'COMPUTING' | 'UNKNOWN';
	/** Date and time in UTC when the test started. */
	resultStartDate: string;
};
export type TrendElement = {
	/** Unique identifier representing a specific Element. <br>
    Could be a UUID, a UUID with index or one of the following: <br>
    all-transactions <br>
    all-requests <br>
    all-pages <br>
    all-actions <br>
    init-container <br>
    actions-container <br>
    end-container <br>
     */
	id: string;
	/** Ordered list representing the full path of the trend element. */
	fullPath: string[];
	/** The name of the trend element. */
	name: string;
};
export type TrendPoint = {
	element: TrendElement;
	statistic: UserPathElementValueStatistic;
	/** The value of the trend point. */
	value: number;
};
export type Trend = {
	metadata: TrendMetadata;
	/** The list of points of the trend */
	points: TrendPoint[];
};
export type TrendPage = {
	/** Items in the page */
	items: Trend[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type TrendsConfigurationMetric = {
	/** Color. */
	color: string;
	/** Ordered array representing the path of the element. The element must be a transaction. The special value 'all-transactions' means &lt;All Transactions&gt;. Note: for compatibility reasons with older Test configurations, the elementPath may be a UUID, prefer replace it with the path to the transaction. */
	elementFullPath: string[];
	/** The id of the element. */
	elementId: string;
	/** True if the objective is enabled and will be used to determine the test result status. */
	objective: boolean;
	statistic: UserPathElementValueStatistic;
	/** Defines whether the series should be displayed or hidden. */
	visible: boolean;
	/** Value of the objective for the metric. It is user-defined in STATIC mode and automatically computed for BASELINE and AUTOMATED mode. Required when objective property is true and mode is STATIC. */
	objectiveValue?: number;
};
export type StaticTrendsConfiguration = {
	/** The maximum number of Test Results to show in the Test Analysis graph */
	displayTestResultsCount: '10' | '20' | '50';
	/** List of configuration metrics */
	metrics: TrendsConfigurationMetric[];
	objectiveMode: 'STATIC';
	/** If true, the test execution will fail if the objective is exceeded */
	shouldFailTestExecutionOnObjectiveExceeded: boolean;
};
export type BaselineTrendsConfiguration = {
	/** The maximum number of Test Results to show in the Test Analysis graph */
	displayTestResultsCount: '10' | '20' | '50';
	/** List of configuration metrics */
	metrics: TrendsConfigurationMetric[];
	objectiveMode: 'BASELINE';
	/** The baseline result to compare the metrics with */
	resultId: string;
	/** If true, the test execution will fail if the objective is exceeded */
	shouldFailTestExecutionOnObjectiveExceeded: boolean;
	/** The tolerance percentage to apply to the baseline. Should be a positive integer */
	tolerance: number;
};
export type AutomatedTrendsConfiguration = {
	/** The maximum number of Test Results to show in the Test Analysis graph */
	displayTestResultsCount: '10' | '20' | '50';
	/** List of configuration metrics */
	metrics: TrendsConfigurationMetric[];
	objectiveMode: 'AUTOMATED';
	/** If true, the test execution will fail if the objective is exceeded */
	shouldFailTestExecutionOnObjectiveExceeded: boolean;
	/** The tolerance for the AUTOMATED objective mode. The possible values (2,3,4) are the number of standard deviations to consider for the trend. */
	tolerance: '2' | '3' | '4';
};
export type TrendsConfiguration =
	| ({
			objectiveMode: 'STATIC';
	  } & StaticTrendsConfiguration)
	| ({
			objectiveMode: 'BASELINE';
	  } & BaselineTrendsConfiguration)
	| ({
			objectiveMode: 'AUTOMATED';
	  } & AutomatedTrendsConfiguration);
export type TrendsConfigurationMetricPatchRequest = {
	/** Ordered array representing the path of the element. The element must be a transaction. The special value 'all-transactions' means &lt;All Transactions&gt;. Note: for compatibility reasons with older Test configurations, the elementPath may be a UUID, prefer replace it with the path to the transaction. */
	elementFullPath: string[];
	statistic: UserPathElementValueStatistic;
	/** Defines whether the series should be displayed or hidden. */
	visible?: boolean | null;
} | null;
export type TrendsConfigurationPatchRequest = {
	/** The maximum number of Test Results to show in the Test Analysis graph */
	displayTestResultsCount?: ('10' | '20' | '50') | null;
	/** List of configuration metrics to patch */
	metrics?: TrendsConfigurationMetricPatchRequest[];
};
export type TrendElementPage = {
	/** Items in the page */
	items: TrendElement[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type UserWorkspacePage = {
	/** Items in the page */
	items: UserWorkspace[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type PutUserWorkspace = {
	/** ID of the Workspace */
	workspaceId: string;
};
export type User = {
	/** ID of the User */
	id: string;
	/** Email of the User */
	email: string;
	/** Role of the user */
	role: 'NONE' | 'GUEST' | 'TESTER' | 'NEOLOAD_ADMIN' | 'ACCOUNT_ADMIN';
	/** Status of the User */
	status: 'PENDING' | 'ACTIVE';
	/** Workspaces of the user */
	workspaces: UserWorkspace[];
	/** First name of the User */
	firstName?: string;
	/** Last name of the User */
	lastName?: string;
	/** Date and time in UTC when the user logged in for the last time. */
	lastSuccessfulLogin?: string;
	/** Login of the User */
	login?: string;
};
export type UserPage = {
	/** Items in the page */
	items: User[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type UserCreationRequest = {
	/** Email of the User */
	email: string;
	/** Role of the User */
	role: 'NONE' | 'GUEST' | 'TESTER' | 'NEOLOAD_ADMIN' | 'ACCOUNT_ADMIN';
};
export type UserPatchRequest = {
	/** Role of the User */
	role?: 'NONE' | 'GUEST' | 'TESTER' | 'NEOLOAD_ADMIN' | 'ACCOUNT_ADMIN';
};
export type ByDurationFilter = {
	/** The flag indicating if this filter is enabled or not */
	enabled: boolean;
	/** The operator of the filter */
	operator: 'IS_LONGER' | 'IS_SHORTER';
	/** The value to filter with (ISO 8601 format) */
	value: string;
};
export type ByMaxVUsFilter = {
	/** The flag indicating if this filter is enabled or not */
	enabled: boolean;
	/** The operator of the filter */
	operator: 'IS_GREATER' | 'IS_LOWER';
	/** The value to filter with */
	value: number;
};
export type ByNameFilter = {
	/** The flag indicating if this filter is enabled or not */
	enabled: boolean;
	/** The operator of the filter */
	operator: 'CONTAINS' | 'DOES_NOT_CONTAIN';
	/** The value to filter with */
	value: string;
};
export type ByQualityStatusFilter = {
	/** The flag indicating if this filter is enabled or not */
	enabled: boolean;
	/** The operator of the filter */
	operator: 'IS' | 'IS_NOT';
	/** The value to filter with */
	value: 'PASSED' | 'FAILED';
};
export type WebhookFilters = {
	byDuration?: ByDurationFilter;
	byMaxVus?: ByMaxVUsFilter;
	byProjectName?: ByNameFilter;
	byQualityStatus?: ByQualityStatusFilter;
	byScenarioName?: ByNameFilter;
	byTestName?: ByNameFilter;
};
export type Webhook = {
	/** ID */
	id: string;
	/** The payload format expected by the server */
	contentType: string;
	/** A flag indicating if Webhook is enabled or not */
	enabled: boolean;
	filters: WebhookFilters;
	/** The name assigned to the Webhook */
	name: string;
	/** The event that will trigger the sending of the notification */
	trigger: 'ON_RESULT_STARTED' | 'ON_RESULT_ENDED';
	/** The URL of the server where the notification is to be sent to */
	url: string;
	/** ID */
	workspaceId: string;
	/** The content to be sent in the notification */
	payload?: string;
};
export type WebhookPage = {
	/** Items in the page */
	items: Webhook[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type PostWebhookRequest = {
	/** The payload format expected by the server */
	contentType: string;
	/** A flag indicating if Webhook is enabled or not */
	enabled: boolean;
	filters: WebhookFilters;
	/** The name assigned to the Webhook */
	name: string;
	/** The event that will trigger the sending of the notification */
	trigger: 'ON_RESULT_STARTED' | 'ON_RESULT_ENDED';
	/** The URL of the server where the notification is to be sent to */
	url: string;
	/** ID */
	workspaceId: string;
	/** The content to be sent in the notification */
	payload?: string;
};
export type ValidationResult = {
	/** The HTTP status code of the response from the server where the notification has been sent to */
	httpStatusCode: number;
};
export type ValidationRequest = {
	/** The payload format expected by the server */
	contentType: string;
	/** The URL of the server where the notification is to be sent to */
	url: string;
	/** The content to be sent in the notification */
	payload?: string;
};
export type PatchWebhookRequest = {
	/** The payload format expected by the server */
	contentType?: string;
	/** A flag indicating if Webhook is enabled or not */
	enabled?: boolean;
	filters?: WebhookFilters;
	/** The name assigned to the Webhook */
	name?: string;
	/** The content to be sent in the notification */
	payload?: string;
	/** The event that will trigger the sending of the notification */
	trigger?: 'ON_RESULT_STARTED' | 'ON_RESULT_ENDED';
	/** The URL of the server where the notification is to be sent to */
	url?: string;
};
export type WorkspaceQuota = {
	/** Quota limitation for Sap Vu */
	sap?: number;
	/** Quota limitation for Web Vu */
	web?: number;
};
export type Workspace = {
	/** ID of the Workspace */
	id: string;
	/** Is workspace enabled for all users */
	enabledForAllUsers: boolean;
	/** Name of the Workspace */
	name: string;
	/** Is quota limitation enabled */
	quotaLimitEnabled: boolean;
	/** Description of the workspace */
	description?: string;
	/** Number of members in the workspace */
	membersCount?: number;
	quota?: WorkspaceQuota;
};
export type WorkspacePage = {
	/** Items in the page */
	items: Workspace[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type WorkspacePostRequest = {
	/** Name of the Workspace */
	name: string;
	/** Description of the workspace */
	description?: string;
	/** Is workspace enabled for all users */
	enabledForAllUsers?: boolean;
	quota?: WorkspaceQuota;
	/** Is quota limitation enabled */
	quotaLimitEnabled?: boolean;
};
export type WorkspacePatchInput = {
	/** Description of the workspace */
	description?: string;
	/** Is workspace enabled for all users */
	enabledForAllUsers?: boolean;
	/** Name of the Workspace */
	name?: string;
	quota?: WorkspaceQuota;
	/** Is quota limitation enabled */
	quotaLimitEnabled?: boolean;
};
export type WorkspaceMember = {
	/** ID of the User */
	id: string;
	/** Email of the User */
	email: string;
	/** Role of the user */
	role: 'NONE' | 'GUEST' | 'TESTER' | 'NEOLOAD_ADMIN' | 'ACCOUNT_ADMIN';
	/** First name of the User */
	firstName?: string;
	/** Last name of the User */
	lastName?: string;
};
export type WorkspaceMemberPage = {
	/** Items in the page */
	items: WorkspaceMember[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type CloudRegion =
	| 'AWS_AF_SOUTH_1'
	| 'AWS_AP_EAST_1'
	| 'AWS_AP_NORTHEAST_1'
	| 'AWS_AP_NORTHEAST_2'
	| 'AWS_AP_NORTHEAST_3'
	| 'AWS_AP_SOUTH_1'
	| 'AWS_AP_SOUTH_2'
	| 'AWS_AP_SOUTHEAST_1'
	| 'AWS_AP_SOUTHEAST_2'
	| 'AWS_AP_SOUTHEAST_3'
	| 'AWS_AP_SOUTHEAST_4'
	| 'AWS_AP_SOUTHEAST_5'
	| 'AWS_CA_CENTRAL_1'
	| 'AWS_CA_WEST_1'
	| 'AWS_EU_CENTRAL_1'
	| 'AWS_EU_CENTRAL_2'
	| 'AWS_EU_NORTH_1'
	| 'AWS_EU_SOUTH_1'
	| 'AWS_EU_SOUTH_2'
	| 'AWS_EU_WEST_1'
	| 'AWS_EU_WEST_2'
	| 'AWS_EU_WEST_3'
	| 'AWS_IL_CENTRAL_1'
	| 'AWS_ME_CENTRAL_1'
	| 'AWS_ME_SOUTH_1'
	| 'AWS_SA_EAST_1'
	| 'AWS_US_EAST_1'
	| 'AWS_US_EAST_2'
	| 'AWS_US_WEST_1'
	| 'AWS_US_WEST_2'
	| 'GCP_AFRICA_SOUTH1'
	| 'GCP_EUROPE_WEST10'
	| 'GCP_EUROPE_WEST12'
	| 'GCP_ME_CENTRAL1'
	| 'GCP_ME_WEST1'
	| 'GCP_US_EAST5'
	| 'GCP_US_SOUTH1'
	| 'AP_EAST_3'
	| 'AP_EAST_4'
	| 'AP_NORTHEAST_7'
	| 'AP_NORTHEAST_8'
	| 'AP_NORTHEAST_9'
	| 'AP_SOUTH_5'
	| 'AP_SOUTH_6'
	| 'AP_SOUTHEAST_8'
	| 'AP_SOUTHEAST_9'
	| 'AP_SOUTHEAST_10'
	| 'AP_SOUTHWEST_11'
	| 'EU_CENTER_1'
	| 'EU_CENTER_4'
	| 'EU_CENTER_5'
	| 'EU_NORTH_2'
	| 'EU_SOUTH_2'
	| 'EU_SOUTHWEST_1'
	| 'EU_WEST_7'
	| 'EU_WEST_15'
	| 'EU_WEST_16'
	| 'EU_WEST_18'
	| 'NA_NORTHEAST_8'
	| 'NA_NORTHEAST_9'
	| 'SA_EAST_3'
	| 'SA_WEST_1'
	| 'US_EAST_3'
	| 'US_EAST_9'
	| 'US_NORTHCENTRAL_4'
	| 'US_WEST_4'
	| 'US_WEST_6'
	| 'US_WEST_10'
	| 'US_WEST_11';
export type ZoneResource = {
	/** Additional Properties of the resource */
	additionalProperties: {
		[key: string]: string;
	};
	/** Name of the controller or address of the load generator */
	name: string;
	/** Status of the resource */
	status: 'AVAILABLE' | 'RESERVED' | 'BUSY';
	/** Version of the resource */
	version: string;
};
export type DedicatedIpsDetails = {
	/** Total number of IP that can be allocated for the cloud zone. */
	availableIpsCount: number;
	/** Date and time in UTC when the reservation will expire. */
	expirationDate: string;
	/** List of IPs currently allocated for the given cloud zone. This list may not be completely up to date while the reservation status is not READY. */
	ipAddresses: string[];
	/** Number of IPs currently allocated for the cloud zone */
	reservedIpsCount: number;
	/** Status of the IP reservation. */
	status: 'READY' | 'ALLOCATING' | 'RELEASING' | 'UNKNOWN';
	/** Estimated Cloud Credits cost of modifying the number of IPs allocated for the cloud zone. This field is only returned in a PATCH method response. */
	modificationCostInCloudCredits?: number;
};
export type CloudZone = {
	/** Provider of the cloud Zone. */
	cloudProvider: string;
	cloudRegion: CloudRegion;
	/** List of Controllers */
	controllers: ZoneResource[];
	/** List of Load Generators */
	loadGenerators: ZoneResource[];
	/** Name of the zone */
	name: string;
	/** Unique identifier of the zone */
	zoneId: string;
	dedicatedIpsDetails?: DedicatedIpsDetails;
};
export type CloudZoneRead = {
	/** Provider of the cloud Zone. */
	cloudProvider: string;
	cloudRegion: CloudRegion;
	/** List of Controllers */
	controllers: ZoneResource[];
	/** List of Load Generators */
	loadGenerators: ZoneResource[];
	/** Name of the zone */
	name: string;
	type: 'CLOUD';
	/** Unique identifier of the zone */
	zoneId: string;
	dedicatedIpsDetails?: DedicatedIpsDetails;
};
export type DynamicZoneProvider = {
	/** Unique ID of the provider */
	id: string;
	/** Name of the provider */
	name: string;
};
export type DynamicZone = {
	/** List of Controllers */
	controllers: ZoneResource[];
	/** List of Load Generators */
	loadGenerators: ZoneResource[];
	/** Name of the zone */
	name: string;
	provider: DynamicZoneProvider;
	/** Unique identifier of the zone */
	zoneId: string;
};
export type DynamicZoneRead = {
	/** List of Controllers */
	controllers: ZoneResource[];
	/** List of Load Generators */
	loadGenerators: ZoneResource[];
	/** Name of the zone */
	name: string;
	provider: DynamicZoneProvider;
	type: 'DYNAMIC';
	/** Unique identifier of the zone */
	zoneId: string;
};
export type StaticZone = {
	/** List of Controllers */
	controllers: ZoneResource[];
	/** List of Load Generators */
	loadGenerators: ZoneResource[];
	/** Name of the zone */
	name: string;
	/** Unique identifier of the zone */
	zoneId: string;
};
export type StaticZoneRead = {
	/** List of Controllers */
	controllers: ZoneResource[];
	/** List of Load Generators */
	loadGenerators: ZoneResource[];
	/** Name of the zone */
	name: string;
	type: 'STATIC';
	/** Unique identifier of the zone */
	zoneId: string;
};
export type Zone =
	| ({
			type: 'CLOUD';
	  } & CloudZone)
	| ({
			type: 'DYNAMIC';
	  } & DynamicZone)
	| ({
			type: 'STATIC';
	  } & StaticZone);
export type ZoneRead =
	| ({
			type: 'CLOUD';
	  } & CloudZoneRead)
	| ({
			type: 'DYNAMIC';
	  } & DynamicZoneRead)
	| ({
			type: 'STATIC';
	  } & StaticZoneRead);
export type ZonePage = {
	/** Items in the page */
	items: Zone[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type ZonePageRead = {
	/** Items in the page */
	items: ZoneRead[];
	/** Total items */
	total: number;
	/** The page number */
	pageNumber?: number;
	/** The number of items per page */
	pageSize?: number;
};
export type ZonePagePartialError = PartialError & {
	/** Fixed value, it define the schema */
	category?: 'PARTIAL_ERROR';
	/** Description of the error */
	description?: string;
	/** Errors encountered during execution */
	errors?: ApiError[];
	result?: ZonePage;
};
export type ZonePagePartialErrorRead = PartialError & {
	/** Fixed value, it define the schema */
	category?: 'PARTIAL_ERROR';
	/** Description of the error */
	description?: string;
	/** Errors encountered during execution */
	errors?: ApiError[];
	result?: ZonePageRead;
};
export type FixedSizing = {
	size: 'SMALL' | 'MEDIUM' | 'LARGE';
	type: 'FIXED';
};
export type CustomSizing = {
	controllerCpu: number;
	controllerMemoryMb: number;
	lgCpu: number;
	lgMemoryMb: number;
	type: 'CUSTOM';
};
export type Sizing =
	| ({
			type: 'FIXED';
	  } & FixedSizing)
	| ({
			type: 'CUSTOM';
	  } & CustomSizing);
export type DynamicZoneWithSettings = {
	/** List of Controllers */
	controllers: ZoneResource[];
	/** List of Load Generators */
	loadGenerators: ZoneResource[];
	/** Name of the zone */
	name: string;
	provider: DynamicZoneProvider;
	sizing: Sizing;
	type: 'DYNAMIC';
	/** Unique identifier of the zone */
	zoneId: string;
	/** The docker image to deploy the Controllers */
	controllerDockerImage?: string;
	/** The docker image to deploy the Load Generators */
	lgDockerImage?: string;
	/** The pull secret used to download the resource images */
	pullSecret?: string;
};
export type ZoneWithSettings =
	| ({
			type: 'CLOUD';
	  } & CloudZone)
	| ({
			type: 'DYNAMIC';
	  } & DynamicZoneWithSettings)
	| ({
			type: 'STATIC';
	  } & StaticZone);
export type ZoneWithSettingsRead =
	| ({
			type: 'CLOUD';
	  } & CloudZoneRead)
	| ({
			type: 'DYNAMIC';
	  } & DynamicZoneWithSettings)
	| ({
			type: 'STATIC';
	  } & StaticZoneRead);
export type CloudZoneFields = {
	cloudRegion: CloudRegion;
	type: 'CLOUD';
};
export type DynamicZoneFields = {
	/** Name of the zone */
	name: string;
	/** Unique ID of provider. */
	providerId: string;
	sizing: Sizing;
	type: 'DYNAMIC';
	/** The docker image to deploy the Controllers */
	controllerDockerImage?: string;
	/** The docker image to deploy the Load Generators */
	lgDockerImage?: string;
	/** The pull secret used to download the resource images */
	pullSecret?: string;
};
export type StaticZoneFields = {
	/** Name of the zone */
	name: string;
	type: 'STATIC';
};
export type PostZoneFields =
	| ({
			type: 'CLOUD';
	  } & CloudZoneFields)
	| ({
			type: 'DYNAMIC';
	  } & DynamicZoneFields)
	| ({
			type: 'STATIC';
	  } & StaticZoneFields);
export type PatchAllCloudZonesResponse = {
	type: 'CLOUD';
	/** Estimated Cloud Credits cost of modifying the number or expiration date of IPs allocated for the cloud zone. This field is only returned in a PATCH method response. */
	modificationCostInCloudCredits?: number;
};
export type PatchAllZonesResponse = {
	type: 'CLOUD';
} & PatchAllCloudZonesResponse;
export type PatchAllZoneInvalidResourceStateError = InvalidResourceStateError & {
	/** Fixed value, it define the schema */
	category?: 'CONFLICT';
	cause?: 'PATCH_ZONE_TYPE_MISMATCH_ERROR' | 'UNKNOWN_PATCH_ZONE_ERROR';
	/** Description of the error */
	description?: string;
	details?: string;
	/** Dynamic values used in the description */
	messageProperties?: {
		[key: string]: string;
	};
};
export type PatchAllCloudZoneInvalidResourceStateError = InvalidResourceStateError & {
	/** Fixed value, it define the schema */
	category?: 'CONFLICT';
	cause?:
		| 'INVALID_CLOUD_SESSION_ID'
		| 'INSUFFICIENT_AVAILABLE_IPS'
		| 'INSUFFICIENT_CLOUD_CREDITS'
		| 'ZONE_DISABLED_ERROR'
		| 'STATUS_ERROR'
		| 'UNKNOWN_PATCH_CLOUD_ZONE_ERROR';
	causeDetails?: string;
	/** Description of the error */
	description?: string;
	/** Dynamic values used in the description */
	messageProperties?: {
		[key: string]: string;
	};
};
export type PatchAllCloudZonesFields = {
	type: 'CLOUD';
	/** Set the reservation expiration date for all zones at once.
    The expiration date must be set in the future and applies to all zones within the scope of this request.
    Possible errors when setting this value include:
    - Invalid date format: occurs when the provided date does not match the required format ("yyyy-MM-ddTHH:mm:ss.SSSZ").
    - Expiration date in the past: occurs when the provided date is earlier than the current date and time.
    - Insufficient Cloud Credits: occurs when the resulting cost of extending reservations exceeds the available Cloud Credits for the account.
    The last error will not be triggered in dryRun mode, which simulates the operation without applying changes.
     */
	expirationDate?: string | null;
	/** Release reserved IPs for all zones at once. */
	releaseAllReservedIps?: boolean | null;
};
export type PatchAllZonesFields = {
	type: 'CLOUD';
} & PatchAllCloudZonesFields;
export type CloudZonePartialError = PartialError & {
	/** Fixed value, it define the schema */
	category?: 'PARTIAL_ERROR';
	/** Description of the error */
	description?: string;
	/** Errors encountered during execution */
	errors?: ApiError[];
	result?: CloudZone;
};
export type CloudZonePartialErrorRead = PartialError & {
	/** Fixed value, it define the schema */
	category?: 'PARTIAL_ERROR';
	/** Description of the error */
	description?: string;
	/** Errors encountered during execution */
	errors?: ApiError[];
	result?: CloudZoneRead;
};
export type PutZoneFields =
	| ({
			type: 'DYNAMIC';
	  } & DynamicZoneFields)
	| ({
			type: 'STATIC';
	  } & StaticZoneFields);
export type PatchZoneInvalidResourceStateError = InvalidResourceStateError & {
	/** Fixed value, it define the schema */
	category?: 'CONFLICT';
	cause?: 'PATCH_ZONE_TYPE_MISMATCH_ERROR' | 'UNKNOWN_PATCH_ZONE_ERROR';
	/** Description of the error */
	description?: string;
	details?: string;
	/** Dynamic values used in the description */
	messageProperties?: {
		[key: string]: string;
	};
};
export type PatchCloudZoneInvalidResourceStateError = InvalidResourceStateError & {
	/** Fixed value, it define the schema */
	category?: 'CONFLICT';
	cause?:
		| 'INVALID_CLOUD_SESSION_ID'
		| 'INSUFFICIENT_AVAILABLE_IPS'
		| 'INSUFFICIENT_CLOUD_CREDITS'
		| 'ZONE_DISABLED_ERROR'
		| 'STATUS_ERROR'
		| 'UNKNOWN_PATCH_CLOUD_ZONE_ERROR';
	causeDetails?: string;
	/** Description of the error */
	description?: string;
	/** Dynamic values used in the description */
	messageProperties?: {
		[key: string]: string;
	};
};
export type PatchDedicatedIpsDetails = {
	/** Set the number of dedicated IPs for this zone.
    It can be higher or lower than the current reservedIpsCount of the zone, and will allocate new IPs or release existing ones accordingly.
    Possible errors when setting this value include :
    - Insufficient available IPs for this zone, in case the value is higher than availableIpsCount
    - Insufficient Cloud Credits, in case the resulting cost for allocating new IPs exceed the available Cloud Credits for the account
    - Invalid reservation status, in case a PATCH request is attempted while the server is still allocating or releasing IPs from a previous request
    The above errors will not be thrown when in dryRun mode. */
	reservedIpsCount?: number | null;
};
export type PatchCloudZoneFields = {
	type: 'CLOUD';
	dedicatedIpsDetails?: PatchDedicatedIpsDetails;
};
export type PatchZoneFields = {
	type: 'CLOUD';
} & PatchCloudZoneFields;
export const {
	usePostV4CheckoutsMutation,
	useGetV4CheckoutsByCheckoutIdQuery,
	usePostV4DashboardsByDashboardIdTilesAndTileIdSeriesMutation,
	useDeleteV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdMutation,
	usePatchV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdMutation,
	useGetV4DashboardsByDashboardIdTilesQuery,
	usePostV4DashboardsByDashboardIdTilesMutation,
	useDeleteV4DashboardsByDashboardIdTilesMutation,
	usePatchV4DashboardsByDashboardIdTilesMutation,
	useDeleteV4DashboardsByDashboardIdTilesAndDashboardTileIdMutation,
	usePatchV4DashboardsByDashboardIdTilesAndDashboardTileIdMutation,
	useGetV4DashboardsQuery,
	usePostV4DashboardsMutation,
	useGetV4DashboardsByDashboardIdQuery,
	useDeleteV4DashboardsByDashboardIdMutation,
	usePatchV4DashboardsByDashboardIdMutation,
	usePostV4DashboardsByDashboardIdDuplicationMutation,
	useGetV4DashboardsByDashboardIdPdfQuery,
	usePostV4DashboardsByDashboardIdPublicTokenMutation,
	useDeleteV4DashboardsByDashboardIdPublicTokenMutation,
	useGetV4DeletionPoliciesQuery,
	usePostV4DeletionPoliciesMutation,
	usePostV4DeletionPoliciesExecutionMutation,
	useGetV4DeletionPoliciesByDeletionPolicyIdQuery,
	useDeleteV4DeletionPoliciesByDeletionPolicyIdMutation,
	usePatchV4DeletionPoliciesByDeletionPolicyIdMutation,
	useGetV4InfrastructureProvidersQuery,
	usePostV4InfrastructureProvidersMutation,
	useDeleteV4InfrastructureProvidersByIdMutation,
	usePatchV4InfrastructureProvidersByIdMutation,
	useGetV4LicenseQuery,
	usePostV4LicenseMutation,
	usePostV4LicenseActivationRequestsMutation,
	usePostV4LicenseDeactivationRequestsMutation,
	usePostV4LicenseForcedReleasesMutation,
	useGetV4LicenseLeasesQuery,
	usePostV4LicenseLeasesMutation,
	useGetV4LicenseLeasesByLeaseIdentifierQuery,
	usePostV4LicenseReleasesMutation,
	useGetV4MeQuery,
	usePatchV4MeMutation,
	useGetV4MeFeaturesQuery,
	usePutV4MePasswordMutation,
	useGetV4MePreferencesQuery,
	usePatchV4MePreferencesMutation,
	useGetV4MeTokensQuery,
	usePostV4MeTokensMutation,
	useDeleteV4MeTokensByTokenMutation,
	useGetV4ReservationsQuery,
	usePostV4ReservationsMutation,
	useGetV4ReservationsByReservationIdQuery,
	useDeleteV4ReservationsByReservationIdMutation,
	usePatchV4ReservationsByReservationIdMutation,
	useGetV4ResultsByResultIdElementsQuery,
	useGetV4ResultsByResultIdElementsValuesQuery,
	useGetV4ResultsByResultIdElementsAndElementIdPercentilesQuery,
	useGetV4ResultsByResultIdElementsAndElementIdTimeseriesQuery,
	useGetV4ResultsByResultIdEventsQuery,
	usePostV4ResultsByResultIdEventsMutation,
	useGetV4ResultsByResultIdEventsContentsAndContentIdQuery,
	useGetV4ResultsByResultIdEventsErrorsQuery,
	useGetV4ResultsByResultIdEventsAndEventIdQuery,
	useDeleteV4ResultsByResultIdEventsAndEventIdMutation,
	usePatchV4ResultsByResultIdEventsAndEventIdMutation,
	useGetV4ResultsByResultIdIntervalsQuery,
	usePostV4ResultsByResultIdIntervalsMutation,
	useDeleteV4ResultsByResultIdIntervalsAndIntervalIdMutation,
	usePatchV4ResultsByResultIdIntervalsAndIntervalIdMutation,
	useGetV4ResultsByResultIdLogsQuery,
	useGetV4ResultsByResultIdMonitorsQuery,
	useGetV4ResultsByResultIdMonitorsValuesQuery,
	useGetV4ResultsByResultIdMonitorsAndMonitorIdTimeseriesQuery,
	useGetV4ResultsByResultIdSlasQuery,
	useGetV4ResultsByResultIdStatisticsQuery,
	useGetV4ResultsByResultIdTimeseriesQuery,
	usePostV4ResultsByResultIdIntervalGenerationMutation,
	useGetV4ResultsQuery,
	useGetV4ResultsSearchCriteriaQuery,
	useGetV4ResultsByResultIdQuery,
	useDeleteV4ResultsByResultIdMutation,
	usePatchV4ResultsByResultIdMutation,
	useGetV4ResultsByResultIdContextsQuery,
	usePostV4ResultsByResultIdReportMutation,
	useGetV4ScmRepositoriesQuery,
	usePostV4ScmRepositoriesMutation,
	useGetV4ScmRepositoriesByRepositoryIdQuery,
	useDeleteV4ScmRepositoriesByRepositoryIdMutation,
	usePatchV4ScmRepositoriesByRepositoryIdMutation,
	useGetV4ScmRepositoriesByRepositoryIdReferencesQuery,
	usePostV4SessionsMutation,
	useDeleteV4SessionsMutation,
	useGetV4InformationQuery,
	useGetV4SettingsQuery,
	usePatchV4SettingsMutation,
	useGetV4SubscriptionQuery,
	useGetV4WorkspacesByWorkspaceIdSubscriptionQuery,
	usePostV4TestExecutionsMutation,
	useGetV4TestExecutionsByTestExecutionIdQuery,
	useDeleteV4TestExecutionsByTestExecutionIdMutation,
	useDeleteV4TestExecutionsByTestExecutionIdForcedMutation,
	useGetV4TestsQuery,
	usePostV4TestsMutation,
	useGetV4TestsByTestIdQuery,
	useDeleteV4TestsByTestIdMutation,
	usePatchV4TestsByTestIdMutation,
	useGetV4TestsByTestIdProjectQuery,
	usePostV4TestsByTestIdProjectPasswordMutation,
	useGetV4TestsByTestIdScenariosAndScenarioNameQuery,
	usePutV4TestsByTestIdScenariosAndScenarioNameMutation,
	useGetV4TestsByTestIdTrendsQuery,
	useGetV4TestsByTestIdTrendsConfigurationQuery,
	usePutV4TestsByTestIdTrendsConfigurationMutation,
	usePatchV4TestsByTestIdTrendsConfigurationMutation,
	useGetV4TestsByTestIdTrendsElementsQuery,
	useGetV4UsersByUserIdWorkspacesQuery,
	usePutV4UsersByUserIdWorkspacesMutation,
	useDeleteV4UsersByUserIdWorkspacesAndWorkspaceIdMutation,
	useGetV4UsersQuery,
	usePostV4UsersMutation,
	useGetV4UsersByUserIdQuery,
	useDeleteV4UsersByUserIdMutation,
	usePatchV4UsersByUserIdMutation,
	useGetV4WebhooksQuery,
	usePostV4WebhooksMutation,
	usePostV4WebhooksValidationMutation,
	useGetV4WebhooksByWebhookIdQuery,
	useDeleteV4WebhooksByWebhookIdMutation,
	usePatchV4WebhooksByWebhookIdMutation,
	useGetV4WorkspacesQuery,
	usePostV4WorkspacesMutation,
	useGetV4WorkspacesByWorkspaceIdQuery,
	useDeleteV4WorkspacesByWorkspaceIdMutation,
	usePatchV4WorkspacesByWorkspaceIdMutation,
	useGetV4WorkspacesByWorkspaceIdMembersQuery,
	useGetV4ZonesQuery,
	usePostV4ZonesMutation,
	usePatchV4ZonesMutation,
	useGetV4ZonesByZoneIdQuery,
	usePutV4ZonesByZoneIdMutation,
	useDeleteV4ZonesByZoneIdMutation,
	usePatchV4ZonesByZoneIdMutation,
} = injectedRtkApi;

import { encode } from 'html-entities';
import { useTranslation } from 'react-i18next';
import { withLazyFetching, WithLazyFetchingProps } from './with-lazy-fetching';
import { SeriesContext, SeriesIdentifier, useSeriesDataFetcher } from './hooks/use-series-data-fetcher';
import { FetchError } from './hooks/types';
import { i18n } from '../../../../i18n';
import { Chart, ChartRef, Series } from '../../../common/chart';
import { matchAndReplaceWithI18N } from '../dashboard-common';
import { DashboardTileUnloadedContent } from '../tiles/dashboard-tile-unloaded-content';
import { Spinner } from '../../../common/spinner';
import {
	DashboardFilterRead,
	DashboardSeries,
	DashboardSeriesPatchRequest,
	DashboardSeriesRead,
	DashboardUserPathElementTimeSeriesStatisticRead,
	ElementPoint,
	ElementTimeSeries,
	FetchElementPercentilesResponse,
	isCommonUnit,
	MonitorPoint,
	MonitorTimeSeries,
	PercentileValue,
	SeriesDashboardTileRead,
	usePatchV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdMutation,
} from '@neoload/api';
import { bytesToBits, convertValue, convertValueWithUnit, timeUtils } from '@neoload/utils';
import { useSetSnackbars } from '@neoload/hooks';

type SeriesFetcherProps = {
	dashboardId: string;
	seriesTile: SeriesDashboardTileRead;
	isReadOnly: boolean;
	chartRef?: React.RefObject<ChartRef>;
} & WithLazyFetchingProps;

type FetchResult = { id: SeriesIdentifier } & (
	| { error: FetchError; context: unknown }
	| { data: ElementTimeSeries | undefined; context: SeriesContext<'ELEMENTS_TIMESERIES'> }
	| { data: MonitorTimeSeries | undefined; context: SeriesContext<'MONITORS_TIMESERIES'> }
	| { data: FetchElementPercentilesResponse | undefined; context: SeriesContext<'ELEMENTS_PERCENTILES'> }
);

const InternalSeriesFetcher = ({
	dashboardId,
	shouldStartFetching,
	seriesTile,
	isReadOnly,
	chartRef,
}: SeriesFetcherProps) => {
	const { t } = useTranslation('dashboard');
	const { showInfo, showError } = useSetSnackbars();
	const [results, loadingState] = useSeriesDataFetcher(seriesTile.series, shouldStartFetching);
	const [patchDashboardSeries] = usePatchV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdMutation();

	if (loadingState === 'UNLOADED') {
		return <DashboardTileUnloadedContent />;
	}

	if (loadingState === 'LOADING' || loadingState === 'PARTIALLY_LOADED') {
		return <Spinner />;
	}

	const doPatchDashboardSeries = (seriesId: string, dashboardSeriesPatchRequest: DashboardSeriesPatchRequest) => {
		patchDashboardSeries({
			dashboardId,
			tileId: seriesTile.id,
			seriesId,
			dashboardSeriesPatchRequest,
		})
			.unwrap()
			.then(() => {
				showInfo({
					text: t('series.messages.patch.success'),
					id: 'DASHBOARD_SERIES_PATCH_SUCCESS',
				});
			})
			.catch((error) => {
				const errorLabel = t('series.messages.patch.error');
				showError({
					text: errorLabel,
				});
				console.error(errorLabel, error);
			});
	};

	return (
		<Chart
			series={resultsToChartSeries(seriesTile, results, doPatchDashboardSeries, isReadOnly)}
			configuration={{
				tooltip: {
					shared: false,
				},
				axis: {
					y: {
						maximumDisplayedCount: 2,
					},
				},
			}}
			ref={chartRef}
		/>
	);
};

export const VisibleForTesting = InternalSeriesFetcher;
export const SeriesFetcher = withLazyFetching(InternalSeriesFetcher);

function resultsToChartSeries(
	tile: SeriesDashboardTileRead,
	fetchResult: readonly FetchResult[],
	doPatchDashboardSeries: (seriesId: string, dashboardSeriesPatchRequest: DashboardSeriesPatchRequest) => void,
	isReadOnly: boolean,
): Series[] {
	return fetchResult
		.filter((result) => tile.series.find((series) => series.id === result.id.seriesId))
		.map((result) => {
			const dashboardSeries = tile.series.find((series) => series.id === result.id.seriesId);
			if (!dashboardSeries) {
				throw new Error(`Unable to find series with id ${result.id.seriesId} in tile with id ${tile.id}`);
			}
			if ('error' in result) {
				return [
					dashboardSeries,
					{
						title: toChartLegend(dashboardSeries.legend),
						color: dashboardSeries.color,
						visible: dashboardSeries.visible,
						isError: true,
						error: JSON.stringify(result.error),
					},
				] as const;
			}
			if (!result.data) {
				return [
					dashboardSeries,
					{
						title: toChartLegend(dashboardSeries.legend),
						color: dashboardSeries.color,
						visible: dashboardSeries.visible,
						isError: true,
						error: 'Missing series data',
					},
				] as const;
			}
			switch (result.context.filterType) {
				case 'ELEMENTS_PERCENTILES': {
					const percentiles = result.data.points as PercentileValue[];
					return [dashboardSeries, percentilesToChartSeries(dashboardSeries, percentiles)] as const;
				}
				case 'ELEMENTS_TIMESERIES':
				case 'MONITORS_TIMESERIES': {
					const elementsOrMonitors = result.data.points as ElementPoint[] | MonitorPoint[];
					return [dashboardSeries, timeSeriesToChartSeries(dashboardSeries, elementsOrMonitors)] as const;
				}
				default: {
					throw new Error('Unhandled series filter type');
				}
			}
		})
		.map(handleSIUnits)
		.map((tuple) => {
			if (isReadOnly) {
				return tuple;
			}
			return addLegendClickItemCallback(doPatchDashboardSeries)(tuple);
		})
		.map(([, series]) => series);
}

function discardNullPoints(point: [number, number] | null): point is [number, number] {
	return !!point;
}

function timeSeriesToChartSeries(series: DashboardSeriesRead, points: ElementPoint[] | MonitorPoint[]): Series {
	const statistic = extractUserPathOrMonitorElementTimeseriesStatisticOrThrow(series.filter);
	const yAxisUnit = extractUserPathOrMonitorElementTimeseriesYAxisUnitOrThrow(series.filter);
	return {
		title: toChartLegend(series.legend),
		color: series.color,
		visible: series.visible,
		points: points
			.map((point) => {
				const offset = timeUtils.asMilliseconds(point.offset);
				const value = parsePointValue(series, point);
				if (typeof value !== 'number') {
					return null;
				}
				return [offset, value] as [number, number];
			})
			.filter(discardNullPoints),
		isError: false,
		axis: {
			x: {
				type: 'TIMESERIES',
			},
			y: {
				unit: yAxisUnit,
				displayLegend: false,
			},
		},
		isStep: statistic.name === 'STEP',
		tooltip: {
			footerLabel: buildSeriesTooltipFooterLabel(series),
		},
	};
}

function extractUserPathOrMonitorElementTimeseriesStatisticOrThrow(filter: DashboardFilterRead) {
	switch (filter.type) {
		case 'ELEMENTS_TIMESERIES':
		case 'MONITORS_TIMESERIES':
		case 'ELEMENTS_PERCENTILES': {
			return filter.statistic;
		}
		default: {
			throw new Error(`Invalid series filter type ${filter.type}`);
		}
	}
}

function extractUserPathOrMonitorElementTimeseriesYAxisUnitOrThrow(filter: DashboardFilterRead) {
	switch (filter.type) {
		case 'ELEMENTS_TIMESERIES':
		case 'ELEMENTS_PERCENTILES':
		case 'MONITORS_TIMESERIES': {
			return toComprehensiveUnit(filter.statistic.yAxisUnit);
		}
		default: {
			throw new Error(`Invalid series filter type ${filter.type}`);
		}
	}
}

function percentilesToChartSeries(series: DashboardSeriesRead, points: PercentileValue[]): Series {
	const yAxisUnit = extractUserPathOrMonitorElementTimeseriesYAxisUnitOrThrow(series.filter);
	return {
		title: toChartLegend(series.legend),
		color: series.color,
		visible: series.visible,
		points: points.map((point) => {
			const valueInSeconds = point.value / 1000;
			return [point.percentile, valueInSeconds] as [number, number];
		}),
		isError: false,
		axis: {
			x: {
				type: 'PERCENTILES',
			},
			y: {
				unit: yAxisUnit,
				displayLegend: false,
			},
		},
		tooltip: {
			footerLabel: buildSeriesTooltipFooterLabel(series),
		},
	};
}

function parsePointValue(series: DashboardSeriesRead, point: ElementPoint | MonitorPoint) {
	switch (series.filter.type) {
		case 'MONITORS_TIMESERIES': {
			return parseMonitorPointValue(point as MonitorPoint);
		}
		case 'ELEMENTS_TIMESERIES': {
			return parseElementPointValue(point as ElementPoint, series.filter.statistic);
		}
		default: {
			return null;
		}
	}
}

function parseMonitorPointValue(point: MonitorPoint) {
	return point.value;
}

function parseElementPointValue(point: ElementPoint, statistic: DashboardUserPathElementTimeSeriesStatisticRead) {
	const elementPointValue = point.statisticsValues[statistic.name];
	if (typeof elementPointValue === 'number') {
		return elementPointValue;
	}
	return null;
}

function toComprehensiveUnit(unit: string): string {
	if (unit) {
		if (isCommonUnit(unit)) {
			return i18n.t('dashboard:series.units.' + unit);
		}
		return unit;
	}
	return '';
}

/**
 * Handles some specific statistics that need unit and multiple conversions, i.e 4000 bytes/s => 32 kbits/s
 */
function handleSIUnits(tuple: readonly [DashboardSeriesRead, Series]): [DashboardSeriesRead, Series] {
	const [dashboardSeries, series] = tuple;
	const statistic = extractUserPathOrMonitorElementTimeseriesStatisticOrThrow(dashboardSeries.filter);
	if (statistic.yAxisUnit === 'BITS_PER_SECOND') {
		return [dashboardSeries, bytesPerSecondToBitsPerSecondSeries(series)];
	}
	return [dashboardSeries, series];
}

function bytesPerSecondToBitsPerSecondSeries(series: Series): Series {
	if (!series.isError) {
		const maxPointValue = [...series.points]
			.filter((point): point is [string | number, number] => typeof point[1] === 'number')
			.sort((a, b) => b[1] - a[1])
			.at(0);
		if (maxPointValue) {
			const [, unit] = convertValue(bytesToBits(maxPointValue[1]), 'BINARY');
			if (unit) {
				return {
					...series,
					points: series.points.map((point) => {
						const xValue = point[0];
						const yValue = point[1] ? convertValueWithUnit(bytesToBits(point[1]), unit)[0] : null;
						return [xValue, yValue];
					}),
					axis: {
						...series.axis,
						y: {
							...series.axis.y,
							unit: unit.symbol + series.axis.y.unit,
						},
					},
				};
			}
		}
	}
	return series;
}

function buildSeriesTooltipFooterLabel(series: DashboardSeries) {
	return `<i>${i18n.t('dashboard:series.tooltip.result', { resultName: series.resultName })}</i>`;
}

function toChartLegend(value: string) {
	return encode(matchAndReplaceWithI18N(value));
}

const addLegendClickItemCallback =
	(doPatchDashboardSeries: (seriesId: string, dashboardSeriesPatchRequest: DashboardSeriesPatchRequest) => void) =>
	([dashboardSeries, series]: [DashboardSeries, Series]) =>
		[
			dashboardSeries,
			{
				...series,
				legendItemClick() {
					doPatchDashboardSeries(dashboardSeries.id, {
						visible: !dashboardSeries.visible,
					});
					return true;
				},
			},
		] as const;

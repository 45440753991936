const formatNumberToString = (language: string, value?: number, digits = 3) => {
	const formatter = new Intl.NumberFormat(language, { minimumFractionDigits: digits, maximumFractionDigits: digits });

	return value === undefined || Number.isNaN(value) ? '' : formatter.format(value);
};

const formatIntegerToString = (language: string, value?: number) => {
	const integerFormatter = new Intl.NumberFormat(language, { compactDisplay: 'short' });

	return value === undefined || Number.isNaN(value) ? '' : integerFormatter.format(value);
};

export const numberUtils = {
	formatNumberToString,
	formatIntegerToString,
};

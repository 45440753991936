import { ComponentPropsWithoutRef, MutableRefObject, useCallback, useRef, useState } from 'react';

import { GridCellParams, GridRenderCellParams, GridSortItem, useGridApiRef } from '@mui/x-data-grid-pro';
import DGColumnChips from '@tricentis/aura/components/DGColumnChips.js';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { GridColDef, GridRowModel, GridRowSelectionModel } from '@mui/x-data-grid/models';
import { useTranslation } from 'react-i18next';
import WorkspacesOutlined from '@mui/icons-material/WorkspacesOutlined';
import { UserWorkspacesDialog } from './workspaces/user-workspaces-dialog';
import { UserInviteDialog } from './invite';
import { UserRoleDialog } from './role/user-role-dialog';
import { i18n } from '../../../i18n';
import { DEFAULT_GRID_PROPS, DEFAULT_PAGINATION_MODEL, onColumnChange } from '../../common/datagrid';
import { Datagrid } from '../../common/data-grid/datagrid';
import { CreateResourceButton } from '../../common/button/create-resource-button';
import { getDateValue } from '../../common/data-grid/getters/get-date-value';
import {
	DatagridAction,
	filterToContextMenuItems,
	filterToSecondaryActions,
} from '../../common/data-grid/actions/data-grid-actions';
import { ActionsCellMenu } from '../../common/data-grid/actions/actions-cell-menu';
import { ContextMenu, ContextMenuFuncts } from '../../common/context-menu/context-menu';
import { useColumnsState, useConfirmModal, useGetMe } from '@neoload/hooks';
import {
	ButtonDescription,
	User,
	USER_ROLE,
	USER_STATUS,
	UserPage,
	UserStatus,
	UserWorkspace,
	Workspace,
} from '@neoload/api';

const emptyUser: User = {
	id: '',
	firstName: '',
	lastName: '',
	email: '',
	role: 'NONE',
	status: 'PENDING',
	workspaces: [],
};

type UserRole = 'ACCOUNT_ADMIN' | 'NEOLOAD_ADMIN' | 'TESTER' | 'GUEST' | 'NONE';

export type UserDataGridProps = {
	isLoading?: boolean;
	userPage: UserPage;
	workspaces: Workspace[];
	onRoleChange: (userId: string, role: UserRole) => void;
	onWorkspacesChange: (userId: string, workspaces: UserWorkspace[]) => void;
	onDeleteUsers: (userIds: string[]) => Promise<void>;
	onUserInvite: (emails: string[], role: UserRole) => void;
	onResendInvitation: (userId: string) => void;
};

type DisplayedModal = 'NONE' | 'EDIT_WORKSPACES' | 'INVITE_USER' | 'EDIT_ROLE';

const columnsStateKey = 'USERS_LIST_COLUMNS_STATE';

const getStatusLabel = (value: UserStatus): string => i18n.t(USER_STATUS[value]);

const UserDataGrid = ({
	isLoading = false,
	userPage = { items: [], total: 0 },
	workspaces = [],
	onRoleChange,
	onWorkspacesChange,
	onDeleteUsers,
	onUserInvite,
	onResendInvitation,
}: UserDataGridProps) => {
	const [selectedUser, setSelectedUser] = useState(emptyUser);
	const { t } = useTranslation(['user']);
	const apiRef = useGridApiRef();
	const { openModal, openDeleteConfirmModal } = useConfirmModal();
	const [{ isAccountAdmin, isAdmin }] = useGetMe();
	const [displayedModal, setDisplayedModal] = useState<DisplayedModal>('NONE');

	const [selectedUserIds, setSelectedUserIds] = useState<GridRowSelectionModel>([]);
	const [paginationModel, setPaginationModel] = useState(DEFAULT_PAGINATION_MODEL);
	const contextMenu: MutableRefObject<ContextMenuFuncts | undefined> = useRef();
	const isAllSelectedUsersArePending = userPage.items
		.filter(({ id }) => selectedUserIds.includes(id))
		.every(({ status }) => status === 'PENDING');

	/**
	 * ACCOUNT_ADMIN can edit all users and can set any role.
	 * NEOLOAD_ADMIN can only edit user that doesn't have the ACCOUNT_ADMIN role and cannot grant the ACCOUNT_ADMIN role
	 * Other roles cannot change role.
	 */
	const shouldEditUserRole = (userRole: UserRole) => isAccountAdmin || (isAdmin && userRole !== 'ACCOUNT_ADMIN');

	const isAllSelectedUsersAreEditable = userPage.items
		.filter(({ id }) => selectedUserIds.includes(id))
		.every(({ role }) => shouldEditUserRole(role));

	const actions: DatagridAction[] = [
		{
			icon: <EditOutlined />,
			text: t('user:editRole'),
			hidden: isAllSelectedUsersArePending || !isAllSelectedUsersAreEditable,
			disabled: selectedUserIds.length !== 1,
			action: () => {
				openUserRoleDialog(userPage.items.find(({ id }) => selectedUserIds[0] === id));
			},
			singleItem: (user: User) => ({
				disabled: false,
				hidden: hasPendingStatus(user) || !shouldEditUserRole(user.role),
				action: () => {
					openUserRoleDialog(user);
				},
			}),
		},
		{
			icon: <EditOutlined />,
			text: t('user:resendInvitation'),
			hidden: !isAllSelectedUsersArePending,
			disabled: selectedUserIds.length !== 1,
			action: () => {
				resendInvitation(userPage.items.find(({ id }) => selectedUserIds[0] === id));
			},
			singleItem: (user: User) => ({
				disabled: false,
				hidden: !hasPendingStatus(user),
				action: () => {
					resendInvitation(user);
				},
			}),
		},
		{
			icon: <WorkspacesOutlined />,
			text: t('workspace:edit'),
			hidden: isAllSelectedUsersArePending,
			disabled: selectedUserIds.length !== 1,
			action: () => {
				openWorkspacesDialog(userPage.items.find(({ id }) => selectedUserIds[0] === id));
			},
			singleItem: (user: User) => ({
				disabled: false,
				hidden: hasPendingStatus(user),
				action: () => {
					openWorkspacesDialog(user);
				},
			}),
		},
		{
			icon: <DeleteOutlined />,
			color: 'error',
			hidden: !isAccountAdmin,
			text: t('deleteButton'),
			tooltip: t('common:delete'),
			action: () => {
				if (selectedUserIds.length === 1) {
					const userToDelete = userPage.items.find((user) => user.id === selectedUserIds[0]);
					if (userToDelete) {
						openConfirmationUserDialog(userToDelete);
					}
				} else {
					openConfirmationAllUsersDialog();
				}
			},
			disabled: selectedUserIds.length === 0,
			singleItem: (user: User) => ({
				disabled: false,
				action: () => {
					deleteUser(user);
				},
			}),
		},
	];

	const hasPendingStatus = (user: User) => user.status === 'PENDING';

	const getRoleValueOptions = () =>
		Object.entries(USER_ROLE)
			.map((role) => ({
				value: role[0] as UserRole,
				label: t(role[1]),
			}))
			.filter((roleValueOption) => shouldEditUserRole(roleValueOption.value));

	function deleteUser(user: User) {
		openConfirmationUserDialog(user);
	}

	function resendInvitation(user: User | undefined) {
		if (!user) {
			return;
		}
		onResendInvitation(user.id);
	}

	function openWorkspacesDialog(user: User | undefined) {
		if (!user) {
			return;
		}
		setSelectedUser(user);
		setDisplayedModal('EDIT_WORKSPACES');
	}

	function openUserRoleDialog(user: User | undefined) {
		if (!user) {
			return;
		}
		setSelectedUser(user);
		setDisplayedModal('EDIT_ROLE');
	}

	function openConfirmationUserDialog(user: User) {
		const title = t('user:deleteSingleUserDialog.title', { user: user.email });
		const content = t('user:deleteSingleUserDialog.label', { user: user.email });
		openDeleteConfirmModal({
			title,
			content,
			handleConfirm: () => onDeleteUsers([user.id]),
		});
	}

	function openConfirmationWorkspaceDialog(user: User, workspaceNameToDelete: string) {
		const title = t('deleteWorkspaceDialog.title', { workspace: workspaceNameToDelete });
		const content = t('deleteWorkspaceDialog.label', {
			workspace: workspaceNameToDelete,
			user: user.email,
		});
		const confirm: ButtonDescription = {
			text: t('common:remove'),
			color: 'info',
		};
		openModal({
			title,
			content,
			confirm,
			handleConfirm: () => deleteWorkspace(user, workspaceNameToDelete),
		});
	}

	function openConfirmationAllUsersDialog() {
		const title = t('user:deleteMultipleUserDialog.title', { userNumber: selectedUserIds.length.toString() });
		const content = t('user:deleteMultipleUserDialog.label', { userNumber: selectedUserIds.length.toString() });
		openDeleteConfirmModal({
			title,
			content,
			handleConfirm: () => onDeleteUsers(selectedUserIds.map((x) => x as string)),
		});
	}

	const deleteWorkspace = async (user: User, workspaceNameToDelete: string) =>
		new Promise<void>((resolve) => {
			const updatedWorkspaces = user.workspaces.filter(
				(workspace: UserWorkspace) => workspace.name !== workspaceNameToDelete,
			);
			onWorkspacesChange(user.id, updatedWorkspaces);
			setSelectedUser({ ...user });
			resolve();
		});

	const columns: GridColDef<User>[] = [
		{ field: 'email', headerName: t('email'), minWidth: 180 },
		{ field: 'firstName', headerName: t('firstName'), minWidth: 180 },
		{ field: 'lastName', headerName: t('lastName'), minWidth: 180 },
		{
			field: 'role',
			headerName: t('role'),
			minWidth: 180,
			type: 'singleSelect',
			valueOptions: getRoleValueOptions(),
			editable: true,
			valueFormatter: (value) => t(USER_ROLE[value]),
		},
		{
			field: 'lastSuccessfulLogin',
			headerName: t('lastLogin'),
			minWidth: 180,
			valueFormatter: getDateValue,
		},
		{
			field: 'workspaces',
			headerName: t('workspaces'),
			minWidth: 180,
			sortable: false,
			renderCell: (props) => (
				<DGColumnChips
					{...props}
					value={props.value
						.filter((workspace: UserWorkspace) => !workspace.enabledForAllUsers)
						.map((workspace: UserWorkspace) => workspace.name)}
					onDelete={(workspaceToDelete: string) => openConfirmationWorkspaceDialog(props.row, workspaceToDelete)}
				/>
			),
		},
		{
			field: 'status',
			headerName: t('status'),
			flex: 1,
			valueGetter: getStatusLabel,
		},
		{
			field: 'actions',
			renderCell: (params: GridRenderCellParams) => <ActionsCellMenu actions={actions} rowData={params.row} />,
			resizable: false,
			disableReorder: true,
			type: 'actions',
		},
	];

	const defaultGridSort: GridSortItem = { field: 'email', sort: 'asc' };
	const initialState = {
		sorting: {
			sortModel: [defaultGridSort],
		},
	};
	const { updatedInitialState, updatedColumns, storeColumnState } = useColumnsState(
		columnsStateKey,
		initialState,
		columns,
		apiRef,
	);
	const inviteAction = {
		children: (
			<CreateResourceButton onClick={() => setDisplayedModal('INVITE_USER')} key='inviteUser'>
				{t('invite')}
			</CreateResourceButton>
		),
	};
	const componentsProps: { toolbar: ComponentPropsWithoutRef<typeof Toolbar>; row: GridRowModel } = {
		toolbar: {
			displayColumnOptions: true,
			description: t('user:description'),
			displaySearchBox: true,
			hideColumnsFromColumnOptions: ['__check__'],
			hideFiltersIcon: true,
			mainActions: isAccountAdmin ? [inviteAction] : [],
			secondaryActions: selectedUserIds.length > 0 ? filterToSecondaryActions(actions) : [],
			syncLocalStorage: {
				datagridId: 'neoloadUserDataGrid',
				isSyncEnabled: true,
			},
			title: t('common:users'),
		},
		row: {
			onContextMenu: contextMenu.current?.openContextMenu,
		},
	};

	const processRowUpdate = useCallback(
		(updatedRow: User, previoudRow: User) => {
			if (previoudRow.role !== updatedRow.role) {
				onRoleChange(updatedRow.id, updatedRow.role);
			}
			return updatedRow;
		},
		[onRoleChange],
	);

	function onUserWorkspaceDialogClose(isOk: boolean, workspaces: UserWorkspace[]) {
		if (isOk) {
			onWorkspacesChange(selectedUser.id, workspaces);
		}
		closeModal();
	}

	function onUserInviteDialogClose(isOk: boolean, emails: string[], role: UserRole) {
		if (isOk) {
			onUserInvite(emails, role);
		}
		closeModal();
	}

	function onUserRoleDialogClose(isOk: boolean, userId: string, role: UserRole) {
		if (isOk) {
			onRoleChange(userId, role);
		}
		closeModal();
	}

	function closeModal() {
		setDisplayedModal('NONE');
	}

	const isCellEditable = (params: GridCellParams<User>): boolean =>
		params.field === 'role' && shouldEditUserRole(params.row.role);

	return (
		<>
			<Datagrid
				{...DEFAULT_GRID_PROPS}
				{...onColumnChange(storeColumnState)}
				apiRef={apiRef}
				loading={isLoading}
				rows={userPage.items}
				disableRowSelectionOnClick={false}
				columns={updatedColumns}
				initialState={updatedInitialState}
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				processRowUpdate={processRowUpdate}
				onRowSelectionModelChange={(model) => setSelectedUserIds(model)}
				isCellEditable={isCellEditable}
				slotProps={componentsProps}
				slots={{
					toolbar: Toolbar,
				}}
			/>
			<ContextMenu apiRef={apiRef} ref={contextMenu} contextMenuItemsList={filterToContextMenuItems(actions)} />
			<UserWorkspacesDialog
				user={selectedUser}
				workspaces={workspaces}
				isOpen={displayedModal === 'EDIT_WORKSPACES'}
				onClose={onUserWorkspaceDialogClose}
			/>
			<UserRoleDialog
				user={selectedUser}
				isOpen={displayedModal === 'EDIT_ROLE'}
				onClose={onUserRoleDialogClose}
				roles={getRoleValueOptions()}
			/>
			<UserInviteDialog
				isOpen={displayedModal === 'INVITE_USER'}
				onClose={onUserInviteDialogClose}
				roles={getRoleValueOptions()}
			/>
		</>
	);
};

export { UserDataGrid };

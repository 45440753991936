import { useTranslation } from 'react-i18next';
import ZeroState from '@tricentis/aura/components/ZeroState.js';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import { Spinner } from '../../../../../common/spinner';
import NoRecording from '../../../../../../assets/NoRecording.svg';
import { EventContent } from '@neoload/api';

export type EventTabContentRequestResponseProps = {
	statusLine?: string;
	headers?: string;
	content?: EventContent;
	warnMessage?: string;
	response?: boolean;
	isContentLoading: boolean;
	contentId?: string;
};

const createTextLine = (text: string | undefined) =>
	text && (
		<pre style={{ overflowWrap: 'anywhere', whiteSpace: 'pre-wrap', fontSize: 12, lineHeight: '16px' }}>{text}</pre>
	);

const isDataStored = (headers: string | undefined, contentId: string | undefined): boolean => !!headers || !!contentId;
const getContentString = (content: EventContent | undefined) =>
	(content?.stringContent ?? '') + (content?.truncated ? '...' : '');

const EventTabContentDetails = ({
	statusLine,
	headers,
	warnMessage,
	response,
	content,
	contentId,
	isContentLoading,
}: EventTabContentRequestResponseProps) => {
	const { t } = useTranslation(['result']);
	return isDataStored(headers, contentId) ? (
		<>
			{warnMessage && (
				<Grid>
					<Alert severity='warning'>
						<Grid>{warnMessage}</Grid>
					</Alert>
				</Grid>
			)}
			{createTextLine(statusLine)}
			{createTextLine(headers)}
			{isContentLoading ? <Spinner /> : createTextLine(getContentString(content))}
		</>
	) : (
		<ZeroState
			containerSx={{
				marginTop: 10,
				h4: { fontSize: '1.25rem' },
				maxWidth: 400,
				img: { opacity: 0.6 },
				backgroundColor: 'transparent',
			}}
			illustration={
				<img
					src={NoRecording}
					width='60%'
					alt={t(`events.content.noContent.no${response ? 'Response' : 'Request'}Title`)}
				/>
			}
			title={t(`events.content.noContent.no${response ? 'Response' : 'Request'}Title`)}
			children={t('events.content.noContent.content')}
		/>
	);
};

export { EventTabContentDetails };

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import { leaseTypeWording } from './leases-drawer-content';
import { getDateValue } from '../../../../common/data-grid/getters/get-date-value';
import { apiBaseUrl, Lease, SapVus, useGetV4WorkspacesByWorkspaceIdSubscriptionQuery, WebVus } from '@neoload/api';

const maxVusSuffix = (vus: WebVus | SapVus | undefined) => (vus ? ` / ${vus.workspaceMaximum}` : '');
const useLeasesColumns = (workspaceId: string | undefined): GridColDef<Lease>[] => {
	const { t } = useTranslation(['workspace'], { keyPrefix: 'tabs.subscription.leases' });
	const { data: subscriptionInfo } = useGetV4WorkspacesByWorkspaceIdSubscriptionQuery(
		{ workspaceId: workspaceId ?? '', fields: ['webVu', 'sapVu'] },
		{ skip: !workspaceId },
	);

	const columns: GridColDef<Lease>[] = [
		{
			field: 'leaseType',
			headerName: t('columns.type'),
			minWidth: 150,
			sortable: true,
			valueFormatter: (value: Lease['leaseType']) => t(`type.${leaseTypeWording(value)}`),
		},
		{
			field: 'owner',
			headerName: t('columns.owner'),
			minWidth: 250,
			sortable: false,
		},
		...(workspaceId
			? []
			: [
					{
						field: 'workspaceName',
						headerName: t('columns.workspace'),
						minWidth: 250,
						flex: 1,
						sortable: true,
						valueGetter: (_: never, lease: Lease) => lease.workspace?.name,
					},
				]),
		{
			field: 'expirationDate',
			headerName: t('columns.expiration'),
			minWidth: 250,
			flex: 1,
			sortable: false,
			valueFormatter: getDateValue,
		},
		{
			field: 'webVus',
			headerName: t('columns.webVus'),
			minWidth: 100,
			flex: 1,
			sortable: false,
			valueFormatter: (value: number) => `${value}${maxVusSuffix(subscriptionInfo?.webVu)}`,
		},
		{
			field: 'sapVus',
			headerName: t('columns.sapVus'),
			minWidth: 100,
			flex: 1,
			sortable: false,
			valueFormatter: (value: number) => `${value}${maxVusSuffix(subscriptionInfo?.sapVu)}`,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			width: 100,
			sortable: false,
			resizable: false,
			disableReorder: true,
			filterable: false,
			renderCell: (params: GridRenderCellParams<Lease>) => (
				<>
					{params.row.leaseType === 'OFFLINE_LEASE' && (
						<Tooltip arrow title={t('download')}>
							<IconButton
								href={`${apiBaseUrl}/v4/license/leases/${encodeURIComponent(params.row.id)}`}
								onClick={(event) => event.stopPropagation()}
								size='small'
							>
								<DownloadOutlined />
							</IconButton>
						</Tooltip>
					)}
					<Tooltip arrow title={t('details.view')}>
						<IconButton size='small'>
							<KeyboardArrowRightIcon />
						</IconButton>
					</Tooltip>
				</>
			),
		},
	];

	return columns;
};

export { useLeasesColumns };

const subscription = {
	title: 'Subscription',
	saasPlan: {
		title: 'NeoLoad SaaS',
		messageError: 'An error occurred while retrieving the subscription information.',
		status: {
			active: 'Active',
			expired: 'Expired',
		},
		expirationDate: 'Expiration date:',
		unknown: 'unknown',
		gauge: {
			vuh: 'Virtual User Hours (VUH)',
			cloudCredits: 'Cloud credits',
			monthlySavedResultDuration: 'This month test results duration',
			totalSavedResultDuration: 'Saved test results duration',
			error: 'An error occurred',
			free: 'free',
			used: 'used',
			total: 'total',
		},
	},
};

export { subscription };

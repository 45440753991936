import { useEffect, useState } from 'react';
import { TestResult, TestResultPage, useGetV4ResultsQuery, useLazyGetV4ResultsByResultIdQuery } from '@neoload/api';
import { useCurrentWorkspace } from '@neoload/hooks';

export const useGetInitialResult = (resultId: string | undefined, pages: Map<number, TestResultPage>) => {
	const [runLazyQuery, lazyQueryResult] = useLazyGetV4ResultsByResultIdQuery();
	const [initialResult, setInitialResult] = useState<TestResult>();
	useEffect(() => {
		if (!resultId || initialResult || !pages.has(0)) {
			return;
		}
		const initialResultFoundInsideFirstPage = pages.get(0)?.items.find(({ id }) => id === resultId);
		if (initialResultFoundInsideFirstPage) {
			setInitialResult(initialResultFoundInsideFirstPage);
		} else {
			runLazyQuery({
				resultId,
			})
				.unwrap()
				.then(setInitialResult)
				.catch((error) => {
					console.log(`An error occured while fetching result ${resultId}`, error);
				});
		}
	}, [initialResult, pages, resultId, runLazyQuery]);
	return {
		...lazyQueryResult,
		data: initialResult,
	};
};

const sort = '-startDate';

export function useGetTestResultData(pageNumber: number, pageSize: number) {
	const [{ id: workspaceId }] = useCurrentWorkspace();
	return useGetV4ResultsQuery({
		workspaceId,
		sort,
		pageSize,
		pageNumber,
		status: ['TERMINATED', 'RUNNING'],
	});
}

import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SectionTitle from '@tricentis/aura/components/SectionTitle.js';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { useWatch } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { ConfigurationFormData } from '.';
import { TestHeaderMenu } from './test-header-menu';
import { RunButton } from './common/run-button';
import { RunAndScheduleButton } from './common/run-and-schedule-button';
import { ModalTestExecution } from '../test-execution';
import { getTranslationKeyToValidatedName } from '../../common/input/input-validation';
import { ScheduleTestModal } from '../reservation/create/schedule-test-modal';
import { CommonRoutes, timeUtils } from '@neoload/utils';
import { PatchTestInput, Test, useGetV4SettingsQuery } from '@neoload/api';
import { useCurrentWorkspace, useGetMe, useSetSnackbars } from '@neoload/hooks';

export type TestHeaderProps = {
	patchTest: (patchTestInput: PatchTestInput) => Promise<Test | void>;
	isConfigurePage: boolean;
	description?: string;
};

const TestHeader = ({ patchTest, isConfigurePage, description }: TestHeaderProps) => {
	const { t } = useTranslation(['test']);
	const theme = useTheme();
	const navigate = useNavigate();
	const test = useWatch<ConfigurationFormData, 'test'>({ name: 'test' });
	const { showInfo, showError } = useSetSnackbars();
	const [currentWorkspace] = useCurrentWorkspace();
	const [openTestExecutionModal, setOpenTestExecutionModal] = useState(false);
	const [openScheduleTestModal, setOpenScheduleTestModal] = useState(false);
	const [{ hasTesterPermissions }] = useGetMe();
	const { data: settings } = useGetV4SettingsQuery();

	const onEditTestName = (name: string) => {
		patchTest({ name })
			.then(() => {
				showInfo({ text: t('messages.updateNameSuccess') });
			})
			.catch((_error) => {
				console.error(_error);
				showError({ text: t('messages.updateNameError') });
			});
	};

	return (
		<>
			<Grid item xs='auto' sx={{ display: 'flex', alignItems: 'center', m: 2 }}>
				<Box sx={{ maxWidth: '1000px' }}>
					<SectionTitle
						onClickBack={
							isConfigurePage
								? async (_event) => {
										await navigate(CommonRoutes.tests.test(test.id));
									}
								: undefined
						}
						title={test.name}
						editable={hasTesterPermissions}
						description={description}
						label={t('test:labelName')}
						slotProps={{
							input: {
								onApply: (updatedData: Record<string, string>) => {
									const updatedName = updatedData.source;
									if (updatedName === test.name) {
										return;
									}
									onEditTestName(updatedName);
								},
								validationRules: [
									(updatedName: string) => {
										const translationKey = getTranslationKeyToValidatedName(updatedName);
										return translationKey ? t(translationKey) : false;
									},
								],
								tooltipTitle: t('editTestName'),
							},
						}}
					/>
				</Box>
				{hasTesterPermissions && (
					<Grid container justifyContent='flex-end' gap={theme.spacing(2)}>
						{!isConfigurePage && (
							<Box display='flex' alignItems='center'>
								<Typography variant='subtitle2' color={theme.palette.text.secondary} marginRight={theme.spacing(1)}>
									{t('testPage.lastModified')}
								</Typography>
								<Tooltip
									arrow
									title={t('testPage.date', {
										date: timeUtils.dateTimeAbsolute(test.updatedAt),
										author: test.userModifierName,
									})}
								>
									<Typography data-testid='lastModified' variant='subtitle2'>
										{timeUtils.dateRelative(test.updatedAt)}
									</Typography>
								</Tooltip>
							</Box>
						)}
						<Stack direction='row' gap={theme.spacing(1)} alignItems='center'>
							<Button
								variant='outlined'
								size='small'
								sx={{ display: isConfigurePage ? 'none' : '' }}
								onClick={() => navigate(CommonRoutes.tests.testConfigure(test.id))}
							>
								{t('configure')}
							</Button>
							{settings?.reservationModeStatus === 'RESERVATION_MODE_DISABLED' ? (
								<RunButton setOpenTestExecutionModal={setOpenTestExecutionModal} disabled={!test.isConfigured} />
							) : (
								<RunAndScheduleButton
									setOpenTestExecutionModal={setOpenTestExecutionModal}
									setOpenScheduleTestModal={setOpenScheduleTestModal}
									disabled={!test.isConfigured}
								/>
							)}
							<TestHeaderMenu id={test.id} patchTest={patchTest} description={description} />
						</Stack>
					</Grid>
				)}
			</Grid>
			<ModalTestExecution
				open={openTestExecutionModal}
				close={() => setOpenTestExecutionModal(false)}
				testId={test.id}
			/>
			{openScheduleTestModal && (
				<ScheduleTestModal
					close={() => setOpenScheduleTestModal(false)}
					workspaceId={currentWorkspace.id}
					testId={test.id}
				/>
			)}
		</>
	);
};

export { TestHeader };

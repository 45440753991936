import Box from '@mui/material/Box';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EditOutlined from '@mui/icons-material/EditOutlined';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { ComparisonTileEditionFormFields } from '../../tile-edition-form';
import { matchAndReplaceWithI18N } from '../../../../../dashboard-common';
import { MonitorComparisonStatistic, Statistic } from '../../../add-series/statistics';
import { getAvailablesStatistics } from '../../../add-series/userpath-tree/userpath-tree';
import {
	useGetV4ResultsByResultIdElementsQuery,
	UserPathElement,
	UserPathElementValueStatistic,
	ValueComparisonRow,
	useGetV4ResultsByResultIdQuery,
} from '@neoload/api';

type ComparisonRowListAccordionItemProps = {
	row: ValueComparisonRow;
	index: number;
	resultId: string;
	onEditRow: () => void;
};
const findElementStatistics = ([pathElement, ...nextPath]: string[], rootElements: UserPathElement[]): Statistic[] => {
	for (const element of rootElements) {
		if (element.name === pathElement) {
			if (nextPath.length > 0) {
				return findElementStatistics(nextPath, element.children);
			}
			return getAvailablesStatistics('COMPARISON', element);
		}
	}
	return [];
};
const ComparisonRowListAccordionItem = ({ row, index, resultId, onEditRow }: ComparisonRowListAccordionItemProps) => {
	const { t } = useTranslation('dashboard');
	const [open, setOpen] = useState(false);
	const { watch, setValue } = useFormContext<ComparisonTileEditionFormFields>();
	const rows = watch('rows');
	const { data: elementTree } = useGetV4ResultsByResultIdElementsQuery({ resultId }, { skip: !resultId });
	const { data: result } = useGetV4ResultsByResultIdQuery({ resultId }, { skip: !resultId });
	const statistics =
		row.rowType === 'ELEMENT'
			? findElementStatistics(row.path, elementTree?.rootElements ?? [])
			: ['MIN', 'MAX', 'AVG'];
	const handleClick = () => {
		setOpen(!open);
	};
	if (!result) {
		return null;
	}
	const elementLabel = row.rowType === 'ELEMENT' ? t('valuesComparison.element') : t('valuesComparison.monitor');
	return (
		<Box
			sx={{
				borderRadius: '4px',
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor: (theme) => theme.palette.divider,
			}}
		>
			<ListItemButton onClick={handleClick}>
				<ListItemIcon sx={{ minWidth: 0, width: '32px' }}>{open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
				<ListItemText
					primary={matchAndReplaceWithI18N(row.rowName)}
					secondary={row.path.join('/')}
					sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
				/>
				<Tooltip title={t('common:remove')} arrow>
					<IconButton
						data-testid={`remove-row-${index}`}
						sx={{
							padding: (theme) => theme.spacing(1),
						}}
						onClick={() => {
							setValue(
								'rows',
								rows.filter((_, index_) => index_ !== index)
							);
						}}
					>
						<DeleteOutlined />
					</IconButton>
				</Tooltip>
			</ListItemButton>
			<Collapse in={open} timeout='auto' unmountOnExit sx={{ padding: 2, paddingTop: 1 }}>
				<Stack sx={{ gap: 2 }}>
					<Stack direction='row' alignItems='center'>
						<Box>
							<Typography title={t('valuesComparison.result')} variant='body2' noWrap color='text.secondary'>
								{t('valuesComparison.result')}
							</Typography>
							<Typography title={elementLabel} variant='body2' noWrap color='text.secondary'>
								{elementLabel}
							</Typography>
						</Box>
						<Box sx={{ width: '100%', paddingLeft: 2 }}>
							<Typography title={result.name} variant='body2' noWrap color='text.primary'>
								{result.name}
							</Typography>
							<Typography title={row.path.at(-1)} variant='body2' noWrap color='text.primary'>
								{row.path.at(-1)}
							</Typography>
						</Box>
						<Box>
							<Tooltip title={t('common:edit')} arrow>
								<IconButton sx={{}} onClick={onEditRow}>
									<EditOutlined />
								</IconButton>
							</Tooltip>
						</Box>
					</Stack>

					<Select
						sx={{ width: '100%' }}
						value={row.statistic}
						onChange={(event: SelectChangeEvent) => {
							setValue(
								`rows.${index}.statistic`,
								event.target.value as UserPathElementValueStatistic | MonitorComparisonStatistic
							);
						}}
					>
						{statistics.map((stat) => (
							<MenuItem key={stat} value={stat}>
								{t(
									`${
										row.rowType === 'ELEMENT' ? 'common:statistics.userPathElementValueStatistics' : 'statistics'
									}.${stat}`
								)}
							</MenuItem>
						))}
					</Select>
					<Divider />
					<TextField
						sx={{ width: '100%' }}
						label='Display name'
						defaultValue={row.rowName}
						onChange={(event) => {
							setValue(`rows.${index}.rowName`, event.target.value);
						}}
					/>
				</Stack>
			</Collapse>
		</Box>
	);
};

export { ComparisonRowListAccordionItem };

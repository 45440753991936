import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ActionsCellMenu } from '../../common/data-grid/actions/actions-cell-menu';
import { DatagridAction } from '../../common/data-grid/actions/data-grid-actions';
import { INFRASTRUCTURE_PROVIDER_TYPE_DISPLAY_NAMES } from '@neoload/utils';
import { InfrastructureProvider } from '@neoload/api';

const useInfrastructureProviderColumns = (actions: DatagridAction[]): GridColDef<InfrastructureProvider>[] => {
	const { t } = useTranslation(['infrastructureProviders']);
	const columns: GridColDef<InfrastructureProvider>[] = [
		{
			field: 'name',
			headerName: t('providers.columns.name.title'),
			minWidth: 350,
			sortable: true,
		},
		{
			field: 'type',
			headerName: t('providers.columns.type.title'),
			minWidth: 150,
			sortable: false,
			valueGetter: (_, row) => INFRASTRUCTURE_PROVIDER_TYPE_DISPLAY_NAMES[row.type],
		},
		{
			field: 'apiUrl',
			headerName: t('providers.columns.url.title'),
			minWidth: 250,
			flex: 1,
			sortable: false,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			sortable: false,
			resizable: false,
			disableReorder: true,
			filterable: false,
			renderCell: (params) => (
				<>
					<ActionsCellMenu actions={actions} rowData={params.row} stopPropagation={true} />
					<Tooltip arrow title={t('providers.columns.viewDetails')}>
						<IconButton size='small'>
							<KeyboardArrowRightIcon />
						</IconButton>
					</Tooltip>
				</>
			),
		},
	];

	return actions.length > 0 ? columns : columns.filter((column) => column.type !== 'actions');
};

export { useInfrastructureProviderColumns };

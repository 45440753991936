import { GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useCommonZonesColumns } from '../use-common-zones-columns';
import { OneOfZone, ZoneDatagridActionGetter } from '../common-zones-datagrid';
import { StaticZoneRead } from '@neoload/api';

export const useStaticZonesColumns = (
	actionGetters: ZoneDatagridActionGetter<OneOfZone>[],
): GridColDef<StaticZoneRead>[] => {
	const { t } = useTranslation(['zone']);
	const commonColumns = useCommonZonesColumns<StaticZoneRead>(actionGetters);
	return [
		commonColumns[0],
		{
			field: 'controllers',
			minWidth: 180,
			headerName: t('columns.controllers'),
			type: 'number',
			valueGetter: (_, row) => row.controllers.length,
		},
		{
			field: 'loadGenerators',
			minWidth: 180,
			headerName: t('columns.lgs'),
			type: 'number',
			valueGetter: (_, row) => row.loadGenerators.length,
		},
		commonColumns[1],
		commonColumns[2],
	] satisfies GridColDef<StaticZoneRead>[];
};

import { useEffect, useRef } from 'react';
import deepEqual from 'deep-equal';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TestExecutionErrorTypes, TestExecutionForm } from '../types.d';
import {
	estimateEndDateByTestDuration,
	executionModalErrors,
	fieldsToTestExecutionInput,
	getPartialErrorSubscription,
	getTestExecutionPartialError,
} from '../test-execution-helpers';
import {
	isFetchBaseQueryError,
	isInvalidPropertyErrorMatching,
	useLazyGetV4WorkspacesByWorkspaceIdSubscriptionQuery,
	usePostV4TestExecutionsMutation,
	WorkspaceSubscription,
} from '@neoload/api';
import { useCurrentWorkspace } from '@neoload/hooks';

const useTestExecutionWatcher = () => {
	const [testExecution] = usePostV4TestExecutionsMutation();
	const [lazyGetWorkspaceSubscription] = useLazyGetV4WorkspacesByWorkspaceIdSubscriptionQuery();
	const [{ id: workspaceId }] = useCurrentWorkspace();
	const { t } = useTranslation(['test']);

	const { setValue } = useFormContext<TestExecutionForm>();
	const test = useWatch<TestExecutionForm, 'test'>({ name: 'test' });
	const resources = useWatch<TestExecutionForm, 'resources'>({ name: 'resources' });
	const errorsInFirstCall = useWatch<TestExecutionForm, 'errorsInFirstCall'>({ name: 'errorsInFirstCall' });
	const reservationMode = useWatch<TestExecutionForm, 'reservation.reservationModeStatus'>({
		name: 'reservation.reservationModeStatus',
	});

	const previousTest = useRef<TestExecutionForm['test']>();
	const previousResources = useRef<TestExecutionForm['resources']>();

	useEffect(() => {
		if (deepEqual(test, previousTest.current) && deepEqual(resources, previousResources.current)) {
			return;
		}
		const getWorkspaceSubscription = async (
			workspaceId: string,
			endTest: Date,
		): Promise<WorkspaceSubscription | undefined> => {
			const { data: subscriptions, error: subscriptionError } = await lazyGetWorkspaceSubscription({
				workspaceId,
				to: endTest.toISOString(),
			});
			return subscriptionError ? getPartialErrorSubscription(subscriptionError) : subscriptions;
		};
		if (test?.id && !errorsInFirstCall) {
			setValue('confirmable', false);
			testExecution({
				dryRun: true,
				testExecutionInput: fieldsToTestExecutionInput(test, resources),
			})
				.unwrap()
				.then(async (testExecution) => {
					if (resources.duration !== previousResources.current?.duration) {
						const subs = await getWorkspaceSubscription(
							workspaceId,
							estimateEndDateByTestDuration(resources.duration ?? 'PT0S', reservationMode),
						);
						setValue('resources.sapVu.available', subs?.sapVu?.available ?? 0);
						setValue('resources.webVu.available', subs?.webVu?.available ?? 0);
					}
					setValue('errors', []);
					setValue(
						'resources.cloudCredits.needed',
						testExecution.resources.consumedCurrencyByConcurrencyType?.CLOUD_CREDITS ?? 0,
					);

					setValue('resources.vuh.needed', testExecution.resources.consumedCurrencyByConcurrencyType?.VUH ?? 0);
					setValue('confirmable', true);
				})
				.catch((error) => {
					if (
						isInvalidPropertyErrorMatching(error.data.errors[0], 'incompatible', 'invalid', 'reservationVirtualUsers')
					) {
						setValue('errors', [
							{ type: TestExecutionErrorTypes.Global, sentence: t('configuration.errors.incompatibleReservationVus') },
						]);
					} else if (isFetchBaseQueryError(error)) {
						const partialError = getTestExecutionPartialError(error);
						if (partialError?.errors) {
							setValue('errors', executionModalErrors(partialError.errors));
						}
					}
				})
				.finally(() => {
					previousTest.current = test;
					previousResources.current = resources;
				});
		}
	}, [
		test,
		resources,
		setValue,
		testExecution,
		previousTest,
		previousResources,
		errorsInFirstCall,
		lazyGetWorkspaceSubscription,
		workspaceId,
		reservationMode,
		t,
	]);
};

export { useTestExecutionWatcher };

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';
import { usePostV4LicenseForcedReleasesMutation } from '@neoload/api';
import { useCrudBatchMessage, useSetSnackbars } from '@neoload/hooks';

type ForceReleaseModalProps = {
	open: boolean;
	onClose: () => void;
	leaseId: string;
};

type ForceReleaseForm = {
	leaseId: string;
	supportResponse: string;
};

const ForceReleaseModal = ({ open, onClose, leaseId }: ForceReleaseModalProps) => {
	const { t } = useTranslation(['workspaceSubscription']);
	const { showInfo } = useSetSnackbars();
	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<ForceReleaseForm>({
		mode: 'onChange',
		defaultValues: {
			leaseId: leaseId,
		},
	});
	const [forceRelease, { isLoading: isForceReleasing }] = usePostV4LicenseForcedReleasesMutation();
	const { remove } = useCrudBatchMessage('workspaceSubscription', 'forceReleaseModal');
	const onSubmit = async (form: ForceReleaseForm) => {
		await remove([leaseId], (leaseId) =>
			forceRelease({
				postForcedRelease: {
					leaseIdentifier: leaseId,
					supportResponse: form.supportResponse,
				},
			})
				.unwrap()
				.then(onClose),
		);
	};
	const copyToClipboard = () =>
		navigator.clipboard.writeText(leaseId).then(() => {
			showInfo({ text: t('forceReleaseModal.clipboardSuccess') });
		});

	return (
		<Dialog open={open} onClose={onClose} aria-labelledby='force-release-modal-title' fullWidth>
			<form
				onSubmit={handleSubmit(onSubmit)}
				style={{
					overflowY: 'auto',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<DialogTitle id='force-release-modal-title'>{t('forceRelease')}</DialogTitle>
				<DialogContent>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 3,
							marginTop: 2,
						}}
					>
						<Typography>{t('forceReleaseModal.leaseId.info')}</Typography>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<TextField
								autoFocus
								label={t('forceReleaseModal.leaseId.label')}
								fullWidth
								required
								multiline
								minRows={2}
								maxRows={10}
								disabled
								{...register('leaseId')}
							/>
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
								<Button
									startIcon={<ContentCopyOutlined />}
									onClick={copyToClipboard}
									aria-label={t('common:clipboard')}
								>
									{t('common:clipboard')}
								</Button>
							</Box>
						</Box>
						<Typography>{t('forceReleaseModal.supportKey.info')}</Typography>
						<TextField
							label={t('forceReleaseModal.supportKey.label')}
							fullWidth
							multiline
							minRows={2}
							required
							error={!!errors.supportResponse}
							helperText={errors.supportResponse?.message}
							{...register('supportResponse', {
								required: t('forceReleaseModal.supportKey.required'),
							})}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} variant='text' color='primary' data-trackingid='force-release-cancel'>
						{t('common:cancel')}
					</Button>
					<Button
						variant='contained'
						color='error'
						disabled={isForceReleasing || !isValid}
						startIcon={isForceReleasing ? <CircularProgress size={24.5} color='inherit' /> : null}
						type='submit'
						aria-label={t('forceRelease')}
						data-trackingid='force-release-ok'
					>
						{t('forceRelease')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export { ForceReleaseModal };

import Divider from '@mui/material/Divider';
import { TestExecutionModalContentErrors } from './test-execution-modal-content-errors';
import { useTestExecutionWatcher } from './use-test-execution-watcher';
import { TestExecutionConfiguration } from '../sections/test-execution-configuration';
import { TestExecutionResultInformation } from '../sections/test-execution-result-information';

const TestExecutionModalContent = () => {
	useTestExecutionWatcher();

	return (
		<>
			<TestExecutionModalContentErrors />
			<TestExecutionConfiguration />
			<Divider />
			<TestExecutionResultInformation />
		</>
	);
};

export { TestExecutionModalContent };

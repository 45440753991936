import { useTranslation } from 'react-i18next';
import { GridColDef, GridComparatorFn, GridRenderCellParams } from '@mui/x-data-grid/models';
import { gridStringOrNumberComparator } from '@mui/x-data-grid';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import { ActionsCell } from '../../../../../common/data-grid/actions/actions-cell';
import { DatagridAction } from '../../../../../common/data-grid/actions/data-grid-actions';
import { ActionsCellMenu } from '../../../../../common/data-grid/actions/actions-cell-menu';
import { timeUtils } from '@neoload/utils';
import { Interval, usePatchV4ResultsByResultIdIntervalsAndIntervalIdMutation } from '@neoload/api';

const formatDuration = (value: string): string => timeUtils.durationSeconds(value);

const offsetDurationComparator: GridComparatorFn<string> = (v1, v2, cellParameters1, cellParameters2) =>
	gridStringOrNumberComparator(formatDuration(v1), formatDuration(v2), cellParameters1, cellParameters2);

function useIntervalsDatagridColumns(resultId: string, actions: DatagridAction[]) {
	const { t } = useTranslation(['result']);
	const [patchInterval] = usePatchV4ResultsByResultIdIntervalsAndIntervalIdMutation();
	const visibilityActions: (interval: Interval) => DatagridAction[] = (interval: Interval) => [
		{
			icon: interval.hidden ? <VisibilityOffOutlined /> : <VisibilityOutlined />,
			text: t(`intervals.${interval.hidden ? 'show' : 'hide'}`),
			disabled: false,
			sx: {},
			action: () => {
				// action expects () => void, so just call the raw rtk hook.
				// eslint-disable-next-line @typescript-eslint/no-floating-promises
				patchInterval({ intervalId: interval.id, resultId, intervalPatchRequest: { hidden: !interval.hidden } });
			},
		},
	];
	const columns: GridColDef<Interval>[] = [
		{
			field: 'visibility-actions',
			width: 70,
			renderCell: (params: GridRenderCellParams) => (
				<ActionsCell rowData={params.id.toString()} actions={visibilityActions(params.row)} />
			),
			resizable: false,
			disableReorder: true,
			type: 'actions',
		},
		{
			field: 'startOffset',
			headerName: t('intervals.startOffset'),
			valueFormatter: (value) => formatDuration(value),
			width: 200,
			sortable: true,
			sortComparator: offsetDurationComparator,
		},
		{
			field: 'endOffset',
			headerName: t('intervals.endOffset'),
			valueFormatter: (value) => formatDuration(value),
			width: 200,
			sortable: true,
			sortComparator: offsetDurationComparator,
		},
		{
			field: 'name',
			minWidth: 250,
			headerName: t('intervals.name'),
			sortable: true,
			align: 'left',
			flex: 1,
		},
	];
	if (actions.length > 0) {
		columns.push({
			field: 'actions',
			renderCell: (params) => <ActionsCellMenu rowData={params.row} actions={actions} />,
			resizable: false,
			disableReorder: true,
			type: 'actions',
			align: 'center',
			minWidth: 50,
			maxWidth: 50,
		});
	}
	return columns;
}

export { useIntervalsDatagridColumns };

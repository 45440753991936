const license = {
	title: 'License & Leases',
	installLicense: 'Install license key',
	licenseManagement: 'License management',
	zeroState: {
		title: 'Install your first license key or lease',
		message: 'License keys and offline leases give you access to all resources you need to run tests.',
	},
	license: {
		title: 'License',
		messageError: 'An error occurred while retrieving the license information.',
		noLicenseFound: 'No license found.',
		messageInstallation:
			'Download your ".lic" file from your <1>license management</1>, then install and activate the license. Alternatively, <3>learn more</3> about how to lease a license from another NeoLoad Web instance.',
		status: {
			active: 'Active',
			expired: 'Expired',
			notActivated: 'Not activated',
			deactivated: 'Deactivated',
			noLicense: 'No license',
		},
		infos: {
			expirationDate: 'Expiration date:',
			edition: 'Edition:',
			version: 'Version:',
			webVuQuota: 'Web VUs quota:',
			sapVuQuota: 'SAP VUs quota:',
			concurrentRuns: 'Concurrent runs:',
			name: 'Name:',
			company: 'Company:',
			address: 'Address:',
		},
		messages: {
			licenseInstalled: 'License installed.',
			licenseActiveInstalled: 'License activated.',
			error: 'An error occurred.',
		},
		activation: {
			activateButton: 'Activate',
			title: 'Activate license',
			step1: '1. Your activation request file <1>"license-activation.req"</1> will be generated.',
			step2: '2. Continue in <1>license management</1> to activate the license.',
			step3: '3. Upload activated license back here and finish activation.',
			generateButton: 'Generate and download',
			dropFile: 'Drop license from the local storage here, or <1>select a file</1>.',
			cancelButton: 'Cancel',
			confirmButton: 'Finish activation',
			generatedMessage: 'Activation request generated',
			errorMessage: 'Error while generating activation request',
		},
		deactivation: {
			deactivateButton: 'Deactivate license key',
			title: 'Deactivate license key?',
			firstMessage: 'If you deactivate the license key, it will prevent you from running tests.',
			secondMessage:
				'A request file "license-deactivation.req" will be generated and needs to be sent to NeoLoad. This allows installation on another server if needed.',
			cancelButton: 'Keep license',
			confirmButton: 'Deactivate',
			generatedMessage: 'Deactivation request generated',
			errorMessage: 'Error while generating deactivation request',
			finalizeDeactivation: {
				title: 'Finalize deactivation',
				message:
					'Continue in <1>license management</1> to finalize the deactivation process, or the license might not be re-installed on a different server.',
				closeButton: 'Close',
			},
			inactiveMessage: 'This is inactive because you still have active leases or VUs in use',
		},
	},
	saasPlan: {
		title: 'NeoLoad SaaS',
		messageError: 'An error occurred while retrieving the subscription information.',
		status: {
			active: 'Active',
			expired: 'Expired',
		},
		expirationDate: 'Expiration date:',
		unknown: 'unknown',
		gauge: {
			vuh: 'Virtual User Hours (VUH)',
			cloudCredits: 'Cloud credits',
			monthlySavedResultDuration: 'This month test results duration',
			totalSavedResultDuration: 'Saved test results duration',
			error: 'An error occurred',
			free: 'free',
			used: 'used',
			total: 'total',
		},
	},
};

export { license };

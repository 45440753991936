type Mapping = {
	v1: string;
	v2: string | RegExp;
};

const frontV1URL = import.meta.env.VITE_FRONT_V1_URL;

const urlMappings: Mapping[] = [
	{ v1: '/#!test', v2: '/tests' },
	{ v1: '/#!test-settings/$1/overview', v2: /^\/tests\/([\dA-Fa-f-]{36})$/ },
	{ v1: '/#!test-settings/$1/', v2: /^\/tests\/([\dA-Fa-f-]{36})\/configure/ },
	{ v1: '/#!resources/reservations', v2: '/settings/reservations' },
	{ v1: '/#!search', v2: '/results' },
	{ v1: '/#!result/$1/overview', v2: /^\/results\/([\dA-Fa-f-]{36})\/overview/ },
	{ v1: '/#!result/$1/values/transactions', v2: /^\/results\/([\dA-Fa-f-]{36})\/values\/transactions/ },
	{ v1: '/#!result/$1/values/pages', v2: /^\/results\/([\dA-Fa-f-]{36})\/values\/pages/ },
	{ v1: '/#!result/$1/values/requests', v2: /^\/results\/([\dA-Fa-f-]{36})\/values\/requests/ },
	{ v1: '/#!result/$1/values/actions', v2: /^\/results\/([\dA-Fa-f-]{36})\/values\/actions/ },
	{ v1: '/#!result/$1/values/counters', v2: /^\/results\/([\dA-Fa-f-]{36})\/values\/counters/ },
	{ v1: '/#!result/$1/events', v2: /^\/results\/([\dA-Fa-f-]{36})\/events/ },
	{ v1: '/#!result/$1/slas', v2: /^\/results\/([\dA-Fa-f-]{36})\/slas/ },
	{ v1: '/#!result/$1/logs', v2: /^\/results\/([\dA-Fa-f-]{36})\/logs/ },
	{ v1: '/#!dashboards', v2: '/dashboards' },
	{ v1: '/#!dashboard/$1', v2: /^\/dashboards\/([\da-f]{24})/ },
	{ v1: '/#!dashboard/report/$1', v2: /^\/reports\/([\dA-Fa-f-]{36})/ },
	{ v1: '/#!myprofile', v2: '/user/profile' },
	{ v1: '/#!myprofile/repositories', v2: '/user/git-repositories' },
	{ v1: '/#!account/workspaces', v2: '/settings/workspaces' },
	{ v1: '/#!account/users', v2: '/settings/users' },
	{ v1: '/#!account/settings', v2: '/settings' },
	{ v1: '/#!account/infraprovider', v2: '/settings/infrastructure-providers' },
	{ v1: '/#!account/usage', v2: '/settings/usage-history' },
	{ v1: '/#!account-workspace/$1/users', v2: /^\/settings\/workspaces\/([\da-f]{24})\/members/ },
	{ v1: '/#!account-workspace/$1/subscription', v2: /^\/settings\/workspaces\/([\da-f]{24})\/subscriptions/ },
	{ v1: '/#!account-workspace/$1/webhooks', v2: /^\/settings\/workspaces\/([\da-f]{24})\/webhooks/ },
	{ v1: '/#!account-workspace/$1/deletion-policies', v2: /^\/settings\/workspaces\/([\da-f]{24})\/policies/ },

	// specialRedirectionsToVaadin:
	{ v1: '/#!dashboards', v2: /^\/results\/([\dA-Fa-f-]{36})\/dashboards/ },

	{ v1: '/#!account/subscription', v2: '/settings/subscription' },
	{ v1: '/#!account/subscription', v2: '/settings/license' },

	{ v1: '/#!resources', v2: '/settings/zones/cloud' },
	{ v1: '/#!resources', v2: '/settings/zones/static' },
	{ v1: '/#!resources', v2: '/settings/zones/dynamic' },

	{ v1: '/#!myprofile/tokens', v2: '/user/access-tokens' },
];

const redirectToOldFront = () => {
	const currentPath = window.location.pathname;
	const foundMapping = urlMappings.find((url) =>
		typeof url.v2 === 'string' ? url.v2 === currentPath : url.v2.test(currentPath),
	);
	const redirectPath = foundMapping ? currentPath.replace(foundMapping.v2, foundMapping.v1) : '/';
	window.location.href = frontV1URL + redirectPath;
};

export { redirectToOldFront };

import { UseFormSetValue } from 'react-hook-form';
import { TFunction } from 'i18next';
import { ConfigurationFormData, ConfigurationProjectFormData } from './types';
import { ScenarioTypes } from './scenarios/scenario-radio-group';
import {
	ApiError,
	isInvalidPropertyErrorMatching,
	GitUploadSettings,
	InvalidPropertyValueErrorDescription,
	isInvalidPropertyError,
	isNotFoundError,
	isPayloadTooLargeError,
	isTooManyRequestError,
	isUnAuthorizedError,
	Project,
	UploadSettings,
} from '@neoload/api';

export type ConfigurationError = { sentenceKey?: string; sentence: string; parameterName?: string };

export const translateErrorMessages = (error: ApiError, name?: string): ConfigurationError[] => {
	const testExecutionErrors: ConfigurationError[] = [];
	if (isPayloadTooLargeError(error)) {
		testExecutionErrors.push({
			sentence: error.description,
			sentenceKey: 'configuration.errors.payloadTooLarge',
		});
	}
	if (isTooManyRequestError(error)) {
		testExecutionErrors.push({
			sentence: error.description,
			sentenceKey: 'configuration.errors.tooManyRequests',
		});
	}
	if (isUnAuthorizedError(error)) {
		testExecutionErrors.push({
			sentence: error.description,
			sentenceKey: 'configuration.errors.unauthorized',
		});
	}
	if (isNotFoundError(error)) {
		testExecutionErrors.push({
			sentence: error.description,
			sentenceKey: 'configuration.errors.notFound',
		});
	}
	if (isInvalidPropertyError(error) && error.errors !== undefined) {
		for (const apiError of error.errors) {
			testExecutionErrors.push(translateApiError(apiError, name));
		}
	}

	if (testExecutionErrors.length === 0) {
		testExecutionErrors.push({
			sentence: error.description,
		});
	}

	return testExecutionErrors;
};

export const configurationErrorToString = (error: ApiError, t: TFunction, name?: string) =>
	translateErrorMessages(error, name)
		.map((error) => {
			if (error.sentenceKey) {
				if (error.parameterName !== undefined) {
					return t(error.sentenceKey, { name: error.parameterName });
				}
				return t(error.sentenceKey);
			}

			return error.sentence;
		})
		.join(', ');

const translateApiError = (
	apiError: InvalidPropertyValueErrorDescription,
	parameterName?: string,
): ConfigurationError => {
	if (isInvalidPropertyErrorMatching(apiError, 'required', 'property', 'name')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.noName',
		};
	} else if (isInvalidPropertyErrorMatching(apiError, 'required', 'projectEntity', 'population')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.noPopulation',
		};
	} else if (isInvalidPropertyErrorMatching(apiError, 'required', 'file', 'nlp')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.invalidFile',
		};
	} else if (isInvalidPropertyErrorMatching(apiError, 'notFound', 'projectEntity', 'referencedFile')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.missingProjectContent',
		};
	} else if (isInvalidPropertyErrorMatching(apiError, 'parsingFailure')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.parsingFailure',
		};
	} else if (isInvalidPropertyErrorMatching(apiError, 'incompatible', 'projectEntity', 'circularReferences')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.circularReferences',
		};
	} else if (isInvalidPropertyErrorMatching(apiError, 'incompatible', 'projectEntity', 'scenarioName')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.nameConflict',
			parameterName: parameterName ?? '',
		};
	} else if (isInvalidPropertyErrorMatching(apiError, 'network', 'invalid', 'scm.url')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.scmUrl',
		};
	} else if (isInvalidPropertyErrorMatching(apiError, 'network', 'invalid', 'scm.credentials')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.scmCredentials',
		};
	} else if (isInvalidPropertyErrorMatching(apiError, 'network', 'invalid', 'scm.host')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.scmHost',
		};
	} else if (isInvalidPropertyErrorMatching(apiError, 'network', 'invalid', 'scm.proxy')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.scmProxy',
		};
	}

	return {
		sentence: apiError.message,
		sentenceKey: '',
	};
};

const isGitUploadSettings = (projectSettings: UploadSettings | undefined): projectSettings is GitUploadSettings =>
	projectSettings !== undefined && projectSettings.source === 'GIT';

export const projectToConfigurationProjectFormData = (projectData: Project): ConfigurationProjectFormData => ({
	name: projectData.name,
	source: projectData.uploadSettings?.source,
	updatedAt: projectData.updatedAt,
	size: projectData.size,
	userModifierName: projectData.userModifierName,
	securityStatus: projectData.securityStatus ?? 'DISABLED',
	...(isGitUploadSettings(projectData.uploadSettings) && {
		reference: projectData.uploadSettings.reference,
		repositoryId: projectData.uploadSettings.repositoryId,
		repositoryName: projectData.uploadSettings.repositoryName,
	}),
	populations: projectData.populations,
});

export const projectToConfigurationForm = (setValue: UseFormSetValue<ConfigurationFormData>, project: Project) => {
	setValue('project', projectToConfigurationProjectFormData(project));
	setValue('configurationDisabled', false);
	const scenarioFrontProject = (project.scenarios ?? []).map(({ maximumVu, name, duration }) => ({
		duration: duration,
		maximumVu: maximumVu,
		name: name,
		type: ScenarioTypes.FromProject,
	}));
	setValue('scenario.fromProject', scenarioFrontProject);
	setValue('scenario.type', ScenarioTypes.FromProject);
	setValue('scenario.selected', scenarioFrontProject[0]);
};

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TestExecutionErrorTypes, TestExecutionForm } from '../types.d';

const TestExecutionModalContentErrors = () => {
	const { t } = useTranslation(['test']);

	const { getValues } = useFormContext<TestExecutionForm>();

	const errors = useWatch<TestExecutionForm, 'errors'>({ name: 'errors' });
	const errorsGlobal = errors.filter((error) => error.type === TestExecutionErrorTypes.Global);

	const isLicenseForStaticResourceMissing =
		getValues('resources.zoneType') === 'STATIC' &&
		getValues('resources.webVu.needed') === 0 &&
		getValues('resources.sapVu.needed') === 0 &&
		errors.length === 0;

	return (
		<>
			{errorsGlobal.length > 0 && (
				<Alert sx={{ marginBottom: 2, marginTop: 0, alignItems: 'center' }} severity='error'>
					<Typography variant='body2'>{errorsGlobal[0].sentence}</Typography>
				</Alert>
			)}
			{isLicenseForStaticResourceMissing && (
				<Alert sx={{ marginBottom: 2, marginTop: 0, alignItems: 'center' }} severity='warning'>
					<Typography variant='body2'>{t('testExecution.warningRunTestWithoutValidLicense')}</Typography>
				</Alert>
			)}
		</>
	);
};

export { TestExecutionModalContentErrors };

import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import { Link as RouterLink } from 'react-router';
import Link from '@mui/material/Link';
import { ReservationStatusChip } from './reservation-status-chip';
import { LabelledItem } from '../../common/labelled-item';
import { Reservation } from '@neoload/api';
import { numberUtils, TestRoutes, timeUtils } from '@neoload/utils';

export type ReservationDrawerContentProps = {
	reservation: Reservation;
	workspacesMap: Record<string, string>;
	zonesMap: Record<string, string>;
};

const useDashIfEmpty = (value: string | undefined): string => (value && value.length > 0 ? value : '—');

const ReservationDrawerContent = ({ reservation, workspacesMap, zonesMap }: ReservationDrawerContentProps) => {
	const { t, i18n } = useTranslation(['reservation']);

	const duration = dayjs(reservation.endDate).diff(reservation.startDate);

	const notAvailableIfEmpty = (value: string | undefined): string => {
		if (value !== undefined) {
			return value;
		}

		return t('grid.drawer.notAvailable');
	};

	const nameValue = (reservation: Reservation) => {
		if (reservation.testId === undefined) {
			return reservation.name;
		}

		return (
			<Link underline='hover' component={RouterLink} to={TestRoutes.test(reservation.testId)} title={reservation.name}>
				{reservation.name}
			</Link>
		);
	};

	return (
		<List
			sx={{
				overflowY: 'auto',
				padding: 2,
				flexGrow: 1,
			}}
		>
			<LabelledItem
				id='reservation-status'
				title={t('grid.drawer.status')}
				value={<ReservationStatusChip status={reservation.status} />}
			/>
			<LabelledItem id='reservation-name' title={t('grid.drawer.name')} value={nameValue(reservation)} />
			<LabelledItem
				id='reservation-description'
				title={t('grid.drawer.description')}
				value={useDashIfEmpty(reservation.description)}
			/>

			<Divider sx={{ marginBottom: 2 }} />

			<LabelledItem
				id='reservation-start'
				title={t('grid.drawer.start')}
				value={timeUtils.dateTimeAbsolute(reservation.startDate)}
			/>
			<LabelledItem
				id='reservation-end'
				title={t('grid.drawer.end')}
				value={timeUtils.dateTimeAbsolute(reservation.endDate)}
			/>
			<LabelledItem
				id='reservation-duration'
				title={t('grid.drawer.duration')}
				value={timeUtils.durationFull(duration)}
			/>

			<Divider sx={{ marginBottom: 2 }} />

			<LabelledItem
				id='reservation-workspace'
				title={t('grid.drawer.workspace')}
				value={useDashIfEmpty(workspacesMap[reservation.workspaceId])}
			/>
			<LabelledItem
				id='reservation-controller'
				title={t('grid.drawer.controller')}
				value={notAvailableIfEmpty(zonesMap[reservation.controllerZoneId])}
			/>
			<LabelledItem
				id='reservation-zones-lgs'
				title={t('grid.drawer.zonesLgs')}
				value={Object.entries(reservation.lgCountByZoneId).map(
					([zone, lgs]) => `${notAvailableIfEmpty(zonesMap[zone])}: ${lgs}`,
				)}
			/>
			<LabelledItem
				id='reservation-license-vus'
				title={t('grid.drawer.licenseVirtualUsers')}
				value={
					reservation.vuCountByVuType
						? Object.entries(reservation.vuCountByVuType).map(
								([vuType, vus]) =>
									`${t('grid.vus.' + vuType.toLowerCase())}: ${numberUtils.formatNumberToString(i18n.language, vus, 0)}`, // remove digit after coma
							)
						: '—'
				}
			/>
		</List>
	);
};

export { ReservationDrawerContent };

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid';
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';
import { OneOfZone, ZoneDatagridActionGetter } from './common-zones-datagrid';
import { ActionsCell } from '../../common/data-grid/actions/actions-cell';
import { ActionsCellMenu } from '../../common/data-grid/actions/actions-cell-menu';
import { useSetSnackbars } from '@neoload/hooks';

export const useCommonZonesColumns = <T extends OneOfZone>(
	actionGetters: ZoneDatagridActionGetter<T>[],
): GridColDef<T>[] => {
	const { t } = useTranslation(['zone']);
	const { showInfo } = useSetSnackbars();
	const copyToClipboard = (zoneId: string) =>
		navigator.clipboard.writeText(zoneId).then(() => {
			showInfo({ text: t('details.clipboardSuccess') });
		});

	return [
		{
			field: 'name',
			flex: 1,
			minWidth: 250,
			headerName: t('columns.name'),
		},
		{
			field: 'zoneId',
			minWidth: 120,
			headerName: t('columns.zoneId'),
		},
		{
			field: 'actions',
			type: 'actions',
			sortable: false,
			disableReorder: true,
			align: 'center',
			minWidth: 50 * (1 + actionGetters.length),
			filterable: false,
			renderCell: (params) => {
				const actions = actionGetters
					.map((actionGetter) => (typeof actionGetter === 'function' ? actionGetter(params.row) : actionGetter))
					.filter((action) => !!action);
				return (
					<>
						{params.row.zoneId && (
							<Tooltip arrow title={t('actions.copyZoneIdToClipboard')}>
								<IconButton
									onClick={async (event) => {
										event.stopPropagation();
										await copyToClipboard(params.row.zoneId);
									}}
									size='small'
									aria-label={t('actions.copyZoneIdToClipboard')}
								>
									<ContentCopyOutlined />
								</IconButton>
							</Tooltip>
						)}
						{actions.length === 1 && <ActionsCell rowData={params.row} actions={actions} />}
						{actions.length > 1 && <ActionsCellMenu rowData={params.row} actions={actions} stopPropagation={true} />}
						{params.row.zoneId && (
							<Tooltip arrow title={t('columns.viewDetails')}>
								<IconButton size='small' aria-label={t('columns.viewDetails')}>
									<KeyboardArrowRightIcon />
								</IconButton>
							</Tooltip>
						)}
					</>
				);
			},
		},
	];
};

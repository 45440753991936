import ZeroState from '@tricentis/aura/components/ZeroState.js';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import Button, { ButtonProps } from '@mui/material/Button';
import { Key } from 'react';
import Stack from '@mui/material/Stack';
import Webhooks from '../../../../assets/Webhooks.svg';
import { IllustrationWithBackground } from '../../../common/illustration-with-background';

type ButtonPropsWithKey = ButtonProps & { key: Key };

type WebhooksEmptyStateProps = {
	openModal: () => void;
};

const WebhooksEmptyState = ({ openModal }: WebhooksEmptyStateProps) => {
	const { t } = useTranslation('workspace', { keyPrefix: 'tabs.webhooks.zero' });

	const actions: ButtonPropsWithKey[] = [
		{
			key: 'zero-button',
			variant: 'contained',
			children: (
				<>
					<AddIcon sx={{ marginRight: 1 }} />
					{t('button')}
				</>
			),
			sx: { textTransform: 'none' },
			onClick: openModal,
		},
	];

	const title = t('title');

	return (
		<Stack justifyContent='center' height='100%'>
			<ZeroState
				illustration={<IllustrationWithBackground image={Webhooks} title={title} />}
				title={title}
				actions={actions.map(({ children, key, ...others }) => (
					<Button key={key} {...others}>
						{children}
					</Button>
				))}
				containerSx={{ backgroundColor: 'transparent', marginTop: '20px' }}
			>
				<Typography variant='body2'>{t('content')}</Typography>
			</ZeroState>
		</Stack>
	);
};

export { WebhooksEmptyState };

import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';
import { Login, LoginPanel } from '@neoload/ui';
import { CreateSessionRequest, CreateSessionResponse, CurrentUser, usePostV4SessionsMutation } from '@neoload/api';
import { HomeRoutes, callCompanion } from '@neoload/utils';
import { useAccountInitialization, useGetMe, useSetSnackbars, useUrlSearchParams } from '@neoload/hooks';

const isResponseWithError = (error: unknown): error is { data: unknown } =>
	!!error && typeof error === 'object' && 'data' in error;

const isCreateSessionResponse = (data: unknown): data is CreateSessionResponse =>
	!!data && typeof data === 'object' && 'accounts' in data;

const onLoginSuccess = (me: CurrentUser) => {
	const user = {
		id: `saas-${me.id}`,
		accountId: me.account.id,
		role: me.role,
	};
	const account = {
		id: me.account.id,
		name: me.account.name,
		version: 'SAAS',
		licenseEdition: undefined,
		licenseExpiration: undefined,
	};
	callCompanion('identify', user, account);
};

const LoginBox = () => {
	const [postSession, postSessionResult] = usePostV4SessionsMutation();
	const navigate = useNavigate();
	const [{ return: returnUrl }] = useUrlSearchParams('return');
	const [me] = useGetMe();
	const { removeAllSnackbars } = useSetSnackbars();

	const { isAccountInitialized, isAccountInitializing, isAccountInitializationTimeout, accountName } =
		useAccountInitialization({});

	useEffect(() => {
		if (me.isLoaded && (isAccountInitialized || isAccountInitializationTimeout)) {
			onLoginSuccess(me);
			removeAllSnackbars();
			if (returnUrl?.startsWith('/')) {
				navigate(decodeURIComponent(returnUrl));
			} else {
				navigate(HomeRoutes.base);
			}
		}
	}, [
		isAccountInitializationTimeout,
		isAccountInitialized,
		me,
		navigate,
		postSessionResult.isSuccess,
		returnUrl,
		removeAllSnackbars,
	]);

	const login = (createSessionRequest: CreateSessionRequest) => {
		postSession({ createSessionRequest }).catch((error) => {
			console.error(error);
		});
	};

	const { error } = postSessionResult;
	const accounts = isResponseWithError(error) && isCreateSessionResponse(error.data) ? (error.data.accounts ?? []) : [];
	const alert = error && accounts.length === 0;

	const initializingAccount = isAccountInitializing
		? {
				initializing: true as const,
				accountName,
			}
		: {
				initializing: false as const,
			};

	return <Login postSession={login} accounts={accounts} alert={alert} initializingAccount={initializingAccount} />;
};

const LoginLayout = () => {
	const theme = useTheme();
	const [noPanel, setNoPanel] = useState(false);
	const onFail = () => {
		setNoPanel(true);
	};

	return (
		<Stack
			sx={{
				height: '100vh',
				width: '100vw',
				alignItems: 'center',
				backgroundColor: theme.palette.background.paper,
			}}
			direction='row'
		>
			{!noPanel && <LoginPanel onFail={onFail} />}
			<Box
				id='login'
				sx={{
					flex: 1,
					display: 'flex',
					justifyContent: 'center',
					height: '100%',
				}}
			>
				<LoginBox />
			</Box>
		</Stack>
	);
};
export const visibleForTesting = { onLoginSuccess };
export { LoginLayout };

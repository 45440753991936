type PayloadVariableInfo = {
	name: string;
	availableForTestStart: boolean;
	availableForTestEnd: boolean;
};

export const PAYLOAD_VARIABLES: PayloadVariableInfo[] = [
	{ name: '$(test_result_name)', availableForTestEnd: true, availableForTestStart: true },
	{ name: '$(test_result_project)', availableForTestEnd: true, availableForTestStart: true },
	{ name: '$(test_result_scenario)', availableForTestEnd: true, availableForTestStart: true },
	{ name: '$(test_result_start_date)', availableForTestEnd: true, availableForTestStart: true },
	{ name: '$(test_result_duration)', availableForTestEnd: true, availableForTestStart: true },
	{ name: '$(test_result_status)', availableForTestEnd: true, availableForTestStart: false },
	{ name: '$(test_result_max_nb_vus)', availableForTestEnd: true, availableForTestStart: true },
	{ name: '$(test_result_id)', availableForTestEnd: true, availableForTestStart: true },
	{ name: '$(test_result_quality_status)', availableForTestEnd: true, availableForTestStart: false },
	{ name: '$(url_test_result_overview)', availableForTestEnd: true, availableForTestStart: false },
	{ name: '$(url_graph_overview)', availableForTestEnd: true, availableForTestStart: true },
	{ name: '$(test_result_author)', availableForTestEnd: true, availableForTestStart: true },
	{ name: '$(test_settings_id)', availableForTestEnd: true, availableForTestStart: true },
	{ name: '$(test_settings_name)', availableForTestEnd: true, availableForTestStart: true },
	{ name: '$(workspace_id)', availableForTestEnd: true, availableForTestStart: true },
	{ name: '$(workspace_name)', availableForTestEnd: true, availableForTestStart: true },
	{ name: '$(user_name)', availableForTestEnd: true, availableForTestStart: true },
	{ name: '$(account_name)', availableForTestEnd: true, availableForTestStart: true },
];

export const DOCUMENTATION_LINK =
	'https://www.neotys.com/redirect/redirect.php?target=docpage&front-version=2.0&product=neoload+web&lngdoc=en&reference=settings.workspaces.webhook';

const dashboard = {
	title: 'Dashboards',
	createButton: 'Create dashboard',
	deleteButton: 'Delete',

	emptyState: {
		title: 'Create your first dashboard',
		content:
			'A dashboard is a tool to visualize and gain insights from your test results data. Create a dashboard to start analyzing your test results.',
	},
	create: {
		success_one: 'Dashboard {{name}} has been created',
		error_one: 'One dashboard has not been created : {{cause}}',
	},
	update: {
		success_one: 'Dashboard {{name}} has been updated',
		success_other: '{{count}} dashboards have been updated',

		error_one: 'One dashboard has not been updated : {{cause}}',
		error_other: '{{count}} dashboards have not been updated',
	},
	delete: {
		success_one: 'Dashboard {{name}} deleted',
		success_other: '{{count}} dashboards deleted',

		error_one: 'One dashboard has not been deleted : {{cause}}',
		error_other: '{{count}} dashboards have not been deleted',
	},
	duplicate: {
		moreThanOneSelected: "Can't duplicate more than one dashboard",
	},
	share: {
		moreThanOneSelected: "Can't share more than one dashboard",
	},
	createDialog: {
		title: 'Create dashboard',
		okButton: 'Create',
		label: {
			placeholder: 'Dashboard name',
			label: 'Dashboard name',
		},
	},
	shareDialog: {
		title: 'Share {{type}}',
		canAccess: 'Who can access',
		owner: 'Owner',
		you: '(You)',
		shareWorkspace: 'Anyone in {{workspace}}',
		shareInWorkspace: 'Share in workspace',
		anyone: 'Anyone with the public link',
		publicLink: 'Generate public link',
		canEdit: 'Can edit',
		canView: 'Can view',
		copyLink: 'Copy link',
		exportPdf: 'Export to PDF',
	},
	validation: {
		name: {
			empty: 'Dashboard name is required',
			tooLong: 'Dashboard name must not exceed 80 characters',
			alreadyExists: 'A dashboard already exists with the same name',
		},
	},
	description:
		'The Dashboards view is designed to help you create, manage, display and analyze as many graphs and boards as required for selected Test Results. Each Dashboard can contain one or several panels where you can trace curves and tables from your test metrics and write text as comments.',

	sharedStateTrue: 'Shared',
	sharedStateFalse: 'Private',
	columns: {
		owner: {
			title: 'Owner',
		},
		name: {
			title: 'Name',
		},
		type: {
			title: 'Type',
		},
		shared: {
			title: 'Sharing',
		},
		publicLink: {
			title: 'Public link',
		},
		lastUpdate: {
			title: 'Last modified',
		},
		creation: {
			title: 'Created',
		},
	},
	dashboardType: {
		report: 'Report',
		dashboard: 'Dashboard',
	},
	dashboardShared: {
		shared: 'Shared',
		private: 'Private',
	},
	me: 'Me',

	deleteSingleDashboardDialog: {
		title: 'Delete dashboard?',
		label:
			"You can't undo this action or get back dashboards once they're deleted. If this dashboard is shared, deleting it will also remove access for all other users.",
	},
	deleteMultipleDashboardDialog: {
		title: 'Delete {{dashboardNumber}} dashboards?',
		label:
			"You can't undo this action or get back dashboards once they're deleted. If a dashboard is shared, deleting it will also remove access for all other users.",
	},
	header: {
		name: 'Dashboard name',
		more: {
			button: 'More actions',
		},
	},
	zero: {
		title: 'Add the first tile',
		content: 'Add a tile to trace curves or tables from your test metrics, or write text as comments.',
		button: 'Add a tile',
	},
	messages: {
		updateNameError: 'Failed to rename dashboard',
		updateNameSuccess: 'Dashboard renamed',
		updateShareStatusError: 'Failed to change dashboard share status',
		updateShareStatusSuccess: 'Dashboard is shared',
		updateUnshareStatusSuccess: 'Dashboard is no longer shared',
		updateManyShareStatusError: 'Failed to change share status for some dashboards',
		updateManyShareStatusSuccess: 'Dashboard sharing status has been updated',
		duplicateError: 'Failed to duplicate dashboard',
		duplicateSuccess: 'Dashboard duplicated',
		deleteError: 'Failed to delete dashboard',
		deleteSuccess: 'Dashboard deleted',
		generatePublicLink: 'Public link generated',
		generatePublicLinkError: 'Failed to generate public link',
		deletePublicLink: 'Public link deleted',
		deletePublicLinkError: 'Failed to delete public link',
		copyPublicLink: 'Public link copied to clipboard',
		copyPublicLinkError: 'Failed to copy public link to clipboard',
		exportReportPdf: 'Report exported',
		exportDashboardPdf: 'Dashboard exported',
		exportPdfError: 'Failed to export',
	},
	tile: {
		empty: 'Empty',
		error: 'Unable to load this tile',
		unloaded: 'This tile has not been loaded yet',
		create: {
			button: 'Add tile',
			headerTitle: 'Tile creation',
			success: 'Tile created',
			error: 'Failed to create the tile',
			types: {
				widget: 'Widget',
				text: 'Text',
				series: 'Series',
				valueComparison: 'Table',
			},
			description: {
				widget: 'Add a predefined single result set',
				text: 'Make notes in a rich text editor',
				series: 'Show result metrics in a line graph',
				valueComparison: 'Compare result metrics in a table',
			},
		},
		delete: {
			button: 'Remove',
			confirm: {
				title: 'Remove tile?',
				content: "This action only affects this tile on your dashboard and can't be undone.",
			},
			success: 'Tile removed',
			error: 'Failed to remove the tile',
		},
		edit: {
			button: 'Edit',
		},
		clear: {
			button: 'Remove all tiles',
			confirm: {
				title: 'Remove all tiles?',
				content: 'This action will clear your dashboard of tiles. This action is not reversible.',
			},
			success: 'All tiles successfully removed from this dashboard',
			error: 'Failed to remove all tiles from this dashboard',
		},
		edition: {
			headerTitle: 'Tile edition',
			accessibleTitle: 'Tile edition form',
			dialog: {
				name: 'Tile edition dialog',
			},
			sections: {
				details: 'Tile details',
				data: 'Tile data',
				text: 'Text',
				series: 'Data series',
				comparison: 'Data',
				comparisonSettings: 'Table settings',
			},
			fields: {
				title: {
					name: 'Title',
					error: {
						required: 'The title is required',
						maxLength: 'The title can not exceed 80 characters',
					},
				},
				text: {
					placeholder: 'Add your text here',
				},
				presets: {
					title: 'Preset analysis',
					groups: {
						testResultInfo: 'Test result info',
						errors: 'Errors',
						slaAlerts: 'SLA alerts',
						transactions: 'Transactions',
						pages: 'Pages',
						requests: 'Requests',
					},
					types: {
						summary: 'Summary',
						topByCode: 'Top by code',
						topByLg: 'Top by LG',
						topByElement: 'Top by element',
						top: 'Top',
						keyMetrics: 'Key metrics',
						topAverageDuration: 'Top average duration',
						topMaximumDuration: 'Top maximum duration',
					},
				},
				series: {
					visibility: {
						show: 'Show',
						hide: 'Hide',
					},
					delete: 'Remove',
					errors: {
						mismatch: 'There is a mismatch in the series list form',
					},
					color: 'Color picker',
				},
			},
			addDataSeries: 'Add data series',
			addSeries: {
				availableDataSeries: 'Available data series',
				accessibleTitle: 'Tile new series edition form',
				msgNoUserPathElementSelected: 'Select elements to see statistics here',
				msgNoStatsFound: 'No statistics found',
				userPathTree: {
					label: 'User path element tree',
				},
				monitorTree: {
					label: 'Monitor element tree',
				},
				userPathPanel: 'User path panel',
				monitorsPanel: 'Monitors panel',
				errorLoadingData: 'Error loading data',
				empty: 'Nothing to display',
				userPathTabLabel: 'User path',
				monitorsTabLabel: 'Monitors',
				error: {
					duplicate: 'Selected series has already been added',
					notCompatible: 'Selected series is not compatible with existing ones',
				},
			},
			success: 'Tile updated',
			error: 'Failed to edit the tile',
		},
	},
	widget: {
		duration: {
			userPath: 'User Path',
			parent: 'Parent',
			element: 'Element',
			maximumDuration: 'Max. Duration',
			averageDuration: 'Avg. Duration',
			maximumDurationCsv: 'Max. Duration (s)',
			averageDurationCsv: 'Avg. Duration (s)',
		},
		error: {
			global: 'The following error(s) occurred while trying to load widget in tile with id {{tileId}}',
			dataNotFound: 'No data returned for widget with id {{widgetId}}',
			fetchFailure: 'Unable to fetch widget for tile {{tileId}}',
		},
	},
	series: {
		tooltip: {
			result: 'Result: {{resultName}}',
		},
		error: {
			global: 'The following error(s) occurred while trying to load series in tile with id {{tileId}}',
			dataNotFound: 'No data returned for series with id {{seriesId}}',
			fetchFailure: 'Unable to fetch series for tile {{tileId}}',
			unsupportedFilterType: 'The filter type {{filterType}} is not supported',
		},
		units: {
			/* eslint-disable @typescript-eslint/naming-convention */
			SECONDS: 's',
			ELEMENTS: 'e',
			ELEMENTS_PER_SECOND: 'e/s',
			ERRORS: 'err',
			ERRORS_PER_SECOND: 'err/s',
			PERCENTAGE: '%',
			BITS_PER_SECOND: 'bits/s',
			EMPTY: '',
			USER_LOAD: '',
			/* eslint-enable @typescript-eslint/naming-convention */
		},
		messages: {
			patch: {
				success: 'Series successfully updated',
				error: 'Failed to update the series',
			},
		},
	},
	errors: {
		errorCode: 'Error Code',
		errorCount: 'Error Count',
		loadGenerator: 'Load Generator',
		userPath: 'User Path',
		element: 'Element',
		parent: 'Parent',
	},
	sla: {
		threshold: {
			/* eslint-disable @typescript-eslint/naming-convention */
			AVERAGE_REQUEST_RESPONSE_TIME: 'Average request response time (s)',
			AVERAGE_PAGE_RESPONSE_TIME: 'Average page response time (s)',
			AVERAGE_CONTAINER_RESPONSE_TIME: 'Average container response time (s)',
			PERCENTILE_CONTAINER_RESPONSE_TIME: 'Percentile container response time (s)',
			AVERAGE_HITS_PER_SECOND: 'Average hits per second',
			AVERAGE_THROUGHPUT_PER_SECOND: 'Average throughput per second',
			TOTAL_ERRORS: 'Total errors',
			TOTAL_HITS: 'Total hits',
			TOTAL_THROUGHPUT: 'Total Throughput',
			AVERAGE_RESPONSE_TIME: 'Average response time (s)',
			ERRORS_PER_SECOND: 'Errors per second',
			ERROR_PERCENTILE: 'Error percentile',
			/* eslint-enable @typescript-eslint/naming-convention */
		},
		columns: {
			name: {
				userPath: 'User path',
				status: 'Status',
				element: 'Element',
				parent: 'Parent',
				name: 'SLA name',
				threshold: 'Threshold',
				duration: 'Duration %',
			},
		},
	},
	statistics: {
		label: 'Statistics',
		/* eslint-disable @typescript-eslint/naming-convention */
		AVERAGE_DURATION: 'Average duration',
		MINIMUM_DURATION: 'Minimum duration',
		MAXIMUM_DURATION: 'Maximum duration',
		THROUGHPUT: 'Throughput',
		ELEMENTS_PER_SECOND: 'Elements/s',
		ERROR_COUNT: 'Errors',
		ERRORS_PER_SECOND: 'Errors/s',
		ERROR_RATE: 'Errors rate',
		AVERAGE_TTFB: 'Average TTFB',
		MINIMUM_TTFB: 'Minimum TTFB',
		MAXIMUM_TTFB: 'Maximum TTFB',
		DURATION_PERCENTILES: 'Percentile duration',
		MIN: 'Minimum',
		MAX: 'Maximum',
		AVG: 'Average',
		/* eslint-enable @typescript-eslint/naming-convention */
	},
	valuesComparison: {
		columns: {
			metricHeader: 'Metric',
			sectionTitle: 'Columns',
			sectionDescription: 'Select the results or intervals to compare statistics.',
			add: 'Add column',
			displayName: 'Display name',
		},
		rows: {
			sectionTitle: 'Rows',
			add: 'Add row',
			sectionDescription: 'Select the statistics to compare results or intervals.',
		},
		zeroStateContentFirstLine: 'Compare statistics across results or within a single result using filters.',
		zeroStateContentSecondLine:
			'1. Select metrics from a reference result.\n2. Add columns to view individual or filtered result values.',
		result: 'Result:',
		element: 'Element:',
		monitor: 'Monitor',
		settings: {
			showDifference: 'Show values difference',
			differenceHelperText: 'All columns will be compared to the first one',
			differenceLabel: 'Show as',
			absolute: 'Absolute value',
			absoluteIndication: '-X.YY ↓',
			percentage: 'Percentage',
			percentageIndication: '+X.YY% ↑',
			both: 'Percentage (Absolute value)',
			bothIndication: '-X.YY% (-X.YY) ↓',
		},
	},
};

export { dashboard };

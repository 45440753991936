import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SectionTitle from '@tricentis/aura/components/SectionTitle.js';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ShareOutlined from '@mui/icons-material/ShareOutlined';
import { useState } from 'react';
import { getTranslationKeyToValidateDashboardName } from './dashboard-common';
import { DashboardHeaderMoreMenu } from './dashboard-header-more-menu';
import { TileCreationPopover } from './tiles/creation/tile-creation-popover';
import { DashboardShareDialog } from './share/dashboard-share-dialog';
import { useGetMe } from '@neoload/hooks';
import { Dashboard } from '@neoload/api';

export type DashboardHeaderProps = {
	dashboard: Dashboard;
	deleteTilesEnabled?: boolean;
	onDuplicateDashboard: () => Promise<void>;
	onEditDashboardName: (name: string) => void;
	onDeleteDashboard: () => Promise<void>;
	onClearTiles: () => Promise<void>;
};
const DashboardHeader = ({
	dashboard,
	deleteTilesEnabled = true,
	onEditDashboardName,
	onDuplicateDashboard,
	onDeleteDashboard,
	onClearTiles,
}: DashboardHeaderProps) => {
	const { t } = useTranslation(['dashboard']);
	const theme = useTheme();
	const styleFlexCenter = { display: 'flex', alignItems: 'center' };
	const [currentUser] = useGetMe();
	const isGuestAndNotOwner = dashboard.ownerId !== currentUser.id && currentUser.role === 'GUEST';
	const [isShareDialogOpen, setIsShareDialogOpen] = useState<boolean>(false);

	const baseHeaderProps = { deleteTilesEnabled, onDuplicateDashboard, onDeleteDashboard };
	const headerProps = { ...baseHeaderProps, onClearTiles };
	return (
		<Grid item xs='auto' sx={styleFlexCenter}>
			<Box sx={{ maxWidth: '1000px' }}>
				<SectionTitle
					title={dashboard.name}
					label={t('header.name')}
					editable={!isGuestAndNotOwner}
					slotProps={{
						input: {
							onApply: (updatedData: Record<string, string>) => {
								const updatedName = updatedData.source;
								if (updatedName === dashboard.name) {
									return;
								}
								onEditDashboardName(updatedName);
							},
							validationRules: [
								(updatedName: string) => {
									const translationKey = getTranslationKeyToValidateDashboardName(updatedName);
									return translationKey ? t(translationKey) : false;
								},
							],
						},
					}}
				/>
			</Box>
			<Grid container justifyContent='flex-end' columnSpacing={theme.spacing(1)} alignItems='center'>
				<Grid item>
					<Button
						variant='outlined'
						size='small'
						onClick={() => setIsShareDialogOpen(true)}
						startIcon={<ShareOutlined />}
					>
						{t('common:share')}
					</Button>
					<DashboardShareDialog
						isOpen={isShareDialogOpen}
						onClose={() => setIsShareDialogOpen(false)}
						dashboard={dashboard}
					/>
				</Grid>
				{!isGuestAndNotOwner && (
					<Grid item>
						<TileCreationPopover />
					</Grid>
				)}
				<Grid item>
					<DashboardHeaderMoreMenu {...headerProps} isActionsVisible={!isGuestAndNotOwner} />
				</Grid>
			</Grid>
		</Grid>
	);
};
export { DashboardHeader };

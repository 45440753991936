import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import MenuList from '@mui/material/MenuList';
import { useFormContext } from 'react-hook-form';
import { TestExecutionReservationMenuItem } from './test-execution-reservation-menu-item';
import { TestExecutionForm } from '../types';
import { Reservation } from '@neoload/api';

export type TestExecutionReservationMenuProps = {
	anchorEl?: HTMLElement | null;
	open: boolean;
	closeReservationMenu: () => void;
	reservations?: Reservation[];
};

const TestExecutionReservationMenu = ({
	anchorEl,
	closeReservationMenu,
	open,
	reservations,
}: TestExecutionReservationMenuProps) => {
	const { t } = useTranslation(['test']);
	const { setValue, getValues } = useFormContext<TestExecutionForm>();

	const existingReservation = reservations !== undefined && reservations.length > 0;

	function handleAutoReservation() {
		setValue('resources.reservationId', undefined);
		setValue('reservation.selectedReservation', undefined);
		setValue('errors', []);
		setValue('resources.webVu.needed', getValues('resources.webVu.initial'));
		setValue('resources.sapVu.needed', getValues('resources.sapVu.initial'));
		setValue('resources.duration', getValues('resources.maxDuration'));
		closeReservationMenu();
	}

	return (
		<Menu
			id='basic-menu'
			anchorEl={anchorEl}
			open={open}
			onClose={() => closeReservationMenu()}
			anchorReference='anchorEl'
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			data-testid='reservation-menu'
		>
			<MenuItem>
				<ListItemText
					primary={t('reservations.autoReservation')}
					secondary={t('reservations.autoReservationText')}
					secondaryTypographyProps={{ style: { whiteSpace: 'normal', width: '300px' } }}
					onClick={handleAutoReservation}
				/>
			</MenuItem>
			<Divider />
			{existingReservation && (
				<MenuItem disabled>
					<ListItemText primary={t('reservations.currentReservations')} />
				</MenuItem>
			)}
			<MenuList>
				{reservations?.map((reservation) => (
					<TestExecutionReservationMenuItem
						reservation={reservation}
						key={reservation.id}
						closeReservationMenu={closeReservationMenu}
					/>
				))}
			</MenuList>
		</Menu>
	);
};

export { TestExecutionReservationMenu };

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useDebounce } from 'react-use';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PopulationAccordion } from './population-accordion';
import { AddPopulationButton } from './add-population-button';
import { customScenarioFormToApi, translateApiError } from './custom-scenarios-helpers';
import { ConfigurationFormData } from './../../types.d';
import { ConfigurationError } from './../../configuration-helpers';
import { PopulationAccordionUnsupported } from './population-accordion-unsupported';
import {
	useGetCustomPopulationNames,
	useGetCustomPopulations,
	useGetCustomPopulationUserChanged,
	useGetUnsupportedCustomPopulations,
	useSetSnackbars,
} from '@neoload/hooks';
import {
	ApiError,
	isApiError,
	isInvalidPropertyError,
	usePutV4TestsByTestIdScenariosAndScenarioNameMutation,
} from '@neoload/api';

const CustomScenarios = () => {
	const { t } = useTranslation(['test']);
	const scenarioPopulationsNames = useGetCustomPopulationNames();
	const populations = useGetCustomPopulations();
	const unsupportedPopulations = useGetUnsupportedCustomPopulations();
	const isCustomPopulationUserChanged = useGetCustomPopulationUserChanged();
	const { showInfo, showError } = useSetSnackbars();
	const [patchScenario] = usePutV4TestsByTestIdScenariosAndScenarioNameMutation();
	const { getValues } = useFormContext<ConfigurationFormData>();

	const handleErrorMessage = (error: ApiError) => {
		const testExecutionErrors: ConfigurationError[] = [];
		if (isInvalidPropertyError(error) && error.errors !== undefined) {
			for (const apiError of error.errors) {
				testExecutionErrors.push(translateApiError(apiError));
			}
		}

		if (testExecutionErrors.length === 0) {
			testExecutionErrors.push({
				sentence: error.description,
			});
		}

		return testExecutionErrors
			.map((error) => {
				if (error.sentenceKey) {
					return t(error.sentenceKey);
				}

				return error.sentence;
			})
			.join(', ');
	};

	useDebounce(
		async () => {
			if (isCustomPopulationUserChanged) {
				await patchScenario({
					testId: getValues('test.id'),
					scenarioName: 'custom',
					scenarioAsCode: { populations: [...customScenarioFormToApi(populations), ...unsupportedPopulations] },
				})
					.unwrap()
					.then(() => {
						showInfo({
							text: t('configuration.snackbar.configSuccess'),
							id: 'config_success',
							autoHideDuration: 2000,
						});
					})
					.catch((error) => {
						if ('data' in error && isApiError(error.data)) {
							showError({ id: 'config_success', text: handleErrorMessage(error.data) });
						} else {
							showError({ id: 'config_success', text: t('common:errors.otherError.content') });
						}
					});
			}
		},
		500,
		[populations],
	);

	const availablePopulationNames = [
		...scenarioPopulationsNames,
		...(unsupportedPopulations && unsupportedPopulations.map(({ name }) => name)),
	];

	return (
		<Box sx={{ width: '100%' }}>
			<Stack gap={1} useFlexGap sx={{ marginBottom: 2 }}>
				{scenarioPopulationsNames.map((name, index) => (
					<PopulationAccordion name={name} index={index} key={name} />
				))}
				{unsupportedPopulations?.map((population) => (
					<PopulationAccordionUnsupported population={population} key={population.name} />
				))}
			</Stack>
			<AddPopulationButton scenarioPopulationsNames={availablePopulationNames} />
		</Box>
	);
};

export { CustomScenarios };

import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { ComparisonRowListAccordion } from './row/comparison-row-list-accordion';
import { ComparisonColumnList } from './column/comparison-column-list';
import { ComparisonDifferenceSelector } from './table-settings/comparison-difference-selector';

export type ComparisonMainFormProps = {
	onAddRow: () => void;
	resultId?: string;
	onEditRow: (index: number) => void;
};
export const ComparisonMainForm = ({ onAddRow, resultId, onEditRow }: ComparisonMainFormProps) => {
	const { t } = useTranslation(['dashboard']);
	return (
		<>
			<Accordion disableGutters defaultExpanded={true} elevation={0}>
				<AccordionSummary expandIcon={<GridExpandMoreIcon />}>{t('tile.edition.sections.comparison')}</AccordionSummary>
				<AccordionDetails>
					<Stack sx={{ gap: 1 }}>
						<ComparisonRowListAccordion resultId={resultId ?? ''} onEditRow={onEditRow} onAddRow={onAddRow} />
						<ComparisonColumnList />
					</Stack>
				</AccordionDetails>
			</Accordion>
			<Divider />
			<Accordion disableGutters defaultExpanded={true} elevation={0}>
				<AccordionSummary expandIcon={<GridExpandMoreIcon />}>
					{t('tile.edition.sections.comparisonSettings')}
				</AccordionSummary>
				<AccordionDetails>
					<ComparisonDifferenceSelector />
				</AccordionDetails>
			</Accordion>
		</>
	);
};

import { TFunction } from 'i18next';
import { TableCellProps } from '@mui/material/TableCell';
import { i18n } from '../../../i18n';
import { Column, SortDirection } from '../../common/table-grid';

const regex = /\$([^ ']*)/gm;
const frontV1URL = import.meta.env.VITE_FRONT_V1_URL;

export function getTranslationKeyToValidateDashboardName(name: string) {
	if (!name || name.trim() === '') {
		return 'validation.name.empty';
	}
	if (name.length > 80) {
		return 'validation.name.tooLong';
	}
	return false;
}

export function matchAndReplaceWithI18N(value: string): string {
	if (value && value.length > 0) {
		const regExpMatchArray = value.match(regex) ?? [];
		for (const matchedElement of regExpMatchArray) {
			const valueToTranslate = matchedElement.replaceAll(regex, '$1');
			const replaceValue = i18n.t(valueToTranslate, { ns: 'dashboardLegacyKeys', defaultValue: matchedElement });
			if (matchedElement !== replaceValue) {
				value = value.replace(matchedElement, replaceValue);
			}
		}
	}
	return value;
}

export type ColumnDefinition = {
	name: string;
	i18nKey: string;
	align?: TableCellProps['align'];
	maxWidth?: number;
};

export const columnDefinitionToColumn = (columnDefinition: ColumnDefinition, t: TFunction): Column => ({
	label: t(columnDefinition.i18nKey),
	align: columnDefinition.align,
	maxWidth: columnDefinition.maxWidth,
});

export const columnDefinitionsToColumns = (columnDefinitions: ColumnDefinition[], t: TFunction): Column[] =>
	columnDefinitions.map((columnDefinition) => columnDefinitionToColumn(columnDefinition, t));

export function sortToSortColumnIndex(columnDefinitions: ColumnDefinition[], sort?: string | null): number | undefined {
	if (sort === null || sort === undefined) {
		return undefined;
	}
	const columnName = sort.slice(1);
	const columnDefinition = columnDefinitions.find((value) => value.name === columnName);
	if (columnDefinition === undefined) {
		return undefined;
	}
	return columnDefinitions.indexOf(columnDefinition);
}

export function sortToSortDirection(sort?: string | null): SortDirection | undefined {
	if (sort === null || sort === undefined) {
		return undefined;
	}
	if (sort.startsWith('-')) {
		return 'desc';
	}
	if (sort.startsWith('+')) {
		return 'asc';
	}
	return undefined;
}

export const buildDashboardPublicSharedLink = (publicToken: string) =>
	`${frontV1URL}/#!dashboard-public/share/${publicToken}/en`;

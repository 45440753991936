import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { AddPopulationModal } from './add-population-modal';
import { ConfigurationFormData } from './../../types.d';
import { CreateResourceButton } from '../../../../../common/button/create-resource-button';
import { Population } from '@neoload/api';
import { useCustomScenario } from '@neoload/hooks';

type AddPopulationButtonProps = {
	scenarioPopulationsNames: string[];
};
const AddPopulationButton = memo(
	({ scenarioPopulationsNames }: AddPopulationButtonProps) => {
		const { t } = useTranslation(['test']);
		const { addDefaultCustomPopulationByNames } = useCustomScenario();
		const projectPopulations = useWatch<ConfigurationFormData, 'project.populations'>({
			name: 'project.populations',
		});

		const [addPopulationModalOpen, setAddPopulationModalOpen] = useState<boolean>(false);
		const closeModal = useCallback(() => setAddPopulationModalOpen(false), [setAddPopulationModalOpen]);

		const getPopulationsToAdd = () =>
			projectPopulations?.filter((population) => !scenarioPopulationsNames.includes(population.name)) || [];

		const cannotAddPopulation = getPopulationsToAdd().length === 0;

		const onSave = (populations: Population[]) => {
			addDefaultCustomPopulationByNames(
				populations.map(({ name }) => name),
				true,
			);
			closeModal();
		};

		return (
			<>
				<Tooltip title={cannotAddPopulation ? t('configuration.scenarios.custom.noMorePopulations') : ''} arrow>
					<Box sx={{ display: 'inline-block' }}>
						<CreateResourceButton
							onClick={() => setAddPopulationModalOpen(true)}
							variant='outlined'
							data-trackingid='custom-scenario-add-population'
							disabled={cannotAddPopulation}
						>
							{t('configuration.scenarios.custom.addPopulationsButton')}
						</CreateResourceButton>
					</Box>
				</Tooltip>
				{addPopulationModalOpen && (
					<AddPopulationModal open={true} onClose={closeModal} onSave={onSave} populations={getPopulationsToAdd()} />
				)}
			</>
		);
	},
	(prevProps, nextProps) =>
		prevProps.scenarioPopulationsNames.toString() === nextProps.scenarioPopulationsNames.toString(),
);

export { AddPopulationButton };

import { Theme } from '@mui/material/styles';
import {
	axisLabelStyle,
	buildTitleText,
	getCommonChartOptions,
	tooltipIconCircle,
	tooltipIconSquare,
} from '../../../common/chart/chart-config';

const SeriesIds = {
	error: 'ErrorSeriesId',
	userLoad: 'UserLoadSeriesId',
	requestDuration: 'ReqDurationSeriesId',
	requestPerSeconds: 'ReqPerSecSeriesId',
} as const;

const resultsChartOptions = (theme: Theme): Highcharts.Options => {
	const commonChartOptions = getCommonChartOptions(theme, true);
	return {
		...commonChartOptions,
		chart: {
			...commonChartOptions.chart,
			height: '13.75%',
		},
		xAxis: {
			...commonChartOptions.xAxis,
			tickPixelInterval: 250,
			lineColor: theme.palette.text.primary,
			/*This empty array is important. Otherwise, when adding plotbands via highchart's api, plotbands are indeed visible,
			 * but plotOptions will not be added the xAxis's options.
			 * This leads to an impossibility to ever get / remove the plotband from the graph via the api
			 * An issue was opened, see https://github.com/highcharts/highcharts-react/issues/462 */
			plotBands: [],
		},
		tooltip: {
			...commonChartOptions.tooltip,
			positioner: function (labelWidth, _labelHeight, point) {
				const offset = labelWidth / 15;
				const tooltipXAfter = point.plotX + 35 + offset;
				const tooltipXBefore = point.plotX - labelWidth + 15 + offset;
				return {
					x: tooltipXAfter + labelWidth < this.chart.chartWidth ? tooltipXAfter : tooltipXBefore,
					y: this.chart.chartHeight / 3,
				};
			},
		},
		responsive: {
			rules: [
				{
					condition: {
						maxHeight: 245,
					},
					chartOptions: {
						chart: {
							height: 240,
						},
					},
				},
			],
		},
	};
};

const commonSerieOptions: Highcharts.SeriesOptionsType = {
	type: 'area',
	lineWidth: 2,
	fillOpacity: 0,
	states: {
		inactive: { opacity: 0.6 },
		hover: { lineWidth: 2 },
	},
};

const getUsersChartDefaultOptions = (
	errorsSerieName: string,
	userLoadSerieName: string,
	theme: Theme,
): Highcharts.Options => ({
	...resultsChartOptions(theme),
	title: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		useHTML: true,
		text: buildTitleText(errorsSerieName, userLoadSerieName, theme),
	},
	yAxis: [
		{
			// Left yAxis
			title: { text: undefined },
			labels: axisLabelStyle(theme, true),
			allowDecimals: false,
			gridLineColor: theme.palette.divider,
			gridLineDashStyle: 'Dash',
		},
		{
			// Right yAxis
			title: { text: undefined },
			labels: axisLabelStyle(theme, true),
			opposite: true,
			allowDecimals: false,
			gridLineColor: theme.palette.divider,
			gridLineDashStyle: 'Dash',
		},
		{
			// Intervals yAxis
			title: { text: undefined },
			min: 0,
			max: 0,
			categories: ['intervals'],
			visible: false,
		},
	],
	series: [
		{
			...commonSerieOptions,
			id: SeriesIds.error,
			name: errorsSerieName,
			color: theme.palette.error.main,
			yAxis: 0,
			//workaround for highcharts issue, setting high threshold to not see points, if using enabled false points appear on graph
			//https://github.com/highcharts/highcharts-react/issues/507
			marker: { symbol: 'square', enabledThreshold: 1000 },
			tooltip: {
				pointFormat: `${tooltipIconSquare} {series.name}: <b>{point.y}</b><br/>`,
			},
			data: [],
		},
		{
			...commonSerieOptions,
			id: SeriesIds.userLoad,
			name: userLoadSerieName,
			color: theme.palette.info.main,
			step: 'left',
			yAxis: 1,
			marker: { symbol: 'circle', enabledThreshold: 1000 },
			tooltip: {
				pointFormat: `${tooltipIconCircle} {series.name}: <b>{point.y}</b><br/>`,
			},
			data: [],
		},
	],
});

const getRequestsChartDefaultOptions = (
	requestsDurationSerieName: string,
	requestsPerSecSerieName: string,
	theme: Theme,
): Highcharts.Options => ({
	...resultsChartOptions(theme),
	title: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		useHTML: true,
		text: buildTitleText(requestsDurationSerieName, requestsPerSecSerieName, theme),
	},
	yAxis: [
		{
			// Left yAxis
			title: { text: undefined },
			labels: axisLabelStyle(theme, true),
			gridLineColor: theme.palette.divider,
			gridLineDashStyle: 'Dash',
		},
		{
			// Right yAxis
			title: { text: undefined },
			labels: axisLabelStyle(theme, true),
			opposite: true,
			gridLineColor: theme.palette.divider,
			gridLineDashStyle: 'Dash',
		},
		{
			// Intervals yAxis
			title: { text: undefined },
			min: 0,
			max: 0,
			categories: ['intervals'],
			visible: false,
		},
	],
	series: [
		{
			...commonSerieOptions,
			id: SeriesIds.requestDuration,
			name: requestsDurationSerieName,
			color: theme.palette.warning.main,
			yAxis: 0,
			marker: { symbol: 'square', enabledThreshold: 1000 },
			tooltip: {
				pointFormat: `${tooltipIconSquare} {series.name}: <b>{point.y:.3f} s</b><br/>`,
			},
			data: [],
		},
		{
			...commonSerieOptions,
			id: SeriesIds.requestPerSeconds,
			name: requestsPerSecSerieName,
			color: theme.palette.success.main,
			yAxis: 1,
			marker: { symbol: 'circle', enabledThreshold: 1000 },
			tooltip: {
				pointFormat: `${tooltipIconCircle} {series.name}: <b>{point.y:.1f}</b><br/>`,
			},
			data: [],
		},
	],
});

export { getUsersChartDefaultOptions, SeriesIds, getRequestsChartDefaultOptions };

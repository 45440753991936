import Dialog from '@mui/material/Dialog';
import { TestExecutionModalFormProvider } from './modal/test-execution-modal-form-provider';

type TestExecutionModalFormProps = {
	open: boolean;
	close: () => void;
	testId: string;
};

const ModalTestExecution = ({ open, close, testId }: TestExecutionModalFormProps) => {
	if (!open) {
		return null;
	}

	return (
		<Dialog open={open} onClose={() => close()} maxWidth={false}>
			<TestExecutionModalFormProvider close={close} testId={testId} />
		</Dialog>
	);
};

export { ModalTestExecution };

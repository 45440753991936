import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { TestExecutionForm } from '../types.d';
import { fieldsToTestExecutionInput } from '../test-execution-helpers';
import { usePostV4TestExecutionsMutation } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';
import { CommonRoutes } from '@neoload/utils';

type TestExecutionModalActionsProps = {
	close: () => void;
};

const TestExecutionModalActions = ({ close }: TestExecutionModalActionsProps) => {
	const { t } = useTranslation(['test']);
	const [loading, setLoading] = useState(false);
	const { showError } = useSetSnackbars();
	const navigate = useNavigate();

	const [testExecution] = usePostV4TestExecutionsMutation();

	const confirmable = useWatch<TestExecutionForm, 'confirmable'>({ name: 'confirmable' });
	const test = useWatch<TestExecutionForm, 'test'>({ name: 'test' });
	const resources = useWatch<TestExecutionForm, 'resources'>({ name: 'resources' });

	const executeAndClose = async () => {
		if (!loading) {
			setLoading(true);
			try {
				if (test?.id) {
					const d = await testExecution({
						dryRun: false,
						testExecutionInput: fieldsToTestExecutionInput(test, resources),
					}).unwrap();
					void navigate(CommonRoutes.results.logs(d.id), { state: { fromTestExecution: true } });
				} else {
					showError({
						text: t('common:errors.otherError.content'),
						id: 'error-run-test',
					});
					close();
				}
				close();
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		}
	};

	return (
		<DialogActions>
			<Button disabled={loading} color='info' onClick={() => close()} data-trackingid='test-execution-button-cancel'>
				{t('common:cancel')}
			</Button>
			<Button
				variant='contained'
				color='info'
				disabled={loading || !confirmable}
				onClick={() => executeAndClose()}
				startIcon={loading ? <CircularProgress size={24.5} color='inherit' /> : null}
				data-trackingid='test-execution-button-confirm'
			>
				{t('testExecution.run')}
			</Button>
		</DialogActions>
	);
};

export { TestExecutionModalActions };

import Tooltip from '@tricentis/aura/components/Tooltip.js';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconCalendarOutlined from '@tricentis/aura/components/IconCalendarOutlined.js';
import ListItemText from '@mui/material/ListItemText';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlayCircleOutlined from '@mui/icons-material/PlayCircleOutlined';

export type RunAndScheduleButtonProps = {
	setOpenTestExecutionModal: Dispatch<SetStateAction<boolean>>;
	setOpenScheduleTestModal: Dispatch<SetStateAction<boolean>>;
	disabled: boolean;
};

const RunAndScheduleButton = ({
	setOpenTestExecutionModal,
	setOpenScheduleTestModal,
	disabled,
}: RunAndScheduleButtonProps) => {
	const { t } = useTranslation(['test']);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const menuOpen = Boolean(anchorEl);

	const scheduleTest = () => {
		setOpenScheduleTestModal(true);
		setAnchorEl(null);
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Tooltip arrow title={disabled ? t('grid.run.notConfigured') : ''}>
				<ButtonGroup variant='contained'>
					<Button
						sx={{ height: '100%' }}
						variant='contained'
						size='small'
						onClick={() => setOpenTestExecutionModal(true)}
						disabled={disabled}
						startIcon={<PlayCircleOutlined />}
						data-trackingid='run-test-header-button'
					>
						{t('grid.actions.runTest')}
					</Button>
					<Button
						size='small'
						variant='contained'
						onClick={handleClick}
						disabled={disabled}
						aria-label={t('grid.actions.moreRunOptions')}
					>
						<ArrowDropDownIcon fontSize='small' />
					</Button>
				</ButtonGroup>
			</Tooltip>
			<Menu
				open={menuOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuItem onClick={() => scheduleTest()} disabled={disabled} data-trackingid='schedule-test-header-button'>
					<ListItemIcon>
						<IconCalendarOutlined />
					</ListItemIcon>
					<ListItemText>{t('grid.actions.scheduleTest')}</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};

export { RunAndScheduleButton };

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from '../translations/en';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		returnNull: false,
		resources: {
			en,
		},
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		supportedLngs: ['en'],
		fallbackLng: 'en',
	});

// eslint-disable-next-line unicorn/prefer-export-from
export { i18n };

import { generatePath } from 'react-router';
import { TestResult } from '@neoload/api';

export const resultTabs = ['overview', 'values', 'events', 'slas', 'dashboards', 'logs'] as const;
export type ResultTab = (typeof resultTabs)[number];

const valuesCategories = ['transactions', 'pages', 'requests', 'actions', 'counters'] as const;
export type ValuesCategory = (typeof valuesCategories)[number];

export const getValuesCategories = (loadInjectionProvider: TestResult['loadInjectionProvider']) =>
	loadInjectionProvider === 'JMETER'
		? valuesCategories.filter((category) => category !== 'pages')
		: [...valuesCategories];

export type ResultGenericParams = {
	resultId: string;
	tab?: ResultTab;
	subTab?: ValuesCategory;
};

export type ResultValuesParams = {
	resultId: string;
	tab: 'values';
	subTab?: ValuesCategory;
};

const patterns = {
	base: '/results',
	byId: '/results/:resultId',
	byIdWithTab: '/results/:resultId/:tab',
	byIdWithTabWithSubTab: '/results/:resultId/:tab/:subTab?',
};

const resultRoutes = {
	patterns: patterns,
	base: patterns.base,
	result: (resultId: string) => generatePath(patterns.byId, { resultId: resultId }),
	overview: (resultId: string) => generatePath(patterns.byIdWithTab, { resultId: resultId, tab: 'overview' }),
	logs: (resultId: string) => generatePath(patterns.byIdWithTab, { resultId: resultId, tab: 'logs' }),
	dashboards: (resultId: string) => generatePath(patterns.byIdWithTab, { resultId: resultId, tab: 'dashboards' }),
	values: (resultId: string, category: ValuesCategory = 'transactions') =>
		generatePath(patterns.byIdWithTabWithSubTab, { resultId: resultId, tab: 'values', subTab: category }),
	generic: (resultId: string, tab: ResultTab = 'overview', subTab = '') =>
		generatePath(patterns.byIdWithTabWithSubTab, { resultId: resultId, tab: tab, subTab: subTab }),
};

export { resultRoutes as ResultRoutes };

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { ChangeEvent, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { useFormContext } from 'react-hook-form';
import { calcWidth, gap, width } from '../constant';
import { TestExecutionForm } from '../types.d';
import { LimitedSizeTextField } from '../../../common/input/limited-size-text-field';

const TestExecutionResultInformation = () => {
	const [edit, setEdit] = useState({ name: false, description: false });
	const { t } = useTranslation(['test']);
	const theme = useTheme();
	const [previousName, setPreviousName] = useState('');
	const { setValue, getValues } = useFormContext<TestExecutionForm>();

	return (
		<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
			<Typography sx={{ marginBottom: 2 }} variant='subtitle1'>
				{t('testExecution.resultInformation')}{' '}
			</Typography>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: gap(theme) }}>
				<Typography
					variant='subtitle2'
					color={theme.palette.text.primary}
					width={width}
					sx={{ paddingBottom: edit.name ? '19px' : '0px' }}
				>
					{t('testExecution.resultName')}
				</Typography>
				{edit.name ? (
					<Box width={calcWidth(theme)}>
						<LimitedSizeTextField
							size='small'
							required
							max={256}
							autoFocus
							name='resultName'
							fullWidth={true}
							onChange={(event: ChangeEvent<HTMLInputElement>) => {
								setValue('test.name', event.currentTarget.value);
							}}
							onBlur={() => {
								setEdit({ ...edit, name: false });
								if (getValues('test.name') === '') {
									setValue('test.name', previousName);
								}
							}}
							value={getValues('test.name')}
							inputProps={{
								style: {
									padding: '0 12px',
									height: '36px',
								},
							}}
							requiredHelperText={t('testExecution.resultNameRequired')}
							aria-label={t('testExecution.resultName')}
						/>
					</Box>
				) : (
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography
							variant='body2'
							color={theme.palette.text.primary}
							marginRight='15px'
							onClick={() => {
								setEdit({ ...edit, name: true });
								setPreviousName(getValues('test.name'));
							}}
							sx={{
								padding: '9px',
								// eslint-disable-next-line @typescript-eslint/naming-convention
								'&:hover': { padding: 1, border: '1px solid rgba(0, 0, 0, 0.54)', borderRadius: '4px' },
							}}
							aria-label={t('testExecution.resultName')}
						>
							{getValues('test.name')}
						</Typography>
						<Tooltip arrow title={t('common:edit')}>
							<IconButton
								onClick={() => {
									setEdit({ ...edit, name: true });
									setPreviousName(getValues('test.name'));
								}}
							>
								<EditOutlined color='action' />
							</IconButton>
						</Tooltip>
					</Box>
				)}
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: gap(theme) }}>
				<Typography
					width={width}
					variant='subtitle2'
					color={theme.palette.text.primary}
					sx={{ paddingBottom: edit.description ? '19px' : '0px' }}
				>
					{t('testExecution.description')}
				</Typography>
				{edit.description ? (
					<Box width={calcWidth(theme)}>
						<TextField
							multiline
							autoFocus
							name='description'
							fullWidth
							onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
								setValue('test.description', event.currentTarget.value)
							}
							inputProps={{
								style: {
									padding: '0 12px',
								},
							}}
							onBlur={() => setEdit({ ...edit, description: false })}
							value={getValues('test.description')}
							aria-label={t('testExecution.description')}
						/>
					</Box>
				) : (
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography
							variant='body2'
							color={getValues('test.description') === '' ? theme.palette.text.secondary : theme.palette.text.primary}
							marginRight='15px'
							onClick={() => {
								setEdit({ ...edit, description: true });
							}}
							sx={{
								padding: '9px',
								// eslint-disable-next-line @typescript-eslint/naming-convention
								'&:hover': { padding: 1, border: '1px solid rgba(0, 0, 0, 0.54)', borderRadius: '4px' },
							}}
							aria-label={t('testExecution.description')}
						>
							{getValues('test.description') === '' ? t('testExecution.noDescription') : getValues('test.description')}
						</Typography>
						<Tooltip arrow title={t('common:edit')}>
							<IconButton
								onClick={() => {
									setEdit({ ...edit, description: true });
								}}
							>
								<EditOutlined color='action' />
							</IconButton>
						</Tooltip>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export { TestExecutionResultInformation };

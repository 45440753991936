import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../common/spinner';

type LoadingModalProps = {
	close: () => void;
};

const LoadingModal = ({ close }: LoadingModalProps) => {
	const { t } = useTranslation(['test']);

	return (
		<>
			<DialogTitle>
				<Stack useFlexGap justifyContent='space-between' flexDirection='row'>
					{t('testExecution.summary')}
				</Stack>
			</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 3,
						marginTop: 1,
						width: '750px',
					}}
				>
					<Box sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '60px' }}>
						<Spinner />
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button color='info' onClick={() => close()} data-trackingid='test-execution-button-cancel'>
					{t('common:cancel')}
				</Button>
				<Button variant='contained' color='info' disabled data-trackingid='test-execution-button-confirm'>
					{t('testExecution.run')}
				</Button>
			</DialogActions>
		</>
	);
};

export { LoadingModal };

import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import { AddSeriesFormData } from './add-series-form';
import { MonitorTree } from './monitor-tree';
import { UserPathTree } from './userpath-tree';
import { Statistic } from './statistics';
import { TreeType } from './userpath-tree/userpath-tree';
import { ComponentTabPanel } from '../../../../../layout/component-tab-panel/component-tab-panel';
import { ComparisonSerieDefaultValue } from '../form/comparison/row/use-find-in-tree';

export type AddSeriesElementSelectorProps<T extends Statistic> = {
	type: TreeType;
	multiple?: boolean;
	defaultValue?: ComparisonSerieDefaultValue<T>;
};
export const AddSeriesElementSelector = <T extends Statistic>({
	type,
	multiple,
	defaultValue,
}: AddSeriesElementSelectorProps<T>) => {
	const { t } = useTranslation(['dashboard'], { keyPrefix: 'tile.edition.addSeries' });
	const theme = useTheme();

	const [selectedTab, setSelectedTab] = useState(defaultValue?.type === 'MONITOR' ? 1 : 0);
	const { setValue } = useFormContext<AddSeriesFormData<T>>();
	useEffect(() => {
		setSelectedTab(defaultValue?.type === 'MONITOR' ? 1 : 0);
	}, [setSelectedTab, defaultValue]);
	return (
		<>
			<Tabs
				variant='fullWidth'
				value={selectedTab}
				onChange={(_event, tab) => {
					setSelectedTab(tab);
					setValue('type', undefined);
				}}
			>
				<Tab label={t('userPathTabLabel')} />
				<Tab label={t('monitorsTabLabel')} />
			</Tabs>
			<ComponentTabPanel
				value={selectedTab}
				index={0}
				aria-label={t('userPathPanel')}
				sx={{ height: '100%', overflow: 'auto', borderTop: `1px solid ${theme.palette.divider}` }}
			>
				<UserPathTree type={type} multiple={multiple} defaultValue={defaultValue} />
			</ComponentTabPanel>
			<ComponentTabPanel
				value={selectedTab}
				index={1}
				aria-label={t('monitorsPanel')}
				sx={{ height: '100%', overflow: 'auto', borderTop: `1px solid ${theme.palette.divider}` }}
			>
				<MonitorTree type={type} defaultValue={defaultValue} />
			</ComponentTabPanel>
		</>
	);
};

const reservation = {
	description: 'List of all reservations',
	title: 'Reservations',
	grid: {
		title: 'Reservations',
		header: {
			name: 'Name',
			time: 'Time',
			lgs: 'LGs',
			vus: 'VUs',
			status: 'Status',
		},
		status: {
			planned: 'Planned',
			available: 'Available',
			running: 'Running',
			ended: 'Ended',
		},
		drawer: {
			title: 'Reservation details',
			status: 'Status',
			name: 'Name',
			description: 'Description',
			start: 'Start',
			end: 'End',
			duration: 'Duration',
			test: 'Test',
			workspace: 'Workspace',
			controller: 'Controller',
			zonesLgs: 'Zones, LGs',
			licenseVirtualUsers: 'License virtual users',
			notAvailable: 'Not available',
		},
		vus: {
			web: 'Web',
			sap: 'SAP',
		},
		delete: {
			title: 'Delete reservation?',
			label: 'Deleting the reservation will cancel your scheduled tests and release your allocated resources.',
		},
	},
	reservationOff: {
		title: 'Enable reservations',
		description:
			'Start reserving resources to plan and automate your test runs. A reservation ensures access for a set duration, beginning at your chosen date and time.',
		isTester: 'To enable, contact your admin.',
		button: 'Enable reservations',
	},
	noReservation: {
		title: 'Create your first reservation',
		description:
			'Secure license resources for your upcoming test runs and activities, or plan and automate test execution ahead.',
		scheduleTest: 'Schedule test run',
		createReservation: 'Create reservation',
	},
	delete: {
		success_one: 'Reservation successfully deleted',
		error_one: 'An unexpected error occurred',
		error405_one: 'This reservation cannot be deleted because it is used by a running test.',
	},
	create: {
		title: 'Create reservation',
		name: 'Name',
		description: 'Description',
		startDate: 'Start date',
		startTime: 'Start time',
		endDate: 'End date',
		endTime: 'End time',
		totalReservation: 'Total reservation duration: ',
		available: '{{available}} available',
		nameRequired: 'Name is required',
		confirm: 'Create',
		snackbars: {
			success: 'Reservation successfully created',
		},
	},
	schedule: {
		title: 'Schedule test run',
		titleEdit: 'Edit scheduled test run',
		confirm: 'Schedule',
		test: 'Test',
		workspace: 'Workspace',
		noWorkspaces: 'No workspaces',
		noTests: 'No runnable test found in the workspace',
		snackbars: {
			success: 'Test run scheduled',
			updateSuccess: 'Scheduled test run updated',
		},
		errors: {
			notEnoughVus: 'Not enough VUs available',
			unAuthorized: "You aren't authorized to access this project. Check your permissions and try again.",
			notFound: 'Resource not found. Verify the URL or resource and try again.',
		},
	},
};

export { reservation };

import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useEffectOnce } from 'react-use';
import dayjs from 'dayjs';
import { CreateReservationForm, reservationErrorToString } from './reservation-helper';
import {
	useLazyGetV4TestsByTestIdProjectQuery,
	useLazyGetV4TestsByTestIdQuery,
	useLazyGetV4TestsQuery,
} from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

type AutocompleteType = { title: string; id: string; selectedScenarioName?: string };

const TestReservationModal = () => {
	const { t } = useTranslation(['reservation']);
	const [triggerGetTests] = useLazyGetV4TestsQuery();
	const [triggerGetTestById] = useLazyGetV4TestsByTestIdQuery();
	const [triggerGetProject] = useLazyGetV4TestsByTestIdProjectQuery();

	const { setValue, watch, getValues } = useFormContext<CreateReservationForm>();

	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<AutocompleteType[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [currentTest, setCurrentTest] = useState<{ title: string; id: string; selectedScenarioName?: string } | null>(
		null,
	);
	const [pageNumber, setPageNumber] = useState(1);
	const { showError } = useSetSnackbars();

	const workspaceId = watch('workspaceId');
	const initialTestId = watch('testId');
	const initialScheduleDataNotChanged = getValues('initialScheduleDataNotChanged');
	const action = getValues('action');

	useEffect(() => {
		if (initialTestId === undefined) {
			setCurrentTest(null);
		}
	}, [initialTestId]);

	useEffectOnce(() => {
		if (initialScheduleDataNotChanged && initialTestId) {
			triggerGetTestById({ testId: initialTestId })
				.unwrap()
				.then((test) => {
					if (test) {
						setOptions([{ title: test.name, id: test.id, selectedScenarioName: test.selectedScenarioName }]);
						setCurrentTest({ title: test.name, id: test.id, selectedScenarioName: test.selectedScenarioName });
					}
				})
				.catch((error) => {
					const message = reservationErrorToString(error.data, t);
					showError({
						text: message,
						id: 'reservation',
					});
					setIsLoading(false);
					setOpen(false);
				});
		}
	});

	const setReference = (data: AutocompleteType | null) => {
		setValue('testId', data?.id ?? undefined);
		setValue('initialScheduleDataNotChanged', false);
		setCurrentTest(data ? { title: data.title, id: data.id, selectedScenarioName: data.selectedScenarioName } : null);
	};

	useEffect(() => {
		if (workspaceId === undefined) {
			setCurrentTest(null);
		}
	}, [workspaceId]);

	useEffect(() => {
		let active = true;

		if (!isLoading || !workspaceId) {
			setIsLoading(false);
			return undefined;
		}
		setIsLoading(true);

		triggerGetTests({
			workspaceId: workspaceId,
			pageNumber: pageNumber,
		})
			.unwrap()
			.then((tests) => {
				const items = tests?.items
					?.filter((test) => test.isConfigured)
					.map((test) => ({ title: test.name, id: test.id, selectedScenarioName: test.selectedScenarioName }))
					.toSorted((first, second) => first.title.localeCompare(second.title));
				if (active && items) {
					setOptions((previousItems) => [...previousItems, ...items]);
					if (tests.pageSize !== undefined && pageNumber < tests.total / tests.pageSize - 1) {
						setPageNumber((previous) => previous + 1);
					} else {
						setIsLoading(false);
					}
				}
			})
			.catch((error) => {
				const message = reservationErrorToString(error.data, t);
				showError({
					text: message,
					id: 'reservation',
				});
				setIsLoading(false);
				setOpen(false);
			});

		return () => {
			active = false;
		};
	}, [setValue, isLoading, workspaceId, triggerGetTests, pageNumber, t, showError]);

	useEffect(() => {
		if (open) {
			setIsLoading(true);
			setPageNumber(0);
		} else {
			setOptions([]);
			setIsLoading(false);
			setPageNumber(0);
		}
	}, [open]);

	useEffect(() => {
		if (currentTest !== null && currentTest.id !== '') {
			if (currentTest.selectedScenarioName === currentTest.title) {
				setValue('scenario.duration', 'custom');
			} else {
				triggerGetProject({ testId: currentTest.id })
					.unwrap()
					.then((project) => {
						const currentScenario = (project.scenarios ?? []).find(
							({ name }) => name === currentTest.selectedScenarioName,
						);
						if (currentScenario?.duration === undefined) {
							setValue('endDate', dayjs().add(75, 'minute').second(0).toISOString());
							setValue('scenario.duration', 'iteration');
						} else {
							setValue('scenario.duration', currentScenario?.duration);
						}
					})
					.catch((error) => {
						const message = reservationErrorToString(error.data, t);
						showError({
							text: message,
							id: 'reservation',
						});
					});
			}
		}
	}, [currentTest, setValue, showError, t, triggerGetProject]);

	return (
		<Autocomplete
			value={currentTest}
			key={workspaceId}
			open={open}
			disabled={workspaceId === undefined || action === 'EDIT'}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			size='small'
			isOptionEqualToValue={(option, value) => option.title === value.title}
			getOptionLabel={(option) => option.title}
			options={options}
			noOptionsText={t('schedule.noTests')}
			loadingText={t('common:loading')}
			loading={isLoading}
			onChange={(_, data) => setReference(data)}
			renderInput={(params) => (
				<TextField
					{...params}
					label={t('schedule.test')}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{isLoading ? <CircularProgress color='inherit' size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
		/>
	);
};

export { TestReservationModal };

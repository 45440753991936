import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationFormData } from './types';
import { configurationErrorToString, projectToConfigurationForm } from './configuration-helpers';
import { useGetV4CheckoutsByCheckoutIdQuery, useLazyGetV4TestsByTestIdProjectQuery } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

const useCheckoutPolling = () => {
	const { t } = useTranslation(['test']);
	const { showInfo, showError } = useSetSnackbars();

	const configuration = useWatch<ConfigurationFormData>();
	const { setValue } = useFormContext<ConfigurationFormData>();

	const { data: checkoutStatus } = useGetV4CheckoutsByCheckoutIdQuery(
		{ checkoutId: configuration?.git?.checkoutId ?? '' },
		{
			pollingInterval: 1000,
			skip: configuration?.git?.checkoutId === undefined || configuration?.git.checkoutId === '',
		},
	);
	const [triggerGetProject] = useLazyGetV4TestsByTestIdProjectQuery();

	useEffect(() => {
		if (configuration?.git?.testId === undefined || checkoutStatus?.id !== configuration?.git?.checkoutId) {
			return;
		}

		if (checkoutStatus?.status === 'ERROR') {
			showError({
				text: t('configuration.snackbar.uploadFail', {
					name: `${configuration?.git?.repositoryName}/${configuration?.git?.reference}`,
					error: checkoutStatus.error
						? configurationErrorToString(checkoutStatus.error, t)
						: t('common:errors.otherError.content'),
				}),
				id: 'upload',
			});
			setValue('git', undefined);
		}

		if (checkoutStatus?.status === 'DONE') {
			triggerGetProject({ testId: configuration.git.testId })
				.unwrap()
				.then((project) => {
					projectToConfigurationForm(setValue, project);
					showInfo({
						text: t('configuration.snackbar.uploadSuccess', {
							name: `${configuration?.git?.repositoryName}/${configuration?.git?.reference}`,
						}),
						id: 'upload',
					});
				})
				.catch((error) => {
					showError({
						text: t('configuration.snackbar.uploadFail', {
							name: `${configuration?.git?.repositoryName}/${configuration?.git?.reference}`,
							error: configurationErrorToString(error.data, t),
						}),
						id: 'upload',
					});
				})
				.finally(() => {
					setValue('git', undefined);
				});
		}
	}, [checkoutStatus, setValue, triggerGetProject, showInfo, t, showError, configuration]);
};

export { useCheckoutPolling };

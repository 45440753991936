import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { CreateOfflineLeaseFormModal } from './creation-modal/create-offline-lease-form-modal';
import { OfflineReleaseButton } from './offline-release-button';
import { CreateResourceButton } from '../../../../common/button/create-resource-button';
import { useGetV4LicenseQuery, useGetV4WorkspacesByWorkspaceIdSubscriptionQuery } from '@neoload/api';
import { isNotFoundError } from '@neoload/utils';

type CreateOfflineLeaseButtonProps = {
	workspaceId: string;
	isAdmin: boolean;
};

export const LeaseDatagridMainActionPanel = ({ workspaceId, isAdmin }: CreateOfflineLeaseButtonProps) => {
	const {
		data: subscriptionInfo,
		isLoading: isSubscriptionInfoLoading,
		error: subscriptionError,
	} = useGetV4WorkspacesByWorkspaceIdSubscriptionQuery({ workspaceId, fields: ['webVu', 'sapVu', 'concurrentRuns'] });
	const {
		data: license,
		isLoading: licenseLoading,
		error: licenseError,
	} = useGetV4LicenseQuery(undefined, { skip: !isAdmin });
	const { t } = useTranslation(['workspace']);

	const [createOfflineLeaseModalOpened, setCreateOfflineLeaseModalOpened] = useState(false);

	const isLoading = isSubscriptionInfoLoading || licenseLoading;

	const getDisabedTooltip = () => {
		if (isLoading) {
			return t('leases.loading');
		}
		if (subscriptionError) {
			return t('leases.fetchSubscriptionError');
		}
		if (isAdmin) {
			if (licenseError && !isNotFoundError(licenseError)) {
				return t('leases.fetchLicenseError');
			} else {
				if (!license) {
					return t('leases.noLicense');
				}
				if (license?.licenseStatus !== 'ACTIVE') {
					return t('leases.licenseNotActive');
				}
			}
		}
		return undefined;
	};

	const disabledMessage = getDisabedTooltip();
	const disabled = !!disabledMessage;

	const maxWebVus = subscriptionInfo?.webVu?.available ?? 0;
	const maxSapVus = subscriptionInfo?.sapVu?.available ?? 0;
	const licenseExpirationDate = license?.expirationDate;
	const maxConcurrentRuns = subscriptionInfo?.concurrentRuns?.available;

	return (
		<>
			<Tooltip arrow title={disabledMessage}>
				<Grid data-testid='create-lease-tooltip-wrapper'>
					<CreateResourceButton
						onClick={() => setCreateOfflineLeaseModalOpened(true)}
						disabled={disabled}
						isLoading={isLoading}
					>
						{t('leases.createOfflineLeaseButton')}
					</CreateResourceButton>
				</Grid>
			</Tooltip>
			<Tooltip arrow title={disabledMessage}>
				<Grid data-testid='offline-release-tooltip-wrapper'>
					<OfflineReleaseButton disabled={disabled} isLoading={isLoading} />
				</Grid>
			</Tooltip>
			<CreateOfflineLeaseFormModal
				opened={createOfflineLeaseModalOpened}
				close={() => setCreateOfflineLeaseModalOpened(false)}
				workspaceId={workspaceId}
				maxSapVus={maxSapVus}
				maxWebVus={maxWebVus}
				maxConcurrentRuns={maxConcurrentRuns}
				licenseExpirationDate={licenseExpirationDate}
			/>
		</>
	);
};

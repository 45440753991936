import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GroupOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import CreditCardOutlined from '@mui/icons-material/CreditCardOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import { useTranslation } from 'react-i18next';
import WorkspacesOutlined from '@mui/icons-material/WorkspacesOutlined';
import IconCalendarOutlined from '@tricentis/aura/components/IconCalendarOutlined.js';
import IconTestDataOutlined from '@tricentis/aura/components/IconTestDataOutlined.js';
import WorkspacePremiumOutlined from '@mui/icons-material/WorkspacePremiumOutlined';
import { useEffect } from 'react';
import { AbstractSettingsLayout } from './abstract-settings-layout';
import { MenuItemProps } from './menu-item';
import { useFeatureEnabled, useGetMe } from '@neoload/hooks';
import { callCompanionIdentifyIfNeeded, SettingsRoutes } from '@neoload/utils';

const SettingsLayout = () => {
	const { t } = useTranslation(['menu']);
	const [userInfo] = useGetMe();
	const title = t('settings');
	const [{ isFeatureEnabled: reservationFeatureEnabled }] = useFeatureEnabled('reservation');
	const [{ isFeatureEnabled: subscriptionFeatureEnabled }] = useFeatureEnabled('subscription');
	const [{ isFeatureEnabled: infrastructureProvidersFeatureEnabled }] = useFeatureEnabled('infrastructure-providers');

	const menuItems: MenuItemProps[] = [
		{
			title: t('general'),
			icon: <SettingsOutlinedIcon />,
			link: SettingsRoutes.generalSettings,
			enabled: false,
		},
		{
			title: t('section.resources'),
			enabled: true,
		},
		{
			title: t('zones'),
			icon: <PublicOutlinedIcon />,
			link: SettingsRoutes.zones.base,
			enabled: true,
		},
		{
			title: t('reservation'),
			icon: <IconCalendarOutlined />,
			link: SettingsRoutes.reservation,
			enabled: reservationFeatureEnabled,
		},
		{
			title: t('section.account'),
			enabled: true,
		},
		{
			title: t('users'),
			icon: <GroupOutlinedIcon />,
			link: SettingsRoutes.users,
			enabled: userInfo.hasAdminPermissions,
		},
		{
			title: t('workspaces'),
			icon: <WorkspacesOutlined />,
			link: SettingsRoutes.workspaces.base,
			enabled: userInfo.hasTesterPermissions,
		},
		{
			title: t('infrastructureProviders'),
			icon: <CloudOutlinedIcon />,
			link: SettingsRoutes.infrastructureProviders,
			enabled: infrastructureProvidersFeatureEnabled,
		},
		{
			title: t('subscription'),
			icon: <CreditCardOutlined />,
			link: SettingsRoutes.subscription,
			enabled: subscriptionFeatureEnabled && userInfo.hasAdminPermissions,
		},
		{
			title: t('license'),
			icon: <WorkspacePremiumOutlined />,
			link: SettingsRoutes.license,
			enabled: userInfo.hasAdminPermissions,
		},
		{
			title: t('usageHistory'),
			icon: <IconTestDataOutlined />,
			link: SettingsRoutes.usageHistory,
			enabled: userInfo.hasAdminPermissions,
		},
	].filter((item) => item.enabled);

	useEffect(() => {
		const timer = setTimeout(() => callCompanionIdentifyIfNeeded(userInfo.id), 5000);
		return () => clearTimeout(timer);
	}, [userInfo.id]);

	return <AbstractSettingsLayout title={title} menuItems={menuItems} />;
};

export { SettingsLayout };

import { useTranslation } from 'react-i18next';
import ChipStatus from '@tricentis/aura/components/ChipStatus.js';
import CachedOutlined from '@mui/icons-material/CachedOutlined';
import EventAvailableOutlined from '@mui/icons-material/EventAvailableOutlined';
import TaskAltOutlined from '@mui/icons-material/TaskAltOutlined';
import EventOutlined from '@mui/icons-material/EventOutlined';
import { Reservation } from '@neoload/api';

const ReservationStatusChip = ({ status }: { status: Reservation['status'] }) => {
	const { t } = useTranslation(['reservation']);

	if (status === 'PLANNED') {
		return <ChipStatus status='Pending' label={t('grid.status.planned')} icon={<EventOutlined />} />;
	}

	if (status === 'RUNNING') {
		return (
			<ChipStatus
				status='Running'
				label={t('grid.status.running')}
				icon={
					<CachedOutlined
						sx={{
							animation: 'spin 2s linear infinite',
							'@keyframes spin': {
								'0%': {
									transform: 'rotate(360deg)',
								},
								'100%': {
									transform: 'rotate(0deg)',
								},
							},
						}}
					/>
				}
			/>
		);
	}

	if (status === 'ENDED') {
		return <ChipStatus status='NotApplicable' label={t('grid.status.ended')} icon={<TaskAltOutlined />} />;
	}

	return <ChipStatus status='Active' label={t('grid.status.available')} icon={<EventAvailableOutlined />} />;
};

export { ReservationStatusChip };

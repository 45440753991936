import { useController, useFormContext } from 'react-hook-form';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { TileEditionFormFields } from './tile-edition-form';
import { RichTextEditor } from '../../../../../common/rich-text-editor/rich-text-editor';

const fieldName = 'text';

export const TileTextAccordion = () => {
	const { t } = useTranslation(['dashboard']);
	const { control } = useFormContext<TileEditionFormFields>();
	const {
		field: { onChange, value },
	} = useController({ control, name: fieldName });

	return (
		<Accordion disableGutters defaultExpanded={true} elevation={0}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>{t('tile.edition.sections.text')}</AccordionSummary>
			<AccordionDetails>
				<RichTextEditor
					name={fieldName}
					value={value}
					placeholder={t('tile.edition.fields.text.placeholder')}
					onChange={onChange}
				/>
			</AccordionDetails>
		</Accordion>
	);
};

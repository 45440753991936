import { useTranslation } from 'react-i18next';
import ZeroState from '@tricentis/aura/components/ZeroState.js';
import Typography from '@mui/material/Typography';
import { ButtonProps } from '@mui/material/Button';
import { Key, MouseEventHandler } from 'react';
import NoRepository from '../../../assets/NoRepository.svg';
import { CreateResourceButton } from '../../common/button/create-resource-button';

type ButtonPropsWithKey = ButtonProps & { key: Key };

type GitRepoEmptyStateProps = {
	createAction: MouseEventHandler<HTMLButtonElement>;
};

const GitRepoEmptyState = ({ createAction }: GitRepoEmptyStateProps) => {
	const { t } = useTranslation(['user']);

	const actions: ButtonPropsWithKey[] = [
		{
			key: 'createGitRepo',
			variant: 'contained',
			onClick: createAction,
		},
	];
	const title = t('repository.zero.title');

	return (
		<ZeroState
			illustration={<img src={NoRepository} alt={title} />}
			title={title}
			actions={actions.map(({ key, ...others }) => (
				<CreateResourceButton {...others} key={key}>
					{t('repository.zero.button')}
				</CreateResourceButton>
			))}
			containerSx={{ backgroundColor: 'transparent' }}
		>
			<Typography variant='body2'>{t('repository.zero.content')}</Typography>
		</ZeroState>
	);
};

export { GitRepoEmptyState };

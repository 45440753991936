import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { User, UserRole } from '@neoload/api';

type UserRoleDialogProps = {
	user: User;
	isOpen: boolean;
	onClose: (isOk: boolean, userId: string, userRole: User['role']) => void;
	roles: {
		value: UserRole;
		label: string;
	}[];
};

const UserRoleDialog = ({ user, isOpen, onClose, roles }: UserRoleDialogProps) => {
	const { t } = useTranslation(['user']);
	const [selectedUserRole, setSelectedUserRole] = useState<User['role']>(user.role);

	useEffect(() => {
		setSelectedUserRole(user.role);
	}, [user]);

	return (
		<Dialog open={isOpen} fullWidth={true}>
			<DialogTitle>{t('editRoleDialog.title', { userName: `${user.firstName} ${user.lastName}` })}</DialogTitle>
			<DialogContent>
				<Box sx={{ marginTop: 1 }}>
					<FormControl fullWidth required>
						<InputLabel id='role-label' required={true}>
							{t('editRoleDialog.role')}
						</InputLabel>
						<Select
							labelId='role-label'
							label={t('editRoleDialog.role')}
							value={selectedUserRole}
							required={true}
							onChange={(event) => setSelectedUserRole(event.target.value as User['role'])}
							size='small'
						>
							{roles.map((role) => (
								<MenuItem key={role.value} value={role.value}>
									{role.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button size='small' onClick={() => onClose(false, user.id, selectedUserRole)} color='primary'>
					{t('common:cancel')}
				</Button>
				<Button size='small' onClick={() => onClose(true, user.id, selectedUserRole)} variant='contained'>
					{t('common:save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export { UserRoleDialog };

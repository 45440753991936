import { Reservation, TestExecutionResource, Zone } from '@neoload/api';

export enum TestExecutionErrorTypes {
	NotEnoughCloudCredits = 'NotEnoughCloudCredits',
	NotEnoughVuh = 'NotEnoughVuh',
	MissingLg = 'MissingLg',
	MissingController = 'MissingController',
	IncompatibleVersion = 'IncompatibleVersion',
	Scenario = 'Scenario',
	Global = 'Global',
	Zones = 'Zones',
}

export type TestExecutionError = { type: TestExecutionErrorTypes; sentence: string };

export type TestExecutionForm = {
	test: {
		id: string;
		name: string;
		description: string;
		projectName: string;
		scenarioName: string;
		duration: string;
		vus: number;
	};
	resources: {
		duration: string;
		maxDuration: string;
		reservationId?: string;
		sapVu: {
			needed: number;
			available: number;
			initial: number;
		};
		webVu: {
			needed: number;
			available: number;
			initial: number;
		};
		minimumVersion: string;
		version?: string;
		controllerZoneId: string;
		zoneType: Zone['type'];
		cloudCredits: {
			available: number;
			needed: number;
		};
		vuh: {
			available: number;
			needed: number;
		};
		zones: {
			[key: string]: number;
		};
		missingDedicatedIpByZone: TestExecutionResource['missingDedicatedIpByZone'];
	};
	errors: TestExecutionError[];
	errorsInFirstCall: boolean;
	reservation: {
		reservationModeStatus?: 'RESERVATION_MODE_ENABLED' | 'RESERVATION_MODE_DISABLED';
		availableReservations?: Reservation[];
		selectedReservation?: Reservation;
	};
	confirmable: boolean;
	loaded: boolean;
};

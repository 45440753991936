import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { useState } from 'react';
import { Population } from '@neoload/api';

type AddPopulationModalProps = {
	open: boolean;
	onClose: () => void;
	onSave: (populations: Population[]) => void;
	populations: Population[];
};

const AddPopulationModal = ({ open, onClose, onSave, populations }: AddPopulationModalProps) => {
	const { t } = useTranslation(['test']);
	const label = t('configuration.scenarios.custom.selectPopulations');

	const [selectedPopulations, setSelectedPopulations] = useState<Population[]>(
		populations.length === 1 ? populations : [],
	);

	function updateSelectedPopulations(_event: object, values: Population[]) {
		setSelectedPopulations(values);
	}

	return (
		<Dialog open={open} maxWidth='sm' fullWidth={true} aria-labelledby='add-population-modal-title'>
			<DialogTitle id='add-population-modal-title'>
				{t('configuration.scenarios.custom.addPopulationsTitle')}
			</DialogTitle>
			<DialogContent>
				<Autocomplete
					defaultValue={populations.length === 1 ? populations : undefined}
					renderInput={(params) => <TextField {...params} label={label} />}
					getOptionLabel={(option) => option.name}
					options={populations.toSorted((a, b) => a.name.localeCompare(b.name))}
					multiple
					noOptionsText={t('configuration.scenarios.custom.noPopulations')}
					onChange={updateSelectedPopulations}
					sx={{ marginTop: 1 }}
				/>
			</DialogContent>
			<DialogActions>
				<Button color='info' data-trackingid='add-population-button-cancel' onClick={onClose}>
					{t('common:cancel')}
				</Button>
				<Button
					variant='contained'
					color='info'
					data-trackingid='add-population-button-confirm'
					disabled={selectedPopulations.length === 0}
					onClick={() => {
						onSave(selectedPopulations);
					}}
				>
					{t('configuration.scenarios.custom.addPopulationButton', {
						count: selectedPopulations.length,
					})}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export { AddPopulationModal };

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { CreateProviderRequestWrite } from '@neoload/api';

type TokenTextFieldProps = {
	isEdition: boolean;
};

export const TokenTextField = ({ isEdition }: TokenTextFieldProps) => {
	const { t } = useTranslation('infrastructureProviders');
	const {
		register,
		formState: { errors },
		setValue,
		trigger,
		watch,
		clearErrors,
		setError,
	} = useFormContext<CreateProviderRequestWrite>();

	const [tokenVisible, setTokenVisible] = useState(false);
	const [enabled, setEnabled] = useState(!isEdition);
	const apiToken = watch('apiToken');

	const tokenVisibilityTooltip = tokenVisible ? t('modal.hideToken') : t('modal.showToken');
	const editOrCancelTooltip = enabled ? t('common:reset') : t('common:edit');

	const handleEnable = (enable: boolean) => {
		setEnabled(enable);
		setValue('apiToken', '');
		//manually setting / clearing token errors because the validation in the register triggers only when input changes
		if (!enable) {
			clearErrors('apiToken');
		} else if (!apiToken) {
			setError('apiToken', { type: 'required', message: t('common:input.required') });
		}
	};

	useEffect(() => {
		if (isEdition) {
			//manually trigger form validation (state of submit button) when token error changes because it doesn't refresh when setting / clearing errors manually
			const triggerTokenValidation = async () => {
				await trigger('apiToken');
			};
			triggerTokenValidation().catch((error) => console.error(error));
		}
	}, [errors.apiToken?.message, trigger, isEdition]);

	return (
		<Stack direction='row' gap={2} alignItems='top'>
			<TextField
				label={t('modal.apiToken')}
				fullWidth
				size='small'
				placeholder={isEdition && !enabled ? '*************' : ''}
				required={enabled}
				disabled={!enabled}
				type={tokenVisible ? 'text' : 'password'}
				error={!!errors.apiToken}
				InputLabelProps={{ shrink: true }}
				{...register('apiToken', {
					validate: (value) => (enabled && !value ? t('common:input.required') : undefined),
				})}
				helperText={errors.apiToken?.message}
				InputProps={{
					endAdornment: enabled && (
						<InputAdornment position='end'>
							<Tooltip arrow title={tokenVisibilityTooltip}>
								<span>
									<IconButton aria-label={tokenVisibilityTooltip} onClick={() => setTokenVisible((old) => !old)}>
										{tokenVisible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
									</IconButton>
								</span>
							</Tooltip>
						</InputAdornment>
					),
					inputProps: { maxLength: 8192 },
				}}
			/>
			{isEdition && (
				<Tooltip arrow title={editOrCancelTooltip}>
					<IconButton aria-label={editOrCancelTooltip} onClick={() => handleEnable(!enabled)} sx={{ height: '40px' }}>
						{enabled ? <RefreshOutlined /> : <EditOutlined />}
					</IconButton>
				</Tooltip>
			)}
		</Stack>
	);
};

import {
	GridColDef,
	GridInitialState,
	GridRenderCellParams,
	GridRowModel,
	GridRowSelectionModel,
	useGridApiRef,
} from '@mui/x-data-grid-pro';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import { ComponentPropsWithoutRef, MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { GitRepoEmptyState } from './git-repo-empty-state';
import { GitRepoUpdateModal } from './git-repo-update-modal';
import { useGitRepoDatagridActions } from './use-git-repo-datagrid-actions';
import { DEFAULT_GRID_PROPS, onColumnChange } from '../../common/datagrid';
import { Datagrid } from '../../common/data-grid/datagrid';
import { CreateResourceButton } from '../../common/button/create-resource-button';
import { filterToContextMenuItems, filterToSecondaryActions } from '../../common/data-grid/actions/data-grid-actions';
import { ActionsCellMenu } from '../../common/data-grid/actions/actions-cell-menu';
import { ContextMenu, ContextMenuFuncts } from '../../common/context-menu/context-menu';
import { Repository, RepositoryPage } from '@neoload/api';
import { useColumnsState } from '@neoload/hooks';

const columnsStateKey = 'REPOS_COLUMNS_STATE';

type GitRepoSettingsProps = {
	gitRepoPage: RepositoryPage;
	isFetching: boolean;
};

type ModalState = {
	open: boolean;
	editRepository?: Repository;
};

const GitRepoSettings = ({ gitRepoPage, isFetching }: GitRepoSettingsProps) => {
	const apiRef = useGridApiRef();
	const contextMenu: MutableRefObject<ContextMenuFuncts | undefined> = useRef();

	const [selectedGitRepoIds, setSelectedGitRepoIds] = useState<GridRowSelectionModel>([]);
	const { t } = useTranslation(['user']);

	const [modalState, setModalState] = useState<ModalState>({
		open: false,
	});

	const openEditModal = (id: string) => {
		const repoToEdit = gitRepoPage.items?.find((item) => item.id === id);
		setModalState({ open: true, editRepository: repoToEdit });
	};

	const actions = useGitRepoDatagridActions(selectedGitRepoIds, openEditModal);

	const openCreateModal = () => {
		setModalState({ open: true });
	};

	const closeModal = () => {
		setModalState({ open: false });
	};

	const columns: GridColDef<Repository>[] = [
		{
			field: 'name',
			headerName: t('repository.name'),
			minWidth: 250,
		},
		{
			field: 'url',
			headerName: t('repository.url'),
			minWidth: 500,
			flex: 1,
		},
		{
			field: 'login',
			valueGetter: (_, row) => row.credentials?.login,
			headerName: t('repository.login'),
			minWidth: 250,
		},
		{
			field: 'actions',
			renderCell: (params: GridRenderCellParams) => <ActionsCellMenu actions={actions} rowData={params.row} />,
			resizable: false,
			disableReorder: true,
			type: 'actions',
		},
	];

	const initialState: GridInitialState = {
		sorting: {
			sortModel: [{ field: 'name', sort: 'asc' }],
		},
	};
	const { updatedInitialState, updatedColumns, storeColumnState } = useColumnsState(
		columnsStateKey,
		initialState,
		columns,
		apiRef,
	);

	const addRepoAction = {
		children: (
			<CreateResourceButton onClick={openCreateModal} key='configureRepo'>
				{t('repository.add')}
			</CreateResourceButton>
		),
	};

	const componentsProps: { toolbar: ComponentPropsWithoutRef<typeof Toolbar>; row: GridRowModel } = {
		toolbar: {
			displayColumnOptions: false,
			hideFiltersIcon: true,
			mainActions: [addRepoAction],
			secondaryActions: selectedGitRepoIds.length > 0 ? [filterToSecondaryActions(actions)] : [],
			syncLocalStorage: {
				datagridId: 'neoloadGitReposDatagrid',
				isSyncEnabled: true,
			},
			title: t('repository.repositories'),
		},
		row: {
			onContextMenu: (event: React.MouseEvent) => contextMenu.current?.openContextMenu(event),
		},
	};

	if (gitRepoPage.items && gitRepoPage.items.length === 0) {
		return (
			<Box sx={{ flexGrow: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<GitRepoEmptyState createAction={openCreateModal} />
				<GitRepoUpdateModal onClose={closeModal} {...modalState} />
			</Box>
		);
	}

	return (
		<>
			<Datagrid
				{...DEFAULT_GRID_PROPS}
				{...onColumnChange(storeColumnState)}
				loading={isFetching}
				apiRef={apiRef}
				rows={gitRepoPage.items ?? []}
				disableRowSelectionOnClick={false}
				getRowId={(row) => row.id}
				initialState={updatedInitialState}
				columns={updatedColumns}
				onRowSelectionModelChange={(model) => setSelectedGitRepoIds(model)}
				slotProps={componentsProps}
				slots={{
					toolbar: Toolbar,
				}}
			/>
			<ContextMenu apiRef={apiRef} ref={contextMenu} contextMenuItemsList={filterToContextMenuItems(actions)} />
			<GitRepoUpdateModal onClose={closeModal} {...modalState} />
		</>
	);
};
export { GitRepoSettings };

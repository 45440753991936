import { useTranslation } from 'react-i18next';
import {
	getGridStringOperators,
	GridCellParams,
	GridFilterInputMultipleSingleSelect,
	GridFilterOperator,
} from '@mui/x-data-grid-pro';
import { GridColDef, GridFilterItem } from '@mui/x-data-grid/models';
import Box from '@mui/material/Box';
import { Category } from './values-data-grid';
import { SlaBadge } from '../../../../common/sla-badge';
import { numericOperators } from '../values-data-grid-common';
import { alphabeticSort } from '../../../../common/data-grid/sort/sort-utils';
import { numberUtils, Sla, slaUtils } from '@neoload/utils';
import { ResultElementValue } from '@neoload/api';

const getValueOptions = (field: 'userPath' | 'parent' | 'name', items: ResultElementValue[]): string[] =>
	alphabeticSort([
		...new Set(items?.map((item) => item[field]).filter((value): value is string => typeof value === 'string')),
	]);

const getIsAnyOfOperator = (label: string): GridFilterOperator => ({
	getApplyFilterFn: (filterItem: GridFilterItem) => {
		if (!filterItem.value || filterItem.value.length === 0) {
			return null;
		}
		return (params: GridCellParams): boolean => filterItem.value.includes(params.value);
	},
	label: label,
	value: 'isAnyOf',
	// eslint-disable-next-line @typescript-eslint/naming-convention
	InputComponent: GridFilterInputMultipleSingleSelect,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	InputComponentProps: { type: 'string' },
});

const getEnhancedStringFilterOperators = (label: string) =>
	getGridStringOperators().map((operator) => (operator.value === 'isAnyOf' ? getIsAnyOfOperator(label) : operator));

const ValuesDataGridColumns = (category: Category, items: ResultElementValue[]) => {
	const { t, i18n } = useTranslation(['result']);
	const formatFloatingNumber = (value?: number): string => numberUtils.formatNumberToString(i18n.language, value);
	const formatIntegerNumber = (value?: number): string => numberUtils.formatIntegerToString(i18n.language, value);

	const base: GridColDef<ResultElementValue>[] = [
		{
			field: 'userPath',
			headerName: t('values.userPath'),
			flex: 1,
			minWidth: 180,
			filterOperators: getEnhancedStringFilterOperators(t('filters.isAnyOf')),
			valueOptions: getValueOptions('userPath', items),
		},
		{
			field: 'slaStatus',
			align: 'center',
			headerAlign: 'center',
			headerName: t('values.sla'),
			minWidth: 20,
			renderCell: ({ value }: { value?: Sla }) => (
				<Box display='flex' alignItems='center' justifyContent='center' height='100%' width='100%'>
					<SlaBadge sla={value} />
				</Box>
			),
			sortComparator: slaUtils.slaComparator,
			filterOperators: getEnhancedStringFilterOperators(t('filters.isAnyOf')),
			valueOptions: ['FAILED', 'SUCCESS', 'WARN'],
		},
		{
			field: 'name',
			headerName: t('values.element'),
			flex: 1,
			minWidth: 180,
			filterOperators: getEnhancedStringFilterOperators(t('filters.isAnyOf')),
			valueOptions: getValueOptions('name', items),
		},
		{
			field: 'parent',
			headerName: t('values.parent'),
			minWidth: 180,
			flex: 1,
			filterOperators: getEnhancedStringFilterOperators(t('filters.isAnyOf')),
			valueOptions: getValueOptions('parent', items),
		},
		{
			field: 'minDuration',
			headerName: t('values.minDuration'),
			type: 'number',
			filterOperators: numericOperators,
			valueGetter: (_, row) => formatFloatingNumber(row.statisticsValues.MINIMUM_DURATION),
		},
		{
			field: 'maxDuration',
			headerName: t('values.maxDuration'),
			type: 'number',
			filterOperators: numericOperators,
			valueGetter: (_, row) => formatFloatingNumber(row.statisticsValues.MAXIMUM_DURATION),
		},
		{
			field: 'avgDuration',
			headerName: t('values.avgDuration'),
			type: 'number',
			filterOperators: numericOperators,
			valueGetter: (_, row) => formatFloatingNumber(row.statisticsValues.AVERAGE_DURATION),
		},
		{
			field: 'count',
			headerName: t('values.count'),
			type: 'number',
			filterOperators: numericOperators,
			valueGetter: (_, row) => formatIntegerNumber(row.statisticsValues.ELEMENT_COUNT),
		},
		{
			field: 'errors',
			headerName: t('values.failureCount'),
			type: 'number',
			filterOperators: numericOperators,
			valueGetter: (_, row) => formatIntegerNumber(row.statisticsValues.ERROR_COUNT),
		},
		{
			field: 'errorRate',
			headerName: t('values.failureRate'),
			type: 'number',
			filterOperators: numericOperators,
			valueGetter: (_, row) => formatFloatingNumber(row.statisticsValues.ERROR_RATE),
		},
		{
			field: 'elementsPerSecond',
			headerName: t('values.elementPerSecond'),
			type: 'number',
			filterOperators: numericOperators,
			valueGetter: (_, row) => formatFloatingNumber(row.statisticsValues.ELEMENTS_PER_SECOND),
		},

		{
			field: 'minTtfb',
			headerName: t('values.minTtfb'),
			type: 'number',
			filterOperators: numericOperators,
			valueGetter: (_, row) => formatFloatingNumber(row.statisticsValues.MINIMUM_TTFB),
		},
		{
			field: 'avgTtfb',
			headerName: t('values.avgTtfb'),
			type: 'number',
			filterOperators: numericOperators,
			valueGetter: (_, row) => formatFloatingNumber(row.statisticsValues.AVERAGE_TTFB),
		},
		{
			field: 'maxTtfb',
			headerName: t('values.maxTtfb'),
			type: 'number',
			filterOperators: numericOperators,
			valueGetter: (_, row) => formatFloatingNumber(row.statisticsValues.MAXIMUM_TTFB),
		},
	];
	const percentile: GridColDef<ResultElementValue>[] = [
		{
			field: 'perc50',
			headerName: t('values.percentile50'),
			type: 'number',
			filterOperators: numericOperators,
			valueGetter: (_, row) => formatFloatingNumber(row.statisticsValues.DURATION_PERCENTILE_50),
		},
		{
			field: 'perc90',
			headerName: t('values.percentile90'),
			type: 'number',
			filterOperators: numericOperators,
			valueGetter: (_, row) => formatFloatingNumber(row.statisticsValues.DURATION_PERCENTILE_90),
		},
		{
			field: 'perc95',
			headerName: t('values.percentile95'),
			type: 'number',
			filterOperators: numericOperators,
			valueGetter: (_, row) => formatFloatingNumber(row.statisticsValues.DURATION_PERCENTILE_95),
		},
		{
			field: 'perc99',
			headerName: t('values.percentile99'),
			type: 'number',
			filterOperators: numericOperators,
			valueGetter: (_, row) => formatFloatingNumber(row.statisticsValues.DURATION_PERCENTILE_99),
		},
	];
	return category === 'transactions' ? [...base, ...percentile] : base;
};

export { ValuesDataGridColumns };

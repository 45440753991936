import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const populations = (state: RootState) => state.customPopulationReducer.populations;
export const getUserChanged = (state: RootState) => state.customPopulationReducer.isUserChanged;

export const getCustomPopulation = createSelector(
	[populations, (_populations, name: string) => name],
	(populations, name) => populations.find((s) => s.name === name),
);

export const getCustomPopulationNames = createSelector([populations], (populations) => populations.map((s) => s.name));

import {
	GridColDef,
	GridRenderCellParams,
	GridRowModel,
	GridRowSelectionModel,
	GridSortItem,
	useGridApiRef,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import { ComponentPropsWithoutRef, MutableRefObject, useEffect, useRef, useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { TFunction } from 'i18next';
import { DeletionPoliciesEmptyState } from './deletion-policies-empty-state';
import { usePoliciesDatagridActions } from './use-policies-datagrid-actions';
import { DeletionPolicyCreateModal } from './deletion-policy-create-modal';
import { getUsedFilters } from './deletion-policies-utils';
import { DEFAULT_GRID_PROPS, onColumnChange } from '../../../common/datagrid';
import { Datagrid } from '../../../common/data-grid/datagrid';
import { Spinner } from '../../../common/spinner';
import { CreateResourceButton } from '../../../common/button/create-resource-button';
import { ContextMenu, ContextMenuFuncts } from '../../../common/context-menu/context-menu';
import { filterToContextMenuItems } from '../../../common/data-grid/actions/data-grid-actions';
import { ActionsCellMenu } from '../../../common/data-grid/actions/actions-cell-menu';
import { useColumnsState } from '@neoload/hooks';
import {
	DeletionPoliciesPageRead,
	DeletionPolicy,
	DeletionPolicyRead,
	useGetV4DeletionPoliciesQuery,
} from '@neoload/api';
import { createNeoLoadError, timeUtils } from '@neoload/utils';

const columnsStateKey = 'DELETION_POLICIES_COLUMNS_STATE';

const defaultPage: DeletionPoliciesPageRead = {
	items: [],
	pageNumber: 0,
	pageSize: 0,
	total: 0,
};

type DeletionPoliciesPanelProps = {
	workspaceId: string;
};

type ModalState = { open: boolean; policyToEdit?: DeletionPolicyRead };

const getPolicyName = (policy: DeletionPolicyRead, t: TFunction) => {
	const deletionFilter = policy.deletionFilter;
	const deletionSetting = policy.deletionSetting;

	let filterDescription: string;
	switch (deletionFilter.type) {
		case 'TEST': {
			filterDescription = t('tabs.deletionPolicies.filter.test', {
				name: deletionFilter.testName,
			});
			break;
		}
		case 'PROJECT': {
			filterDescription = t('tabs.deletionPolicies.filter.projectAndScenario', {
				project: deletionFilter.projectName,
				scenario: deletionFilter.scenarioName,
			});
			break;
		}
		case 'RESULT_NAME': {
			filterDescription = t('tabs.deletionPolicies.filter.resultName', {
				name: deletionFilter.resultNamePattern,
			});
			break;
		}
	}

	let settingDescription: string;
	switch (deletionSetting.type) {
		case 'COUNT': {
			settingDescription = t('tabs.deletionPolicies.settings.count', {
				number: deletionSetting.retentionCount,
			});
			break;
		}
		case 'DURATION': {
			settingDescription = t('tabs.deletionPolicies.settings.duration', {
				days: timeUtils.asDays(deletionSetting.retentionDuration),
			});
			break;
		}
	}

	return `${filterDescription}${settingDescription}`;
};

const DeletionPoliciesPanel = ({ workspaceId }: DeletionPoliciesPanelProps) => {
	const [selectedPolicyId, setSelectedPolicyId] = useState<GridRowSelectionModel>([]);
	const { t } = useTranslation(['workspace']);
	const apiRef = useGridApiRef();
	const {
		data: deletionPolicyPage = defaultPage,
		isFetching,
		isLoading,
		error: resultsError,
	} = useGetV4DeletionPoliciesQuery({ workspaceId });
	const contextMenu: MutableRefObject<ContextMenuFuncts | undefined> = useRef();

	if (resultsError) {
		throw createNeoLoadError(resultsError);
	}
	const [isOpenOverlapAlert, setIsOpenOverlapAlert] = useState<boolean>(false);
	const [isOpenExecutionAlert, setIsOpenExecutionAlert] = useState<boolean>(true);
	const [modalState, setModalState] = useState<ModalState>({ open: false });

	const openEditModal = (id: string) => {
		const policyToEdit = deletionPolicyPage.items?.find((item) => item.id === id);
		setModalState({ open: true, policyToEdit: policyToEdit });
	};

	const actions = usePoliciesDatagridActions(selectedPolicyId, openEditModal);

	const openCreateModal = () => {
		setModalState({ open: true });
	};

	const closeModal = () => {
		setModalState({ open: false });
	};

	useEffect(() => {
		setIsOpenOverlapAlert(deletionPolicyPage.total > 1);
	}, [deletionPolicyPage.total]);

	const columns: GridColDef<DeletionPolicy>[] = [
		{
			field: 'policyName',
			headerName: t('tabs.deletionPolicies.grid.name'),
			flex: 1,
			valueGetter: (_, row) => getPolicyName(row, t),
		},
		{
			field: 'updatedAt',
			headerName: t('tabs.deletionPolicies.grid.lastModified'),
			minWidth: 250,
			valueFormatter: (value: string) => timeUtils.dateTimeAbsolute(value),
		},
		{ field: 'userModifierName', headerName: t('tabs.deletionPolicies.grid.lastModifiedBy'), minWidth: 250 },
		{
			field: 'actions',
			renderCell: (params: GridRenderCellParams) => <ActionsCellMenu actions={actions} rowData={params.row} />,
			resizable: false,
			disableReorder: true,
			type: 'actions',
		},
	];

	const defaultGridSort: GridSortItem = { field: 'updatedAt', sort: 'desc' };
	const initialState = {
		sorting: {
			sortModel: [defaultGridSort],
		},
	};

	const { updatedInitialState, updatedColumns, storeColumnState } = useColumnsState(
		columnsStateKey,
		initialState,
		columns,
		apiRef,
	);

	if (isLoading) {
		return <Spinner />;
	}

	const componentsProps: { toolbar: ComponentPropsWithoutRef<typeof Toolbar>; row: GridRowModel } = {
		toolbar: {
			hideFiltersIcon: true,
			displayColumnOptions: false,
			title: t('tabs.deletionPolicies.title'),
		},
		row: {
			onContextMenu: contextMenu.current?.openContextMenu,
		},
	};

	return (
		<>
			<DeletionPolicyCreateModal
				workspaceId={workspaceId}
				onClose={closeModal}
				open={modalState.open}
				policyToEdit={modalState.policyToEdit}
				{...getUsedFilters(deletionPolicyPage.items)}
			/>
			{deletionPolicyPage.total === 0 && !isFetching ? (
				<DeletionPoliciesEmptyState createAction={() => openCreateModal()} />
			) : (
				<Stack height='100%'>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 2,
							margin: 2,
						}}
					>
						<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
							<Typography variant='subtitle1'> {t('tabs.deletionPolicies.title')} </Typography>
							<CreateResourceButton sx={{ alignSelf: 'flex-end' }} onClick={() => openCreateModal()}>
								{t('tabs.deletionPolicies.createButton')}
							</CreateResourceButton>
						</Box>
						{isOpenExecutionAlert && (
							<Alert
								severity='info'
								onClose={() => {
									setIsOpenExecutionAlert(false);
								}}
							>
								{t('tabs.deletionPolicies.alertExecution')}
							</Alert>
						)}
						{isOpenOverlapAlert && (
							<Alert
								severity='warning'
								onClose={() => {
									setIsOpenOverlapAlert(false);
								}}
							>
								{t('tabs.deletionPolicies.alertOverlapping')}
							</Alert>
						)}
					</Box>
					<Datagrid
						{...DEFAULT_GRID_PROPS}
						{...onColumnChange(storeColumnState)}
						apiRef={apiRef}
						checkboxSelection={false}
						rows={deletionPolicyPage.items}
						loading={isFetching}
						getRowId={(row) => row.id}
						initialState={updatedInitialState}
						columns={updatedColumns}
						onRowSelectionModelChange={(model) => setSelectedPolicyId(model)}
						slotProps={componentsProps}
						disableRowSelectionOnClick={false}
					/>
					<ContextMenu apiRef={apiRef} ref={contextMenu} contextMenuItemsList={filterToContextMenuItems(actions)} />
				</Stack>
			)}
		</>
	);
};

export { DeletionPoliciesPanel };

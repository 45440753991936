import { FieldError, FieldErrorsImpl, Merge, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import { MAXIMUM_SIZING, MINIMUM_SIZING } from './dynamic-zone-utils';
import { NumberTextfieldWithoutSpinners } from '../../../common/number-textfield-without-spinners';
import { CustomSizing, DynamicZoneFields, FixedSizing } from '@neoload/api';

export type SizingTextfieldProps = {
	propertyName: keyof Omit<CustomSizing, 'type'>;
	fieldType: 'MEMORY' | 'CPU';
};

type MergedErrorType<T> = Merge<FieldError, FieldErrorsImpl<NonNullable<T>>>;
type CustomSizingErrorType = MergedErrorType<CustomSizing>;
type SizingErrorType = MergedErrorType<FixedSizing | CustomSizing>;

function isCustomSizingTypeError(
	propertyKey: keyof Omit<CustomSizing, 'type'>,
	sizingErrors: SizingErrorType
): sizingErrors is CustomSizingErrorType {
	return propertyKey in sizingErrors;
}

/**
 * Get the error message for a specific property of the sizing object
 * Union types are not well handled in react use form's Field errors, see https://github.com/react-hook-form/react-hook-form/issues/9287
 * Needs some manual type assertions to get the error message in a type safe way
 * @param propertyKey the property key to get the error message for, which is a property of CustomSizing
 * @param sizingErrors the sizing errors object, as returned by react-hook-form
 */
export const getCustomErrorFromProperty = (
	propertyKey: keyof Omit<CustomSizing, 'type'>,
	sizingErrors?: SizingErrorType
) => {
	if (sizingErrors && isCustomSizingTypeError(propertyKey, sizingErrors)) {
		return sizingErrors[propertyKey]?.message;
	}
};

export const SizingTextfield = ({ propertyName, fieldType }: SizingTextfieldProps) => {
	const {
		register,
		formState: { errors },
	} = useFormContext<DynamicZoneFields>();
	const { t } = useTranslation(['zone']);
	const minimum = MINIMUM_SIZING[propertyName];
	const maximum = MAXIMUM_SIZING[propertyName];

	const textFieldError = getCustomErrorFromProperty(propertyName, errors.sizing);

	return (
		<NumberTextfieldWithoutSpinners
			size='small'
			type='number'
			label={t(`sizes.${fieldType}`)}
			required
			helperText={textFieldError}
			{...register(`sizing.${propertyName}`, {
				setValueAs: (value) => Number.parseFloat(value),
				validate: (value) => {
					if (fieldType === 'MEMORY' && !Number.isInteger(value)) {
						return t('sizes.integerError');
					}
					if (fieldType === 'CPU' && !/^\d+(\.\d{1,6})?$/.test(value.toString())) {
						return t('sizes.floatError');
					}
					return true;
				},
				required: {
					value: true,
					message: t('common:input.required'),
				},
				min: {
					value: minimum,
					message: t('common:input.minimum', {
						min: minimum,
					}),
				},
				max: {
					value: maximum,
					message: t('common:input.maximum', {
						max: maximum,
					}),
				},
			})}
			error={!!textFieldError}
			InputProps={{
				endAdornment: <InputAdornment position='end'>{fieldType === 'MEMORY' && t('sizes.mb')}</InputAdornment>,
			}}
			// eslint-disable-next-line @typescript-eslint/naming-convention
			inputProps={{ 'data-testid': `${propertyName}-input`, maxLength: 128, step: 'any' }}
		/>
	);
};

import Stack from '@mui/material/Stack';
import { ErrorPage } from './error-page';

const GlobalErrorPage = () => (
	<Stack sx={{ height: '100%', width: '100%' }} alignSelf='center' justifyContent='center'>
		<ErrorPage />
	</Stack>
);

export { GlobalErrorPage };

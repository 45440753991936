import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import IconDistributionConstantOutlined from '@tricentis/aura/components/IconDistributionConstantOutlined.js';
import IconDistributionRampUpOutlined from '@tricentis/aura/components/IconDistributionRampUpOutlined.js';
import { memo } from 'react';
import deepEqual from 'deep-equal';
import { StepRampUp } from './step-ramp-up';
import { RampUp } from './ramp-up';
import { CustomScenariosVu } from './custom-scenarios-vu';
import {
	CustomPopulation,
	ExecutionPolicies,
	PartialCustomPopulation,
	RampUpRange,
	VuDistributions,
} from '@neoload/api';
import { useCustomScenario } from '@neoload/hooks';

type VusDistributionProps = {
	population: CustomPopulation;
};

const shouldUpdate = (prevProps: VusDistributionProps, nextProps: VusDistributionProps) =>
	prevProps.population.name === nextProps.population.name &&
	prevProps.population.executionPolicy === nextProps.population.executionPolicy &&
	prevProps.population.stepRampUp === nextProps.population.stepRampUp &&
	prevProps.population.vus === nextProps.population.vus &&
	prevProps.population.vuDistribution === nextProps.population.vuDistribution &&
	((prevProps.population.vuDistribution === VuDistributions.RAMPUP &&
		nextProps.population.vuDistribution === VuDistributions.RAMPUP &&
		deepEqual(prevProps.population.rampUp, nextProps.population.rampUp)) ||
		prevProps.population.vuDistribution === VuDistributions.CONSTANT);

const VusDistribution = memo(
	({ population }: VusDistributionProps) => {
		const { t } = useTranslation(['test']);
		const { updatePopulation } = useCustomScenario();

		const displayDistributionView = (value: VuDistributions) => {
			const partialPopulation: PartialCustomPopulation = {
				vuDistribution: value,
				...(value === VuDistributions.RAMPUP
					? {
							rampUp: {
								users: 1,
								every: {
									amount: 1,
									unit:
										population.executionPolicy === ExecutionPolicies.DURATION
											? RampUpRange.MINUTES
											: RampUpRange.ITERATIONS,
								},
							},
						}
					: {}),
			};

			updatePopulation(population.name, partialPopulation, true);
		};

		return (
			<Stack>
				<Typography variant='body1'>{t('configuration.scenarios.custom.vusDistribution')}</Typography>
				<RadioGroup
					row
					value={population.vuDistribution}
					onChange={(_, value) => displayDistributionView(value as VuDistributions)}
					sx={{
						marginBottom: 2,
						marginLeft: 2,
					}}
				>
					<FormControlLabel
						value={VuDistributions.CONSTANT}
						control={<Radio />}
						label={
							<Stack direction='row' useFlexGap gap={1}>
								<IconDistributionConstantOutlined /> {t('configuration.scenarios.custom.constant')}
							</Stack>
						}
						data-trackingid='custom-scenario-vu-distribution-constant'
					/>
					<FormControlLabel
						value={VuDistributions.RAMPUP}
						control={<Radio />}
						label={
							<Stack direction='row' useFlexGap gap={1}>
								<IconDistributionRampUpOutlined /> {t('configuration.scenarios.custom.rampUp')}
							</Stack>
						}
						data-trackingid='custom-scenario-vu-distribution-rampup'
					/>
				</RadioGroup>
				<Stack useFlexGap gap={2}>
					<CustomScenariosVu population={population} />
					{population.vuDistribution === VuDistributions.CONSTANT ? (
						<StepRampUp population={population} />
					) : (
						<>
							<StepRampUp population={population} />
							<RampUp population={population} />
						</>
					)}
				</Stack>
			</Stack>
		);
	},
	(prevProps, nextProps) => shouldUpdate(prevProps, nextProps),
);

export { VusDistribution };

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { InvalidPropertyValueErrorDescription } from '../nlw-api-v4';

const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError =>
	typeof error === 'object' && error != null && 'status' in error;

const isInvalidPropertyErrorMatching = (
	apiError: InvalidPropertyValueErrorDescription,
	type: string,
	property?: string,
	value?: string,
) =>
	(apiError.validationType === type && property === undefined) ||
	(apiError.validationType === type &&
		property &&
		property in apiError.messageProperties &&
		apiError.messageProperties[property] === value);

export { isFetchBaseQueryError, isInvalidPropertyErrorMatching };

import IconButton from '@mui/material/IconButton';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { DatagridSingleItemAction } from './data-grid-actions';
const ActionsCellItem = ({ icon, text, disabled, action, sx, hidden }: DatagridSingleItemAction) => {
	if (hidden) {
		return null;
	}
	return (
		<Tooltip title={text}>
			<span>
				<IconButton
					disabled={disabled}
					sx={sx}
					aria-label={text}
					onClick={(event: React.MouseEvent<HTMLButtonElement>) => action(event)}
					onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
						event.preventDefault();
					}}
				>
					{icon}
				</IconButton>
			</span>
		</Tooltip>
	);
};

export { ActionsCellItem };

import { ReactNode, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import IconInfoOutlined from '@tricentis/aura/components/IconInfoOutlined.js';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import OpenInNewOutlined from '@mui/icons-material/OpenInNewOutlined';
import Stack from '@mui/material/Stack';

type InputWithInfoIconProps = {
	text: string;
	url: string;
	children?: ReactNode;
};

export const InputWithInfoIcon = ({ text, url, children }: InputWithInfoIconProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const theme = useTheme();
	const { t } = useTranslation('infrastructureProviders');

	const onMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const close = () => setAnchorEl(null);

	return (
		<>
			<Stack direction='row' alignItems='center' gap={1}>
				{children}
				<Grid onMouseEnter={onMouseEnter}>
					<IconInfoOutlined sx={{ color: theme.palette.action.active, fontSize: '1em' }} />
				</Grid>
			</Stack>
			<Popover
				open={anchorEl !== null}
				anchorEl={anchorEl}
				onClose={close}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 0,
				}}
			>
				<Stack onMouseLeave={close} sx={{ margin: 2, gap: 1 }}>
					<Typography title={text} variant='h6'>
						{text}
					</Typography>
					<Link href={url} underline='none' target='_blank' alignItems='center'>
						<Button endIcon={<OpenInNewOutlined />}>{t('modal.learnMore')}</Button>
					</Link>
				</Stack>
			</Popover>
		</>
	);
};

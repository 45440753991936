import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { SubscriptionSaasPlan } from '@neoload/ui';

const SubscriptionPage = () => {
	const { t } = useTranslation(['subscription']);

	return (
		<Stack height='100%' width='100%'>
			<Stack sx={{ position: 'sticky' }}>
				<Typography variant='subtitle1' p={2}>
					{t('title')}
				</Typography>
			</Stack>
			<Divider />
			<Box sx={{ height: '100%', overflowY: 'auto' }}>
				<SubscriptionSaasPlan />
			</Box>
		</Stack>
	);
};

export { SubscriptionPage };

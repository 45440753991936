import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import OpenInNewOutlined from '@mui/icons-material/OpenInNewOutlined';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import CheckOutlined from '@mui/icons-material/CheckOutlined';
import { DOCUMENTATION_LINK, PAYLOAD_VARIABLES } from './webhook-helper-constants';

export const PayloadHelperCustomTooltip = () => {
	const { t } = useTranslation('workspace', { keyPrefix: 'tabs.webhooks' });
	const theme = useTheme();
	return (
		<Paper elevation={5} sx={{ width: '440px' }}>
			<Box padding={2} gap={2} display='flex' flexDirection='column'>
				<Typography variant='subtitle1' title={t('availableVariables')}>
					{t('availableVariables')}
				</Typography>
				<TableContainer sx={{ maxHeight: '300px', border: `1px solid ${theme.palette.divider}` }}>
					<Table size='small' stickyHeader sx={{ borderRight: `1px solid ${theme.palette.divider}` }}>
						<TableHead>
							<TableRow>
								<TableCell>{t('variable')}</TableCell>
								<TableCell>{t('testStart')}</TableCell>
								<TableCell>{t('testEnd')}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{PAYLOAD_VARIABLES.map((variable) => (
								<TableRow key={variable.name}>
									<TableCell>{variable.name}</TableCell>
									<TableCell align='center'>
										{variable.availableForTestStart && <CheckOutlined fontSize='small' />}
									</TableCell>
									<TableCell align='center'>
										{variable.availableForTestEnd && <CheckOutlined fontSize='small' />}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<Link href={DOCUMENTATION_LINK} underline='none' target='_blank' alignItems='center'>
					<Button endIcon={<OpenInNewOutlined />}>{t('learnMore')}</Button>
				</Link>
			</Box>
		</Paper>
	);
};

import Button, { ButtonProps } from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';

type CreateResourceButtonProps = Omit<ButtonProps, 'startIcon'> & {
	isLoading?: boolean;
};

const CreateResourceButton = ({ isLoading = false, ...buttonProps }: CreateResourceButtonProps) => (
	<Button
		{...buttonProps}
		size='small'
		startIcon={isLoading ? <CircularProgress size={24.5} color='inherit' /> : <AddIcon />}
		variant={buttonProps.variant ?? 'contained'}
		color={buttonProps.color ?? 'primary'}
	/>
);

export { CreateResourceButton };

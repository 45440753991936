import { useTheme } from '@mui/material';

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { ComparisonState, Direction } from './table-grid';

export type ComparisonCellProps = {
	value: string;
	delta: string;
	direction: Direction;
	state: ComparisonState;
	align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

const formatDelta = (
	delta: string,
	direction: Direction,
	state: ComparisonState,
	theme: Theme,
	align: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined
) => {
	let formattedDelta = delta;
	let color: string;

	if (state === 'BETTER') {
		color = theme.palette.success.main;
	} else if (state === 'LESSER') {
		color = theme.palette.error.main;
	} else {
		color = theme.palette.text.secondary;
	}

	if (direction === 'UP') {
		formattedDelta = delta + '↑';
	}
	if (direction === 'DOWN') {
		formattedDelta = delta + '↓';
	}

	return (
		<Typography variant='caption' color={color} align={align}>
			{formattedDelta}
		</Typography>
	);
};

export const ComparisonCell = ({ value, delta, direction, state, align }: ComparisonCellProps) => {
	const theme = useTheme();

	return (
		<>
			<Typography variant='body2' align={align}>
				{value}
			</Typography>
			{delta && formatDelta(delta, direction, state, theme, align)}
		</>
	);
};

import { useTranslation } from 'react-i18next';
import { ComponentPropsWithoutRef, useState } from 'react';
import { GridCellParams, GridInitialState, GridOverlay, useGridApiRef } from '@mui/x-data-grid-pro';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import Typography from '@mui/material/Typography';
import { useLeasesColumns } from './leases-columns';
import { LeaseDrawerContent } from './leases-drawer-content';
import { LeaseDatagridMainActionPanel } from './lease-datagrid-main-action-panel';
import { DatagridWithDrawer } from '../../../../common/data-grid/datagrid-with-drawer';
import { onColumnChange } from '../../../../common/datagrid';
import { Spinner } from '../../../../common/spinner';
import { Lease, useGetV4LicenseLeasesQuery } from '@neoload/api';
import { useColumnsState } from '@neoload/hooks';

type LeasesDatagridProps = {
	workspaceId?: string;
	isAdmin: boolean;
};
const columnsStateKey = 'LEASES_COLUMNS_STATE';

const LeasesDatagrid = ({ workspaceId, isAdmin }: LeasesDatagridProps) => {
	const { t } = useTranslation(['workspace'], { keyPrefix: 'tabs.subscription.leases' });
	const {
		data: leases,
		isLoading: isLeasesLoading,
		isFetching: leasesFetching,
	} = useGetV4LicenseLeasesQuery({
		workspaceId,
	});

	const [selectedLease, setSelectedLease] = useState<Lease>();

	const componentsProps: { toolbar: ComponentPropsWithoutRef<typeof Toolbar> } = {
		toolbar: {
			displayColumnOptions: false,
			hideFiltersIcon: true,
			title: t('name'),
			mainActions: workspaceId
				? [
						{
							children: <LeaseDatagridMainActionPanel isAdmin={isAdmin} workspaceId={workspaceId} />,
						},
					]
				: [],
		},
	};

	const initialState: GridInitialState = {
		sorting: {
			sortModel: [{ field: 'name', sort: 'asc' }],
		},
	};
	const columns = useLeasesColumns(workspaceId);
	const apiRef = useGridApiRef();

	const { updatedInitialState, updatedColumns, storeColumnState } = useColumnsState(
		columnsStateKey,
		initialState,
		columns,
		apiRef,
	);

	if (isLeasesLoading) {
		return <Spinner />;
	}
	const drawerProps: ComponentPropsWithoutRef<typeof DatagridWithDrawer>['drawer'] = {
		onOpen: (params: GridCellParams) => {
			setSelectedLease(params.row);
		},
		onClose: () => setSelectedLease(undefined),
		title: t('details.title'),
		selectedRowId: selectedLease?.id,
		minWidth: 400,
	};
	const getNoRowsOverlay = () => (
		<GridOverlay sx={{ backgroundColor: 'transparent' }}>
			<Typography variant='bodyRegular' sx={{ textAlign: 'center' }}>
				{t('noLease')}
			</Typography>
		</GridOverlay>
	);
	return (
		<DatagridWithDrawer
			sortingOrder={['desc', 'asc']}
			loading={leasesFetching}
			pagination={false}
			disableColumnMenu={true}
			disableRowSelectionOnClick={true}
			{...onColumnChange(storeColumnState)}
			apiRef={apiRef}
			checkboxSelection={false}
			columns={updatedColumns}
			rows={leases?.items ?? []}
			initialState={updatedInitialState}
			slotProps={componentsProps}
			slots={{
				toolbar: Toolbar,
				noRowsOverlay: getNoRowsOverlay,
			}}
			drawer={drawerProps}
		>
			{selectedLease && <LeaseDrawerContent lease={selectedLease} />}
		</DatagridWithDrawer>
	);
};

export { LeasesDatagrid };

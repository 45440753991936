import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { InputOffset } from '../../../../../common/input/input-offset';
import { WebhookFilters } from '@neoload/api';

export const DurationValueInput = () => {
	const { t } = useTranslation('workspace', { keyPrefix: 'tabs.webhooks.filters' });
	const { setValue, watch } = useFormContext<WebhookFilters>();

	const value = watch('byDuration.value');

	return (
		<InputOffset
			value={value}
			textFieldProps={{
				label: t('value'),
				size: 'small',
				required: true,
			}}
			onChange={(value) => setValue('byDuration.value', value)}
			includeMillis={false}
		/>
	);
};

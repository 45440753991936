import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useFormContext, useWatch } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { EmptyProject } from './empty-project';
import { getReadableSize } from './utils';
import { ModalGit } from './modal-git';
import { ButtonSelectionFile } from './buttons/button-selection-file';
import { ButtonSelectionGit } from './buttons/button-selection-git';
import { ChipProjectProtected } from './protected/chip-project-protected';
import { ModalPassword } from './protected/modal-password';
import { TestConfiguration } from '../test-configuration';
import { ConfigurationFormData } from '../types';
import { timeUtils } from '@neoload/utils';
import { useGetV4InformationQuery } from '@neoload/api';

const Project = () => {
	const { t } = useTranslation(['test']);
	const { data: information, isLoading: isInformationLoading } = useGetV4InformationQuery();
	const { setValue, getValues } = useFormContext<ConfigurationFormData>();
	const projectName = useWatch<ConfigurationFormData, 'project.name'>({ name: 'project.name' });
	const repositoryName = useWatch<ConfigurationFormData, 'project.repositoryName'>({ name: 'project.repositoryName' });
	const repositoryId = useWatch<ConfigurationFormData, 'project.repositoryId'>({ name: 'project.repositoryId' });
	const reference = useWatch<ConfigurationFormData, 'project.reference'>({ name: 'project.reference' });
	const projectSize = useWatch<ConfigurationFormData, 'project.size'>({ name: 'project.size' });
	const projectUpdatedAt = useWatch<ConfigurationFormData, 'project.updatedAt'>({ name: 'project.updatedAt' });
	const projectUserModifierName = useWatch<ConfigurationFormData, 'project.userModifierName'>({
		name: 'project.userModifierName',
	});
	const projectSource = useWatch<ConfigurationFormData, 'project.source'>({ name: 'project.source' });
	const disabled = useWatch<ConfigurationFormData, 'configurationDisabled'>({ name: 'configurationDisabled' });
	const securityStatus = useWatch<ConfigurationFormData, 'project.securityStatus'>({ name: 'project.securityStatus' });

	const [showGitModal, setShowGitModal] = useState(false);
	const [showPasswordModal, setShowPasswordModal] = useState(false);

	const isProjectGit = projectSource === 'GIT';
	const getTypeUploaded = () =>
		isProjectGit ? t('configuration.project.sourceGit') : t('configuration.project.sourceLocal');

	useEffect(() => {
		if (information) {
			setValue('projectMaxSize', information?.projectMaxSize);
			setValue('zones.maximumLgCountPerProjectSize', information?.maximumLgCountPerProjectSize);
		}
	}, [information, setValue]);

	const header = (
		<Stack flexDirection='row' sx={{ gap: 3, alignItems: 'center' }}>
			<Typography variant='subtitle1'>{t('configuration.project.title')}</Typography>
			{!disabled && securityStatus !== 'DISABLED' && (
				<ChipProjectProtected openModal={() => setShowPasswordModal(true)} />
			)}
		</Stack>
	);

	const buttonActions = (
		<>
			{securityStatus === 'INVALID_PASSWORD' && (
				<Button variant='outlined' onClick={() => setShowPasswordModal(true)}>
					{t('configuration.project.enterPassword')}
				</Button>
			)}
			{isProjectGit ? (
				<ButtonSelectionGit isInformationLoading={isInformationLoading} openGitModal={() => setShowGitModal(true)} />
			) : (
				<ButtonSelectionFile isInformationLoading={isInformationLoading} openGitModal={() => setShowGitModal(true)} />
			)}
		</>
	);

	return (
		<>
			<TestConfiguration name={header} actions={!disabled && buttonActions}>
				{disabled ? (
					<EmptyProject />
				) : (
					<Box sx={{ paddingTop: 2, paddingRight: 2, paddingBottom: 2, gap: 2 }}>
						<Stack useFlexGap flexDirection='row' sx={{ paddingRight: 2, gap: 5 }}>
							<ItemProject name={t('configuration.project.project')} value={projectName} />
							<ItemProject
								name={t('configuration.project.size')}
								value={projectSize ? getReadableSize(t, projectSize, 2) : undefined}
							/>
							<ItemProject
								name={t('configuration.project.uploaded')}
								value={timeUtils.dateRelative(projectUpdatedAt)}
								title={timeUtils.dateTimeAbsolute(projectUpdatedAt)}
							/>
							<ItemProject name={t('configuration.project.uploadedBy')} value={projectUserModifierName} />
							<ItemProject name={t('configuration.project.source')} value={getTypeUploaded()} />
							{repositoryName && (
								<ItemProject name={t('configuration.project.git.reference')} value={`${repositoryName}/${reference}`} />
							)}
						</Stack>
					</Box>
				)}
			</TestConfiguration>
			{showGitModal && (
				<ModalGit
					close={() => setShowGitModal(false)}
					testId={getValues('test.id')}
					configuredRepositoryId={repositoryId}
				/>
			)}
			{showPasswordModal && <ModalPassword testId={getValues('test.id')} close={() => setShowPasswordModal(false)} />}
		</>
	);
};

type ItemProjectProps = {
	name: string;
	value: string | undefined;
	title?: string;
};

const ItemProject = ({ name, value, title }: ItemProjectProps) => (
	<Stack gap={1}>
		<Typography variant='body2' color='text.secondary'>
			{name}
		</Typography>
		{value ? (
			<Typography variant='body1' title={title}>
				{value}
			</Typography>
		) : (
			<Typography variant='body2' color='text.secondary'>
				—
			</Typography>
		)}
	</Stack>
);

export { Project };

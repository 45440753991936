import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMeasure } from 'react-use';
import TableSortLabel from '@mui/material/TableSortLabel';
import { SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SlaBadge } from './sla-badge';
import { ComparisonCell } from './comparison-cell';

/**
 * Small/Dense Table with responsive capabilities (dynamic font size based on parent component size).
 * Each cell can be either text, or status.
 */

export type Column = {
	label: string;
	align?: TableCellProps['align'];
	maxWidth?: number;
};

export type Status = 'SUCCESS' | 'WARN' | 'FAILED';
export type Direction = 'UP' | 'DOWN' | 'NONE';
export type ComparisonState = 'BETTER' | 'LESSER' | 'EQUALS';
export type BaseCell = {
	type?: 'status' | 'comparison';
};

export type TextCell = BaseCell & {
	text: string;
};

export type StatusCell = BaseCell & {
	type: 'status';
	status: Status;
};

export type ComparisonDataCell = BaseCell & {
	type: 'comparison';
	value: string;
	delta: string;
	direction: Direction;
	state: ComparisonState;
	align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export type Cell = TextCell | StatusCell | ComparisonDataCell;

export type SortDirection = 'asc' | 'desc';

export type TableGridProps = {
	columns: Column[];
	sortColumnIndex?: number;
	sortDirection?: SortDirection;
	values: Cell[][];
};

const getStyle = (width: number, maxWidth?: number): SxProps => ({
	padding: getPadding(width),
	fontSize: getFontSize(width),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	maxWidth: maxWidth,
});

const XS_WIDTH_BREAKPOINT = 350;
const SM_WIDTH_BREAKPOINT = 650;

const getPadding = (width: number): string => {
	if (width < XS_WIDTH_BREAKPOINT) {
		return '1px';
	} else if (width < SM_WIDTH_BREAKPOINT) {
		return '2px';
	}
	return '3px';
};

const getFontSize = (width: number): number => {
	if (width < XS_WIDTH_BREAKPOINT) {
		return 10;
	} else if (width < SM_WIDTH_BREAKPOINT) {
		return 11;
	}
	return 12;
};

function renderTableRow(columns: Column[], cells: Cell[], fontSize: number) {
	const row = [];
	for (const [index, cell] of cells.entries()) {
		row.push(
			<TableCell sx={{ ...getStyle(fontSize, columns[index].maxWidth) }} align={columns[index].align}>
				{renderCellContent(cell)}
			</TableCell>
		);
	}
	return row;
}

function renderCellContent(cell: Cell) {
	if (isSlaStatusCell(cell)) {
		return <SlaBadge size='body2' sla={cell.status} />;
	} else if (isComparisonCell(cell)) {
		return (
			<ComparisonCell
				value={cell.value}
				delta={cell.delta}
				direction={cell.direction}
				state={cell.state}
				align={cell.align}
			/>
		);
	}
	return cell.text;
}

const isSlaStatusCell = (cell: Cell): cell is StatusCell => cell.type === 'status';
const isComparisonCell = (cell: Cell): cell is ComparisonDataCell => cell.type === 'comparison';

const ZeroState = () => {
	const { t } = useTranslation(['common']);
	return (
		<Box
			sx={{
				width: '100%',
				height: '50%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Typography variant='body2'>{t('zeroState.noResultsFound')}</Typography>
		</Box>
	);
};

export const TableGrid = ({ columns, values, sortColumnIndex, sortDirection }: TableGridProps) => {
	const [ref, { width }] = useMeasure<HTMLDivElement>();
	return (
		<TableContainer
			ref={ref}
			sx={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				padding: (theme) => theme.spacing(1),
			}}
		>
			<Table size='small'>
				<TableHead>
					<TableRow>
						{columns.map((column, index) => (
							<TableCell sx={{ ...getStyle(width, column.maxWidth) }} key={column.label + index} align={column.align}>
								<TableSortLabel active={sortColumnIndex === index} direction={sortDirection} disabled={true}>
									<Typography sx={{ ...getStyle(width, column.maxWidth) }}>{column.label}</Typography>
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{values.map((value, index) => (
						<TableRow key={index} sx={{ border: 0 }}>
							{renderTableRow(columns, value, width)}
						</TableRow>
					))}
				</TableBody>
			</Table>
			{values.length === 0 && <ZeroState />}
		</TableContainer>
	);
};

import { useTranslation } from 'react-i18next';
import ZeroState from '@tricentis/aura/components/ZeroState.js';
import { useContext } from 'react';
import Typography from '@mui/material/Typography';
import {
	ElementContext,
	MonitorContext,
	ValuesComparisonIdentifier,
	useValuesDataFetcher,
} from './hooks/use-values-data-fetcher';
import { FetchError } from './hooks/types';
import { Column, ComparisonDataCell, ComparisonState, TableGrid } from '../../../common/table-grid';
import ValueComparisonTableZeroState from '../../../../assets/ValueComparisonTableZeroState.svg';
import { CsvDownloadContext } from '../tiles/tile/csv-download-context';
import {
	GetResultElementValuesResponse,
	MonitorComparisonRow,
	UserPathElementValueStatistic,
	ValueComparisonRow,
	ValuesComparisonColumn,
	ValuesComparisonDashboardTile,
	MonitorsValuesPage,
	ValuesComparisonIdMapping,
} from '@neoload/api';
import { getAllXxxId, getAllXxxIdByElementType, isAnAllXxxElement, numberUtils } from '@neoload/utils';

type MonitorValueStatistic = MonitorComparisonRow['statistic'];
type ValuesComparisonFetcherProps = {
	valuesComparisonTile: ValuesComparisonDashboardTile;
};
type ColumnId = ValuesComparisonIdentifier;

type FetchResult = ErrorFetchResult | ElementValuesFetchResult | MonitorValuesFetchResult;

export type ErrorFetchResult = { id: ValuesComparisonIdentifier; error: FetchError };

export type ElementValuesFetchResult = {
	id: ValuesComparisonIdentifier;
	data: GetResultElementValuesResponse | undefined;
	context: ElementContext;
};

export type MonitorValuesFetchResult = {
	id: ValuesComparisonIdentifier;
	data: MonitorsValuesPage | undefined;
	context: MonitorContext;
};

const emptyValue = '';

const displayEmptyState = (valuesComparisonTile: ValuesComparisonDashboardTile) =>
	valuesComparisonTile.columns.length === 0 && valuesComparisonTile.rows.length === 0;

export const ValuesComparisonFetcher = ({ valuesComparisonTile }: ValuesComparisonFetcherProps) => {
	const { t, i18n } = useTranslation(['dashboard']);
	const [results, loadingState] = useValuesDataFetcher(valuesComparisonTile, true);
	const { setDataToDownload } = useContext(CsvDownloadContext);

	if (displayEmptyState(valuesComparisonTile)) {
		return (
			<ZeroState
				containerSx={{
					maxWidth: '600px',
					height: '100%',
					gap: 1,
					whiteSpace: 'pre-wrap',
					backgroundColor: 'transparent',
				}}
				illustration={
					<img
						src={ValueComparisonTableZeroState}
						alt={`${t('valuesComparison.zeroStateContentFirstLine')}\n${t('valuesComparison.zeroStateContentSecondLine')}`}
					/>
				}
				title={emptyValue}
			>
				<>
					<Typography sx={{ marginX: 'auto', width: 'fit-content', fontWeight: 'bold' }} variant='body2'>
						{t('valuesComparison.zeroStateContentFirstLine')}
					</Typography>
					<Typography sx={{ marginX: 'auto', width: 'fit-content' }} variant='body2'>
						{t('valuesComparison.zeroStateContentSecondLine')}
					</Typography>
				</>
			</ZeroState>
		);
	} else {
		const columns = getColumns(t('valuesComparison.columns.metricHeader'), valuesComparisonTile.columns);
		const values = getValues(valuesComparisonTile, results, i18n.language);

		if (loadingState === 'LOADED') {
			setDataToDownload({ columns, values });
		}

		return <TableGrid columns={columns} values={values} />;
	}
};

const getValues = (
	tile: ValuesComparisonDashboardTile,
	fetchResults: readonly FetchResult[],
	language: string,
): ComparisonDataCell[][] => {
	const resultIds = tile.columns.map((column) => ({ resultId: column.resultId, intervalId: column.intervalId }));

	return tile.rows.map((row) => {
		const mapping = tile.idMapping.find((mapping) => mapping.path.join('>') === row.path.join('>'));

		let userPath = undefined;
		if (row.rowType === 'ELEMENT') {
			userPath = row.userPathId;
		}

		return extractRow(row, resultIds, mapping, fetchResults, language, tile.differenceType, userPath);
	});
};

const extractRow = (
	row: ValueComparisonRow,
	columnIds: ColumnId[],
	mapping: ValuesComparisonIdMapping | undefined,
	fetchResults: readonly FetchResult[],
	language: string,
	differenceType: ValuesComparisonDashboardTile['differenceType'],
	userPath?: string,
): ComparisonDataCell[] => {
	const cell: ComparisonDataCell = {
		type: 'comparison',
		value: row.rowName,
		delta: '',
		direction: 'NONE',
		state: 'EQUALS',
		align: 'left',
	};

	const extractedRow = [cell];

	let referenceValue = undefined;
	let referenceValueInitialized = false;

	for (const columnId of columnIds) {
		let itemId = mapping?.metricIdByItemIds[columnId.resultId];
		if (itemId === undefined && isAnAllXxxElement(row.path)) {
			itemId = getAllXxxId(row.path.at(-1));
		}

		const result = fetchResults
			// First we filter on resultId, intervalId and userPath ...
			.filter((fetchResult) => fetchResult.id.resultId === columnId.resultId)
			.filter((fetchResult) => fetchResult.id.intervalId === columnId.intervalId)
			.filter((fetchResult) => fetchResult.id.userPath === userPath)
			// ... then we filter on the rowType, verifying that the result has a context (does not exist on errors) ...
			.filter((fetchResult) => 'context' in fetchResult && fetchResult.context.rowType === row.rowType)
			.find((fetchResult) =>
				// ... at this step we can still have more than one result if we requested all-transactions and all-pages
				// so we need to filter on the elementType. To do that we check the id of the totalValue of the response
				// if we don't have a totalValue or an elementType, this means that the result contains monitors,
				// in that case we have only one result so we return 'true'.
				'data' in fetchResult && fetchResult.data && 'totalValue' in fetchResult.data && 'elementType' in row
					? fetchResult.data.totalValue?.id === getAllXxxIdByElementType(row.elementType)
					: true,
			);

		const value = result && itemId ? retrieveItemValue(row.statistic, itemId, result) : undefined;

		if (referenceValueInitialized) {
			extractedRow.push(computeComparisonData(referenceValue, value, language, result, row.statistic, differenceType));
		} else {
			referenceValue = value;
			referenceValueInitialized = true;

			extractedRow.push(getDefaultComparisonCell(formatValue(language, referenceValue)));
		}
	}

	return extractedRow;
};

export const getDefaultComparisonCell = (value: string): ComparisonDataCell => ({
	type: 'comparison',
	value: value,
	delta: '',
	direction: 'NONE',
	state: 'EQUALS',
	align: 'right',
});

const formatDeltaValue = (value: number, language: string) => {
	if (value === 0) {
		return '0.0';
	} else if (value > 0) {
		return `+${formatValue(language, value)}`;
	}
	return formatValue(language, value);
};

const formatAbsoluteDelta = (referenceValue: number, currentValue: number, language: string) =>
	formatDeltaValue(currentValue - referenceValue, language);

const formatPercentDelta = (referenceValue: number, currentValue: number, language: string) =>
	`${formatDeltaValue(((currentValue - referenceValue) / referenceValue) * 100, language)}%`;

const getDeltaString = (
	differenceType: ValuesComparisonDashboardTile['differenceType'],
	referenceValue: number,
	currentValue: number,
	language: string,
) => {
	switch (differenceType) {
		case 'PERCENTAGE': {
			return formatPercentDelta(referenceValue, currentValue, language);
		}
		case 'VALUE': {
			return formatAbsoluteDelta(referenceValue, currentValue, language);
		}
		case 'BOTH': {
			return `${formatPercentDelta(referenceValue, currentValue, language)} (${formatAbsoluteDelta(
				referenceValue,
				currentValue,
				language,
			)})`;
		}
		case 'NONE': {
			return '';
		}
	}
};

export const computeComparisonData = (
	referenceValue: number | undefined,
	currentValue: number | undefined,
	language: string,
	fetchResult: FetchResult | undefined,
	statistic: UserPathElementValueStatistic | MonitorValueStatistic,
	differenceType: ValuesComparisonDashboardTile['differenceType'],
): ComparisonDataCell => {
	if (!fetchResult || currentValue === undefined) {
		return getDefaultComparisonCell('');
	}

	if (referenceValue === undefined) {
		return {
			type: 'comparison',
			value: formatValue(language, currentValue),
			delta: '-',
			direction: 'NONE',
			state: 'EQUALS',
			align: 'right',
		};
	}

	const deltaValue = currentValue - referenceValue;

	if (deltaValue === 0) {
		return {
			type: 'comparison',
			value: formatValue(language, currentValue),
			delta: getDeltaString(differenceType, referenceValue, currentValue, language),
			direction: 'NONE',
			state: 'EQUALS',
			align: 'right',
		};
	}

	return {
		type: 'comparison',
		value: formatValue(language, currentValue),
		delta: getDeltaString(differenceType, referenceValue, currentValue, language),
		direction: deltaValue > 0 ? 'UP' : 'DOWN',
		state: getStatisticComparisonState(fetchResult, statistic, deltaValue),
		align: 'right',
	};
};

export const getStatisticComparisonState = (
	fetchResult: FetchResult,
	statistic: UserPathElementValueStatistic | MonitorValueStatistic,
	deltaValue: number,
): ComparisonState => {
	if ('error' in fetchResult) {
		return 'EQUALS';
	}

	if (fetchResult.context.rowType === 'ELEMENT') {
		switch (statistic as UserPathElementValueStatistic) {
			case 'ELEMENT_COUNT':
			case 'ELEMENTS_PER_SECOND': {
				return deltaValue > 0 ? 'BETTER' : 'LESSER';
			}
			default: {
				return deltaValue < 0 ? 'BETTER' : 'LESSER';
			}
		}
	} else {
		return 'EQUALS';
	}
};

const retrieveItemValue = (
	statistic: UserPathElementValueStatistic | MonitorValueStatistic,
	itemId: string,
	fetchResult: FetchResult,
): number | undefined => {
	if ('error' in fetchResult) {
		return undefined;
	}

	switch (fetchResult.context.rowType) {
		case 'ELEMENT': {
			return getElementValue(
				statistic as UserPathElementValueStatistic,
				itemId,
				fetchResult as ElementValuesFetchResult,
			);
		}
		case 'MONITOR': {
			return getMonitorValue(statistic as MonitorValueStatistic, itemId, fetchResult as MonitorValuesFetchResult);
		}
		default: {
			return undefined;
		}
	}
};

const getColumn = (column: ValuesComparisonColumn): Column => ({
	label: column.columnName,
	align: 'right',
});

const getColumns = (metricTitle: string, columns: ValuesComparisonColumn[]): Column[] => [
	{
		label: metricTitle,
	},
	...columns.map((column) => getColumn(column)),
];

const formatValue = (language: string, value?: number): string =>
	Number.isInteger(value)
		? numberUtils.formatIntegerToString(language, value)
		: numberUtils.formatNumberToString(language, value);

const getElementValue = (
	statistic: UserPathElementValueStatistic,
	elementId: string,
	fetchResult: ElementValuesFetchResult,
): number | undefined => {
	if (!fetchResult.data) {
		return undefined;
	}

	const element = [...fetchResult.data.items, fetchResult.data.totalValue].find(
		(elementValue) => elementValue?.id === elementId,
	);

	if (element) {
		return element.statisticsValues[statistic];
	}

	return undefined;
};

const getMonitorValue = (
	statistic: MonitorValueStatistic,
	monitorId: string,
	fetchResult: MonitorValuesFetchResult,
): number | undefined => {
	if (!fetchResult.data) {
		return undefined;
	}

	const monitor = fetchResult.data.items.find((monitorValue) => monitorValue.id === monitorId);

	if (monitor === undefined) {
		return undefined;
	}

	switch (statistic) {
		case 'AVG': {
			return monitor.avg;
		}
		case 'MIN': {
			return monitor.min;
		}
		case 'MAX': {
			return monitor.max;
		}
		default: {
			return undefined;
		}
	}
};

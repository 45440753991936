import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import { memo } from 'react';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { useTranslation } from 'react-i18next';
import { PopulationItemUnsupported } from './population-item-unsupported';
import { ScenarioAsCodePopulation } from '@neoload/api';

type PopulationAccordionProps = {
	population: ScenarioAsCodePopulation;
};

const PopulationAccordionUnsupported = memo(({ population }: PopulationAccordionProps) => {
	const theme = useTheme();
	const { t } = useTranslation(['test']);

	if (!population) {
		return false;
	}

	return (
		<Tooltip arrow title={t('configuration.scenarios.custom.notSupported')}>
			<Accordion
				disableGutters
				sx={{
					backgroundColor: 'transparent',
					boxShadow: 'none',
					borderRadius: `${theme.aura.borderRadius.xs}px`,
					borderColor: theme.palette.divider,
					borderWidth: '1px',
					borderStyle: 'solid',
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'&:before': {
						display: 'none',
					},
				}}
				disabled={true}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<PopulationItemUnsupported population={population} />
				</AccordionSummary>
			</Accordion>
		</Tooltip>
	);
});

export { PopulationAccordionUnsupported };

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { TooltipContext } from '../../../../../common/chart/custom-chart-tooltip';
import { RunChip } from '../../../../../data-grid/run-chip';
import { TrendMetadata } from '@neoload/api';

export const displayNumber = (number_: number, decimal: number) =>
	Number.isInteger(number_) ? number_.toString() : number_.toFixed(decimal);

const formatPercentage = (percentage: number) => {
	if (!Number.isFinite(percentage)) {
		return '+∞';
	}
	return (percentage > 0 ? '+' : '') + displayNumber(percentage, 2);
};

type TrendsGraphTooltipProps = {
	context: TooltipContext;
	metadataBySeriesCategory: Map<string, TrendMetadata>;
};

export const TrendsGraphTooltip = ({ context, metadataBySeriesCategory }: TrendsGraphTooltipProps) => {
	if (!context.currentPoint.key) {
		return null;
	}
	const metadata = metadataBySeriesCategory.get(`${context.currentPoint.key}`);

	return (
		<Card
			sx={{ color: (theme) => theme.palette.text.secondary, background: (theme) => theme.palette.background.paper }}
			elevation={1}
		>
			<Stack padding={1}>
				<div>{context.currentPoint.key}</div>
				{metadata && (
					<>
						<div>{metadata.resultName}</div>
						<div>
							<RunChip
								resultStatusData={{
									qualityStatus: metadata.resultQualityStatus,
									status: 'UNKNOWN',
									terminationReason: 'UNKNOWN',
								}}
								size='small'
								withLabel={true}
							/>
						</div>
					</>
				)}
				<br />
				{context.points?.map((point) => {
					if (!point.series) {
						return null;
					}
					return (
						<Stack key={point.series.index} direction='row' gap={0.5}>
							<span style={{ color: point.color?.toString() }}>●</span>
							<span>{point.series.name}</span>
							<span>:</span>
							<span style={{ fontWeight: 'bold' }}>{point.y && displayNumber(point.y, 3)}</span>
							<span>{point.series.yAxis.userOptions.title?.text}</span>
							{point.percentage === undefined ? null : <span>({formatPercentage(point.percentage)}%)</span>}
						</Stack>
					);
				})}
			</Stack>
		</Card>
	);
};

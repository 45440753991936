import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import ListItemText from '@mui/material/ListItemText';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { TestExecutionForm } from '../types';
import { Reservation } from '@neoload/api';
import { timeUtils } from '@neoload/utils';

type TestExecutionReservationMenuItemProps = {
	reservation: Reservation;
	closeReservationMenu: () => void;
};

function getLgs(reservation: Reservation) {
	return Object.entries(reservation.lgCountByZoneId).reduce((accumulator, [, value]) => accumulator + value, 0);
}

function getVus(reservation: Reservation) {
	return Object.entries(reservation.vuCountByVuType || {}).reduce((accumulator, [, value]) => accumulator + value, 0);
}

const TestExecutionReservationMenuItem = ({
	reservation,
	closeReservationMenu,
}: TestExecutionReservationMenuItemProps) => {
	const { t } = useTranslation('test');
	const { setValue } = useFormContext<TestExecutionForm>();

	function onReservationSelected() {
		setValue('errorsInFirstCall', false);
		setValue('resources.reservationId', reservation.id);
		setValue('errors', []);
		setValue('reservation.selectedReservation', reservation);
		setValue('resources.webVu.needed', reservation.vuCountByVuType?.WEB ?? 0);
		setValue('resources.sapVu.needed', reservation.vuCountByVuType?.SAP ?? 0);
		setValue(
			'resources.duration',
			dayjs
				.duration(
					timeUtils.getRemaining(
						reservation.startDate,
						timeUtils
							.parseDuration(new Date(reservation.endDate).getTime() - new Date(reservation.startDate).getTime())
							.toISOString(),
					),
					'ms',
				)
				.toISOString(),
		);
		closeReservationMenu();
	}
	return (
		<MenuItem onClick={onReservationSelected}>
			<ListItemText
				primary={reservation.name}
				secondary={
					timeUtils.getRemainingTime(
						reservation.startDate,
						timeUtils
							.parseDuration(new Date(reservation.endDate).getTime() - new Date(reservation.startDate).getTime())
							.toISOString(),
					) +
					' ' +
					t('reservations.remaining')
				}
			/>
			<ListItemText
				secondary={
					getLgs(reservation) + ' ' + t('reservations.lgs') + ', ' + getVus(reservation) + ' ' + t('reservations.vus')
				}
				secondaryTypographyProps={{ marginLeft: '40px', marginBottom: '20px', align: 'right' }}
			/>
		</MenuItem>
	);
};

export { TestExecutionReservationMenuItem };

import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { useTranslation } from 'react-i18next';
import { InputNumber } from '../../../../common/input/input-number';
import { ConfigurationZoneFormData } from '../types.d';
import { Zone } from '@neoload/api';

export type ZoneRowProps = {
	disabledRemoved?: boolean;
	zone: ConfigurationZoneFormData;
	isError?: boolean;
	selectedZoneType: Zone['type'];
	controller: string;
	setLgs: (value: number) => void;
	setController: (value: string) => void;
};

const ZoneRow = ({
	zone,
	selectedZoneType,
	controller,
	setLgs,
	setController,
	disabledRemoved,
	isError = false,
}: ZoneRowProps) => {
	const theme = useTheme();
	const { t } = useTranslation(['test']);

	const disabledController = selectedZoneType === 'CLOUD';
	const disabledLg = selectedZoneType === 'DYNAMIC' && controller !== zone.zoneId;
	const tooltipController =
		selectedZoneType === 'CLOUD' && controller === zone.zoneId ? t('configuration.zones.ncp.freeController') : '';
	const tooltipLg =
		selectedZoneType === 'DYNAMIC' && controller !== zone.zoneId ? t('configuration.zones.dynamic.noController') : '';

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				borderRadius: theme.spacing(0.5),
				borderColor: theme.palette.divider,
				borderWidth: '1px',
				borderStyle: 'solid',
				paddingY: 1,
				paddingX: 2,
			}}
		>
			<Tooltip arrow title={zone.name}>
				<Typography variant='body2' sx={{ flex: 1, paddingRight: 3 }} noWrap>
					{zone.name}
				</Typography>
			</Tooltip>
			<Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
				<Tooltip arrow title={tooltipLg}>
					<span>
						<InputNumber
							onChange={(value) => {
								setLgs(value);
							}}
							value={zone.number}
							disabled={disabledLg}
							aria-label={t('configuration.zones.lg') + ' ' + zone.name}
							min={0}
							error={isError && zone.number > 0}
						/>
					</span>
				</Tooltip>
				<Tooltip arrow title={tooltipController}>
					<span>
						<Radio
							onChange={(value) => {
								setController(value.target.value);
							}}
							size='small'
							value={zone.zoneId}
							checked={zone.zoneId === controller}
							disabled={disabledController}
							aria-label={t('configuration.zones.controller') + ' ' + zone.name}
						/>
					</span>
				</Tooltip>
				{!disabledRemoved && (
					<Tooltip arrow title={t('common:remove')}>
						<IconButton aria-label={t('common:remove') + ' ' + zone.name}>
							<DeleteOutlined />
						</IconButton>
					</Tooltip>
				)}
			</Box>
		</Box>
	);
};

export { ZoneRow };

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconSettingsOutlined from '@tricentis/aura/components/IconSettingsOutlined.js';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
	DeepPartial,
	EventType,
	FormProvider,
	useForm,
	UseFormClearErrors,
	UseFormSetError,
	UseFormSetValue,
	UseFormWatch,
	WatchObserver,
} from 'react-hook-form';
import i18n from 'i18next';
import Tooltip from '@mui/material/Tooltip';
import { TrendsGraphSettingsForm } from './trends-graph-settings-form';
import {
	AutomatedTrendsConfiguration,
	BaselineTrendsConfiguration,
	StaticTrendsConfiguration,
	TrendElement,
	TrendsConfiguration,
} from '@neoload/api';

type TrendsGraphSettingsProps = {
	trendsConfiguration: TrendsConfiguration;
	trendElements: TrendElement[];
	sendTrendsPutConfigurationRequest: (
		trendsConfiguration: TrendsConfiguration,
		dryRun: boolean,
	) => Promise<TrendsConfiguration | void>;
	testId: string;
	testWorkspaceId: string;
};

export const AUTOMATED_TOLERANCE_VALUES = ['2', '3', '4'] as const;

type AutomatedToleranceValue = (typeof AUTOMATED_TOLERANCE_VALUES)[number];

export type TrendsConfigurationForm =
	| ({ objectiveMode: 'STATIC' } & StaticTrendsConfiguration)
	| ({ objectiveMode: 'BASELINE'; baselineTolerance: number } & Omit<BaselineTrendsConfiguration, 'tolerance'>)
	| ({ objectiveMode: 'AUTOMATED'; automatedTolerance: AutomatedToleranceValue } & Omit<
			AutomatedTrendsConfiguration,
			'tolerance'
	  >);

export const TrendsGraphSettings = ({
	trendsConfiguration,
	trendElements,
	sendTrendsPutConfigurationRequest,
	testId,
	testWorkspaceId,
}: TrendsGraphSettingsProps) => {
	const { t } = useTranslation('test', { keyPrefix: 'trends.settings' });
	const [open, setOpen] = useState(false);
	const defaultValues = useMemo(() => toDefaultValues(trendsConfiguration), [trendsConfiguration]);
	const { handleSubmit, reset, formState, watch, setValue, setError, clearErrors, ...methods } =
		useForm<TrendsConfigurationForm>({
			mode: 'onChange',
			defaultValues,
		});

	useFetchObjectiveValues({
		trendsConfiguration,
		sendTrendsPutConfigurationRequest,
		watch,
		setValue,
		setError,
		clearErrors,
	});

	useEffect(() => {
		reset(defaultValues);
	}, [reset, defaultValues]);

	const openSettingsDialog = () => {
		setOpen(true);
	};

	const closeSettingsDialog = (form?: TrendsConfigurationForm) => {
		setOpen(false);
		reset(form);
	};

	return (
		<FormProvider
			{...methods}
			handleSubmit={handleSubmit}
			formState={formState}
			setValue={setValue}
			setError={setError}
			clearErrors={clearErrors}
			watch={watch}
			reset={reset}
		>
			<Tooltip arrow title={trendElements.length === 0 ? t('buttonDisabledTooltip') : undefined}>
				<span>
					<Button
						aria-label={t('title')}
						variant='outlined'
						size='small'
						startIcon={<IconSettingsOutlined />}
						disabled={trendElements.length === 0}
						onClick={openSettingsDialog}
					>
						{t('title')}
					</Button>
				</span>
			</Tooltip>
			<Dialog open={open} fullWidth maxWidth='md'>
				<DialogTitle>{t('title')}</DialogTitle>
				<DialogContent sx={{ height: '85vh', overflow: 'hidden' }}>
					<TrendsGraphSettingsForm trendElements={trendElements} testId={testId} testWorkspaceId={testWorkspaceId} />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => closeSettingsDialog()} data-trackingid='trends-graph-cancel'>
						{t('cancel')}
					</Button>
					<Button
						variant='contained'
						disabled={!formState.isValid || Object.keys(formState.errors).length > 0}
						onClick={handleSubmit(async (form) => {
							await sendTrendsPutConfigurationRequest(toTrendsConfiguration(form), false);
							closeSettingsDialog(form);
						})}
						data-trackingid='trends-graph-ok'
					>
						{t('apply')}
					</Button>
				</DialogActions>
			</Dialog>
		</FormProvider>
	);
};

const toDefaultValues = (trendsConfiguration: TrendsConfiguration): TrendsConfigurationForm => {
	if (trendsConfiguration.objectiveMode === 'AUTOMATED') {
		return {
			...trendsConfiguration,
			automatedTolerance: trendsConfiguration.tolerance ?? '2',
			metrics: trendsConfiguration.metrics.map((metric) => ({
				...metric,
				objectiveValue: metric.objectiveValue ? round(metric.objectiveValue) : undefined,
			})),
		};
	} else if (trendsConfiguration.objectiveMode === 'BASELINE') {
		return {
			...trendsConfiguration,
			baselineTolerance: trendsConfiguration.tolerance,
			metrics: trendsConfiguration.metrics.map((metric) => ({
				...metric,
				objectiveValue: metric.objectiveValue ? round(metric.objectiveValue) : undefined,
			})),
		};
	}
	return {
		...trendsConfiguration,
		metrics: trendsConfiguration.metrics.map((metric) => ({
			...metric,
			objectiveValue: metric.objectiveValue ? round(metric.objectiveValue) : undefined,
		})),
	};
};

const toTrendsConfiguration = (trendsConfigurationForm: TrendsConfigurationForm): TrendsConfiguration => {
	if (trendsConfigurationForm.objectiveMode === 'AUTOMATED') {
		const { automatedTolerance, ...rest } = trendsConfigurationForm;
		return {
			...rest,
			tolerance: automatedTolerance,
			metrics: rest.metrics.map((metric) => ({
				...metric,
				objectiveValue: metric.objectiveValue ? round(metric.objectiveValue) : undefined,
			})),
		};
	} else if (trendsConfigurationForm.objectiveMode === 'BASELINE') {
		const { baselineTolerance, ...rest } = trendsConfigurationForm;
		return {
			...rest,
			tolerance: baselineTolerance,
			metrics: rest.metrics.map((metric) => ({
				...metric,
				objectiveValue: metric.objectiveValue ? round(metric.objectiveValue) : undefined,
			})),
		};
	}
	return {
		displayTestResultsCount: trendsConfigurationForm.displayTestResultsCount,
		objectiveMode: trendsConfigurationForm.objectiveMode,
		metrics: trendsConfigurationForm.metrics.map((metric) => ({
			...metric,
			objectiveValue: metric.objectiveValue ? round(metric.objectiveValue) : undefined,
		})),
		shouldFailTestExecutionOnObjectiveExceeded: trendsConfigurationForm.shouldFailTestExecutionOnObjectiveExceeded,
	};
};

type TriggeringFieldName = Parameters<WatchObserver<TrendsConfigurationForm>>[1]['name'];

const TRIGGERING_FIELD_NAME_BLACKLIST: Set<TriggeringFieldName> = new Set([
	'shouldFailTestExecutionOnObjectiveExceeded',
]);

const isTriggeredByUser = (type: EventType | undefined) => !!type;
const isTriggeringFieldNameValid = (name: TriggeringFieldName | undefined) =>
	!TRIGGERING_FIELD_NAME_BLACKLIST.has(name);

const isTriggerValid = (type: EventType | undefined, name: TriggeringFieldName | undefined) =>
	isTriggeredByUser(type) && isTriggeringFieldNameValid(name);

const shouldFetchObjectiveValues = (values: DeepPartial<TrendsConfigurationForm>) =>
	!!(
		((values.objectiveMode === 'BASELINE' && values.resultId && values.baselineTolerance !== undefined) ||
			(values.objectiveMode === 'AUTOMATED' && values.automatedTolerance !== undefined)) &&
		values.metrics?.some((metric) => Boolean(metric?.objective))
	);

type UseFetchObjectiveValuesProps = {
	trendsConfiguration: TrendsConfiguration;
	watch: UseFormWatch<TrendsConfigurationForm>;
	setValue: UseFormSetValue<TrendsConfigurationForm>;
	setError: UseFormSetError<TrendsConfigurationForm>;
	clearErrors: UseFormClearErrors<TrendsConfigurationForm>;
	sendTrendsPutConfigurationRequest: (
		trendsConfiguration: TrendsConfiguration,
		dryRun: boolean,
	) => Promise<TrendsConfiguration | void>;
};

const useFetchObjectiveValues = ({
	trendsConfiguration,
	watch,
	setValue,
	setError,
	clearErrors,
	sendTrendsPutConfigurationRequest,
}: UseFetchObjectiveValuesProps) => {
	useEffect(() => {
		const subscription = watch((values, info) => {
			if (isTriggerValid(info.type, info.name) && shouldFetchObjectiveValues(values)) {
				const trendsConfigurationForDryRun: TrendsConfiguration = removeObjectiveValuesFromMetrics(
					toTrendsConfiguration(values as TrendsConfigurationForm),
				);
				if (!isConfigurationValidForDryRunRequest(trendsConfigurationForDryRun)) {
					return;
				}
				sendTrendsPutConfigurationRequest(trendsConfigurationForDryRun, true)
					.then((response) => {
						if (response) {
							for (let index = 0; index < response.metrics.length; index++) {
								clearErrors(`metrics.${index}.objectiveValue`);
								if (trendsConfigurationForDryRun.metrics[index].objective) {
									const { objective, objectiveValue } = response.metrics[index];
									if (!objective || typeof objectiveValue !== 'number') {
										setError(`metrics.${index}.objectiveValue`, {
											message: i18n.t('test:trends.settings.errorMessages.noObjective'),
											type: 'validate',
										});
									} else {
										setValue(`metrics.${index}.objectiveValue`, round(objectiveValue));
									}
								}
							}
						}
					})
					.catch((error) => {
						console.error('Update of objective values failed', error);
					});
			}
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [clearErrors, sendTrendsPutConfigurationRequest, setError, setValue, trendsConfiguration, watch]);
};

const removeObjectiveValuesFromMetrics = (trendsConfiguration: TrendsConfiguration): TrendsConfiguration => ({
	...trendsConfiguration,
	metrics: trendsConfiguration.metrics.map(({ objectiveValue, ...rest }) => ({ ...rest })),
});

const isConfigurationValidForDryRunRequest = (trendsConfiguration: TrendsConfiguration) => {
	if (trendsConfiguration.objectiveMode === 'BASELINE') {
		return (
			typeof trendsConfiguration.tolerance === 'number' &&
			!Number.isNaN(trendsConfiguration.tolerance) &&
			trendsConfiguration.resultId.length > 0
		);
	}
	if (trendsConfiguration.objectiveMode === 'AUTOMATED') {
		return AUTOMATED_TOLERANCE_VALUES.includes(trendsConfiguration.tolerance);
	}
	return true;
};

const round = (objectiveValue: number) => Math.round(objectiveValue * 1000) / 1000;

import Grid from '@mui/material/Grid';
import { memo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import { ResultStatusDetails } from './result-status-details';
import { StatisticsLayout } from './statistics/statistics-layout';
import { ResultOverviewGraphs } from './overview/result-overview-graphs';

type ResultTabOverviewProps = {
	resultId: string;
	resultDescription: string;
	isRunning: boolean;
	statisticsSamplingInterval?: string;
	startDate: string;
	isStarting: boolean;
	resultDuration: string;
	resultEndDate?: string;
};

const ResultTabOverviewInternal = ({
	resultId,
	resultDescription,
	isRunning,
	statisticsSamplingInterval,
	startDate,
	isStarting,
	resultDuration,
	resultEndDate,
}: ResultTabOverviewProps) => {
	const theme = useTheme();

	return (
		<Grid
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Grid container columns={16}>
				<ResultStatusDetails resultId={resultId} resultDescription={resultDescription} />
				<StatisticsLayout
					resultId={resultId}
					isRunning={isRunning}
					statisticsSamplingInterval={statisticsSamplingInterval}
					isStarting={isStarting}
				/>
			</Grid>

			{isStarting ? (
				<>
					<Grid
						container
						display='flex'
						justifyContent='space-between'
						alignItems='baseline'
						sx={{
							borderTop: `1px solid ${theme.palette.divider}`,
							marginTop: 2,
							paddingTop: 2,
							paddingX: 4,
						}}
					>
						<Grid item>
							<Skeleton variant='rounded' sx={{ width: '100px' }} />
						</Grid>
						<Grid item>
							<Skeleton variant='rounded' sx={{ width: '100px' }} />
						</Grid>
					</Grid>
					<Grid sx={{ width: '100%', height: '100%' }}>
						<Skeleton
							variant='rounded'
							sx={{
								width: `calc(100% - ${theme.spacing(8)})`,
								height: `calc(50% - ${theme.spacing(6)})`,
								margin: theme.spacing(4),
							}}
						/>
						<Skeleton
							variant='rounded'
							sx={{
								width: `calc(100% - ${theme.spacing(8)})`,
								height: `calc(50% - ${theme.spacing(6)})`,
								margin: theme.spacing(4),
							}}
						/>
					</Grid>
				</>
			) : (
				<Grid item>
					<ResultOverviewGraphs
						resultId={resultId}
						isRunning={isRunning}
						startDate={startDate}
						resultDuration={resultDuration}
						resultEndDate={resultEndDate}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export const ResultTabOverview = memo(ResultTabOverviewInternal);

import {
	ApiError,
	InvalidPropertyValueError,
	NotFoundError,
	PayloadTooLargeError,
	TooManyRequestsError,
	UnauthorizedError,
} from './nlw-api-v4';

export const isApiError = (error: unknown): error is ApiError => {
	const apiError = error as ApiError;
	return apiError && typeof apiError === 'object' && 'category' in apiError && 'description' in apiError;
};

export const isInvalidPropertyError = (error: ApiError): error is InvalidPropertyValueError =>
	error.category === 'INVALID_PROPERTY_VALUE';

export const isPayloadTooLargeError = (error: ApiError): error is PayloadTooLargeError =>
	error.category === 'PAYLOAD_TOO_LARGE';

export const isTooManyRequestError = (error: ApiError): error is TooManyRequestsError =>
	error.category === 'TOO_MANY_REQUESTS';

export const isUnAuthorizedError = (error: ApiError): error is UnauthorizedError => error.category === 'UNAUTHORIZED';

export const isNotFoundError = (error: ApiError): error is NotFoundError => error.category === 'NOT_FOUND';

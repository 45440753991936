import { ComponentPropsWithoutRef, MutableRefObject, useRef, useState } from 'react';
import {
	GridColDef,
	GridInitialState,
	GridRenderCellParams,
	GridRowId,
	GridRowModel,
	GridRowSelectionModel,
	useGridApiRef,
} from '@mui/x-data-grid-pro';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import { useTranslation } from 'react-i18next';
import { useAccessTokensActions } from './access-token-actions/access-token-actions';
import { HiddenInput } from '../../common/hidden-input';
import { DEFAULT_GRID_PROPS, onColumnChange } from '../../common/datagrid';
import { Datagrid } from '../../common/data-grid/datagrid';
import { CreateResourceButton } from '../../common/button/create-resource-button';
import { toContextMenuItem, toSecondaryAction } from '../../common/data-grid/actions/data-grid-actions';
import { ActionsCell } from '../../common/data-grid/actions/actions-cell';
import { ContextMenu, ContextMenuFuncts } from '../../common/context-menu/context-menu';
import { Token, TokenPage } from '@neoload/api';
import { useColumnsState, useConfirmModal } from '@neoload/hooks';

const columnsStateKey = 'TOKENS_COLUMNS_STATE';

type AccessTokenDataGridProps = {
	accessTokensPage: TokenPage;
	publicApiUrl: string | undefined;
	onDeleteAccessTokens: (tokens: GridRowSelectionModel) => void;
	onGenerateAccessToken: (tokenName: string) => Promise<void>;
	isFetching: boolean;
};

const AccessTokenDataGrid = ({
	accessTokensPage,
	onDeleteAccessTokens,
	onGenerateAccessToken,
	publicApiUrl,
	isFetching,
}: AccessTokenDataGridProps) => {
	const [selectedAccessTokenIds, setSelectedAccessTokenIds] = useState<GridRowSelectionModel>([]);
	const [displayedTokenRows, setDisplayedTokenRows] = useState<GridRowSelectionModel>([]);
	const contextMenu: MutableRefObject<ContextMenuFuncts | undefined> = useRef();

	const isAllSelectedTokensVisibles =
		displayedTokenRows.length > 0 && selectedAccessTokenIds.every((t: GridRowId) => displayedTokenRows.includes(t));

	const { t } = useTranslation(['user']);

	const apiRef = useGridApiRef();
	const { openModal } = useConfirmModal();

	const generateToken = () => {
		openModal({
			title: 'Generate access token',
			content: [
				{
					label: t('token.dialog.create.name'),
					value: '',
					max: 128,
					name: 'name',
					type: 'string',
					required: true,
					requiredHelperText: t('token.error.nameRequired'),
					size: 'small',
				},
			],
			defaultValue: { name: '' },
			confirm: { text: t('token.dialog.create.createButton') },
			handleConfirm: async ({ name }) => {
				await onGenerateAccessToken(`${name}`);
			},
		});
	};

	const { full: actions, deleteToken } = useAccessTokensActions({
		publicApiUrl,
		displayedTokenRows,
		setDisplayedTokenRows,
		items: accessTokensPage.items,
		isAllSelectedTokensVisibles,
		selectedAccessTokenIds,
		onDeleteAccessTokens,
	});

	const columns: GridColDef<Token>[] = [
		{
			field: 'name',
			headerName: t('token.name'),
			minWidth: 250,
			flex: 1,
		},
		{
			field: 'token',
			headerName: t('token.token'),
			minWidth: 500,
			type: 'string',
			sortable: false,
			renderCell: (params: GridRenderCellParams) => (
				<HiddenInput
					data-testid='token-cell'
					inputData={params.value}
					display={displayedTokenRows.includes(params.row.token)}
					sx={{ width: '100%' }}
				/>
			),
		},
		{
			field: 'actions',
			minWidth: 200,
			renderCell: (params: GridRenderCellParams) => <ActionsCell rowData={params.id.toString()} actions={actions} />,
			resizable: false,
			disableReorder: true,
			type: 'actions',
		},
	];

	const initialState: GridInitialState = {
		sorting: {
			sortModel: [{ field: 'name', sort: 'asc' }],
		},
	};
	const { updatedInitialState, updatedColumns, storeColumnState } = useColumnsState(
		columnsStateKey,
		initialState,
		columns,
		apiRef,
	);

	const generateTokenAction = {
		children: (
			<CreateResourceButton onClick={() => generateToken()} key='generateToken'>
				{t('token.generate')}
			</CreateResourceButton>
		),
	};

	const componentsProps: { toolbar: ComponentPropsWithoutRef<typeof Toolbar>; row: GridRowModel } = {
		toolbar: {
			displayColumnOptions: false,
			description: t('token.description'),
			displaySearchBox: true,
			hideColumnsFromColumnOptions: ['__check__'],
			hideFiltersIcon: true,
			mainActions: [generateTokenAction],
			secondaryActions: selectedAccessTokenIds.length > 0 ? [toSecondaryAction(deleteToken)] : [],
			syncLocalStorage: {
				datagridId: 'neoloadTokensDataGrid',
				isSyncEnabled: true,
			},
			title: t('token.tokens'),
		},
		row: {
			onContextMenu: (event: React.MouseEvent) => contextMenu.current?.openContextMenu(event),
		},
	};

	return (
		<>
			<Datagrid
				{...DEFAULT_GRID_PROPS}
				{...onColumnChange(storeColumnState)}
				loading={isFetching}
				apiRef={apiRef}
				rows={accessTokensPage.items}
				getRowId={(row) => row.token}
				initialState={updatedInitialState}
				columns={updatedColumns}
				disableRowSelectionOnClick={false}
				slotProps={componentsProps}
				slots={{
					toolbar: Toolbar,
				}}
				onRowSelectionModelChange={(model) => setSelectedAccessTokenIds(model)}
				isCellEditable={(params) => params.row.token === '1'}
			/>
			<ContextMenu apiRef={apiRef} ref={contextMenu} contextMenuItemsList={actions.map(toContextMenuItem)} />
		</>
	);
};

export { AccessTokenDataGrid };

import { GridRowModel } from '@mui/x-data-grid-pro';
import DGColumnContextMenuButton from '@tricentis/aura/components/DGColumnContextMenuButton.js';
import { MouseEvent } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { DatagridAction, filterToContextMenuItems, toSingleItemAction } from './data-grid-actions';

export type DatagridActionCellProps<T extends GridRowModel> = {
	actions: DatagridAction[];
	rowData: T;
	stopPropagation?: boolean;
};

const ActionsCellMenu = <T extends GridRowModel>({ actions, rowData, stopPropagation }: DatagridActionCellProps<T>) => {
	const { t } = useTranslation(['common']);

	return (
		<Box
			onClick={(event: MouseEvent<HTMLElement>) => {
				if (stopPropagation) {
					event.stopPropagation();
				}
			}}
			aria-label={t('datagrid.actions')}
		>
			<DGColumnContextMenuButton
				rowData={rowData}
				contextMenu={filterToContextMenuItems(actions.map((action) => toSingleItemAction(action, rowData)))}
			/>
		</Box>
	);
};

export { ActionsCellMenu };

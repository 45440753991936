import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { SubscriptionStatusPlan } from './subscription-status-plan';
import { SubscriptionGauge } from './subscription-gauge';
import { Spinner } from '../../../common/spinner';
import { getPartialErrorSubscription } from '../../test-execution';
import { useGetV4SubscriptionQuery } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

const SubscriptionSaasPlan = () => {
	const { t } = useTranslation(['subscription'], { keyPrefix: 'saasPlan' });
	const { data: subscriptionInfos, error: subscriptionError, isLoading } = useGetV4SubscriptionQuery({});
	const subscription = subscriptionError ? getPartialErrorSubscription(subscriptionError) : subscriptionInfos;
	const { showError } = useSetSnackbars();

	useEffect(() => {
		if (subscriptionError && !getPartialErrorSubscription(subscriptionError)) {
			showError({ text: t('messageError') });
		}
	}, [showError, subscriptionError, t]);

	if (isLoading) {
		return <Spinner />;
	}

	if (!subscription) {
		return null;
	}

	return (
		<Stack p={2} spacing={2}>
			<SubscriptionStatusPlan expirationDate={subscription.expirationDate} />
			<Stack spacing={3}>
				{subscription.monthlySavedResultDuration?.totalInMinutes !== 0 && (
					<SubscriptionGauge
						title={t('gauge.monthlySavedResultDuration')}
						valueType='DURATION'
						free={subscription.monthlySavedResultDuration?.availableInMinutes}
						total={subscription.monthlySavedResultDuration?.totalInMinutes}
					/>
				)}
				{subscription.totalSavedResultDuration?.totalInMinutes !== 0 && (
					<SubscriptionGauge
						title={t('gauge.totalSavedResultDuration')}
						valueType='DURATION'
						free={subscription.totalSavedResultDuration?.availableInMinutes}
						total={subscription.totalSavedResultDuration?.totalInMinutes}
					/>
				)}
				{subscription.cloudCredits?.initial !== 0 && (
					<SubscriptionGauge
						title={t('gauge.cloudCredits')}
						valueType='RAW'
						free={subscription.cloudCredits?.available}
						total={subscription.cloudCredits?.initial}
					/>
				)}
				{subscription.vuhs?.initial !== 0 && (
					<SubscriptionGauge
						title={t('gauge.vuh')}
						valueType='RAW'
						free={subscription.vuhs?.available}
						total={subscription.vuhs?.initial}
					/>
				)}
			</Stack>
		</Stack>
	);
};

export { SubscriptionSaasPlan };

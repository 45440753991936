import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormContext, useWatch } from 'react-hook-form';
import { CreateReservationForm, reservationErrorToString } from './reservation-helper';
import { useSetSnackbars } from '@neoload/hooks';
import { usePostV4ReservationsMutation, usePatchV4ReservationsByReservationIdMutation } from '@neoload/api';

type CreateModalReservationActionProps = {
	close: () => void;
};

const ActionsScheduleTestModal = ({ close }: CreateModalReservationActionProps) => {
	const { t } = useTranslation(['reservation']);
	const { showInfo } = useSetSnackbars();
	const [loading, setLoading] = useState(false);
	const [postReservation] = usePostV4ReservationsMutation();
	const [updateReservation] = usePatchV4ReservationsByReservationIdMutation();
	const { setValue } = useFormContext<CreateReservationForm>();

	const testId = useWatch<CreateReservationForm, 'testId'>({ name: 'testId' });
	const startDate = useWatch<CreateReservationForm, 'startDate'>({ name: 'startDate' });
	const endDate = useWatch<CreateReservationForm, 'endDate'>({ name: 'endDate' });
	const errorDate = useWatch<CreateReservationForm, 'errorDate'>({ name: 'errorDate' });
	const action = useWatch<CreateReservationForm, 'action'>({ name: 'action' });
	const reservationId = useWatch<CreateReservationForm, 'reservationId'>({ name: 'reservationId' });

	const disableSubmit = testId === undefined || errorDate;

	const createScheduleTest = () => {
		setValue('error', '');
		if (!testId) {
			return;
		}
		setLoading(true);
		postReservation({
			body: {
				testId: testId,
				startDate: startDate,
				endDate: endDate,
			},
		})
			.unwrap()
			.then(() => {
				showInfo({ text: t('schedule.snackbars.success') });
				close();
			})
			.catch((error) => {
				const message = reservationErrorToString(error.data, t);
				setValue('error', message);
			})
			.finally(() => setLoading(false));
	};

	const editScheduleTest = () => {
		setValue('error', '');
		if (!reservationId) {
			return;
		}

		setLoading(true);
		updateReservation({
			reservationId: reservationId,
			patchReservation: {
				startDate: startDate,
				endDate: endDate,
			},
		})
			.unwrap()
			.then(() => {
				showInfo({ text: t('schedule.snackbars.updateSuccess') });
				close();
			})
			.catch((error) => {
				const message = reservationErrorToString(error.data, t);
				setValue('error', message);
			})
			.finally(() => setLoading(false));
	};

	const scheduleTest = () => {
		if (action === 'NEW') {
			createScheduleTest();
		} else {
			editScheduleTest();
		}
	};

	return (
		<DialogActions>
			<Button onClick={close} color='primary' data-trackingid='schedule-test-cancel'>
				{t('common:cancel')}
			</Button>
			<Button
				disabled={disableSubmit || loading}
				variant='contained'
				startIcon={loading && <CircularProgress size={24.5} color='inherit' />}
				onClick={() => {
					scheduleTest();
				}}
				data-trackingid='schedule-test-ok'
			>
				{action === 'NEW' ? t('schedule.confirm') : t('common:save')}
			</Button>
		</DialogActions>
	);
};

export { ActionsScheduleTestModal };

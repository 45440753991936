import { SeriesData } from '../../add-series';
import { UserPathElementStatistic } from '@neoload/api';

type MonitorElementStatistic = 'User Load' | 'Charge Utilisateurs';
const predefinedColors = new Map<UserPathElementStatistic | MonitorElementStatistic, string>([
	['User Load', '#1A8CFF'],
	['Charge Utilisateurs', '#1A8CFF'],
	['AVERAGE_DURATION', '#D2B067'],
	['ELEMENTS_PER_SECOND', '#00CD00'],
	['THROUGHPUT', '#C93AC9'],
	['AVERAGE_TTFB', '#EDB800'],
	['ERROR_COUNT', '#CE0000'],
	['ERROR_RATE', '#CE0000'],
	['ERRORS_PER_SECOND', '#CE0000'],
]);

export const randomColorTable = [
	'#1ABA9F',
	'#BA4F1A',
	'#1ABA4F',
	'#BA9F1A',
	'#BA1A35',
	'#1A85BA',
	'#8092ED',
	'#35BA1A',
	'#BA9F1A',
	'#BA4F1A',
	'#B37400',
	'#C23265',
];

function isColorUsed(
	color: string,
	existingSeriesList: (
		| {
				color?: string;
				deleteOnSubmit?: boolean;
		  }
		| undefined
	)[] = [],
) {
	return existingSeriesList
		?.filter((series) => !series?.deleteOnSubmit)
		?.find((existingSeries) => existingSeries?.color?.toUpperCase() === color.toUpperCase());
}

export const generateSeriesColor = (
	seriesToAdd: SeriesData<UserPathElementStatistic>,
	existingSeriesList: (
		| {
				color?: string;
				deleteOnSubmit?: boolean;
		  }
		| undefined
	)[] = [],
) => {
	let color: string | undefined;
	if (
		seriesToAdd.type === 'MONITOR' &&
		(seriesToAdd.monitor.name === 'User Load' || seriesToAdd.monitor.name === 'Charge Utilisateurs')
	) {
		color = predefinedColors.get(seriesToAdd.monitor.name);
	} else if (seriesToAdd.type === 'USERPATH') {
		color = predefinedColors.get(seriesToAdd.stats[0]);
	}

	if (!color || isColorUsed(color, existingSeriesList)) {
		const colorsNotUsed = randomColorsNotUsed(existingSeriesList);
		color = colorsNotUsed[Math.floor(Math.random() * colorsNotUsed.length)];
	}
	return color;
};

const randomColorsNotUsed = (_existingSeries: ({ color?: string } | undefined)[] = []) => {
	const unusedColors = randomColorTable.filter((color) => !isColorUsed(color, _existingSeries));
	return unusedColors.length === 0 ? randomColorTable : unusedColors;
};

import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
import { atLeastZero, atMostMaxInteger, findSingleHumanReadableTime } from './custom-scenarios-helpers';
import { CustomPopulation, RampUpRange } from '@neoload/api';
import { useCustomScenario } from '@neoload/hooks';

type StepRampUpProps = {
	population: CustomPopulation;
};

const shouldUpdate = (prevProps: StepRampUpProps, nextProps: StepRampUpProps) =>
	prevProps.population.name === nextProps.population.name &&
	prevProps.population.stepRampUp === nextProps.population.stepRampUp;

const StepRampUp = memo(
	({ population }: StepRampUpProps) => {
		const { t } = useTranslation(['test']);
		const { updatePopulation } = useCustomScenario();

		const { unit, value } = findSingleHumanReadableTime(population.stepRampUp);

		const updateRampUpValue = (rampUp: number) => {
			updatePopulation(population.name, { stepRampUp: `${atMostMaxInteger(atLeastZero(rampUp))}${unit}` }, true);
		};

		const updateRampUpUnit = (unit: RampUpRange) => {
			updatePopulation(population.name, { stepRampUp: `${value}${unit}` }, true);
		};

		return (
			<Stack direction='row' useFlexGap gap={1}>
				<TextField
					label={t('configuration.scenarios.custom.stepRampUp')}
					size='small'
					value={value}
					onChange={(event) => updateRampUpValue(Number.parseInt(event.target.value))}
					sx={{ width: '140px' }}
					type='number'
				/>
				<Select
					value={unit}
					onChange={(value) => updateRampUpUnit(value.target.value as RampUpRange)}
					variant='outlined'
					size='small'
					data-trackingid='custom-scenario-rampup-range'
				>
					<MenuItem value={RampUpRange.SECONDS}>{t('configuration.scenarios.custom.rampUpValues.seconds')}</MenuItem>
					<MenuItem value={RampUpRange.MINUTES}>{t('configuration.scenarios.custom.rampUpValues.minutes')}</MenuItem>
					<MenuItem value={RampUpRange.HOURS}>{t('configuration.scenarios.custom.rampUpValues.hours')}</MenuItem>
				</Select>
			</Stack>
		);
	},
	(prevProps, nextProps) => shouldUpdate(prevProps, nextProps),
);

export { StepRampUp };

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { ReservationZones } from './reservation-zones';
import { ZoneTypes } from '../../../../tests/configuration/zones/zone-types';

const ReservationLoadZones = () => {
	const { t } = useTranslation(['reservation']);
	return (
		<Box>
			<Typography variant='h3'>{t('common:reservationZones')}</Typography>
			<ZoneTypes disabled={false} />
			<ReservationZones />
		</Box>
	);
};

export { ReservationLoadZones };

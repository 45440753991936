import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { CloudZoneWithAvailability } from './use-with-unavailable-cloud-zones';
import { useDeleteV4ZonesByZoneIdMutation, usePostV4ZonesMutation } from '@neoload/api';
import { useGetMe, useSetSnackbars } from '@neoload/hooks';
import { isForbiddenError } from '@neoload/utils';

type CloudZoneSwitchProps = {
	zone: Pick<
		CloudZoneWithAvailability,
		'zoneId' | 'cloudRegion' | 'available' | 'loadGenerators' | 'controllers' | 'dedicatedIpsDetails'
	>;
};

const CloudZoneSwitch = ({
	zone: { zoneId, cloudRegion, available, loadGenerators, controllers, dedicatedIpsDetails },
}: CloudZoneSwitchProps) => {
	const { t } = useTranslation(['zone']);
	const [{ hasAdminPermissions }] = useGetMe();
	const { showInfo, showError } = useSetSnackbars();
	const [postZone, { isLoading: isCreating }] = usePostV4ZonesMutation();
	const [deleteZone, { isLoading: isDeleting }] = useDeleteV4ZonesByZoneIdMutation();
	const disabled =
		!hasAdminPermissions ||
		loadGenerators.length > 0 ||
		controllers.length > 0 ||
		(dedicatedIpsDetails && dedicatedIpsDetails.reservedIpsCount > 0);

	const createCloudZone = async () => {
		await postZone({ postZoneFields: { type: 'CLOUD', cloudRegion } })
			.unwrap()
			.then(() => showInfo({ text: t('cloudZone.createSuccessful') }))
			.catch((_error) => showError({ text: t('cloudZone.createFailed') }));
	};

	const deleteCloudZone = async () => {
		await deleteZone({ zoneId })
			.unwrap()
			.then(() => showInfo({ text: t('cloudZone.deleteSuccessful') }))
			.catch((error) =>
				// Forbidden error could be: "This zone has dedicated IPs. Please release the IPs before deletion"
				showError({ text: isForbiddenError(error) ? error.data.description : t('cloudZone.deleteFailed') }),
			);
	};
	const label = t(available ? 'cloudZone.disable' : 'cloudZone.enable');
	const disabledLabel = hasAdminPermissions ? t('cloudZone.deleteForbidden') : t('cloudZone.testerSwitchTooltip');

	return (
		<Tooltip arrow title={disabled ? disabledLabel : label}>
			<span>
				<Switch
					disabled={disabled || isCreating || isDeleting}
					checked={available}
					onClick={(event) => event.stopPropagation()}
					onChange={(event) => (event.target.checked ? createCloudZone() : deleteCloudZone())}
					aria-label={t(available ? 'cloudZone.available' : 'cloudZone.unAvailable')}
					inputProps={{ 'aria-label': label }}
				/>
			</span>
		</Tooltip>
	);
};

export { CloudZoneSwitch };

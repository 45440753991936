const externalLinks = {
	documentation:
		'https://www.neotys.com/redirect/redirect.php?target=docpage&front-version=2.0&product=neoload+web&lngdoc=en&reference=home',
	support: 'https://www.neotys.com/redirect/redirect.php?target=support&product=neoload+web&lngdoc=en',
	whatsNew:
		'https://www.neotys.com/redirect/redirect.php?target=docpage&front-version=2.0&product=neoload+web&lngdoc=en&reference=releasenotes',
	privacyPolicy: 'https://www.tricentis.com/legal-information/privacy-policy',
	termsOfUse: 'https://be.tricentis.com/media-assets/pdf/Tricentis-Legal-General-Terms-of-Use-Final-V08-22-EN.pdf',
	customerArea: 'https://www.neotys.com/accountarea/my-licenses.html',
	activationLicense: 'https://www.neotys.com/accountarea/activation-floating.html',
	deactivationLicense: 'https://www.neotys.com/accountarea/disableLicense.html',
};

export { externalLinks };

import {
	DeletionPolicyFilter,
	DeletionPolicySetting,
	TestResultSearchCriteria,
	DeletionPolicy,
	DeletionPolicyFilterByTest,
	DeletionPolicyFilterByProject,
	DeletionPolicyFilterByResultName,
	SimpleScenario,
	ProjectScenarios,
	SimpleTestSettingsDefinition,
	DeletionPolicyFilterRead,
} from '@neoload/api';

type FilterType = DeletionPolicyFilter['type'];
type SettingType = DeletionPolicySetting['type'];
type DeletionFilterItem = { id: string; name: string };
type ProjectConfig = { projectName: string; scenarioName: string };
type UsedFilters = { usedTestIds?: string[]; usedProjects?: ProjectConfig[]; usedPatterns?: string[] };

const difference = <T>(setA: Set<T>, setB: Set<T>): Set<T> => new Set([...setA].filter((x) => !setB.has(x)));

const sortDeletionFilterItemArray = (items: DeletionFilterItem[]): DeletionFilterItem[] =>
	items.toSorted((a, b) => a.name.localeCompare(b.name));

const filterAndFormatTests = (
	tests: SimpleTestSettingsDefinition[],
	usedTestIds: string[],
	filterToEdit: DeletionPolicyFilterRead | undefined,
): DeletionFilterItem[] =>
	sortDeletionFilterItemArray([
		...tests.filter((test) => !usedTestIds.includes(test.id)),
		// We add the test of the policy to edit
		...(filterToEdit && filterToEdit.type === 'TEST'
			? [{ id: filterToEdit.testId, name: filterToEdit.testName ?? filterToEdit.testId }]
			: []),
	]);

const filterAndFormatProjects = (
	projects: ProjectScenarios[],
	usedProjects: ProjectConfig[],
	filterToEdit: DeletionPolicyFilterRead | undefined,
): DeletionFilterItem[] => {
	const filteredProjects = projects
		// We remove the projects that are already used and doesn't have any scenario left
		.filter(
			(projectScenarios) =>
				difference(
					new Set(projectScenarios.scenarios.map(({ name }) => name)),
					getUsedScenarios(usedProjects, projectScenarios.name),
				).size > 0,
		)
		// Here we put the name as id because the id is not available in the response and the name is unique
		.map(({ name }) => ({ id: name, name }));

	const projectToEdit =
		filterToEdit?.type === 'PROJECT' ? { id: filterToEdit.projectName, name: filterToEdit.projectName } : undefined;

	// We add the project of the policy to edit if it's not already in the list
	if (projectToEdit && !filteredProjects.some((item) => item.id === projectToEdit.id)) {
		filteredProjects.push(projectToEdit);
	}

	return sortDeletionFilterItemArray(filteredProjects);
};
const getUsedScenarios = (usedProjects: ProjectConfig[], selectedProjectName: string): Set<string> =>
	new Set(usedProjects.filter((p) => p.projectName === selectedProjectName).map(({ scenarioName }) => scenarioName));

const getScenariosOfProject = (
	searchCriteria: TestResultSearchCriteria | undefined,
	projectName: string | undefined,
): SimpleScenario[] => searchCriteria?.projects.find((p) => p.name === projectName)?.scenarios ?? [];

const filterAndFormatScenarios = (
	scenarios: SimpleScenario[],
	selectedProjectName: string | undefined,
	usedProjects: ProjectConfig[],
	filterToEdit: DeletionPolicyFilterRead | undefined,
): DeletionFilterItem[] => {
	if (!selectedProjectName) {
		return [];
	}

	const usedScenarios = getUsedScenarios(usedProjects, selectedProjectName);
	return sortDeletionFilterItemArray([
		...scenarios
			.filter((scenario) => !usedScenarios.has(scenario.name))
			// Here we put the name as id because the id is not available in the response and the name is unique
			.map(({ name }) => ({ id: name, name })),
		// We add the scenario of the policy to edit if the selected project is the same as the project of the policy
		...(filterToEdit && filterToEdit.type === 'PROJECT' && filterToEdit.projectName === selectedProjectName
			? [{ id: filterToEdit.scenarioName, name: filterToEdit.scenarioName }]
			: []),
	]);
};

const getUsedFilters = (policies: DeletionPolicy[]): UsedFilters => {
	const usedTestIds = policies
		.filter((policy) => policy.deletionFilter.type === 'TEST')
		.map((policy) => policy.deletionFilter as DeletionPolicyFilterByTest)
		.map((filter) => filter.testId);

	const usedProjects = policies
		.filter((policy) => policy.deletionFilter.type === 'PROJECT')
		.map((policy) => policy.deletionFilter as DeletionPolicyFilterByProject)
		.map((filter) => ({
			projectName: filter.projectName,
			scenarioName: filter.scenarioName,
		}));

	const usedPatterns = policies
		.filter((policy) => policy.deletionFilter.type === 'RESULT_NAME')
		.map((policy) => policy.deletionFilter as DeletionPolicyFilterByResultName)
		.map((filter) => filter.resultNamePattern);

	return { usedTestIds, usedProjects, usedPatterns };
};

const getResultNamePattern = (filter: DeletionPolicyFilter | undefined): string | undefined =>
	filter?.type === 'RESULT_NAME' ? filter.resultNamePattern : undefined;

export {
	FilterType,
	SettingType,
	DeletionFilterItem,
	ProjectConfig,
	UsedFilters,
	filterAndFormatTests,
	filterAndFormatProjects,
	filterAndFormatScenarios,
	getUsedFilters,
	getScenariosOfProject,
	getResultNamePattern,
};

import React, { memo } from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { atLeastOne, atMostMaxInteger } from './custom-scenarios-helpers';
import { useCustomScenario } from '@neoload/hooks';
import { CustomPopulation, VuDistributions } from '@neoload/api';

type CustomScenariosVuProps = {
	population: CustomPopulation;
};

const shouldUpdate = (prevProps: CustomScenariosVuProps, nextProps: CustomScenariosVuProps) =>
	prevProps.population.name === nextProps.population.name &&
	prevProps.population.vus === nextProps.population.vus &&
	prevProps.population.vuDistribution === nextProps.population.vuDistribution;
const CustomScenariosVu = memo(
	({ population }: CustomScenariosVuProps) => {
		const { t } = useTranslation(['test']);
		const { updatePopulation } = useCustomScenario();

		const updateVus = (users: number) => {
			updatePopulation(population.name, { vus: atMostMaxInteger(atLeastOne(users)) }, true);
		};

		return (
			<TextField
				label={
					population.vuDistribution === VuDistributions.RAMPUP
						? t('configuration.scenarios.custom.virtualUsersMax')
						: t('configuration.scenarios.custom.virtualUsers')
				}
				size='small'
				data-trackingid='custom-scenario-virtual-users'
				value={population.vus}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateVus(Number.parseInt(event.target.value))}
				sx={{ width: '140px' }}
				type='number'
			/>
		);
	},
	(prevProps, nextProps) => shouldUpdate(prevProps, nextProps),
);

export { CustomScenariosVu };

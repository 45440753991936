import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import { CreateReservationForm, ReservationZoneFormData } from './reservation-helper';
import { ZoneRow } from '../../../../tests/configuration/zones/zone-row';
import { Zone } from '@neoload/api';

const ReservationZones = () => {
	const { t } = useTranslation(['test']);
	const { setValue, getValues } = useFormContext<CreateReservationForm>();
	const zoneTypeSelected = useWatch<CreateReservationForm, 'zones.type'>({ name: 'zones.type' });
	const controllerSelected = useWatch<CreateReservationForm, 'zones.controller'>({ name: 'zones.controller' });
	const zones = useWatch<CreateReservationForm, 'zones.items'>({ name: 'zones.items' });

	const [previous, setPrevious] = useState<{
		zoneType: Zone['type'] | '';
		zones: ReservationZoneFormData[];
	}>({ zoneType: '', zones: [] });

	const filterZones = (zones: ReservationZoneFormData[], zoneTypeSelected: Zone['type']) =>
		zones.filter((value) => value.type === zoneTypeSelected);

	const handleReservationZoneTypeChange = useCallback(() => {
		const filteredZones = filterZones(zones, zoneTypeSelected);
		for (let index = 0; index < zones.length; index++) {
			setValue(`zones.items.${index}.number`, 0);
		}
		const zoneNumber = filteredZones.length;
		const zoneId = filteredZones[0].zoneId;
		if (zoneNumber > 0) {
			setValue('zones.controller', zoneId);
		}
		if (zoneNumber === 1) {
			const z = filteredZones[0];
			z.number = 1;
			const index = zones.findIndex((value) => value.zoneId === zoneId);
			setValue(`zones.items.${index}.number`, 1);
		}
	}, [setValue, zoneTypeSelected, zones]);

	const handleReservationZoneChange = useCallback(() => {
		const filteredZones = filterZones(zones, zoneTypeSelected);
		if (zoneTypeSelected === 'CLOUD' && filteredZones.length > 0) {
			const maxZoneId = filteredZones.toSorted((a, b) => b.number - a.number)[0].zoneId;
			if (controllerSelected !== maxZoneId) {
				setValue('zones.controller', maxZoneId);
			}
		}
	}, [controllerSelected, setValue, zoneTypeSelected, zones]);

	useEffect(() => {
		if (previous.zoneType !== '' && previous.zoneType !== zoneTypeSelected) {
			handleReservationZoneTypeChange();
		}

		if (previous.zones.length > 0) {
			handleReservationZoneChange();
		}

		setPrevious(() => ({
			zoneType: zoneTypeSelected,
			zones: zones,
		}));
	}, [
		zoneTypeSelected,
		previous.zoneType,
		setValue,
		handleReservationZoneTypeChange,
		previous.zones.length,
		handleReservationZoneChange,
		zones,
	]);

	const filteredZones = filterZones(zones, zoneTypeSelected);
	if (filteredZones.length === 0) {
		const noZoneMessage =
			zoneTypeSelected === 'DYNAMIC' ? t('configuration.zones.noZones.dynamic') : t('configuration.zones.noZones.ncp');
		return (
			<Box>
				<Typography variant='body1'>{noZoneMessage}</Typography>
			</Box>
		);
	}

	return (
		<Stack gap={1} useFlexGap sx={{ minWidth: '500px' }} id='zones-list'>
			<Stack direction='row'>
				<Typography variant='caption' sx={{ flex: '1', marginLeft: 2 }}>
					{t('configuration.zones.name')}
				</Typography>
				<Typography variant='caption' sx={{ width: '96px' }}>
					{t('configuration.zones.lg')}
				</Typography>
				<Typography variant='caption' sx={{ width: '70px' }}>
					{t('configuration.zones.controller')}
				</Typography>
			</Stack>
			{zones.map(
				(zone, index) =>
					zone.type === zoneTypeSelected && (
						<ZoneRow
							key={zone.zoneId}
							zone={zone}
							disabledRemoved
							selectedZoneType={zoneTypeSelected}
							controller={getValues('zones.controller')}
							setLgs={(value) => {
								setValue(`zones.items.${index}.number`, value);
							}}
							setController={(value) => {
								setValue('zones.controller', value);
							}}
						/>
					),
			)}
		</Stack>
	);
};

export { ReservationZones };

import { RtkErrorResponse, isV4InvaliPropertyError } from './neoload-error';
import { InvalidPropertyValueError, InvalidPropertyValueErrorDescription } from '@neoload/api';

const sources = <const>['elementValues', 'monitors', 'percentiles', 'percentilesAndMonitors', 'unknown'];
type IntervalIncompatibility = {
	source: (typeof sources)[number];
	level: 'warning' | 'error';
	actual?: number;
	maximum?: number;
	percentage?: number;
};

const isIntervalLimitErrorDescription = (errorDescription: InvalidPropertyValueErrorDescription) =>
	errorDescription.validationType === 'limit';

/**
 * Interval creation/update and values filtering on interval are limited for performance purposes and might trigger 400 error.
 * According to cases, conditions may differ.
 * This method returns true if this error is an InvalidPropertyValueError and at least one of its error descriptions has
 * 'validationType': 'limit'
 * You may call this method to identify and handle interval limitations differently than other errors
 * @param error the error given by rtk
 */
const isIntervalLimitError = (error: RtkErrorResponse): error is { status: 400; data: InvalidPropertyValueError } => {
	if (isV4InvaliPropertyError(error) && error.data.errors) {
		return error.data.errors.some((errorDescription) => isIntervalLimitErrorDescription(errorDescription));
	}
	return false;
};

/**
 * Extract from the given error the interval incompatibilities
 * @param error the error given by rtk
 * @returns The list of incompatibilities. Or undefined if there is no incompatibilities
 */
const extractIntervalIncompatibilities = (error: RtkErrorResponse): IntervalIncompatibility[] | undefined =>
	isIntervalLimitError(error) ? errorToIncompatibility(error.data) : undefined;

const errorToIncompatibility = (error: InvalidPropertyValueError): IntervalIncompatibility[] =>
	(error.errors ?? [])
		.filter((errorDescription) => isIntervalLimitErrorDescription(errorDescription))
		.map((errorDescription) => buildIncompatibility(errorDescription.messageProperties));

const buildIncompatibility = (messageProperties: { [key: string]: string }): IntervalIncompatibility => {
	const source = sources.find((s) => s === messageProperties['source']) ?? 'unknown';
	const actual = Number.parseFloat(messageProperties['actual']);
	const maximum = Number.parseFloat(messageProperties['maximum']);
	const percentage = ((actual - maximum) / maximum) * 100;
	return {
		source,
		level: source === 'elementValues' ? 'error' : 'warning',
		actual: Number.isNaN(actual) ? undefined : actual,
		maximum: Number.isNaN(maximum) ? undefined : maximum,
		percentage: Number.isNaN(percentage) ? undefined : Math.ceil(percentage),
	};
};

/**
 * Check if there is at least an error incompatibility.
 * @param incompatibilities an array of incompatibilities to check.
 * @returns true if at least one of the incompatibilities has a level equals to 'error'
 */
const hasErrorLevel = (incompatibilities: IntervalIncompatibility[]): boolean =>
	incompatibilities.some((incompatibility) => incompatibility.level === 'error');

/**
 * Check if the input array is containing percentiles AND monitors incompatibilities
 * @param incompatibilities an array of incompatibilities to check.
 * @returns true if there is both monitors and percentiles warning incompatibilities into the input array.
 */
const hasMonitorsAndPercentilesWarningIncompatibilities = (incompatibilities: IntervalIncompatibility[]): boolean =>
	incompatibilities.filter((incompat) => incompat.source === 'monitors' || incompat.source === 'percentiles').length >=
	2;

const reduceIncompatibilities = (incompatibilities: IntervalIncompatibility[]): IntervalIncompatibility[] => {
	// If there is an error in the incompatibility list, we only want to display it
	if (hasErrorLevel(incompatibilities)) {
		return incompatibilities.filter((incompat) => incompat.level === 'error');
	}

	if (hasMonitorsAndPercentilesWarningIncompatibilities(incompatibilities)) {
		return [
			...incompatibilities.filter((incompat) => incompat.source !== 'monitors' && incompat.source !== 'percentiles'),
			{
				source: 'percentilesAndMonitors',
				level: 'warning',
			},
		];
	}

	return incompatibilities;
};

export {
	IntervalIncompatibility,
	isIntervalLimitError,
	extractIntervalIncompatibilities,
	hasErrorLevel,
	reduceIncompatibilities,
};

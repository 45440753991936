import { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import { INFRASTRUCTURE_PROVIDER_TYPE_DISPLAY_NAMES } from '@neoload/utils';
import { InfrastructureProvider, useGetV4InfrastructureProvidersQuery } from '@neoload/api';

type SearchProviderComboboxProps = {
	initialProviderId?: string;
	onSelectedProviderChange: (providerId: string) => void;
};

const findProviderById = (providers: InfrastructureProvider[], id: string) =>
	providers.find((provider) => provider.id === id);

export const SearchProviderCombobox = ({
	initialProviderId,
	onSelectedProviderChange,
}: SearchProviderComboboxProps) => {
	const { t } = useTranslation(['zone']);
	const [items, setItems] = useState<InfrastructureProvider[]>([]);
	const [noProviderError, setNoProviderError] = useState(false);
	const [selectedProviderId, setSelectedProviderId] = useState<string | undefined>(initialProviderId);
	const { data: { items: providers } = {}, isLoading, error } = useGetV4InfrastructureProvidersQuery();

	useEffect(() => {
		if (providers) {
			setItems(providers);
		}
	}, [providers]);

	useEffect(() => {
		if (!selectedProviderId && items.length > 0) {
			const providerId = items[0].id;
			setSelectedProviderId(providerId);
			onSelectedProviderChange(providerId);
		}
	}, [items, onSelectedProviderChange, selectedProviderId]);

	useEffect(() => {
		setNoProviderError(!isLoading && items.length === 0);
	}, [isLoading, items.length]);

	const onChange = (event: SelectChangeEvent<string>) => {
		const providerId = event.target.value;
		setSelectedProviderId(event.target.value);
		onSelectedProviderChange(providerId);
	};

	return (
		<FormControl fullWidth variant='outlined'>
			<InputLabel required={true}>
				<Label isLoading={isLoading} />
			</InputLabel>
			<Select
				sx={{ width: '100%' }}
				value={selectedProviderId}
				onChange={onChange}
				renderValue={(id) => <ListItemText primary={findProviderById(items, id)?.name} sx={{ margin: 0 }} />}
				label={t('dynamicEdition.provider')}
				variant='outlined'
				size='small'
				error={!!error}
			>
				{items.map((provider) => (
					<MenuItem key={provider.id} value={provider.id}>
						<ListItemText primary={provider.name} />
						<ListItemText
							secondary={INFRASTRUCTURE_PROVIDER_TYPE_DISPLAY_NAMES[provider.type]}
							secondaryTypographyProps={{ marginLeft: '40px', align: 'right' }}
						/>
					</MenuItem>
				))}
			</Select>
			{!!error && <FormHelperText error>{t('dynamicEdition.providerLoadingError')}</FormHelperText>}
			{noProviderError && <FormHelperText error>{t('dynamicEdition.noProvidersError')}</FormHelperText>}
		</FormControl>
	);
};

const Label = ({ isLoading }: { isLoading: boolean }) => {
	const { t } = useTranslation(['zone']);
	if (isLoading) {
		return (
			<Box sx={{ position: 'relative' }}>
				{t('dynamicEdition.providerLoading')} <CircularProgress size='1em' />
			</Box>
		);
	}
	return t('dynamicEdition.provider');
};

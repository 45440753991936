import { FormProvider, useForm } from 'react-hook-form';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { DynamicZoneFormModalContent } from './dynamic-zone-form-modal-content';
import { DEFAULT_CREATION_FORM_VALUES } from './dynamic-zone-utils';
import { Spinner } from '../../../common/spinner';
import {
	DynamicZoneFields,
	DynamicZoneWithSettings,
	PostV4ZonesApiArg,
	PutV4ZonesByZoneIdApiArg,
	useGetV4ZonesByZoneIdQuery,
	usePostV4ZonesMutation,
	usePutV4ZonesByZoneIdMutation,
	ZoneWithSettingsRead,
} from '@neoload/api';
import { useCrudBatchMessage, useSetSnackbars } from '@neoload/hooks';

export type DynamicZoneFormProps = {
	open: boolean;
	close: () => void;
	editedZoneId?: string;
};

const isDynamicZoneFields = (zone: ZoneWithSettingsRead): zone is DynamicZoneWithSettings => zone.type === 'DYNAMIC';

const toDynamicZoneFields = ({
	controllerDockerImage,
	lgDockerImage,
	name,
	provider: { id },
	pullSecret,
	sizing,
	type,
}: DynamicZoneWithSettings): DynamicZoneFields => ({
	name,
	sizing,
	type,
	controllerDockerImage,
	lgDockerImage,
	pullSecret,
	providerId: id,
});

const getDefaultValues = (editedZone?: ZoneWithSettingsRead): DynamicZoneFields => {
	const dynamicEditedZone = editedZone && isDynamicZoneFields(editedZone) ? toDynamicZoneFields(editedZone) : undefined;
	return dynamicEditedZone ?? DEFAULT_CREATION_FORM_VALUES;
};

const toPutZoneArguments = (dynamicZoneFields: DynamicZoneFields, editedZoneId: string): PutV4ZonesByZoneIdApiArg => ({
	zoneId: editedZoneId,
	putZoneFields: {
		...dynamicZoneFields,
		type: 'DYNAMIC',
	},
});

const toPostZoneArguments = (dynamicZoneFields: DynamicZoneFields): PostV4ZonesApiArg => ({
	postZoneFields: {
		...dynamicZoneFields,
		type: 'DYNAMIC',
	},
});

export const DynamicZoneFormModal = ({ editedZoneId, open, close }: DynamicZoneFormProps) => {
	const { t } = useTranslation(['zone']);
	const { create, update } = useCrudBatchMessage('zone');
	const [postZone] = usePostV4ZonesMutation();
	const [putZone] = usePutV4ZonesByZoneIdMutation();
	const { showError } = useSetSnackbars();

	const {
		data: dynamicZonesWithSettings,
		isLoading: dynamicZonesLoading,
		error,
	} = useGetV4ZonesByZoneIdQuery({ zoneId: editedZoneId ?? '' }, { skip: !editedZoneId });

	const { formState, handleSubmit, reset, ...methods } = useForm<DynamicZoneFields>({
		mode: 'onChange',
		defaultValues: getDefaultValues(dynamicZonesWithSettings),
	});

	useEffect(() => {
		reset(getDefaultValues(editedZoneId ? dynamicZonesWithSettings : undefined));
	}, [dynamicZonesWithSettings, editedZoneId, reset]);

	const submitForm = (form: DynamicZoneFields) => {
		void (
			editedZoneId
				? update([toPutZoneArguments(form, editedZoneId)], (input) => putZone(input).unwrap())
				: create([toPostZoneArguments(form)], (input) => postZone(input).unwrap())
		).then(close);
	};

	useEffect(() => {
		if (error) {
			showError({ text: t('dynamicEdition.getZoneFailed') });
			close();
		}
	}, [close, error, showError, t]);

	return (
		<Dialog open={open} aria-labelledby='zone-creation-modal-title'>
			<FormProvider {...methods} formState={formState} handleSubmit={handleSubmit} reset={reset}>
				<form
					aria-label={editedZoneId ? t('editDynamicZone') : t('createDynamicZone')}
					onSubmit={handleSubmit(submitForm)}
					onReset={() => {
						reset();
						close();
					}}
				>
					<DialogTitle id='zone-creation-modal-title'>
						{editedZoneId ? t('editDynamicZone') : t('createDynamicZone')}
					</DialogTitle>
					<DialogContent sx={{ width: '600px' }}>
						{dynamicZonesLoading ? <Spinner /> : <DynamicZoneFormModalContent />}
					</DialogContent>
					<DialogActions>
						<Button type='reset' data-trackingid='dynamic-zone-cancel'>
							{t('common:cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							disabled={!formState.isValid || Object.keys(formState.errors).length > 0}
							data-trackingid='dynamic-zone-ok'
						>
							{t('common:apply')}
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
};

type PasswordFormProps = {
	password: {
		value: string;
		error?: string;
	};
};

const translatePasswordError = (password: string) => {
	if (!password || password.trim() === '') {
		return 'configuration.project.modalPassword.required';
	}
	return false;
};

export { translatePasswordError, PasswordFormProps };

import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { PostV4LicenseReleasesApiArg, usePostV4LicenseReleasesMutation } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';
import { invalidPropertyErrorMatches, isNotFoundError, isV4InvaliPropertyError } from '@neoload/utils';

type OfflineReleaseButtonProps = ButtonProps & {
	isLoading?: boolean;
};

export const OfflineReleaseButton = ({ isLoading = false, ...buttonProps }: OfflineReleaseButtonProps) => {
	const { t } = useTranslation(['workspace']);
	const { showError, showInfo } = useSetSnackbars();

	const [postRelease] = usePostV4LicenseReleasesMutation();
	const handleFileSelected = async (file: File) => {
		if (file.name.endsWith('.rls')) {
			const body = new FormData();
			body.append('releaseFile', file as Blob);
			await postRelease({ body: body as unknown as PostV4LicenseReleasesApiArg['body'] })
				.unwrap()
				.then(() => showInfo({ text: t('leases.offlineReleaseSuccess') }))
				.catch((error) => {
					if (isNotFoundError(error)) {
						showError({ text: t('leases.licenseNotFound') });
					} else if (isV4InvaliPropertyError(error) && invalidPropertyErrorMatches(error.data, 'data', {})) {
						showError({ text: t('leases.offlineReleaseNotParseable') });
					} else {
						showError({ text: t('leases.offlineReleaseFail') });
					}
				});
		} else {
			showError({ text: t('leases.offlineReleaseInvalidExtension') });
		}
	};

	return (
		<Button
			component='label'
			sx={{ marginLeft: 1 }}
			variant='outlined'
			{...buttonProps}
			startIcon={isLoading && <CircularProgress size={24.5} color='inherit' />}
		>
			{t('tabs.subscription.leases.offlineRelease')}
			<input
				type='file'
				data-testid='releaseInputFile'
				hidden
				accept='.rls'
				disabled={buttonProps.disabled}
				onChange={async (event) => {
					const files = (event.target as HTMLInputElement).files;
					if (files?.[0]) {
						await handleFileSelected(files?.[0]);
					}
				}}
			/>
		</Button>
	);
};

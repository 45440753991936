import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Outlet, useLocation, useNavigate } from 'react-router';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { MenuItem, MenuItemProps } from './menu-item';
import { AppBar, ApplicationLoader, DocumentTitleHandler, Modal, StackedSnackbars } from '@neoload/ui';
import { CommonRoutes, HomeRoutes } from '@neoload/utils';
import { useGetMe } from '@neoload/hooks';

type AbstractSettingsLayoutProps = {
	title: string;
	menuItems: MenuItemProps[];
};

const Layout = ({ title, menuItems = [] }: AbstractSettingsLayoutProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();
	const { t } = useTranslation(['common']);
	const [{ hasTesterPermissions, hasAdminPermissions }] = useGetMe();

	const outerLayoutHeight = `calc(100vh - ${theme.constants.appBar.height.xl}px)`;
	//prettier-ignore
	const innerLayoutHeight = `calc(100vh - ${theme.constants.appBar.height.xl}px - ${theme.spacing(6)})`;
	const innerLayoutWidth = `calc(100vw - 256px - ${theme.spacing(8)})`;

	useEffect(() => {
		if (location.pathname === CommonRoutes.settings.base || location.pathname === CommonRoutes.settings.base + '/') {
			if (hasAdminPermissions) {
				navigate(CommonRoutes.settings.workspaces.base);
			} else if (hasTesterPermissions) {
				navigate(CommonRoutes.settings.zones.base);
			}
		}
	});

	return (
		<>
			<AppBar withWorkspaceSelector={false} />
			<Box
				sx={{
					paddingTop: '40px',
					width: '100%',
					display: 'grid',
					gridTemplateColumns: '1fr',
					gridTemplateRows: '39px 1fr',
					rowGap: '1px',
					height: '100vh',
					backgroundColor: theme.palette.aura.backgroundDefault,
				}}
			>
				<Box
					sx={{
						height: outerLayoutHeight,
						padding: theme.spacing(3),
					}}
				>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: 'minmax(256px, max-content) 1fr',
							gap: '16px',
							height: innerLayoutHeight,
						}}
					>
						<Paper>
							<Box>
								<Box sx={{ display: 'flex', alignItems: 'center', py: 1, px: 1.5 }}>
									<Tooltip title={t('goBack')} arrow>
										<IconButton
											sx={{ p: 1 }}
											onClick={(_event) => (location.key === 'default' ? navigate(HomeRoutes.base) : navigate(-1))}
										>
											<ArrowBackOutlined />
										</IconButton>
									</Tooltip>
									<Typography variant='subtitle1'>{title}</Typography>
								</Box>
								<List>
									{menuItems
										.filter((props) => props.enabled === undefined || props.enabled)
										.map((props) => (
											<MenuItem key={props.title} {...props} />
										))}
								</List>
							</Box>
						</Paper>
						<Paper>
							<Box sx={{ height: innerLayoutHeight, width: innerLayoutWidth }}>
								<Outlet />
							</Box>
						</Paper>
					</Box>
				</Box>
			</Box>
			<DocumentTitleHandler />
			<Modal />
			<StackedSnackbars />
		</>
	);
};

export const AbstractSettingsLayout = (props: AbstractSettingsLayoutProps) => {
	const [{ isLoaded }] = useGetMe();
	return isLoaded ? <Layout {...props} /> : <ApplicationLoader />;
};

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

export const DashboardTileEmptyContent = () => {
	const { t } = useTranslation(['dashboard']);

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			<Typography align='center' variant='body2'>
				{t('tile.empty')}
			</Typography>
		</Box>
	);
};

import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { AbstractSettingsLayout } from './abstract-settings-layout';
import { MenuItemProps } from './menu-item';
import { callCompanionIdentifyIfNeeded, UserRoutes } from '@neoload/utils';
import { useGetMe } from '@neoload/hooks';

const UserLayout = () => {
	const { t } = useTranslation(['menu']);

	const title = t('profile');
	const [userInfo] = useGetMe();

	const menuItems: MenuItemProps[] = [
		{
			title: t('profileDetails'),
			icon: <AccountCircleOutlinedIcon />,
			link: UserRoutes.profile,
			enabled: true,
		},
		{
			title: t('accessTokens'),
			icon: <KeyOutlinedIcon />,
			link: UserRoutes.accessTokens,
			enabled: true,
		},
		{
			title: t('gitRepositories'),
			icon: <BookOutlinedIcon />,
			link: UserRoutes.gitRepositories,
			enabled: userInfo.hasTesterPermissions,
		},
	];

	useEffect(() => {
		const timer = setTimeout(() => callCompanionIdentifyIfNeeded(userInfo.id), 5000);
		return () => clearTimeout(timer);
	}, [userInfo.id]);

	return <AbstractSettingsLayout title={title} menuItems={menuItems} />;
};

export { UserLayout };

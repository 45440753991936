import { ActionsCellItem } from './actions-cell-item';
import { DatagridAction, toSingleItemAction } from './data-grid-actions';

export type DatagridActionCellProps = {
	actions: DatagridAction[];
	rowData: unknown;
};
const ActionsCell = ({ actions, rowData }: DatagridActionCellProps) => (
	<div>
		{actions.map((actionProps) => {
			const cellItem = toSingleItemAction(actionProps, rowData);
			return <ActionsCellItem key={cellItem.text} {...cellItem} />;
		})}
	</div>
);

export { ActionsCell };

import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import i18n from 'i18next';
import { withLazyFetching, WithLazyFetchingProps } from './with-lazy-fetching';
import { useWidgetDataFetcher } from './hooks/use-widget-data-fetcher';
import { Spinner } from '../../../common/spinner';
import { DashboardTileErrorContent } from '../tiles/dashboard-tile-error-content';
import { SummaryTilesGrid } from '../../../common/summary-tiles-grid';
import { DashboardTileUnloadedContent } from '../tiles/dashboard-tile-unloaded-content';
import { CsvDownloadContext } from '../tiles/tile/csv-download-context';
import { TextCell } from '../../../common/table-grid';
import {
	GetV4ResultsByResultIdApiArg,
	GetV4ResultsByResultIdApiResponse,
	useLazyGetV4ResultsByResultIdQuery,
	WidgetDashboardTile,
} from '@neoload/api';
import { timeUtils } from '@neoload/utils';

type SummaryFetcherProps = {
	widgetTile: WidgetDashboardTile;
} & WithLazyFetchingProps;

const columns = [
	{ label: i18n.t('summary.testName', { ns: 'common' }), type: 'text' },
	{ label: i18n.t('summary.testId', { ns: 'common' }), type: 'text' },
	{ label: i18n.t('summary.resultName', { ns: 'common' }), type: 'text' },
	{ label: i18n.t('summary.resultId', { ns: 'common' }), type: 'text' },
	{ label: i18n.t('summary.status', { ns: 'common' }) },
	{ label: i18n.t('summary.startDate', { ns: 'common' }) },
	{ label: i18n.t('summary.endDate', { ns: 'common' }) },
	{ label: i18n.t('summary.duration', { ns: 'common' }) },
	{ label: i18n.t('summary.project', { ns: 'common' }) },
	{ label: i18n.t('summary.scenario', { ns: 'common' }) },
];

export const InternalSummaryFetcher = ({ shouldStartFetching, widgetTile }: SummaryFetcherProps) => {
	const { t } = useTranslation(['dashboard']);
	const [triggerGetResult] = useLazyGetV4ResultsByResultIdQuery();
	const { setDataToDownload } = useContext(CsvDownloadContext);
	const [data, error, loadingState] = useWidgetDataFetcher<
		GetV4ResultsByResultIdApiArg,
		GetV4ResultsByResultIdApiResponse
	>(
		widgetTile,
		{
			resultId: widgetTile.resultId,
		},
		shouldStartFetching,
		triggerGetResult
	);

	useEffect(() => {
		const values: TextCell[][] = [
			[
				{ text: widgetTile.testName ?? '' },
				{ text: widgetTile.testId ?? '' },
				{ text: widgetTile.resultName },
				{ text: widgetTile.resultId },
				{ text: data?.status ?? '' },
				{ text: timeUtils.dateTimeAbsolute(data?.startDate ?? null) },
				{ text: timeUtils.dateTimeAbsolute(data?.endDate ?? null) },
				{ text: timeUtils.durationSeconds(data?.duration ?? 0) },
				{ text: data?.project ?? '' },
				{ text: data?.scenario ?? '' },
			],
		];

		if (loadingState === 'LOADED' && data) {
			setDataToDownload({
				columns,
				values: values,
			});
		}
	}, [
		loadingState,
		data,
		setDataToDownload,
		widgetTile.testName,
		widgetTile.testId,
		widgetTile.resultName,
		widgetTile.resultId,
	]);

	if (loadingState === 'UNLOADED') {
		return <DashboardTileUnloadedContent />;
	}
	if (loadingState === 'LOADING') {
		return <Spinner />;
	}
	if (error || !data) {
		if (error) {
			console.error(
				t('widget.error.global', {
					tileId: widgetTile.id,
				}),
				error
			);
		}
		return <DashboardTileErrorContent />;
	}

	return (
		<SummaryTilesGrid
			result={{ id: data.id, name: data.name }}
			test={data.test?.name ? { id: data.test.id, name: data.test.name } : undefined}
			status={data}
			startDate={timeUtils.dateTimeAbsolute(data.startDate)}
			endDate={timeUtils.dateTimeAbsolute(data.endDate ?? null)}
			duration={timeUtils.durationSeconds(data.duration)}
			project={data.project}
			scenario={data.scenario}
		/>
	);
};

export const VisibleForTesting = InternalSummaryFetcher;
export const SummaryFetcher = withLazyFetching(InternalSummaryFetcher);

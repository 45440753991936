import { generatePath } from 'react-router';

const patterns = {
	byId: '/reports/:resultId',
};

const reportRoutes = {
	patterns: patterns,
	base: patterns.byId,
	report: (resultId: string) => generatePath(patterns.byId, { resultId: resultId }),
};

export type ReportGenericParams = {
	resultId: string;
};

export { reportRoutes as ReportRoutes };

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import Paper, { PaperProps } from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { AddMembersFormFields } from '../workspace-add-member-modal';
import { User } from '@neoload/api';

type MemberComboboxPaperComponentProps = PaperProps & {
	availableUsers: User[];
};

const MemberComboboxPaperComponent = ({
	children,
	availableUsers,
	...paperProps
}: MemberComboboxPaperComponentProps) => {
	const { t } = useTranslation('workspace');
	const { watch, setValue } = useFormContext<AddMembersFormFields>();
	const selectedUsers = watch('selectedUsers');
	const selectableUsers = availableUsers.filter((user) => user.status !== 'PENDING');
	const selectedAllUsers = (selected: boolean) => {
		setValue('selectedUsers', selected ? selectableUsers : []);
	};

	const allUsersAreSelected = selectableUsers.length === selectedUsers.length;
	const atLeastOneSelectableUser = selectableUsers.length > 0;

	return (
		<Paper {...paperProps}>
			{children}
			{atLeastOneSelectableUser && (
				<>
					<Divider />
					<MenuItem
						sx={{ width: '100%' }}
						//onMouseDown to prevent closing of popup
						onMouseDown={(event) => {
							selectedAllUsers(!allUsersAreSelected);
							event.stopPropagation();
							event.preventDefault();
						}}
					>
						<Checkbox sx={{ marginRight: 1 }} checked={allUsersAreSelected} />
						<ListItemText primary={t(allUsersAreSelected ? 'members.deselectAll' : 'members.selectAll')} />
					</MenuItem>
				</>
			)}
		</Paper>
	);
};

export { MemberComboboxPaperComponent };

import yaml from 'js-yaml';
import Ajv from 'ajv';
import React from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { TFunction } from 'i18next';
import FormHelperText from '@mui/material/FormHelperText';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { CreateProviderRequestWrite } from '@neoload/api';

const tolerationSchema = {
	type: 'array',
	items: {
		type: 'object',
		required: ['key', 'operator', 'effect'],
		properties: {
			key: { type: 'string' },
			operator: { type: 'string', enum: ['Exists', 'Equal'] },
			value: { type: 'string' },
			effect: { type: 'string', enum: ['NoSchedule', 'PreferNoSchedule', 'NoExecute'] },
			tolerationSeconds: { type: 'number' },
		},
	},
};

const ajv = new Ajv();
const validateYaml = ajv.compile(tolerationSchema);

const validate = (tolerations: string | undefined, t: TFunction): string | undefined => {
	if (tolerations) {
		try {
			const tolerationsData = yaml.load(tolerations);
			if (!validateYaml(tolerationsData)) {
				return t('modal.invalidSchema');
			}
		} catch {
			return t('modal.invalidYaml');
		}
	}
	return undefined;
};

export const TolerationInput = () => {
	const { t } = useTranslation('infrastructureProviders');
	const { register } = useFormContext<CreateProviderRequestWrite>();
	const theme = useTheme();

	const [warning, setWarning] = React.useState<string | undefined>(undefined);

	const onValidate = (value?: string) => {
		setWarning(validate(value, t));
		return undefined;
	};

	return (
		<Stack direction='column' sx={{ width: '100%' }}>
			<TextField
				size='small'
				InputLabelProps={{ shrink: true }}
				placeholder={t('modal.tolerationPlaceHolderText')}
				rows={5}
				multiline={true}
				label={t('modal.tolerations')}
				fullWidth
				InputProps={{ inputProps: { maxLength: 4096 } }}
				{...register('tolerations', { validate: (value) => onValidate(value) })}
			/>
			{warning && (
				<FormHelperText sx={{ marginLeft: 2, color: theme.palette.warning.main }} color={theme.palette.warning.main}>
					{warning}
				</FormHelperText>
			)}
		</Stack>
	);
};

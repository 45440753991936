import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import i18n from 'i18next';
import { useWidgetDataFetcher } from './hooks/use-widget-data-fetcher';
import { withLazyFetching, WithLazyFetchingProps } from './with-lazy-fetching';
import { Spinner } from '../../../common/spinner';
import { Cell, TableGrid } from '../../../common/table-grid';
import {
	ColumnDefinition,
	columnDefinitionsToColumns,
	sortToSortColumnIndex,
	sortToSortDirection,
} from '../dashboard-common';
import { DashboardTileErrorContent } from '../tiles/dashboard-tile-error-content';
import { DashboardTileUnloadedContent } from '../tiles/dashboard-tile-unloaded-content';
import { CsvDownloadContext } from '../tiles/tile/csv-download-context';
import {
	ElementsValuesFilter,
	GetV4ResultsByResultIdElementsValuesApiArg,
	GetV4ResultsByResultIdElementsValuesApiResponse,
	ResultElementValue,
	useLazyGetV4ResultsByResultIdElementsValuesQuery,
	WidgetDashboardTile,
} from '@neoload/api';

type TopDurationFetcherProps = {
	widgetTile: WidgetDashboardTile;
} & WithLazyFetchingProps;

const DurationNames = ['maximumDuration', 'averageDuration'] as const;
type DurationType = (typeof DurationNames)[number];

const isDuration = (durationType: string): durationType is DurationType =>
	// Does match with typescript.
	// eslint-disable-next-line unicorn/prefer-includes
	DurationNames.some((value) => durationType === value);

const extractAggregationType = (sort: string): DurationType | null => {
	if (!sort) {
		return null;
	}
	const durationType = sort.slice(1);
	if (isDuration(durationType)) {
		return durationType;
	}
	return null;
};

export const InternalTopDurationFetcher = ({ shouldStartFetching, widgetTile }: TopDurationFetcherProps) => {
	const { t } = useTranslation(['dashboard']);
	const { setDataToDownload } = useContext(CsvDownloadContext);
	const [triggerGetValues] = useLazyGetV4ResultsByResultIdElementsValuesQuery();
	const { sort, elementType, pageSize } = widgetTile.filter as ElementsValuesFilter;
	const [data, error, loadingState] = useWidgetDataFetcher<
		GetV4ResultsByResultIdElementsValuesApiArg,
		GetV4ResultsByResultIdElementsValuesApiResponse
	>(
		widgetTile,
		{
			resultId: widgetTile.resultId,
			pageSize,
			elementType,
			sort,
		},
		shouldStartFetching,
		triggerGetValues,
	);
	useEffect(() => {
		if (loadingState === 'LOADED') {
			const durationType = extractAggregationType(sort);
			if (durationType) {
				const values = data?.items.map((value) => valueToTableRow(value, durationType, true));
				if (values) {
					setDataToDownload({
						columns: [
							{ label: t('widget.duration.userPath') },
							{ label: t('widget.duration.element') },
							{ label: t('widget.duration.parent') },
							{ label: t(`widget.duration.${durationType}Csv`) },
						],
						values,
					});
				}
			}
		}
	}, [loadingState, data, setDataToDownload, sort, t]);

	const aggregationType = extractAggregationType(sort);

	if (loadingState === 'UNLOADED') {
		return <DashboardTileUnloadedContent />;
	}
	if (loadingState === 'LOADING') {
		return <Spinner />;
	}
	if (error || !data || !aggregationType) {
		if (error) {
			console.error(
				t('widget.error.global', {
					tileId: widgetTile.id,
				}),
				error,
			);
		}
		return <DashboardTileErrorContent />;
	}

	const columnDefs: ColumnDefinition[] = [
		{
			name: 'USERPATH',
			i18nKey: 'widget.duration.userPath',
		},
		{
			name: 'ELEMENT',
			i18nKey: 'widget.duration.element',
		},
		{
			name: 'PARENT',
			i18nKey: 'widget.duration.parent',
		},
		{
			name: aggregationType,
			i18nKey: `widget.duration.${aggregationType}`,
			align: 'right',
		},
	];

	return (
		<TableGrid
			columns={columnDefinitionsToColumns(columnDefs, t)}
			sortColumnIndex={sortToSortColumnIndex(columnDefs, sort)}
			sortDirection={sortToSortDirection(sort)}
			values={valuesArrayToTable(data.items, aggregationType)}
		/>
	);
};

export const TopDurationFetcher = withLazyFetching(InternalTopDurationFetcher);

function valuesArrayToTable(valueList: ResultElementValue[], type: DurationType): Cell[][] {
	const rows = [];
	for (const value of valueList) {
		rows.push(valueToTableRow(value, type, false));
	}
	return rows;
}

export function valueToTableRow(value: ResultElementValue, type: DurationType, forCsvExport: boolean): Cell[] {
	let decimalValue: number | undefined;
	switch (type) {
		case 'averageDuration': {
			decimalValue = value.statisticsValues.AVERAGE_DURATION;
			break;
		}
		case 'maximumDuration': {
			decimalValue = value.statisticsValues.MAXIMUM_DURATION;
			break;
		}
		default: {
			console.warn('Unhandled duration type', type);
			break;
		}
	}
	if (forCsvExport) {
		const finalValue = decimalValue ? decimalValue.toString() : '';
		return [
			{ text: value.userPath ?? '' },
			{ text: value.name ?? '' },
			{ text: value.parent ?? '' },
			{ text: finalValue },
		];
	}

	const finalValue = decimalValue ? decimalFormat.format(decimalValue) : '';
	return [
		{ text: value.userPath ?? '' },
		{ text: value.name ?? '' },
		{ text: value.parent ?? '' },
		{ text: finalValue },
	];
}
const decimalFormat = new Intl.NumberFormat(i18n.language, {
	style: 'unit',
	unit: 'second',
	unitDisplay: 'narrow',
});

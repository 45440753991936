import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import isURL from 'validator/lib/isURL';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {
	ApiError,
	CreateRepository,
	InvalidPropertyValueErrorDescription,
	isInvalidPropertyError,
	Repository,
	usePatchV4ScmRepositoriesByRepositoryIdMutation,
	usePostV4ScmRepositoriesMutation,
} from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

type GitRepoFormProps = {
	open: boolean;
	onClose: () => void;
	editRepository?: Repository;
};

const defaultFormValues: CreateRepository = {
	name: '',
	url: 'https://',
};

type AlertState = {
	active: boolean;
	alertText?: string;
};

const GitRepoUpdateModal = ({ open, onClose, editRepository }: GitRepoFormProps) => {
	const { t } = useTranslation(['user']);
	const { showInfo } = useSetSnackbars();
	const [createRepository, { isLoading: isCreating }] = usePostV4ScmRepositoriesMutation();
	const [updateRepository, { isLoading: isEditing }] = usePatchV4ScmRepositoriesByRepositoryIdMutation();
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [isPrivate, setIsPrivate] = useState<boolean>(false);
	const [canEditCredentials, setCanEditCredentials] = useState<boolean>(true);
	const [alertState, setAlertState] = useState<AlertState>({ active: false });

	const setAlertFromError = (error: InvalidPropertyValueErrorDescription) => {
		const messageProperties = error.messageProperties;
		if ('invalid' in messageProperties) {
			switch (messageProperties['invalid']) {
				case 'scm.url': {
					setAlertState({ active: true, alertText: t('repository.error.invalidUrl') });
					break;
				}
				case 'scm.credentials': {
					setAlertState({
						active: true,
						alertText: isPrivate ? t('repository.error.invalidCredentials') : t('repository.error.missingCredentials'),
					});
					break;
				}
				case 'scm.host': {
					setAlertState({
						active: true,
						alertText: t('repository.error.unknownHost'),
					});
					break;
				}
				default: {
					setAlertState({
						active: true,
						alertText: editRepository ? t('repository.update.error') : t('repository.create.error'),
					});
				}
			}
		}
	};

	const manageUpdateError = (error: ApiError, isUpdate: boolean) => {
		if (isInvalidPropertyError(error) && error.errors !== undefined) {
			setAlertFromError(error.errors[0]);
		} else {
			setAlertState({
				active: true,
				alertText: isUpdate ? t('repository.update.error') : t('repository.create.error'),
			});
		}
		const errorLabel = isUpdate ? t('repository.update.error') : t('repository.create.error');
		console.log(errorLabel, error);
	};

	const onSubmit = async (form: CreateRepository, id?: string) => {
		id
			? await updateRepository({ updateRepository: form, repositoryId: id })
					.unwrap()
					.then((response) => {
						showInfo({
							text:
								response.references.length === 1
									? t('repository.update.successOneBranch')
									: t('repository.update.successMultipleBranches', { branchesNumber: response.references.length }),
						});
						onClose();
					})
					.catch((error) => {
						manageUpdateError(error.data, false);
					})
			: await createRepository({ createRepository: form })
					.unwrap()
					.then((response) => {
						showInfo({
							text:
								response.references.length === 1
									? t('repository.create.successOneBranch')
									: t('repository.create.successMultipleBranches', { branchesNumber: response.references.length }),
						});
						onClose();
					})
					.catch((error) => {
						manageUpdateError(error.data, true);
					});
	};

	const handleFormSubmit: SubmitHandler<CreateRepository> = (form) => {
		// IIFE to avoid nested ternaries (Sonar)
		const submitCredentials = (() => {
			// Changed credentials of private repo
			if (isPrivate && canEditCredentials) {
				return form.credentialsWithPassword;
			}
			// Removed credentials (private to public)
			if (!isPrivate && editRepository?.credentials) {
				return null;
			}
		})();
		void onSubmit({ ...form, credentialsWithPassword: submitCredentials }, editRepository?.id);
	};

	const isUpdating = isCreating || isEditing;

	const {
		register,
		handleSubmit,
		reset,
		trigger,
		formState: { errors, isValid },
	} = useForm<CreateRepository>({
		mode: 'onChange',
		defaultValues: defaultFormValues,
	});

	const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const changedValue = event.target.value === 'true';
		setIsPrivate(changedValue);
		// reset in case user types creds then check public option
		if (changedValue === false) {
			setCanEditCredentials(true);
			reset({ credentialsWithPassword: defaultFormValues.credentialsWithPassword });
		}
	};

	const passwordButtonLabel = showPassword ? t('repository.modal.passwordHide') : t('repository.modal.passwordShow');

	useEffect(() => {
		if (open && editRepository) {
			setIsPrivate(!!editRepository.credentials);
			setCanEditCredentials(!editRepository.credentials);
			reset({
				credentialsWithPassword: editRepository.credentials ?? null,
				...editRepository,
			});
		}
		if (!open) {
			setCanEditCredentials(true);
			setIsPrivate(false);
			setAlertState({ active: false });
			reset(defaultFormValues);
		}
	}, [reset, open, editRepository]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth aria-labelledby='repo-update-modal-title'>
			<form onSubmit={handleSubmit(handleFormSubmit)}>
				<DialogTitle id='repo-update-modal-title'>
					{editRepository ? t('repository.modal.editTitle') : t('repository.modal.addTitle')}
				</DialogTitle>
				<DialogContent>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 3,
							marginTop: 2,
						}}
					>
						<TextField
							autoFocus
							label={t('repository.name')}
							fullWidth
							required
							error={!!errors.name}
							helperText={errors.name?.message}
							{...register('name', {
								required: t('repository.error.nameRequired'),
								maxLength: { value: 140, message: t('repository.error.nameMaxSize') },
							})}
							size='small'
						/>

						<TextField
							label={t('repository.url')}
							fullWidth
							required
							error={!!errors.url}
							helperText={errors.url?.message}
							{...register('url', {
								required: t('repository.error.urlRequired'),
								maxLength: { value: 2048, message: t('repository.error.urlMaxSize') },
								validate: (value) =>
									// eslint-disable-next-line @typescript-eslint/naming-convention
									isURL(value, { protocols: ['http', 'https'], require_protocol: true, require_tld: false }) ||
									t('repository.error.urlFormat'),
							})}
							size='small'
						/>
					</Box>
					<Box sx={{ marginTop: 2, marginBottom: 1 }}>
						<FormLabel>{t('repository.radio.formLabel')}</FormLabel>
						<RadioGroup row value={isPrivate.toString()} onChange={handleVisibilityChange} sx={{ marginLeft: 2 }}>
							<FormControlLabel
								value='false'
								control={<Radio />}
								label={t('repository.radio.public')}
								// fix bug where validation disapear when switching back to public
								onChange={() => trigger(['name', 'url'])}
							/>
							<FormControlLabel value='true' control={<Radio />} label={t('repository.radio.private')} />
						</RadioGroup>
					</Box>
					{isPrivate && (
						<>
							{!canEditCredentials && (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'row',
									}}
								>
									<FormLabel sx={{ marginRight: 'auto' }}>{t('repository.credentials')}</FormLabel>
									<Button sx={{ marginTop: 0 }} onClick={() => setCanEditCredentials(true)}>
										{t('repository.modal.editCredentials')}
									</Button>
								</Box>
							)}
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: 3,
									marginY: 1,
								}}
							>
								<TextField
									label={t('repository.login')}
									fullWidth
									disabled={!canEditCredentials}
									error={!!errors.credentialsWithPassword?.login}
									{...register('credentialsWithPassword.login', {
										maxLength: { value: 500, message: t('repository.error.loginMaxSize') },
										onChange: () => {
											setCanEditCredentials(true);
										},
										validate: (value) => {
											if (!value && isPrivate) {
												return t('repository.error.loginCondition');
											}
											return true;
										},
									})}
									helperText={errors.credentialsWithPassword?.login?.message}
									size='small'
								/>

								<TextField
									label={t('repository.password')}
									fullWidth
									disabled={!canEditCredentials}
									type={showPassword ? 'text' : 'password'}
									error={!!errors.credentialsWithPassword?.password}
									{...register('credentialsWithPassword.password', {
										maxLength: { value: 500, message: t('repository.error.passwordMaxSize') },
										validate: (value) => {
											if (!value && canEditCredentials) {
												return t('repository.error.passwordCondition');
											}
											return true;
										},
									})}
									helperText={errors.credentialsWithPassword?.password?.message}
									InputProps={
										canEditCredentials
											? {
													endAdornment: (
														<InputAdornment position='end'>
															<Tooltip arrow title={passwordButtonLabel}>
																<IconButton
																	aria-label={passwordButtonLabel}
																	onClick={() => {
																		setShowPassword(!showPassword);
																	}}
																>
																	{showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
																</IconButton>
															</Tooltip>
														</InputAdornment>
													),
												}
											: undefined
									}
									size='small'
								/>
							</Box>
						</>
					)}
					{alertState.active && (
						<Alert severity='error' onClose={() => setAlertState({ active: false })}>
							{alertState.alertText}
						</Alert>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color='primary' data-trackingid='git-repo-update-modal-cancel'>
						{t('repository.modal.cancelButton')}
					</Button>
					<Button
						variant='contained'
						disabled={isUpdating || !isValid}
						startIcon={isUpdating ? <CircularProgress size={24.5} color='inherit' /> : null}
						type='submit'
						aria-label={editRepository ? t('common:save') : t('common:add')}
						data-trackingid='git-repo-update-modal-ok'
					>
						{editRepository ? t('common:save') : t('common:add')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export { GitRepoUpdateModal };

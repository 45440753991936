import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
	FieldUniquenessError,
	ForbiddenError,
	InvalidPropertyValueError,
	InvalidPropertyValueErrorDescription,
	isFetchBaseQueryError,
	MethodNotAllowedError,
	NotFoundError,
} from '@neoload/api';

type RtkErrorResponse = FetchBaseQueryError | SerializedError | undefined;

enum NeoLoadErrorType {
	Forbidden = 'forbidden',
	NotFound = 'notFound',
	ServerError = 'serverError',
	ServerNotReached = 'serverNotReached',
	Error = 'error',
}

type NeoLoadError = {
	type: NeoLoadErrorType;
	httpCode?: number;
	customMessageI18nKey?: 'badIdFormat';
	cause: Error;
};

const isNeoLoadError = (error: unknown): error is NeoLoadError =>
	error instanceof Object && 'type' in error && 'cause' in error;

const toNeoLoadType = (httpStatus: number): NeoLoadErrorType => {
	switch (httpStatus) {
		case 403: {
			return NeoLoadErrorType.Forbidden;
		}
		case 404: {
			return NeoLoadErrorType.NotFound;
		}
		default: {
			return NeoLoadErrorType.ServerError;
		}
	}
};

const getHttpCode = (error?: RtkErrorResponse): number | undefined => {
	if (error && 'status' in error && typeof error.status === 'number') {
		return error.status;
	}
	if (error && 'originalStatus' in error && typeof error.originalStatus === 'number') {
		return error.originalStatus;
	}
	return undefined;
};

/**
 * Convert error into a NeoLoadError.
 *
 * NeoLoadErrors are catched by the router and display an Error page according to its NeoLoadErrorType.
 *
 * Usage : see `libs/utils/README.md`
 *
 * @param error An error returned by the API client
 */
const createNeoLoadError = (error?: RtkErrorResponse): NeoLoadError => {
	const httpCode = getHttpCode(error);
	if (httpCode) {
		return {
			type: toNeoLoadType(httpCode),
			httpCode,
			cause: new Error(JSON.stringify(error)),
		};
	}
	return {
		type: NeoLoadErrorType.ServerNotReached,
		cause: new Error(JSON.stringify(error)),
	};
};

const isMethodNotAllowedError = (error: RtkErrorResponse): error is { status: 405; data: MethodNotAllowedError } =>
	isFetchBaseQueryError(error) && error.status === 405;

const isForbiddenError = (error: RtkErrorResponse): error is { status: 403; data: ForbiddenError } =>
	isFetchBaseQueryError(error) && error.status === 403;

const isNotFoundError = (error: RtkErrorResponse): error is { status: 404; data: NotFoundError } =>
	isFetchBaseQueryError(error) && error.status === 404;

const isV4InvaliPropertyError = (error: RtkErrorResponse): error is { status: 400; data: InvalidPropertyValueError } =>
	isFetchBaseQueryError(error) && error.status === 400;

const invalidPropertyErrorMatches = (
	error: InvalidPropertyValueError,
	validationType: string,
	expectedProperties: InvalidPropertyValueErrorDescription['messageProperties'],
) =>
	error.errors?.some(
		(errorDescription) =>
			errorDescription.validationType === validationType &&
			Object.entries(expectedProperties).every(
				([property, value]) => errorDescription.messageProperties?.[property] === value,
			),
	);

const isFieldUniquenessError = (error: RtkErrorResponse): error is { status: 409; data: FieldUniquenessError } =>
	isFetchBaseQueryError(error) && error.status === 409;

export {
	NeoLoadErrorType,
	NeoLoadError,
	RtkErrorResponse,
	isNeoLoadError,
	toNeoLoadType,
	createNeoLoadError,
	isForbiddenError,
	isNotFoundError,
	isV4InvaliPropertyError,
	isFieldUniquenessError,
	invalidPropertyErrorMatches,
	isMethodNotAllowedError,
};

import { emptySplitFileApiV4 as api } from './file-api-v4';
const injectedRtkApi = api.injectEndpoints({
	endpoints: (build) => ({
		postV4TestsByTestIdProject: build.mutation<PostV4TestsByTestIdProjectApiResponse, PostV4TestsByTestIdProjectApiArg>(
			{
				query: (queryArg) => ({
					url: `/v4/tests/${queryArg.testId}/project`,
					method: 'POST',
					body: queryArg.uploadRequest,
				}),
			},
		),
	}),
	overrideExisting: false,
});
export { injectedRtkApi as nlwFileApiV4 };
export type PostV4TestsByTestIdProjectApiResponse = /** status 201 Entity successfully created. */ Project;
export type PostV4TestsByTestIdProjectApiArg = {
	/** UUID. */
	testId: string;
	uploadRequest: UploadRequest;
};
export type Population = {
	/** Name */
	name: string;
};
export type Scenario = {
	/** Name */
	name: string;
	/** Scenario duration (ISO 8601 format). */
	duration?: string;
	/** Maximum number of VU which can be expected during the scenario execution */
	maximumVu?: number;
};
export type FileUploadSettings = {
	source: 'FILE';
};
export type GitUploadSettings = {
	/** Reference name, prefixed with tags/ for tags */
	reference: string;
	/** ID */
	repositoryId: string;
	source: 'GIT';
	repositoryName?: string;
};
export type UploadSettings = FileUploadSettings | GitUploadSettings;
export type Project = {
	/** Name */
	name: string;
	/** Date and time in UTC when the entity was updated for the last time */
	updatedAt: string;
	/** List of population name */
	populations?: Population[];
	/** Scenarios extracted from the project */
	scenarios?: Scenario[];
	/** Security status of the project, if left empty, the project is not secured */
	securityStatus?: 'ENABLED' | 'DISABLED' | 'INVALID_PASSWORD';
	/** Size of the project in bytes */
	size?: number;
	uploadSettings?: UploadSettings;
	/** Identifier of the user who modified the entity */
	userModifierName?: string;
};
export type ApiError = {
	/** Define the category of the error, some categories can have additional fields */
	category: string;
	/** Description of the error */
	description: string;
};
export type InvalidPropertyValueErrorDescription = {
	/** Message describing the validation issue */
	message: string;
	/** Dynamic values used in the message */
	messageProperties: {
		[key: string]: string;
	};
	/** Validation type */
	validationType: string;
};
export type InvalidPropertyValueError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'INVALID_PROPERTY_VALUE';
	/** Description of the error */
	description?: string;
	/** Validation errors */
	errors?: InvalidPropertyValueErrorDescription[];
};
export type UnauthorizedError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'UNAUTHORIZED';
	/** Description of the error */
	description?: string;
};
export type ForbiddenError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'FORBIDDEN';
	/** Description of the error */
	description?: string;
};
export type NotFoundError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'NOT_FOUND';
	/** Description of the error */
	description?: string;
};
export type RequestTimeoutError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'REQUEST_TIMEOUT';
	/** Description of the error */
	description?: string;
};
export type PayloadTooLargeError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'PAYLOAD_TOO_LARGE';
	/** Description of the error */
	description?: string;
};
export type TooManyRequestsError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'TOO_MANY_REQUESTS';
	/** Description of the error */
	description?: string;
};
export type InternalServerError = ApiError & {
	/** Fixed value, it define the schema */
	category?: 'INTERNAL_SERVER_ERROR';
	/** Description of the error */
	description?: string;
};
export type UploadRequest = {
	file: Blob;
};
export const { usePostV4TestsByTestIdProjectMutation } = injectedRtkApi;

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

type DeletionSettingRadioButtonProps = {
	settingType: 'COUNT' | 'DURATION';
	onChange: (value: number) => void;
	defaultValue?: number;
	disabled?: boolean;
	invalid?: boolean;
};

const DeletionSettingRadioButton = ({
	settingType,
	onChange,
	defaultValue = 0,
	disabled = false,
	invalid = false,
}: DeletionSettingRadioButtonProps) => {
	const { t } = useTranslation('workspace', { keyPrefix: 'deletionPolicies.create.setting' });
	const lowerSettingType = settingType.toLowerCase();

	return (
		<Stack key={settingType} direction='row' alignItems='center' gap={1}>
			<FormControlLabel
				value={settingType}
				control={<Radio />}
				label={t(`${lowerSettingType}.label`)}
				sx={{ marginRight: '0' }}
			/>
			<TextField
				type='number'
				sx={{ width: '71px' }}
				size='small'
				disabled={disabled}
				required={true}
				error={invalid}
				defaultValue={defaultValue}
				inputProps={{
					min: 1,
					max: 20_000,
					'aria-label': lowerSettingType,
				}}
				onChange={(event) => onChange(Number.parseFloat(event.target.value))}
			/>
			<Typography>{t(`${lowerSettingType}.unit`)}</Typography>
		</Stack>
	);
};

export { DeletionSettingRadioButton };

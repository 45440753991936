import { useTranslation } from 'react-i18next';
import IconContentCopyOutlined from '@tricentis/aura/components/IconContentCopyOutlined.js';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { DatagridAction } from '../../../common/data-grid/actions/data-grid-actions';
import { useSetSnackbars } from '@neoload/hooks';

export type CopyToClipboardTokenActionProps = {
	selectedAccessTokenIds: GridRowSelectionModel;
};

export const useCopyToClipboardTokenAction = ({
	selectedAccessTokenIds,
}: CopyToClipboardTokenActionProps): DatagridAction => {
	const { showInfo } = useSetSnackbars();
	const { t } = useTranslation(['user']);

	const copyToClipboard: (token: string) => void = (token: string) =>
		void navigator.clipboard.writeText(token).then(() => {
			showInfo({
				text: t('token.clipboardSuccess'),
			});
		});

	const clipboard = {
		icon: <IconContentCopyOutlined />,
		text: t('common:clipboard'),
		disabled: selectedAccessTokenIds.length !== 1,
		action: () => {
			if (selectedAccessTokenIds.length === 1) {
				copyToClipboard(selectedAccessTokenIds[0].toString());
			}
		},
		singleItem: (token: string) => ({
			disabled: false,
			action: () => copyToClipboard(token),
		}),
	};
	return clipboard;
};

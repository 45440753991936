import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { SlaValuePopover } from './sla-value-popover';
import { SlaBadge } from '../../../common/sla-badge';
import { SlaPerRun } from '@neoload/api';
import { slaUtils } from '@neoload/utils';

export type SlaGlobalPerRunValueProps = {
	slaPerRun: SlaPerRun;
};

export const SlaGlobalPerRunValue = ({ slaPerRun }: SlaGlobalPerRunValueProps) => {
	const { status, slaThreshold, value } = slaPerRun;
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const theme = useTheme();

	const difference = slaUtils.formatDifference(slaThreshold, status, value);

	const valueVariant = 'SUCCESS' === status ? 'body2' : 'subtitle2';
	const valueColor = 'FAILED' === status ? theme.palette.error.main : theme.palette.secondary.main;
	const valueFontWeight = 'SUCCESS' === status ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium;
	const displayBadge = 'SUCCESS' !== status;

	const onMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const onMouseLeave = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<Stack
			direction='column'
			spacing={1}
			alignItems='end'
			justifyContent='center'
			sx={{ width: '100%', height: '100%' }}
		>
			<Grid
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'right',
					alignContent: 'right',
				}}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				data-testid='perRunValueCell'
			>
				<Grid sx={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
					{displayBadge && <SlaBadge sla={status} />}
					<Typography
						variant={valueVariant}
						sx={{
							color: valueColor,
							align: 'right',
							fontWeight: valueFontWeight,
						}}
					>
						{slaUtils.formatValue(value, slaThreshold.identifier)}
					</Typography>
				</Grid>
				{difference && (
					<Typography
						data-testid='differenceLabel'
						variant='caption'
						sx={{
							lineHeight: '16px',
							textAlign: 'right',
							color: theme.palette.text.secondary,
						}}
					>
						{difference}
					</Typography>
				)}
			</Grid>
			<SlaValuePopover slaThreshHold={slaThreshold} anchorEl={anchorEl} open={open} />
		</Stack>
	);
};

import { GridColDef, GridRowModel, GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { ComponentPropsWithoutRef, memo, MutableRefObject, useRef, useState } from 'react';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import { useIntervalsDatagridColumns } from './intervals-datagrid-columns';
import { Datagrid } from '../../../../../common/data-grid/datagrid';
import { DEFAULT_GRID_PROPS, onColumnChange } from '../../../../../common/datagrid';
import {
	DatagridAction,
	filterToContextMenuItems,
	toSecondaryAction,
} from '../../../../../common/data-grid/actions/data-grid-actions';
import { ContextMenu, ContextMenuFuncts } from '../../../../../common/context-menu/context-menu';
import { Interval, IntervalPage } from '@neoload/api';
import { useColumnsState, useDeleteIntervals } from '@neoload/hooks';
import './intervals-data-grid.css';

const intervalsColumnsKey = 'INTERVALS_COLUMNS_STATE';

export type IntervalsDataGridProps = {
	intervals: IntervalPage;
	resultId: string;
	hasTesterPermissions: boolean;
	isFetching: boolean;
};

const IntervalsDataGridInternal = ({
	intervals,
	resultId,
	hasTesterPermissions,
	isFetching,
}: IntervalsDataGridProps) => {
	const { t } = useTranslation(['result']);
	const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);
	const deleteInterval = useDeleteIntervals();
	const apiRef = useGridApiRef();
	const initialState = {};
	const contextMenu: MutableRefObject<ContextMenuFuncts | undefined> = useRef();

	const actions: DatagridAction[] = hasTesterPermissions
		? [
				{
					icon: <DeleteOutlined />,
					color: 'error',
					text: t('common:delete'),
					action: () => {
						deleteInterval(selectedIds as string[], resultId);
					},
					singleItem: (interval: Interval) => ({
						disabled: false,
						action: () => deleteInterval([interval.id], resultId),
					}),
				},
		  ]
		: [];

	const columns: GridColDef<Interval>[] = useIntervalsDatagridColumns(resultId, actions);

	const { updatedColumns, updatedInitialState, storeColumnState } = useColumnsState(
		intervalsColumnsKey,
		initialState,
		columns,
		apiRef,
	);

	const slotProps: {
		toolbar: ComponentPropsWithoutRef<typeof Toolbar>;
		row: GridRowModel;
	} = {
		toolbar: {
			displayColumnOptions: false,
			displaySearchBox: true,
			hideFiltersIcon: true,
			secondaryActions: selectedIds.length === 0 ? [] : actions.map((action) => toSecondaryAction(action)),
			title: t('intervals.gridHeader'),
		},
		row: {
			onContextMenu: contextMenu.current?.openContextMenu,
		},
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Datagrid
				apiRef={apiRef}
				{...DEFAULT_GRID_PROPS}
				{...onColumnChange(storeColumnState)}
				loading={isFetching}
				rows={intervals.items}
				rowCount={intervals.total}
				checkboxSelection
				onRowSelectionModelChange={setSelectedIds}
				slotProps={slotProps}
				slots={{
					toolbar: Toolbar,
				}}
				columns={updatedColumns}
				initialState={updatedInitialState}
				paginationModel={{ page: 0, pageSize: intervals.total }}
				hideFooter={true}
				density='standard'
				getRowClassName={(params) => `interval-grid-${params.row.hidden ? 'hidden' : 'shown'}-row`}
			/>
			{actions.length > 0 && (
				<ContextMenu apiRef={apiRef} ref={contextMenu} contextMenuItemsList={filterToContextMenuItems(actions)} />
			)}
		</div>
	);
};

export const IntervalsDataGrid = memo(IntervalsDataGridInternal);

import React, { isValidElement, useCallback, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { LimitedSizeTextField } from '../input/limited-size-text-field';
import {
	CloseFunction,
	FormValue,
	SetConfirmableFunction,
	canConfirmModal,
	cannotConfirmModal,
	closeModal,
	getModal,
} from '@neoload/api';

const Modal = () => {
	const {
		open,
		title,
		content,
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		handleConfirm = async () => {},

		// eslint-disable-next-line @typescript-eslint/no-empty-function
		handleCancel = async () => {},
		confirm,
		cancel,
		validate = () => true,
		defaultValue = {},
		canConfirm = true,
		width = 500,
	} = useSelector(getModal);

	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState<FormValue>(defaultValue);
	const firstField = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (Object.keys(defaultValue).length > 0) {
			setForm(defaultValue);
		}
	}, [defaultValue]);

	useEffect(() => {
		setTimeout(() => {
			if (firstField.current) {
				firstField.current.focus();
			}
		}, 20);
	}, [open]);

	const dispatch = useDispatch();
	const executeAndClose = (funct: (values: FormValue) => Promise<unknown>, reset: boolean) => async () => {
		if (!loading) {
			setLoading(true);
			try {
				await funct(form);
				if (reset) {
					setForm(defaultValue);
				}
				dispatch(closeModal());
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		}
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setForm({
			...form,
			[name]: value,
		});
	};

	const close: CloseFunction = useCallback(() => dispatch(closeModal()), [dispatch]);

	const setConfirmable: SetConfirmableFunction = useCallback(
		(confirmable: boolean) => (confirmable ? dispatch(canConfirmModal()) : dispatch(cannotConfirmModal())),
		[dispatch],
	);

	const displayContent = () => {
		if (isValidElement(content)) {
			return content;
		}

		if (typeof content == 'function') {
			return content(setForm, setConfirmable, close);
		}

		if (Array.isArray(content)) {
			return content.map((element, index) => {
				if ('max' in element) {
					return (
						<LimitedSizeTextField
							key={element.name}
							{...element}
							inputRef={index === 0 ? firstField : null}
							value={form[element.name ?? ''] ?? ''}
							onChange={handleInputChange}
						/>
					);
				}
				return (
					<TextField
						key={element.name}
						{...element}
						inputRef={index === 0 ? firstField : null}
						value={form[element.name ?? ''] ?? ''}
						onChange={handleInputChange}
					/>
				);
			});
		}

		return <DialogContentText>{content}</DialogContentText>;
	};

	return (
		<Dialog
			open={open}
			onClose={executeAndClose(handleCancel, true)}
			aria-labelledby='delete-modal-title'
			aria-describedby='delete-modal-description'
			maxWidth={false}
		>
			<DialogTitle id='delete-modal-title'>{title}</DialogTitle>
			<DialogContent id='delete-modal-description'>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 3,
						marginTop: 1,
						width: width,
					}}
				>
					{displayContent()}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					disabled={loading}
					color={cancel.color}
					onClick={executeAndClose(handleCancel, true)}
					startIcon={cancel.icon}
				>
					{cancel.text}
				</Button>
				<Button
					variant='contained'
					color={confirm.color}
					disabled={loading || !validate(form) || !canConfirm}
					onClick={executeAndClose(handleConfirm, true)}
					startIcon={loading ? <CircularProgress size={24.5} color='inherit' /> : confirm.icon}
				>
					{confirm.text}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export { Modal };

import { useTranslation } from 'react-i18next';
import ZeroState from '@tricentis/aura/components/ZeroState.js';
import Typography from '@mui/material/Typography';
import Button, { ButtonProps } from '@mui/material/Button';
import { Key } from 'react';
import Box from '@mui/material/Box';
import ArrowForwardOutlined from '@mui/icons-material/ArrowForwardOutlined';
import { useNavigate } from 'react-router';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import ExecutionsTestRun from '../../../assets/ExecutionsTestRun.svg';
import { useGetMe } from '@neoload/hooks';
import { HomeRoutes, TestRoutes } from '@neoload/utils';

type Actions = ButtonProps & { key: Key; buttonText: string };

const ResultsEmptyState = () => {
	const { t } = useTranslation(['result']);
	const [{ isGuest }] = useGetMe();
	const navigate = useNavigate();

	const actionsGuest: Actions[] = [
		{
			key: 'guest',
			buttonText: t('zeroGuest.button'),
			startIcon: <ArrowBackOutlined />,
			onClick: () => void navigate(HomeRoutes.base),
		},
	];

	const actionsOther: Actions[] = [
		{
			key: 'other',
			buttonText: t('zero.button'),
			endIcon: <ArrowForwardOutlined />,
			onClick: () => void navigate(TestRoutes.base),
		},
	];

	const actions = isGuest ? actionsGuest : actionsOther;
	const title = isGuest ? t('zeroGuest.title') : t('zero.title');

	return (
		<Box sx={{ flexGrow: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<ZeroState
				illustration={<img src={ExecutionsTestRun} alt={title} />}
				title={title}
				actions={actions.map(({ key, buttonText, ...others }) => (
					<Button key={key} size='small' variant='contained' {...others}>
						{buttonText}
					</Button>
				))}
				containerSx={{ backgroundColor: 'transparent' }}
			>
				<Typography variant='body2'>{t(`${isGuest ? 'zeroGuest' : 'zero'}.contentLine1`)}</Typography>
				<Typography variant='body2'>{t(`${isGuest ? 'zeroGuest' : 'zero'}.contentLine2`)}</Typography>
			</ZeroState>
		</Box>
	);
};

export { ResultsEmptyState };

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TestExecutionModalLayout } from './test-execution-modal-layout';
import {
	defaultTestExecutionFormError,
	defaultTestExecutionFormSuccess,
	estimateEndDateByTestDuration,
	getPartialErrorSubscription,
	getTestExecutionPartialError,
} from './../test-execution-helpers';
import { LoadingModal } from './loading-modal';
import { TestExecutionForm } from '../types';
import { useCurrentWorkspace, useSetSnackbars } from '@neoload/hooks';
import {
	GetV4WorkspacesByWorkspaceIdSubscriptionApiArg,
	isFetchBaseQueryError,
	MethodNotAllowedError,
	TestExecutionConfiguration,
	useGetV4ReservationsQuery,
	useGetV4SettingsQuery,
	useLazyGetV4WorkspacesByWorkspaceIdSubscriptionQuery,
	usePostV4TestExecutionsMutation,
	WorkspaceSubscription,
} from '@neoload/api';

type TestExecutionModalFormProviderProps = {
	close: () => void;
	testId: string;
};

const TestExecutionModalFormProvider = ({ close, testId }: TestExecutionModalFormProviderProps) => {
	const { t } = useTranslation(['test']);
	const { showError } = useSetSnackbars();
	const [testExecution] = usePostV4TestExecutionsMutation();
	const [{ id: workspaceId }] = useCurrentWorkspace();
	const [lazyGetSubscriptions] = useLazyGetV4WorkspacesByWorkspaceIdSubscriptionQuery();
	const { data: settings, isLoading: isSettingsLoading } = useGetV4SettingsQuery();
	const { data: reservations, isLoading: isReservationsLoading } = useGetV4ReservationsQuery({
		workspaceId,
		status: ['AVAILABLE', 'PARTIALLY_AVAILABLE'],
	});
	const [defaultValues, setDefaultValues] = useState<TestExecutionForm | null>(null);

	useEffect(() => {
		const getSubscriptions = async (
			workspaceId: string,
			endTest: Date,
			zoneType: TestExecutionConfiguration['zoneType'],
		): Promise<WorkspaceSubscription | undefined> => {
			const fields: Required<GetV4WorkspacesByWorkspaceIdSubscriptionApiArg['fields']> = [
				'webVu',
				'sapVu',
				'vuhs',
				'concurrentRuns',
			];
			if (zoneType === 'NEOLOAD_CLOUD') {
				fields.push('cloudCredits');
			}
			const { data: subscriptions, error: subscriptionError } = await lazyGetSubscriptions({
				workspaceId,
				to: endTest.toISOString(),
				fields,
			});
			return subscriptionError ? getPartialErrorSubscription(subscriptionError) : subscriptions;
		};

		if (testId && testId !== '' && !isSettingsLoading && !isReservationsLoading) {
			testExecution({
				dryRun: true,
				testExecutionInput: {
					testId: testId,
				},
			})
				.unwrap()
				.then(async (testExecution) => {
					const subs = await getSubscriptions(
						workspaceId,
						estimateEndDateByTestDuration(
							testExecution.configuration.testDuration ?? 'PT0S',
							settings?.reservationModeStatus,
						),
						testExecution.configuration.zoneType,
					);
					setDefaultValues(
						defaultTestExecutionFormSuccess(
							testExecution,
							subs,
							settings,
							reservations?.items.filter((r) => r.testId === undefined),
						),
					);
				})
				.catch(async (error) => {
					if (isFetchBaseQueryError(error)) {
						const partialError = getTestExecutionPartialError(error);
						if (partialError?.result && partialError.errors) {
							const subs = await getSubscriptions(
								workspaceId,
								estimateEndDateByTestDuration(
									partialError?.result?.configuration.testDuration ?? 'PT0S',
									settings?.reservationModeStatus,
								),
								partialError.result.configuration.zoneType,
							);
							setDefaultValues(
								defaultTestExecutionFormError(
									partialError.result,
									partialError.errors,
									subs,
									settings,
									reservations?.items.filter((r) => r.testId === undefined),
								),
							);
						} else {
							let text = t('common:errors.otherError.content');
							if (
								error.status === 405 &&
								(error.data as MethodNotAllowedError).description === 'The Neoload cloud platform is not available'
							) {
								text = t('testExecution.errors.ncpUnreachable');
							}
							close();
							showError({
								text,
								id: 'error-no-cnp',
							});
						}
					}
				});
		}
	}, [
		testId,
		showError,
		t,
		testExecution,
		isSettingsLoading,
		settings,
		reservations,
		isReservationsLoading,
		close,
		workspaceId,
		lazyGetSubscriptions,
	]);

	if (defaultValues === null) {
		return <LoadingModal close={close} />;
	}

	return <TestExecutionModalLayout close={close} defaultValues={defaultValues} />;
};

export { TestExecutionModalFormProvider };

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type ProfileItemProps = {
	name: string;
	label: string;
	value: string | undefined;
	loading: boolean;
};

const ProfileItem = ({ label, name, value, loading }: ProfileItemProps) => (
	<Stack spacing={0.5}>
		<Typography variant='caption' sx={{ color: 'text.secondary' }} id={`${name}-label`}>
			{label}
		</Typography>

		<Typography variant='body2' aria-labelledby={`${name}-label`}>
			{loading ? <Skeleton data-testid='skeleton' /> : value}
		</Typography>
	</Stack>
);

export { ProfileItem };

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { FormEventHandler, useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { UsedFilters } from './deletion-policies-utils';
import { DeletionFilterSection } from './deletion-filter-section';
import { DeletionSettingSection } from './deletion-setting-section';
import { DeletionPolicyPreview } from './deletion-policy-preview';
import { useCrudBatchMessage } from '@neoload/hooks';
import {
	DeletionPolicyRead,
	PostDeletionPolicyFields,
	usePostV4DeletionPoliciesMutation,
	usePatchV4DeletionPoliciesByDeletionPolicyIdMutation,
	usePostV4DeletionPoliciesExecutionMutation,
	DeletionPolicyExecution,
} from '@neoload/api';

type DeletionPolicyUpdate = Pick<DeletionPolicyRead, 'id' | 'workspaceId' | 'deletionFilter' | 'deletionSetting'>;

type DeletionPolicyCreateModalProps = {
	workspaceId: string;
	open: boolean;
	onClose: () => void;
	policyToEdit?: DeletionPolicyUpdate;
} & UsedFilters;

type FormPolicyFields = {
	deletionFilter: PostDeletionPolicyFields['deletionFilter'] | undefined;
	deletionSetting: PostDeletionPolicyFields['deletionSetting'] | undefined;
};

const getDefaultValues = (policyToEdit?: DeletionPolicyUpdate): FormPolicyFields => {
	if (policyToEdit) {
		return {
			deletionFilter: policyToEdit.deletionFilter,
			deletionSetting: policyToEdit.deletionSetting,
		};
	}
	return {
		deletionFilter: undefined,
		deletionSetting: { type: 'DURATION', retentionDuration: 'P30D' },
	};
};

const DeletionPolicyCreateModal = ({
	workspaceId,
	open,
	onClose,
	policyToEdit,
	usedTestIds = [],
	usedProjects = [],
	usedPatterns = [],
}: DeletionPolicyCreateModalProps) => {
	const { t } = useTranslation('workspace');
	const { create, update } = useCrudBatchMessage('workspace', 'deletionPolicies');
	const [formPolicyFields, setFormPolicyFields] = useState<FormPolicyFields>(getDefaultValues(policyToEdit));
	const [policyExecutionInfo, setPolicyExecutionInfo] = useState<DeletionPolicyExecution>({
		resultsDeleted: 0,
		totalResultNumber: 0,
	});
	const [createDeletionPolicy, { isLoading: isCreating }] = usePostV4DeletionPoliciesMutation();
	const [updateDeletionPolicy, { isLoading: isUpdating }] = usePatchV4DeletionPoliciesByDeletionPolicyIdMutation();
	const [executeDeletionPolicy, { isLoading: isExecuting }] = usePostV4DeletionPoliciesExecutionMutation();

	const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
		event.preventDefault();
		if (formPolicyFields.deletionFilter && formPolicyFields.deletionSetting) {
			void (policyToEdit
				? update(
						[{ patchDeletionPolicyFields: formPolicyFields, deletionPolicyId: policyToEdit.id }],
						(deletionPolicy) =>
							updateDeletionPolicy(deletionPolicy).unwrap().then(handleOnClose).finally(handleOnClose),
					)
				: create(
						[
							{
								postDeletionPolicyFields: {
									deletionFilter: formPolicyFields.deletionFilter,
									deletionSetting: formPolicyFields.deletionSetting,
									workspaceId,
								},
							},
						],
						(deletionPolicy) => createDeletionPolicy(deletionPolicy).unwrap().then(handleOnClose),
					));
		}
	};

	const isFormValid = !!formPolicyFields.deletionFilter && !!formPolicyFields.deletionSetting;

	const handleOnClose = () => {
		onClose();
		setFormPolicyFields({
			deletionFilter: undefined,
			deletionSetting: undefined,
		});
		setPolicyExecutionInfo({
			resultsDeleted: 0,
			totalResultNumber: 0,
		});
	};

	// We use a uesCallback here because this function will be used into a useEffect and we want to avoid infinite loop
	const handleDeletionFilterChange = useCallback(
		(deletionFilter: PostDeletionPolicyFields['deletionFilter'] | undefined) => {
			setFormPolicyFields((previous) => ({ ...previous, deletionFilter }));
		},
		[setFormPolicyFields],
	);

	const handleDeletionSettingChange = (deletionSetting: PostDeletionPolicyFields['deletionSetting'] | undefined) => {
		setFormPolicyFields((previous) => ({ ...previous, deletionSetting }));
	};

	const isSubmitting = isCreating || isUpdating;

	useEffect(() => {
		if (open) {
			setFormPolicyFields(getDefaultValues(policyToEdit));
		}
	}, [open, policyToEdit]);

	useDebounce(
		async () => {
			if (formPolicyFields.deletionFilter && formPolicyFields.deletionSetting) {
				await executeDeletionPolicy({
					postDeletionPolicyFields: {
						deletionFilter: formPolicyFields.deletionFilter,
						deletionSetting: formPolicyFields.deletionSetting,
						workspaceId,
					},
				})
					.unwrap()
					.then((response: DeletionPolicyExecution) => {
						setPolicyExecutionInfo(response);
					});
			}
		},
		500,
		[formPolicyFields],
	);

	return (
		<Dialog open={open} onClose={handleOnClose} fullWidth>
			<form onSubmit={onSubmit} onReset={handleOnClose}>
				<DialogTitle id='deletion-policies-create-modal-title'>
					{policyToEdit ? t('deletionPolicies.update.title') : t('deletionPolicies.create.title')}
				</DialogTitle>
				<DialogContent>
					<DeletionFilterSection
						deletionFilter={policyToEdit?.deletionFilter}
						usedTestIds={usedTestIds}
						usedProjects={usedProjects}
						usedPatterns={usedPatterns}
						workspaceId={workspaceId}
						onChange={handleDeletionFilterChange}
					/>
					<DeletionSettingSection
						deletionSetting={policyToEdit?.deletionSetting}
						onChange={handleDeletionSettingChange}
					/>
					<DeletionPolicyPreview
						policyExecutionInfo={policyExecutionInfo}
						isExecuting={isExecuting}
						isEdit={!!policyToEdit}
						isFormValid={isFormValid}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						type='reset'
						color='primary'
						aria-labelledby={t('common:cancel')}
						data-trackingid='deletion-policies-create-cancel'
					>
						{t('common:cancel')}
					</Button>
					<Button
						variant='contained'
						disabled={isSubmitting || !isFormValid}
						startIcon={isSubmitting ? <CircularProgress size={24.5} color='inherit' /> : null}
						type='submit'
						aria-labelledby={t('common:save')}
						data-trackingid='deletion-policies-create-ok'
					>
						{t('common:save')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export { DeletionPolicyCreateModal };

import { DashboardRoutes } from './dashboards';
import { HomeRoutes } from './home';
import { LoginRoutes } from './login';
import { ResultRoutes } from './results';
import { SettingsRoutes } from './settings';
import { TestRoutes } from './tests';
import { UserRoutes } from './user';
import { StartingRoutes } from './starting';
import { ReportRoutes } from './reports';

const commonsRoutes = {
	login: LoginRoutes,
	home: HomeRoutes,
	results: ResultRoutes,
	dashboards: DashboardRoutes,
	tests: TestRoutes,
	user: UserRoutes,
	settings: SettingsRoutes,
	starting: StartingRoutes,
	report: ReportRoutes,
};

export { commonsRoutes as CommonRoutes };

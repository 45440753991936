const usageHistory = {
	title: 'Usage history',
	data: 'Data',
	datePicker: {
		startDate: 'Start date',
		endDate: 'End date',
		lastYear: 'Last year',
		lastMonth: 'Last month',
		lastWeek: 'Last week',
		last7Days: 'Last 7 days',
		reset: 'Reset',
	},

	downloadCsv: 'Download CSV',
	dataOptions: {
		licensing: 'Licensing',
		runtime: 'Runtime',
		all: 'All',
	},
};

export { usageHistory };

import { Key, ReactNode } from 'react';
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ZeroState from '@tricentis/aura/components/ZeroState.js';
import { useTranslation } from 'react-i18next';
import ErrorForbidden from '../../../assets/ErrorForbidden.svg';
import ErrorNotFound from '../../../assets/ErrorNotFound.svg';
import ServerError from '../../../assets/ServerError.svg';
import ErrorUnknown from '../../../assets/ErrorUnknown.svg';
import { HomeRoutes, isNeoLoadError, toNeoLoadType, NeoLoadErrorType } from '@neoload/utils';

const ErrorPage = () => {
	const error: unknown = useRouteError();
	const { t } = useTranslation(['common']);
	const navigate = useNavigate();

	const getErrorImage = (type: NeoLoadErrorType): ReactNode | undefined => {
		const altText = t(`errors.${errorTypeToI18nKey(type)}.imageAlt`);
		switch (type) {
			case NeoLoadErrorType.Forbidden: {
				return <img src={ErrorForbidden} alt={altText} />;
			}
			case NeoLoadErrorType.NotFound: {
				return <img src={ErrorNotFound} alt={altText} />;
			}
			case NeoLoadErrorType.ServerError: {
				return <img src={ServerError} alt={altText} />;
			}
			case NeoLoadErrorType.ServerNotReached:
			default: {
				return undefined;
			}
		}
	};

	const errorTypeToI18nKey = (type: NeoLoadErrorType): string => {
		switch (type) {
			case NeoLoadErrorType.Forbidden: {
				return 'forbidden';
			}
			case NeoLoadErrorType.NotFound: {
				return 'notFound';
			}
			case NeoLoadErrorType.ServerError: {
				return 'serverError';
			}
			case NeoLoadErrorType.ServerNotReached: {
				return 'serverNotReached';
			}
			case NeoLoadErrorType.Error:
			default: {
				return 'otherError';
			}
		}
	};

	type ButtonPropsWithKey = ButtonProps & { key: Key };

	const getActions = (type: NeoLoadErrorType): ButtonPropsWithKey[] => {
		const backHomeButton: ButtonPropsWithKey = {
			key: 'back-home',
			variant: 'contained',
			children: (
				<>
					<ArrowBackOutlined sx={{ marginRight: 1 }} />
					{t('errors.buttons.home')}
				</>
			),
			sx: { textTransform: 'none' },
			onClick: () => void navigate(HomeRoutes.base),
		};
		const reloadButton: ButtonPropsWithKey = {
			key: 'reload',
			variant: 'outlined',
			children: t('errors.buttons.reload'),
			onClick: () => window.location.reload(),
		};

		switch (type) {
			case NeoLoadErrorType.Forbidden:
			case NeoLoadErrorType.NotFound: {
				return [backHomeButton];
			}
			case NeoLoadErrorType.ServerError:
			case NeoLoadErrorType.ServerNotReached: {
				return [reloadButton];
			}
			case NeoLoadErrorType.Error:
			default: {
				return [];
			}
		}
	};

	let illustration: ReactNode | undefined;
	let customMessage: string | null = null;
	let type = NeoLoadErrorType.Error;
	let httpCode: number | undefined;

	if (isRouteErrorResponse(error)) {
		type = toNeoLoadType(error.status);
		illustration = getErrorImage(type);
		httpCode = error.status;
	} else if (isNeoLoadError(error)) {
		type = error.type;
		illustration = getErrorImage(type);
		httpCode = error.httpCode;
		if (error.customMessageI18nKey) {
			customMessage = t(`errors.customMessages.${error.customMessageI18nKey}`);
		}
	} else {
		illustration = <img src={ErrorUnknown} alt={t('common:errors.otherError.imageAlt')} />;
	}

	return (
		<ZeroState
			illustration={illustration}
			title={t(`errors.${errorTypeToI18nKey(type)}.title`, { httpCode })}
			actions={getActions(type).map(({ children, key, ...others }) => (
				<Button key={key} {...others}>
					{children}
				</Button>
			))}
			containerSx={{ backgroundColor: 'transparent' }}
		>
			{t(`errors.${errorTypeToI18nKey(type)}.content`)}
			{customMessage && <Typography variant='body2'>{customMessage}</Typography>}
		</ZeroState>
	);
};

export { ErrorPage };

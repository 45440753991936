import { useTranslation } from 'react-i18next';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { useStaticZonesColumns } from './use-static-zones-columns';
import { StaticZonesModalInput } from './static-zone-modal-input';
import { CommonZonesDatagrid, OneOfZone, ZoneDatagridActionGetter } from '../common-zones-datagrid';
import { useConfirmModal, useCrudBatchMessage, useGetMe } from '@neoload/hooks';
import {
	PutV4ZonesByZoneIdApiArg,
	PostV4ZonesApiArg,
	StaticZoneRead,
	useDeleteV4ZonesByZoneIdMutation,
	usePostV4ZonesMutation,
	usePutV4ZonesByZoneIdMutation,
} from '@neoload/api';
const addEditZoneInput = (
	valueUpdater: (value: { name: string }) => void,
	setConfirmable: (confirmable: boolean) => void,
	defaultName: string,
) => <StaticZonesModalInput {...{ valueUpdater, setConfirmable, defaultName }} />;
const StaticZonesDataGrid = () => {
	const { openDeleteConfirmModal, openModal } = useConfirmModal();
	const [deleteZone] = useDeleteV4ZonesByZoneIdMutation();
	const [postZone] = usePostV4ZonesMutation();
	const [putZone] = usePutV4ZonesByZoneIdMutation();
	const [{ hasAdminPermissions }] = useGetMe();
	const { t } = useTranslation('zone');
	const { create, update, remove } = useCrudBatchMessage('zone');

	const addEditZone = (defaultValue?: StaticZoneRead) => {
		openModal({
			title: t(defaultValue ? 'update.header' : 'create.header', { type: t('tabs.static').toLowerCase() }),
			content: (valueUpdater, setConfirmable) =>
				addEditZoneInput(valueUpdater, setConfirmable, defaultValue?.name ?? ''),
			defaultValue: { name: defaultValue?.name ?? '' },
			confirm: { text: t(defaultValue ? 'common:edit' : 'common:add', { type: t('types.static') }), color: 'primary' },
			width: 500,
			handleConfirm: ({ name }) =>
				defaultValue ? updateZone(name as string, defaultValue.zoneId) : createZone(name as string),
		});
	};
	const createZone = async (name: string) =>
		create([{ postZoneFields: { name, type: 'STATIC' } }], (body: PostV4ZonesApiArg) => postZone(body).unwrap());
	const updateZone = async (name: string, zoneId: string) =>
		update([{ putZoneFields: { name, type: 'STATIC' }, zoneId }], (body: PutV4ZonesByZoneIdApiArg) =>
			putZone(body).unwrap(),
		);
	const removeZone = async (zoneId: string) => remove([zoneId], () => deleteZone({ zoneId }).unwrap());

	const actionGetters: ZoneDatagridActionGetter<OneOfZone>[] = hasAdminPermissions
		? [
				{
					icon: <EditOutlined />,
					text: t('common:edit'),
					action: () => ({}),
					singleItem: (zone: StaticZoneRead) => ({
						disabled: zone.zoneId === 'defaultzone',
						text: t('common:edit'),
						action: (event?: React.MouseEvent<HTMLButtonElement>) => {
							event?.stopPropagation();
							addEditZone(zone);
						},
					}),
				},
				{
					icon: <DeleteOutlined />,
					text: t('common:delete'),
					color: 'error',
					action: () => ({}),
					singleItem: ({ name, zoneId, controllers, loadGenerators }: StaticZoneRead) => {
						const hasConnectedResources = controllers.length + loadGenerators.length > 0;
						return {
							disabled: hasConnectedResources || zoneId === 'defaultzone',
							text: t('common:delete'),
							tooltip: hasConnectedResources ? t('delete.busy') : undefined,
							action: (event?: React.MouseEvent<HTMLButtonElement>) => {
								event?.stopPropagation();
								openDeleteConfirmModal({
									title: t('delete.header', { type: t('tabs.static'), name }),
									content: t('delete.text', { type: t('tabs.static'), name }),
									handleConfirm: () => removeZone(zoneId),
								});
							},
						};
					},
				},
			]
		: [];

	const columns = useStaticZonesColumns(actionGetters);
	return (
		<CommonZonesDatagrid
			type='STATIC'
			columns={columns}
			onAdd={hasAdminPermissions ? addEditZone : undefined}
			actionGetters={actionGetters}
		/>
	);
};

export { StaticZonesDataGrid };

import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { GitCloneForm } from './types.d';
import { translateErrorMessages } from './../../configuration-helpers';
import { useLazyGetV4ScmRepositoriesByRepositoryIdReferencesQuery } from '@neoload/api';

type AutocompleteType = { title: string };

const GitReferenceAutocomplete = () => {
	const { t } = useTranslation(['test']);
	const [triggerScmRepositoriesByRepositoryIdReferences] = useLazyGetV4ScmRepositoriesByRepositoryIdReferencesQuery();

	const { setValue, watch } = useFormContext<GitCloneForm>();

	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<AutocompleteType[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const repositoryId = watch('repositoryId');
	const setReference = (data: AutocompleteType | null) => {
		setValue('reference', data?.title ?? '');
	};

	useEffect(() => {
		let active = true;

		if (!isLoading || !repositoryId) {
			setIsLoading(false);
			return undefined;
		}
		setIsLoading(true);
		triggerScmRepositoriesByRepositoryIdReferences({
			repositoryId: repositoryId,
		})
			.unwrap()
			.then((references) => {
				setValue('error', undefined);
				const items = references?.items
					?.map((reference) => ({ title: reference.name }))
					.toSorted((first, second) => first.title.localeCompare(second.title));
				if (active && items) {
					setOptions(items);
				}
			})
			.catch((error) => {
				setValue('error', translateErrorMessages(error.data)[0].sentence);
			})
			.finally(() => setIsLoading(false));

		return () => {
			active = false;
		};
	}, [setValue, isLoading, repositoryId, triggerScmRepositoriesByRepositoryIdReferences]);

	useEffect(() => {
		if (open) {
			setIsLoading(true);
		} else {
			setOptions([]);
			setIsLoading(false);
		}
	}, [open]);

	return (
		<Autocomplete
			key={repositoryId}
			open={open}
			disabled={repositoryId === ''}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			isOptionEqualToValue={(option, value) => option.title === value.title}
			getOptionLabel={(option) => option.title}
			options={options}
			noOptionsText={t('configuration.project.git.modal.noReferences')}
			loadingText={t('configuration.project.git.modal.loading')}
			loading={isLoading}
			onChange={(_, data) => setReference(data)}
			size='small'
			renderInput={(params) => (
				<TextField
					{...params}
					label={t('configuration.project.git.modal.reference')}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{isLoading ? <CircularProgress color='inherit' size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
		/>
	);
};

export { GitReferenceAutocomplete };

import React, { DragEvent, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { useColorScheme } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';

type DragDropZoneProps = {
	onDrop: (file: File) => void;
	children: React.ReactNode;
};

const DragDropZone = ({ onDrop, children }: DragDropZoneProps) => {
	const theme = useTheme();
	const { mode } = useColorScheme();
	const [active, setActive] = useState(false);
	const [entered, setEntered] = useState(0);

	const activeColor =
		mode === 'light' ? alpha(theme.palette.action.selected, 0.7) : alpha(theme.palette.action.selected, 0.6);

	const handleDrag = (event: DragEvent) => {
		event.preventDefault();
		event.stopPropagation();
		if (event.type === 'dragenter') {
			setEntered((previous) => previous + 1);
		} else if (event.type === 'dragleave') {
			setEntered((previous) => previous - 1);
		}
	};

	const handleDrop = (event: DragEvent) => {
		event.preventDefault();
		event.stopPropagation();
		const file = event.dataTransfer.files[0];
		if (file) {
			onDrop(file);
		}
		setActive(false);
		setEntered(0);
	};

	useEffect(() => {
		setActive(entered > 0);
	}, [entered]);

	return (
		<Box
			display='flex'
			borderRadius={2}
			alignItems='center'
			flexDirection='column'
			border={active ? `1px dashed ${theme.palette.primary.main}` : `1px solid ${theme.palette.text.disabled}`}
			padding={2}
			height='100%'
			sx={{
				backgroundColor: active ? activeColor : theme.palette.background.paper,
			}}
			onDragEnter={handleDrag}
			onDragLeave={handleDrag}
			onDragOver={handleDrag}
			onDrop={handleDrop}
		>
			{children}
		</Box>
	);
};

export { DragDropZone };

import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import ZeroState from '@tricentis/aura/components/ZeroState.js';
import Typography from '@mui/material/Typography';
import ZoneEmptyState from '../../../assets/ZoneEmptyState.svg';
import { CreateResourceButton } from '../../common/button/create-resource-button';
import { IllustrationWithBackground } from '../../common/illustration-with-background';

type ZoneDatagridZeroStateProps = {
	createZone?: () => void;
};

export const ZoneDatagridZeroState = ({ createZone }: ZoneDatagridZeroStateProps) => {
	const { t } = useTranslation(['zone']);
	const title = t('emptyState');
	return (
		<Stack justifyContent='center' height='100%'>
			<ZeroState
				illustration={<IllustrationWithBackground image={ZoneEmptyState} title={title} />}
				title={title}
				actions={
					createZone ? (
						<CreateResourceButton data-testid='AddIcon' onClick={createZone}>
							{t('createZone')}
						</CreateResourceButton>
					) : undefined
				}
				containerSx={{ backgroundColor: 'transparent' }}
			>
				<Typography variant='body2'>{t('dynamicZoneDescription')}</Typography>
			</ZeroState>
		</Stack>
	);
};

import BackgroundAsset from '../../assets/BackgroundAsset.svg';

type IllustrationWithBackgroundProps = {
	image: string;
	title: string;
};

const IllustrationWithBackground = ({ image, title }: IllustrationWithBackgroundProps) => (
	<>
		<img style={{ position: 'absolute' }} src={image} alt={title} />
		<img src={BackgroundAsset} alt={title} />
	</>
);

export { IllustrationWithBackground };

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useFormContext, useWatch } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import Alert from '@mui/material/Alert';
import { ZoneRow } from './zone-row';
import { filterZones, findClosestLowerValue, hasExceededLgsCountForCloudMultiZone } from './zones-helpers';
import { ConfigurationFormData, ConfigurationZoneFormData } from '../types.d';
import { Zone } from '@neoload/api';

const Zones = () => {
	const { t } = useTranslation(['test']);
	const zoneTypeSelected = useWatch<ConfigurationFormData, 'zones.type'>({ name: 'zones.type' });
	const { setValue, getValues } = useFormContext<ConfigurationFormData>();
	const controllerSelected = useWatch<ConfigurationFormData, 'zones.controller'>({ name: 'zones.controller' });
	const zones = useWatch<ConfigurationFormData, 'zones.items'>({ name: 'zones.items' });
	const maximumLgCountPerProjectSize = useWatch<ConfigurationFormData, 'zones.maximumLgCountPerProjectSize'>({
		name: 'zones.maximumLgCountPerProjectSize',
	});
	const projectSize = useWatch<ConfigurationFormData, 'project.size'>({ name: 'project.size' });

	const [previous, setPrevious] = useState<{
		zones: ConfigurationZoneFormData[];
		controller: string;
		zoneType: Zone['type'] | '';
	}>({ controller: '', zoneType: '', zones: [] });

	const handleZoneTypeChange = useCallback(() => {
		for (let index = 0, max = zones.length; index < max; index++) {
			setValue(`zones.items.${index}.number`, 0);
		}
		const filteredZones = filterZones(zones, zoneTypeSelected);
		if (filteredZones.length > 0) {
			setValue('zones.controller', filteredZones[0].zoneId);
		}
		if (filteredZones.length === 1) {
			const z = filteredZones[0];
			z.number = 1;
			const index = zones.findIndex((value) => value.zoneId === filteredZones[0].zoneId);
			setValue(`zones.items.${index}.number`, 1);
		}
	}, [setValue, zoneTypeSelected, zones]);

	const handleControllerChange = useCallback(() => {
		if (zoneTypeSelected === 'DYNAMIC') {
			for (const [index, { zoneId }] of zones.entries()) {
				if (zoneId === controllerSelected) {
					setValue(`zones.items.${index}.number`, 1);
				} else {
					setValue(`zones.items.${index}.number`, 0);
				}
			}
		}
	}, [controllerSelected, setValue, zoneTypeSelected, zones]);

	const handleZoneChange = useCallback(() => {
		if (zoneTypeSelected === 'CLOUD') {
			const filteredZones = filterZones(zones, zoneTypeSelected);
			if (filteredZones.length > 0) {
				const max = filteredZones.toSorted((a, b) => b.number - a.number)[0];
				if (controllerSelected !== max.zoneId) {
					setValue('zones.controller', max.zoneId);
				}
			}
		}
	}, [controllerSelected, setValue, zoneTypeSelected, zones]);

	useEffectOnce(() => {
		if (previous.controller !== '' && previous.controller !== controllerSelected) {
			handleControllerChange();
		}
	});

	useEffect(() => {
		if (previous.controller !== '' && previous.controller !== controllerSelected) {
			handleControllerChange();
		}

		if (previous.zoneType !== '' && previous.zoneType !== zoneTypeSelected) {
			handleZoneTypeChange();
		}

		if (previous.zones.length > 0) {
			handleZoneChange();
		}

		setPrevious(() => ({
			zoneType: zoneTypeSelected,
			controller: controllerSelected,
			zones: zones,
		}));
	}, [
		zoneTypeSelected,
		controllerSelected,
		zones,
		previous.controller,
		previous.zoneType,
		previous.zones.length,
		setValue,
		handleZoneTypeChange,
		handleControllerChange,
		handleZoneChange,
	]);

	const filteredZones = filterZones(zones, zoneTypeSelected);

	if (filteredZones.length === 0) {
		const noZoneMessage =
			zoneTypeSelected === 'DYNAMIC' ? t('configuration.zones.noZones.dynamic') : t('configuration.zones.noZones.ncp');
		return (
			<Box>
				<Typography variant='body1'>{noZoneMessage}</Typography>
			</Box>
		);
	}

	const maxMultiZoneCloudLgs = findClosestLowerValue(maximumLgCountPerProjectSize, projectSize);
	const hasExceededLgsCountForCloudMultiZoneError = hasExceededLgsCountForCloudMultiZone(
		maximumLgCountPerProjectSize,
		projectSize,
		zones,
		zoneTypeSelected,
	);

	return (
		<Stack gap={1} useFlexGap sx={{ minWidth: '750px', maxWidth: 'max-content' }} id='zones-list'>
			<Stack direction='row'>
				<Typography variant='caption' sx={{ flex: '1', marginLeft: 2 }}>
					{t('configuration.zones.name')}
				</Typography>
				<Typography variant='caption' sx={{ width: '96px' }}>
					{t('configuration.zones.lg')}
				</Typography>
				<Typography variant='caption' sx={{ width: '70px' }}>
					{t('configuration.zones.controller')}
				</Typography>
			</Stack>
			{zones.map(
				(zone, index) =>
					zone.type === zoneTypeSelected && (
						<ZoneRow
							key={zone.zoneId}
							zone={zone}
							disabledRemoved
							isError={hasExceededLgsCountForCloudMultiZoneError}
							selectedZoneType={zoneTypeSelected}
							controller={getValues('zones.controller')}
							setLgs={(value) => {
								setValue(`zones.items.${index}.number`, value);
							}}
							setController={(value) => {
								console.log({ value });
								setValue('zones.controller', value);
							}}
						/>
					),
			)}
			{hasExceededLgsCountForCloudMultiZoneError && (
				<Alert sx={{ marginTop: 2, alignItems: 'center', width: '750px' }} severity='error'>
					<Typography variant='body2'>
						{maxMultiZoneCloudLgs === -1
							? t('configuration.errors.tooManyCloudLgsFileSize')
							: t('configuration.errors.tooManyCloudLgs', {
									lgs: maxMultiZoneCloudLgs,
								})}
					</Typography>
				</Alert>
			)}
		</Stack>
	);
};

export { Zones };

import { useCallback } from 'react';
import {
	addCustomPopulation,
	updatePopulation,
	removeCustomPopulation,
	CustomPopulation,
	PartialCustomPopulation,
	getCustomPopulation,
	getCustomPopulationNames,
	useAppDispatch,
	useAppSelector,
	removeAllCustomPopulation,
	defaultConstantPopulation,
	getUserChanged,
	addUnsupportedPopulations,
	ScenarioAsCodePopulation,
} from '@neoload/api';

const useCustomScenario = () => {
	const dispatch = useAppDispatch();

	return {
		addCustomPopulation: useCallback(
			(population: CustomPopulation, isUser = false) => {
				dispatch(addCustomPopulation({ population, isUser }));
			},
			[dispatch],
		),
		addUnsupportedPopulations: useCallback(
			(population: ScenarioAsCodePopulation) => {
				dispatch(addUnsupportedPopulations(population));
			},
			[dispatch],
		),
		addDefaultCustomPopulationByNames: useCallback(
			(names: string[], isUser = false) => {
				for (const name of names) {
					dispatch(addCustomPopulation({ population: { ...defaultConstantPopulation, name }, isUser }));
				}
			},
			[dispatch],
		),
		updatePopulation: useCallback(
			(name: string, partialCustomPopulation: PartialCustomPopulation, isUser = false) => {
				dispatch(updatePopulation({ name, partialCustomPopulation, isUser }));
			},
			[dispatch],
		),
		removeCustomPopulation: useCallback(
			(name: string, isUser = false) => {
				dispatch(removeCustomPopulation({ name, isUser }));
			},
			[dispatch],
		),
		removeAllCustomPopulation: useCallback(
			(isUser = false) => {
				dispatch(removeAllCustomPopulation(isUser));
			},
			[dispatch],
		),
	};
};

const useGetCustomPopulation = (name: string) => useAppSelector((state) => getCustomPopulation(state, name));

const useGetCustomPopulationNames = () => useAppSelector(getCustomPopulationNames);
const useGetCustomPopulations = () => useAppSelector((state) => state.customPopulationReducer.populations);
const useGetUnsupportedCustomPopulations = () =>
	useAppSelector((state) => state.customPopulationReducer.unsupportedPopulations);
const useGetCustomPopulationUserChanged = () => useAppSelector(getUserChanged);

export {
	useCustomScenario,
	useGetCustomPopulation,
	useGetCustomPopulationNames,
	useGetCustomPopulationUserChanged,
	useGetCustomPopulations,
	useGetUnsupportedCustomPopulations,
};

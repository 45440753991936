import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import {
	CloudZonePartialError,
	CloudZonePartialErrorRead,
	isFetchBaseQueryError,
	ZonePagePartialError,
	ZonePagePartialErrorRead,
} from '@neoload/api';

export const getPartialErrorZones = (error: FetchBaseQueryError | SerializedError | undefined) => {
	if (isFetchBaseQueryError(error)) {
		const { status, data } = error;
		if (
			status === 500 &&
			data &&
			typeof data === 'object' &&
			(data as ZonePagePartialError).category === 'PARTIAL_ERROR'
		) {
			return (data as ZonePagePartialErrorRead).result;
		}
	}
};

export const getPartialErrorCloudZone = (error: FetchBaseQueryError | SerializedError | undefined) => {
	if (isFetchBaseQueryError(error)) {
		const { status, data } = error;
		if (
			status === 500 &&
			data &&
			typeof data === 'object' &&
			(data as CloudZonePartialError).category === 'PARTIAL_ERROR'
		) {
			return (data as CloudZonePartialErrorRead).result;
		}
	}
};

import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import ZeroState from '@tricentis/aura/components/ZeroState.js';
import Typography from '@mui/material/Typography';
import Button, { ButtonProps } from '@mui/material/Button';
import { Key, useState } from 'react';
import { ScheduleTestModal } from './create/schedule-test-modal';
import { CreateReservationModal } from './create/create-reservation-modal';
import NoReservation from '../../../assets/NoReservation.svg';
import { IllustrationWithBackground } from '../../common/illustration-with-background';

// eslint-disable-next-line @typescript-eslint/naming-convention
type ButtonPropsWithKey = ButtonProps & { key: Key; 'data-trackingid': string };

export const NoReservationZeroState = () => {
	const { t } = useTranslation(['reservation']);
	const title = t('noReservation.title');
	const [openScheduleTestModal, setOpenScheduleTestModal] = useState(false);
	const [openReservationModal, setOpenReservationModal] = useState(false);

	const actions: ButtonPropsWithKey[] = [
		{
			key: 'scheduleTestRun',
			variant: 'contained',
			children: <>{t('noReservation.scheduleTest')}</>,
			sx: { textTransform: 'none' },
			onClick: () => setOpenScheduleTestModal(true),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'data-trackingid': 'schedule-test-no-reservation-button',
		},
		{
			key: 'createReservation',
			variant: 'outlined',
			children: <>{t('noReservation.createReservation')}</>,
			sx: { textTransform: 'none' },
			onClick: () => setOpenReservationModal(true),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'data-trackingid': 'reservation-no-reservation-button',
		},
	];

	return (
		<>
			<Stack justifyContent='center' height='100%'>
				<ZeroState
					illustration={<IllustrationWithBackground image={NoReservation} title={title} />}
					title={title}
					containerSx={{ backgroundColor: 'transparent' }}
					actions={actions.map(({ children, key, ...others }) => (
						<Button key={key} {...others}>
							{children}
						</Button>
					))}
				>
					<Typography variant='body2'>{t('noReservation.description')}</Typography>
				</ZeroState>
			</Stack>
			{openScheduleTestModal && <ScheduleTestModal close={() => setOpenScheduleTestModal(false)} />}
			{openReservationModal && <CreateReservationModal close={() => setOpenReservationModal(false)} />}
		</>
	);
};

import Grid from '@mui/material/Grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { ColorResult } from 'react-color';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { InputOffset } from '../../../../../common/input/input-offset';
import { ComponentTabPanel } from '../../../../../layout/component-tab-panel/component-tab-panel';
import { ColorPicker } from '../../../../../common/color-picker';
import { RichTextEditor } from '../../../../../common/rich-text-editor/rich-text-editor';
import { IntervalPostRequest } from '@neoload/api';
import {
	DEFAULT_INTERVAL_COLOR,
	hasErrorLevel,
	IntervalIncompatibility,
	isRawDataAvailable,
	reduceIncompatibilities,
	timeUtils,
} from '@neoload/utils';

export type IntervalPopoverFormProps = {
	// note: IntervalPostRequest is valid input for patch
	interval: IntervalPostRequest;
	onSubmit: (interval: IntervalPostRequest, ignoreIncompatibilities: boolean) => Promise<IntervalIncompatibility[]>;
	onCancel: () => void;
	mode?: 'EDIT' | 'CREATE';
	resultDuration: string;
	resultEndDate?: string;
	onOffsetsInputsEdited: (startOffset: string | undefined, endOffset: string | undefined) => void;
	onColorChanged: (hexColor: string) => void;
};

/**
 * Form to edit/create intervals
 * @param interval the interval to display in the popper
 * @param resultId id of the result of the interval
 * @param onSubmit callback to save the interval
 * @param onCancel callback to cancel the edition/creation
 * @param mode edition / creation mode
 * @constructor
 */
const IntervalForm = ({
	interval,
	resultDuration,
	resultEndDate,
	onCancel,
	onSubmit,
	onOffsetsInputsEdited,
	mode = 'CREATE',
	onColorChanged,
}: IntervalPopoverFormProps) => {
	const { t } = useTranslation(['result']);
	const theme = useTheme();

	const [intervalForm, setIntervalForm] = useState(interval);
	const [errors, setErrors] = useState<
		Partial<IntervalPostRequest> & { offset?: string } & { incompatibility?: boolean }
	>({});
	const { name, description, startOffset, endOffset, color = DEFAULT_INTERVAL_COLOR } = intervalForm;
	const [incompatibilities, setIncompatibilities] = useState<IntervalIncompatibility[]>([]);

	const [sending, setSending] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);

	const isEditionMode = mode === 'EDIT';
	const rawDataAvailable = isRawDataAvailable(resultEndDate);
	const disableInputOffset: boolean = isEditionMode && !rawDataAvailable;

	const getInfoMessage = (): string | undefined => {
		if (!rawDataAvailable) {
			return isEditionMode ? t('intervals.cannotEditOffsets') : t('intervals.noPercAvailable');
		}
		return undefined;
	};

	const infoMessage = getInfoMessage();

	const handleKeyChanged = (key: keyof IntervalPostRequest, value: string) => {
		setIntervalForm((current) => ({
			...current,
			[key]: value,
		}));
	};

	const onColorPicked = useCallback((v: ColorResult) => {
		handleKeyChanged('color', v.hex);
	}, []);

	useEffect(() => {
		setErrors((current) => ({
			...current,
			name: name ? undefined : 'empty',
		}));
	}, [name]);

	useEffect(() => {
		const changedStartOffset = timeUtils.asMilliseconds(startOffset);
		const changedEndOffset = timeUtils.asMilliseconds(endOffset);
		const resultDurationMillis = timeUtils.asMilliseconds(resultDuration ?? '');
		if (changedStartOffset > changedEndOffset) {
			handleKeyChanged('endOffset', startOffset);
			onOffsetsInputsEdited(undefined, startOffset);
		} else {
			setErrors((current) => ({
				...current,
				offset: changedEndOffset > resultDurationMillis ? 'intervalAfterBenchEnd' : undefined,
			}));
		}
	}, [startOffset, endOffset, resultDuration, onOffsetsInputsEdited]);

	useEffect(() => {
		const length = description?.length ?? 0;
		setErrors((current) => ({
			...current,
			description: length > 2048 ? 'tooLong' : undefined,
		}));
	}, [description]);

	useEffect(() => {
		onColorChanged(color);
	}, [color, onColorChanged]);

	useEffect(() => {
		handleKeyChanged('name', interval.name);
	}, [interval.name]);

	useEffect(() => {
		handleKeyChanged('description', interval.description ?? '');
	}, [interval.description]);

	useEffect(() => {
		handleKeyChanged('startOffset', interval.startOffset);
	}, [interval.startOffset]);

	useEffect(() => {
		handleKeyChanged('endOffset', interval.endOffset);
	}, [interval.endOffset]);

	useEffect(() => {
		handleKeyChanged('color', interval.color ?? DEFAULT_INTERVAL_COLOR);
	}, [interval.color]);

	useEffect(() => {
		//element values incompatibility is relative to the result, not the interval, so do not reset it when changing offsets
		setIncompatibilities((current) => current.filter((incompatibility) => incompatibility.source === 'elementValues'));
	}, [startOffset, endOffset]);

	const handleSubmit = async () => {
		setSending(true);
		const incompat = await onSubmit(intervalForm, incompatibilities.length > 0);
		setIncompatibilities(incompat);
		if (hasErrorLevel(incompat)) {
			setErrors((current) => ({
				...current,
				incompatibility: true,
			}));
		}
		setSending(false);
	};
	const hasAtLeastOneError = Object.values(errors).some(Boolean);

	const richTextMemo = useMemo(
		() => (
			<RichTextEditor
				name='description'
				data-testid='interval-description-input'
				value={description}
				placeholder={t('common:description')}
				onChange={(v) => {
					// When the RichTextEditor is empty, it returns '<p><br></p>' which implies a change in the description
					v === '<p><br></p>' ? handleKeyChanged('description', '') : handleKeyChanged('description', v);
				}}
			/>
		),
		[t, description],
	);

	return (
		<Box
			sx={{
				padding: 2,
				width: '660px',
				overflowY: 'auto',
			}}
		>
			<Grid container direction='column' rowGap={1}>
				<Grid item>
					{reduceIncompatibilities(incompatibilities).map((incompat) => (
						<Alert key={incompat.source} severity={incompat.level} sx={{ marginBottom: 2 }}>
							{t(`intervals.incompatibility.${incompat.source}`)}
						</Alert>
					))}
					{infoMessage && !hasErrorLevel(incompatibilities) && (
						<Alert severity='info' sx={{ marginBottom: 2 }}>
							{infoMessage}
						</Alert>
					)}
					<Grid
						display='flex'
						flexDirection='row'
						sx={{ height: '40px', marginBottom: errors.name ? 2 : 1, marginTop: 1 }}
					>
						<ColorPicker onColorPicked={onColorPicked} initialColor={color} />
						<TextField
							autoFocus
							value={name}
							label={t('common:name')}
							data-testid='interval-name-input'
							size='small'
							error={!!errors.name}
							helperText={errors.name && t(`intervals.formError.name.${errors.name}`)}
							inputProps={{ maxLength: 256 }}
							sx={{
								width: '100%',
							}}
							onChange={(event) => {
								handleKeyChanged('name', event.currentTarget.value);
							}}
						/>
					</Grid>
				</Grid>

				<Grid item marginBottom={1}>
					<Tabs
						value={selectedTab}
						onChange={(_event: React.SyntheticEvent, value: number) => setSelectedTab(value)}
						sx={{ borderBottom: `1px solid ${theme.palette.divider}`, marginBottom: 2 }}
					>
						<Tab label={t('intervals.tabs.range')} data-testid='interval-range-tab' />
						<Tab label={t('intervals.tabs.description')} data-testid='interval-description-tab' />
					</Tabs>
					<ComponentTabPanel
						value={selectedTab}
						index={0}
						data-testid='interval-range-panel'
						sx={{ height: '100%', flexGrow: 1 }}
					>
						<Grid container display='flex' justifyContent='space-between' sx={{ height: '40px' }}>
							<Grid item md={5.5}>
								<InputOffset
									maxValue={resultDuration}
									includeMillis={false}
									onChange={(v, isUserInput) => {
										if (isUserInput) {
											onOffsetsInputsEdited(v, undefined);
											handleKeyChanged('startOffset', v);
										}
									}}
									data-testid='interval-start-input'
									textFieldProps={{
										size: 'small',
										error: !!errors.offset,
										variant: 'outlined',
										disabled: sending || disableInputOffset,
										label: t('intervals.startOffset'),
									}}
									value={startOffset}
								/>
							</Grid>
							<Grid item md={0.5} sx={{ textAlign: 'center', alignSelf: 'center' }}>
								-
							</Grid>
							<Grid item md={5.5}>
								<InputOffset
									maxValue={resultDuration}
									includeMillis={false}
									onChange={(v, isUserInput) => {
										if (isUserInput) {
											onOffsetsInputsEdited(undefined, v);
											handleKeyChanged('endOffset', v);
										}
									}}
									data-testid='interval-end-input'
									textFieldProps={{
										size: 'small',
										error: !!errors.offset,
										variant: 'outlined',
										disabled: sending || disableInputOffset,
										label: t('intervals.endOffset'),
									}}
									value={endOffset}
								/>
							</Grid>
						</Grid>
						{errors.offset && (
							<Typography variant='caption' sx={{ color: theme.palette.error.main }}>
								{t(`intervals.formError.offset.${errors.offset}`)}
							</Typography>
						)}
					</ComponentTabPanel>

					<ComponentTabPanel
						value={selectedTab}
						index={1}
						data-testid='interval-description-panel'
						sx={{ height: '100%', flexGrow: 1 }}
					>
						<Grid
							item
							// whiteSpace='normal'
							sx={{ height: errors.description ? 220 : 240, width: '100%', marginTop: '-4px' }}
							alignSelf='flex-start'
						>
							{richTextMemo}
						</Grid>
						{errors.description && (
							<Grid item whiteSpace='normal' sx={{ height: 20 }} alignSelf='flex-start'>
								<Typography variant='caption' sx={{ color: theme.palette.error.main }}>
									{t(`intervals.formError.description.${errors.description}`)}
								</Typography>
							</Grid>
						)}
					</ComponentTabPanel>
				</Grid>

				<Grid item whiteSpace='normal' sx={{ height: '40px' }} alignSelf='flex-end'>
					<Button
						onClick={onCancel}
						variant='text'
						disabled={sending}
						color='primary'
						data-testid='interval-edit-cancel'
						sx={{ marginRight: 1 }}
					>
						{t('common:cancel')}
					</Button>
					<Button
						onClick={handleSubmit}
						variant='contained'
						color='primary'
						data-testid='interval-edit-submit'
						disabled={sending || hasAtLeastOneError}
						startIcon={sending && <CircularProgress size={24.5} color='inherit' />}
					>
						{!hasErrorLevel(incompatibilities) && incompatibilities.length > 0
							? t('common:saveAnyway')
							: t('common:save')}
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

export { IntervalForm };

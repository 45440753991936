import { HTMLAttributes } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { User } from '@neoload/api';

type MemberComboboxItemProps = {
	member: User;
	selected: boolean;
	props: HTMLAttributes<HTMLLIElement>;
};

const MemberComboboxItem = ({ member, selected, props }: MemberComboboxItemProps) => {
	const { t } = useTranslation('workspace');
	const isPending = member.status === 'PENDING';
	return (
		<MenuItem sx={{ width: '100%', verticalAlign: 'center' }} {...props} disabled={isPending}>
			<Stack direction='row' width='100%' height='100%'>
				<Checkbox disabled={isPending} sx={{ marginRight: 1 }} checked={selected} />
				{isPending ? (
					<ListItemText primaryTypographyProps={{ noWrap: true }} sx={{ alignSelf: 'center', maxWidth: '350px' }}>
						{member.email}
					</ListItemText>
				) : (
					<ListItemText
						sx={{ maxWidth: '350px' }}
						primary={`${member.firstName} ${member.lastName}`}
						primaryTypographyProps={{ noWrap: true }}
						secondary={member.email}
					/>
				)}
			</Stack>
			<ListItemText
				sx={{ marginRight: 2 }}
				primary={t(member.status === 'ACTIVE' ? 'members.active' : 'members.pending')}
			/>
		</MenuItem>
	);
};

export { MemberComboboxItem };

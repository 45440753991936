import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { LabelledItem } from '../../common/labelled-item';
import { INFRASTRUCTURE_PROVIDER_TYPE_DISPLAY_NAMES } from '@neoload/utils';
import { InfrastructureProvider } from '@neoload/api';

export type InfrastructureProviderDrawerContentProps = {
	provider: InfrastructureProvider;
};

const useDashIfEmpty = (value: string | undefined): string => (value && value.length > 0 ? value : '—');

const InfrastructureProviderDrawerContent = ({ provider }: InfrastructureProviderDrawerContentProps) => {
	const { t } = useTranslation(['infrastructureProviders']);

	return (
		<List
			sx={() => ({
				overflowY: 'auto',
				padding: 2,
				flexGrow: 1,
			})}
		>
			<LabelledItem id='provider-name' title={t('providers.columns.name.title')} value={provider.name} />
			<LabelledItem
				id='provider-type'
				title={t('providers.columns.type.title')}
				value={INFRASTRUCTURE_PROVIDER_TYPE_DISPLAY_NAMES[provider.type]}
			/>
			<LabelledItem id='namespace-url' title={t('providers.columns.url.title')} value={provider.apiUrl} />

			<LabelledItem id='provider-namespace' title={t('providers.details.namespace')} value={provider.namespace} />

			<Divider sx={{ marginBottom: 2 }} />

			<LabelledItem
				id='provider-labels'
				title={t('providers.details.labels')}
				value={useDashIfEmpty(provider.labels)}
			/>
			<LabelledItem
				id='provider-node-selectors'
				title={t('providers.details.nodeSelectors')}
				value={useDashIfEmpty(provider.nodeSelectors)}
			/>
			<LabelledItem
				id='provider-annotations'
				title={t('providers.details.annotations')}
				value={useDashIfEmpty(provider.annotations)}
			/>
			<LabelledItem
				id='provider-tolerations'
				title={t('providers.details.tolerations')}
				value={useDashIfEmpty(provider.tolerations)}
			/>
		</List>
	);
};

export { InfrastructureProviderDrawerContent };

import { GridFilterInputValueProps, GridFilterItem, GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-pro';
import { duration } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { InputOffset } from '../../input/input-offset';

const getOffsetValue = (
	offsetItems: GridFilterItem[],
	operatorValue: string,
	linkOperator?: GridLogicOperator,
): string => {
	const offsetCandidates: string[] = offsetItems
		.filter((item) => item.operator === operatorValue)
		.map(({ value }) => value);

	offsetCandidates.sort(
		(first, second) =>
			(operatorValue === 'before' ? 1 : -1) *
			(linkOperator === 'and' ? 1 : -1) *
			(duration(first).asMilliseconds() - duration(second).asMilliseconds()),
	);
	return offsetCandidates[0] ?? '';
};

const getOffsetRangeValuesFromOffsetPicker = (filterModel: GridFilterModel): string[] => {
	const offsetItems: GridFilterItem[] = filterModel.items.filter((item) => 'timeRange' === item.field && item.value);
	const fromOffsetValue = getOffsetValue(offsetItems, 'after', filterModel.logicOperator);
	const toOffsetValue = getOffsetValue(offsetItems, 'before', filterModel.logicOperator);

	if (areOffsetInvalid(fromOffsetValue, toOffsetValue)) {
		return ['', ''];
	}
	return [fromOffsetValue, toOffsetValue];
};

const areOffsetInvalid = (fromOffsetValue: string, toOffsetValue: string): boolean =>
	fromOffsetValue !== '' &&
	toOffsetValue !== '' &&
	duration(fromOffsetValue).asMilliseconds() > duration(toOffsetValue).asMilliseconds();

const DatagridOffsetFilterInput = (props: GridFilterInputValueProps) => {
	const { item, applyValue } = props;
	const { t } = useTranslation(['common']);
	return (
		<InputOffset
			{...props}
			textFieldProps={{
				size: 'small',
				error: false,
				variant: 'standard',
				label: t('value'),
			}}
			onChange={(inputValue) => {
				applyValue({
					...item,
					value: inputValue,
				});
			}}
			value={item.value}
		/>
	);
};

export { DatagridOffsetFilterInput, getOffsetRangeValuesFromOffsetPicker };

import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { ButtonSelectFile } from './button-select-file';

type ButtonSelectionFileProps = {
	isInformationLoading: boolean;
	openGitModal: () => void;
};

const ButtonSelectionFile = ({ isInformationLoading, openGitModal }: ButtonSelectionFileProps) => {
	const { t } = useTranslation(['test']);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const menuOpen = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const gitModal = () => {
		openGitModal();
		setAnchorEl(null);
	};

	return (
		<>
			<ButtonGroup>
				<ButtonSelectFile isInformationLoading={isInformationLoading} data-trackingid='file-update-project-button' />
				<Button size='small' onClick={handleClick} disabled={isInformationLoading}>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Menu
				open={menuOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuItem onClick={() => gitModal()} data-trackingid='file-to-git-project-button'>
					{t('configuration.project.git.button.clone')}
				</MenuItem>
			</Menu>
		</>
	);
};

export { ButtonSelectionFile };

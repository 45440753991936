import IconButton from '@mui/material/IconButton';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { memo, useId } from 'react';
import { formatAsCodeHumanReadableTimeSpecification } from './custom-scenarios-helpers';
import { CustomPopulation, ExecutionPolicies, VuDistributions } from '@neoload/api';
import { useCustomScenario } from '@neoload/hooks';

type PopulationItemProps = {
	population: CustomPopulation;
};

const shouldUpdate = (prevProps: PopulationItemProps, nextProps: PopulationItemProps) =>
	prevProps.population.name === nextProps.population.name &&
	prevProps.population.vus === nextProps.population.vus &&
	prevProps.population.duration === nextProps.population.duration &&
	prevProps.population.executionPolicy === nextProps.population.executionPolicy;

const PopulationItem = memo(
	({ population }: PopulationItemProps) => {
		const { t } = useTranslation(['test']);
		const id = useId();
		const { removeCustomPopulation } = useCustomScenario();

		const getDuration = () => {
			if (population.executionPolicy === ExecutionPolicies.ITERATION) {
				return t('configuration.scenarios.custom.title.iteration', {
					count: Number.parseInt(population.duration),
				});
			}
			return formatAsCodeHumanReadableTimeSpecification(population.duration);
		};

		const getPopulationDistribution = () => {
			switch (population.vuDistribution) {
				case VuDistributions.CONSTANT: {
					return t('configuration.scenarios.custom.constant');
				}
				case VuDistributions.RAMPUP: {
					return t('configuration.scenarios.custom.rampUp');
				}
				default: {
					return '';
				}
			}
		};

		return (
			<Stack useFlexGap flexDirection='row' gap={1} alignItems='center' width='100%' justifyContent='space-between'>
				<Typography>{population.name}</Typography>
				<Stack useFlexGap flexDirection='row' gap={2} alignItems='center' justifyContent='flex-end'>
					<Stack useFlexGap flexDirection='row' gap={1} alignItems='center' justifyContent='flex-end'>
						<Typography variant='body1' color='text.secondary' id={`${id}-custom-virtual-users`}>
							{t('configuration.scenarios.custom.title.virtualUsers')}
						</Typography>
						<Typography variant='body1' color='text.primary' aria-labelledby={`${id}-custom-virtual-users`}>
							{population.vus}
						</Typography>
					</Stack>
					<Stack useFlexGap flexDirection='row' gap={1} alignItems='center' justifyContent='flex-end'>
						<Typography variant='body1' color='text.secondary' id={`${id}-custom-duration`}>
							{t('configuration.scenarios.custom.title.duration')}
						</Typography>
						<Typography variant='body1' color='text.primary' aria-labelledby={`${id}-custom-duration`}>
							{getDuration()}
						</Typography>
					</Stack>
					<Stack useFlexGap flexDirection='row' gap={1} alignItems='center' justifyContent='flex-end'>
						<Typography variant='body1' color='text.secondary' id={`${id}-custom-duration`}>
							{t('configuration.scenarios.custom.title.distribution')}
						</Typography>
						<Typography variant='body1' id={`${id}-custom-duration`}>
							{getPopulationDistribution()}
						</Typography>
					</Stack>
					<Stack useFlexGap flexDirection='row' gap={1} alignItems='center' justifyContent='flex-end'>
						<Tooltip title={t('common:remove')} arrow>
							<span>
								<IconButton
									onClick={(event) => {
										removeCustomPopulation(population.name, true);
										event.stopPropagation();
									}}
									data-trackingid='custom-scenario-delete-population'
								>
									<DeleteOutlined />
								</IconButton>
							</span>
						</Tooltip>
					</Stack>
				</Stack>
			</Stack>
		);
	},
	(prevProps, nextProps) => shouldUpdate(prevProps, nextProps),
);

export { PopulationItem };

import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useConfigureNlguiAction } from './configure-nlgui';
import { useDeleteTokenAction } from './delete-token';
import { useCopyToClipboardTokenAction } from './clipboard';
import { useVisibilityTokenAction } from './visibility';
import { DatagridAction } from '../../../common/data-grid/actions/data-grid-actions';
import { useGetMe } from '@neoload/hooks';
import { Token } from '@neoload/api';

export type AccessTokensActionsProps = {
	publicApiUrl: string | undefined;
	displayedTokenRows: GridRowSelectionModel;
	setDisplayedTokenRows: (tokens: GridRowSelectionModel) => void;
	items: Token[];
	isAllSelectedTokensVisibles: boolean;
	selectedAccessTokenIds: GridRowSelectionModel;
	onDeleteAccessTokens: (tokens: GridRowSelectionModel) => void;
};

export const useAccessTokensActions = ({
	publicApiUrl,
	displayedTokenRows,
	setDisplayedTokenRows,
	items,
	isAllSelectedTokensVisibles,
	selectedAccessTokenIds,
	onDeleteAccessTokens,
}: AccessTokensActionsProps): { full: DatagridAction[]; deleteToken: DatagridAction } => {
	const [{ isGuest }] = useGetMe();

	const visibility = useVisibilityTokenAction({
		displayedTokenRows,
		setDisplayedTokenRows,
		items,
		isAllSelectedTokensVisibles,
		selectedAccessTokenIds,
	});

	const clipboard = useCopyToClipboardTokenAction({ selectedAccessTokenIds });

	const configureNlgui = useConfigureNlguiAction({ publicApiUrl, selectedAccessTokenIds, isGuest });

	const deleteToken = useDeleteTokenAction({ onDeleteAccessTokens, selectedAccessTokenIds });

	return {
		full: [visibility, clipboard, configureNlgui, deleteToken],
		deleteToken,
	};
};

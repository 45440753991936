import { createBrowserRouter, redirect, replace, RouteObject } from 'react-router';
import { DefaultLayout } from './layouts/default-layout';
import { SettingsLayout } from './layouts/settings-layout';
import { LoginLayout } from './layouts/login-layout';
import { TestsPage } from './pages/tests-page';
import { UsersPage } from './pages/users-page';
import { HomePage } from './pages/home-page';
import { ProfilePage } from './pages/profile-page';
import { UserLayout } from './layouts/user-layout';
import { GitRepoPage } from './pages/git-repo-page';
import { InfrastructureProviderPage } from './pages/infrastructure-provider-page';
import { AccessTokensPage } from './pages/access-tokens-page';
import { ResultPage } from './pages/result-page';
import { DashboardsPage } from './pages/dashboards-page';
import { DashboardPage } from './pages/dashboard-page';
import { ResultListPage } from './pages/result-list-page';
import { TestPage } from './pages/test-page';
import { ProtectedRoute } from './utils/protected-route/protected-route';
import { ZonesPage } from './pages/zones-page';
import { WorkspacesPage } from './pages/workspaces-page';
import { WorkspacePage } from './pages/workspace-page';
import { ReservationPage } from './pages/reservation-page';
import { SubscriptionPage } from './pages/subscription-page';
import { UsageHistoryPage } from './pages/usage-history-page';
import { LicensePage } from './pages/license-page';
import { GlobalErrorPage } from '@neoload/ui';
import { CommonRoutes, ZonesTab, zonesTabs } from '@neoload/utils';

const routeList: RouteObject[] = [
	{
		path: CommonRoutes.login.patterns.base,
		element: <LoginLayout />,
	},
	{
		path: CommonRoutes.settings.patterns.base,
		element: <SettingsLayout />,
		errorElement: <GlobalErrorPage />,
		children: [
			{
				path: CommonRoutes.settings.patterns.generalSettings,
				errorElement: <GlobalErrorPage />,
				element: (
					<ProtectedRoute permission='admin'>
						<div>Settings</div>
					</ProtectedRoute>
				),
			},
			{
				path: CommonRoutes.settings.patterns.users,
				errorElement: <GlobalErrorPage />,
				element: (
					<ProtectedRoute permission='admin'>
						<UsersPage />
					</ProtectedRoute>
				),
			},
			{
				path: CommonRoutes.settings.workspaces.base,
				children: [
					{
						index: true,
						errorElement: <GlobalErrorPage />,
						element: (
							<ProtectedRoute permission='tester'>
								<WorkspacesPage />
							</ProtectedRoute>
						),
					},
					{
						path: CommonRoutes.settings.workspaces.patterns.byId,
						loader: ({ params }) => replace(CommonRoutes.settings.workspaces.members(params.workspaceId ?? '')),
					},
					{
						path: CommonRoutes.settings.workspaces.patterns.byIdWithTab,
						errorElement: <GlobalErrorPage />,
						element: (
							<ProtectedRoute permission='tester'>
								<WorkspacePage />
							</ProtectedRoute>
						),
					},
				],
			},
			{
				path: CommonRoutes.settings.patterns.infrastructureProviders,
				errorElement: <GlobalErrorPage />,
				element: (
					<ProtectedRoute permission='tester'>
						<InfrastructureProviderPage />
					</ProtectedRoute>
				),
			},
			{
				path: CommonRoutes.settings.patterns.subscription,
				errorElement: <GlobalErrorPage />,
				element: (
					<ProtectedRoute permission='admin'>
						<SubscriptionPage />
					</ProtectedRoute>
				),
			},
			{
				path: CommonRoutes.settings.patterns.license,
				errorElement: <GlobalErrorPage />,
				element: (
					<ProtectedRoute permission='admin'>
						<LicensePage />
					</ProtectedRoute>
				),
			},
			{
				path: CommonRoutes.settings.patterns.usageHistory,
				errorElement: <GlobalErrorPage />,
				element: (
					<ProtectedRoute permission='admin'>
						<UsageHistoryPage />
					</ProtectedRoute>
				),
			},
			{
				path: CommonRoutes.settings.zones.base,
				children: [
					{
						index: true,
						loader: () => replace(CommonRoutes.settings.zones.generic('cloud')),
					},
					{
						path: CommonRoutes.settings.zones.patterns.withTab,
						loader: ({ params }) =>
							// Redirect to cloud zones if invalid tabName in URL
							zonesTabs.includes(params.tab as ZonesTab) ? true : replace(CommonRoutes.settings.zones.generic('cloud')),
						errorElement: <GlobalErrorPage />,
						element: (
							<ProtectedRoute permission='tester'>
								<ZonesPage />
							</ProtectedRoute>
						),
					},
				],
			},
			{
				path: CommonRoutes.settings.reservation,
				errorElement: <GlobalErrorPage />,
				element: (
					<ProtectedRoute permission='tester'>
						<ReservationPage />
					</ProtectedRoute>
				),
			},
		],
	},
	{
		path: CommonRoutes.user.patterns.base,
		element: <UserLayout />,
		errorElement: <GlobalErrorPage />,
		children: [
			{
				path: CommonRoutes.user.patterns.accessTokens,
				errorElement: <GlobalErrorPage />,
				element: <AccessTokensPage />,
			},
			{
				path: CommonRoutes.user.patterns.profile,
				errorElement: <GlobalErrorPage />,
				element: <ProfilePage />,
			},
			{
				path: CommonRoutes.user.patterns.gitRepositories,
				errorElement: <GlobalErrorPage />,
				element: (
					<ProtectedRoute permission='tester'>
						<GitRepoPage />
					</ProtectedRoute>
				),
			},
		],
	},
	{
		path: CommonRoutes.home.patterns.base,
		element: <DefaultLayout />,
		errorElement: <GlobalErrorPage />,
		children: [
			{
				errorElement: <GlobalErrorPage />,
				children: [
					{ index: true, errorElement: <GlobalErrorPage />, element: <HomePage /> },
					{
						path: CommonRoutes.tests.patterns.base,
						children: [
							{
								index: true,
								errorElement: <GlobalErrorPage />,
								element: <TestsPage />,
							},
							{
								path: CommonRoutes.tests.patterns.byId,
								errorElement: <GlobalErrorPage />,
								element: <TestPage />,
							},
							{
								path: CommonRoutes.tests.patterns.byIdConfigure,
								errorElement: <GlobalErrorPage />,
								element: (
									<ProtectedRoute permission='tester'>
										<TestPage configure />
									</ProtectedRoute>
								),
							},
						],
					},
					{
						path: CommonRoutes.dashboards.patterns.base,
						errorElement: <GlobalErrorPage />,
						element: <DashboardsPage />,
					},
					{
						path: CommonRoutes.dashboards.patterns.byId,
						errorElement: <GlobalErrorPage />,
						element: <DashboardPage />,
					},
					{
						path: CommonRoutes.report.patterns.byId,
						errorElement: <GlobalErrorPage />,
						element: <DashboardPage />,
					},
					{
						path: CommonRoutes.results.patterns.base,
						children: [
							{
								index: true,
								errorElement: <GlobalErrorPage />,
								element: <ResultListPage />,
							},
							{
								path: CommonRoutes.results.patterns.byId,
								children: [
									{
										index: true,
										// Redirect to the Overview tab if no tab specified in URL
										loader: ({ params }) => redirect(CommonRoutes.results.overview(params.resultId ?? '')),
									},
									{
										path: CommonRoutes.results.patterns.byIdWithTab,
										errorElement: <GlobalErrorPage />,
										element: <ResultPage />,
									},
									{
										path: CommonRoutes.results.patterns.byIdWithTabWithSubTab,
										errorElement: <GlobalErrorPage />,
										element: <ResultPage />,
									},
								],
							},
						],
					},
				],
			},
		],
	},
];
const routes = createBrowserRouter(routeList);

export { routes, routeList };

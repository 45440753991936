import ZeroState from '@tricentis/aura/components/ZeroState.js';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from '@mui/material/Button';
import { Key } from 'react';
import TestData from '../../../../assets/TestData.svg';
import { CreateResourceButton } from '../../../common/button/create-resource-button';
import { IllustrationWithBackground } from '../../../common/illustration-with-background';
import { useUrlSearchParams } from '@neoload/hooks';

type ButtonPropsWithKey = ButtonProps & { key: Key };

const DashboardsEmptyState = () => {
	const { t } = useTranslation(['dashboard']);
	const [, setUrlSearchParams] = useUrlSearchParams('createDashboard');

	const actions: ButtonPropsWithKey[] = [
		{
			key: 'createDashboard',
			variant: 'contained',
			children: <>{t('createButton')}</>,
			sx: { textTransform: 'none' },
			onClick: () => {
				setUrlSearchParams({ createDashboard: 'true' });
			},
		},
	];

	const title = t('dashboard:emptyState:title');

	return (
		<ZeroState
			illustration={<IllustrationWithBackground image={TestData} title={title} />}
			title={title}
			actions={actions.map(({ key, ...others }) => (
				<CreateResourceButton {...others} data-testid='createDashboard' key={key} />
			))}
			containerSx={{ backgroundColor: 'transparent' }}
		>
			<Typography variant='body2'>{t('dashboard:emptyState:content')}</Typography>
		</ZeroState>
	);
};

export { DashboardsEmptyState };

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

type DetailInfoProps = {
	id: string;
	title: string;
	value: string | number;
	loading?: boolean;
};

const DetailInfo = ({ title, value, id, loading = false }: DetailInfoProps) => (
	<Box display='flex' lineHeight={1}>
		<Typography
			id={id}
			color='text.secondary'
			variant='body2'
			noWrap
			sx={{ marginLeft: 2, marginRight: 1, resize: 'none' }}
		>
			{title}
		</Typography>
		{loading ? (
			<Skeleton width={50} data-testid='detail-value-skeleton' />
		) : (
			<Typography aria-labelledby={id} variant='body2' noWrap maxWidth={300} sx={{ resize: 'none' }}>
				{value}
			</Typography>
		)}
	</Box>
);
export { DetailInfo };

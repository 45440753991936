import { useTranslation } from 'react-i18next';
import { LabelledItem } from '../../../../../common/labelled-item';
import { AlertEventWithDetails } from '@neoload/api';

export type EventTabDetailsObjectiveProps = {
	event: AlertEventWithDetails;
};
const EventTabDetailsObjective = ({ event }: EventTabDetailsObjectiveProps) => {
	const { t, i18n } = useTranslation(['result']);
	if (!event.details) {
		return null;
	}
	const { details: alertDetails = {} } = event;
	const { value, expectedValue, accuracy } = alertDetails;

	let valueToDisplay = '';
	const valueDefined = value !== undefined;
	if (valueDefined) {
		const valueParsed = Number.parseFloat(value);
		const formatterValue = new Intl.NumberFormat(i18n.language, {
			compactDisplay: 'short',
			maximumFractionDigits: 3,
			minimumIntegerDigits: 1,
		});
		const valueString = formatterValue.format(valueParsed);
		if (expectedValue === undefined) {
			valueToDisplay = valueString;
		} else {
			const distance = valueParsed / expectedValue - 1;
			const formatter = new Intl.NumberFormat(i18n.language, {
				compactDisplay: 'short',
				signDisplay: 'exceptZero',
				style: 'percent',
				maximumFractionDigits: 0,
			});
			valueToDisplay = `${valueString} (${formatter.format(distance)}) `;
		}
	}

	return (
		<>
			{valueDefined && (
				<LabelledItem id='event-objective-value' value={valueToDisplay} title={t('events.details.value')} />
			)}
			{accuracy !== undefined && (
				<LabelledItem
					id='event-objective-accuracy'
					value={t('events.details.accuracyComputed', { count: accuracy })}
					title={t('events.details.accuracy')}
				/>
			)}
		</>
	);
};
export { EventTabDetailsObjective };

import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreHorizOutlined from '@mui/icons-material/MoreHorizOutlined';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { useTheme } from '@mui/material/styles';
import { DetailInfo } from './detail-info';
import { styleTypo } from './styles-utils';
import { TestResult } from '@neoload/api';
import { isAlive, timeUtils } from '@neoload/utils';

export type ResultDetailsMenuProps = {
	result: Pick<TestResult, 'startDate' | 'endDate' | 'terminationReason' | 'author' | 'lgCount' | 'status' | 'debug'>;
	zones?: string[];
};

const ResultDetailsMenu = ({ result, zones }: ResultDetailsMenuProps) => {
	const { startDate, endDate, terminationReason, author, lgCount, debug } = result;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { t } = useTranslation(['result']);
	const theme = useTheme();

	const getDisplayedTerminationReason = (terminationReason: TestResult['terminationReason']): string => {
		switch (terminationReason) {
			case 'POLICY': {
				return t('details.termination.policy');
			}
			case 'VARIABLE': {
				return t('details.termination.variable');
			}
			case 'MANUAL': {
				return t('details.termination.manual');
			}
			case 'LG_AVAILABILITY': {
				return t('details.termination.lgAvailability');
			}
			case 'LICENSE': {
				return t('details.termination.license');
			}
			case 'FAILED_TO_START': {
				return t('details.termination.failedToStart');
			}
			case 'CANCELLED': {
				return t('details.termination.cancelled');
			}
			case 'RESERVATION_ENDED': {
				return t('details.termination.reservationEnded');
			}
			case 'UNKNOWN':
			default: {
				return t('details.termination.unknown');
			}
		}
	};

	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const finished = !isAlive(result);

	return (
		<>
			<IconButton
				onClick={handleClick}
				data-testid='result-details-menu-button'
				sx={{ marginRight: theme.spacing(1) }}
			>
				<Tooltip title={t('details.moreInfo')} arrow>
					<MoreHorizOutlined />
				</Tooltip>
			</IconButton>
			<Menu open={open} anchorEl={anchorEl} onClose={handleClose} data-testid='result-header-menu'>
				<Grid item sx={{ paddingRight: theme.spacing(2) }}>
					<Grid item sx={{ display: 'flex', padding: theme.spacing(1) }}>
						<DetailInfo id='menu.started' title={t('details.started')} value={timeUtils.dateTimeAbsolute(startDate)} />
					</Grid>
					{finished && endDate && (
						<Grid item sx={{ display: 'flex', padding: theme.spacing(1) }}>
							<DetailInfo id='menu.ended' title={t('details.ended')} value={timeUtils.dateTimeAbsolute(endDate)} />
						</Grid>
					)}
					<Grid item sx={{ display: 'flex', padding: theme.spacing(1) }}>
						<DetailInfo id='menu.author' title={t('details.author')} value={author} />{' '}
					</Grid>
					{zones && zones.length > 0 && (
						<Grid item sx={{ display: 'flex', padding: theme.spacing(1) }}>
							<Typography variant='body2' color='text.secondary' sx={styleTypo}>
								{t('details.zone', { count: zones.length })}
							</Typography>
							<Grid item sx={{ display: 'flex', flexDirection: 'column' }}>
								{zones.map((zone) => (
									<Typography key={zone} variant='body2' noWrap overflow='visible' sx={{ paddingBottom: '2px' }}>
										{zone}
									</Typography>
								))}
							</Grid>
						</Grid>
					)}
					{finished && (
						<Grid item sx={{ display: 'flex', padding: theme.spacing(1) }}>
							<DetailInfo
								id='menu.terminationReason'
								title={t('details.termination.reason')}
								value={getDisplayedTerminationReason(terminationReason)}
							/>
						</Grid>
					)}
					<Grid item sx={{ display: 'flex', padding: theme.spacing(1) }}>
						<DetailInfo id='menu.loadGenerators' title={t('details.lg')} value={lgCount} />{' '}
					</Grid>
					<Grid item sx={{ display: 'flex', padding: theme.spacing(1) }}>
						<DetailInfo
							id='menu.debugMode'
							title={t('details.debug.mode')}
							value={debug ? t('details.debug.enabled') : t('details.debug.disabled')}
						/>
					</Grid>
				</Grid>
			</Menu>
		</>
	);
};

export { ResultDetailsMenu };

import { generatePath } from 'react-router';

export const zonesTabs = ['cloud', 'static', 'dynamic'] as const;
export type ZonesTab = (typeof zonesTabs)[number];

export type ZonesGenericParams = {
	tab?: ZonesTab;
};

const patterns = {
	base: '/settings/zones',
	withTab: '/settings/zones/:tab',
};

const zonesRoutes = {
	patterns: patterns,
	base: patterns.base,
	generic: (tab: ZonesTab = 'cloud') => generatePath(patterns.withTab, { tab: tab }),
};

export { zonesRoutes as ZonesRoutes };

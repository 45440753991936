import DialogContent from '@mui/material/DialogContent';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { CreateReservationForm } from './common/reservation-helper';
import { WorkspaceReservationModal } from './common/workspace-reservation-modal';
import { TestReservationModal } from './common/test-reservation-modal';
import { ActionsScheduleTestModal } from './common/actions-schedule-test-modal';
import { InputDatePicker } from '../../../../common/input/input-date-picker';
import { timeUtils } from '@neoload/utils';

type CreateModalReservationLayoutProps = {
	close: () => void;
	defaultValue: CreateReservationForm;
};

const ScheduleTestLayoutModal = ({ close, defaultValue }: CreateModalReservationLayoutProps) => {
	const { t } = useTranslation(['reservation']);
	const { ...methods } = useForm<CreateReservationForm>({
		mode: 'onChange',
		defaultValues: defaultValue,
	});

	const error = useWatch<CreateReservationForm, 'error'>({ name: 'error', control: methods.control });
	const startDate = useWatch<CreateReservationForm, 'startDate'>({ name: 'startDate', control: methods.control });
	const endDate = useWatch<CreateReservationForm, 'endDate'>({ name: 'endDate', control: methods.control });
	const durationScenario = useWatch<CreateReservationForm, 'scenario.duration'>({
		name: 'scenario.duration',
		control: methods.control,
	});

	useEffect(() => {
		if (dayjs().diff(dayjs(startDate), 'minute') > 0) {
			methods.setValue('startDate', dayjs().add(15, 'minute').second(0).toISOString());
		}
		if (durationScenario) {
			if (durationScenario === 'custom') {
				if (dayjs(endDate).diff(dayjs(startDate), 'minute') < 0) {
					const endDateFormated = dayjs(startDate).add(20, 'minute').second(0).toISOString();
					methods.setValue('endDate', endDateFormated);
				}
			} else if (durationScenario === 'iteration') {
				if (dayjs(endDate).diff(dayjs(startDate), 'minute') < 20) {
					const endDateFormated = dayjs(startDate).add(20, 'minute').second(0).toISOString();
					methods.setValue('endDate', endDateFormated);
				}
			} else {
				const duration = timeUtils.asMilliseconds(durationScenario) + timeUtils.asMilliseconds('PT20M');
				if (dayjs(endDate).diff(dayjs(startDate), 'milliseconds') < duration) {
					const endDateFormated = dayjs(startDate).add(duration, 'milliseconds').second(0).toISOString();
					methods.setValue('endDate', endDateFormated);
				}
			}
		}
	}, [startDate, endDate, durationScenario, methods]);

	const onChangeStarDate = (date: dayjs.Dayjs | null) => {
		if (date?.isValid()) {
			methods.setValue('startDate', date.second(0).toISOString());
		}
	};
	const onChangeEndDate = (date: dayjs.Dayjs | null) => {
		if (date?.isValid()) {
			methods.setValue('endDate', date.second(0).toISOString());
		}
	};

	return (
		<FormProvider {...methods}>
			<DialogContent sx={{ padding: '12px 24px', width: '700px' }}>
				<Stack useFlexGap gap={3} marginTop={3}>
					{error !== '' && (
						<Alert sx={{ marginBottom: 2, marginTop: 0, alignItems: 'center' }} severity='error'>
							<Typography variant='body2'>{error}</Typography>
						</Alert>
					)}
					<WorkspaceReservationModal />
					<TestReservationModal />
					<Stack useFlexGap flexDirection='row' alignItems='center' gap={1} justifyContent='space-between'>
						<InputDatePicker
							value={startDate}
							onChange={onChangeStarDate}
							labelDate={t('create.startDate')}
							labelTime={t('create.startTime')}
							minDate={dayjs().add(1, 'minute').second(0).toISOString()}
							onError={(error) => {
								methods.setValue('errorDate', error !== null);
							}}
						/>
						<Typography>-</Typography>
						<InputDatePicker
							value={endDate}
							onChange={onChangeEndDate}
							labelDate={t('create.endDate')}
							labelTime={t('create.endTime')}
							minDate={dayjs().toISOString()}
							onError={(error) => {
								methods.setValue('errorDate', error !== null);
							}}
						/>
					</Stack>
				</Stack>
			</DialogContent>
			<ActionsScheduleTestModal close={close} />
		</FormProvider>
	);
};

export { ScheduleTestLayoutModal };

import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import CachedOutlined from '@mui/icons-material/CachedOutlined';
import Typography from '@mui/material/Typography';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { memo } from 'react';
import {
	atLeastOne,
	atMostMaxInteger,
	convertAsCodeHumanReadableTimeSpecificationToISO8601,
	convertISO8601DurationToAsCodeHumanReadableTimeSpecification,
} from './custom-scenarios-helpers';
import { InputOffset } from '../../../../../common/input/input-offset';
import {
	CustomPopulation,
	ExecutionPolicies,
	PartialCustomPopulation,
	RampUpRange,
	VuDistributions,
} from '@neoload/api';
import { useCustomScenario } from '@neoload/hooks';

type ExecutionPolicyProps = {
	population: CustomPopulation;
};

const shouldUpdate = (prevProps: ExecutionPolicyProps, nextProps: ExecutionPolicyProps) =>
	prevProps.population.name === nextProps.population.name &&
	prevProps.population.duration === nextProps.population.duration &&
	prevProps.population.vuDistribution === nextProps.population.vuDistribution &&
	prevProps.population.executionPolicy === nextProps.population.executionPolicy;

const ExecutionPolicy = memo(
	({ population }: ExecutionPolicyProps) => {
		const { t } = useTranslation(['test']);
		const { updatePopulation } = useCustomScenario();

		const updateExecutionPolicyView = (value: ExecutionPolicies) => {
			const partialPopulation: PartialCustomPopulation = {
				executionPolicy: value,
				duration: value === ExecutionPolicies.DURATION ? '5m' : '1',
				...(population.vuDistribution === VuDistributions.RAMPUP
					? {
							rampUp: {
								every: {
									amount: 1,
									unit: value === ExecutionPolicies.DURATION ? RampUpRange.MINUTES : RampUpRange.ITERATIONS,
								},
							},
						}
					: {}),
			};

			updatePopulation(population.name, partialPopulation, true);
		};

		let currentDuration = population.duration;
		if (population.executionPolicy === ExecutionPolicies.DURATION) {
			currentDuration = convertAsCodeHumanReadableTimeSpecificationToISO8601(currentDuration);
		}

		const updateDuration = (duration: string, isUserInput: boolean) => {
			if (!isUserInput) {
				return;
			}
			let converted = convertISO8601DurationToAsCodeHumanReadableTimeSpecification(duration);
			if (converted === '0s') {
				converted = '1s';
			}
			updatePopulation(population.name, { duration: converted }, true);
		};

		const updateIterations = (iterations: number) => {
			updatePopulation(population.name, { duration: atMostMaxInteger(atLeastOne(iterations)).toString() }, true);
		};

		return (
			<Stack>
				<Typography variant='body1'>Execution Policy</Typography>
				<RadioGroup
					row
					value={population.executionPolicy}
					onChange={(_, value) => updateExecutionPolicyView(value as ExecutionPolicies)}
					sx={{
						marginBottom: 2,
						marginLeft: 2,
					}}
				>
					<FormControlLabel
						value={ExecutionPolicies.DURATION}
						control={<Radio />}
						label={
							<Stack direction='row' useFlexGap gap={1}>
								<AccessTimeOutlinedIcon /> {t('configuration.scenarios.custom.duration')}
							</Stack>
						}
						data-trackingid='custom-scenario-execution-policy-duration'
					/>
					<FormControlLabel
						value={ExecutionPolicies.ITERATION}
						control={<Radio />}
						label={
							<Stack direction='row' useFlexGap gap={1}>
								<CachedOutlined /> {t('configuration.scenarios.custom.iterations')}
							</Stack>
						}
						data-trackingid='custom-scenario-execution-policy-iteration'
					/>
				</RadioGroup>
				{population.executionPolicy === ExecutionPolicies.DURATION ? (
					<InputOffset
						onChange={(value, isUserInput) => updateDuration(value, isUserInput)}
						includeMillis={false}
						textFieldProps={{
							size: 'small',
							variant: 'outlined',
							label: t('configuration.scenarios.custom.duration'),
						}}
						value={currentDuration}
						maxValue='P31DT'
					/>
				) : (
					<TextField
						onChange={(event) => {
							updateIterations(Number.parseInt(event.target.value));
						}}
						size='small'
						type='number'
						value={Number.parseInt(currentDuration)}
						label={t('configuration.scenarios.custom.iterations')}
						sx={{ width: '140px' }}
					/>
				)}
			</Stack>
		);
	},
	(prevProps, nextProps) => shouldUpdate(prevProps, nextProps),
);

export { ExecutionPolicy };

import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ConfigurationForm } from './configuration-form';
import { ConfigurationFormData } from './types.d';
import { customScenarioApiToForm } from './scenarios/custom/custom-scenarios-helpers';
import { TestGenericParams, validateUuid } from '@neoload/utils';
import { useCustomScenario, usePushDocumentTitleHandler, useSetSnackbars } from '@neoload/hooks';
import {
	PatchTestInput,
	Test,
	useGetV4TestsByTestIdScenariosAndScenarioNameQuery,
	usePatchV4TestsByTestIdMutation,
} from '@neoload/api';

type ConfigurePageProps = {
	testInfos: ConfigurationFormData;
};

const ConfigurePage = ({ testInfos }: ConfigurePageProps) => {
	const { testId = '' } = useParams<TestGenericParams>();
	validateUuid(testId);
	const { t } = useTranslation(['test']);
	const { showError, showInfo } = useSetSnackbars();
	const {
		addCustomPopulation,
		addUnsupportedPopulations,
		removeAllCustomPopulation,
		addDefaultCustomPopulationByNames,
	} = useCustomScenario();
	const [patchTest] = usePatchV4TestsByTestIdMutation();
	const { data: customScenarios, isLoading } = useGetV4TestsByTestIdScenariosAndScenarioNameQuery({
		scenarioName: 'custom',
		testId: testId,
	});
	usePushDocumentTitleHandler(testInfos.test.name);

	useEffect(() => {
		if (
			!isLoading &&
			customScenarios?.populations?.length === 0 &&
			testInfos.test.name === testInfos.test.selectedScenarioName
		) {
			addDefaultCustomPopulationByNames([testInfos.project.populations?.values().next().value?.name ?? '']);
		}
		if (!isLoading && customScenarios?.populations && customScenarios?.populations?.length > 0) {
			removeAllCustomPopulation();
			const formPopulations = customScenarioApiToForm(customScenarios.populations);
			for (const population of formPopulations.population) {
				addCustomPopulation(population);
			}
			for (const population of formPopulations.unsupportedPopulation) {
				addUnsupportedPopulations(population);
			}
		}
	}, [
		addCustomPopulation,
		addDefaultCustomPopulationByNames,
		addUnsupportedPopulations,
		customScenarios,
		isLoading,
		removeAllCustomPopulation,
		testInfos,
	]);

	const patch = async (patchTestInput: PatchTestInput): Promise<Test | void> =>
		patchTest({ testId: testId, patchTestInput: patchTestInput })
			.unwrap()
			.then((test) => {
				// If the name is set, the notification is handled by the TestHeader component
				if (!patchTestInput.name) {
					showInfo({
						text: t('configuration.snackbar.configSuccess'),
						id: 'config_success',
						autoHideDuration: 2000,
					});
				}

				return test;
			})
			.catch((_error) => {
				// If the name is set, the notification is handled by the TestHeader component
				if (!patchTestInput.name) {
					showError({ text: t('configuration.snackbar.configFail', { name: testInfos.test.name }), id: 'config_fail' });
				}
			});

	return <ConfigurationForm patchTest={patch} defaultValues={testInfos} />;
};

export { ConfigurePage };

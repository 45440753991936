import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { ChangeEvent, useState } from 'react';
import { CreateReservationForm } from './reservation-helper';
import { LimitedSizeTextField } from '../../../../../common/input/limited-size-text-field';

const reservationNameCheck = (reservationName: string) => {
	if (reservationName.length === 0) {
		return 'common:input.required';
	}
};

const TitleReservationModal = () => {
	const { t } = useTranslation(['reservation']);
	const name = useWatch<CreateReservationForm, 'name'>({ name: 'name' });
	const { setValue } = useFormContext<CreateReservationForm>();
	const [error, setError] = useState<string | undefined>();
	const change = (name: string) => {
		setError(reservationNameCheck(name));
		setValue('name', name);
	};

	return (
		<LimitedSizeTextField
			label={t('create.name')}
			max={150}
			required
			onChange={(event: ChangeEvent<HTMLInputElement>) => change(event.target.value)}
			value={name}
			autoFocus
			fullWidth={true}
			requiredHelperText={t('create.nameRequired')}
			inputProps={{
				style: {
					padding: '0 12px',
					height: '36px',
				},
			}}
			error={!!error}
			size='small'
		/>
	);
};

export { TitleReservationModal };

import IconButton from '@mui/material/IconButton';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { memo, useId } from 'react';
import { ScenarioAsCodePopulation } from '@neoload/api';
import { useCustomScenario } from '@neoload/hooks';

type PopulationItemProps = {
	population: ScenarioAsCodePopulation;
};

const shouldUpdate = (prevProps: PopulationItemProps, nextProps: PopulationItemProps) =>
	prevProps.population.name === nextProps.population.name;

const PopulationItemUnsupported = memo(
	({ population }: PopulationItemProps) => {
		const { t } = useTranslation(['test']);
		const id = useId();
		const { removeCustomPopulation } = useCustomScenario();

		const getPopulationDistribution = () => {
			if ('constant_load' in population) {
				return t('configuration.scenarios.custom.constant');
			}
			if ('rampup_load' in population) {
				return t('configuration.scenarios.custom.rampUp');
			}
			if ('peaks_load' in population) {
				return t('configuration.scenarios.custom.peaks');
			}
			if ('custom_load' in population) {
				return t('configuration.scenarios.custom.custom');
			}
			return '';
		};

		return (
			<Stack useFlexGap flexDirection='row' gap={1} alignItems='center' width='100%' justifyContent='space-between'>
				<Typography>{population.name}</Typography>
				<Stack useFlexGap flexDirection='row' gap={1} alignItems='center' justifyContent='flex-end'>
					<Typography variant='body2' color='text.secondary' id={`${id}-custom-virtual-users`}>
						{t('configuration.scenarios.custom.title.virtualUsers')}
					</Typography>
					<Typography color='text.primary' aria-labelledby={`${id}-custom-virtual-users`}>
						{t('configuration.scenarios.custom.unknown')}
					</Typography>
					<Typography variant='body2' color='text.secondary' id={`${id}-custom-duration`}>
						{t('configuration.scenarios.custom.title.duration')}
					</Typography>
					<Typography color='text.primary' aria-labelledby={`${id}-custom-duration`}>
						{t('configuration.scenarios.custom.unknown')}
					</Typography>

					<Typography variant='body2' color='text.secondary' id={`${id}-custom-duration`}>
						{t('configuration.scenarios.custom.title.distribution')}
					</Typography>
					<Typography variant='body2' color='text.secondary' id={`${id}-custom-duration`}>
						{getPopulationDistribution()}
					</Typography>
					<Tooltip title={t('common:remove')} arrow>
						<span>
							<IconButton
								sx={{ marginLeft: 2 }}
								onClick={(event) => {
									removeCustomPopulation(population.name, true);
									event.stopPropagation();
								}}
								data-trackingid='custom-scenario-delete-population'
							>
								<DeleteOutlined />
							</IconButton>
						</span>
					</Tooltip>
				</Stack>
			</Stack>
		);
	},
	(prevProps, nextProps) => shouldUpdate(prevProps, nextProps),
);

export { PopulationItemUnsupported };

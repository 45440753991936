import IconInfoOutlined from '@tricentis/aura/components/IconInfoOutlined.js';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import IconOpenInNewOutlined from '@tricentis/aura/components/IconOpenInNewOutlined.js';
import Link from '@mui/material/Link';

export const PolicyRegexpInfoIcon = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const { t } = useTranslation('workspace', { keyPrefix: 'deletionPolicies.create.info' });
	const theme = useTheme();
	const onMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const close = () => setAnchorEl(null);

	return (
		<>
			<Grid onMouseEnter={onMouseEnter}>
				<IconInfoOutlined sx={{ color: theme.palette.action.active, fontSize: '1.5em' }} />
			</Grid>
			<Popover
				open={anchorEl !== null}
				anchorEl={anchorEl}
				onClose={close}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
			>
				<Grid onMouseLeave={close}>
					<Paper elevation={5} sx={{ width: '440px' }}>
						<Box padding={2} gap={2} display='flex' flexDirection='column'>
							<Typography variant='subtitle1' title={t('title')}>
								{t('title')}
							</Typography>
							<Link
								rel='noreferrer'
								underline='hover'
								target='_blank'
								href='https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_expressions/Cheatsheet'
							>
								<Box gap={1} display='flex' flexDirection='row'>
									{t('link')}
									<IconOpenInNewOutlined />
								</Box>
							</Link>
						</Box>
					</Paper>
				</Grid>
			</Popover>
		</>
	);
};

import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

type MenuChildProps = {
	title: string;
	icon: React.JSX.Element;
	link: string;
	enabled?: boolean;
};

type MenuSectionProps = {
	title: string;
	enabled?: boolean;
};

export type MenuItemProps = MenuChildProps | MenuSectionProps;

const MenuItem = (props: MenuItemProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();

	const isChild = 'link' in props;
	const isActive = isChild && props.link !== '' && location.pathname.includes(props.link);

	return isChild ? (
		<ListItem disablePadding sx={{ display: 'block' }}>
			<ListItemButton
				selected={isActive}
				sx={{
					minHeight: 48,
					justifyContent: 'initial',
					px: 2.5,
					...(isActive && { backgroundColor: theme.palette.error.main }),
				}}
				onClick={props.link === '' ? undefined : () => navigate(props.link, { replace: true })}
			>
				<ListItemIcon
					sx={{
						minWidth: 0,
						mr: 'auto',
						justifyContent: 'center',
						...(isActive && { color: theme.palette.primary.main }),
						paddingRight: '10px',
					}}
				>
					{props.icon}
				</ListItemIcon>
				<ListItemText
					primary={<Typography variant='body1'>{props.title}</Typography>}
					sx={{ fontVariant: 'none', ...(isActive && { color: theme.palette.primary.main }) }}
				/>
			</ListItemButton>
		</ListItem>
	) : (
		<Typography variant='body1' padding={2}>
			{props.title}
		</Typography>
	);
};

export { MenuItem };

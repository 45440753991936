import ScreenshotMonitorOutlined from '@mui/icons-material/ScreenshotMonitorOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import { GridFilterOperator } from '@mui/x-data-grid-pro';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid/models';
import { useTranslation } from 'react-i18next';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import { ComponentPropsWithoutRef } from 'react';
import { EventCodeRenderer } from './event-code-renderer';
import { getDisplayedDuration } from './drawer/event-detail-utils';
import { DatagridOffsetFilterInput } from '../../../common/data-grid/input/datagrid-offset-filter-input';
import { toSentenceCase } from '@neoload/utils';
import { Event, GetV4ResultsByResultIdEventsApiArg, TestResult } from '@neoload/api';

type FilterableTypes = NonNullable<GetV4ResultsByResultIdEventsApiArg['types']>;

const defaultFilters: ComponentPropsWithoutRef<typeof Toolbar>['defaultFilters'] = [
	{
		active: false,
		filter: {
			id: 'typeIsAlert',
			field: 'type',
			value: 'Alert',
			operator: 'is',
		},
	},
	{
		active: false,
		filter: {
			id: 'typeIsError',
			field: 'type',
			value: 'Error',
			operator: 'is',
		},
	},
	{
		active: false,
		filter: {
			id: 'typeIsObjective',
			field: 'type',
			value: 'Objective',
			operator: 'is',
		},
	},
	{
		active: false,
		filter: {
			id: 'typeIsSla',
			field: 'type',
			value: 'SLA',
			operator: 'is',
		},
	},
	{
		active: false,
		filter: {
			id: 'typeIsCustom',
			field: 'type',
			value: 'Custom',
			operator: 'is',
		},
	},
];

const jmeterFilters = defaultFilters.filter(({ filter: { value } }) => value !== 'SLA' && value !== 'Alert');

export const getDefaultFilters = (loadInjectionProvider: TestResult['loadInjectionProvider']) =>
	loadInjectionProvider === 'JMETER' ? jmeterFilters : defaultFilters;

function EventsDatagridColumns() {
	const { t } = useTranslation(['result']);
	const theme = useTheme();

	const beforeOffsetOperatorLabel = t('filters.before');
	const afterOffsetOperatorLabel = t('filters.after');

	const beforeOffsetOperator: GridFilterOperator = {
		getApplyFilterFn: () => null,
		label: beforeOffsetOperatorLabel,
		value: 'before',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		InputComponent: DatagridOffsetFilterInput,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		InputComponentProps: { type: 'string' },
	};

	const afterOffsetOperator: GridFilterOperator = {
		getApplyFilterFn: () => null,
		label: afterOffsetOperatorLabel,
		value: 'after',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		InputComponent: DatagridOffsetFilterInput,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		InputComponentProps: { type: 'string' },
	};

	const columns: GridColDef<Event>[] = [
		{
			field: 'timeRange',
			headerName: t('events.time'),
			width: 225,
			valueGetter: (_, row) => getDisplayedDuration(row.offset, row.duration, row.type, t('events.pending')),
			sortable: true,
			filterOperators: [beforeOffsetOperator, afterOffsetOperator],
		},
		{
			field: 'fullName',
			filterable: false,
			headerName: t('events.name'),
			flex: 1,
			minWidth: 200,
			sortable: true,
		},
		{
			field: 'screenshot',
			width: 25,
			renderHeader: () => (
				<Tooltip arrow title={t('events.screenshotHeaderTooltip')}>
					<ScreenshotMonitorOutlined style={{ color: theme.palette.action.active, fontSize: 'large' }} />
				</Tooltip>
			),
			sortable: false,
			resizable: false,
			filterable: false,
			align: 'center',
			headerAlign: 'center',
			renderCell: (props: GridRenderCellParams) =>
				props.row.hasScreenshot && (
					<ScreenshotMonitorOutlined style={{ color: theme.palette.action.active, fontSize: 'large' }} />
				),
		},
		{
			field: 'code',
			filterable: false,
			headerName: t('events.code'),
			width: 200,
			sortable: true,
			renderCell: ({ value }: { value?: string }) => value && <EventCodeRenderer eventCode={value} />,
		},
		{ field: 'source', headerName: t('events.source'), width: 200, sortable: true, filterable: false },
		{
			field: 'type',
			headerName: t('events.type'),
			width: 200,
			sortable: false,
			valueOptions: ['Alert', 'Custom', 'Error', 'Objective', 'SLA'],
			valueGetter: (_, { type }) => (type === 'SLA' ? type : toSentenceCase(type)),
			filterable: false,
		},
		{
			field: 'details',
			headerName: '',
			width: 55,
			sortable: false,
			resizable: false,
			filterable: false,
			renderCell: () => (
				<Tooltip arrow title={t('events.viewDetails')}>
					<IconButton size='small'>
						<KeyboardArrowRightIcon />
					</IconButton>
				</Tooltip>
			),
		},
	];
	return columns;
}

export { EventsDatagridColumns, FilterableTypes, defaultFilters, jmeterFilters };

const test = {
	description:
		'The Tests view helps you manage a test from creation to deletion. It includes operations like the execution of a test or its move to another workspace.',
	requiredName: 'Test name is required.',
	deleteConfirm: 'Deleting a test is permanent and cannot be undone',
	createConfirm: 'New test {{name}} created',
	configure: 'Configure',
	labelName: 'Test name',
	editTestName: 'Edit',
	placeholderDescription: 'Description',
	buttonConfirmCreate: 'Create',
	testNameAlreadyExist: 'A test with this name already exists',
	createFailed: 'Failed to create test',
	// eslint-disable-next-line unicorn/no-keyword-prefix
	newTest: 'New test {{number}}',
	testExecution: {
		summary: 'Test run overview',
		configuration: 'Test configuration',
		licenseAllocation: 'License allocation',
		project: 'Project',
		scenario: 'Scenario',
		duration: 'Test duration',
		durationUndetermined: 'Undetermined',
		maxDuration: 'Max. duration',
		virtualUsers: 'Virtual users',
		vuhEstimation: 'VUH estimation',
		vuhEstimationDescription:
			'The real VUH consumption is computed on the effective test duration and maximum number of VU',
		available: 'available',
		cloud: 'Cloud credits',
		cloudDescription: 'One credit per hour per Load Generator running in the NeoLoad Cloud Platform',
		resultInformation: 'Test result information',
		resultName: 'Test name',
		resultNameRequired: 'Test name is required',
		character: 'characters',
		description: 'Description',
		noDescription: '- no description -',
		cancelRun: 'Cancel',
		run: 'Run test',
		lg: 'LG',
		lg_one: 'LG',
		lg_other: 'LGs',
		zones: 'Zones',
		controllerZoneId: '(Controller zone)',
		autoReservation: 'Auto reservation',
		minDuration: 'Min.',
		warningRunTestWithoutValidLicense:
			'No valid license found. Test may proceed only if controller has adequate local license; otherwise, it will fail.',
		errors: {
			// error codes from API
			/* eslint-disable @typescript-eslint/naming-convention */
			MissingLg: 'Insufficient load generators matching controller version.',
			MissingLg_version: 'Insufficient load generators matching controller version {{version}}.',
			MissingController: 'No controller available in the selected zone.',
			IncompatibleVersion: 'Project not compatible with the available controller version.',
			IncompatibleVersion_version:
				'Project not compatible with the available controller version. Requires controller version {{version}} or higher.',
			NotEnoughCloudCredits: 'Insufficient cloud credits: your account lacks the credits needed for this test.',
			NotEnoughVuh: 'Insufficient VUH: your account lacks the VUH needed for this test.',
			ncpUnreachable:
				'Cloud platform unreachable: network timeout or service issue prevents starting the test with cloud LGs. Retry Later.',
			Zones:
				'Infrastructure issue. The number of Cloud Load Generators exceeds the current capacity with the given project size.',
			/* eslint-enable @typescript-eslint/naming-convention */
		},
		notEnoughDedicatedIp: {
			tooltip: {
				controller: 'No dedicated IP available to be assigned to the controller.',
				lg_one: 'Missing {{ips}} dedicated IP.',
				lg_other: 'Missing {{ips}} dedicated IPs.',
			},
			warning: {
				controller: 'Dedicated IP(s) available for LG(s) but not for the controller.',
				lg: 'Insufficient dedicated IP(s): ensure you have enough IP(s) for all LGs and possibly one for the controller.',
			},
		},
		sap: 'SAP:',
		web: 'Web:',
		webEdit: 'Edit Web vus',
		sapEdit: 'Edit SAP vus',
	},
	grid: {
		title: 'Tests',
		header: {
			name: 'Name',
			project: 'Project',
			lastRun: 'Last run',
			lastModifiedBy: 'Last modified by',
			lastModifiedDate: 'Last modified',
		},
		actions: {
			createTest: 'Create test',
			runTest: 'Run test',
			scheduleTest: 'Schedule run',
			moreRunOptions: 'more run options',
			runTestReservation: 'Run with reservation',
			editionConfiguration: 'Configure',
			moveToWorkspace: 'Move to workspace',
			deleteTest: 'Delete',
			addEditDescription: {
				menuItemAdd: 'Add description',
				menuItemEdit: 'Edit description',
				dialogTitle: {
					edit: 'Edit description',
					add: 'Add description',
				},
				label: 'Description',
				button: {
					cancel: 'Cancel',
					save: 'Save',
				},
			},
		},
		run: {
			moreThanOneSelected: "Can't run more than one test",
			notConfigured: 'You must complete the test configurations before running the test.',
		},
		edit: {
			moreThanOneSelected: "Can't configure more than one test",
		},
	},
	configuration: {
		disabledText: 'Upload a project to continue with the configuration',
		project: {
			title: 'NeoLoad Project',
			separator: 'OR',
			protected: 'Protected',
			protectedDescription: 'You must enter the chosen password in NeoLoad GUI to run this project.',
			enterPassword: 'Enter password',
			modalPassword: {
				description: "Enter the chosen password in NeoLoad GUI to access and run '{{projectName}}'.",
				placeholder: 'Password',
				required: 'Password is required',
				apply: 'Apply',
				invalidPassword: 'The password you entered is incorrect. Please try again.',
			},
			export: {
				description: 'Use the "Export and send to NeoLoad Web" feature in NeoLoad GUI to import the project here.',
				title: 'NeoLoad export to web',
			},
			file: {
				title: 'File upload',
				description: 'Drag and drop your project from your local storage, or select it manually.',
				button: 'Select file',
				limit: 'ZIP, YAML (1 file up to {{size}})',
				sizeFile: {
					kb: '{{size}}KB',
					mb: '{{size}}MB',
					gb: '{{size}}GB',
				},
			},
			git: {
				title: 'Git clone',
				description: 'Fetch the project from your Git repository',
				gitRepository: 'Git repository',
				button: {
					clone: 'Clone from Git',
					change: 'Select branch',
				},
				reference: 'Branch',
				modal: {
					title: 'Clone from Git',
					repository: 'Repository',
					reference: 'Branch',
					clone: 'Clone the project',
					loading: 'Loading...',
					noRepositories: 'No Git repository available. Please select a valid repository.',
					noReferences: 'The selected Git repository has no branches.',
				},
			},
			download: 'Download NeoLoad GUI',
			project: 'Project',
			size: 'Size',
			uploaded: 'Uploaded',
			uploadedBy: 'Uploaded by',
			source: 'Source',
			sourceGit: 'Git',
			sourceLocal: 'Local storage',
		},
		scenarios: {
			types: {
				fromProject: 'From project',
				custom: 'Create custom',
			},
			title: 'Load scenario',
			label: 'Scenario',
			virtualUsers: 'Virtual users:',
			vus: 'VUs',
			duration: 'Duration:',
			undetermined: 'undetermined',
			unsupported: 'Unsupported',
			custom: {
				title: {
					virtualUsers: 'Virtual users:',
					duration: 'Duration:',
					iteration_one: '{{count}} iteration',
					iteration_other: '{{count}} iterations',
					distribution: 'VU distribution:',
				},
				notSupported: 'Details unavailable. This population was modified using advanced API configurations.',
				selectPopulations: 'Select populations',
				addPopulationsTitle: 'Add populations',
				addPopulationsButton: 'Add populations',
				addPopulationButton_one: 'Add {{count}} population',
				addPopulationButton_other: 'Add {{count}} populations',
				noMorePopulations: 'You have added all your populations',
				noPopulations: 'No populations',
				virtualUsers: 'Virtual users',
				virtualUsersMax: 'Max. virtual users',
				duration: 'Duration',
				iterations: 'Iterations',
				iterationS: 'iteration(s)',
				vusDistribution: 'Virtual users distribution',
				constant: 'Constant',
				rampUp: 'Ramp up',
				custom: 'Custom',
				peaks: 'Peaks',
				stepRampUp: 'Step ramp up',
				rampUpValues: {
					seconds: 'Seconds',
					minutes: 'Minutes',
					hours: 'Hours',
				},
				add: 'Add',
				every: 'every',
				vus: 'VUs',
				unknown: 'Unknown',
				aria: {
					rampUpVus: 'Ramp up VUs',
					rampUpEvery: 'Ramp up every',
				},
			},
		},
		zones: {
			name: 'Zone name',
			lg: 'LG',
			controller: 'Controller',
			title: 'Load zones',
			types: {
				ncp: 'NeoLoad Cloud',
				static: 'Static',
				dynamic: 'Dynamic',
			},
			noZones: {
				ncp: 'You can add cloud zones through the Resources panel.',
				dynamic: "You don't have any dynamic zone created yet. Use the Resources panel to create at least one.",
			},
			ncp: {
				freeController: 'NeoLoad automatically provides at no cost the controller in the zone with the most LGs.',
			},
			dynamic: {
				noController: 'You can only use one dynamic zone.',
			},
		},
		advanced: {
			title: 'Advanced',
			label: 'Result name mask',
			helper: 'Define a name pattern for all future test results.',
			error: {
				required: 'The test result mask is required',
			},
		},
		snackbar: {
			configSuccess: 'Changes saved',
			configFail: 'Failed to update {{name}}',
			uploading: '{{name}} uploading',
			uploadSuccess: '{{name}} uploaded',
			uploadFail: '{{name}} upload failed: {{error}}',
			fileTooLarge: 'The file {{name}} is too large. Maximum file size allowed is {{maxSize}}.',
			fileExtensionsIncorrect: 'Unsupported file type. Valid extensions are: {{extensions}}.',
		},
		aria: {
			moreActions: 'more test actions',
			configuration: 'test configuration',
		},
		errors: {
			invalidFile: 'Missing .nlp file in the project archive. Add the file and try again.',
			missingProjectContent: 'Project content is missing. Include all required files and try again.',
			parsingFailure: 'Unable to parse the .yaml files. Verify the .yaml files structure and try again.',
			circularReferences:
				'Circular dependencies detected in the as-code files. Resolve the dependencies and try again.',
			noPopulation: 'You need at least one population to run the scenario. Add a population and try again.',
			noName: 'The project name is not unique. Enter a unique project name and try again.',
			nameConflict:
				'The scenario and the test both have the name {{name}}. Rename the scenario or the test and try again. ',
			payloadTooLarge: 'The payload size is too large. Reduce the payload size and try again.',
			tooManyRequests: 'Too many requests. Please wait a moment and try again.',
			unAuthorized: "You aren't authorized to access this project. Check your permissions and try again.",
			notFound: 'Resource not found. Verify the URL or resource and try again.',
			scmUrl: 'The SCM URL is invalid. Check the URL and try again.',
			scmCredentials: 'The SCM credentials are invalid. Verify your credentials and try again.',
			scmHost: 'The SCM host is invalid. Check the host information and try again.',
			scmProxy: 'The SCM proxy is invalid. Verify the proxy settings and try again.',
			tooManyCloudLgs:
				'You exceeded the total limit ({{lgs}}) of cloud load generators allocated for your project size. Enter a support ticket to report this situation.',
			tooManyCloudLgsFileSize:
				'You cannot use multiple cloud zones with your project size. Enter a support ticket to report this situation.',
			customScenario: {
				populationsNameUnique: 'A population can be defined only one time in the scenario.',
				populationsNameNotFound: 'Population not found in the project.',
				durationPositive: 'At least one step from a custom load policy should have a duration greater than 0.',
				stepRampUpUnique: 'All the steps from a custom load policy should have the same duration type.',
				stepRampUpOrdered: "All the steps from a custom load policy should be ordered by the field 'when'.",
				invalidJson: 'The custom scenario is not a valid JSON.',
				uploadTooOld: 'Current project uploaded is too old to use custom scenario.',
				populationsError: 'Population is not valid.',
			},
			incompatibleReservationVus: 'Reservation not matching required virtual users defined in the scenario.',
		},
	},
	zero: {
		title: 'Create your first test',
		contentLine1: 'A test is the basic unit that describes your testing scenario.',
		contentLine2: 'Create a test case to get started with your testing!',
		button: 'Create test',
	},
	zeroGuest: {
		title: 'There is no test here yet',
		contentLine1: '',
		contentLine2: '',
		button: 'Back home',
	},
	create: {
		success_one: 'Test {{name}} has been created',
		error_one: 'One test has not been created: {{cause}}',
	},
	delete: {
		success_one: 'Test {{name}} deleted',
		success_other: '{{count}} tests deleted',
		error_one: 'One test has not been deleted: {{cause}}',
		error_other: '{{count}} tests have not been deleted',
	},
	deleteSingleTestDialog: {
		title: 'Delete test?',
		label:
			"Deleting the test will permanently erase it from your data. It's not reversible, and you won't be able to retrieve it once removed.",
	},
	deleteMultipleTestDialog: {
		title: 'Delete {{testNumber}} tests?',
		label:
			"Deleting the tests will permanently erase them from your data. It's not reversible, and you won't be able to retrieve them once removed.",
	},
	testPage: {
		lastModified: 'Last modified:',
		date: '{{date}} by {{author}}',
	},
	messages: {
		updateNameSuccess: 'Test successfully renamed.',
		updateDescriptionSuccess: 'Description edited',
		deleteDescriptionSuccess: 'Description deleted',
		addDescriptionSuccess: 'Description added',
		updateNameError: 'Failed to rename test.',
	},
	validation: {
		name: {
			empty: 'Test name is required',
			tooLong: 'Test name must not exceed 100 characters',
		},
	},
	trends: {
		title: 'Test analysis',
		description: 'The test analysis graph provides an overview of how your test results key metrics are trending.',
		graph: {
			noData: 'No transaction data available',
			legend: '{{statistic}} of {{element}}',
		},
		success: {
			patchConfiguration: 'Configuration saved',
		},
		errors: {
			patchConfiguration: 'Failed to save configuration',
		},
		downloads: {
			title: 'Export',
			png: 'PNG',
			csv: 'CSV',
		},
		settings: {
			buttonDisabledTooltip: 'You need a successfully started result with transaction data',
			title: 'Select metrics and SLOs',
			metrics: 'Metrics',
			element: 'Element',
			value: 'Value',
			statistic: 'Statistic',
			objectivesTitle: 'Objectives Mode',
			objective: 'Objective',
			referenceResult: 'Reference result',
			tolerance: 'Tolerance',
			shouldFailTestExecutionOnObjectiveExceeded:
				'Test Result status is "Failed" when the boundary of the objective is reached or exceeded',
			cancel: 'Cancel',
			apply: 'Apply',
			objectiveMode: {
				static: 'Static',
				staticInfo: 'You manually set objective values for the metrics',
				baseline: 'Baseline',
				baselineInfo:
					'Use a reference baseline result to set objective values. You can set the tolerance as a percentage',
				automated: 'Automated',
				automatedInfo:
					'NeoLoad calculates the statistical values for the objectives based on the 50 latest passed results. You must specify the tolerance in standard deviations',
			},
			actions: {
				addMetric: 'Add a new metric to the test analysis configuration',
				addMetricLimit: 'You reached the max number of metrics you can add ({{max}})',
			},
			errorMessages: {
				emptyObjective: 'Objective value is empty',
				positiveObjective: 'Objective value must be positive',
				noObjective: 'This element has no transaction for this result. NeoLoad cannot compute its objective value.',
				emptyTolerance: 'Tolerance value is empty',
				positiveTolerance: 'Tolerance value must be positive',
				noResultAvailable: 'No result available for this test',
				noResultSelected: 'No result selected',
			},
		},
		statistics: {
			/* eslint-disable @typescript-eslint/naming-convention */
			duration_percentile_99: 'Perc 99 duration',
			duration_percentile_95: 'Perc 95 duration',
			duration_percentile_90: 'Perc 90 duration',
			duration_percentile_50: 'Perc 50 duration',
			average_duration: 'Average duration',
			minimum_duration: 'Minimum duration',
			maximum_duration: 'Maximum duration',
			element_count: 'Sum count',
			error_count: 'Failure sum count',
			elements_per_second: 'Count per second',
			error_rate: 'Failure rate',
			minimum_ttfb: 'Min. TTFB',
			maximum_ttfb: 'Max. TTFB',
			average_ttfb: 'Avg. TTFB',
			/* eslint-enable @typescript-eslint/naming-convention */
		},
		statisticsUnits: {
			elementsPerSec: {
				short: 'elt/s',
				long: 'Elements per second',
			},
			seconds: {
				short: 's',
				long: 'Seconds',
			},
		},
	},
	reservations: {
		currentReservations: 'Current reservations',
		remaining: 'remaining',
		autoReservation: 'Auto reservation',
		autoReservationText: 'Reservation will be created according to the test launch required resources.',
		lgs: 'LGs',
		vus: 'Vus',
	},
};

export { test };

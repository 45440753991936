import { useTranslation } from 'react-i18next';
import { DashboardsDataGrid } from '../../dashboards';
import { useCrudBatchMessage, useSetSnackbars } from '@neoload/hooks';
import {
	useDeleteV4DashboardsByDashboardIdMutation,
	usePostV4DashboardsByDashboardIdDuplicationMutation,
} from '@neoload/api';

export type ResultDashboardsTabProps = {
	resultId: string;
};
export const ResultDashboardsTab = ({ resultId }: ResultDashboardsTabProps) => {
	const { t } = useTranslation(['dashboard']);
	const { showError, showInfo } = useSetSnackbars();
	const { remove } = useCrudBatchMessage('dashboard');
	const [deleteDashboard] = useDeleteV4DashboardsByDashboardIdMutation();
	const [duplicateDashboard] = usePostV4DashboardsByDashboardIdDuplicationMutation();

	const deleteDashboards = async (dashboardIds: string[]) => {
		await remove(dashboardIds, (dashboardId) => deleteDashboard({ dashboardId: dashboardId }).unwrap());
	};

	const onDashboardDuplicate = (dashboardId: string) => {
		duplicateDashboard({ dashboardId, dashboardDuplicateRequest: {} })
			.unwrap()
			.then(() => {
				showInfo({ text: t('messages.duplicateSuccess') });
			})
			.catch((error) => {
				console.error('error on onDuplicateDashboard', error);
				showError({ text: t('messages.duplicateError') });
			});
	};

	return (
		<DashboardsDataGrid
			onDashboardDuplicate={onDashboardDuplicate}
			onDeleteDashboards={deleteDashboards}
			fromResult={resultId}
		/>
	);
};

import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { CreateReservationForm } from './reservation-helper';
import { LimitedSizeTextField } from '../../../../../common/input/limited-size-text-field';

const DescriptionReservationModal = () => {
	const { t } = useTranslation(['reservation']);
	const description = useWatch<CreateReservationForm, 'description'>({ name: 'description' });
	const { setValue } = useFormContext<CreateReservationForm>();

	return (
		<LimitedSizeTextField
			label={t('create.description')}
			max={500}
			rows={4}
			multiline
			fullWidth
			inputProps={{
				style: {
					padding: '0 12px',
				},
			}}
			onChange={(event) => {
				setValue('description', event.target.value);
			}}
			value={description}
			size='small'
		/>
	);
};

export { DescriptionReservationModal };

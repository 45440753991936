import Toolbar from '@tricentis/aura/components/Toolbar.js';
import { useTranslation } from 'react-i18next';
import { GridCellParams } from '@mui/x-data-grid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import * as React from 'react';
import { ComponentPropsWithoutRef, MutableRefObject, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useReservationsDataGridColumns } from './use-reservations-data-grid-columns';
import { ReservationDrawerContent } from './reservation-drawer-content';
import { NoReservationZeroState } from './no-reservation-zero-state';
import { ScheduleTestModal } from './create/schedule-test-modal';
import { useReservationsDatagridActions } from './use-reservations-datagrid-actions';
import { CreateReservationModal } from './create/create-reservation-modal';
import { CreateReservationForm } from './create/modal/common/reservation-helper';
import { DatagridWithDrawer } from '../../common/data-grid/datagrid-with-drawer';
import { Spinner } from '../../common/spinner';
import { filterToContextMenuItems } from '../../common/data-grid/actions/data-grid-actions';
import { ContextMenu, ContextMenuFuncts } from '../../common/context-menu/context-menu';
import { DEFAULT_GRID_PROPS } from '../../common/datagrid';
import { Reservation, ReservationPage, useGetV4ReservationsQuery, useGetV4ZonesQuery } from '@neoload/api';
import { useGetMe, useSetSnackbars } from '@neoload/hooks';

const defaultPage: ReservationPage = {
	total: 0,
	pageNumber: 0,
	pageSize: 0,
	items: [],
};
const ReservationsDataGrid = () => {
	const { t } = useTranslation(['reservation']);
	const { showError } = useSetSnackbars();
	const [selectedReservation, setSelectedReservation] = useState<Reservation>();
	const [selectedRightReservation, setSelectedRightReservation] = useState<Reservation>();
	const [openScheduleTestModal, setOpenScheduleTestModal] = useState(false);
	const [openCreateReservationModal, setOpenCreateReservationModal] = useState(false);
	const contextMenu: MutableRefObject<ContextMenuFuncts | undefined> = useRef();
	const [scheduleEdit, setScheduleEdit] = useState<{
		workspaceId?: string;
		testId?: string;
		startDate?: dayjs.Dayjs;
		action: CreateReservationForm['action'];
		reservationId?: string;
	}>({
		workspaceId: undefined,
		testId: undefined,
		startDate: undefined,
		action: 'NEW',
		reservationId: undefined,
	});

	const editSchedule = (workspaceId?: string, testId?: string, startDate?: dayjs.Dayjs, reservationId?: string) => {
		setScheduleEdit({
			workspaceId,
			testId,
			startDate,
			reservationId,
			action: 'EDIT',
		});
		setOpenScheduleTestModal(true);
	};

	const apiRef = useGridApiRef();
	const actions = useReservationsDatagridActions(selectedRightReservation, editSchedule);
	const columns = useReservationsDataGridColumns(actions);

	const [{ workspaces: workspaceData = [], isLoading: isFetchingWorkspace }] = useGetMe();

	const {
		data: zonesData = { items: [] },
		isFetching: isFetchingZones,
		error: errorZones,
	} = useGetV4ZonesQuery({ type: undefined });
	useEffect(() => {
		if (errorZones) {
			showError({ text: t('zoneErrorMessage'), autoHideDuration: 10_000 });
		}
	}, [showError, errorZones, t]);

	const { data: reservationPageData = defaultPage, isLoading } = useGetV4ReservationsQuery({});

	if (isLoading) {
		return <Spinner />;
	}

	if (reservationPageData.items.length === 0) {
		return <NoReservationZeroState />;
	}

	const mainActions = [
		{
			children: (
				<Button
					onClick={() => setOpenScheduleTestModal(true)}
					key='scheduleTest'
					variant='contained'
					data-trackingid='schedule-test-grid-button'
				>
					{t('noReservation.scheduleTest')}
				</Button>
			),
		},
		{
			children: (
				<Button
					onClick={() => setOpenCreateReservationModal(true)}
					key='createReservation'
					variant='outlined'
					data-trackingid='create-reservation-grid-button'
				>
					{t('noReservation.createReservation')}
				</Button>
			),
		},
	];

	const componentsProps: { toolbar: ComponentPropsWithoutRef<typeof Toolbar> } = {
		toolbar: {
			description: t('description'),
			displayColumnOptions: true,
			displaySearchBox: true,
			title: t('grid.title'),
			mainActions: mainActions,
			hideFiltersIcon: true,
		},
	};

	const drawerProps: ComponentPropsWithoutRef<typeof DatagridWithDrawer>['drawer'] = {
		onOpen: (params: GridCellParams) => {
			setSelectedReservation(params.row);
		},
		onClose: () => setSelectedReservation(undefined),
		title: t('grid.drawer.title'),
		selectedRowId: selectedReservation?.id,
		minWidth: 400,
	};

	const workspacesMap: Record<string, string> = {};
	for (const workspace of workspaceData) {
		workspacesMap[workspace.id] = workspace.name;
	}
	const zonesMap: Record<string, string> = {};
	for (const zone of zonesData.items) {
		zonesMap[zone.zoneId] = zone.name;
	}

	return (
		<>
			<DatagridWithDrawer
				{...DEFAULT_GRID_PROPS}
				checkboxSelection={false}
				rows={reservationPageData.items}
				disableRowSelectionOnClick={false}
				columns={columns}
				slots={{
					toolbar: Toolbar,
				}}
				slotProps={{
					...componentsProps,
					row: {
						onContextMenu: (event) => {
							event.preventDefault();
							setSelectedRightReservation(
								reservationPageData.items.find(({ id }) => id === event.currentTarget.getAttribute('data-id')),
							);
							contextMenu.current?.openContextMenu(event);
						},
					},
				}}
				loading={isLoading || isFetchingWorkspace || isFetchingZones}
				apiRef={apiRef}
				drawer={drawerProps}
				initialState={{
					sorting: {
						sortModel: [{ field: 'startDate', sort: 'desc' }],
					},
				}}
			>
				{selectedReservation && (
					<ReservationDrawerContent
						reservation={selectedReservation}
						workspacesMap={workspacesMap}
						zonesMap={zonesMap}
					/>
				)}
			</DatagridWithDrawer>
			<ContextMenu apiRef={apiRef} ref={contextMenu} contextMenuItemsList={filterToContextMenuItems(actions)} />
			{openScheduleTestModal && (
				<ScheduleTestModal
					close={() => {
						setOpenScheduleTestModal(false);
						setScheduleEdit({
							action: 'NEW',
							reservationId: undefined,
							startDate: undefined,
							testId: undefined,
							workspaceId: undefined,
						});
					}}
					workspaceId={scheduleEdit.workspaceId}
					testId={scheduleEdit.testId}
					startDate={scheduleEdit.startDate}
					action={scheduleEdit.action}
					reservationId={scheduleEdit.reservationId}
				/>
			)}
			{openCreateReservationModal && <CreateReservationModal close={() => setOpenCreateReservationModal(false)} />}
		</>
	);
};
export { ReservationsDataGrid };

import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { CreateResourceButton } from '../../../common/button/create-resource-button';

type MemberManagementPanelProps = {
	enabledForAllUsers: boolean;
	includeAllUsers: (include: boolean) => Promise<void>;
	isPatching: boolean;
	openAddMembersModal: () => void;
};

const MemberManagementPanel = ({
	enabledForAllUsers,
	includeAllUsers,
	isPatching,
	openAddMembersModal,
}: MemberManagementPanelProps) => {
	const { t } = useTranslation(['workspace']);

	const addMembersDisabled = enabledForAllUsers || isPatching;

	const getCreateTooltip = (): string | undefined => {
		if (enabledForAllUsers) {
			return t('members.addMembersDisabledForAllUsers');
		}
		return undefined;
	};

	return (
		<Stack sx={{ alignItems: 'center' }} direction='row'>
			<FormControlLabel
				control={
					<Switch
						disabled={isPatching}
						checked={enabledForAllUsers}
						onChange={(event) => includeAllUsers(event.target.checked)}
						aria-label={t('members.includeAllMembers')}
					/>
				}
				label={t('members.includeAllMembers')}
			/>
			<Divider orientation='vertical' flexItem sx={{ marginRight: 2 }} />
			<Tooltip arrow title={getCreateTooltip()}>
				<Grid>
					<CreateResourceButton
						onClick={() => openAddMembersModal()}
						disabled={addMembersDisabled}
						aria-label={t('members.addMembers')}
					>
						{t('members.addMembers')}
					</CreateResourceButton>
				</Grid>
			</Tooltip>
		</Stack>
	);
};

export { MemberManagementPanel };

import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import { User, UserWorkspace, Workspace } from '@neoload/api';

type UserWorkspacesDialogProps = {
	user: User;
	workspaces: Workspace[];
	isOpen: boolean;
	onClose: (isOk: boolean, workspaces: UserWorkspace[]) => void;
};

const UserWorkspacesDialog = ({ user, workspaces, isOpen, onClose }: UserWorkspacesDialogProps) => {
	const { t } = useTranslation(['workspace']);
	const label = t('selectWorkspace');
	const save = t('common:save');
	const cancel = t('common:cancel');
	const title = t('workspaceOf');

	const [localWorkspaces, setLocalWorkspaces] = useState(user.workspaces);
	useEffect(() => {
		setLocalWorkspaces(user.workspaces.filter((workspace: UserWorkspace) => !workspace.enabledForAllUsers));
	}, [user.workspaces, isOpen]);
	return (
		<Dialog open={isOpen}>
			<DialogTitle>
				{title} {user.firstName} {user.lastName}
			</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						marginTop: 1,
						width: 500,
					}}
				>
					<Autocomplete
						multiple
						options={workspaces}
						value={localWorkspaces}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						getOptionLabel={(option) => (option as Workspace).name}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox sx={{ marginRight: 1 }} checked={selected} />
								{option.name}
							</li>
						)}
						onChange={(_event, updatedWorkspaces) => setLocalWorkspaces(updatedWorkspaces)}
						renderInput={(params) => <TextField {...params} autoFocus label={label} />}
						size='small'
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => onClose(false, [])}
					color='primary'
					data-trackingid='user-workspaces-cancel'
					size='small'
				>
					{cancel}
				</Button>
				<Button
					onClick={() => onClose(true, localWorkspaces)}
					variant='contained'
					data-trackingid='user-workspaces-ok'
					size='small'
				>
					{save}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export { UserWorkspacesDialog };

import { GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router';
import { getDateValue } from '../../common/data-grid/getters/get-date-value';
import { DatagridAction } from '../../common/data-grid/actions/data-grid-actions';
import { ActionsCellMenu } from '../../common/data-grid/actions/actions-cell-menu';
import { useGetMe } from '@neoload/hooks';
import { TestRoutes } from '@neoload/utils';
import { Test } from '@neoload/api';

function TestsDataGridColumns(actions: DatagridAction[]): GridColDef<Test>[] {
	const { t } = useTranslation(['test']);
	const [{ isGuest }] = useGetMe();

	return [
		{
			field: 'name',
			headerName: t('grid.header.name'),
			flex: 1,
			minWidth: 300,
			renderCell: (props) => (
				<Link underline='hover' component={RouterLink} to={TestRoutes.test(props.id.toString())} title={props.value}>
					{props.value}
				</Link>
			),
		},
		{ field: 'projectName', headerName: t('grid.header.project'), minWidth: 200, filterable: false },
		{
			field: 'lastRunDate',
			valueFormatter: getDateValue,
			headerName: t('grid.header.lastRun'),
			minWidth: 180,
			filterable: false,
		},
		{
			field: 'userModifierName',
			headerName: t('grid.header.lastModifiedBy'),
			minWidth: 180,
			filterable: false,
		},
		{
			field: 'updatedAt',
			valueFormatter: getDateValue,
			headerName: t('grid.header.lastModifiedDate'),
			minWidth: 180,
			filterable: false,
			flex: 0.1,
		},
		...(isGuest
			? []
			: ([
					{
						field: 'actions',
						renderCell: (params) => <ActionsCellMenu rowData={params.row} actions={actions} />,
						resizable: false,
						disableReorder: true,
						type: 'actions',
						minWidth: 50,
						maxWidth: 50,
					},
				] satisfies GridColDef<Test>[])),
	];
}

export { TestsDataGridColumns };

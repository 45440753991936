import { DataGridPro, DataGridProProps, GridApi, GridValidRowModel } from '@mui/x-data-grid-pro';
import * as React from 'react';
import './datagrid.css';

export type DatagridProps = DataGridProProps & {
	apiRef: React.MutableRefObject<GridApi>;
};

const Datagrid = <T extends GridValidRowModel>(props: DatagridProps) => (
	<DataGridPro<T> {...props} apiRef={props.apiRef} />
);

export { Datagrid };

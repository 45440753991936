import Divider from '@mui/material/Divider';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

type MembersCountProps = {
	membersCount: number;
	enabledForAllUsers: boolean;
};

const MembersCount = ({ enabledForAllUsers, membersCount }: MembersCountProps) => {
	const { t } = useTranslation(['workspace']);
	const theme = useTheme();

	return (
		<>
			<GroupOutlinedIcon sx={{ color: theme.palette.action.active }} />
			<Typography marginLeft={1}>
				{enabledForAllUsers ? t('tabs.members.all', { membersCount }) : membersCount}
			</Typography>
			<Divider sx={{ display: 'flex', margin: 2 }} orientation='vertical' />
		</>
	);
};

export { MembersCount };

import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { OneOfZone } from '../common-zones-datagrid';
import { CloudRegion, CloudZoneRead, ZoneRead } from '@neoload/api';

const stringUnionTypeToArray =
	<T extends string>() =>
	<U extends T[]>(...args: U & ([T] extends [U[number]] ? unknown : never)) =>
		args;

const cloudRegionNames = stringUnionTypeToArray<CloudRegion>()(
	'AWS_AF_SOUTH_1',
	'AWS_AP_EAST_1',
	'AWS_AP_NORTHEAST_1',
	'AWS_AP_NORTHEAST_2',
	'AWS_AP_NORTHEAST_3',
	'AWS_AP_SOUTH_1',
	'AWS_AP_SOUTH_2',
	'AWS_AP_SOUTHEAST_1',
	'AWS_AP_SOUTHEAST_2',
	'AWS_AP_SOUTHEAST_3',
	'AWS_AP_SOUTHEAST_4',
	'AWS_AP_SOUTHEAST_5',
	'AWS_CA_CENTRAL_1',
	'AWS_CA_WEST_1',
	'AWS_EU_CENTRAL_1',
	'AWS_EU_CENTRAL_2',
	'AWS_EU_NORTH_1',
	'AWS_EU_SOUTH_1',
	'AWS_EU_SOUTH_2',
	'AWS_EU_WEST_1',
	'AWS_EU_WEST_2',
	'AWS_EU_WEST_3',
	'AWS_IL_CENTRAL_1',
	'AWS_ME_CENTRAL_1',
	'AWS_ME_SOUTH_1',
	'AWS_SA_EAST_1',
	'AWS_US_EAST_1',
	'AWS_US_EAST_2',
	'AWS_US_WEST_1',
	'AWS_US_WEST_2',
	'GCP_AFRICA_SOUTH1',
	'GCP_EUROPE_WEST10',
	'GCP_EUROPE_WEST12',
	'GCP_ME_CENTRAL1',
	'GCP_ME_WEST1',
	'GCP_US_EAST5',
	'GCP_US_SOUTH1',
	'AP_EAST_4',
	'AP_EAST_3',
	'AP_NORTHEAST_7',
	'AP_SOUTH_5',
	'AP_SOUTH_6',
	'AP_SOUTHEAST_8',
	'AP_SOUTHEAST_9',
	'AP_SOUTHWEST_11',
	'EU_CENTER_4',
	'EU_NORTH_2',
	'EU_WEST_7',
	'EU_WEST_15',
	'EU_WEST_16',
	'EU_WEST_18',
	'EU_SOUTH_2',
	'EU_SOUTHWEST_1',
	'NA_NORTHEAST_8',
	'SA_EAST_3',
	'US_EAST_3',
	'US_EAST_9',
	'US_NORTHCENTRAL_4',
	'NA_NORTHEAST_9',
	'US_WEST_6',
	'US_WEST_10',
	'AP_NORTHEAST_8',
	'AP_NORTHEAST_9',
	'AP_SOUTHEAST_10',
	'EU_CENTER_1',
	'EU_CENTER_5',
	'US_WEST_4',
	'US_WEST_11',
	'SA_WEST_1',
);
export type CloudZoneWithAvailability = CloudZoneRead & { available: boolean };

const isAllCloudZones = (zones: ZoneRead[] | undefined): zones is CloudZoneRead[] =>
	zones ? zones.every((zone) => zone.type === 'CLOUD') : false;

const useWithUnavailableCloudZones = (
	zones: ZoneRead[] | undefined,
	type: ZoneRead['type'],
): OneOfZone[] | undefined => {
	const { t } = useTranslation(['zone']);
	const allCloudZones = useMemo<CloudZoneWithAvailability[]>(
		() =>
			cloudRegionNames.map((region) => ({
				type: 'CLOUD',
				zoneId: '',
				name: t(`cloudZone.zones.${region}.name`),
				controllers: [],
				loadGenerators: [],
				cloudRegion: region,
				cloudProvider: t(`cloudZone.zones.${region}.provider`),
				available: false,
			})),
		[t],
	);

	const isCloud = type === 'CLOUD' && isAllCloudZones(zones);
	if (!isCloud) {
		return zones?.map((zone) => ({
			...zone,
			available: true,
		}));
	}
	const availableZones = zones.map((zone) => ({
		...zone,
		name: zone.name.replaceAll(' - AWS', '').replaceAll(' - GCP', ''),
		available: true,
	}));
	const availablecloudRegionNames = new Set(availableZones.map((zone) => zone.cloudRegion));
	const unAvailableZones = allCloudZones.filter((zone) => !availablecloudRegionNames.has(zone.cloudRegion));

	return [...availableZones, ...unAvailableZones];
};

export { useWithUnavailableCloudZones };

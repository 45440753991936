import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { Configuration } from './configuration';
import { ConfigurationFormData } from './types.d';
import { DragDropForm } from './project/drag-drop-form';
import { TestHeader } from '../test-header';
import { PatchTestInput, Test } from '@neoload/api';

type ConfigurationProps = {
	patchTest: (patchTestInput: PatchTestInput) => Promise<Test | void>;
	defaultValues: ConfigurationFormData;
};

const ConfigurationForm = ({ patchTest, defaultValues }: ConfigurationProps) => {
	const { t } = useTranslation(['test']);
	const { handleSubmit, ...methods } = useForm<ConfigurationFormData>({
		mode: 'onChange',
		defaultValues,
	});

	return (
		<FormProvider {...methods} handleSubmit={handleSubmit}>
			<DragDropForm>
				<form
					aria-label={t('configuration.aria.configuration')}
					style={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						width: '100%',
					}}
					// onSubmit should not occur here
					onSubmit={(event) => event.preventDefault()}
				>
					<TestHeader patchTest={patchTest} isConfigurePage description={defaultValues.test.description} />
					<Divider />
					<Box
						sx={{
							flexGrow: 1,
							overflowY: 'auto',
							backgroundColor: 'transparent',
						}}
					>
						<Configuration defaultValues={defaultValues} patchTest={patchTest} />
					</Box>
				</form>
			</DragDropForm>
		</FormProvider>
	);
};

export { ConfigurationForm };

import Stack from '@mui/material/Stack';
import React from 'react';
import { VusDistribution } from './vus-distribution';
import { CustomScenariosChart } from './custom-scenarios-chart';
import { ExecutionPolicy } from './execution-policy';
import { CustomPopulation } from '@neoload/api';

type PopulationDetailsProps = {
	population: CustomPopulation;
};

const PopulationDetails = ({ population }: PopulationDetailsProps) => (
	<Stack direction='row' alignItems='start'>
		<Stack useFlexGap flexDirection='column' gap={3} alignItems='start' sx={{ minWidth: '420px' }}>
			<ExecutionPolicy population={population} />
			<VusDistribution population={population} />
		</Stack>
		<CustomScenariosChart population={population} />
	</Stack>
);

export { PopulationDetails };

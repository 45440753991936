import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DashboardTilesContainer, TileInfo } from './dashboard-tiles-container';
import { DashboardEmptyState } from '../../dashboard-empty-state';
import { TileEditionDialog } from '../edition/form/tile-edition-dialog';
import { TileLayout } from '../types';
import { DashboardTileContent } from '../tile';
import { TileEditionFormFields } from '../edition/form/tile-edition-form';
import { DashboardTile, DashboardTilePageRead, DashboardTileRead, WidgetDashboardTile } from '@neoload/api';
import { useGetMe, useUrlSearchParams } from '@neoload/hooks';

export type DashboardTilesAreaProps = {
	dashboardId: string;
	ownerId: string;
	tilesData: DashboardTilePageRead | { items: never[] };
	onLayoutChange: (layouts: TileLayout[]) => void;
	onDashboardTileDelete: (dashboardTileId: string, dashboardTileName: string) => void;
	onSubmitEditionForm: (formFields: TileEditionFormFields, tileInEdition: DashboardTileRead) => void;
	reportBenchId?: string;
};

const getTileToEdit = (type: string | undefined, tiles: DashboardTileRead[]): DashboardTileRead | undefined => {
	switch (type) {
		case 'SERIES': {
			return {
				id: '',
				title: '',
				x: 0,
				y: 0,
				height: 1,
				width: 1,
				type: 'SERIES',
				series: [],
			};
		}
		case 'TEXT': {
			return {
				id: '',
				title: '',
				type: 'TEXT',
				text: '',
				x: 0,
				y: 0,
				height: 1,
				width: 1,
			};
		}
		case 'WIDGET': {
			return {
				id: '',
				title: '',
				type: 'WIDGET',
				x: 0,
				y: 0,
				height: 1,
				width: 1,
				filter: { type: 'EMPTY' },
				resultId: '',
				resultName: '',
				visualization: 'SUMMARY',
			};
		}
		case 'VALUES_COMPARISON': {
			return {
				id: '',
				type: 'VALUES_COMPARISON',
				columns: [],
				differenceType: 'NONE',
				height: 1,
				idMapping: [],
				rows: [],
				title: '',
				width: 1,
				x: 0,
				y: 0,
			};
		}
		default: {
			return tiles.find((tile: DashboardTile) => tile.id === type);
		}
	}
};

export const DashboardTilesArea = ({
	dashboardId,
	ownerId,
	tilesData,
	onLayoutChange,
	onDashboardTileDelete,
	onSubmitEditionForm,
	reportBenchId,
}: DashboardTilesAreaProps) => {
	const [tileInEdition, setTileInEdition] = useState<DashboardTileRead>();
	const [{ tileToEdit }, setUrlSearchParams] = useUrlSearchParams('tileToEdit');

	const [currentUser] = useGetMe();
	const isGuestAndNotOwner = ownerId !== currentUser.id && currentUser.role === 'GUEST';

	useEffect(() => {
		const selectedTileToEdit = getTileToEdit(tileToEdit, tilesData.items);

		if (tileToEdit && !isGuestAndNotOwner) {
			setTileInEdition(selectedTileToEdit);
		}
	}, [isGuestAndNotOwner, tileToEdit, setTileInEdition, tilesData.items]);

	type TestDataContentType =
		| 'SERIES'
		| 'LINE_CHART'
		| 'PIE_CHART'
		| 'TEXT'
		| 'VALUES_COMPARISON'
		| 'TABLE'
		| 'GRID'
		| 'ERROR'
		| 'EMPTY';
	const getContentType = (tile: DashboardTileRead): TestDataContentType => {
		switch (tile.type) {
			case 'SERIES':
			case 'TEXT':
			case 'EMPTY': {
				return tile.type;
			}
			case 'VALUES_COMPARISON': {
				return 'VALUES_COMPARISON';
			}
			case 'WIDGET': {
				return getWidgetContentType(tile);
			}
			default: {
				return 'ERROR';
			}
		}
	};

	const getWidgetContentType = (tile: WidgetDashboardTile): TestDataContentType => {
		switch (tile.visualization) {
			case 'LINE_CHART':
			case 'PIE_CHART':
			case 'TEXT':
			case 'TABLE': {
				return tile.visualization;
			}
			case 'CATEGORY':
			case 'SUMMARY': {
				return 'GRID';
			}
			case 'COUNTER': {
				return 'TABLE';
			}
			default: {
				return 'ERROR';
			}
		}
	};

	const tileWithContent: TileInfo[] = (tilesData.items as DashboardTileRead[]).map(
		(tile): TileInfo => ({
			data: tile,
			children: <DashboardTileContent tile={tile} dashboardId={dashboardId} isReadOnly={false} />,
			contentType: getContentType(tile),
		}),
	);

	const _onSubmitEditionForm = (formFields?: TileEditionFormFields) => {
		setUrlSearchParams({ tileToEdit: null });
		if (formFields && tileInEdition) {
			onSubmitEditionForm(formFields, tileInEdition);
		}
		setTileInEdition(undefined);
	};

	return (
		<div style={{ height: '100%' }}>
			{tilesData.items.length === 0 && !isGuestAndNotOwner ? (
				<Box sx={{ flexGrow: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<DashboardEmptyState />
				</Box>
			) : (
				<Box sx={{ flexGrow: 1 }}>
					<DashboardTilesContainer
						isReadOnly={isGuestAndNotOwner}
						onLayoutChange={onLayoutChange}
						onDashboardTileDelete={onDashboardTileDelete}
						tilesInfo={tileWithContent}
					/>
				</Box>
			)}
			{tileInEdition && (
				<TileEditionDialog
					dashboardId={dashboardId}
					tile={tileInEdition}
					reportBenchId={reportBenchId}
					onSubmit={_onSubmitEditionForm}
				/>
			)}
		</div>
	);
};

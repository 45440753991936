import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

type AlertWithDetailsProps = {
	details?: string;
	causeList?: string[];
};

const buildDetailsText = (details: string, causeList: string[]) => `${details}\n${causeList.join('\n')}`;

export const AlertWithDetails = ({ details, causeList }: AlertWithDetailsProps) => {
	const { t } = useTranslation('infrastructureProviders');
	const [showDetails, setShowDetails] = useState(false);
	const hasDetails = details ?? causeList?.length !== 0;

	return (
		<Stack direction='column' spacing={2}>
			<Alert
				severity='error'
				action={
					hasDetails && (
						<Button
							sx={{ width: '150px' }}
							variant='text'
							color='secondary'
							onClick={() => setShowDetails((current) => !current)}
						>
							{t(showDetails ? 'modal.hideDetails' : 'modal.showDetails')}
						</Button>
					)
				}
			>
				{t('modal.connectivityNotReady')}
			</Alert>
			{showDetails && (
				<TextField
					data-testid='connectivity-details'
					size='small'
					rows={5}
					multiline
					disabled
					value={buildDetailsText(details ?? '', causeList ?? [])}
				/>
			)}
		</Stack>
	);
};

import { forwardRef, Ref, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { allowedFileUploadExtensionString } from './utils';
import { ConfigurationFormData } from './../types.d';
import { useProjectUpload } from './use-project-upload';

type InputFileProps = {
	onChange?: () => void;
};

const InputFile = forwardRef(({ onChange }: InputFileProps, ref: Ref<HTMLInputElement> | undefined) => {
	const { getValues } = useFormContext<ConfigurationFormData>();
	const handleFileChange = useProjectUpload();
	const [inputKey, setInputKey] = useState(Date.now());
	const projectMaxSize = getValues('projectMaxSize');
	const testId = getValues('test.id');

	return (
		<input
			ref={ref}
			key={inputKey}
			type='file'
			data-testid='inputFile'
			hidden
			accept={allowedFileUploadExtensionString.replaceAll(' ', '')}
			onChange={async (event) => {
				const files = (event.target as HTMLInputElement).files;
				if (files?.[0] && projectMaxSize && testId) {
					await handleFileChange(files?.[0], projectMaxSize, testId);
				}
				setInputKey(Date.now());
				if (onChange) {
					onChange();
				}
			}}
		/>
	);
});

export { InputFile };

import { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';

type RecommendationSectionProps = {
	image: ReactElement;
	title: string;
	content: string;
	buttons: ReactElement;
};

export const RecommendationSection = ({ image, title, content, buttons }: RecommendationSectionProps) => {
	const theme = useTheme();
	return (
		<Card
			sx={{
				padding: {
					sm: theme.spacing(1),
					md: theme.spacing(2),
					lg: theme.spacing(3),
				},
				flex: 1,
			}}
			variant='outlined'
		>
			<Stack sx={{ height: '100%' }} spacing={2} alignItems='flex-start' justifyContent='space-between'>
				{image}
				<Typography title={title} variant='h6'>
					{title}
				</Typography>
				<Typography variant='body1'>{content}</Typography>
				{buttons}
			</Stack>
		</Card>
	);
};

import { useCallback } from 'react';
import { SeriesData } from '../../../add-series';
import { MonitorComparisonStatistic, Statistic } from '../../../add-series/statistics';
import {
	MonitorElement,
	useLazyGetV4ResultsByResultIdElementsQuery,
	useLazyGetV4ResultsByResultIdMonitorsQuery,
	useLazyGetV4ResultsByResultIdQuery,
	UserPathElement,
	UserPathElementValueStatistic,
	ValueComparisonRow,
} from '@neoload/api';

export type TreeSelection = {
	expandedIds: string[];
	nodeId: string;
};

export type ComparisonSerieDefaultValue<T extends Statistic> = SeriesData<T> & TreeSelection;
const findMonitorNode = (
	elements: MonitorElement[] | undefined,
	searchedPath: string[]
): MonitorElement & TreeSelection => {
	for (const element of elements ?? []) {
		if (searchedPath[0] === element.name) {
			if (searchedPath.length === 1) {
				return { ...element, nodeId: element.id, expandedIds: [] };
			}
			const monitorChild = findMonitorNode(element.children, [...searchedPath].slice(1));
			return {
				...monitorChild,
				expandedIds: [element.id, ...monitorChild.expandedIds],
			};
		}
	}
	throw new Error('Cannot retrieve existing Monitor');
};

const findUserpathNode = (
	elements: UserPathElement[] | undefined,
	searchedPath: string[]
): UserPathElement & TreeSelection => {
	for (const element of elements ?? []) {
		if (searchedPath[0] === element.name) {
			if (searchedPath.length === 1) {
				return {
					...element,
					nodeId: element.id,
					expandedIds: [],
				};
			}
			const {
				expandedIds = [],
				nodeId,
				...userPathChild
			} = findUserpathNode(element.children, [...searchedPath].slice(1));

			return {
				...userPathChild,
				nodeId: `${element.name}|${userPathChild.id}`,
				expandedIds: [element.id, ...expandedIds],
			};
		}
	}
	throw new Error('Cannot retrieve existing UserPath');
};

/**
 * Hook that find the mathing element of a ValueComparisonRow in a given result
 * depending on if the ValueComparisonRow is type  MONITOR or ELEMENT, it will
 * search through the result's monitors or elements
 * @param resultId {string} : the id of the result to search through
 * @returns {[function]} a async function that accepts a ValueComparisonRow and
 * will search for its matching monitor/element for the given result
 * @throws error if monitor/element is not found for this result. This would
 * most likely be thrown if the given resultId and ValueComparisonRow do not match
 */
export const useFindInTree = (
	resultId: string
): [
	(
		row: ValueComparisonRow
	) => Promise<ComparisonSerieDefaultValue<UserPathElementValueStatistic | MonitorComparisonStatistic>>
] => {
	const [getResultByResultId] = useLazyGetV4ResultsByResultIdQuery();
	const [getMonitorsByResultId] = useLazyGetV4ResultsByResultIdMonitorsQuery();
	const [getElementsByResultId] = useLazyGetV4ResultsByResultIdElementsQuery();
	return [
		useCallback(
			async (row: ValueComparisonRow) => {
				if (resultId) {
					const { data: testResult } = await getResultByResultId({ resultId });
					if (testResult) {
						if (row && row.rowType === 'MONITOR') {
							const { data } = await getMonitorsByResultId({ resultId });
							const { expandedIds = [], nodeId, ...monitor } = findMonitorNode(data?.rootElements, row.path);
							return {
								type: 'MONITOR',
								testResult,
								comparisonStat: row.statistic,
								monitor: { ...monitor, path: row.path },
								nodeId,
								expandedIds,
							};
						} else if (row && row.rowType === 'ELEMENT') {
							const { data } = await getElementsByResultId({ resultId });
							const { expandedIds = [], nodeId, ...userPath } = findUserpathNode(data?.rootElements, row.path);
							return {
								type: 'USERPATH',
								userPath: row.path,
								testResult,
								userPathElement: userPath,
								userPathId: userPath.id,
								stats: [row.statistic],
								rootElementType: 'USER_PATH',
								nodeId,
								expandedIds,
							};
						}
						throw new Error('Series type not recognized');
					}
				}
				throw new Error('No result');
			},
			[getElementsByResultId, getMonitorsByResultId, getResultByResultId, resultId]
		),
	];
};

const workspace = {
	selectWorkspace: 'Workspaces',
	workspaceOf: 'Workspace of',
	edit: 'Manage workspace(s)',
	members: {
		includeAllMembers: 'Include all account users',
		addMembers: 'Add members',
		users: 'Users',
		allUsersAreMembers: 'All users are already members of this workspace',
		getUsersError: 'Error while fetching users',
		addMembersDisabledForAllUsers: 'Cannot add members to a workspace that includes all account users',
		removeMembersDisabledForAllUsers: 'Cannot add members to a workspace that includes all account users',
		update: {
			success_one: 'Member successfully added',
			error_one: 'Error while adding member',
			success_other: '{{count}} members successfully added',
			error_other: 'Error while adding members',
		},
		delete: {
			success: 'Member successfully removed',
			error: 'Error while removing member',
		},
		removeUser: 'Remove member',
		emptyState: {
			title: 'Add a workspace member',
			body: 'Add members to your workspace so they can access and manage its data based on their assigned roles.',
		},
		selectAll: 'Select all',
		deselectAll: 'Deselect all',
		active: 'Active',
		pending: 'Pending',
	},
	deleteDialog: {
		title: 'Delete workspace?',
		label:
			"Deleting the workspace will permanently erase it from your data. It's not reversible, and you won't be able to retrieve it once removed.",
		warningIntro: 'You will lose all workspace data, including ',
		warningImportant: ' tests, results with all related reports, and dashboards.',
		warningUsers: ' All users will lose access to this workspace.',
		warningToDelete: 'Data to be deleted:',
		warningFetching: ' Fetching',
		warningError: ' Unable to fetch data',
		warningTestsCount_zero: ' 0 test, ',
		warningTestsCount_one: ' 1 test, ',
		warningTestsCount_other: ' {{count}} tests, ',
		warningResultsCount_zero: '0 result and reports. ',
		warningResultsCount_one: '1 result and reports. ',
		warningResultsCount_other: '{{count}} results and reports. ',
		workspaceTypeConfirm: 'Type {{workspaceName}} to confirm',
	},
	grid: {
		title: 'Workspaces',
		name: 'Name',
		description: 'Description',
		membersCount: 'Members',
		allUsers: 'All users',
	},
	messages: {
		deleteSuccess: 'Workspace deleted',
		deleteError: 'Error while deleting workspace',
		deleteErrorForbidden: 'Error while deleting workspace, please make sure all results are terminated',
		updateNameError: 'Failed to update workspace name',
		updateNameAlreadyExists: 'Failed to update workspace name : a workspace with the same name already exists',
	},
	createWorkspace: 'Create workspace',
	editWorkspace: 'Edit workspace',
	create: {
		success_one: 'Workspace {{name}} successfully created',
		error_one: 'Failed to create workspace.',
		error409_one: 'Failed to create workspace, a workspace with the same name already exists.',
		error403_one: 'Failed to create workspace, maximum number of workspaces reached for your license.',
	},
	update: {
		success_one: 'Workspace {{name}} successfully updated',
		error_one: 'Failed to update workspace.',
		error409_one: 'Failed to update workspace, a workspace with the same name already exists.',
		error403_one: 'Failed to update workspace, maximum number of workspaces reached for your license.',
	},
	header: {
		name: 'Workspace name',
		menu: {
			tooltip: 'More actions',
			edit: 'Edit workspace',
			delete: 'Delete workspace',
		},
	},
	tabs: {
		members: {
			title: 'Members',
			all: 'All ({{membersCount}})',
			grid: {
				email: 'Email',
				firstName: 'First name',
				lastName: 'Last name',
				role: 'Role',
			},
		},
		webhooks: {
			title: 'Webhooks',
			grid: {
				name: 'Name',
				trigger: 'Event',
				url: 'URL',
				enabled: 'Enabled',
			},
			enable: 'Enable Webhook',
			disable: 'Disable Webhook',
			enabled: 'Webhook enabled',
			disabled: 'Webhook disabled',
			onResultStarted: 'Test started',
			onResultEnded: 'Test ended',
			nameTooLong: 'Name is too long',
			urlTooLong: 'URL is too long',
			contentTypeTooLong: 'Content type is too long',
			payloadTypeTooLong: 'Payload is too long',
			nameRequired: 'Name is required',
			urlRequired: 'URL is required',
			contentTypeRequired: 'Content type is required',
			urlFormat: 'Please enter a valid URL, example: https://my-server.com',
			payload: 'Payload',
			url: 'URL',
			contentType: 'Content type',
			creationTitle: 'Create webhook',
			editionTitle: 'Edit webhook',
			event: 'Event',
			configuration: 'Configuration',
			resetApplicationType: 'Reset to application/json',
			availableVariables: 'Available NeoLoad Web variables',
			testStart: 'Test starts',
			testEnd: 'Test ends',
			variable: 'Variable',
			learnMore: 'Learn more',
			create: {
				success_one: 'Webhook successfully created',
				error_one: 'Failed to create webhook',
				error400_one: 'Failed to create webhook : invalid URL',
			},
			update: {
				success_one: 'Webhook successfully updated',
				error_one: 'Failed to update webhook',
				error400_one: 'Failed to update webhook : invalid URL',
			},
			zero: {
				title: 'Create your first webhook',
				content:
					'Use a webhook to send notifications to external servers through HTTP requests when NeoLoad web events occur.',
				button: 'Create webhook',
			},
			executionTest: {
				receivedSuccess: 'Webhook returned with success code {{code}}',
				receivedFail: 'Webhook returned with error code {{code}}',
				executionError: 'Unable to test the webhook configuration',
				testWebhook: 'Send test message',
			},
			deleteWebhookDialog: {
				title: 'Delete webhook?',
				label:
					"Deleting the webhook will permanently erase it from your data. It's not reversible, and you won't be able to retrieve it once removed.",
			},
			deleteSuccess: 'Webhook deleted',
			deleteError: 'Error while deleting webhook',
			filters: {
				operator: 'Operator',
				selectFilters: 'Select filters',
				value: 'Value',
				byDuration: 'Result duration',
				byMaxVus: 'Max result VUs',
				byProjectName: 'Project name',
				byQualityStatus: 'Quality status',
				qualityStatusFilterDisabledTooltip: 'Cannot filter by quality status on test start event',
				byScenarioName: 'Scenario name',
				byTestName: 'Test name',
				applyFilters: 'Apply filters',
				valueRequired: 'Value is required',
				valueInteger: 'Must be an integer',
				valuePositive: 'Must be positive',
				maxInt: 'Value must be an integer',
				filters: 'Filters',
				filtersHint: 'Filters combine when selected',
				/*eslint-disable @typescript-eslint/naming-convention */
				operatorIS_LONGER: 'is longer than',
				operatorIS_SHORTER: 'is shorter than',
				operatorIS_GREATER: 'is greater than',
				operatorIS_LOWER: 'is lower than',
				operatorCONTAINS: 'contains',
				operatorDOES_NOT_CONTAIN: 'does not contain',
				operatorIS: 'is',
				operatorIS_NOT: 'is not',
				statusPASSED: 'Passed',
				statusFAILED: 'Failed',
				/*eslint-enable @typescript-eslint/naming-convention*/
			},
		},
		deletionPolicies: {
			title: 'Deletion policies',
			disabledTooltip: 'Deletion policies can only be edited by the members of the Workspace',
			alertExecution: 'Each deletion policy is firstly executed 24 hours after its creation, and then every 24 hours.',
			createButton: 'Create deletion policy',
			alertOverlapping:
				'In the eventuality of overlapping policies, the policy which encompasses the largest number of results will prevail. Deleted results cannot be restored.',
			grid: {
				name: 'Name',
				lastModified: 'Last modified',
				lastModifiedBy: 'Last modified by',
			},
			filter: {
				test: 'On test {{name}}',
				projectAndScenario: 'On project/scenario {{project}}/{{scenario}}',
				resultName: 'On results with name matching {{name}}',
			},
			settings: {
				duration: ' delete results older than {{days}} days',
				count: ' keep latest {{number}} results',
			},
			zero: {
				title: 'Create your first deletion policy',
				content:
					'A deletion policy sets up an automation that cleans the data from your results and keeps your workspace organized.',
			},
			deleteDialog: {
				title: 'Delete policy?',
				label:
					"Deleting the policy will permanently erase it from your data. It's not reversible, and you won't be able to retrieve it once removed.",
			},
			crud: {
				delete: {
					success_one: 'Deletion policy deleted.',
					error_one: 'Failed to delete a deletion policy : {{cause}}',
				},
			},
		},
		subscription: {
			tabTitle: 'Subscription',
			snackbarSuccess: 'Quota succesfully modified',
			title: 'Licensed VUs',
			limitUsage: 'Limit license usage for this workspace',
			protocol: 'Protocol',
			available: 'Available',
			quota: 'Quota',
			noQuota: 'N/A',
			license: 'License',
			web: 'Web',
			sap: 'SAP',
			max: 'Max',
			leases: {
				name: 'Leases',
				offlineRelease: 'Release offline lease',
				columns: {
					type: 'Type',
					owner: 'Owner',
					expiration: 'Expiration date',
					webVus: 'Web VUs',
					sapVus: 'SAP VUs',
					workspace: 'Workspace',
				},
				type: {
					online: 'Online',
					reservation: 'Online for reservation',
					offline: 'Offline',
				},
				download: 'Download',
				details: {
					view: 'View details',
					title: 'Lease details',
					owner: 'Owner',
					clientId: 'Client ID',
					expiration: 'Expiration date',
					concurrentRun: 'Concurrent runs',
					description: 'Description',
				},
				noLease: 'No lease in progress.',
				forceRelease: 'Force release',
			},
			licenseStatus: {
				/* eslint-disable @typescript-eslint/naming-convention*/
				EXPIRED: 'License expired',
				NOT_ACTIVATED: 'License not activated',
				DEACTIVATED: 'License deactivated',
				/* eslint-enable @typescript-eslint/naming-convention*/
				fetchLicenseError: 'Failed to fetch license information',
				licenseNotFound: 'No license found',
			},
		},
	},
	leases: {
		createOfflineLeaseButton: 'Create offline lease',
		createOfflineLeaseTitle: 'Create offline license lease',
		fetchLicenseError: 'Error while fetching license information',
		noLicense: 'No license found',
		fetchSubscriptionError: 'Error while fetching subscription information',
		licenseNotActive: 'License is not active',
		loading: 'Loading...',
		durationHelperText: 'License expires in {{count}} {{unit}}',
		clientId: 'Client ID',
		clientIdPatternError: 'Invalid client ID',
		clientIdDocumentation: 'Documentation on where to find your client ID',
		duration: 'Duration',
		concurrentRuns: 'Concurrent runs',
		availableHelperText: '{{count}} available',
		positive: 'Value must be positive',
		minimumOne: 'At least 1 needed',
		virtualUsers: 'Virtual users',
		webVus: 'Web',
		sapVus: 'SAP',
		maxDuration: 'Duration is too long',
		minDuration: 'Duration is too short',
		sucessFullyCreated: 'Offline lease successfully created',
		noValidLicenseError: 'Failed to create offline lease : no valid license available',
		clientIdFormatError: 'Failed to create offline lease : client ID format is invalid',
		currentClientIdError:
			'Failed to create offline lease : Cannot lease license offline for the current NeoLoad Web, please copy/paste it from the NeoLoad on which you want to install the lease',
		vusTooHighError: 'Failed to create offline lease : the number of VU(s) is higher than those available',
		vusTooLowError: 'Failed to create offline lease : the number of VU(s) is lower than the minimum authorized',
		concurrentCountError: 'Failed to create offline lease : not enough concurrent runs available',
		licenseWillBeExpiredError: 'Failed to create offline lease : license will be expired before the end of the lease',
		clientIdAlreadyInUse:
			'Failed to create offline lease : an offline license lease already exists with the same Client Id',
		createOfflineLeaseError: 'Failed to create offline lease',
		downloadSuccess: 'Lease file {{fileName}} successfully downloaded',
		downloadError: 'Error while downloading the lease',
		offlineReleaseSuccess: 'Offline lease successfully released',
		offlineReleaseFail: 'Error while releasing offline lease',
		offlineReleaseNotParseable: 'Failed to release offline lease : file is not readable',
		licenseNotFound: 'License not found',
		offlineReleaseInvalidExtension: 'Please select a valid release file (with .rls extension)',
	},
	editDialog: {
		title: 'Edit workspace',
		name: 'Name',
		description: 'Description',
		empty: 'Workspace name is required',
	},
	deletionPolicies: {
		update: {
			title: 'Edit deletion policy',
			success_one: 'Deletion policy successfully updated.',
			error_one: 'Failed to update deletion Policy.',
			error404_one: 'Deletion policy not found.',
		},
		create: {
			title: 'Create deletion policy',
			success_one:
				'Deletion policy set. We will remove results according to this policy, except for protected results.',
			error_one: 'Failed to create deletion Policy.',
			execution: {
				suffixZero: 'None',
				suffix: '{{resultsDeleted}} of {{totalResultNumber}}',
				base: 'Unprotected test results currently meeting the conditions: ',
				create: 'The policy will run daily, starting tomorrow {{hour}}',
			},
			filter: {
				label: 'Filter by',
				test: {
					radioButton: 'Test',
					placeholder: 'Test name',
					empty: 'No test found in the workspace',
					loading: 'Loading tests...',
					loadingError: 'Fail to load tests',
					errorMessages: {
						empty: 'A test is required',
					},
					emptiness: {
						noItems:
							'There is no test in workspace {{workspaceName}}. Please start at least one test in this workspace.',
						noRemainingItems:
							'All the tests in workspace {{workspaceName}} are already used by other deletion policies',
					},
				},
				project: {
					radioButton: 'Project/Scenario',
					placeholder: 'Project',
					empty: 'No project found in the workspace',
					loading: 'Loading projects...',
					loadingError: 'Fail to load projects',
					errorMessages: {
						empty: 'A project is required',
					},
					emptiness: {
						noItems:
							'There is no project in workspace {{workspaceName}}. Please start at least one test in this workspace.',
						noRemainingItems:
							'All the combinations project/scenario in workspace {{workspaceName}} are already used by other deletion policies',
					},
				},
				scenario: {
					placeholder: 'Scenario',
					empty: 'No scenario found in the selected project',
					loading: 'Loading scenarii...',
					loadingError: 'Fail to load scenarii',
					errorMessages: {
						empty: 'A scenario is required',
					},
					emptiness: {
						noItems:
							'There is no scenario in workspace {{workspaceName}}. Please start at least one test in this workspace.',
						noRemainingItems: 'Please select a project first.',
					},
				},
				resultNamePattern: {
					radioButton: 'Result name pattern',
					placeholder: 'Regular expression',
					errorMessages: {
						empty: 'A regular expression is required',
						alreadyUsed: 'This regular expression is already used',
						noBlankCharacters: 'The regular expression cannot contain only blank characters',
					},
				},
			},
			setting: {
				label: 'Deletion rule',
				duration: {
					label: 'Delete results older than',
					unit: 'days',
				},
				count: {
					label: 'Keep latest',
					unit: 'results',
				},
			},
			info: {
				title: 'Regular expression',
				link: 'Learn more',
			},
		},
	},
};
export { workspace };

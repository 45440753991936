import { ComponentPropsWithoutRef, MouseEvent, ReactElement } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import DGColumnContextMenuButton from '@tricentis/aura/components/DGColumnContextMenuButton.js';
import { TooltipProps } from '@mui/material/Tooltip';
import { IconButtonProps } from '@mui/material/IconButton';

/**
 * Action of a datagrid to execute on selected rows.
 */
export type DatagridAction = {
	/**
	 * icon of the action
	 * ie <DeleteOutlined/>
	 */
	icon: ReactElement;
	/**
	 * action label
	 */
	text: string;
	/**
	 * Tooltip for toolbar secondary actions item. If not used, the text will be used instead.
	 * Usually use it when action is disabled and you want to explain why in the tooltip.
	 */
	tooltip?: string;
	/**
	 * Action to execute when clicked.
	 */
	action: (event?: MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Disable or not the action. Note: action will still be displayed but as disabled. To hide
	 * it you need to specify hidden
	 */
	disabled?: boolean;
	/**
	 *  Hide completely the action.
	 */
	hidden?: boolean;
	color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
	/**
	 * Single item variant generation. Use it when you want to generate actions columns (specific to a row).
	 * @param rowData
	 * @returns
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	singleItem?: (rowData: any) => Partial<DatagridSingleItemAction>;
	/**
	 * Custom css
	 */
	sx?: SxProps<Theme>;
};

/**
 * Action of a datagrid to execute on a single row. This differs to DatagridAction
 * because this is a single item action and will not be triggered on whole selection.
 * Every field here will overload the global one.
 */
export type DatagridSingleItemAction = {
	/**
	 * icon of the action
	 * ie <DeleteOutlined/>
	 */
	icon: ReactElement;
	/**
	 * action label
	 */
	text: string;
	/**
	 * Action to execute when clicked.
	 */
	action: (event?: React.MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Disable or not the action. Note: action will still be displayed but as disabled. To hide
	 * it you need to specify hidden
	 */
	disabled: boolean;
	/**
	 *  Hide completely the action.
	 */
	hidden?: boolean;
	/**
	 * Custom css
	 */
	sx: SxProps<Theme>;
};

export const mapColor = (color: ['primary', 'secondary', 'success', 'error', 'info', 'warning'][number]) =>
	`var(--mui-palette-${color}-main)`;

export type ContextMenuItemProps = ComponentPropsWithoutRef<typeof DGColumnContextMenuButton>['contextMenu'][number] & {
	tooltipProps: Omit<TooltipProps, 'children'>;
};

export const toContextMenuItem = ({
	icon,
	text,
	disabled,
	color,
	sx,
	action,
	tooltip,
	hidden,
}: DatagridAction): ContextMenuItemProps => ({
	icon: <icon.type {...icon.props} {...{ sx: { ...(color ? { color: mapColor(color) } : {}), ...sx } }} />,
	text,
	disabled,
	hidden,
	tooltipProps: {
		arrow: true,
		title: tooltip ?? text,
	},
	color: color ? mapColor(color) : undefined,
	sx: { ...(color ? { color: mapColor(color) } : {}), ...sx },
	onClickMenuItem: () => action(),
});

export type SecondaryActionProps = {
	dataTestId?: string;
	tooltipProps?: Omit<TooltipProps, 'children'>;
} & Pick<IconButtonProps, 'aria-label' | 'color' | 'children' | 'disabled' | 'key' | 'onClick' | 'size'>;

export const toSecondaryAction = ({
	icon,
	text,
	disabled,
	color,
	action,
	tooltip,
}: DatagridAction): SecondaryActionProps => ({
	children: icon,
	disabled,
	color,
	onClick: action,
	tooltipProps: {
		arrow: true,
		title: tooltip ?? text,
	},
});

export const filterToSecondaryActions = (actions: DatagridAction[]) =>
	actions.filter(({ hidden }) => !hidden).map(toSecondaryAction);

export const filterToContextMenuItems = (actions: DatagridAction[]) =>
	actions.filter(({ hidden }) => !hidden).map(toContextMenuItem);

export const toSingleItemAction = (actionProps: DatagridAction, rowData: unknown): DatagridSingleItemAction => {
	const {
		icon = actionProps.icon,
		text = actionProps.text,
		disabled = actionProps.disabled ?? false,
		action = actionProps.action,
		sx = actionProps.sx ?? {},
		hidden = actionProps.hidden,
	} = actionProps.singleItem ? actionProps.singleItem(rowData) : {};
	return {
		icon,
		text,
		disabled,
		hidden,
		action,
		sx: { ...sx, ...(actionProps.color ? { color: mapColor(actionProps.color) } : {}) },
	};
};

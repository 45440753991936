import { GridColDef } from '@mui/x-data-grid/models';
import { useTranslation } from 'react-i18next';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { EnableWebhookSwitchButton } from './enable-webhook-switch-button';
import { ActionsCellMenu } from '../../../common/data-grid/actions/actions-cell-menu';
import { DatagridAction } from '../../../common/data-grid/actions/data-grid-actions';
import { Webhook } from '@neoload/api';

const useWebhooksDatagridColumns = (actions: DatagridAction[]) => {
	const { t } = useTranslation('workspace', { keyPrefix: 'tabs.webhooks' });
	const columns: GridColDef<Webhook>[] = [
		{ field: 'name', headerName: t('grid.name'), minWidth: 350 },
		{
			field: 'trigger',
			headerName: t('grid.trigger'),
			minWidth: 350,
			renderCell: ({ row }) => t(row.trigger === 'ON_RESULT_STARTED' ? 'onResultStarted' : 'onResultEnded'),
		},
		{
			field: 'url',
			headerName: t('grid.url'),
			flex: 1,
		},
		{
			field: 'enabled',
			headerName: t('grid.enabled'),
			minWidth: 150,
			renderCell: ({ row }) => <EnableWebhookSwitchButton enabled={row.enabled} webhookId={row.id} />,
		},
		{
			field: 'actions',
			renderCell: (params: GridRenderCellParams) => <ActionsCellMenu actions={actions} rowData={params.row} />,
			resizable: false,
			disableReorder: true,
			type: 'actions',
		},
	];
	return columns;
};

export { useWebhooksDatagridColumns };

import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import IconErrorOutlined from '@tricentis/aura/components/IconErrorOutlined.js';

const colorGauge = (percentage: number, thresholdWarning: number, thresholdError: number) => {
	if (percentage > thresholdError) {
		return 'error';
	}
	if (percentage > thresholdWarning) {
		return 'warning';
	}
	return 'primary';
};

type ValueType = 'RAW' | 'DURATION';
type GaugeSubscriptionProps = {
	title: string;
	valueType: ValueType;
	free: number | undefined;
	total: number | undefined;
	thresholdWarning?: number;
	thresholdError?: number;
};

const round = (quantity: number) => Math.round(Math.abs(quantity) * 100) / 100;

const formatQuantity = (quantity: number, valueType: ValueType) =>
	valueType === 'RAW' ? '' + round(quantity) : `${round(quantity / 60)}h`;

const SubscriptionGauge = ({
	title,
	valueType,
	free = 0,
	total = 0,
	thresholdWarning = 80,
	thresholdError = 90,
}: GaugeSubscriptionProps) => {
	const { t } = useTranslation(['subscription'], { keyPrefix: 'saasPlan.gauge' });
	const isValid = free !== undefined && total !== undefined && total !== 0;

	const used = (total ?? 0) - (free ?? 0);
	const usedValuePercentage = isValid ? (used / total) * 100 : 0;

	return (
		<Stack>
			<Stack direction='row' spacing={1} alignItems='center'>
				<Typography variant='subtitle2'>{title}</Typography>
			</Stack>
			<Stack direction='row' spacing={1} alignItems='center'>
				<LinearProgress
					variant='determinate'
					value={usedValuePercentage < 100 ? usedValuePercentage : 100}
					color={colorGauge(usedValuePercentage, thresholdWarning, thresholdError)}
					sx={{ width: '40%' }}
				/>
				<Typography variant='body2' color='secondary'>
					{Math.round(usedValuePercentage)}%
				</Typography>
			</Stack>
			<Stack direction='row' spacing={1}>
				{isValid ? (
					<>
						<Typography variant='caption'>
							{free < 0 ? '-' : ''}
							{formatQuantity(free, valueType)} {t('free')}
						</Typography>
						<Typography variant='caption' color='secondary'>
							/ {used < 0 ? '-' : ''}
							{formatQuantity(used, valueType)} {t('used')}
						</Typography>
						<Typography variant='caption' color='secondary'>
							/ {total < 0 ? '-' : ''}
							{formatQuantity(total, valueType)} {t('total')}
						</Typography>
					</>
				) : (
					<Stack direction='row' alignItems='center' spacing={1}>
						<IconErrorOutlined color='error' fontSize='small' />
						<Typography variant='caption' color='error'>
							{t('error')}
						</Typography>
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

export { SubscriptionGauge };

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import React from 'react';
import TextField from '@mui/material/TextField';
import { WorkspaceFormFields } from './workspace-form-modal';

export const WorkspaceModalContent = () => {
	const { t } = useTranslation(['workspace']);
	const {
		watch,
		register,
		formState: { errors },
	} = useFormContext<WorkspaceFormFields>();

	const name = watch('name');
	const description = watch('description');

	const getHelperText = (value: string | undefined, maxLength: number) =>
		`${value?.length ?? 0}/${maxLength} ${t('common:input.characters')}`;

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
				marginTop: 1,
			}}
		>
			<TextField
				autoFocus
				label={t('common:name')}
				size='small'
				fullWidth
				margin='normal'
				required
				error={!!errors.name}
				helperText={errors.name?.message ?? getHelperText(name, 140)}
				InputProps={{ inputProps: { maxLength: 140 } }}
				{...register('name', {
					required: t('common:input.required'),
				})}
			/>
			<TextField
				rows={5}
				multiline={true}
				label={t('common:description')}
				fullWidth
				margin='normal'
				helperText={getHelperText(description, 2000)}
				InputProps={{ inputProps: { maxLength: 2000 } }}
				{...register('description')}
			/>
		</Box>
	);
};

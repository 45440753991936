import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import AddOutlined from '@mui/icons-material/AddOutlined';
import IconChevronDownOutlined from '@tricentis/aura/components/IconChevronDownOutlined.js';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AutorenewOutlined from '@mui/icons-material/AutorenewOutlined';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { IntervalForm } from './popper/interval-form';
import { PopperWrapper } from './popper/popper-wrapper';
import { IntervalEditionDragState } from '../result-overview-graphs';
import {
	IntervalPostRequest,
	usePostV4ResultsByResultIdIntervalGenerationMutation,
	usePostV4ResultsByResultIdIntervalsMutation,
} from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';
import {
	DEFAULT_INTERVAL_COLOR,
	extractIntervalIncompatibilities,
	IntervalIncompatibility,
	reduceIncompatibilities,
	timeUtils,
} from '@neoload/utils';

const DEFAULT_COLOR = DEFAULT_INTERVAL_COLOR;

export type IntervalAddButtonProps = {
	resultId: string;
	resultDuration: string;
	resultEndDate?: string;
	isRunning: boolean;
	startCreating: () => void;
	stopCreating: () => void;
	intervalDragState: IntervalEditionDragState;
	getIntervalRect: () => DOMRect | undefined;
	onOffsetsInputsEdited: (startOffset: string | undefined, endOffset: string | undefined) => void;
	onColorChanged: (hexColor: string) => void;
	disabled: boolean;
	chartContainer?: HTMLElement;
};

const getDefaultIntervalPostRequest = (name: string): IntervalPostRequest => ({
	name: name,
	startOffset: 'PT0S',
	endOffset: 'PT0S',
	color: DEFAULT_COLOR,
});

const getIntervalPostRequest = (
	intervalPostRequest: IntervalPostRequest,
	intervalDragState: IntervalEditionDragState,
): IntervalPostRequest => ({
	...intervalPostRequest,
	...(intervalDragState.editedStartOffset && { startOffset: intervalDragState.editedStartOffset }),
	...(intervalDragState.editedEndOffset && { endOffset: intervalDragState.editedEndOffset }),
});

const IntervalAddButton = ({
	resultId,
	resultDuration,
	resultEndDate,
	isRunning,
	getIntervalRect,
	startCreating,
	stopCreating,
	onOffsetsInputsEdited,
	intervalDragState,
	onColorChanged,
	disabled,
	chartContainer,
}: IntervalAddButtonProps) => {
	const { t } = useTranslation(['result']);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();
	const isMenuOpen = !!anchorEl;
	const [intervalRect, setIntervalRect] = useState<DOMRect | undefined>(undefined);
	const [postInterval] = usePostV4ResultsByResultIdIntervalsMutation();
	const [postAugmentedAnalysis, { isLoading: isLoadingAugmentedAnalysis }] =
		usePostV4ResultsByResultIdIntervalGenerationMutation();
	const { showInfo, showWarning, showError } = useSetSnackbars();
	const [intervalPostRequest, setIntervalPostRequest] = useState<IntervalPostRequest>(
		getIntervalPostRequest(getDefaultIntervalPostRequest(t('overview.interval.defaultName')), intervalDragState),
	);
	const augmentedAnalysisAvailable = !isRunning && timeUtils.asMilliseconds(resultDuration) > 120_000;

	useEffect(() => {
		setIntervalPostRequest((current) => getIntervalPostRequest(current, intervalDragState));
	}, [intervalDragState]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		augmentedAnalysisAvailable ? setAnchorEl(event.currentTarget) : createManualInterval();
	};

	const closeMenu = () => {
		setAnchorEl(undefined);
	};

	const createManualInterval = () => {
		startCreating();
		setIntervalRect(getIntervalRect());
		closeMenu();
	};

	const handleClose = () => {
		setIntervalRect(undefined);
		stopCreating();
	};

	const startAugmentedAnalysis = async () => {
		closeMenu();
		await postAugmentedAnalysis({ resultId })
			.unwrap()
			.then(
				(intervalPage) => showInfo({ text: t('overview.interval.generated', { count: intervalPage.total }) }),
				(error) => {
					const incompatibilities = extractIntervalIncompatibilities(error);
					if (incompatibilities) {
						for (const incompatibility of reduceIncompatibilities(incompatibilities)) {
							const text = t(`intervals.incompatibility.${incompatibility.source}`);
							incompatibility.level === 'warning' ? showWarning({ text }) : showError({ text });
						}
					} else {
						showError({ text: t('overview.interval.generateFailed') });
					}
				},
			);
	};

	const handleSubmit = (
		intervalToSubmit: IntervalPostRequest,
		ignoreIncompatibilities: boolean,
	): Promise<IntervalIncompatibility[]> =>
		postInterval({
			resultId,
			intervalPostRequest: intervalToSubmit,
			ignoreIncompatibilities,
		})
			.unwrap()
			.then(
				(_interval) => handleClose(),
				(error) => extractIntervalIncompatibilities(error) ?? showError({ text: t('intervals.createFailed') }),
			)
			.then((response) => (Array.isArray(response) ? response : []));

	const open = intervalRect !== undefined;

	return (
		<>
			<Button
				data-testid='result-add-interval'
				aria-haspopup='true'
				onClick={handleClick}
				variant='outlined'
				color='primary'
				size='small'
				startIcon={isLoadingAugmentedAnalysis ? <AutorenewOutlined /> : <AddOutlined />}
				endIcon={augmentedAnalysisAvailable ? <IconChevronDownOutlined /> : undefined}
				disabled={disabled || isLoadingAugmentedAnalysis}
			>
				{isLoadingAugmentedAnalysis ? t('overview.interval.generating') : t('overview.interval.addButtonLabel')}
			</Button>
			<Menu
				open={isMenuOpen}
				anchorEl={anchorEl}
				onClose={closeMenu}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuItem onClick={startAugmentedAnalysis}>
					<ListItemText
						primary={
							<Typography sx={{ display: 'flex', alignItems: 'center' }}>
								{t('overview.interval.generate')}
								<Tooltip arrow placement='top' title={t('overview.interval.generateTooltip')}>
									<InfoOutlined
										sx={{
											marginLeft: '4px',
										}}
										fontSize='small'
										color='secondary'
									/>
								</Tooltip>
							</Typography>
						}
						secondary={t('overview.interval.generateDesc')}
					/>
				</MenuItem>
				<MenuItem onClick={createManualInterval}>
					<ListItemText primary={t('overview.interval.manual')} secondary={t('overview.interval.manualDesc')} />
				</MenuItem>
			</Menu>
			{open && (
				<PopperWrapper
					intervalRect={intervalRect}
					onClickAway={handleClose}
					open={open}
					chartContainer={chartContainer}
				>
					<IntervalForm
						interval={intervalPostRequest}
						resultDuration={resultDuration}
						resultEndDate={resultEndDate}
						onCancel={handleClose}
						onSubmit={handleSubmit}
						onOffsetsInputsEdited={onOffsetsInputsEdited}
						onColorChanged={onColorChanged}
					/>
				</PopperWrapper>
			)}
		</>
	);
};

export { IntervalAddButton };

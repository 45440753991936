import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { TestExecutionModalContent } from './test-execution-modal-content';
import { TestExecutionModalActions } from './test-execution-modal-actions';
import { TestExecutionReservation } from '../sections/test-execution-reservation';
import { TestExecutionForm } from '../types';

type TestExecutionModalLayoutProps = {
	close: () => void;
	defaultValues: TestExecutionForm;
};

const TestExecutionModalLayout = ({ close, defaultValues }: TestExecutionModalLayoutProps) => {
	const { t } = useTranslation(['test']);

	const { ...methods } = useForm<TestExecutionForm>({
		mode: 'onChange',
		defaultValues: defaultValues,
	});

	return (
		<FormProvider {...methods}>
			<DialogTitle>
				<Stack useFlexGap justifyContent='space-between' flexDirection='row' alignItems='center'>
					{t('testExecution.summary')}
					<TestExecutionReservation />
				</Stack>
			</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 3,
						marginTop: 1,
						width: '755px',
					}}
				>
					<TestExecutionModalContent />
				</Box>
			</DialogContent>
			<TestExecutionModalActions close={close} />
		</FormProvider>
	);
};

export { TestExecutionModalLayout };

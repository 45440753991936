import { memo, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { BaseQueryFn, QueryActionCreatorResult, QueryDefinition } from '@reduxjs/toolkit/query';
import CachedOutlined from '@mui/icons-material/CachedOutlined';
import Stack from '@mui/material/Stack';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { ValuesFilters } from './values-tab/values-filters';
import { Category, ValuesDataGrid } from './values-tab/values-data-grid';
import { CountersDataGrid } from './counter-tab/counters-data-grid';
import { IntervalFilter } from '../interval-filter';
import { ComponentTabPanel } from '../../../layout/component-tab-panel/component-tab-panel';
import { getValuesCategories, ResultRoutes, ResultValuesParams, ValuesCategory } from '@neoload/utils';
import { Interval, ResultContext, TestResult } from '@neoload/api';
import { UpdateUrlProps, useUrlSearchParams } from '@neoload/hooks';

const urlKeys = ['populations', 'userPaths', 'zones', 'intervalId'] as const;
type UrlKeysType = typeof urlKeys;

// 0 => STM (transactions, pages, requests and actions)
// 1 => IM (counters)
type SelectedGrid = 0 | 1;

export type ResultTabValuesProps = {
	intervals: Interval[];
	context: ResultContext;
	isRunning: boolean;
	resultDuration: string;
	loadInjectionProvider: TestResult['loadInjectionProvider'];
};
export type ValuesDataGridFuncts = {
	refetch: () => QueryActionCreatorResult<QueryDefinition<unknown, BaseQueryFn, string, unknown>>;
};

export const isQueryNotAvailable = (
	key: UrlKeysType[number],
	availables: string[],
	actualSearchParams: { [K in UrlKeysType[number]]?: string | undefined },
) => actualSearchParams[key] && !availables.includes(actualSearchParams[key] ?? '');

const ResultTabValuesInternal = ({
	context,
	isRunning,
	intervals,
	resultDuration,
	loadInjectionProvider,
}: ResultTabValuesProps) => {
	const navigate = useNavigate();

	const { t } = useTranslation(['result']);
	const { resultId = '', subTab } = useParams<ResultValuesParams>();
	const selectedGrid: SelectedGrid = subTab === 'counters' ? 1 : 0;
	const [searchParams, setSearchParams, fullUrlParams] = useUrlSearchParams(...urlKeys);
	const [isDataFetching, setIsDataFetching] = useState<boolean>(false);
	const valuesDatagrid: MutableRefObject<ValuesDataGridFuncts | undefined> = useRef();
	const counterDatagrid: MutableRefObject<ValuesDataGridFuncts | undefined> = useRef();

	useEffect(() => {
		if (!subTab || !getValuesCategories(loadInjectionProvider).includes(subTab)) {
			void navigate(ResultRoutes.values(resultId), { replace: true });
		}
	}, [subTab, loadInjectionProvider, navigate, resultId]);

	useEffect(() => {
		const update: UpdateUrlProps<UrlKeysType> = {};
		const fieldsToCheckUrl: [UrlKeysType[number], string[]][] = [
			['populations', context.populations],
			['userPaths', context.userPaths],
			['zones', context.zones],
			['intervalId', intervals.map(({ id }) => id)],
		];
		for (const [key, availables] of fieldsToCheckUrl) {
			if (isQueryNotAvailable(key, availables, searchParams)) {
				update[key] = null;
			}
		}
		setSearchParams(update);
	}, [searchParams, context, intervals, setSearchParams]);

	const refetch = useCallback(async () => {
		if (selectedGrid === 1 && counterDatagrid.current) {
			await counterDatagrid.current.refetch();
		}
		if (selectedGrid === 0 && valuesDatagrid.current) {
			await valuesDatagrid.current.refetch();
		}
	}, [selectedGrid]);

	useEffect(() => {
		if (isRunning) {
			void refetch();
		}
	}, [subTab, selectedGrid, isRunning, refetch]);

	const onChange = (_event: React.SyntheticEvent, value: ValuesCategory) => {
		void navigate({
			pathname: ResultRoutes.values(resultId, value),
			search: fullUrlParams.toString(),
		});
	};

	return (
		<Stack justifyContent='space-evenly' sx={{ height: '100%' }}>
			<Stack
				direction='row'
				justifyContent='space-between'
				sx={{
					padding: 2,
				}}
				flexGrow={0}
			>
				<ToggleButtonGroup color='primary' onChange={onChange} exclusive size='small'>
					{getValuesCategories(loadInjectionProvider).map((tab) => (
						<ToggleButton
							key={tab}
							value={tab}
							data-testid={`${tab}-tab`}
							sx={(theme) => ({ padding: `${theme.spacing(1)} ${theme.spacing(2)}` })}
							selected={subTab === tab}
						>
							{t(`tabs.${tab}`)}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
				{isRunning && (
					<Button
						variant='outlined'
						color='primary'
						data-testid='refresh-values-or-counter'
						disabled={isDataFetching}
						onClick={() => refetch()}
						startIcon={isDataFetching ? <CircularProgress size={20} color='inherit' /> : <CachedOutlined />}
						sx={{
							marginLeft: '20px',
							marginRight: 'auto',
							textTransform: 'uppercase',
						}}
					>
						{t('commons:refresh')}
					</Button>
				)}
				<Grid
					container
					display='flex'
					justifySelf='flex-end'
					justifyContent='flex-end'
					flexDirection='row'
					flexWrap='nowrap'
					maxWidth='60%'
				>
					<IntervalFilter intervals={intervals} resultDuration={resultDuration} />
					{selectedGrid === 0 && <ValuesFilters context={context} />}
				</Grid>
			</Stack>
			<ComponentTabPanel sx={{ minHeight: 0 }} flexGrow={1} value={selectedGrid} index={0} data-testid='values-panel'>
				<ValuesDataGrid
					ref={valuesDatagrid}
					setDataFetching={setIsDataFetching}
					category={subTab as Category}
					resultId={resultId}
				/>
			</ComponentTabPanel>
			<ComponentTabPanel value={selectedGrid} index={1} data-testid='counters-panel' sx={{ minHeight: 0 }} flexGrow={1}>
				<CountersDataGrid ref={counterDatagrid} setDataFetching={setIsDataFetching} resultId={resultId} />
			</ComponentTabPanel>
		</Stack>
	);
};

export const ResultTabValues = memo(ResultTabValuesInternal);

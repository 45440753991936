import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { generatePointsForPopulation } from './custom-scenarios-chart-helper';
import { Chart } from '../../../../../common/chart';
import { TooltipContext } from '../../../../../common/chart/custom-chart-tooltip';
import { CustomPopulation } from '@neoload/api';

type CustomScenariosChartProps = {
	population: CustomPopulation;
};

const tooltipComponent = (context: TooltipContext, theme: Theme) => (
	<Card sx={{ color: 'text.secondary', background: 'background.paper' }} elevation={1}>
		<Stack padding={1}>
			<div>
				{dayjs.duration(context.currentPoint.x ?? 0).format('HH:mm:ss')}
				<br />
				<span style={{ color: theme.palette.primary.main }}>● </span>
				Virtual users: <b>{context.currentPoint.y}</b>
			</div>
		</Stack>
	</Card>
);

const CustomScenariosChart = ({ population }: CustomScenariosChartProps) => {
	const theme = useTheme();

	return (
		<Chart
			tooltipComponent={(context) => tooltipComponent(context, theme)}
			series={[
				{
					title: population.name,
					type: 'area',
					visible: true,
					color: theme.palette.primary.main,
					isError: false,
					isStep: true,
					points: generatePointsForPopulation(population),
					axis: {
						x: {
							type: 'TIMESERIES',
						},
						y: {
							displayLegend: true,
							unit: 'Virtual users',
						},
					},
				},
			]}
			configuration={{
				legend: {
					enabled: false,
				},
				tooltip: {
					shared: false,
				},
				axis: {
					y: {
						maximumDisplayedCount: 2,
					},
				},
				plotOptions: {
					area: {
						lineColor: theme.palette.primary.main,
						color: {
							linearGradient: {
								x1: 0,
								y1: 0,
								x2: 0,
								y2: 1,
							},
							stops: [
								[0, theme.palette.primary.main],
								[1, theme.palette.background.paper],
							],
						},
					},
				},
			}}
		/>
	);
};

export { CustomScenariosChart };

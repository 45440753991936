import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import PlayCircleOutlined from '@mui/icons-material/PlayCircleOutlined';
import { Dispatch, SetStateAction } from 'react';
import Tooltip from '@tricentis/aura/components/Tooltip.js';

export type RunButtonProps = {
	setOpenTestExecutionModal: Dispatch<SetStateAction<boolean>>;
	disabled: boolean;
};

const RunButton = ({ setOpenTestExecutionModal, disabled }: RunButtonProps) => {
	const { t } = useTranslation(['test']);

	return (
		<Tooltip arrow title={disabled ? t('grid.run.notConfigured') : ''}>
			<Button
				variant='contained'
				size='small'
				onClick={() => setOpenTestExecutionModal(true)}
				disabled={disabled}
				startIcon={<PlayCircleOutlined />}
				data-trackingid='run-test-header-button'
			>
				{t('grid.actions.runTest')}
			</Button>
		</Tooltip>
	);
};

export { RunButton };

import { useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { TileEditionFormFields } from './tile-edition-form';

export const TileDetailsAccordion = () => {
	const { t } = useTranslation(['dashboard']);
	const {
		register,
		formState: { errors },
	} = useFormContext<TileEditionFormFields>();
	return (
		<Accordion disableGutters defaultExpanded={true} elevation={0}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>{t('tile.edition.sections.details')}</AccordionSummary>
			<AccordionDetails>
				<TextField
					{...register('title', {
						required: {
							value: true,
							message: t('tile.edition.fields.title.error.required'),
						},
						maxLength: {
							value: 80,
							message: t('tile.edition.fields.title.error.maxLength'),
						},
					})}
					fullWidth={true}
					required={true}
					label={t('tile.edition.fields.title.name')}
					error={!!errors.title}
					helperText={errors?.title?.message ?? undefined}
				/>
			</AccordionDetails>
		</Accordion>
	);
};

import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { InputFile } from '../input-file';

type ButtonSelectFileProps = {
	isInformationLoading: boolean;
};

const ButtonSelectFile = ({ isInformationLoading }: ButtonSelectFileProps) => {
	const { t } = useTranslation(['test']);

	return (
		<Button component='label' size='small' role={undefined} variant='outlined' disabled={isInformationLoading}>
			{t('configuration.project.file.button')}
			<InputFile />
		</Button>
	);
};

export { ButtonSelectFile };

import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useCurrentWorkspace } from '../workspaces/use-current-workspace';
import { useSetCurrentWorkspaceId } from '../workspaces/use-set-current-workspace-id';
import { useGetV4DashboardsByDashboardIdQuery, usePostV4ResultsByResultIdReportMutation } from '@neoload/api';
import { checkObjectId, DashboardGenericParams, ReportGenericParams, validateUuid } from '@neoload/utils';

/**
 * Fetches the dashboard (take its ID from the URL)
 * Switch to its workspace if needed.
 * Create the result report if needed
 * @returns data is the dashboard or the report, the error if any, and a loading flag
 */
const useGetDashboard = () => {
	const { dashboardId = '' } = useParams<DashboardGenericParams>();
	const { resultId = '' } = useParams<ReportGenericParams>();
	const [{ id: currentWorkspaceId }] = useCurrentWorkspace();
	const setCurrentWorkspaceId = useSetCurrentWorkspaceId();

	if (dashboardId) {
		checkObjectId(dashboardId);
	}
	if (resultId) {
		validateUuid(resultId);
	}
	const {
		data: dashboardData,
		error: dashboardError,
		isLoading: isDashboardLoading,
	} = useGetV4DashboardsByDashboardIdQuery({ dashboardId }, { skip: !dashboardId });

	const [
		triggerReport,
		{ data: reportData, error: reportError, isLoading: isReportLoading, isUninitialized: isReportUninitialized },
	] = usePostV4ResultsByResultIdReportMutation();
	useEffect(() => {
		if (resultId) {
			triggerReport({ resultId });
		}
	}, [resultId, triggerReport]);

	const data = dashboardId ? dashboardData : reportData;
	const error = dashboardId ? dashboardError : reportError;
	const isLoading = (dashboardId && isDashboardLoading) || (resultId && (isReportLoading || isReportUninitialized));

	useEffect(() => {
		if (data && data.workspaceId !== currentWorkspaceId) {
			setCurrentWorkspaceId(data.workspaceId);
		}
	}, [data, currentWorkspaceId, setCurrentWorkspaceId]);

	return { data, error, isLoading };
};

export { useGetDashboard };

import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';

type LabelsInfoLicenseProps = {
	labels: string[];
};

const LabelsInfoLicense = ({ labels }: LabelsInfoLicenseProps) => {
	const { t } = useTranslation(['license'], { keyPrefix: 'license.infos' });

	return (
		<Stack direction='column' spacing={1}>
			{labels.map((label) => (
				<Typography variant='body2' color='text.secondary' key={label}>
					{t(label)}
				</Typography>
			))}
		</Stack>
	);
};

export { LabelsInfoLicense };

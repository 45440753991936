import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CreateReservationForm, emptyCreateReservationForm } from './modal/common/reservation-helper';
import { ScheduleTestLayoutModal } from './modal/schedule-test-layout-modal';

type ScheduleTestModalProps = {
	close: () => void;
	workspaceId?: string;
	testId?: string;
	reservationId?: string;
	action?: CreateReservationForm['action'];
	startDate?: dayjs.Dayjs;
};
const ScheduleTestModal = ({
	close,
	workspaceId,
	testId,
	reservationId,
	startDate,
	action = 'NEW',
}: ScheduleTestModalProps) => {
	const { t } = useTranslation(['reservation']);

	const defaultValues: CreateReservationForm = {
		...emptyCreateReservationForm,
		workspaceId,
		testId,
		initialScheduleDataNotChanged: workspaceId !== undefined && testId !== undefined,
		startDate: startDate ? startDate.second(0).toISOString() : dayjs().add(15, 'minutes').second(0).toISOString(),
		reservationId,
		action,
	};

	return (
		<Dialog open={true} maxWidth={false}>
			<DialogTitle>{action === 'NEW' ? t('schedule.title') : t('schedule.titleEdit')}</DialogTitle>
			<ScheduleTestLayoutModal close={close} defaultValue={defaultValues} />
		</Dialog>
	);
};
export { ScheduleTestModal };

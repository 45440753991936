import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { getGridDateOperators, getGridSingleSelectOperators } from '@mui/x-data-grid-pro';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid/models';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import Stack from '@mui/material/Stack';
import { ExternalUrlPlainLink } from './external-url/external-url-plain-link';
import { ActionsCellMenu } from '../../common/data-grid/actions/actions-cell-menu';
import { DatagridAction } from '../../common/data-grid/actions/data-grid-actions';
import { getDateValue } from '../../common/data-grid/getters/get-date-value';
import { RunChip } from '../../data-grid/run-chip';
import { alphabeticSort } from '../../common/data-grid/sort/sort-utils';
import { useRenderFunctions } from '@neoload/hooks';
import { ResultRoutes, TestRoutes, timeUtils } from '@neoload/utils';
import { TestResult, TestResultSearchCriteria } from '@neoload/api';

function ResultsDataGridColumns(
	actions: DatagridAction[],
	isGuest: boolean,
	searchCriterias?: TestResultSearchCriteria,
): GridColDef<TestResult>[] {
	const { t, i18n } = useTranslation(['result']);
	const theme = useTheme();
	const requestPerSecondFormat = new Intl.NumberFormat(i18n.language, {
		compactDisplay: 'short',
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	});
	const transactionTimeFormat = new Intl.NumberFormat(i18n.language, {
		compactDisplay: 'short',
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
		style: 'unit',
		unit: 'second',
		unitDisplay: 'narrow',
	});

	const isAnyOfOperatorLabel = t('filters.isAnyOf');
	const passedValueLabel = t('resultStatus.passed');
	const failedValueLabel = t('resultStatus.failed');
	const runningValueLabel = t('filters.runningFilter');

	const isAnyOfOperator = getGridSingleSelectOperators()
		.filter((filter) => filter.value === 'isAnyOf')
		.map((filter) => ({
			...filter,
			label: isAnyOfOperatorLabel,
		}));

	const { renderHeader } = useRenderFunctions('result');

	const getRunningPercent = (benchDefinition: TestResult) =>
		timeUtils.getRemainingTimePercent(benchDefinition.startDate, benchDefinition.duration);

	const guestColumns: GridColDef<TestResult>[] = [
		{
			field: 'name',
			minWidth: 150,
			flex: 0.5,
			resizable: true,
			filterable: false,
			headerName: t('columns.name.title'),
			renderHeader,
			renderCell: (props: GridRenderCellParams) => (
				<Link
					underline='hover'
					component={RouterLink}
					to={ResultRoutes.result(props.row.id)}
					sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
				>
					{props.value}
				</Link>
			),
		},
		{
			field: 'externalUrl',
			headerName: t('header.externalUrlToolTip'),
			renderHeader: () => (
				<Tooltip arrow title={t('header.externalUrlToolTip')}>
					<LinkOutlinedIcon style={{ color: theme.palette.action.active, fontSize: 'large' }} />
				</Tooltip>
			),
			align: 'center',
			headerAlign: 'center',
			width: 50,
			sortable: false,
			resizable: false,
			filterable: false,
			renderCell: (props) =>
				props.row.externalUrl && (
					<ExternalUrlPlainLink title={props.row.externalUrl.title} href={props.row.externalUrl.url} />
				),
		},
		{
			field: 'locked',
			width: 50,
			headerName: t('header.lockTooltip'),
			renderHeader: () => (
				<Tooltip arrow title={t('header.lockTooltip')}>
					<LockOutlinedIcon style={{ color: theme.palette.action.active, fontSize: 'large' }} />
				</Tooltip>
			),
			align: 'center',
			headerAlign: 'center',
			sortable: false,
			resizable: false,
			filterable: false,
			renderCell: (props) =>
				props.row.locked && (
					<Stack direction='row' justifyContent='center' alignItems='center' sx={{ width: '100%', height: '100%' }}>
						<Tooltip arrow title={t('header.locked')}>
							<LockOutlinedIcon style={{ color: theme.palette.action.active, fontSize: 'large' }} />
						</Tooltip>
					</Stack>
				),
		},
		{
			field: 'testName',
			valueGetter: (_, row) => row.test?.name,
			minWidth: 150,
			type: 'singleSelect',
			flex: 0.3,
			resizable: true,
			sortable: false,
			valueOptions: alphabeticSort(searchCriterias?.tests.map((tests) => tests.name) ?? []),
			filterOperators: isAnyOfOperator,
			headerName: t('columns.testName.title'),
			renderHeader,
			renderCell: (props) =>
				props.row.test ? (
					<Link
						component={RouterLink}
						to={TestRoutes.test(props.row.test.id)}
						sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
						underline='hover'
					>
						{props.value}
					</Link>
				) : null,
		},
		{
			field: 'project',
			minWidth: 150,
			type: 'singleSelect',
			flex: 0.2,
			resizable: true,
			renderHeader,
			valueOptions: alphabeticSort(searchCriterias?.projects.map((project) => project.name) ?? []),
			filterOperators: isAnyOfOperator,
			headerName: t('columns.project.title'),
		},
		{
			field: 'startDate',
			minWidth: 190,
			resizable: true,
			filterable: true,
			renderHeader,
			valueFormatter: getDateValue,
			filterOperators: getGridDateOperators().filter(
				(operator) => operator.value === 'before' || operator.value === 'after',
			),
			headerName: t('columns.startDate.title'),
		},
		{
			field: 'vusCount',
			resizable: true,
			width: 50,
			renderHeader,
			filterable: false,
			type: 'number',
			sortable: false,
			headerName: t('columns.vusCount.title'),
		},
		{
			field: 'duration',
			minWidth: 75,
			resizable: true,
			headerName: t('columns.duration.title'),
			renderHeader,
			renderCell: ({ value }) => timeUtils.durationSeconds(value),
			filterable: false,
		},
		{
			field: 'qualityStatus',
			headerName: t('columns.qualityStatus.title'),
			minWidth: 120,
			maxWidth: 170,
			flex: 1,
			type: 'singleSelect',
			resizable: false,
			valueOptions: [
				{ value: 'FAILED', label: failedValueLabel },
				{ value: 'PASSED', label: passedValueLabel },
				{ value: 'RUNNING', label: runningValueLabel },
			],
			filterOperators: isAnyOfOperator,
			renderHeader,
			renderCell: ({ row }) => (
				<RunChip resultStatusData={row} percentage={getRunningPercent(row)} withLabel size='small' />
			),
		},
		{
			field: 'errors',
			minWidth: 50,
			resizable: true,
			filterable: false,
			align: 'right',
			headerAlign: 'right',
			headerName: t('columns.errors.title'),
			renderHeader,
			renderCell: ({ value }) => (
				<span
					style={{
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						...(value > 0 ? { color: theme.palette.error.main } : {}),
					}}
				>
					{value}
				</span>
			),
		},
		{
			field: 'requestPerSecond',
			minWidth: 50,
			resizable: true,
			filterable: false,
			align: 'right',
			headerAlign: 'right',
			headerName: t('columns.requestPerSecond.title'),
			renderHeader,
			renderCell: ({ value }) => (value ? requestPerSecondFormat.format(value) : null),
		},
		{
			field: 'transactionTime',
			minWidth: 50,
			resizable: true,
			filterable: false,
			align: 'right',
			headerAlign: 'right',
			headerName: t('columns.transactionTime.title'),
			renderHeader,
			renderCell: ({ value }) => (value ? transactionTimeFormat.format(value / 1000) : ''),
		},
		{
			field: 'description',
			minWidth: 50,
			resizable: true,
			filterable: false,
			sortable: false,
			headerName: t('columns.description.title'),
			renderHeader,
		},
	];
	return isGuest
		? guestColumns
		: [
				...guestColumns,
				{
					field: 'actions',
					renderCell: (params: GridRenderCellParams) => <ActionsCellMenu rowData={params.row} actions={actions} />,
					resizable: false,
					disableReorder: true,
					type: 'actions',
					align: 'center',
					minWidth: 50,
					maxWidth: 50,
				},
			];
}

export { ResultsDataGridColumns };

import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import { ConfigurationError } from './../../configuration-helpers';
import {
	CustomPopulation,
	Duration,
	ExecutionPolicies,
	InvalidPropertyValueErrorDescription,
	isInvalidPropertyErrorMatching,
	RampUpPopulation,
	RampUpRange,
	ScenarioAsCode,
	ScenarioAsCodePopulation,
	VuDistributions,
} from '@neoload/api';

dayjs.extend(dayjsDuration);

const JAVA_MAX_INTEGER = 2_147_483_647;

export const findSingleHumanReadableTime = (durationString: string): { value: number; unit: RampUpRange } => {
	const regex = /(\d+h)?(\d+m)?(\d+s)?/i;
	const matches = regex.exec(durationString);

	if (!matches) {
		throw new Error('Invalid duration format');
	}

	const hours = matches[1] ? Number.parseInt(matches[1]) * 3600 : 0;
	const minutes = matches[2] ? Number.parseInt(matches[2]) * 60 : 0;
	const seconds = matches[3] ? Number.parseInt(matches[3]) : 0;

	const totalSeconds = hours + minutes + seconds;

	if (totalSeconds >= 3600 && totalSeconds % 3600 === 0) {
		return { value: totalSeconds / 3600, unit: RampUpRange.HOURS };
	}
	if (totalSeconds >= 60 && totalSeconds % 60 === 0) {
		return { value: totalSeconds / 60, unit: RampUpRange.MINUTES };
	}
	return { value: totalSeconds, unit: RampUpRange.SECONDS };
};

export const convertAsCodeHumanReadableTimeSpecificationToISO8601 = (durationString: string) => {
	const regex = /(\d+h)?(\d+m)?(\d+s)?/i;
	const matches = regex.exec(durationString);

	if (!matches) {
		throw new Error('Invalid duration format');
	}

	let hours = Number.parseInt(matches[1]);
	let days = 0;
	if (hours > 23) {
		days = Math.floor(hours / 24);
		hours = hours % 24;
	}

	return dayjs
		.duration({
			seconds: Number.parseInt(matches[3]),
			minutes: Number.parseInt(matches[2]),
			hours: hours,
			days: days,
		})
		.toISOString();
};

export const convertISO8601DurationToAsCodeHumanReadableTimeSpecification = (iso8601Duration: string) => {
	const duration = dayjs.duration(iso8601Duration);
	if (duration.days()) {
		return `${duration.asHours().toFixed(0)}h${duration.minutes()}m${duration.seconds()}s`.trim();
	}
	if (duration.hours()) {
		return duration.format('H[h]m[m]s[s]');
	}
	if (duration.minutes()) {
		return duration.format('m[m]s[s]');
	}

	return duration.format('s[s]');
};

export const formatAsCodeHumanReadableTimeSpecification = (duration: string) => {
	const regex = /(\d+h)?(\d+m)?(\d+s)?/i;
	const matches = regex.exec(duration);

	if (!matches) {
		throw new Error('Invalid duration format');
	}

	const hours = matches[1] ? matches[1].toLowerCase() + ' ' : '';
	const minutes = matches[2] ? matches[2].toLowerCase() + ' ' : '';
	const seconds = matches[3] ? matches[3].toLowerCase() : '';

	return `${hours}${minutes}${seconds}`.trim();
};

export const atLeastZero = (value: number) => (Number.isNaN(value) || value < 0 ? 0 : value);
export const atLeastOne = (value: number) => (Number.isNaN(value) || value < 1 ? 1 : value);
export const atMostMaxInteger = (value: number) =>
	Number.isNaN(value) || value > JAVA_MAX_INTEGER ? JAVA_MAX_INTEGER : value;
const parseApiDuration = (duration: string): CustomPopulation['duration'] => {
	if (duration.includes('iteration')) {
		return duration.replace(' iterations', '').replace(' iteration', '');
	}

	return duration;
};

const parseApiRampUpDuration = (duration: string): RampUpPopulation['rampUp']['every'] => {
	if (duration.includes('iteration')) {
		return {
			amount: Number(duration.replace(' iterations', '').replace(' iteration', '')),
			unit: RampUpRange.ITERATIONS,
		};
	}
	const { value, unit } = findSingleHumanReadableTime(duration);

	return { amount: value, unit };
};

const parseStepRampUpApi = (stepRampUp: string | undefined): CustomPopulation['stepRampUp'] => {
	if (!stepRampUp) {
		return '0s';
	}

	return stepRampUp;
};

const parseExecutionPolicy = (duration: Duration): ExecutionPolicies => {
	if (duration.includes('iteration')) {
		return ExecutionPolicies.ITERATION;
	}

	return ExecutionPolicies.DURATION;
};

export type CustomScenarioApiToFormReturn = {
	population: CustomPopulation[];
	unsupportedPopulation: ScenarioAsCodePopulation[];
};

export const customScenarioApiToForm = (populations: ScenarioAsCode['populations']): CustomScenarioApiToFormReturn => {
	const formPopulations: CustomPopulation[] = [];
	const formUnsupportedPopulation: ScenarioAsCodePopulation[] = [];
	if (populations === undefined) {
		return { population: formPopulations, unsupportedPopulation: formUnsupportedPopulation };
	}

	for (const population of populations) {
		if ('constant_load' in population) {
			if (population.constant_load.stop_after !== undefined || population.constant_load.start_after !== undefined) {
				formUnsupportedPopulation.push(population);
			} else {
				formPopulations.push({
					name: population.name,
					vus: population.constant_load.users,
					stepRampUp: parseStepRampUpApi(population.constant_load.rampup),
					executionPolicy: parseExecutionPolicy(population.constant_load.duration),
					duration: parseApiDuration(population.constant_load.duration),
					vuDistribution: VuDistributions.CONSTANT,
				});
			}
		}
		if ('rampup_load' in population) {
			if (population.rampup_load.stop_after !== undefined || population.rampup_load.start_after !== undefined) {
				formUnsupportedPopulation.push(population);
			} else {
				formPopulations.push({
					name: population.name,
					vus: population.rampup_load.max_users ?? 0,
					stepRampUp: parseStepRampUpApi(population.rampup_load.increment_rampup),
					executionPolicy: parseExecutionPolicy(population.rampup_load.duration),
					duration: parseApiDuration(population.rampup_load.duration),
					vuDistribution: VuDistributions.RAMPUP,
					rampUp: {
						users: population.rampup_load.increment_users,
						every: parseApiRampUpDuration(population.rampup_load.increment_every),
					},
				});
			}
		}
		if ('peaks_load' in population || 'custom_load' in population) {
			formUnsupportedPopulation.push(population);
		}
	}

	return { population: formPopulations, unsupportedPopulation: formUnsupportedPopulation };
};

const getApiDuration = (population: CustomPopulation): string => {
	if (population.executionPolicy === ExecutionPolicies.DURATION) {
		return population.duration;
	}

	if (population.duration === '1') {
		return '1 iteration';
	}

	return `${population.duration} iterations`;
};

const getApiRampUpDuration = (population: RampUpPopulation): string => {
	if (population.executionPolicy === ExecutionPolicies.DURATION) {
		return population.rampUp.every.amount + population.rampUp.every.unit;
	}

	if (population.rampUp.every.amount === 1) {
		return '1 iteration';
	}

	return `${population.rampUp.every.amount} iterations`;
};

const getStepRampUpApi = (population: CustomPopulation): string | undefined => {
	if (population.stepRampUp !== '0s' && population.stepRampUp !== '0m' && population.stepRampUp !== '0h') {
		return population.stepRampUp;
	}

	return undefined;
};

export const customScenarioFormToApi = (populations: CustomPopulation[]): ScenarioAsCodePopulation[] => {
	const apiPopulations: ScenarioAsCodePopulation[] = [];

	for (const population of populations) {
		if (population.vuDistribution === VuDistributions.CONSTANT) {
			apiPopulations.push({
				name: population.name,
				constant_load: {
					users: population.vus,
					duration: getApiDuration(population),
					...(getStepRampUpApi(population) && { rampup: getStepRampUpApi(population) }),
				},
			});
		}
		if (population.vuDistribution === VuDistributions.RAMPUP && population.rampUp) {
			apiPopulations.push({
				name: population.name,
				rampup_load: {
					min_users: population.rampUp.users,
					max_users: population.vus,
					duration: getApiDuration(population),
					...(getStepRampUpApi(population) && { increment_rampup: getStepRampUpApi(population) }),
					increment_every: getApiRampUpDuration(population),
					increment_users: population.rampUp.users,
				},
			});
		}
	}

	return apiPopulations;
};

export const translateApiError = (apiError: InvalidPropertyValueErrorDescription): ConfigurationError => {
	if (isInvalidPropertyErrorMatching(apiError, 'format', 'populations.name', 'unique')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.customScenario.populationsNameUnique',
		};
	}
	if (isInvalidPropertyErrorMatching(apiError, 'format', 'populations.name', 'notFound')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.customScenario.populationsNameNotFound',
		};
	}
	if (isInvalidPropertyErrorMatching(apiError, 'format', 'populations.custom_load.duration', 'positiveNotZero')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.customScenario.durationPositive',
		};
	}
	if (isInvalidPropertyErrorMatching(apiError, 'format', 'populations.custom_load.steps.when', 'notUnique')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.customScenario.stepRampUpUnique',
		};
	}
	if (isInvalidPropertyErrorMatching(apiError, 'format', 'populations.custom_load.steps.when', 'notOrdered')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.customScenario.stepRampUpOrdered',
		};
	}
	if (isInvalidPropertyErrorMatching(apiError, 'format', 'property', 'REQUEST_BODY')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.customScenario.invalidJson',
		};
	}
	if (isInvalidPropertyErrorMatching(apiError, 'incompatible', 'project', 'uploadTooOld')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.customScenario.uploadTooOld',
		};
	}
	if (isInvalidPropertyErrorMatching(apiError, 'oneOf', 'property', 'populations/0')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'configuration.errors.customScenario.populationsError',
		};
	}

	return {
		sentence: apiError.message,
		sentenceKey: '',
	};
};

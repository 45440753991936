import { useCallback } from 'react';
import { useSearchParams } from 'react-router';

export type UpdateUrlProps<T extends readonly string[]> = {
	[key in T[number]]?: string | null;
};

/**
 * Hook that allows you to pick and edit a subsection of the URL search params. It is to replace react hook useSearchParams.
 * difference between this one and react hook useSearchParams are:
 * - the use of plain js object instead of URLSearchParams
 * - useUrlSearchParams accept keys arguments that limit the read and write to those keys
 * @param keys {string[]} : the url param keys your component wants to interact with
 */
const useUrlSearchParams = <T extends readonly string[]>(
	...keys: Readonly<T>
): [
	{
		[K in T[number]]?: string;
	},
	/**
	 * Update part of the url search params. It can add, update or remove property.
	 * @param updateProps: a function allowing to update the search parameters. You can: <ul>
	 * * set **value** to a key you want to set the value as parameter. This will replace existing value if it exists.
	 * * set **null** to a key you want to delete the key parameter.
	 * * Either way, you won't be able to update a key that has not been given as parameter of `useUrlSearchParams`.
	 * </ul>
	 */
	(update: {
		[key in T[number]]?: string | null;
	}) => void,
	fullUrlParams: URLSearchParams
] => {
	const [searchParams, setSearchParams] = useSearchParams();
	// actual state filtered by keys
	const filteredState: {
		[K in T[number]]?: string;
	} = Object.fromEntries(keys.map((key) => (searchParams.has(key) ? [key, searchParams.get(key)] : [])));

	const updateUrl = useCallback(
		(updateProps: UpdateUrlProps<T>) => {
			const entriesToChange = Object.entries(updateProps).filter(
				([key, value]) => keys.includes(key) && filteredState[key as T[number]] !== value
			);
			if (entriesToChange.length > 0) {
				setSearchParams((current) => {
					for (const [key, value] of entriesToChange) {
						if (value === null) {
							current.delete(key);
						} else if (value) {
							current.set(key, value as string);
						}
					}
					return current;
				});
			}
		},
		[keys, setSearchParams, filteredState]
	);
	return [filteredState, updateUrl, searchParams];
};

export { useUrlSearchParams };

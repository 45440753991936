import { GridCallbackDetails, GridCellParams } from '@mui/x-data-grid-pro';
import { PropsWithChildren } from 'react';
import { GridRowId } from '@mui/x-data-grid';
import { DatagridDrawer } from './drawer/datagrid-drawer';
import { Datagrid, DatagridProps } from './datagrid';

type DatagridWithDrawerProps = DatagridProps & {
	drawer: DrawerProps;
};
type DrawerProps = {
	/**
	 * Triggered when clicking on a row in the datagrid
	 * @param params the cell on which the user clicked
	 */
	onOpen: (params: GridCellParams) => void;
	/**
	 * Triggered when closing the drawer (click on the cross icon)
	 */
	onClose: () => void;
	/**
	 * The row Identifier that will be selected (emphasis) in the datagrid.\
	 * `undefined` will close the drawer and select no row in the datagrid.
	 */
	selectedRowId?: GridRowId;
	/**
	 * Drawer heading typography
	 */
	title: string;
	/**
	 * Drawer minimum width, default to 250
	 */
	minWidth?: number;
};

const DatagridWithDrawer = ({
	drawer: { onOpen, onClose, selectedRowId, title, minWidth = 250 },
	children,
	...dataGridProps
}: PropsWithChildren<DatagridWithDrawerProps>) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onCellClick = (params: GridCellParams, event: any, details: GridCallbackDetails): void => {
		if (dataGridProps.onCellClick) {
			dataGridProps.onCellClick(params, event, details);
		}
		onOpen(params);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
			<Datagrid {...dataGridProps} rowSelectionModel={selectedRowId ? [selectedRowId] : []} onCellClick={onCellClick} />
			{!!selectedRowId && (
				<DatagridDrawer onClose={onClose} title={title} minWidth={minWidth}>
					{children}
				</DatagridDrawer>
			)}
		</div>
	);
};

export { DatagridWithDrawer };

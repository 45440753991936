import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { CreateReservationForm } from './reservation-helper';
import { useGetMe } from '@neoload/hooks';

type AutocompleteType = { title: string; id: string };

const WorkspaceReservationModal = () => {
	const { t } = useTranslation(['reservation']);
	const { setValue, getValues } = useFormContext<CreateReservationForm>();

	const [{ workspaces = [], isLoading }] = useGetMe();

	const [options, setOptions] = useState<AutocompleteType[]>([]);
	const workspaceId = useWatch<CreateReservationForm>({ name: 'workspaceId' });
	const configuredWorkspaceId = getValues('workspaceName');
	const hasInitialScheduleData = getValues('initialScheduleDataNotChanged');
	const action = getValues('action');

	const setWorkspace = (data: AutocompleteType | null) => {
		setValue('workspaceId', data?.id ?? undefined);
		setValue('workspaceName', data?.title ?? undefined);
		setValue('testId', undefined);
		setValue('initialScheduleDataNotChanged', false);
	};

	useEffect(() => {
		if (isLoading || workspaces.length === 0) {
			return undefined;
		}

		const items = workspaces
			.map((workspace) => ({ title: workspace.name, id: workspace.id }))
			.toSorted((first, second) => first.title.localeCompare(second.title));

		setOptions(items);
		if (!hasInitialScheduleData) {
			setValue('testId', undefined);
		}

		if (items.length === 1) {
			setValue('workspaceId', items[0].id);
			setValue('workspaceName', items[0].title);
		}

		const configItem = items.find((item) => item.id === configuredWorkspaceId);
		if (configItem) {
			setValue('workspaceId', configItem.id);
			setValue('workspaceName', configItem.title);
		}
	}, [isLoading, workspaces, setValue, configuredWorkspaceId, hasInitialScheduleData]);

	const currentWorkspace =
		workspaces
			.filter((workspace) => workspace.id === workspaceId)
			.map((workspace) => ({ title: workspace.name, id: workspace.id }))[0] || null;

	return (
		<Autocomplete
			disabled={action === 'EDIT'}
			value={currentWorkspace}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			getOptionLabel={(option) => option.title}
			onChange={(_, data) => setWorkspace(data)}
			options={options}
			noOptionsText={t('schedule.noWorkspaces')}
			loadingText={t('common:loading')}
			loading={isLoading}
			size='small'
			disableClearable
			renderInput={(params) => (
				<TextField
					{...params}
					label={t('schedule.workspace')}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{isLoading ? <CircularProgress color='inherit' size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
		/>
	);
};

export { WorkspaceReservationModal };

import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';
import { WorkspaceModalContent } from './workspace-modal-content';
import { useCrudBatchMessage } from '@neoload/hooks';
import {
	usePatchV4WorkspacesByWorkspaceIdMutation,
	usePostV4WorkspacesMutation,
	Workspace,
	WorkspacePostRequest,
} from '@neoload/api';

type WorkspaceFormModalProps = {
	opened: boolean;
	closeModal: () => void;
	editedWorkspace?: Pick<Workspace, 'id' | 'name' | 'description'>;
};

export type WorkspaceFormFields = {
	name: string;
	description: string;
};

const toPostRequest = (form: WorkspaceFormFields): WorkspacePostRequest => ({
	enabledForAllUsers: false,
	description: form.description,
	name: form.name,
	quotaLimitEnabled: false,
});

const defaultPostRequest: WorkspacePostRequest = {
	enabledForAllUsers: false,
	description: '',
	name: '',
	quotaLimitEnabled: false,
};

const defaultFormFields: WorkspaceFormFields = {
	description: '',
	name: '',
};

const getDefaultValues = (workspace?: Pick<Workspace, 'name' | 'description'>): WorkspaceFormFields => ({
	...defaultFormFields,
	...(workspace && { name: workspace.name, description: workspace.description }),
});

export const WorkspaceFormModal = ({ opened, closeModal, editedWorkspace }: WorkspaceFormModalProps) => {
	const { t } = useTranslation(['workspace']);
	const [createWorkspace, { isLoading: isCreating }] = usePostV4WorkspacesMutation();
	const [patchWorkspace, { isLoading: isPatching }] = usePatchV4WorkspacesByWorkspaceIdMutation();
	const { create, update } = useCrudBatchMessage('workspace');

	const { formState, handleSubmit, reset, ...methods } = useForm<WorkspaceFormFields>({
		mode: 'onChange',
		defaultValues: getDefaultValues(editedWorkspace),
	});

	useEffect(() => {
		reset(getDefaultValues(editedWorkspace));
	}, [editedWorkspace, reset]);

	const submitForm = async (form: WorkspaceFormFields) => {
		await (editedWorkspace
			? update(
					[form],
					(input) =>
						patchWorkspace({
							workspaceId: editedWorkspace.id,
							workspacePatchInput: input,
						}).unwrap(),
					close,
				)
			: create(
					[form],
					(input) =>
						createWorkspace({
							workspacePostRequest: toPostRequest(input),
						}).unwrap(),
					close,
				));
	};

	const close = () => {
		closeModal();
		reset(defaultPostRequest);
	};

	const title = editedWorkspace ? t('editWorkspace') : t('createWorkspace');

	return (
		<Dialog open={opened} onClose={close}>
			<FormProvider {...methods} formState={formState} handleSubmit={handleSubmit} reset={reset}>
				<form
					aria-label={title}
					onSubmit={handleSubmit(submitForm)}
					onReset={() => {
						close();
					}}
				>
					<DialogTitle>{title}</DialogTitle>
					<DialogContent sx={{ width: '600px' }}>
						<WorkspaceModalContent />
					</DialogContent>
					<DialogActions>
						<Button type='reset' data-trackingid='workspace-form-cancel'>
							{t('common:cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							startIcon={isCreating || isPatching ? <CircularProgress size={24.5} color='inherit' /> : null}
							disabled={!formState.isValid || Object.keys(formState.errors).length > 0 || isCreating}
							data-trackingid='workspace-form-ok'
						>
							{t('common:apply')}
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
};

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Trans, useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconDocumentOutlined from '@tricentis/aura/components/IconDocumentOutlined.js';
import IconDeleteOutlined from '@tricentis/aura/components/IconDeleteOutlined.js';
import IconButton from '@mui/material/IconButton';
import FileSaver from 'file-saver';
import IconUploadOutlined from '@tricentis/aura/components/IconUploadOutlined.js';
import { DragDropZone } from './drag-drop-zone';
import { TextEllipsisTooltip } from '../../../common/text-ellipsis-tooltip';
import { InputLicenseFile } from '../input-license-file';
import { useSetSnackbars } from '@neoload/hooks';
import { formatBytes } from '@neoload/utils';
import { usePostV4LicenseActivationRequestsOverrideMutation } from '@neoload/api';

type ActivationDialogProps = {
	onInstall: (file: Blob) => void;
};

const ActivationDialog = ({ onInstall }: ActivationDialogProps) => {
	const { t } = useTranslation(['license'], { keyPrefix: 'license.activation' });
	const { t: tExternalLinks } = useTranslation(['externalLinks']);
	const [generateRequest] = usePostV4LicenseActivationRequestsOverrideMutation();
	const [isOpen, setIsOpen] = useState(false);
	const { showInfo, showError } = useSetSnackbars();
	const [uploadedLicense, setUploadedLicense] = useState<File | undefined>(undefined);
	const inputFile = useRef<HTMLInputElement>(null);

	const onCancel = () => {
		setIsOpen(false);
	};

	const onFinish = () => {
		onInstall(uploadedLicense as Blob);
		setIsOpen(false);
	};

	const handleGenerateRequest = () => {
		generateRequest()
			.then(({ data }) => {
				if (!data) {
					throw new Error('No Response Data while generating activation request');
				}

				FileSaver.saveAs(data.fileContent, data?.fileName ?? 'activation-request.req');
				showInfo({ text: t('generatedMessage') });
			})
			.catch(() => showError({ text: t('errorMessage') }));
	};

	const onInstallLicense = () => {
		if (inputFile.current) {
			inputFile.current.click();
		}
	};

	const handleDrop = (file: File) => {
		setUploadedLicense(file);
	};

	return (
		<>
			<Button color='primary' variant='contained' size='small' onClick={() => setIsOpen(true)}>
				{t('activateButton')}
			</Button>
			<Dialog open={isOpen} onClose={onCancel} fullWidth={true}>
				<DialogTitle>
					<Typography variant='h1'>{t('title')}</Typography>
				</DialogTitle>
				<DialogContent>
					<Stack direction='column' spacing={2}>
						<Typography variant='bodyRegular'>
							<Trans i18nKey='step1' t={t}>
								1. Your activation request file <strong>"license-activation.req"</strong> will be generated.
							</Trans>
						</Typography>
						<Box display='flex' justifyContent='center'>
							<Button variant='outlined' onClick={handleGenerateRequest} size='small'>
								{t('generateButton')}
							</Button>
						</Box>
						<Typography variant='bodyRegular'>
							<Trans i18nKey='step2' t={t}>
								2. Continue in
								{}
								<a href={tExternalLinks('activationLicense')} target='_blank' rel='noreferrer'>
									license management
								</a>
								{}
								to activate the license.
							</Trans>
						</Typography>
						<Typography variant='bodyRegular'>{t('step3', {})}</Typography>
						{uploadedLicense === undefined ? (
							<DragDropZone onDrop={handleDrop}>
								<IconUploadOutlined fontSize='medium' />
								<Typography variant='bodyRegular'>
									<Trans i18nKey='dropFile' t={t}>
										Drop license from the local storage here, or
										{}
										<Button
											onClick={onInstallLicense}
											sx={{
												padding: 0,
												// eslint-disable-next-line @typescript-eslint/naming-convention
												'&:hover': {
													backgroundColor: 'transparent',
												},
											}}
										>
											select a file
										</Button>
										.
									</Trans>
								</Typography>
								<InputLicenseFile onChange={(event) => setUploadedLicense(event.target.files?.[0])} ref={inputFile} />
							</DragDropZone>
						) : (
							<Box
								display='flex'
								flexDirection='row'
								alignItems='center'
								border='1px solid'
								borderColor='text.disabled'
								borderRadius={2}
								padding={2}
								gap={1}
							>
								<IconDocumentOutlined />
								<Stack sx={{ minWidth: 0 }}>
									<TextEllipsisTooltip title={uploadedLicense?.name} />
									<Typography color='secondary' variant='caption'>
										{formatBytes(uploadedLicense?.size)}
									</Typography>
								</Stack>
								<IconButton onClick={() => setUploadedLicense(undefined)} sx={{ ml: 'auto' }}>
									<IconDeleteOutlined />
								</IconButton>
							</Box>
						)}
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={onCancel} size='small' data-trackingid='license-activation-cancel'>
						{t('cancelButton')}
					</Button>
					<Button
						variant='contained'
						size='small'
						onClick={onFinish}
						disabled={!uploadedLicense}
						data-trackingid='license-activation-ok'
					>
						{t('confirmButton')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export { ActivationDialog };

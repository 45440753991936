import IconButton from '@mui/material/IconButton';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { useConfirmModal, useDeleteTests, useSetSnackbars } from '@neoload/hooks';
import { PatchTestInput } from '@neoload/api';
import { CommonRoutes } from '@neoload/utils';

type TestHeaderMenu = {
	id: string;
	patchTest: (patchTestInput: PatchTestInput) => void;
	description?: string;
};
const TestHeaderMenu = ({ id, patchTest, description = '' }: TestHeaderMenu) => {
	const theme = useTheme();
	const { t } = useTranslation(['test']);
	const { showInfo } = useSetSnackbars();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isMenuOpen = !!anchorEl;
	const { openModal } = useConfirmModal();
	const deleteTests = useDeleteTests(CommonRoutes.tests.base);

	const onMoreMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleMoreMenuClose = () => {
		setAnchorEl(null);
	};

	const onDeleteTestClick = () => {
		handleMoreMenuClose();
		deleteTests([{ id }]);
	};
	const onAddEditDescriptionClick = () => {
		handleMoreMenuClose();
		openModal({
			title: description
				? t('grid.actions.addEditDescription.dialogTitle.edit')
				: t('grid.actions.addEditDescription.dialogTitle.add'),
			content: [
				{
					label: t('grid.actions.addEditDescription.label'),
					value: description,
					max: 500,
					multiline: true,
					rows: 4,
					name: 'description',
				},
			],
			defaultValue: { description: description },
			confirm: { text: t('grid.actions.addEditDescription.button.save'), color: 'primary' },
			width: 500,
			handleConfirm: async ({ description }) =>
				new Promise<void>((resolve) => {
					submitDescription(description as string);
					resolve();
				}),
		});
	};

	const submitDescription = (updatedDescription: string) => {
		if (updatedDescription.trim() === '') {
			patchTest({ description: '' });
			showInfo({ text: t('messages.deleteDescriptionSuccess') });
		} else {
			patchTest({ description: updatedDescription });
			description
				? showInfo({ text: t('messages.updateDescriptionSuccess') })
				: showInfo({ text: t('messages.addDescriptionSuccess') });
		}
	};

	return (
		<>
			<IconButton onClick={onMoreMenuButtonClick} aria-label={t('configuration.aria.moreActions')} size='small'>
				<MoreVertOutlined />
			</IconButton>
			<Menu data-testid='test-header-menu' open={isMenuOpen} anchorEl={anchorEl} onClose={handleMoreMenuClose}>
				<MenuItem onClick={onAddEditDescriptionClick}>
					<ListItemIcon>
						<EditOutlined />
					</ListItemIcon>
					<ListItemText>
						{description
							? t('grid.actions.addEditDescription.menuItemEdit')
							: t('grid.actions.addEditDescription.menuItemAdd')}
					</ListItemText>
				</MenuItem>
				<MenuItem onClick={onDeleteTestClick}>
					<ListItemIcon>
						<DeleteOutlined sx={{ color: theme.palette.error.main }} />
					</ListItemIcon>
					<ListItemText sx={{ color: theme.palette.error.main }}>{t('grid.actions.deleteTest')}</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};

export { TestHeaderMenu };

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { TestExecutionError, TestExecutionErrorTypes, TestExecutionForm } from './types.d';
import {
	ApiError,
	InvalidPropertyValueErrorDescription,
	isFetchBaseQueryError,
	isInvalidPropertyError,
	Reservation,
	Settings,
	Subscription,
	SubscriptionPartialError,
	TestExecution,
	TestExecutionConfiguration,
	TestExecutionInput,
	TestExecutionPartialError,
	WorkspaceSubscription,
	Zone,
} from '@neoload/api';

export const isZoneErrorLg = (errors: TestExecutionError[]) =>
	errors.some((error) => error.type === TestExecutionErrorTypes.MissingLg);
const isZoneErrorMissingController = (errors: TestExecutionError[]) =>
	errors.some((error) => error.type === TestExecutionErrorTypes.MissingController);
export const isZoneErrorIncompatibleVersion = (errors: TestExecutionError[]) =>
	errors.some((error) => error.type === TestExecutionErrorTypes.IncompatibleVersion);
const isZoneErrorZones = (errors: TestExecutionError[]) =>
	errors.some((error) => error.type === TestExecutionErrorTypes.Zones);
export const isZoneError = (errors: TestExecutionError[]) =>
	isZoneErrorMissingController(errors) ||
	isZoneErrorIncompatibleVersion(errors) ||
	isZoneErrorLg(errors) ||
	isZoneErrorZones(errors);
export const hasControllerError = (errorType: TestExecutionError[]) =>
	isZoneErrorMissingController(errorType) || isZoneErrorIncompatibleVersion(errorType);

export const getTestExecutionPartialError = (error: FetchBaseQueryError): TestExecutionPartialError | undefined => {
	const { status, data } = error;
	if (
		(status === 409 || status === 400) &&
		data &&
		typeof data === 'object' &&
		(data as TestExecutionPartialError).category === 'PARTIAL_ERROR'
	) {
		return data as TestExecutionPartialError;
	}
};

export const getPartialErrorSubscription = (error: FetchBaseQueryError | SerializedError): Subscription | undefined => {
	if (isFetchBaseQueryError(error)) {
		const { status, data } = error;
		if (
			(status === 409 || status === 400) &&
			data &&
			typeof data === 'object' &&
			(data as SubscriptionPartialError).category === 'PARTIAL_ERROR'
		) {
			return (data as SubscriptionPartialError).result;
		}
	}
};

const getZoneType = (type: TestExecutionConfiguration['zoneType']): Zone['type'] => {
	if (type === 'NEOLOAD_CLOUD') {
		return 'CLOUD';
	}

	return type;
};
export const defaultTestExecutionFormSuccess = (
	testExecution: TestExecution,
	subscription?: WorkspaceSubscription,
	setting?: Settings,
	availableReservations?: Reservation[],
): TestExecutionForm => ({
	test: {
		id: testExecution.configuration.testId,
		name: testExecution.name,
		description: '',
		projectName: testExecution.configuration.projectName,
		scenarioName: testExecution.configuration.scenarioName,
		duration: testExecution.configuration.testDuration ?? '',
		vus:
			(testExecution.configuration.vuCountByVuType?.WEB ?? 0) + (testExecution.configuration.vuCountByVuType?.SAP ?? 0),
	},
	resources: {
		duration: testExecution.resources.maximumDuration ?? '',
		maxDuration: testExecution.resources.maximumDuration ?? '',
		reservationId: testExecution.resources.reservationId,
		sapVu: {
			needed: testExecution.resources.usedConcurrencyByConcurrencyType?.SAP ?? 0,
			available: subscription?.sapVu?.available ?? 0,
			initial: testExecution.resources.usedConcurrencyByConcurrencyType?.SAP ?? 0,
		},
		webVu: {
			needed: testExecution.resources.usedConcurrencyByConcurrencyType?.WEB ?? 0,
			available: subscription?.webVu?.available ?? 0,
			initial: testExecution.resources.usedConcurrencyByConcurrencyType?.WEB ?? 0,
		},
		minimumVersion: testExecution.configuration.minimumResourcesVersion ?? '',
		version: testExecution.resources.resourcesVersion,
		controllerZoneId: testExecution.resources.controllerZoneId,
		zoneType: getZoneType(testExecution.configuration.zoneType),
		cloudCredits: {
			needed: testExecution.resources.consumedCurrencyByConcurrencyType?.CLOUD_CREDITS ?? 0,
			available: subscription?.cloudCredits?.available ?? 0,
		},
		vuh: {
			needed: testExecution.resources.consumedCurrencyByConcurrencyType?.VUH ?? 0,
			available: subscription?.vuhs?.available ?? 0,
		},
		zones: testExecution.resources.lgCountByZoneId,
		missingDedicatedIpByZone: testExecution.resources.missingDedicatedIpByZone,
	},
	errors: [],
	errorsInFirstCall: false,
	reservation: {
		reservationModeStatus: setting?.reservationModeStatus,
		availableReservations: availableReservations,
	},
	confirmable: true,
	loaded: true,
});

export const defaultTestExecutionForm = (): TestExecutionForm => ({
	test: {
		id: '',
		name: '',
		description: '',
		projectName: '',
		scenarioName: '',
		duration: '',
		vus: 0,
	},
	resources: {
		duration: '',
		maxDuration: '',
		reservationId: '',
		sapVu: {
			needed: 0,
			available: 0,
			initial: 0,
		},
		webVu: {
			needed: 0,
			available: 0,
			initial: 0,
		},
		minimumVersion: '',
		version: '',
		controllerZoneId: '',
		zoneType: 'STATIC',
		cloudCredits: {
			needed: 0,
			available: 0,
		},
		vuh: {
			needed: 0,
			available: 0,
		},
		zones: {},
		missingDedicatedIpByZone: {},
	},
	errors: [],
	errorsInFirstCall: false,
	reservation: {
		reservationModeStatus: 'RESERVATION_MODE_DISABLED',
		availableReservations: [],
	},
	confirmable: false,
	loaded: false,
});

export const defaultTestExecutionFormError = (
	testExecution: TestExecution,
	errors: ApiError[],
	subscription?: WorkspaceSubscription,
	setting?: Settings,
	availableReservations?: Reservation[],
): TestExecutionForm => ({
	...defaultTestExecutionFormSuccess(testExecution, subscription, setting, availableReservations),
	errors: executionModalErrors(errors),
	errorsInFirstCall: true,
	confirmable: false,
});

export const executionModalErrors = (errors: ApiError[]): TestExecutionError[] => {
	const testExecutionErrors: TestExecutionError[] = [];
	for (const error of errors) {
		if (isInvalidPropertyError(error) && error.errors !== undefined) {
			for (const apiError of error.errors) {
				testExecutionErrors.push(translateApiError(apiError));
			}
		}
	}

	return testExecutionErrors;
};

function translateApiError(apiError: InvalidPropertyValueErrorDescription): TestExecutionError {
	if (apiError.validationType === 'infrastructure') {
		if (apiError.messageProperties.required === 'controller') {
			return {
				type: TestExecutionErrorTypes.MissingController,
				sentence: apiError.message,
			};
		}
		if (apiError.messageProperties.invalid === 'controller') {
			return {
				type: TestExecutionErrorTypes.IncompatibleVersion,
				sentence: apiError.message,
			};
		}
		if (apiError.messageProperties.notEnough === 'load-generator') {
			return {
				type: TestExecutionErrorTypes.MissingLg,
				sentence: apiError.message,
			};
		}
		return {
			type: TestExecutionErrorTypes.Zones,
			sentence: apiError.message,
		};
	} else if (apiError.validationType === 'licensing') {
		if (apiError.messageProperties.notEnough === 'cloudCredits') {
			return {
				type: TestExecutionErrorTypes.NotEnoughCloudCredits,
				sentence: apiError.message,
			};
		} else if (apiError.messageProperties.notEnough === 'vuh') {
			return {
				type: TestExecutionErrorTypes.NotEnoughVuh,
				sentence: apiError.message,
			};
		} else {
			return {
				type: TestExecutionErrorTypes.Global,
				sentence: apiError.message,
			};
		}
	} else {
		return {
			type: TestExecutionErrorTypes.Global,
			sentence: apiError.message,
		};
	}
}

/**
 *
 * @param test Must have an id
 * @param resources
 */
export const fieldsToTestExecutionInput = (
	test: TestExecutionForm['test'],
	resources: TestExecutionForm['resources'],
): TestExecutionInput => {
	if (!test?.id) {
		throw new Error('Missing test id');
	}

	return {
		testId: test.id,
		name: test?.name,
		description: test?.description,
		...(resources?.reservationId
			? {
					reservationId: resources?.reservationId,
				}
			: {
					webVu: resources?.webVu?.needed,
					sapVu: resources?.sapVu?.needed,
					duration: resources?.duration,
				}),
	};
};
export const estimateEndDateByTestDuration = (
	duration: string,
	reservationMode: 'RESERVATION_MODE_ENABLED' | 'RESERVATION_MODE_DISABLED' | undefined,
) => {
	const date = new Date();
	if (reservationMode === 'RESERVATION_MODE_ENABLED') {
		date.setMinutes(date.getMinutes() + dayjs.duration(duration).add(20, 'minute').asMinutes());
	} else {
		date.setMinutes(date.getMinutes() + dayjs.duration(duration).add(5, 'minute').asMinutes());
	}
	return date;
};

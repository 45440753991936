import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

export type StatisticsBoxItemProps =
	| {
			name: string;
			value: string;
			color?: string;
			statisticKey: string;
			dataReady: true;
	  }
	| {
			statisticKey: string;
			dataReady: false;
	  };

const StatisticsBoxItem = (props: StatisticsBoxItemProps) => (
	<>
		<Typography
			variant='caption'
			color='text.secondary'
			component='p'
			gutterBottom
			data-testid={`statistics-label-${props.statisticKey}`}
		>
			{props.dataReady ? (
				props.name
			) : (
				<Skeleton sx={{ maxWidth: '80%' }} data-testid={`statistics-labelskeleton-${props.statisticKey}`} />
			)}
		</Typography>
		<Typography
			variant='body1'
			color={'color' in props ? props.color : ''}
			gutterBottom
			data-testid={`statistics-value-${props.statisticKey}`}
		>
			{props.dataReady ? (
				props.value
			) : (
				<Skeleton sx={{ maxWidth: '60%' }} data-testid={`statistics-valueskeleton-${props.statisticKey}`} />
			)}
		</Typography>
	</>
);

export { StatisticsBoxItem };

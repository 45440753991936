import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import FilterAltOutlined from '@mui/icons-material/FilterAltOutlined';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { WebhookFiltersPanel } from './webhook-filters-panel';
import { WebhookFilterChipGroup } from './webhook-filter-chip-group';
import { PostWebhookRequest, WebhookFilters } from '@neoload/api';

const getInitialFormValues = (webhookFilters: WebhookFilters): WebhookFilters => ({
	byDuration: webhookFilters.byDuration ?? {
		value: 'PT0S',
		enabled: false,
		operator: 'IS_LONGER',
	},
	byMaxVus: webhookFilters.byMaxVus ?? {
		value: 0,
		enabled: false,
		operator: 'IS_GREATER',
	},
	byProjectName: webhookFilters.byProjectName ?? {
		value: '',
		enabled: false,
		operator: 'CONTAINS',
	},
	byQualityStatus: webhookFilters.byQualityStatus ?? {
		value: 'PASSED',
		enabled: false,
		operator: 'IS',
	},
	byScenarioName: webhookFilters.byScenarioName ?? {
		value: '',
		enabled: false,
		operator: 'CONTAINS',
	},
	byTestName: webhookFilters.byTestName ?? {
		value: '',
		enabled: false,
		operator: 'CONTAINS',
	},
});

const toEnabledFilters = (filters: WebhookFilters): WebhookFilters => ({
	byDuration: filters.byDuration?.enabled ? filters.byDuration : undefined,
	byMaxVus: filters.byMaxVus?.enabled ? filters.byMaxVus : undefined,
	byProjectName: filters.byProjectName?.enabled ? filters.byProjectName : undefined,
	byQualityStatus: filters.byQualityStatus?.enabled ? filters.byQualityStatus : undefined,
	byScenarioName: filters.byScenarioName?.enabled ? filters.byScenarioName : undefined,
	byTestName: filters.byTestName?.enabled ? filters.byTestName : undefined,
});

export const WebhookFilterSelector = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const { t } = useTranslation('workspace', { keyPrefix: 'tabs.webhooks.filters' });
	const theme = useTheme();
	const { setValue: setWebhookValue, watch } = useFormContext<PostWebhookRequest>();

	const filters = watch('filters');
	const webhookTrigger = watch('trigger');

	const {
		formState,
		handleSubmit,
		reset,
		getValues: getFilterValues,
		setValue: setFilterValue,
		...methods
	} = useForm<WebhookFilters>({
		mode: 'onChange',
		defaultValues: getInitialFormValues(filters),
	});

	const submitForm = () => {
		setWebhookValue('filters', toEnabledFilters(getFilterValues()));
		setAnchorEl(null);
	};

	const cancel = () => {
		setAnchorEl(null);
		reset(filters);
	};

	const openFilterPopover = (event: React.MouseEvent<HTMLElement>) => {
		reset(getInitialFormValues(filters));
		setAnchorEl(event.currentTarget);
	};

	return (
		<Grid sx={{ marginBottom: 1 }}>
			<Typography color={theme.palette.text.secondary}>{t('filters')}</Typography>
			<Stack direction='row' gap={2} alignItems='center'>
				<Button startIcon={<FilterAltOutlined />} sx={{ marginTop: 1, marginBottom: 1 }} onClick={openFilterPopover}>
					{t('selectFilters')}
				</Button>
				<Typography variant='caption' color={theme.palette.text.secondary}>
					{t('filtersHint')}
				</Typography>
			</Stack>
			<WebhookFilterChipGroup />
			<Popover
				open={anchorEl !== null}
				onClose={cancel}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<FormProvider
					{...methods}
					formState={formState}
					handleSubmit={handleSubmit}
					reset={reset}
					setValue={setFilterValue}
					getValues={getFilterValues}
				>
					<form onSubmit={handleSubmit(submitForm)}>
						<DialogContent sx={{ width: '600px' }} data-trackingid='webhook-filters-cancel'>
							<WebhookFiltersPanel webhookTrigger={webhookTrigger} />
						</DialogContent>
						<DialogActions>
							<Button
								variant='contained'
								onClick={submitForm}
								disabled={Object.keys(formState.errors).length > 0}
								data-trackingid='webhook-filters-ok'
							>
								{t('applyFilters')}
							</Button>
						</DialogActions>
					</form>
				</FormProvider>
			</Popover>
		</Grid>
	);
};

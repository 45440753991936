import { generatePath } from 'react-router';

export const workspaceTabs = ['members', 'subscriptions', 'webhooks', 'policies'];
export type WorkspaceTab = (typeof workspaceTabs)[number];

export type WorkspaceGenericParams = {
	workspaceId: string;
	tab?: WorkspaceTab;
};

const patterns = {
	base: '/settings/workspaces',
	byId: '/settings/workspaces/:workspaceId',
	byIdWithTab: '/settings/workspaces/:workspaceId/:tab',
};

const workspaceRoutes = {
	patterns: patterns,
	base: patterns.base,
	workspace: (workspaceId: string) => generatePath(patterns.byId, { workspaceId: workspaceId }),
	members: (workspaceId: string) => generatePath(patterns.byIdWithTab, { workspaceId: workspaceId, tab: 'members' }),
	subscriptions: (workspaceId: string) =>
		generatePath(patterns.byIdWithTab, { workspaceId: workspaceId, tab: 'subscriptions' }),
	webhooks: (workspaceId: string) => generatePath(patterns.byIdWithTab, { workspaceId: workspaceId, tab: 'webhooks' }),
	policies: (workspaceId: string) => generatePath(patterns.byIdWithTab, { workspaceId: workspaceId, tab: 'policies' }),
	generic: (workspaceId: string, tab: WorkspaceTab = 'members') =>
		generatePath(patterns.byIdWithTab, { workspaceId: workspaceId, tab: tab }),
};

export { workspaceRoutes as WorkspaceRoutes };

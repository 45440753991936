import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import { CloudZoneWithAvailability } from './use-with-unavailable-cloud-zones';
import { CloudZoneSwitch } from './cloud-zone-switch';
import { useCommonZonesColumns } from '../use-common-zones-columns';
import { ZoneDatagridActionGetter } from '../common-zones-datagrid';

export const useCloudZonesColumns = (
	actionGetters: ZoneDatagridActionGetter<CloudZoneWithAvailability>[],
): GridColDef<CloudZoneWithAvailability>[] => {
	const { t } = useTranslation(['zone']);
	const providerColumn: GridColDef<CloudZoneWithAvailability> = {
		field: 'cloudProvider',
		flex: 0.5,
		minWidth: 250,
		headerName: t('columns.cloudProvider'),
		type: 'singleSelect',
		valueOptions: [t('cloudZone.providers.aws'), t('cloudZone.providers.gcp')],
	};
	const availableColumn: GridColDef<CloudZoneWithAvailability> = {
		field: 'available',
		minWidth: 100,
		resizable: false,
		headerName: t('columns.status'),
		renderCell: (params) => <CloudZoneSwitch zone={params.row} />,
		type: 'boolean',
	};
	const commonColumns = useCommonZonesColumns<CloudZoneWithAvailability>(actionGetters);
	const reservedIpsCountColumn: GridColDef<CloudZoneWithAvailability> = {
		field: 'dedicatedIpsDetails.reservedIpsCount',
		width: 200,
		headerName: t('columns.reservedIpsCount'),
		renderCell: (params) => {
			if (
				params.row.dedicatedIpsDetails?.availableIpsCount &&
				params.row.dedicatedIpsDetails?.availableIpsCount > 0 &&
				params.row.dedicatedIpsDetails?.status !== 'READY'
			) {
				return (
					<Skeleton
						width={30}
						height={35}
						style={{ float: 'right', marginTop: '5px' }}
						data-testid='dedicated-ips-skeleton'
					/>
				);
			}
			return (
				params.row.dedicatedIpsDetails?.reservedIpsCount ?? (
					<Tooltip arrow title={t('columns.unavailableReservedIpsCount.message')}>
						<span>{t('columns.unavailableReservedIpsCount.value')}</span>
					</Tooltip>
				)
			);
		},
		valueGetter: (_value, row) => row.dedicatedIpsDetails?.reservedIpsCount,
		type: 'number',
	};

	return [
		commonColumns[0],
		providerColumn,
		reservedIpsCountColumn,
		commonColumns[1],
		availableColumn,
		commonColumns[2],
	];
};

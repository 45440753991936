import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import { useTranslation } from 'react-i18next';
import RadioGroup from '@mui/material/RadioGroup';
import { useCallback, useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import {
	filterAndFormatProjects,
	filterAndFormatScenarios,
	filterAndFormatTests,
	FilterType,
	getResultNamePattern,
	getScenariosOfProject,
	UsedFilters,
} from './deletion-policies-utils';
import { DeletionFilterAutocompleteTextField } from './deletion-filter-autocomplete-text-field';
import { PolicyRegexpInfoIcon } from './policy-regexp-info-icon';
import {
	DeletionPolicyFilterByProject,
	DeletionPolicyFilterRead,
	useGetV4ResultsSearchCriteriaQuery,
} from '@neoload/api';
import { useGetMe } from '@neoload/hooks';

type DeletionFilterSectionProps = {
	deletionFilter?: DeletionPolicyFilterRead;
	workspaceId: string;
	onChange: (deletionFilter: DeletionPolicyFilterRead | undefined) => void;
} & UsedFilters;

type ProjectState = Partial<Pick<DeletionPolicyFilterByProject, 'projectName' | 'scenarioName'>>;

const DeletionFilterSection = ({
	deletionFilter,
	workspaceId,
	usedTestIds = [],
	usedProjects = [],
	usedPatterns = [],
	onChange,
}: DeletionFilterSectionProps) => {
	const { t } = useTranslation('workspace', { keyPrefix: 'deletionPolicies.create.filter' });
	const [selectedTest, setSelectedTest] = useState<string | undefined>(
		deletionFilter?.type === 'TEST' ? deletionFilter.testId : undefined,
	);
	const [selectedFilterByProject, setSelectedFilterByProject] = useState<ProjectState>(
		deletionFilter?.type === 'PROJECT' ? deletionFilter : {},
	);
	const [selectedRegexp, setSelectedRegexp] = useState<string | undefined>(
		deletionFilter?.type === 'RESULT_NAME' ? deletionFilter.resultNamePattern : undefined,
	);
	const [type, setType] = useState<FilterType>(deletionFilter?.type ?? 'TEST');
	const [resultNameErrorMessage, setResultNameErrorMessage] = useState<string | undefined>(undefined);

	const [{ workspaces: meWorkspace }] = useGetMe();
	const workspaceName = meWorkspace.find((w) => w.id === workspaceId)?.name;
	const {
		data: searchCriteria,
		isFetching,
		isError,
	} = useGetV4ResultsSearchCriteriaQuery({
		workspaceId: workspaceId,
	});

	const tests = searchCriteria?.tests ?? [];
	const projects = searchCriteria?.projects ?? [];
	const scenarios = getScenariosOfProject(searchCriteria, selectedFilterByProject.projectName);
	const resultNamePatternToUpdate = getResultNamePattern(deletionFilter);

	const getDeletionFilter = useCallback((): DeletionPolicyFilterRead | undefined => {
		switch (type) {
			case 'TEST': {
				if (selectedTest) {
					return {
						type: 'TEST',
						testId: selectedTest,
					};
				}
				break;
			}
			case 'PROJECT': {
				if (selectedFilterByProject.projectName && selectedFilterByProject.scenarioName) {
					return {
						type: 'PROJECT',
						projectName: selectedFilterByProject.projectName,
						scenarioName: selectedFilterByProject.scenarioName,
					};
				}
				break;
			}
			case 'RESULT_NAME': {
				if (resultNameErrorMessage === undefined && selectedRegexp) {
					return {
						type: 'RESULT_NAME',
						resultNamePattern: selectedRegexp,
					};
				}
				break;
			}
		}

		return undefined;
	}, [resultNameErrorMessage, selectedFilterByProject, selectedRegexp, selectedTest, type]);

	useEffect(() => {
		onChange(getDeletionFilter());
	}, [getDeletionFilter, onChange]);

	const validateResultNameTextField = (value: string | undefined) => {
		let errorMessage = undefined;
		if (value === undefined || value.length === 0) {
			errorMessage = t('resultNamePattern.errorMessages.empty');
		} else if (value?.trim().length === 0) {
			errorMessage = t('resultNamePattern.errorMessages.noBlankCharacters');
		} else if (value && usedPatterns.filter((pattern) => pattern !== resultNamePatternToUpdate).includes(value)) {
			errorMessage = t('resultNamePattern.errorMessages.alreadyUsed');
		}

		setResultNameErrorMessage(errorMessage);
	};

	const handleResultNamePatternChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		validateResultNameTextField(value);
		setSelectedRegexp(value);
	};

	const boxStyle = {
		display: 'flex',
		flexDirection: 'column',
		gap: 1,
		marginTop: 2,
	};

	return (
		<Box sx={boxStyle}>
			<FormLabel>{t('label')}</FormLabel>
			<RadioGroup
				defaultValue={deletionFilter?.type ?? 'TEST'}
				row
				sx={{ marginLeft: 2 }}
				onChange={(event) => setType(event.target.value as FilterType)}
			>
				<FormControlLabel value='TEST' control={<Radio />} label={t('test.radioButton')} />
				<FormControlLabel value='PROJECT' control={<Radio />} label={t('project.radioButton')} />
				<FormControlLabel value='RESULT_NAME' control={<Radio />} label={t('resultNamePattern.radioButton')} />
			</RadioGroup>
			{type === 'TEST' && (
				<DeletionFilterAutocompleteTextField
					type='test'
					items={filterAndFormatTests(tests, usedTestIds, deletionFilter)}
					isFetching={isFetching}
					isError={isError}
					workspaceName={workspaceName ?? ''}
					noItemInWorkspace={tests.length === 0}
					onChange={setSelectedTest}
					value={selectedTest}
				/>
			)}
			{type === 'PROJECT' && (
				<>
					<DeletionFilterAutocompleteTextField
						type='project'
						items={filterAndFormatProjects(projects, usedProjects, deletionFilter)}
						isFetching={isFetching}
						isError={isError}
						workspaceName={workspaceName ?? ''}
						noItemInWorkspace={projects.length === 0}
						onChange={(value) => setSelectedFilterByProject({ projectName: value, scenarioName: undefined })}
						value={selectedFilterByProject.projectName}
					/>
					<DeletionFilterAutocompleteTextField
						type='scenario'
						items={filterAndFormatScenarios(
							scenarios,
							selectedFilterByProject.projectName,
							usedProjects,
							deletionFilter,
						)}
						isFetching={isFetching}
						isError={isError}
						workspaceName={workspaceName ?? ''}
						noItemInWorkspace={projects.length === 0}
						onChange={(value) => setSelectedFilterByProject((previous) => ({ ...previous, scenarioName: value }))}
						value={selectedFilterByProject.scenarioName}
					/>
				</>
			)}
			{type === 'RESULT_NAME' && (
				<Stack direction='row' gap={1} alignItems='center'>
					<TextField
						sx={{ flexGrow: 1 }}
						onChange={handleResultNamePatternChange}
						required
						size='small'
						error={resultNameErrorMessage !== undefined}
						helperText={resultNameErrorMessage}
						value={selectedRegexp}
						label={t('resultNamePattern.placeholder')}
					/>
					<PolicyRegexpInfoIcon />
				</Stack>
			)}
		</Box>
	);
};

export { DeletionFilterSection };

import { TFunction } from 'i18next';
import { ConfigurationFormData } from '../types';
import { ScenarioTypes } from '../scenarios/scenario-radio-group';

const getReadableSize = (t: TFunction, size: number, fractionDigits = 0) => {
	const kb = 1000;
	const mb = kb * 1000;
	const gb = mb * 1000;

	if (size >= gb) {
		return `${t('configuration.project.file.sizeFile.gb', { size: (size / gb).toFixed(fractionDigits) })}`;
	}

	if (size >= mb) {
		return `${t('configuration.project.file.sizeFile.mb', { size: (size / mb).toFixed(fractionDigits) })}`;
	}

	return `${t('configuration.project.file.sizeFile.kb', { size: (size / kb).toFixed(fractionDigits) })}`;
};

const allowedFileUploadExtension = new Set(['zip', 'yml', 'yaml']);

const allowedFileUploadExtensionString = [...allowedFileUploadExtension].map((v) => '.' + v).join(', ');

const defaultValuesTest: ConfigurationFormData = {
	configurationDisabled: false,
	testResultMask: 'Run test #1',
	project: {
		name: 'Project Name',
		updatedAt: '2020-01-01T00:00',
		size: 123_456,
		source: 'GIT',
		userModifierName: 'Schmi',
	},
	zones: {
		type: 'STATIC',
		controller: 'default-zone',
		items: [
			{
				name: 'Default zone',
				zoneId: 'default-zone',
				number: 2,
				type: 'STATIC',
			},
		],
		maximumLgCountPerProjectSize: {},
	},
	test: {
		id: '0123-456',
		isConfigured: true,
		name: 'Test Name',
		updatedAt: '2020-01-01T00:00:00.',
	},
	scenario: {
		selected: {
			duration: 'PT5M',
			maximumVu: 78,
			type: ScenarioTypes.FromProject,
			name: 'Scenario 1',
		},
		type: ScenarioTypes.FromProject,
		fromProject: [],
	},
};

export { getReadableSize, allowedFileUploadExtension, allowedFileUploadExtensionString, defaultValuesTest };

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { WebhookFiltersKeys } from '../types';
import { WebhookFilters } from '@neoload/api';

type StringFilterValueInputProps = {
	filterKey: WebhookFiltersKeys;
	maxLength: number;
};

export const NameFilterValueInput = ({ filterKey, maxLength }: StringFilterValueInputProps) => {
	const { t } = useTranslation('workspace', { keyPrefix: 'tabs.webhooks.filters' });
	const {
		register,
		watch,
		formState: { errors },
	} = useFormContext<WebhookFilters>();

	const enabled = watch(`${filterKey}.enabled`);

	return (
		<TextField
			size='small'
			InputLabelProps={{ shrink: true }}
			autoFocus
			label={t('value')}
			required
			error={!!errors[filterKey]?.value}
			helperText={errors[filterKey]?.value?.message}
			InputProps={{ inputProps: { maxLength: maxLength } }}
			{...register(`${filterKey}.value`, {
				required: {
					value: enabled,
					message: t('valueRequired'),
				},
			})}
		/>
	);
};

import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import ZeroState from '@tricentis/aura/components/ZeroState.js';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ReservationOff from '../../../assets/ReservationOff.svg';
import { IllustrationWithBackground } from '../../common/illustration-with-background';
import { useGetMe, useSetSnackbars } from '@neoload/hooks';
import { isApiError, usePatchV4SettingsMutation } from '@neoload/api';

export const ReservationOffZeroState = () => {
	const { t } = useTranslation(['reservation']);
	const title = t('reservationOff.title');
	const [{ isTester, hasAdminPermissions }] = useGetMe();
	const { showError } = useSetSnackbars();

	const [patchSettings] = usePatchV4SettingsMutation();

	const enableReservations = () => {
		patchSettings({
			patchSettingsInput: {
				reservationModeStatus: 'RESERVATION_MODE_ENABLED',
			},
		})
			.unwrap()
			.then(() => {
				// do nothing the page will update to no reservations
			})
			.catch((error) => {
				if ('data' in error && isApiError(error.data)) {
					showError({ text: error.data.description, id: 'reservation-off-error' });
				}
			});
	};

	return (
		<Stack justifyContent='center' height='100%'>
			<ZeroState
				illustration={<IllustrationWithBackground image={ReservationOff} title={title} />}
				title={title}
				containerSx={{ backgroundColor: 'transparent' }}
			>
				<Typography variant='body2' sx={{ marginBottom: 4 }}>
					{t('reservationOff.description')}
				</Typography>
				{isTester && <Alert severity='info'>{t('reservationOff.isTester')}</Alert>}
				{hasAdminPermissions && (
					<Button variant='contained' onClick={enableReservations} data-trackingid='enable-reservations'>
						{t('reservationOff.button')}
					</Button>
				)}
			</ZeroState>
		</Stack>
	);
};

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { EventTabDetails } from './details/event-tab-details';
import { EventTabContent } from './content/event-tab-content';
import { EventTabScreenshot } from './screenshot/event-tab-sceenshot';
import { ComponentTabPanel } from '../../../../layout/component-tab-panel/component-tab-panel';
import { Spinner } from '../../../../common/spinner';
import { Event, useGetV4ResultsByResultIdEventsAndEventIdQuery } from '@neoload/api';
import { isErrorEvent } from '@neoload/utils';

export type EventDrawerContentProps = {
	resultId: string;
	event: Event;
	showScreenShot: boolean;
};

const EventDrawerContent = ({ resultId, event, showScreenShot }: EventDrawerContentProps) => {
	const { t } = useTranslation(['result']);
	const [selectedTab, setSelectedTab] = useState(showScreenShot ? 2 : 0);
	const [isScrolling, setIsScrolling] = useState(false);
	const onScroll = (event: React.UIEvent) => setIsScrolling(event.currentTarget.scrollTop !== 0);

	const {
		data: eventWithDetails,
		isLoading,
		error,
	} = useGetV4ResultsByResultIdEventsAndEventIdQuery({
		eventId: event.id,
		resultId,
	});

	if (isLoading) {
		return <Spinner />;
	}

	if (error || !eventWithDetails) {
		return (
			<Typography color='error' margin={2}>
				{t('events.details.fetchError')}
			</Typography>
		);
	}

	let screenshotId: string | undefined;

	if (isErrorEvent(eventWithDetails)) {
		if (eventWithDetails.hasScreenshot) {
			screenshotId = eventWithDetails.screenshotId;
		} else if (selectedTab === 2) {
			setSelectedTab(0);
		}
	} else if (selectedTab !== 0) {
		setSelectedTab(0);
	}

	return (
		<>
			{isErrorEvent(eventWithDetails) && (
				<Grid item>
					<Tabs value={selectedTab} onChange={(_event, tab) => setSelectedTab(tab)}>
						<Tab label={t('events.tabs.details')} />
						<Tab label={t('events.tabs.content')} />
						{screenshotId && <Tab label={t('events.tabs.screenshot')} />}
					</Tabs>
				</Grid>
			)}
			<Grid
				item
				sx={(theme) => ({
					overflowY: 'auto',
					boxShadow: isScrolling ? 'inset 0 4px 8px -8px' : '',
					borderTop: `1px solid ${theme.palette.divider}`,
					padding: 2,
					flexGrow: 1,
				})}
				onScroll={onScroll}
			>
				<ComponentTabPanel value={selectedTab} index={0}>
					<EventTabDetails event={{ ...event, ...eventWithDetails }} isLoading={isLoading} />
				</ComponentTabPanel>
				{isErrorEvent(eventWithDetails) && (
					<ComponentTabPanel value={selectedTab} index={1}>
						<EventTabContent event={{ ...event, ...eventWithDetails }} resultId={resultId} />
					</ComponentTabPanel>
				)}
				{screenshotId && (
					<ComponentTabPanel value={selectedTab} index={2} height='100%'>
						<EventTabScreenshot screenshotId={screenshotId} resultId={resultId} />
					</ComponentTabPanel>
				)}
			</Grid>
		</>
	);
};

export { EventDrawerContent };

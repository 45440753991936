import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useForm } from 'react-hook-form';
import TableBody from '@mui/material/TableBody';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useDebounce } from 'react-use';
import { useTheme } from '@mui/material';
import { LeasesDatagrid } from './leases/leases-datagrid';
import { LicenseVusTableCell } from './license-vus-table-cell';
import { Spinner } from '../../../common/spinner';
import { NumberTextfieldWithoutSpinners } from '../../../common/number-textfield-without-spinners';
import {
	useGetV4SettingsQuery,
	useGetV4WorkspacesByWorkspaceIdSubscriptionQuery,
	usePatchV4WorkspacesByWorkspaceIdMutation,
	WorkspaceQuota,
} from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

type SubscriptionPanelProps = {
	workspaceId: string;
	isQuotaLimitEnabled: boolean | undefined;
	isAdmin: boolean;
};

const textFieldProps: TextFieldProps = {
	size: 'small',
	sx: { maxWidth: 100 },
};

const SubscriptionPanel = ({ workspaceId, isQuotaLimitEnabled, isAdmin }: SubscriptionPanelProps) => {
	const { t } = useTranslation(['workspace']);
	const { showInfo, showError } = useSetSnackbars();
	const { data: subscriptionInfo, isFetching: isSubscriptionInfoLoading } =
		useGetV4WorkspacesByWorkspaceIdSubscriptionQuery({ workspaceId, fields: ['webVu', 'sapVu'] });
	const { data: workspaceSettings, isLoading: isSettingsInfoLoading } = useGetV4SettingsQuery();
	const [patchWorkspace] = usePatchV4WorkspacesByWorkspaceIdMutation();
	const switchQuotaLimit = async (value: boolean) => {
		await patchWorkspace({ workspaceId, workspacePatchInput: { quotaLimitEnabled: value } });
	};
	const {
		register,
		watch,
		formState: { errors, isValid, isValidating },
	} = useForm<WorkspaceQuota>({
		mode: 'onChange',
		defaultValues: {
			web: subscriptionInfo?.webVu?.workspaceMaximum,
			sap: subscriptionInfo?.sapVu?.workspaceMaximum,
		},
	});

	const form = watch();
	const hasFormChanged =
		!!form.web &&
		(form.sap !== subscriptionInfo?.sapVu?.workspaceMaximum || form.web !== subscriptionInfo?.webVu?.workspaceMaximum);

	const theme = useTheme();
	useDebounce(
		async () => {
			if (isValidating) {
				return;
			}
			if (isValid && hasFormChanged) {
				await patchWorkspace({ workspaceId, workspacePatchInput: { quota: form } })
					.unwrap()
					.then(() => {
						showInfo({
							text: t('tabs.subscription.snackbarSuccess'),
							id: 'config_success',
							autoHideDuration: 2000,
						});
					})
					.catch((_error) => {
						showError({ text: t('common:errors.otherError.content') });
					});
			}
		},
		500,
		[isValidating],
	);

	if (isSubscriptionInfoLoading || isSettingsInfoLoading) {
		return <Spinner />;
	}

	const isReservationModeEnabled = workspaceSettings?.reservationModeStatus === 'RESERVATION_MODE_ENABLED';
	return (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
			}}
		>
			<Box
				sx={{ display: 'flex', margin: 2, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
			>
				<Typography variant='subtitle1'>{t('tabs.subscription.title')}</Typography>
				{isAdmin && (
					<FormControlLabel
						control={
							<Switch
								onClick={(event) => event.stopPropagation()}
								onChange={(event) => switchQuotaLimit(event.target.checked)}
								defaultChecked={isQuotaLimitEnabled}
								disabled={!isAdmin}
							/>
						}
						label={t('tabs.subscription.limitUsage')}
					/>
				)}
			</Box>

			<Box>
				<TableContainer>
					<Table
						border={0}
						// eslint-disable-next-line @typescript-eslint/naming-convention
						sx={{ maxWidth: isReservationModeEnabled ? 650 : 450, borderBottom: 'none', '& td, & th': { border: 0 } }}
					>
						<TableHead>
							<TableRow>
								<TableCell sx={{ color: theme.palette.text.secondary }}>{t('tabs.subscription.protocol')}</TableCell>
								{isReservationModeEnabled && (
									<TableCell sx={{ color: 'gray' }}>{t('tabs.subscription.available')}</TableCell>
								)}
								{isAdmin ? (
									<>
										<TableCell sx={{ color: 'gray' }}>{t('tabs.subscription.quota')}</TableCell>
										<TableCell sx={{ color: 'gray' }}>{t('tabs.subscription.license')}</TableCell>
									</>
								) : (
									<TableCell sx={{ color: 'gray' }}>{t('tabs.subscription.max')}</TableCell>
								)}
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>{t('tabs.subscription.web')}</TableCell>
								{isReservationModeEnabled && <TableCell>{subscriptionInfo?.webVu?.available}</TableCell>}
								{isAdmin ? (
									<>
										<TableCell>
											{isQuotaLimitEnabled ? (
												<NumberTextfieldWithoutSpinners
													{...textFieldProps}
													type='number'
													defaultValue={subscriptionInfo?.webVu?.workspaceMaximum}
													{...register('web', {
														setValueAs: (value) => Number.parseFloat(value),
														validate: (value) => Number.isInteger(value),
														min: 0,
														max: subscriptionInfo?.webVu?.licenseMaximum,
													})}
													error={!!errors.web}
												/>
											) : (
												<TextField {...textFieldProps} disabled value={t('tabs.subscription.noQuota')} />
											)}
										</TableCell>
										<LicenseVusTableCell vusValue={subscriptionInfo?.webVu?.licenseMaximum} isAdmin={isAdmin} />
									</>
								) : (
									<LicenseVusTableCell
										vusValue={
											subscriptionInfo?.webVu &&
											Math.min(subscriptionInfo.webVu.licenseMaximum, subscriptionInfo.webVu.workspaceMaximum)
										}
										isAdmin={isAdmin}
									/>
								)}
							</TableRow>
							{subscriptionInfo?.sapVu && (
								<TableRow sx={{ borderBottom: 'none' }}>
									<TableCell>{t('tabs.subscription.sap')}</TableCell>
									{isReservationModeEnabled && <TableCell>{subscriptionInfo.sapVu.available}</TableCell>}
									{isAdmin ? (
										<>
											<TableCell>
												{isQuotaLimitEnabled ? (
													<NumberTextfieldWithoutSpinners
														{...textFieldProps}
														type='number'
														defaultValue={subscriptionInfo.sapVu.workspaceMaximum}
														{...register('sap', {
															setValueAs: (value) => Number.parseFloat(value),
															validate: (value) => Number.isInteger(value),
															min: 0,
															max: subscriptionInfo.sapVu.licenseMaximum,
														})}
														error={!!errors.sap}
													/>
												) : (
													<TextField {...textFieldProps} disabled value={t('tabs.subscription.noQuota')} />
												)}
											</TableCell>
											<LicenseVusTableCell vusValue={subscriptionInfo.sapVu.licenseMaximum} isAdmin={isAdmin} />
										</>
									) : (
										<LicenseVusTableCell
											vusValue={Math.min(
												subscriptionInfo.sapVu.licenseMaximum,
												subscriptionInfo.sapVu.workspaceMaximum,
											)}
											isAdmin={isAdmin}
										/>
									)}
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
			<Box sx={{ flexGrow: 1, borderTop: '1px solid', borderColor: 'divider' }}>
				<LeasesDatagrid workspaceId={workspaceId} isAdmin={isAdmin} />
			</Box>
		</Box>
	);
};

export { SubscriptionPanel };

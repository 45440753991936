import IconInfoOutlined from '@tricentis/aura/components/IconInfoOutlined.js';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import { PayloadHelperCustomTooltip } from './payload-helper-custom-tooltip';

export const WebhookPayloadInfoIcon = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const theme = useTheme();
	const onMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const close = () => setAnchorEl(null);

	return (
		<>
			<Grid onMouseEnter={onMouseEnter}>
				<IconInfoOutlined sx={{ color: theme.palette.action.active, fontSize: '1em' }} />
			</Grid>
			<Popover
				open={anchorEl !== null}
				anchorEl={anchorEl}
				onClose={close}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: -20,
				}}
			>
				<Grid onMouseLeave={close}>
					<PayloadHelperCustomTooltip />
				</Grid>
			</Popover>
		</>
	);
};

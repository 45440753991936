import { useTranslation } from 'react-i18next';
import { LabelledItem } from '../../../../../common/labelled-item';
import type { CustomEventWithDetails } from '@neoload/api';

export type EventTabDetailsCustomProps = {
	event: CustomEventWithDetails;
};

const EventTabDetailsCustom = ({ event }: EventTabDetailsCustomProps) => {
	const { t } = useTranslation(['result']);
	const { details: customDetails = {} } = event;
	const { url, description } = customDetails;
	const urlDefined = url !== undefined;
	let urlToPrint = null;
	if (urlDefined) {
		const urlValue = url;
		urlToPrint =
			urlValue.startsWith('http://') || urlValue.startsWith('https://') ? (
				<a href={urlValue} target='_blank' rel='noreferrer'>
					{urlValue}
				</a>
			) : (
				url
			);
	}
	return (
		<>
			<LabelledItem id='event-custom-description' value={description} title={t('events.details.description')} />
			{urlDefined && <LabelledItem id='event-custom-url' value={urlToPrint} title={t('events.details.url')} />}
		</>
	);
};
export { EventTabDetailsCustom };

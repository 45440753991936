import { useTranslation } from 'react-i18next';
import { ReservationOffZeroState, ReservationsDataGrid, Spinner } from '@neoload/ui';
import { useGetV4SettingsQuery } from '@neoload/api';
import { useDocumentTitleHandler } from '@neoload/hooks';

const ReservationPage = () => {
	const { data: settings, isLoading: isSettingsInfoLoading } = useGetV4SettingsQuery();
	const { t } = useTranslation(['reservation']);
	useDocumentTitleHandler(t('title'));
	if (isSettingsInfoLoading) {
		return <Spinner />;
	}

	if (settings && settings.reservationModeStatus === 'RESERVATION_MODE_ENABLED') {
		return <ReservationsDataGrid />;
	}

	return <ReservationOffZeroState />;
};

export { ReservationPage };

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { FormProvider, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import { useCurrentWorkspace, useSetSnackbars } from '@neoload/hooks';
import { TestInput, usePostV4TestsMutation } from '@neoload/api';
import { isFieldUniquenessError } from '@neoload/utils';

export const TEST_NAME_MAXLENGTH = 100;
const DESCRIPTION_MAXLENGTH = 500;

type CreateTestModalProps = {
	handleClose: () => void;
	open: boolean;
	totalTests?: number;
};

const CreateTestModal = ({ open, handleClose, totalTests }: CreateTestModalProps) => {
	const { t } = useTranslation(['test']);
	const [createTest, { isLoading }] = usePostV4TestsMutation();
	const { showInfo, showError } = useSetSnackbars();
	const [{ id: workspaceId }] = useCurrentWorkspace();
	const defaultName = t('newTest', { number: totalTests === undefined ? '' : totalTests + 1 });

	const { formState, handleSubmit, reset, register, watch, setError, ...methods } = useForm<TestInput>({
		mode: 'onChange',
		defaultValues: {
			name: defaultName,
			description: '',
			workspaceId,
		},
	});

	useEffect(() => {
		reset({
			name: defaultName,
			workspaceId,
		});
	}, [reset, defaultName, workspaceId]);

	const name = watch('name');
	const description = watch('description');

	const submitForm = (testInput: TestInput) =>
		createTest({ testInput })
			.unwrap()
			.then(({ name }) => {
				close();
				showInfo({ text: t('createConfirm', { name }) });
			})
			.catch((error) => {
				if (isFieldUniquenessError(error)) {
					setError('name', { type: 'value', message: t('testNameAlreadyExist') }, { shouldFocus: true });
				} else {
					showError({ text: t('createFailed') });
				}
			});

	const close = () => {
		reset({
			name: defaultName,
			description: '',
			workspaceId,
		});
		handleClose();
	};

	const getHelperText = (value: string | undefined, maxLength: number) =>
		`${value?.length ?? 0}/${maxLength} ${t('common:input.characters')}`;

	return (
		<Dialog open={open} onClose={close}>
			<FormProvider
				{...methods}
				formState={formState}
				handleSubmit={handleSubmit}
				reset={reset}
				register={register}
				watch={watch}
				setError={setError}
			>
				<form aria-labelledby='create-test-dialog-title' onSubmit={handleSubmit(submitForm)} onReset={close}>
					<DialogTitle id='create-test-dialog-title'>{t('grid.actions.createTest')}</DialogTitle>
					<DialogContent>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: 3,
								marginTop: 1,
								width: 500,
							}}
						>
							<TextField
								InputProps={{ inputProps: { maxLength: TEST_NAME_MAXLENGTH } }}
								autoFocus
								label={t('test:labelName')}
								fullWidth
								error={!!formState.errors.name}
								helperText={formState.errors.name?.message ?? getHelperText(name, TEST_NAME_MAXLENGTH)}
								required={true}
								data-testid='create-test-name-input'
								{...register('name', {
									required: t('common:input.required'),
								})}
								size='small'
							/>
							<TextField
								InputProps={{ inputProps: { maxLength: DESCRIPTION_MAXLENGTH } }}
								multiline={true}
								label={t('test:placeholderDescription')}
								error={!!formState.errors.description}
								helperText={formState.errors.description?.message ?? getHelperText(description, DESCRIPTION_MAXLENGTH)}
								rows={5}
								data-testid='create-test-description-input'
								{...register('description')}
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button type='reset' data-trackingid='create-test-cancel'>
							{t('common:cancel')}
						</Button>
						<Button
							disabled={!formState.isValid || Object.keys(formState.errors).length > 0 || isLoading}
							type='submit'
							variant='contained'
							startIcon={isLoading ? <CircularProgress size={24.5} color='inherit' /> : null}
							data-testid='create-and-configure-button'
							data-trackingid='create-test-ok'
						>
							{t('test:buttonConfirmCreate')}
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
};

export { CreateTestModal };

import { menu } from './menu';
import { login } from './login';
import { test } from './test';
import { result } from './result';
import { common } from './common';
import { user } from './user';
import { home } from './home';
import { workspace } from './workspace';
import { dashboard } from './dashboard';
import { dashboardLegacyKeys } from './dashboard-legacy-keys';
import { externalLinks } from './external-links';
import { profile } from './profile';
import { zone } from './zone';
import { infrastructureProviders } from './infrastructure-providers';
import { subscription } from './subscription';
import { license } from './license';

import { reservation } from './reservation';
import { usageHistory } from './usage-history';
import { workspaceSubscription } from './workspace-subscription';

const en = {
	menu,
	login,
	test,
	result,
	common,
	user,
	home,
	workspace,
	dashboard,
	dashboardLegacyKeys,
	externalLinks,
	profile,
	zone,
	infrastructureProviders,
	subscription,
	license,
	reservation,
	usageHistory,
	workspaceSubscription,
};

export { en };

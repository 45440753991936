import { ChangeEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, getModal } from '@neoload/api';

/**
 * Return error label for given draft Zone name
 * @param zoneName
 * return i18n key for error, null if zoneName is valid
 */
const zoneNameCheck = (zoneName: string) => {
	if (zoneName.length === 0) {
		return 'common:input.required';
	}
	if (zoneName.length > 150) {
		return 'dynamicEdition.nameTooLong';
	}
};
export type StaticZonesModalInputProps = {
	valueUpdater: (value: { name: string }) => void;
	setConfirmable: (confirmable: boolean) => void;
	defaultName?: string;
};
const StaticZonesModalInput = ({ valueUpdater, setConfirmable, defaultName = '' }: StaticZonesModalInputProps) => {
	const { t } = useTranslation('zone');
	const [error, setError] = useState<string | undefined>('');
	const dispatch = useDispatch();
	const { handleConfirm } = useSelector(getModal);
	const change = (name: string) => {
		setError(zoneNameCheck(name));
		valueUpdater({ name });
		setConfirmable(!zoneNameCheck(name));
	};
	useEffect(() => {
		valueUpdater({ name: defaultName });
		setConfirmable(!zoneNameCheck(defaultName));
	}, [defaultName, valueUpdater, setConfirmable]);

	return (
		<form
			onSubmit={async (event) => {
				event.preventDefault();
				const { value: name } = (event.target as HTMLFormElement)[0] as HTMLInputElement;
				const error = zoneNameCheck(name);
				if (!error) {
					await handleConfirm?.({ name });
					dispatch(closeModal());
				}
			}}
		>
			<TextField
				name='name'
				defaultValue={defaultName}
				data-testid='static-zones-modal-input'
				InputLabelProps={{ shrink: true }}
				autoFocus
				onChange={(event: ChangeEvent<HTMLInputElement>) => change(event.target.value)}
				label={t('common:name')}
				placeholder={t('common:name')}
				fullWidth
				margin='normal'
				required
				error={!!error}
				helperText={t(error ?? '')}
				size='small'
			/>
		</form>
	);
};

export { StaticZonesModalInput };

import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DatagridAction } from '../../common/data-grid/actions/data-grid-actions';
import { Reservation, useDeleteV4ReservationsByReservationIdMutation } from '@neoload/api';
import { useConfirmModal, useCrudBatchMessage } from '@neoload/hooks';

const useReservationsDatagridActions = (
	selectedReservation: Reservation | undefined,
	editSchedule: (workspaceId?: string, testId?: string, startDate?: dayjs.Dayjs, reservationId?: string) => void,
): DatagridAction[] => {
	const { openDeleteConfirmModal } = useConfirmModal();
	const [deleteReservationMutation] = useDeleteV4ReservationsByReservationIdMutation();

	const { t } = useTranslation(['reservation']);

	const { remove } = useCrudBatchMessage('reservation');
	const deleteReservation = async (reservationIds: string[]) => {
		await remove(reservationIds, (id) => deleteReservationMutation({ reservationId: id }).unwrap());
	};

	const openConfirmationReservationDialog = (reservation: Reservation) => {
		const title = t('grid.delete.title', { name: reservation.name });
		const content = t('grid.delete.label', { name: reservation.name });
		openDeleteConfirmModal({
			title,
			content,
			handleConfirm: () => deleteReservation([reservation.id]),
		});
	};

	const openEditScheduleTestModal = (reservation: Reservation) => {
		editSchedule(reservation.workspaceId, reservation.testId, dayjs(reservation.startDate), reservation.id);
	};

	const isScheduleAndEditable = (reservation: Reservation | undefined): boolean => {
		const value =
			reservation !== undefined && reservation.testId !== undefined && dayjs(reservation.startDate).isAfter(dayjs());
		return !value;
	};

	return [
		{
			icon: <DeleteOutlined />,
			text: t('common:delete'),
			color: 'error',
			action: () => {
				if (selectedReservation) {
					openConfirmationReservationDialog(selectedReservation);
				}
			},
			disabled: false,
			singleItem: (row: Reservation) => ({
				disabled: false,
				action: () => {
					openConfirmationReservationDialog(row);
				},
			}),
		},
		{
			icon: <EditOutlined />,
			text: t('common:edit'),
			action: () => {
				if (selectedReservation) {
					openEditScheduleTestModal(selectedReservation);
				}
			},
			hidden: isScheduleAndEditable(selectedReservation),
			disabled: false,
			singleItem: (row: Reservation) => ({
				hidden: isScheduleAndEditable(row),
				disabled: false,
				action: () => {
					openEditScheduleTestModal(row);
				},
			}),
		},
	];
};

export { useReservationsDatagridActions };

import { DashboardFilter, DashboardSeries } from '@neoload/api';

export const checkDuplicate = (seriesList: DashboardSeries[], nextSeries: DashboardSeries): boolean =>
	seriesList.some(
		(item) =>
			item.resultId === nextSeries.resultId &&
			(nextSeries.filter.type === 'ELEMENTS_TIMESERIES' || nextSeries.filter.type === 'ELEMENTS_PERCENTILES') &&
			item.filter.type === nextSeries.filter.type &&
			item.filter.elementId === nextSeries.filter.elementId &&
			item.filter.statistic.name === nextSeries.filter.statistic.name &&
			(item.filter.userPathId ?? '') === (nextSeries.filter.userPathId ?? '')
	) ||
	seriesList.some(
		(item) =>
			nextSeries.filter.type === 'MONITORS_TIMESERIES' &&
			item.filter.type === nextSeries.filter.type &&
			item.filter.monitorId === nextSeries.filter.monitorId &&
			item.resultId === nextSeries.resultId
	);

export const seriesListNotDeleted = (
	seriesList: DashboardSeries[],
	existingSeriesList: (
		| {
				id?: string;
				deleteOnSubmit?: boolean;
		  }
		| undefined
	)[] = []
) => {
	const notDeleted = existingSeriesList.filter((item) => !item?.deleteOnSubmit).map((item) => item?.id);
	return seriesList.filter((item) => notDeleted?.includes(item.id));
};

const timeseriesFilterTypes = new Set<DashboardFilter['type']>(['ELEMENTS_TIMESERIES', 'MONITORS_TIMESERIES']);
const percentilesFilterTypes = new Set<DashboardFilter['type']>(['ELEMENTS_PERCENTILES']);

export const checkCompatible = (seriesList: DashboardSeries[], other: DashboardSeries): boolean => {
	if (timeseriesFilterTypes.has(other.filter.type)) {
		return seriesList.every(({ filter: { type } }) => timeseriesFilterTypes.has(type));
	}
	if (percentilesFilterTypes.has(other.filter.type)) {
		return seriesList.every(({ filter: { type } }) => percentilesFilterTypes.has(type));
	}
	return false;
};

import IconButton from '@mui/material/IconButton';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';

type InputNumberProps = {
	onChange: (value: number) => void;
	value: number;
	min?: number;
	max?: number;
} & Pick<TextFieldProps, 'label' | 'disabled' | 'error'>;

const InputNumber = ({ onChange, value, disabled, min, max, ...others }: InputNumberProps) => {
	const theme = useTheme();
	const [localValue, setLocalValue] = useState(value ?? 0);
	const { t } = useTranslation(['common']);

	useEffect(() => {
		if (localValue !== value) {
			setLocalValue(value);
		}
	}, [localValue, value]);

	const tooltipRemove = disabled ? '' : t('decrease');
	const tooltipAdd = disabled ? '' : t('increase');
	const disabledIncrease = disabled || localValue === max;
	const disabledDecrease = disabled || localValue === min;

	return (
		<Stack direction='row' alignItems='center'>
			<Tooltip arrow title={tooltipRemove}>
				<span>
					<IconButton
						aria-label={t('decrease') + ' button'}
						onClick={() => {
							const updatedValue = value - 1;
							if (min === undefined || updatedValue >= min) {
								onChange(updatedValue);
								setLocalValue((previousState) => previousState - 1);
							}
						}}
						disabled={disabledDecrease}
						sx={{ paddingRight: theme.spacing(0.5) }}
						size='small'
					>
						<RemoveOutlinedIcon />
					</IconButton>
				</span>
			</Tooltip>
			<TextField
				size='small'
				sx={{ width: '60px' }}
				onChange={(changeEvent) => {
					let value = Number.parseInt(changeEvent.currentTarget.value);
					if (Number.isNaN(value)) {
						value = 0;
					}
					if (max && value > max) {
						value = max;
					}
					if (min && value < min) {
						value = min;
					}
					onChange(value);
					setLocalValue(value);
				}}
				onKeyDown={(key) => {
					if (key.code === 'ArrowDown') {
						const updatedValue = value - 1;
						if (min === undefined || updatedValue >= min) {
							onChange(updatedValue);
							setLocalValue((previousState) => previousState - 1);
						}
					}
					if (key.code === 'ArrowUp') {
						const updatedValue = value + 1;
						if (max === undefined || updatedValue <= max) {
							onChange(updatedValue);
							setLocalValue((previousState) => previousState + 1);
						}
					}
				}}
				value={localValue}
				inputProps={{
					sx: {
						textAlign: 'center',
					},
				}}
				disabled={disabled}
				{...others}
			/>
			<Tooltip arrow title={tooltipAdd}>
				<span>
					<IconButton
						aria-label={t('increase') + ' button'}
						onClick={() => {
							const updatedValue = value + 1;
							if (max === undefined || updatedValue <= max) {
								onChange(updatedValue);
								setLocalValue((previousState) => previousState + 1);
							}
						}}
						disabled={disabledIncrease}
						sx={{ paddingLeft: theme.spacing(0.5) }}
						size='small'
					>
						<AddOutlined />
					</IconButton>
				</span>
			</Tooltip>
		</Stack>
	);
};

export { InputNumber };

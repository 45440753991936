const profile = {
	update: {
		success: 'Profile updated',
		failure: 'Failed to update your profile',
	},
	emailAddress: 'Email address',
	account: 'Account',
	role: 'Role',
	changePassword: {
		changePassword: 'Change password',
		failedToRetrievePasswordPolicy: 'Failed to retrieve password policy',
		wrongCurrentPassword: 'The current password is incorrect. Please check and try again.',
		invalidNewPassword: 'The new password you are trying to set may have leaked. Try another password.',
		success: 'Password updated',
		currentPassword: 'Current password',
		currentPasswordRequired: 'Current password is required',
		// eslint-disable-next-line unicorn/no-keyword-prefix
		newPassword: 'New password',
		// eslint-disable-next-line unicorn/no-keyword-prefix
		newPasswordRequired: 'New password is required',
		show: 'Show',
		hide: 'Hide',
		validation: {
			minimumLength: 'At least {{minimumLength}} characters.',
			minimumDigits: 'At least {{minimumDigits}} numbers.',
			minimumLowerCaseCharacters: 'At least {{minimumLowerCaseCharacters}} lower case characters.',
			minimumUpperCaseCharacters: 'At least {{minimumUpperCaseCharacters}} upper case characters.',
			minimumSpecialCharacters: "At least {{minimumSpecialCharacters}} characters in '{{specialCharacters}}'.",
		},
	},
	firstName: 'First name',
	firstNameRequired: 'First name is required',
	lastName: 'Last name',
	lastNameRequired: 'Last name is required',
};

export { profile };

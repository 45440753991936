import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconLinkOutlined from '@tricentis/aura/components/IconLinkOutlined.js';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileSaver from 'file-saver';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { TextEllipsisTooltip } from '../../../common/text-ellipsis-tooltip';
import { buildDashboardPublicSharedLink } from '../dashboard-common';
import {
	Dashboard,
	useDeleteV4DashboardsByDashboardIdPublicTokenMutation,
	useLazyGetV4DashboardsByDashboardIdPdfQuery,
	usePatchV4DashboardsByDashboardIdMutation,
	usePostV4DashboardsByDashboardIdPublicTokenMutation,
} from '@neoload/api';
import { useGetMe, useSetSnackbars } from '@neoload/hooks';

export type DashboardShareDialogProps = {
	isOpen: boolean;
	onClose: () => void;
	dashboard: Dashboard;
};

export const DashboardShareDialog = ({ isOpen, onClose, dashboard }: DashboardShareDialogProps) => {
	const { t } = useTranslation(['dashboard']);
	const [currentUser] = useGetMe();
	const { showError, showInfo } = useSetSnackbars();
	const [patchDashboard] = usePatchV4DashboardsByDashboardIdMutation();
	const [createPublicToken] = usePostV4DashboardsByDashboardIdPublicTokenMutation();
	const [deletePublicToken] = useDeleteV4DashboardsByDashboardIdPublicTokenMutation();
	const [triggerExportPdf] = useLazyGetV4DashboardsByDashboardIdPdfQuery();
	const isReport = dashboard.resultId !== undefined;
	const isOwner = dashboard.ownerId === currentUser.id;
	const [isLoadingExport, setIsLoadingExport] = useState(false);

	const onChangeDashboardShareStatus = (isShared: boolean) => {
		patchDashboard({
			dashboardId: dashboard.id,
			dashboardPatchRequest: { shareStatus: isShared ? 'SHARED' : 'UNSHARED' },
		})
			.unwrap()
			.then(() => {
				isShared
					? showInfo({ text: t('messages.updateShareStatusSuccess') })
					: showInfo({ text: t('messages.updateUnshareStatusSuccess') });
			})
			.catch((error: unknown) => {
				console.error('error on onChangeDashboardShareStatus', error);
				showError({ text: t('messages.updateShareStatusError') });
			});
	};

	const onChangePublicLink = (isPublic: boolean) => {
		if (isPublic) {
			createPublicToken({ dashboardId: dashboard.id })
				.unwrap()
				.then(() => {
					showInfo({ text: t('messages.generatePublicLink') });
				})
				.catch(() => {
					showError({ text: t('messages.generatePublicLinkError') });
				});
		} else {
			deletePublicToken({ dashboardId: dashboard.id })
				.unwrap()
				.then(() => {
					showInfo({ text: t('messages.deletePublicLink') });
				})
				.catch(() => {
					showError({ text: t('messages.deletePublicLinkError') });
				});
		}
	};

	const copyToClipboard = () => {
		if (dashboard.publicToken) {
			const publicLink = buildDashboardPublicSharedLink(dashboard.publicToken);

			navigator.clipboard
				.writeText(publicLink)
				.then(() => {
					showInfo({ text: t('messages.copyPublicLink') });
				})
				.catch(() => {
					showError({ text: t('messages.copyPublicLinkError') });
				});
		}
	};

	const onExportDashboard = () => {
		setIsLoadingExport(true);
		triggerExportPdf({
			dashboardId: dashboard.id,
			locale: 'EN',
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		})
			.then(({ data }) => {
				if (!data) {
					throw new Error('No Response Data while exporting PDF');
				}
				FileSaver.saveAs(data.fileContent, data.fileName ?? `${dashboard.name}.pdf`);
				showInfo({ text: t(`messages.${isReport ? 'exportReportPdf' : 'exportDashboardPdf'}`) });
			})
			.catch(() => showError({ text: t('messages.exportPdfError') }))
			.finally(() => setIsLoadingExport(false));
	};

	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth={true}>
			<DialogTitle marginBottom={2}>
				{t('shareDialog.title', {
					type: isReport ? t('dashboardType.report') : t('dashboardType.dashboard'),
				})}
			</DialogTitle>
			<DialogContent>
				<Stack gap={2}>
					<Typography variant='subtitle1'>{t('shareDialog.canAccess')}</Typography>
					<Stack direction='row' gap={1}>
						<TextEllipsisTooltip title={dashboard.ownerFullName} variant='body2' />
						{dashboard.ownerId === currentUser.id && (
							<Typography variant='body2' color='text.secondary'>
								{t('shareDialog.you')}
							</Typography>
						)}
						<Typography variant='body2' ml='auto'>
							{t('shareDialog.owner')}
						</Typography>
					</Stack>
					<Stack direction='row' gap={1} alignItems='center'>
						<Switch
							inputProps={{ 'aria-label': t('shareDialog.shareInWorkspace') }}
							defaultChecked={dashboard.shareStatus !== 'UNSHARED'}
							disabled={isReport || !isOwner}
							onChange={(event) => onChangeDashboardShareStatus(event.currentTarget.checked)}
						/>
						<Typography variant='body2'>
							{t('shareDialog.shareWorkspace', {
								workspace: currentUser.workspaces.find((workspace) => workspace.id === dashboard.workspaceId)?.name,
							})}
						</Typography>
						<Typography variant='body2' ml='auto'>
							{t('shareDialog.canEdit')}
						</Typography>
					</Stack>
				</Stack>
				<Stack direction='row' gap={1} alignItems='center'>
					<Switch
						inputProps={{ 'aria-label': t('shareDialog.publicLink') }}
						checked={dashboard.publicToken !== undefined}
						disabled={currentUser.role === 'GUEST' && !isOwner}
						onChange={(event) => onChangePublicLink(event.currentTarget.checked)}
					/>
					<Typography variant='body2'>{t('shareDialog.anyone')}</Typography>
					<Typography variant='body2' ml='auto'>
						{t('shareDialog.canView')}
					</Typography>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Stack sx={{ marginRight: 'auto' }} direction='row' gap={1}>
					<Button
						variant='outlined'
						size='small'
						disabled={isLoadingExport}
						startIcon={isLoadingExport ? <CircularProgress size={18} color='inherit' /> : <FileDownloadOutlinedIcon />}
						onClick={onExportDashboard}
						data-trackingid='dashboard-share-pdf'
					>
						{t('shareDialog.exportPdf')}
					</Button>
					{dashboard.publicToken && (
						<Button
							onClick={copyToClipboard}
							color='primary'
							variant='outlined'
							startIcon={<IconLinkOutlined />}
							data-trackingid='dashboard-share-copyLink'
							size='small'
						>
							{t('shareDialog.copyLink')}
						</Button>
					)}
				</Stack>
				<Button onClick={onClose} color='primary' variant='contained' data-trackingid='dashboard-share-close' size='small'>
					{t('common:close')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

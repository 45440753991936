import { useTranslation } from 'react-i18next';
import IconCalendarOutlined from '@tricentis/aura/components/IconCalendarOutlined.js';
import Chip from '@mui/material/Chip';
import { useWatch } from 'react-hook-form';
import { useRef, useState } from 'react';
import { TestExecutionForm } from '../types';
import { TestExecutionReservationMenu } from '../reservation/test-execution-reservation-menu';

const TestExecutionReservation = () => {
	const { t } = useTranslation(['test']);
	const reservation = useWatch<TestExecutionForm, 'reservation'>({ name: 'reservation' });
	const chipRef = useRef(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);

	const openReservationMenu = () => {
		setAnchorEl(chipRef.current);
		setOpen(true);
	};

	const closeReservationMenu = () => {
		setAnchorEl(null);
		setOpen(false);
	};

	const availableReservations = reservation.availableReservations;
	return reservation.reservationModeStatus === 'RESERVATION_MODE_ENABLED' ? (
		<>
			<Chip
				size='medium'
				avatar={<IconCalendarOutlined />}
				ref={chipRef}
				label={reservation?.selectedReservation?.name ?? t('testExecution.autoReservation')}
				onClick={openReservationMenu}
				disabled={availableReservations === undefined || availableReservations.length === 0}
			/>
			<TestExecutionReservationMenu
				reservations={availableReservations}
				open={open}
				anchorEl={anchorEl}
				closeReservationMenu={closeReservationMenu}
			/>
		</>
	) : (
		''
	);
};

export { TestExecutionReservation };

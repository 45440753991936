import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { ComparisonMainForm } from './comparison-main-form';
import { ComparisonSerieDefaultValue, useFindInTree } from './row/use-find-in-tree';
import { AddSeriesForm, SeriesData } from '../../add-series';
import { ComparisonTileEditionFormFields, FormTemplate, TileEditionFormBaseProps } from '../tile-edition-form';
import { MonitorComparisonStatistic } from '../../add-series/statistics';
import { matchAndReplaceWithI18N } from '../../../../dashboard-common';
import { useGetReportComparisonTile } from '../use-get-report-comparison-tile';
import { Spinner } from '../../../../../../common/spinner';
import {
	ElementComparisonRow,
	UserPathElementValueStatistic,
	ValueComparisonRow,
	ValuesComparisonColumn,
	ValuesComparisonDashboardTile,
} from '@neoload/api';

export type ComparisonFormProps = {
	reportBenchId?: string;
	tile: ValuesComparisonDashboardTile;
} & TileEditionFormBaseProps<ComparisonTileEditionFormFields>;

enum EditingRowStatusEnum {
	VIEW,
	EDIT,
	CREATE,
}

const areColumnsValid = (form: ComparisonTileEditionFormFields) =>
	form.columns && form.columns.length > 0 && !form.columns.some((column) => (column.columnName ?? '').length === 0);

const areRowsValid = (form: ComparisonTileEditionFormFields) => form.rows && form.rows.length > 0;

const getFormValuesFromTile = (tile: ValuesComparisonDashboardTile): ComparisonTileEditionFormFields => ({
	type: 'VALUES_COMPARISON',
	title: matchAndReplaceWithI18N(tile.title),
	rows: tile.rows,
	columns: tile.columns,
	differenceType: tile.differenceType,
});

export const concatRowsCols = (
	existing: ComparisonTileEditionFormFields,
	rowsToAdd: ValueComparisonRow[],
	columnsToAdd: ValuesComparisonColumn[]
): ComparisonTileEditionFormFields => ({
	...existing,
	type: 'VALUES_COMPARISON',
	rows: [...(existing.rows ?? []), ...rowsToAdd],
	columns: [...(existing.columns ?? []), ...columnsToAdd],
});
export const toRowItems = <T extends UserPathElementValueStatistic | MonitorComparisonStatistic>(
	seriesData: SeriesData<T>,
	t: TFunction<[string], undefined>
): ValueComparisonRow[] => {
	if (seriesData.type === 'MONITOR') {
		return [
			{
				visible: true,
				path: [...seriesData.monitor.path, seriesData.monitor.name],
				rowName: `${[...seriesData.monitor.path, seriesData.monitor.name].join('/')} - ${t(
					'statistics.' + seriesData.comparisonStat
				)}`,
				rowType: 'MONITOR',
				statistic: seriesData.comparisonStat,
			},
		];
	} else if (seriesData.type === 'USERPATH') {
		return seriesData.stats.map((statistic) => ({
			rowType: 'ELEMENT',
			path: seriesData.userPath,
			rowName: `${seriesData.userPathElement.name} - ${t(
				'common:statistics.userPathElementValueStatistics.' + statistic
			)}`,
			userPathId: seriesData.rootElementType === 'USER_PATH' ? seriesData.userPath[0] : undefined,
			visible: true,
			elementType: seriesData.userPathElement.type as ElementComparisonRow['elementType'],
			statistic: statistic as UserPathElementValueStatistic,
		}));
	} else {
		throw new Error('Series type not recognized');
	}
};

export const replaceRow = (
	existing: ComparisonTileEditionFormFields,
	indexToReplace: number,
	replacementRow: ValueComparisonRow
): ComparisonTileEditionFormFields => ({
	...existing,
	type: 'VALUES_COMPARISON',
	rows: existing.rows.map((row, index) => (index === indexToReplace ? replacementRow : row)),
});
export const ComparisonForm = ({ tile, onFormValuesChange, reportBenchId, onSubmit, ...rest }: ComparisonFormProps) => {
	const [editingRowStatus, setEditingRowStatus] = useState<EditingRowStatusEnum>(EditingRowStatusEnum.VIEW);
	const [editingRowIndex, setEditingRowIndex] = useState<number>(-1);
	const { t } = useTranslation(['dashboard']);
	const fetchReportCreationTile = useGetReportComparisonTile();
	const isReportCreationTile = tile.id === '' && reportBenchId !== undefined;
	const [tileLoading, setTileLoading] = useState<boolean>(isReportCreationTile);
	const [formValues, setFormValues] = useState<ComparisonTileEditionFormFields>(getFormValuesFromTile(tile));
	const [addSerieDataDefaultValue, setAddSerieDataDefaultValue] = useState<
		ComparisonSerieDefaultValue<UserPathElementValueStatistic | MonitorComparisonStatistic> | undefined
	>();
	const [findInTree] = useFindInTree(formValues.columns[0]?.resultId);

	useEffect(() => {
		if (isReportCreationTile) {
			fetchReportCreationTile(reportBenchId)
				.then((fetchedTile: ValuesComparisonDashboardTile) => {
					const values = getFormValuesFromTile(fetchedTile);
					setFormValues(values);
					onFormValuesChange(values);
				})
				// TODO : manage this error
				.catch((error) => console.log(error))
				.finally(() => setTileLoading(false));
		}
	}, [fetchReportCreationTile, isReportCreationTile, reportBenchId, onFormValuesChange]);
	useEffect(() => {
		if (editingRowStatus !== EditingRowStatusEnum.EDIT) {
			setAddSerieDataDefaultValue(undefined);
		}
	}, [editingRowStatus]);
	const onEditRow = useCallback(
		async (index: number) => {
			setAddSerieDataDefaultValue(await findInTree(formValues.rows[index]));
			setEditingRowIndex(index);
			setEditingRowStatus(EditingRowStatusEnum.EDIT);
		},
		[formValues.rows, findInTree]
	);

	const onAddRow = <T extends UserPathElementValueStatistic | MonitorComparisonStatistic>(
		addedSerie: SeriesData<T>
	) => {
		const addedRow: ValueComparisonRow[] = toRowItems(addedSerie, t);
		if (editingRowStatus === EditingRowStatusEnum.CREATE) {
			const addedColumns: ValuesComparisonColumn[] =
				formValues.columns.length === 0
					? [
							{
								columnName: addedSerie.testResult.name,
								resultId: addedSerie.testResult.id,
								visible: true,
							},
					  ]
					: [];

			onFormValuesChange(concatRowsCols(formValues, addedRow, addedColumns));
			setFormValues((previousFormValues) => concatRowsCols(previousFormValues, addedRow, addedColumns));
		} else {
			onFormValuesChange(replaceRow(formValues, editingRowIndex, addedRow[0]));
			setFormValues((previousFormValues) => replaceRow(previousFormValues, editingRowIndex, addedRow[0]));
		}
		setEditingRowStatus(EditingRowStatusEnum.VIEW);
	};

	if (tileLoading) {
		return <Spinner />;
	}

	return editingRowStatus === EditingRowStatusEnum.VIEW ? (
		<FormTemplate<ComparisonTileEditionFormFields>
			{...rest}
			onFormValuesChange={(values) => {
				const updatedValue = Object.assign(formValues, values);
				setFormValues(updatedValue);
				onFormValuesChange(updatedValue);
			}}
			defaultValues={formValues}
			onSubmit={(form) => onSubmit(form)}
			mainForm={
				<ComparisonMainForm
					onAddRow={() => setEditingRowStatus(EditingRowStatusEnum.CREATE)}
					resultId={formValues.columns[0]?.resultId}
					onEditRow={onEditRow}
				/>
			}
			canApply={(form) => areColumnsValid(form) && areRowsValid(form)}
		/>
	) : (
		<>
			<Stack
				direction='row'
				sx={{ paddingX: (theme) => theme.spacing(2), position: 'absolute', top: (theme) => theme.spacing(1) }}
			>
				<IconButton title={t('tile.edit.button')} onClick={() => setEditingRowStatus(EditingRowStatusEnum.VIEW)}>
					<ArrowBackOutlined />
				</IconButton>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography variant='h6'>{t('tile.edition.addSeries.availableDataSeries')}</Typography>
				</Box>
			</Stack>
			<Box sx={{ height: '100%' }}>
				<AddSeriesForm
					onApply={(data: SeriesData<UserPathElementValueStatistic | MonitorComparisonStatistic>) => onAddRow(data)}
					onCancel={() => setEditingRowStatus(EditingRowStatusEnum.VIEW)}
					key={editingRowStatus}
					multiple={editingRowStatus === EditingRowStatusEnum.CREATE}
					type='COMPARISON'
					defaultValue={addSerieDataDefaultValue}
					resultId={formValues.columns[0]?.resultId}
				/>
			</Box>
		</>
	);
};

import { Controller, EventType, useFormContext, WatchObserver } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useDebouncedCallback } from 'use-debounce';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import { Form } from './dedicated-ips-release-date-modal';
import { COST_IN_CLOUD_CREDITS_PER_DAY_PER_IP, toPatchAllCloudZonesValidDateFormat } from '../utils';
import { CloudCredits, usePatchV4ZonesMutation } from '@neoload/api';

const fetchModificationCostInCloudCreditsDebounceMillis = import.meta.env.VITEST_WORKER_ID ? 0 : 1000;

type DedicatedIpsReleaseDateFormProps = {
	cloudCredits: CloudCredits;
};

export const DedicatedIpsReleaseDateForm = ({ cloudCredits }: DedicatedIpsReleaseDateFormProps) => {
	const { t } = useTranslation(['zone']);
	const [patchZones, patchZonesResult] = usePatchV4ZonesMutation();
	const { control, formState, register, trigger, watch, setValue, setError } = useFormContext<Form>();
	const modificationCostInCloudCredits = watch('modificationCostInCloudCredits');

	const fetchModificationCostInCloudCredits = useDebouncedCallback<(input: { expirationDate: string }) => void>(
		({ expirationDate }) => {
			patchZones({
				dryRun: true,
				patchAllZonesFields: {
					type: 'CLOUD',
					expirationDate: toPatchAllCloudZonesValidDateFormat(expirationDate),
				},
			})
				.unwrap()
				.then((response) => {
					if (response.type !== 'CLOUD') {
						throw new Error(`Expected response type 'CLOUD', got '${response.type}'`);
					}
					if (response.modificationCostInCloudCredits === undefined) {
						throw new Error('Unable to fetch the modificationCostInCloudCredits');
					}
					setValue('modificationCostInCloudCredits', response.modificationCostInCloudCredits);
				})
				.then(() => trigger())
				.catch((error) => {
					console.error('Could not fetch Cloud Credits estimation', error);
					setError('modificationCostInCloudCredits', {
						type: 'fetch',
					});
				});
		},
		fetchModificationCostInCloudCreditsDebounceMillis,
	);

	useEffect(() => {
		const subscription = watch((values, info) => {
			if (isTriggeredByUser(info.type) && isTriggeringFieldNameValid(info.name) && values.expirationDate) {
				fetchModificationCostInCloudCredits({
					expirationDate: values.expirationDate,
				});
			}
		});
		return () => subscription.unsubscribe();
	}, [fetchModificationCostInCloudCredits, watch]);

	return (
		<Stack width='500px' spacing={1} marginTop={1}>
			<Box>
				<Controller
					control={control}
					name='expirationDate'
					rules={{
						required: true,
						validate: (value) => {
							const expirationDate = dayjs(value);
							return expirationDate.isAfter(dayjs(), 'days') || expirationDate.isSame(dayjs(), 'days');
						},
					}}
					render={({ field, fieldState: { error: fieldError } }) => (
						<FormControl required>
							<DatePicker
								disablePast
								value={dayjs(field.value)}
								onChange={(value) => field.onChange(value ? value.toISOString() : null)}
								label={t('cloudZone.dedicatedIps.releaseDateDialog.releaseDateField.label') + ' *'}
								sx={{
									'& .MuiInputBase-root': {
										height: '36px',
									},
									'& .MuiInputBase-input': {
										padding: '6px 14px',
									},
								}}
							/>
							{!!fieldError && (
								<FormHelperText error={true}>
									{t('cloudZone.dedicatedIps.releaseDateDialog.releaseDateField.invalidError')}
								</FormHelperText>
							)}
						</FormControl>
					)}
				/>
			</Box>
			<Box>
				<input
					type='hidden'
					{...register('modificationCostInCloudCredits', {
						valueAsNumber: true,
						validate: {
							validateCloudCredits: (value) => {
								if (value === null) {
									return false;
								}
								return value <= cloudCredits.available;
							},
						},
						required: true,
					})}
				/>
				<Stack direction='row' alignItems='center' gap={1}>
					{patchZonesResult.isLoading && !formState.errors.modificationCostInCloudCredits ? (
						<FormHelperText
							error={!!formState.errors.modificationCostInCloudCredits}
							sx={{ typography: 'bodyRegular' }}
						>
							<Stack component='span' alignItems='center' direction='row' gap={1}>
								{t('cloudZone.dedicatedIps.allocationDialog.cloudCreditsText')}
								<CustomSpinner />
								{t('cloudZone.dedicatedIps.allocationDialog.fetching')}
								{t('cloudZone.dedicatedIps.allocationDialog.cloudCreditsAvailable', {
									cloudCredits: cloudCredits.available,
								})}
							</Stack>
						</FormHelperText>
					) : (
						<FormHelperText
							error={!!formState.errors.modificationCostInCloudCredits}
							sx={{ typography: 'bodyRegular' }}
						>
							{t('cloudZone.dedicatedIps.allocationDialog.cloudCreditsText')}
							<Typography
								color={(theme) => theme.palette.text.primary}
								component='span'
								data-testid='cloud-credits-modification-cost'
							>
								{(modificationCostInCloudCredits ?? 0) + ' '}
							</Typography>
							{t('cloudZone.dedicatedIps.allocationDialog.cloudCreditsAvailable', {
								cloudCredits: cloudCredits.available,
							})}
						</FormHelperText>
					)}

					<Tooltip
						arrow
						placement='bottom'
						title={t('cloudZone.dedicatedIps.costPerDayPerIp', {
							costInCloudCreditsPerDayPerIp: COST_IN_CLOUD_CREDITS_PER_DAY_PER_IP,
						})}
					>
						<InfoOutlined fontSize='small' color='secondary' />
					</Tooltip>
				</Stack>
			</Box>
		</Stack>
	);
};

type TriggeringFieldName = Parameters<WatchObserver<Form>>[1]['name'];

const TRIGGERING_FIELD_NAME_BLACKLIST: Set<TriggeringFieldName> = new Set(['modificationCostInCloudCredits']);
const isTriggeredByUser = (_type: EventType | undefined) => true;
const isTriggeringFieldNameValid = (name: TriggeringFieldName | undefined) =>
	!TRIGGERING_FIELD_NAME_BLACKLIST.has(name);

const CustomSpinner = () => (
	<Box
		component='span'
		sx={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}
	>
		<CircularProgress size={14} />
	</Box>
);

const checkPasswordMinimumSpecialCharacters = (
	password: string,
	specialCharacters: string,
	minimumSpecialCharacters: number
): boolean | undefined => {
	const specialCharactersAsArray = new Set(specialCharacters);
	return [...password].filter((c) => specialCharactersAsArray.has(c)).length >= minimumSpecialCharacters;
};

const checkPasswordMinimumUpperCaseCharacters = (
	password: string,
	minimumUpperCaseCharacters: number
): boolean | undefined => (password.match(/[A-Z]/g) || []).length >= minimumUpperCaseCharacters;

const checkPassworMinimumLowerCaseCharacters = (
	password: string,
	minimumLowerCaseCharacters: number
): boolean | undefined => (password.match(/[a-z]/g) || []).length >= minimumLowerCaseCharacters;

const checkPasswordMinimumDigits = (password: string, minimumDigits: number): boolean | undefined =>
	(password.match(/\d/g) || []).length >= minimumDigits;

const checkPasswordMinimumLength = (password: string, minimumLength: number): boolean | undefined =>
	password.length >= minimumLength;

export {
	checkPassworMinimumLowerCaseCharacters,
	checkPasswordMinimumDigits,
	checkPasswordMinimumLength,
	checkPasswordMinimumSpecialCharacters,
	checkPasswordMinimumUpperCaseCharacters,
};

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { LabelledItem } from '../../../../common/labelled-item';
import { ForceReleaseModal } from '../force-release-modal';
import { Lease } from '@neoload/api';
import { timeUtils } from '@neoload/utils';

export type LeaseDrawerProps = {
	lease: Lease;
};
export const leaseTypeWording = (type: 'ONLINE_LEASE' | 'ONLINE_LEASE_FOR_RESERVATION' | 'OFFLINE_LEASE') => {
	switch (type) {
		case 'ONLINE_LEASE': {
			return 'online';
		}
		case 'ONLINE_LEASE_FOR_RESERVATION': {
			return 'reservation';
		}
		case 'OFFLINE_LEASE': {
			return 'offline';
		}
	}
};
const LeaseDrawerContent = ({ lease }: LeaseDrawerProps) => {
	const theme = useTheme();
	const { t } = useTranslation(['workspace'], { keyPrefix: 'tabs.subscription.leases' });
	const [isForceReleaseModalOpen, setIsForceReleaseModalOpen] = useState<boolean>(false);

	return (
		<Box sx={{ borderTop: `1px solid ${theme.palette.divider}`, padding: 2 }}>
			<Button
				sx={{ marginBottom: 2 }}
				color='error'
				variant='outlined'
				size='medium'
				onClick={() => setIsForceReleaseModalOpen(true)}
			>
				{t('forceRelease')}
			</Button>
			{isForceReleaseModalOpen && (
				<ForceReleaseModal
					open={isForceReleaseModalOpen}
					onClose={() => setIsForceReleaseModalOpen(false)}
					leaseId={lease.id}
				/>
			)}
			<LabelledItem title={t('details.owner')} value={lease.owner} id='lease.owner' />
			<LabelledItem
				title={t('columns.type')}
				value={t(`type.${leaseTypeWording(lease.leaseType)}`)}
				id='lease.leaseType'
			/>
			<LabelledItem title={t('columns.workspace')} value={lease.workspace?.name} id='lease.workspaceName' />
			<LabelledItem title={t('details.clientId')} value={lease.clientId} id='lease.clientId' />
			<Box sx={{ display: 'flex', flexDirection: 'row' }}>
				<Box sx={{ paddingRight: 4 }}>
					<LabelledItem title={t('columns.webVus')} value={lease.webVus} id='lease.webVus' />
				</Box>
				<Box sx={{ flex: 1 }}>
					<LabelledItem title={t('columns.sapVus')} value={lease.sapVus} id='lease.sapVus' />
				</Box>
			</Box>
			<LabelledItem
				title={t('details.expiration')}
				value={timeUtils.dateTimeAbsolute(lease.expirationDate)}
				id='lease.expirationDate'
			/>
			<LabelledItem title={t('details.concurrentRun')} value={lease.concurrentRuns} id='lease.concurrentRun' />
			<LabelledItem title={t('details.description')} value={lease.description} id='lease.description' />
		</Box>
	);
};

export { LeaseDrawerContent };

import { useFormContext } from 'react-hook-form';
import ChipGroup from '@tricentis/aura/components/ChipGroup.js';
import { useTranslation } from 'react-i18next';
import { WebhookFiltersKeys, WebhookFiltersType } from './types';
import { PostWebhookRequest } from '@neoload/api';
import { timeUtils } from '@neoload/utils';

export const WebhookFilterChipGroup = () => {
	const { t } = useTranslation('workspace', { keyPrefix: 'tabs.webhooks.filters' });
	const { setValue, watch } = useFormContext<PostWebhookRequest>();
	const filters = watch('filters');

	const getChipLabel = (key: WebhookFiltersKeys, value: WebhookFiltersType) => {
		const operator = t(`operator${value.operator}`);
		if (key === 'byDuration') {
			return `${t(key)} ${operator} ${timeUtils.durationSeconds(value.value)}`;
		}
		return `${t(key)} ${operator} ${value.value}`;
	};

	return (
		<ChipGroup
			multiline
			chips={Object.entries(filters)
				.filter(([_, value]) => value?.enabled)
				.map(([key, value]) => {
					const typedKey = key as WebhookFiltersKeys;
					return {
						onDelete: (_event) => {
							setValue(`filters.${typedKey}`, undefined);
						},
						label: getChipLabel(typedKey, value),
						sx: { maxWidth: '400px' },
					};
				})}
		/>
	);
};

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { TFunctionNonStrict } from 'i18next';
import { useState } from 'react';
import { DeletionFilterItem } from './deletion-policies-utils';

type DeletionFilterAutocompleteTextFieldProps = {
	type: 'test' | 'project' | 'scenario';
	value?: string | null;
	items: DeletionFilterItem[];
	isFetching: boolean;
	isError: boolean;
	workspaceName: string;
	noItemInWorkspace: boolean;
	onChange: (value: string | undefined) => void;
};

const getTooltipMessage = (
	t: TFunctionNonStrict,
	noItemInWorkspace: boolean,
	items: DeletionFilterItem[],
	workspaceName: string,
) => {
	// We make the difference between noItemInWorkspace which means that the call to search-criteria returned empty arrays
	// and no remaining items which means that all the items are already used by other deletion policies
	if (noItemInWorkspace) {
		return t('emptiness.noItems', { workspaceName });
	} else if (items.length === 0) {
		return t('emptiness.noRemainingItems', { workspaceName });
	} else {
		return '';
	}
};

const DeletionFilterAutocompleteTextField = ({
	type,
	value,
	items,
	isFetching,
	isError,
	workspaceName,
	noItemInWorkspace,
	onChange,
}: DeletionFilterAutocompleteTextFieldProps) => {
	const { t } = useTranslation('workspace', { keyPrefix: `deletionPolicies.create.filter.${type}` });
	const [invalid, setInvalid] = useState(false);
	const tooltip = getTooltipMessage(t, noItemInWorkspace, items, workspaceName);
	const disabled = isFetching || isError || items.length === 0;

	return (
		<FormControl fullWidth required>
			<Autocomplete<string>
				loading={isFetching}
				disabled={disabled}
				loadingText={t('loading')}
				blurOnSelect
				value={disabled ? null : value}
				options={items.map((item) => item.id)}
				getOptionLabel={(option) => items.find((item) => item.id === option)?.name ?? option}
				noOptionsText={t('empty')}
				onChange={(_, value) => {
					setInvalid(value === null || value === undefined || value === '');
					onChange(value ?? undefined);
				}}
				renderInput={(params) => (
					<Tooltip title={tooltip} arrow>
						<TextField
							sx={{ marginTop: 1 }}
							{...params}
							label={t('placeholder')}
							required
							size='small'
							error={invalid}
							helperText={invalid && t('errorMessages.empty')}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{isFetching && <CircularProgress color='inherit' size={20} />}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					</Tooltip>
				)}
			/>
			{isError && <FormHelperText error>{t('loadingError')}</FormHelperText>}
		</FormControl>
	);
};

export { DeletionFilterAutocompleteTextField, DeletionFilterAutocompleteTextFieldProps };

import {
	DashboardUserPathElementPercentilesSeriesStatisticRead,
	DashboardUserPathElementTimeSeriesStatisticRead,
	ElementsTimeseriesFilterRead,
	MonitorElement,
	UserPathElementStatistic,
	DashboardMonitorElementTimeSeriesStatisticRead,
} from '../../nlw-api-v4';

type CommonUnit = ElementsTimeseriesFilterRead['statistic']['xAxisUnit'];

const USER_LOAD_UNIT = 'USER_LOAD';

const COMMON_UNITS = new Set<CommonUnit | typeof USER_LOAD_UNIT>([
	'BITS_PER_SECOND',
	'ELEMENTS',
	'ELEMENTS_PER_SECOND',
	'EMPTY',
	'ERRORS',
	'ERRORS_PER_SECOND',
	'PERCENTAGE',
	'SECONDS',
	USER_LOAD_UNIT,
]);

export const isCommonUnit = (unit: string): unit is CommonUnit => COMMON_UNITS.has(unit as CommonUnit);

export const getDashboardStatisticForUserPathElementStatistic = (
	statistic: UserPathElementStatistic,
):
	| (DashboardUserPathElementTimeSeriesStatisticRead & { type: 'TIMESERIES' })
	| (DashboardUserPathElementPercentilesSeriesStatisticRead & { type: 'PERCENTILES' }) => {
	if (statistic === 'DURATION_PERCENTILES') {
		return { name: statistic, type: 'PERCENTILES', xAxisUnit: 'PERCENTAGE', yAxisUnit: 'SECONDS' };
	}
	switch (statistic) {
		case 'AVERAGE_DURATION':
		case 'MAXIMUM_DURATION':
		case 'MINIMUM_DURATION':
		case 'AVERAGE_TTFB':
		case 'MAXIMUM_TTFB':
		case 'MINIMUM_TTFB': {
			return { name: statistic, xAxisUnit: 'SECONDS', yAxisUnit: 'SECONDS', type: 'TIMESERIES' };
		}
		case 'ELEMENT_COUNT': {
			return { name: statistic, xAxisUnit: 'SECONDS', yAxisUnit: 'ELEMENTS', type: 'TIMESERIES' };
		}
		case 'ELEMENTS_PER_SECOND': {
			return { name: statistic, xAxisUnit: 'SECONDS', yAxisUnit: 'ELEMENTS_PER_SECOND', type: 'TIMESERIES' };
		}
		case 'THROUGHPUT': {
			return { name: statistic, xAxisUnit: 'SECONDS', yAxisUnit: 'BITS_PER_SECOND', type: 'TIMESERIES' };
		}
		case 'ERROR_COUNT': {
			return { name: statistic, xAxisUnit: 'SECONDS', yAxisUnit: 'ERRORS', type: 'TIMESERIES' };
		}
		case 'ERRORS_PER_SECOND': {
			return { name: statistic, xAxisUnit: 'SECONDS', yAxisUnit: 'ERRORS_PER_SECOND', type: 'TIMESERIES' };
		}
		case 'ERROR_RATE': {
			return { name: statistic, xAxisUnit: 'SECONDS', yAxisUnit: 'PERCENTAGE', type: 'TIMESERIES' };
		}
		default: {
			return { name: statistic, xAxisUnit: 'SECONDS', yAxisUnit: 'EMPTY', type: 'TIMESERIES' };
		}
	}
};

export const getDashboardStatisticForMonitorElement = (
	monitor: MonitorElement,
): DashboardMonitorElementTimeSeriesStatisticRead => {
	if (monitor.unit === USER_LOAD_UNIT) {
		return {
			name: 'STEP',
			xAxisUnit: 's',
			yAxisUnit: '',
		};
	}
	return {
		name: 'AVERAGE',
		xAxisUnit: 's',
		yAxisUnit: monitor.unit ?? '',
	};
};

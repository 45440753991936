import { useEffect, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import { FormProvider, useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { AllocateDedicatedIpsForm } from './allocate-dedicated-ips-form';
import { Spinner } from '../../../../common/spinner';
import {
	CloudZoneRead,
	useGetV4SubscriptionQuery,
	useGetV4ZonesQuery,
	usePatchV4ZonesByZoneIdMutation,
	ZonePageRead,
} from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

const ALLOCATE_DEDICATED_IPS_DIALOG_SNACKBAR_ID = 'ALLOCATE_DEDICATED_IPS_DIALOG_SNACKBAR_ID';

export type AllocateDedicatedIpsModalProps = {
	open: boolean;
	initialCloudZoneId?: string;
	handleClose: () => void;
	handleConfirm: (error: boolean, message: string) => void;
};

export type Form = {
	zoneId: string;
	reservedIpsCount: number;
	modificationCostInCloudCredits: number;
};
const initialFormValues: Form = {
	zoneId: '',
	reservedIpsCount: 0,
	modificationCostInCloudCredits: 0,
};
const AllocateDedicatedIpsModal = ({
	open = false,
	initialCloudZoneId,
	handleClose,
	handleConfirm,
}: AllocateDedicatedIpsModalProps) => {
	const { t } = useTranslation(['zone']);

	const { showError } = useSetSnackbars();

	const {
		data: subscriptionData,
		error: subscriptionError,
		isLoading: isSubscriptionLoading,
	} = useGetV4SubscriptionQuery({ fields: ['cloudCredits'] }, { pollingInterval: 10_000 });
	const { data: zonesData, error: zonesError, isLoading: isZonesLoading } = useGetV4ZonesQuery({ type: 'CLOUD' });
	const [patchZone, patchZoneResult] = usePatchV4ZonesByZoneIdMutation();
	const availableCloudCredits = subscriptionData?.cloudCredits?.available ?? 0;
	const cloudZones = useMemo(() => mapToCloudZones(zonesData), [zonesData]);
	const [openConfirmRelease, setOpenConfirmRelease] = useState(false);
	const { handleSubmit, reset, formState, watch, setValue, setError, clearErrors, ...methods } = useForm<Form>({
		mode: 'onChange',
		defaultValues: initialFormValues,
	});
	const zoneId = watch('zoneId');
	const reservedIpsCount = watch('reservedIpsCount');
	const modificationCostInCloudCredits = watch('modificationCostInCloudCredits');

	async function applyPatchZone() {
		if (modificationCostInCloudCredits < 0) {
			setOpenConfirmRelease(true);
		} else {
			await doPatch();
		}
	}
	async function doPatch() {
		setOpenConfirmRelease(false);
		if (zoneId && reservedIpsCount >= 0) {
			await patchZone({
				zoneId: zoneId,
				dryRun: false,
				patchZoneFields: {
					type: 'CLOUD',
					dedicatedIpsDetails: {
						reservedIpsCount: reservedIpsCount,
					},
				},
			}).then((response) => {
				if (response.error) {
					handleConfirm(true, 'cloudZone.dedicatedIps.allocateFailed');
				} else if (
					response?.data.type === 'CLOUD' &&
					response?.data.dedicatedIpsDetails?.modificationCostInCloudCredits
				) {
					const isReleaseIps = response.data.dedicatedIpsDetails?.modificationCostInCloudCredits < 0;
					handleConfirm(false, isReleaseIps ? 'cloudZone.dedicatedIps.released' : 'cloudZone.dedicatedIps.allocated');
				}
			});
		}
	}

	useEffect(() => {
		reset(toDefaultValues(cloudZones, initialCloudZoneId));
	}, [reset, initialCloudZoneId, cloudZones]);

	useEffect(() => {
		if (subscriptionError) {
			handleClose();
			showError({
				id: ALLOCATE_DEDICATED_IPS_DIALOG_SNACKBAR_ID,
				text: t('cloudZone.cloudCreditsUnavailable'),
				autoHideDuration: 10_000,
			});
		} else if (zonesError) {
			handleClose();
			showError({
				id: ALLOCATE_DEDICATED_IPS_DIALOG_SNACKBAR_ID,
				text: t('cloudZone.cloudZonesUnavailable'),
				autoHideDuration: 10_000,
			});
		}
	}, [handleClose, showError, subscriptionError, t, zonesError]);

	function closeConfirmDialog() {
		setOpenConfirmRelease(false);
		reset();
	}
	const isLoading = isSubscriptionLoading || isZonesLoading;

	return (
		<FormProvider
			{...methods}
			handleSubmit={handleSubmit}
			formState={formState}
			setValue={setValue}
			setError={setError}
			clearErrors={clearErrors}
			watch={watch}
			reset={reset}
		>
			<Dialog open={open} onClose={handleClose} fullWidth>
				<DialogTitle>{t('cloudZone.dedicatedIps.allocationDialog.title')}</DialogTitle>
				<DialogContent>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: (theme) => theme.spacing(2),
							marginTop: 1,
							width: '100%',
						}}
					>
						{isLoading ? (
							<Spinner />
						) : (
							<AllocateDedicatedIpsForm cloudZones={cloudZones} availableCloudCredits={availableCloudCredits} />
						)}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color='primary'
						data-testid='cancel-button'
						data-trackingid='allocate-dedicated-ips-cancel'
					>
						{t('common:cancel')}
					</Button>
					<Button
						onClick={applyPatchZone}
						variant='contained'
						data-testid='create-and-configure-button'
						disabled={!formState.isValid || patchZoneResult.isLoading || modificationCostInCloudCredits === 0}
						data-trackingid='allocate-dedicated-ips-ok'
					>
						{modificationCostInCloudCredits < 0
							? t('cloudZone.dedicatedIps.allocationDialog.release', {
									count: getIpCountToReleaseByZoneId(cloudZones, zoneId, reservedIpsCount),
								})
							: t('cloudZone.dedicatedIps.allocationDialog.allocate')}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={openConfirmRelease}>
				<DialogTitle>{t('cloudZone.dedicatedIps.allocationDialog.confirmRelease.title')}</DialogTitle>
				<DialogContent>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: (theme) => theme.spacing(2),
							marginTop: 1,
							width: 500,
						}}
					>
						<Typography>{t('cloudZone.dedicatedIps.allocationDialog.confirmRelease.text')}</Typography>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={closeConfirmDialog}
						color='primary'
						data-testid='cancel-button'
						data-trackingid='allocate-dedicated-ips-release-cancel'
					>
						{t('common:cancel')}
					</Button>
					<Button
						onClick={doPatch}
						variant='contained'
						data-testid='create-and-configure-button'
						color='error'
						data-trackingid='allocate-dedicated-ips-release-ok'
					>
						{t('cloudZone.dedicatedIps.allocationDialog.release', {
							count: getIpCountToReleaseByZoneId(cloudZones, zoneId, reservedIpsCount),
						})}
					</Button>
				</DialogActions>
			</Dialog>
		</FormProvider>
	);
};

export { AllocateDedicatedIpsModal };

function mapToCloudZones(zonePage: ZonePageRead | undefined): CloudZoneRead[] {
	if (!zonePage) {
		return [];
	}
	return zonePage.items.map((zone) => {
		if (zone.type === 'CLOUD') {
			return zone;
		}
		throw new Error(`Unexpected type ${zone.type} for Zone with ID ${zone.zoneId}`);
	});
}

function getZoneById(cloudZones: CloudZoneRead[], zoneId: string): CloudZoneRead | undefined {
	return cloudZones.find((cloudZone) => cloudZone.zoneId === zoneId);
}
function getIpCountToReleaseByZoneId(cloudZones: CloudZoneRead[], zoneId: string, reservedIpsCount: number): number {
	const zone = getZoneById(cloudZones, zoneId);
	if (zone?.dedicatedIpsDetails && reservedIpsCount >= 0) {
		return zone.dedicatedIpsDetails?.reservedIpsCount - reservedIpsCount;
	}
	return 0;
}
const toDefaultValues = (cloudZones: CloudZoneRead[], initialCloudZoneId: string | undefined): Form => {
	const initialCloudZone = initialCloudZoneId
		? cloudZones.find((cloudZone) => cloudZone.zoneId === initialCloudZoneId)
		: cloudZones.at(0);
	if (initialCloudZone) {
		return {
			zoneId: initialCloudZone.zoneId,
			reservedIpsCount: initialCloudZone.dedicatedIpsDetails?.reservedIpsCount ?? 0,
			modificationCostInCloudCredits: 0,
		};
	}
	return initialFormValues;
};

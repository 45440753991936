import { useTranslation } from 'react-i18next';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { DatagridAction } from '../../../common/data-grid/actions/data-grid-actions';
import { Token } from '@neoload/api';

export type VisibilityTokenActionProps = {
	displayedTokenRows: GridRowSelectionModel;
	setDisplayedTokenRows: (tokens: GridRowSelectionModel) => void;
	items: Token[];
	isAllSelectedTokensVisibles: boolean;
	selectedAccessTokenIds: GridRowSelectionModel;
};

export const useVisibilityTokenAction = ({
	displayedTokenRows,
	setDisplayedTokenRows,
	items,
	isAllSelectedTokensVisibles,
	selectedAccessTokenIds,
}: VisibilityTokenActionProps): DatagridAction => {
	const { t } = useTranslation(['user']);

	const displayAccessTokens = (tokens: GridRowSelectionModel) => {
		setDisplayedTokenRows(
			items.map(({ token }) => token).filter((t) => displayedTokenRows.includes(t) || tokens.includes(t)),
		);
	};
	const hideAccessTokens = (tokens: GridRowSelectionModel) => {
		setDisplayedTokenRows(displayedTokenRows.filter((t) => !tokens.includes(t)));
	};
	const onToggleDisplayAccessToken = (token: string) => {
		if (displayedTokenRows.includes(token)) {
			hideAccessTokens([token]);
		} else {
			displayAccessTokens([token]);
		}
	};

	const visibility = {
		icon: isAllSelectedTokensVisibles ? <VisibilityOffOutlined /> : <VisibilityOutlined />,
		text: t(`token.${isAllSelectedTokensVisibles ? 'hide' : 'show'}`),
		disabled: selectedAccessTokenIds.length === 0,
		action: () => {
			if (isAllSelectedTokensVisibles) {
				hideAccessTokens(selectedAccessTokenIds);
			} else {
				displayAccessTokens(selectedAccessTokenIds);
			}
		},
		singleItem: (token: string) => ({
			icon: displayedTokenRows.includes(token) ? <VisibilityOffOutlined /> : <VisibilityOutlined />,
			text: t(`token.${displayedTokenRows.includes(token) ? 'hide' : 'show'}`),
			disabled: false,
			action: () => {
				onToggleDisplayAccessToken(token);
			},
		}),
	};

	return visibility;
};

import './datagrid-drawer.css';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { PropsWithChildren, useState } from 'react';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { Resizable } from 'react-resizable';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

type DatagridDrawerProps = {
	onClose: () => void;
	title: string;
	minWidth: number;
};

const DatagridDrawer = ({ onClose, title, children, minWidth }: PropsWithChildren<DatagridDrawerProps>) => {
	const { t } = useTranslation(['common']);
	const [width, setWidth] = useState(600);

	return (
		<Resizable
			axis='x'
			width={width}
			onResize={(_event, { size }) => setWidth(size.width)}
			handle={<span className='resize-datagrid-details-handle' />}
			resizeHandles={['w']}
			minConstraints={[minWidth, 0]}
			data-testid='datagrid-drawer'
		>
			<Grid
				container
				direction='column'
				sx={(theme) => ({
					width: width,
					height: '100%',
					borderLeft: `2px solid ${theme.palette.divider}`,
					flexWrap: 'nowrap',
				})}
			>
				<Grid container padding={2} paddingBottom={1} justifyContent='space-between' alignItems='center'>
					<Typography variant='h6'>{title}</Typography>
					<Tooltip arrow title={t('close')}>
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</Tooltip>
				</Grid>
				{children}
			</Grid>
		</Resizable>
	);
};

export { DatagridDrawer };

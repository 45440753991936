import { InfrastructureProvider } from '@neoload/api';

type InfrastructureProviderTypeDisplay = {
	[key in InfrastructureProvider['type']]: string;
};

export const INFRASTRUCTURE_PROVIDER_TYPE_DISPLAY_NAMES: InfrastructureProviderTypeDisplay = {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	OPENSHIFT: 'Openshift',
	// eslint-disable-next-line @typescript-eslint/naming-convention
	KUBERNETES: 'Kubernetes',
};

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import React, { useRef } from 'react';
import ZeroState from '@tricentis/aura/components/ZeroState.js';
import GraphicsZeroStateBackground from '@tricentis/aura/components/GraphicsZeroStateBackground.js';
import GraphicsSettings from '@tricentis/aura/components/GraphicsSettings.js';
import Button from '@mui/material/Button';
import { InputLicenseFile, LeasesDatagrid, LicenseDetails, LicenseManagementMenu, Spinner } from '@neoload/ui';
import { useDocumentTitleHandler, useFeatureEnabled, useGetMe, useSetSnackbars } from '@neoload/hooks';
import { isFetchBaseQueryError, useGetV4LicenseQuery, usePostV4LicenseMutation } from '@neoload/api';

const LicensePage = () => {
	const { t } = useTranslation(['license']);
	const { data: licenseInfos, error: licenseError, isLoading } = useGetV4LicenseQuery();
	const [installLicense] = usePostV4LicenseMutation();
	const { showInfo, showError } = useSetSnackbars();
	const inputFile = useRef<HTMLInputElement>(null);
	const [{ isFeatureEnabled: subscriptionLicenseFeatureEnabled }] = useFeatureEnabled(
		'subscription-license-in-front-next-gen',
	);
	const [{ hasAdminPermissions }] = useGetMe();
	useDocumentTitleHandler(t('title'));

	const onInstallLicense = () => {
		if (inputFile.current) {
			inputFile.current.click();
		}
	};

	const installNewLicense = (file: Blob) => {
		installLicense({
			body: { file: file },
		})
			.unwrap()
			.then((updatedLicense) => {
				showInfo({
					text:
						updatedLicense.licenseStatus === 'ACTIVE'
							? t('license.messages.licenseActiveInstalled')
							: t('license.messages.licenseInstalled'),
				});
			})
			.catch(() => {
				showError({ text: t('license.messages.error') });
			});
	};

	const onChangeLicense = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = (event.target as HTMLInputElement).files;
		if (files?.[0]) {
			installNewLicense(files[0]);
		}
	};

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Box height='100%' width='100%' sx={{ display: 'flex', flexDirection: 'column' }}>
			{isFetchBaseQueryError(licenseError) && licenseError.status === 404 ? (
				<Stack height='100%' justifyContent='center'>
					<ZeroState
						actions={
							<Button variant='outlined' onClick={onInstallLicense} size='small'>
								{t('installLicense')}
							</Button>
						}
						illustration={
							<>
								<GraphicsZeroStateBackground />
								<Box position='absolute'>
									<GraphicsSettings />
								</Box>
							</>
						}
						containerSx={{ backgroundColor: 'transparent' }}
						title={t('zeroState.title')}
					>
						{t('zeroState.message')}
					</ZeroState>
				</Stack>
			) : (
				<>
					<Stack sx={{ position: 'sticky' }} direction='row' justifyContent='space-between'>
						<Typography variant='subtitle1' p={2}>
							{t('title')}
						</Typography>
						{subscriptionLicenseFeatureEnabled && (
							<LicenseManagementMenu
								licenseInfos={licenseInfos}
								installNewLicense={installNewLicense}
								onInstallLicense={onInstallLicense}
							/>
						)}
					</Stack>
					<Divider />
					<Box sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
						{subscriptionLicenseFeatureEnabled &&
							(licenseInfos === undefined ? (
								<Typography color='error'>{t('license.messageError')}</Typography>
							) : (
								<LicenseDetails licenseInfos={licenseInfos} />
							))}
						<Divider />
						<Box sx={{ flexGrow: 1 }}>
							<LeasesDatagrid isAdmin={hasAdminPermissions} />
						</Box>
					</Box>
				</>
			)}
			<InputLicenseFile onChange={onChangeLicense} ref={inputFile} />
		</Box>
	);
};

export { LicensePage };

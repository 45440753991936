import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../../../common/spinner';

type LoadingContentModalProps = {
	close: () => void;
	labelSubmit: string;
};
const LoadingContentModal = ({ close, labelSubmit }: LoadingContentModalProps) => {
	const { t } = useTranslation(['reservation']);

	return (
		<>
			<DialogContent sx={{ width: '600px' }}>
				<Box sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '40px' }}>
					<Spinner />
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={close} color='primary'>
					{t('common:cancel')}
				</Button>
				<Button disabled variant='contained'>
					{labelSubmit}
				</Button>
			</DialogActions>
		</>
	);
};

export { LoadingContentModal };

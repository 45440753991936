import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { ZoneIdWithCopyButton } from './zone-id-with-copy-button';
import { getPartialErrorCloudZone } from './zone-helpers';
import { AllocateDedicatedIpsModal } from './cloud/dedicated-ips';
import { LabelledItem } from '../../common/labelled-item';
import { Spinner } from '../../common/spinner';
import { CloudZoneRead, useGetV4ZonesByZoneIdQuery, Zone, ZoneResource } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

const CLIPBOARD_SUCCESS_SNACKBAR_ID = 'CLIPBOARD_SUCCESS_SNACKBAR_ID';

type ZoneDetailsProps = {
	zoneId: string;
};

const getProvider = (zone: Zone): string | undefined => {
	switch (zone.type) {
		case 'CLOUD': {
			return zone.cloudProvider;
		}
		case 'DYNAMIC': {
			return zone.provider.name;
		}
		case 'STATIC':
		default: {
			return undefined;
		}
	}
};

const ZoneDetails = ({ zoneId }: ZoneDetailsProps) => {
	const { t } = useTranslation(['zone']);
	const {
		data,
		error: zoneError,
		isLoading,
	} = useGetV4ZonesByZoneIdQuery(
		{ zoneId },
		{
			pollingInterval: 10_000,
		},
	);
	const { showInfo, showError } = useSetSnackbars();
	const partialCloudZoneError = zoneError ? getPartialErrorCloudZone(zoneError) : undefined;
	const zone = partialCloudZoneError ?? data;
	const [openAllocationDialog, setOpenAllocationDialog] = useState(false);
	const [preselectedZone, setPreselectedZone] = useState<CloudZoneRead | undefined>(undefined);

	function handleOpenDialog() {
		setOpenAllocationDialog(true);
	}

	function handleCloseDialog() {
		setOpenAllocationDialog(false);
	}

	const copyToClipboard = (ipAddresses: string[]) =>
		navigator.clipboard
			.writeText(JSON.stringify(ipAddresses))
			.then(() => {
				showInfo({ text: t('actions.copyIpAddressesToClipboardSuccess'), id: CLIPBOARD_SUCCESS_SNACKBAR_ID });
			})
			.catch((error) => console.error('Unable to copy IP addresses to clipboard.', error));

	function handleConfirm(error: boolean, message: string) {
		setOpenAllocationDialog(false);
		if (error) {
			showError({ text: t(message) });
		} else {
			showInfo({ text: t(message) });
		}
	}

	const getLabels = (resources: ZoneResource[]): string[] =>
		resources.length > 0
			? resources.map(
					(resource) => t('details.resourceName', resource) + t('details.resourceStatus.' + resource.status),
				)
			: [t('details.noResource')];

	if (isLoading) {
		return <Spinner />;
	}

	const error = zoneError && !partialCloudZoneError;

	if (!zone || error) {
		return (
			<Box padding={2}>
				<Alert severity='error'>{t('details.fetchFailed')}</Alert>
			</Box>
		);
	}

	const provider = getProvider(zone);

	return (
		<Box padding={2}>
			{zone.type === 'CLOUD' && zone.dedicatedIpsDetails && (
				<Stack direction='row' spacing={1} mb={2}>
					{zone.dedicatedIpsDetails.reservedIpsCount !== 0 && (
						<Button
							variant='outlined'
							size='small'
							onClick={() => void copyToClipboard(zone.dedicatedIpsDetails?.ipAddresses ?? [])}
							disabled={!zone.dedicatedIpsDetails}
						>
							{t('cloudZone.dedicatedIps.copyIps')}
						</Button>
					)}
					<Button
						variant='outlined'
						size='small'
						onClick={() => {
							setPreselectedZone(zone);
							handleOpenDialog();
						}}
						disabled={!zone.dedicatedIpsDetails || zone.dedicatedIpsDetails?.status !== 'READY'}
					>
						{t('cloudZone.dedicatedIps.editIps')}
					</Button>
				</Stack>
			)}
			<LabelledItem id='zone-name' value={zone.name} title={t('columns.name')} />
			{provider && <LabelledItem id='zone-provider' value={provider} title={t('columns.providerName')} />}
			<LabelledItem id='zone-id' value={<ZoneIdWithCopyButton zoneId={zone.zoneId} />} title={t('columns.zoneId')} />
			<LabelledItem id='zone-lgs' value={getLabels(zone.loadGenerators)} title={t('columns.lgs')} />
			<LabelledItem id='zone-controllers' value={getLabels(zone.controllers)} title={t('columns.controllers')} />
			{zone.type === 'CLOUD' && (
				<>
					<LabelledItem
						id='dedicated-ips'
						value={zone.dedicatedIpsDetails?.reservedIpsCount}
						title={t('columns.reservedIpsCount')}
					/>
					<LabelledItem
						id='total-ips'
						value={
							(zone.dedicatedIpsDetails?.reservedIpsCount ?? 0) + (zone.dedicatedIpsDetails?.availableIpsCount ?? 0)
						}
						title={t('columns.totalIps')}
					/>
				</>
			)}
			{openAllocationDialog && (
				<AllocateDedicatedIpsModal
					open={openAllocationDialog}
					handleClose={handleCloseDialog}
					handleConfirm={handleConfirm}
					initialCloudZoneId={preselectedZone?.zoneId}
				/>
			)}
		</Box>
	);
};

export { ZoneDetails };

import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import {
	GetV4DashboardsByDashboardIdPdfApiArg as GetV4DashboardsByDashboardIdPdfApiArgument,
	GetV4DashboardsByDashboardIdPdfApiResponse,
	GetV4LicenseLeasesByLeaseIdentifierApiArg as GetV4LicenseLeasesByLeaseIdentifierApiArgument,
	GetV4LicenseLeasesByLeaseIdentifierApiResponse,
	nlwApiV4,
	PostV4LicenseActivationRequestsApiArg as PostV4LicenseActivationRequestsApiArgument,
	PostV4LicenseActivationRequestsApiResponse,
	PostV4LicenseApiArg as PostV4LicenseApiArgument,
	PostV4LicenseApiResponse,
	PostV4LicenseDeactivationRequestsApiArg as PostV4LicenseDeactivationRequestsApiArgument,
} from './nlw-api-v4';

type GetV4DashboardsByDashboardIdPdfApiResponseOverride = {
	fileContent: GetV4DashboardsByDashboardIdPdfApiResponse;
	fileName: string | null;
};
type GetV4LicenseLeasesByLeaseIdentifierApiResponseOverride = {
	fileContent: GetV4LicenseLeasesByLeaseIdentifierApiResponse;
	fileName: string | null;
};
type PostV4LicenseActivationRequestsApiResponseOverride = {
	fileContent: PostV4LicenseActivationRequestsApiResponse;
	fileName: string | null;
};
type PostV4LicenseDeactivationRequestsApiResponseOverride = {
	fileContent: PostV4LicenseActivationRequestsApiResponse;
	fileName: string | null;
};
const fileNameRegex = /filename=(.*)/;
const extractFileNameFromHeaders = (headers?: Headers): string | null => {
	const contentDispositionHeader = headers?.get('content-disposition');
	if (contentDispositionHeader) {
		const matchFileName = fileNameRegex.exec(contentDispositionHeader);
		if (matchFileName) {
			return matchFileName[1];
		}
	}
	return null;
};
const toFileResponse = (response: Blob, meta?: FetchBaseQueryMeta) => ({
	fileContent: response,
	fileName: extractFileNameFromHeaders(meta?.response?.headers),
});

export const overriddenApi = nlwApiV4.injectEndpoints({
	endpoints: (builder) => ({
		getV4DashboardsByDashboardIdPdf: builder.query<
			GetV4DashboardsByDashboardIdPdfApiResponseOverride,
			GetV4DashboardsByDashboardIdPdfApiArgument
		>({
			query(args) {
				return {
					url: `/v4/dashboards/${args.dashboardId}/pdf`,
					params: { locale: args.locale, timezone: args.timezone },
					method: 'GET',
					responseHandler: async (response) => await response.blob(),
					cache: 'no-cache',
				};
			},
			transformResponse: toFileResponse,
		}),
		getV4LeasesByLeaseIdentifierFile: builder.query<
			GetV4LicenseLeasesByLeaseIdentifierApiResponseOverride,
			GetV4LicenseLeasesByLeaseIdentifierApiArgument
		>({
			query(args) {
				return {
					url: `/v4/license/leases/${encodeURIComponent(args.leaseIdentifier)}`,
					method: 'GET',
					responseHandler: async (response) => await response.blob(),
					cache: 'no-cache',
				};
			},
			transformResponse: toFileResponse,
		}),
		postV4License: builder.mutation<PostV4LicenseApiResponse, PostV4LicenseApiArgument>({
			query(args) {
				const formData = new FormData();
				formData.append('file', args.body.file, 'license.lic');

				return {
					url: '/v4/license',
					method: 'POST',
					body: formData,
				};
			},
		}),
		postV4LicenseActivationRequestsOverride: builder.mutation<
			PostV4LicenseActivationRequestsApiResponseOverride,
			PostV4LicenseActivationRequestsApiArgument
		>({
			query() {
				return {
					url: '/v4/license/activation-requests',
					method: 'POST',
					responseHandler: async (response) => await response.blob(),
					cache: 'no-cache',
				};
			},
			transformResponse: toFileResponse,
		}),
		postV4LicenseDeactivationRequestsOverride: builder.mutation<
			PostV4LicenseDeactivationRequestsApiResponseOverride,
			PostV4LicenseDeactivationRequestsApiArgument
		>({
			query() {
				return {
					url: '/v4/license/deactivation-requests',
					method: 'POST',
					responseHandler: async (response) => await response.blob(),
					cache: 'no-cache',
				};
			},
			transformResponse: toFileResponse,
		}),
	}),
	overrideExisting: true,
});

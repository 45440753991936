import { useTranslation } from 'react-i18next';
import { useGetV4LicenseQuery } from '@neoload/api';
import { isNotFoundError } from '@neoload/utils';

export const useGetLicenseWarningMessage = (isAdmin: boolean) => {
	const { t } = useTranslation('workspace', { keyPrefix: 'tabs.subscription' });
	const { data: license, error } = useGetV4LicenseQuery(undefined, { skip: !isAdmin });

	if (license && license.licenseStatus !== 'ACTIVE') {
		return t(`licenseStatus.${license.licenseStatus}`);
	}
	if (error) {
		if (isNotFoundError(error)) {
			return t('licenseStatus.licenseNotFound');
		} else {
			console.error(error);
			return t('licenseStatus.fetchLicenseError');
		}
	}
	return undefined;
};

import Stack from '@mui/material/Stack';
import { InfrastructureProviderDataGrid } from '@neoload/ui';
import { useGetV4InfrastructureProvidersQuery } from '@neoload/api';
import { createNeoLoadError } from '@neoload/utils';

const InfrastructureProviderPage = () => {
	const { data: providers, error, isLoading } = useGetV4InfrastructureProvidersQuery();

	if (error) {
		throw createNeoLoadError(error);
	}

	return (
		<Stack height='100%' width='100%'>
			<InfrastructureProviderDataGrid providers={providers?.items} isLoading={isLoading} />
		</Stack>
	);
};

export { InfrastructureProviderPage };

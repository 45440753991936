import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import saveAs from 'file-saver';
import { CreateOfflineLeaseModalContent } from './create-offline-lease-modal-content';
import {
	PostOfflineLease,
	useLazyGetV4LeasesByLeaseIdentifierFileQuery,
	usePostV4LicenseLeasesMutation,
} from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';
import {
	invalidPropertyErrorMatches,
	isFieldUniquenessError,
	isNotFoundError,
	isV4InvaliPropertyError,
} from '@neoload/utils';

const getDefaultOfflineLease = (workspaceId: string, maxConcurrentRuns?: number): PostOfflineLease => ({
	clientId: '',
	concurrentRuns: maxConcurrentRuns === undefined ? 1 : 0,
	description: '',
	duration: 'PT1H',
	sapVus: 0,
	webVus: 0,
	workspaceId: workspaceId,
});

type CreateOfflineLeaseFormModalProps = {
	opened: boolean;
	close: () => void;
	workspaceId: string;
	maxWebVus: number;
	maxSapVus: number;
	maxConcurrentRuns?: number;
	licenseExpirationDate?: string;
};

export const CreateOfflineLeaseFormModal = ({
	opened,
	close,
	workspaceId,
	maxWebVus,
	maxSapVus,
	maxConcurrentRuns,
	licenseExpirationDate,
}: CreateOfflineLeaseFormModalProps) => {
	const { t } = useTranslation(['workspace']);
	const { showError, showInfo } = useSetSnackbars();

	const { formState, handleSubmit, reset, watch, ...methods } = useForm<PostOfflineLease>({
		mode: 'onChange',
		defaultValues: getDefaultOfflineLease(workspaceId, maxConcurrentRuns),
	});

	const [createOfflineLease, { isLoading: isCreating }] = usePostV4LicenseLeasesMutation();
	const [downloadOfflineLease] = useLazyGetV4LeasesByLeaseIdentifierFileQuery();

	const [webVus, sapVus] = watch(['webVus', 'sapVus']);

	const closeAndReset = () => {
		close();
		reset(getDefaultOfflineLease(workspaceId));
	};

	const submitForm = async (form: PostOfflineLease) => {
		await createOfflineLease({ postOfflineLease: form })
			.unwrap()
			.then(async (offlineLease) => {
				showInfo({ text: t('leases.sucessFullyCreated') });
				closeAndReset();
				await downloadOfflineLease({ leaseIdentifier: offlineLease.id })
					.unwrap()
					.then((response) => {
						saveAs(response.fileContent, response.fileName ?? 'lease.rsp');
						showInfo({ text: t('leases.downloadSuccess', { fileName: response.fileName }) });
					})
					.catch((error) => {
						console.error(error);
						showError({ text: t('leases.downloadError') });
					});
			})
			.catch((error) => {
				if (isNotFoundError(error)) {
					showError({ text: t('leases.noValidLicenseError') });
					return;
				} else if (isV4InvaliPropertyError(error)) {
					if (invalidPropertyErrorMatches(error.data, 'format', { property: 'clientId' })) {
						showError({ text: t('leases.clientIdFormatError') });
						return;
					} else if (invalidPropertyErrorMatches(error.data, 'data', { property: 'clientId' })) {
						showError({ text: t('leases.currentClientIdError') });
						return;
					} else if (invalidPropertyErrorMatches(error.data, 'data', { properties: 'webVus,sapVus', bound: 'lower' })) {
						showError({ text: t('leases.vusTooLowError') });
						return;
					} else if (invalidPropertyErrorMatches(error.data, 'data', { properties: 'webVus,sapVus', bound: 'upper' })) {
						showError({ text: t('leases.vusTooHighError') });
						return;
					} else if (invalidPropertyErrorMatches(error.data, 'data', { property: 'concurrentRuns' })) {
						showError({ text: t('leases.concurrentCountError') });
						return;
					} else if (invalidPropertyErrorMatches(error.data, 'data', { property: 'duration' })) {
						showError({ text: t('leases.licenseWillBeExpiredError') });
						return;
					}
				} else if (isFieldUniquenessError(error)) {
					showError({ text: t('leases.clientIdAlreadyInUse') });
					return;
				}
				showError({ text: t('leases.createOfflineLeaseError') });
			});
	};

	//Individually, webVus and sapVus can be 0 so fields would not be in error, but we need at least one vu of any kind to validate the form
	const hasAtLeastOneVu = webVus > 0 || sapVus > 0;

	return (
		<Dialog open={opened} onClose={closeAndReset}>
			<FormProvider {...methods} formState={formState} handleSubmit={handleSubmit} reset={reset} watch={watch}>
				<form
					aria-label={t('leases.createOfflineLeaseTitle')}
					onSubmit={handleSubmit(submitForm)}
					onReset={() => {
						closeAndReset();
					}}
				>
					<DialogTitle variant='h1'>{t('leases.createOfflineLeaseTitle')}</DialogTitle>
					<DialogContent sx={{ width: '600px' }}>
						<CreateOfflineLeaseModalContent
							maxWebVus={maxWebVus}
							maxSapVus={maxSapVus}
							maxConcurrentRuns={maxConcurrentRuns}
							licenseExpirationDate={licenseExpirationDate}
						/>
					</DialogContent>
					<DialogActions>
						<Button type='reset' variant='text' data-trackingid='create-offline-lease-cancel'>
							{t('common:cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							startIcon={isCreating ? <CircularProgress size={24.5} color='inherit' /> : null}
							disabled={
								!formState.isValid || Object.keys(formState.errors).length > 0 || isCreating || !hasAtLeastOneVu
							}
							data-trackingid='create-offline-lease-ok'
						>
							{t('common:save')}
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
};

import { useTranslation } from 'react-i18next';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { DatagridAction } from '../../../common/data-grid/actions/data-grid-actions';
import { DeletionPolicyRead, useDeleteV4DeletionPoliciesByDeletionPolicyIdMutation } from '@neoload/api';
import { useConfirmModal, useCrudBatchMessage } from '@neoload/hooks';

export const usePoliciesDatagridActions = (
	selectedId: GridRowSelectionModel,
	openEditModal: (policyId: string) => void,
): DatagridAction[] => {
	const { openDeleteConfirmModal } = useConfirmModal();
	const { remove } = useCrudBatchMessage('workspace', 'tabs.deletionPolicies.crud');
	const [deletePolicy] = useDeleteV4DeletionPoliciesByDeletionPolicyIdMutation();
	const { t } = useTranslation('workspace');

	const deleteRow = async (policyToDelete: string) => {
		await remove([policyToDelete], (policyId) => deletePolicy({ deletionPolicyId: policyId }).unwrap());
	};

	const openConfirmationPolicyDialog = (policyId: string) => {
		const title = t('tabs.deletionPolicies.deleteDialog.title');
		const content = t('tabs.deletionPolicies.deleteDialog.label');
		openDeleteConfirmModal({
			title,
			content,
			handleConfirm: () => deleteRow(policyId),
		});
	};

	return [
		{
			icon: <EditOutlined />,
			text: t('common:edit'),
			action: () => {
				openEditModal(selectedId[0] as string);
			},
			singleItem: ({ id }: DeletionPolicyRead) => ({
				text: t('common:edit'),
				action: () => {
					openEditModal(id);
				},
			}),
		},
		{
			icon: <DeleteOutlined />,
			text: t('common:delete'),
			tooltip: t('common:delete'),
			color: 'error',
			action: () => {
				openConfirmationPolicyDialog(selectedId[0] as string);
			},
			singleItem: (row: DeletionPolicyRead) => ({
				action: () => {
					openConfirmationPolicyDialog(row.id);
				},
			}),
		},
	];
};

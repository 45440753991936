import { DataGridProProps, GridPaginationModel } from '@mui/x-data-grid-pro';

type DefaultGridPropsType = Pick<
	DataGridProProps,
	| 'sortingOrder'
	| 'pagination'
	| 'disableColumnMenu'
	| 'pageSizeOptions'
	| 'checkboxSelection'
	| 'disableRowSelectionOnClick'
	| 'paginationMode'
	| 'onColumnVisibilityModelChange'
	| 'onColumnOrderChange'
	| 'onColumnWidthChange'
>;

const SMALL_PAGE_SIZE = 50;
const MEDIUM_PAGE_SIZE = 100;
const LARGE_PAGE_SIZE = 200;
const DEFAULT_ROWS_PER_PAGE_OPTIONS = [SMALL_PAGE_SIZE, MEDIUM_PAGE_SIZE, LARGE_PAGE_SIZE];

const SMALL_PAGINATION_MODEL: GridPaginationModel = { pageSize: SMALL_PAGE_SIZE, page: 0 };
const MEDIUM_PAGINATION_MODEL: GridPaginationModel = { pageSize: MEDIUM_PAGE_SIZE, page: 0 };
const LARGE_PAGINATION_MODEL: GridPaginationModel = { pageSize: LARGE_PAGE_SIZE, page: 0 };
const DEFAULT_PAGINATION_MODEL = SMALL_PAGINATION_MODEL;

const DEFAULT_GRID_PROPS: DefaultGridPropsType = {
	sortingOrder: ['desc', 'asc'],
	pagination: true,
	disableColumnMenu: true,
	pageSizeOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
	checkboxSelection: true,
	disableRowSelectionOnClick: true,
	paginationMode: 'client',
};

const onColumnChange = (onChange: () => void): DefaultGridPropsType => ({
	onColumnVisibilityModelChange: onChange,
	onColumnOrderChange: onChange,
	onColumnWidthChange: onChange,
});

export {
	DEFAULT_ROWS_PER_PAGE_OPTIONS,
	SMALL_PAGINATION_MODEL,
	MEDIUM_PAGINATION_MODEL,
	LARGE_PAGINATION_MODEL,
	DEFAULT_PAGINATION_MODEL,
	DEFAULT_GRID_PROPS,
	onColumnChange,
};

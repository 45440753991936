import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useGetInitialResult, useGetTestResultData } from './search-result-combo-hooks';
import { TestResult, TestResultPage } from '@neoload/api';
import { hasNotStarted, timeUtils } from '@neoload/utils';
import { useUrlSearchParams } from '@neoload/hooks';

export type SearchResultComboProps = {
	onResultSelected: (result: TestResult) => void;
	initialResultId?: string;
	disabled?: boolean;
};

const pageSize = 25;

export const SearchResultCombo = ({
	onResultSelected,
	initialResultId: initialResultIdFromParent,
	disabled = false,
}: SearchResultComboProps) => {
	const [{ fromResult: initialResultIdFromUrl, tileToEdit }] = useUrlSearchParams('fromResult', 'tileToEdit');
	const theme = useTheme();
	const { t } = useTranslation(['result']);
	const [pageNumber, setPageNumber] = useState(0);
	const [testResultPagesMap, setTestResultPagesMap] = useState<Map<number, TestResultPage>>(new Map());
	const [selectedResultId, setSelectedResultId] = useState<string>('');
	const {
		data: testResultPage,
		error: testResultPageError,
		isLoading: isTestResultPageLoading,
		isFetching: isTestResultPageFetching,
	} = useGetTestResultData(pageNumber, pageSize);

	const initialResultId =
		initialResultIdFromUrl && (tileToEdit === 'SERIES' || tileToEdit === 'VALUES_COMPARISON' || tileToEdit === 'WIDGET')
			? initialResultIdFromUrl
			: initialResultIdFromParent;

	const {
		data: initialResult,
		isLoading: isInitialResultLoading,
		error: initialResultError,
	} = useGetInitialResult(initialResultId, testResultPagesMap);

	useEffect(() => {
		if (testResultPage?.pageNumber === pageNumber && !testResultPagesMap.has(pageNumber)) {
			const map = new Map(testResultPagesMap);
			map.set(pageNumber, testResultPage);
			setTestResultPagesMap(map);
		}
	}, [pageNumber, testResultPage, testResultPagesMap]);

	const items = useMemo(() => [...testResultPagesMap.values()].flatMap((page) => page.items), [testResultPagesMap]);
	const filteredItems = useMemo(() => {
		const map = new Map();
		for (const item of items) {
			if ((!hasNotStarted(item) || item.id === initialResultId) && !map.has(item.id)) {
				map.set(item.id, item);
			}
		}
		return [...map.values()];
	}, [initialResultId, items]);

	const total = testResultPagesMap.get(testResultPagesMap.size - 1)?.total ?? 0;
	const hasMore = items.length < total;

	const loadMoreItems = () => {
		setPageNumber((previous) => previous + 1);
	};

	const isPageLoadedButResultStillNotSelected =
		!selectedResultId && !initialResultError && !testResultPageError && (testResultPage?.items.length ?? 0) > 0;
	const isLoading = isTestResultPageLoading || isInitialResultLoading || isPageLoadedButResultStillNotSelected;

	useEffect(() => {
		if (selectedResultId) {
			return;
		}
		if (initialResultId && !initialResultError) {
			if (initialResult) {
				setSelectedResultId(initialResult.id);
				onResultSelected(initialResult);
			}
			return;
		}
		if (filteredItems.length > 0) {
			setSelectedResultId(filteredItems[0].id);
			onResultSelected(filteredItems[0]);
		}
	}, [filteredItems, initialResult, initialResultError, initialResultId, onResultSelected, selectedResultId]);

	const onChange = (event: SelectChangeEvent<string>) => {
		const testResult = filteredItems.find((item) => item.id === event.target.value);
		if (testResult) {
			setSelectedResultId(testResult.id);
			onResultSelected(testResult);
		}
	};

	const isError = !!testResultPageError || !!initialResultError || (!isLoading && !initialResult && total === 0);

	return (
		<FormControl fullWidth variant='outlined' required error={isError}>
			<InputLabel id='test-result' required={!isLoading}>
				<Label isLoading={isLoading} />
			</InputLabel>
			<Select
				// eslint-disable-next-line @typescript-eslint/naming-convention
				inputProps={{ 'data-testid': 'test-result-combo' }}
				label={<Label isLoading={isLoading} />}
				labelId='test-result'
				sx={{ height: theme.spacing(7) }}
				disabled={disabled}
				onChange={onChange}
				value={selectedResultId}
				MenuProps={{
					// eslint-disable-next-line @typescript-eslint/naming-convention
					MenuListProps: {
						style: {
							maxHeight: 500,
						},
					},
				}}
			>
				{initialResult && (
					<MenuItem key={`initial_test_result_${initialResult.id}`} value={initialResult.id} sx={{ display: 'none' }}>
						<TestResultItem testResult={initialResult} />
					</MenuItem>
				)}
				{filteredItems.map((item) => (
					<MenuItem key={`test_result_${item.id}`} value={item.id}>
						<TestResultItem testResult={item} />
					</MenuItem>
				))}
				{hasMore && (
					<Box key='load-more-items'>
						<Button fullWidth size='large' variant='text' onClick={() => loadMoreItems()}>
							{t('testResultCombo.loadMoreItems')}
							{(!testResultPagesMap.has(pageNumber) || isTestResultPageFetching) && (
								<CircularProgress size='1em' sx={{ marginLeft: (theme) => theme.spacing(1) }} />
							)}
						</Button>
					</Box>
				)}
			</Select>
			{isError && <FormHelperText error>{t('testResultCombo.loadingError')}</FormHelperText>}
		</FormControl>
	);
};

type TestResultItemProps = {
	testResult: TestResult;
};
const TestResultItem = ({ testResult }: TestResultItemProps) => (
	<Stack direction='row' justifyContent='space-between' alignItems='center' flex={1}>
		<Stack width='20vw'>
			<Typography noWrap title={testResult.name}>
				{testResult.name}
			</Typography>
			{testResult.test && (
				<Typography
					noWrap
					variant='subtitle2'
					color={(theme) => theme.palette.text.secondary}
					title={testResult.test.name}
				>
					{testResult.test.name}
				</Typography>
			)}
		</Stack>
		<Typography variant='subtitle2' color={(theme) => theme.palette.text.secondary}>
			{timeUtils.dateTimeAbsolute(testResult.startDate)}
		</Typography>
	</Stack>
);

const Label = ({ isLoading }: { isLoading: boolean }) => {
	const { t } = useTranslation(['result']);
	if (isLoading) {
		return (
			<Box component='span'>
				{t('testResultCombo.loading')} <CircularProgress size='1em' />
			</Box>
		);
	}
	return t('testResultCombo.testResult');
};

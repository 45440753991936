import Dialog from '@mui/material/Dialog';
import { FormProvider, useForm } from 'react-hook-form';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import OpenInNewOutlined from '@mui/icons-material/OpenInNewOutlined';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import { InfrastructureProviderFormContent } from './infrastructure-provider-form-content';
import { AlertWithDetails } from './alert-with-details';
import {
	CreateProviderRequestWrite,
	InfrastructureProvider,
	InfrastructureProviderWithConnectivityStatus,
	PatchProviderRequestWrite,
	ProviderConnectivityStatus,
	usePatchV4InfrastructureProvidersByIdMutation,
	usePostV4InfrastructureProvidersMutation,
} from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';
import { isMethodNotAllowedError, RtkErrorResponse } from '@neoload/utils';

type InfrastructureProviderModalProps = {
	opened: boolean;
	closeModal: () => void;
	editedProvider?: InfrastructureProvider;
};

const emptyCreateProviderRequest: CreateProviderRequestWrite = {
	apiToken: '',
	apiUrl: '',
	name: '',
	namespace: '',
	type: 'KUBERNETES',
};

const createDefaultProviderRequest = (editedProvider?: InfrastructureProvider): CreateProviderRequestWrite => {
	if (!editedProvider) {
		return emptyCreateProviderRequest;
	}
	const { id, ...editedProviderWithoutId } = editedProvider;
	return {
		...editedProviderWithoutId,
		apiToken: '',
	};
};

//Cleaning empty values to no values before POST request as some have minLength constraints if present
const clearEmptyValuesFromRequest = (request: CreateProviderRequestWrite): CreateProviderRequestWrite => ({
	apiToken: request.apiToken,
	apiUrl: request.apiUrl,
	name: request.name,
	namespace: request.namespace,
	type: request.type,
	...(request.annotations !== '' && { annotations: request.annotations }),
	...(request.labels !== '' && { labels: request.labels }),
	...(request.nodeSelectors !== '' && { nodeSelectors: request.nodeSelectors }),
	...(request.tolerations !== '' && { tolerations: request.tolerations }),
});

//Do not put api token into patch request if empty. Other fields may be empty as they could clear the value
const toPatchRequest = (request: CreateProviderRequestWrite): PatchProviderRequestWrite => ({
	apiUrl: request.apiUrl,
	name: request.name,
	namespace: request.namespace,
	type: request.type,
	annotations: request.annotations,
	labels: request.labels,
	nodeSelectors: request.nodeSelectors,
	tolerations: request.tolerations,
	...(request.apiToken !== '' && { apiToken: request.apiToken }),
});

const DOCUMENTATION_LINK =
	'https://www.neotys.com/redirect/redirect.php?target=docpage&front-version=2.0&product=neoload+web&lngdoc=en&reference=runtime.dynamic-providers';
export const LABELS_LINK = 'https://kubernetes.io/docs/concepts/overview/working-with-objects/labels/';
export const ANNOTATIONS_LINK = 'https://kubernetes.io/docs/concepts/overview/working-with-objects/annotations/';
export const NODE_SELECTOR_LINK =
	'https://kubernetes.io/docs/concepts/scheduling-eviction/assign-pod-node/#nodeselector';
export const TOLERATIONS_LINK = 'https://kubernetes.io/docs/concepts/scheduling-eviction/taint-and-toleration/';

const getSubmiti18Key = (isEdition: boolean, connectivityCheckResult?: ProviderConnectivityStatus) => {
	const i18nKeyPrefix = isEdition ? 'modal.save' : 'modal.add';
	const i18nKeySuffix = connectivityCheckResult && connectivityCheckResult.status !== 'READY' ? 'Anyway' : '';
	return `${i18nKeyPrefix}${i18nKeySuffix}`;
};

export const InfrastructureProviderFormModal = ({
	opened,
	closeModal,
	editedProvider,
}: InfrastructureProviderModalProps) => {
	const { t } = useTranslation('infrastructureProviders');
	const [createInfrastructureProvider, { isLoading: isCreating }] = usePostV4InfrastructureProvidersMutation();
	const [updateInfrastructureProvider, { isLoading: isUpdating }] = usePatchV4InfrastructureProvidersByIdMutation();
	const { showError, showInfo } = useSetSnackbars();
	const [connectivityCheckResult, setConnectivityCheckResult] = useState<ProviderConnectivityStatus | undefined>();
	const isEdition = !!editedProvider;
	const isSaving = isCreating || isUpdating;

	const { formState, handleSubmit, reset, getValues, ...methods } = useForm<CreateProviderRequestWrite>({
		mode: 'onChange',
		defaultValues: createDefaultProviderRequest(editedProvider),
	});

	useEffect(() => {
		reset(createDefaultProviderRequest(editedProvider));
	}, [editedProvider, reset]);

	const close = () => {
		closeModal();
		reset();
		setConnectivityCheckResult(undefined);
	};

	const dryRunSave = async (form: CreateProviderRequestWrite): Promise<InfrastructureProviderWithConnectivityStatus> =>
		isEdition
			? updateInfrastructureProvider({
					patchProviderRequest: toPatchRequest(form),
					dryRun: true,
					id: editedProvider.id,
				}).unwrap()
			: createInfrastructureProvider({
					createProviderRequest: clearEmptyValuesFromRequest(form),
					dryRun: true,
				}).unwrap();

	const showSnackbarError = (error: RtkErrorResponse, actionMessage: string) => {
		showError({
			text: t(isMethodNotAllowedError(error) ? 'modal.errors.failNotAllowed' : 'modal.errors.fail', {
				action: actionMessage,
			}),
		});
	};

	const testConnexion = async (form: CreateProviderRequestWrite) => {
		await dryRunSave(form)
			.then((infraProvider: InfrastructureProviderWithConnectivityStatus) => {
				setConnectivityCheckResult(infraProvider.connectivityStatus);
			})
			.catch((error) => showSnackbarError(error, t('modal.errors.action.test')));
	};

	const doSave = async (form: CreateProviderRequestWrite) => {
		await (
			isEdition
				? updateInfrastructureProvider({
						patchProviderRequest: toPatchRequest(form),
						id: editedProvider.id,
					}).unwrap()
				: createInfrastructureProvider({
						createProviderRequest: clearEmptyValuesFromRequest(form),
					}).unwrap()
		)
			.then((infraProvider: InfrastructureProviderWithConnectivityStatus) => {
				showInfo({
					text: t(isEdition ? 'modal.providerUpdated' : 'modal.providerCreated', { name: infraProvider.name }),
				});
				close();
			})
			.catch((error) =>
				showSnackbarError(error, t(isEdition ? 'modal.errors.action.update' : 'modal.errors.action.create')),
			);
	};

	const saveIfReady = async (form: CreateProviderRequestWrite) => {
		await dryRunSave(form)
			.then(async (infraProvider: InfrastructureProviderWithConnectivityStatus) => {
				if (infraProvider.connectivityStatus.status === 'READY') {
					await doSave(form);
				} else {
					setConnectivityCheckResult(infraProvider.connectivityStatus);
				}
			})
			.catch((error) =>
				showSnackbarError(error, t(isEdition ? 'modal.errors.action.update' : 'modal.errors.action.create')),
			);
	};

	const submitForm = async (form: CreateProviderRequestWrite) => {
		await (connectivityCheckResult ? doSave(form) : saveIfReady(form));
	};

	const title = t(isEdition ? 'modal.editionTitle' : 'modal.creationTitle');
	const submitOrCheckConnectivityDisabled = !formState.isValid || Object.keys(formState.errors).length > 0 || isSaving;
	const submitLabel = t(getSubmiti18Key(isEdition, connectivityCheckResult));

	return (
		<Dialog open={opened} fullWidth>
			<FormProvider {...methods} formState={formState} handleSubmit={handleSubmit} reset={reset} getValues={getValues}>
				<form
					aria-label={title}
					onSubmit={handleSubmit(submitForm)}
					onChange={() => setConnectivityCheckResult(undefined)}
					onReset={() => {
						close();
					}}
					style={{
						overflowY: 'auto',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Box display='flex' justifyContent='space-between' alignItems='center' paddingRight={2}>
						<DialogTitle>{title}</DialogTitle>
						<Link href={DOCUMENTATION_LINK} underline='none' target='_blank' alignItems='center'>
							<Button endIcon={<OpenInNewOutlined />}>{t('modal.openHelp')}</Button>
						</Link>
					</Box>
					<DialogContent>
						<>
							<InfrastructureProviderFormContent isEdition={isEdition} />
							<Button
								variant='outlined'
								disabled={submitOrCheckConnectivityDisabled}
								onClick={() => testConnexion(getValues())}
								startIcon={isSaving ? <CircularProgress size={24.5} color='inherit' /> : null}
								sx={{ alignSelf: 'left', marginY: 2 }}
							>
								{t('modal.testConnection')}
							</Button>
							{connectivityCheckResult &&
								(connectivityCheckResult.status === 'READY' ? (
									<Alert severity='success'>{t('modal.connectivityReady')}</Alert>
								) : (
									<AlertWithDetails
										details={connectivityCheckResult.details}
										causeList={connectivityCheckResult.causeList}
									/>
								))}
						</>
					</DialogContent>
					<DialogActions>
						<Button onClick={close} color='primary' data-trackingid='infra-provider-cancel'>
							{t('common:cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							startIcon={isSaving ? <CircularProgress size={24.5} color='inherit' /> : null}
							disabled={submitOrCheckConnectivityDisabled}
							data-trackingid='infra-provider-ok'
						>
							{submitLabel}
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
};

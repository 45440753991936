import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';
import { WebhookFormModalContent } from './webhook-form-modal-content';
import { useCrudBatchMessage } from '@neoload/hooks';
import {
	PostWebhookRequest,
	usePatchV4WebhooksByWebhookIdMutation,
	usePostV4WebhooksMutation,
	Webhook,
} from '@neoload/api';

type WebhookFormModalProps = {
	open: boolean;
	closeModal: () => void;
	workspaceId: string;
	editedWebHook?: Webhook;
};

const getPostWebhookRequest = (workspaceId: string, editedWebHook?: Webhook): PostWebhookRequest => ({
	contentType: editedWebHook?.contentType ?? 'application/json',
	enabled: editedWebHook?.enabled ?? true,
	name: editedWebHook?.name ?? '',
	payload: editedWebHook?.payload ?? '',
	url: editedWebHook?.url ?? 'https://',
	trigger: editedWebHook?.trigger ?? 'ON_RESULT_STARTED',
	filters: editedWebHook?.filters ?? {},
	workspaceId: workspaceId,
});

export const WebhookFormModal = ({ open, closeModal, workspaceId, editedWebHook }: WebhookFormModalProps) => {
	const { t } = useTranslation('workspace');
	const [createWebhook, { isLoading: isCreating }] = usePostV4WebhooksMutation();
	const [patchWebhook, { isLoading: isPatching }] = usePatchV4WebhooksByWebhookIdMutation();
	const { create, update } = useCrudBatchMessage('workspace', 'tabs.webhooks');

	const { formState, handleSubmit, reset, ...methods } = useForm<PostWebhookRequest>({
		mode: 'onChange',
		defaultValues: getPostWebhookRequest(workspaceId, editedWebHook),
	});

	useEffect(() => {
		reset(getPostWebhookRequest(workspaceId, editedWebHook));
	}, [editedWebHook, reset, workspaceId]);

	const close = () => {
		closeModal();
		reset(getPostWebhookRequest(workspaceId, editedWebHook));
	};

	const submitForm = async (form: PostWebhookRequest) => {
		editedWebHook
			? await update(
					[form],
					(input) => patchWebhook({ webhookId: editedWebHook.id, patchWebhookRequest: input }).unwrap(),
					close,
			  )
			: await create(
					[form],
					(input) =>
						createWebhook({
							postWebhookRequest: input,
						}).unwrap(),
					close,
			  );
	};

	const isSubmitting = isCreating || isPatching;

	const title = t(editedWebHook ? 'tabs.webhooks.editionTitle' : 'tabs.webhooks.creationTitle');

	return (
		<Dialog open={open}>
			<FormProvider {...methods} formState={formState} handleSubmit={handleSubmit} reset={reset}>
				<form
					aria-label={title}
					onSubmit={handleSubmit(submitForm)}
					onReset={() => {
						close();
					}}
					style={{
						overflowY: 'auto',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<DialogTitle>{title}</DialogTitle>
					<DialogContent sx={{ width: '600px' }}>
						<WebhookFormModalContent />
					</DialogContent>
					<DialogActions>
						<Button onClick={close} color='primary' data-trackingid='webhook-form-cancel'>
							{t('common:cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							startIcon={isSubmitting ? <CircularProgress size={24.5} color='inherit' /> : null}
							disabled={!formState.isValid || Object.keys(formState.errors).length > 0 || isSubmitting}
							data-trackingid='webhook-form-ok'
						>
							{t('common:save')}
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
};

import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import isURL from 'validator/lib/isURL';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import SyncOutlined from '@mui/icons-material/SyncOutlined';
import { WebhookPayloadInfoIcon } from './webhook-payload-info-icon';
import { WebhookTestValidationButton } from './webhook-test-validation-button';
import { WebhookFilterSelector } from './filters/webhook-filter-selector';
import { PostWebhookRequest } from '@neoload/api';

const testStartValue: PostWebhookRequest['trigger'] = 'ON_RESULT_STARTED';
const testEndValue: PostWebhookRequest['trigger'] = 'ON_RESULT_ENDED';

export const WebhookFormModalContent = () => {
	const { t } = useTranslation('workspace');
	const theme = useTheme();
	const { control, register, setValue, formState, trigger, getValues, watch } = useFormContext<PostWebhookRequest>();

	const formIsValid = formState.isValid && Object.keys(formState.errors).length === 0;
	const formTrigger = watch('trigger');

	useEffect(() => {
		if (formTrigger === 'ON_RESULT_STARTED') {
			setValue('filters.byQualityStatus', undefined);
		}
	}, [formTrigger, setValue]);

	return (
		<>
			<TextField
				size='small'
				autoFocus
				label={t('common:name')}
				fullWidth
				margin='normal'
				required
				error={!!formState.errors.name}
				helperText={formState.errors.name?.message}
				sx={{ marginBottom: 2 }}
				InputProps={{ inputProps: { maxLength: 256 } }}
				{...register('name', {
					required: t('tabs.webhooks.nameRequired'),
					maxLength: { value: 256, message: t('tabs.webhooks.nameTooLong') },
				})}
			/>
			<Typography color={theme.palette.text.secondary}>{t('tabs.webhooks.event')}</Typography>
			<Controller
				control={control}
				name='trigger'
				rules={{ required: true }}
				render={({ field }) => (
					<RadioGroup
						{...field}
						row
						sx={{
							marginBottom: 2,
							marginLeft: 2,
						}}
					>
						<FormControlLabel value={testStartValue} control={<Radio />} label={t('tabs.webhooks.onResultStarted')} />
						<FormControlLabel value={testEndValue} control={<Radio />} label={t('tabs.webhooks.onResultEnded')} />
					</RadioGroup>
				)}
			/>
			<WebhookFilterSelector />
			<Typography color={theme.palette.text.secondary}>{t('tabs.webhooks.configuration')}</Typography>
			<TextField
				size='small'
				label={t('tabs.webhooks.url')}
				fullWidth
				margin='normal'
				required
				error={!!formState.errors.url}
				helperText={formState.errors.url?.message}
				InputProps={{ inputProps: { maxLength: 2048 } }}
				{...register('url', {
					required: t('tabs.webhooks.urlRequired'),
					maxLength: { value: 2048, message: t('tabs.webhooks.urlTooLong') },
					validate: (value) =>
						/* eslint-disable @typescript-eslint/naming-convention*/
						isURL(value, {
							protocols: ['http', 'https'],
							require_protocol: true,
							require_tld: true,
						}) || t('tabs.webhooks.urlFormat'),
				})}
			/>
			<Stack direction='row' gap={1} alignItems='center'>
				<TextField
					rows={6}
					multiline={true}
					label={t('tabs.webhooks.payload')}
					fullWidth
					margin='normal'
					helperText={formState.errors.url?.message}
					InputProps={{ inputProps: { maxLength: 5000 } }}
					{...register('payload', {
						maxLength: { value: 5000, message: t('tabs.webhooks.payloadTypeTooLong') },
					})}
				/>
				<WebhookPayloadInfoIcon />
			</Stack>
			{/*the controller is there to prevent overlap with label when resetting the field. If the field is not controlled only user input refresh the label*/}
			<Controller
				name='contentType'
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						sx={{ marginBottom: 2 }}
						size='small'
						label={t('tabs.webhooks.contentType')}
						fullWidth
						margin='normal'
						required
						error={!!formState.errors.contentType}
						helperText={formState.errors.contentType?.message}
						InputProps={{
							inputProps: { maxLength: 256 },
							endAdornment: (
								<InputAdornment position='end'>
									<Tooltip title={t('tabs.webhooks.resetApplicationType')} arrow>
										<IconButton
											aria-label={t('tabs.webhooks.resetApplicationType')}
											onClick={async () => {
												setValue('contentType', 'application/json');
												//trigger error and form validation as they are not refreshed despite the value change
												await trigger('contentType');
											}}
											edge='end'
										>
											<SyncOutlined />
										</IconButton>
									</Tooltip>
								</InputAdornment>
							),
						}}
						{...register('contentType', {
							required: t('tabs.webhooks.contentTypeRequired'),
							maxLength: { value: 256, message: t('tabs.webhooks.contentTypeTooLong') },
						})}
					/>
				)}
			/>
			<WebhookTestValidationButton formIsValid={formIsValid} getFormValues={() => getValues()} />
		</>
	);
};

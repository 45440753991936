import { GridInitialState } from '@mui/x-data-grid-pro';
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { useCloudZonesColumns } from './use-cloud-zones-columns';
import { AllocateDedicatedIpsModal } from './dedicated-ips';
import { CommonZonesDatagrid, OneOfZone, ZoneDatagridActionGetter } from '../common-zones-datagrid';
import { useSetSnackbars } from '@neoload/hooks';
import { CloudZoneRead } from '@neoload/api';

const CLIPBOARD_SUCCESS_SNACKBAR_ID = 'CLIPBOARD_SUCCESS_SNACKBAR_ID';

const initialState: GridInitialState = {
	sorting: {
		sortModel: [
			{ field: 'available', sort: 'desc' },
			{ field: 'name', sort: 'asc' },
		],
	},
};

const CloudZonesDatagrid = () => {
	const { t } = useTranslation(['zone']);
	const { showInfo, showError } = useSetSnackbars();
	const [openAllocationDialog, setOpenAllocationDialog] = useState(false);
	const [preselectedZone, setPreselectedZone] = useState<CloudZoneRead | undefined>(undefined);

	function handleOpenDialog() {
		setOpenAllocationDialog(true);
	}

	function handleCloseDialog() {
		setOpenAllocationDialog(false);
	}

	function handleConfirm(error: boolean, message: string) {
		setOpenAllocationDialog(false);
		if (error) {
			showError({ text: t(message) });
		} else {
			showInfo({ text: t(message) });
		}
	}

	const copyToClipboard = (ipAddresses: string[]) =>
		navigator.clipboard
			.writeText(JSON.stringify(ipAddresses))
			.then(() => {
				showInfo({ text: t('actions.copyIpAddressesToClipboardSuccess'), id: CLIPBOARD_SUCCESS_SNACKBAR_ID });
			})
			.catch((error) => console.error('Unable to copy IP addresses to clipboard.', error));

	const actionGetters: ZoneDatagridActionGetter<OneOfZone>[] = [
		(zone) => {
			if (zone.type !== 'CLOUD') {
				return null;
			}
			if (!zone.available) {
				return null;
			}
			return {
				disabled: !zone.dedicatedIpsDetails,
				icon: <ContentCopyOutlined sx={{ fontSize: 20 }} />,
				text: t('cloudZone.dedicatedIps.copyIps'),
				action: () => ({}),
				singleItem: () => ({
					action: (event) => {
						event?.stopPropagation();
						void copyToClipboard(zone.dedicatedIpsDetails?.ipAddresses ?? []);
					},
				}),
			};
		},

		(zone) => {
			if (zone.type !== 'CLOUD') {
				return null;
			}
			if (!zone.available) {
				return null;
			}
			return {
				disabled: !zone.dedicatedIpsDetails || zone.dedicatedIpsDetails?.status !== 'READY',
				icon: <EditOutlined sx={{ fontSize: 20 }} />,
				text: t('cloudZone.dedicatedIps.editIps'),
				action: () => {
					setPreselectedZone(zone);
					handleOpenDialog();
				},
			};
		},
	];
	const columns = useCloudZonesColumns(actionGetters);
	return (
		<>
			<CommonZonesDatagrid
				type='CLOUD'
				columns={columns}
				getRowId={(row) => row.cloudRegion}
				disabledRow={(row) => !row.available}
				initialState={initialState}
				actionGetters={actionGetters}
			/>
			{openAllocationDialog && (
				<AllocateDedicatedIpsModal
					open={openAllocationDialog}
					handleClose={handleCloseDialog}
					handleConfirm={handleConfirm}
					initialCloudZoneId={preselectedZone?.zoneId}
				/>
			)}
		</>
	);
};

export { CloudZonesDatagrid };

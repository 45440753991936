import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import {
	ApiError,
	InvalidPropertyValueErrorDescription,
	isInvalidPropertyError,
	isInvalidPropertyErrorMatching,
	isNotFoundError,
	isUnAuthorizedError,
	Zone,
} from '@neoload/api';

export type CreateReservationForm = {
	name: string;
	description: string;
	startDate: string;
	endDate: string;
	webVus: {
		needed: number;
		available: number;
	};
	sapVus: {
		needed: number;
		available: number;
	};
	workspaceId: string | undefined;
	workspaceName: string | undefined;
	testId: string | undefined;
	initialScheduleDataNotChanged: boolean;
	errorDate: boolean;
	zones: ReservationZonesFormData | undefined;
	action: 'NEW' | 'EDIT';
	reservationId?: string;
	error: string;
	scenario: {
		duration: string | undefined;
	};
};

export type ReservationZonesFormData = {
	items: ReservationZoneFormData[];
	type: Zone['type'];
	controller: string;
};

export type ReservationZoneFormData = {
	zoneId: string;
	number: number;
	type: Zone['type'];
	name: string;
};

export const emptyCreateReservationForm: CreateReservationForm = {
	name: '',
	description: '',
	startDate: dayjs().add(15, 'minutes').second(0).toISOString(),
	endDate: dayjs().add(45, 'minutes').second(0).toISOString(),
	webVus: {
		needed: 0,
		available: 0,
	},
	sapVus: {
		needed: 0,
		available: 0,
	},
	workspaceId: undefined,
	testId: undefined,
	workspaceName: undefined,
	initialScheduleDataNotChanged: false,
	errorDate: false,
	zones: undefined,
	action: 'NEW',
	scenario: {
		duration: undefined,
	},
	error: '',
};

export type ReservationError = { sentenceKey?: string; sentence: string };

export const translateErrorMessages = (error: ApiError): ReservationError[] => {
	const reservationErrors: ReservationError[] = [];
	if (isUnAuthorizedError(error)) {
		reservationErrors.push({
			sentence: error.description,
			sentenceKey: 'schedule.errors.unAuthorized',
		});
	}
	if (isNotFoundError(error)) {
		reservationErrors.push({
			sentence: error.description,
			sentenceKey: 'schedule.errors.notFound',
		});
	}
	if (isInvalidPropertyError(error) && error.errors !== undefined) {
		for (const apiError of error.errors) {
			reservationErrors.push(translateApiError(apiError));
		}
	}

	if (reservationErrors.length === 0) {
		reservationErrors.push({
			sentence: error.description,
		});
	}

	return reservationErrors;
};

export const translateApiError = (apiError: InvalidPropertyValueErrorDescription): ReservationError => {
	if (isInvalidPropertyErrorMatching(apiError, 'notEnough', 'licensing', 'vu')) {
		return {
			sentence: apiError.message,
			sentenceKey: 'schedule.errors.notEnoughVU',
		};
	}

	return {
		sentence: apiError.message,
		sentenceKey: '',
	};
};

export const reservationErrorToString = (error: ApiError, t: TFunction) =>
	translateErrorMessages(error)
		.map((error) => {
			if (error.sentenceKey) {
				return t(error.sentenceKey);
			}

			return error.sentence;
		})
		.join(', ');

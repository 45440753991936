import { ConfigurationZoneFormData } from './../types.d';
import { InformationResponse, Zone } from '@neoload/api';

export const filterZones = (zones: ConfigurationZoneFormData[], zoneTypeSelected: Zone['type']) =>
	zones.filter((value) => value.type === zoneTypeSelected);

export const findClosestLowerValue = (
	maximumLgCountPerProjectSize: InformationResponse['maximumLgCountPerProjectSize'],
	search: number | undefined,
): number => {
	const keys = Object.keys(maximumLgCountPerProjectSize)
		.map(Number)
		.sort((a, b) => a - b);

	if (search === undefined) {
		const maxLgsDefaultKey = keys.at(-2);
		return maxLgsDefaultKey === undefined
			? maximumLgCountPerProjectSize[0]
			: maximumLgCountPerProjectSize[maxLgsDefaultKey];
	}
	let closestLowerKey: number | null = null;

	for (const key of keys) {
		if (Number(key) < search) {
			closestLowerKey = Number(key);
		} else {
			break;
		}
	}

	return closestLowerKey === null ? 0 : maximumLgCountPerProjectSize[closestLowerKey];
};

export const hasExceededLgsCountForCloudMultiZone = (
	maximumLgCountPerProjectSize: InformationResponse['maximumLgCountPerProjectSize'],
	projectSize: number | undefined,
	zones: ConfigurationZoneFormData[],
	zoneType: Zone['type'],
) => {
	const isCloudZone = zoneType === 'CLOUD';
	if (!isCloudZone) {
		return false;
	}
	const maxMultiZoneCloudLgs = findClosestLowerValue(maximumLgCountPerProjectSize, projectSize);
	const hasMoreThanOneLg =
		zones.filter((zones) => zones.type === zoneType).filter((zone) => zone.number > 0).length > 1;
	const numberOfLgs = zones
		.filter((zones) => zones.type === zoneType)
		.reduce((accumulator, zone) => accumulator + zone.number, 0);
	return isCloudZone && hasMoreThanOneLg && numberOfLgs > maxMultiZoneCloudLgs;
};

import { GridColDef } from '@mui/x-data-grid';
import { timeUtils } from '@neoload/utils';

const getDateValue = (value: string | Date | number | undefined, _: unknown, column: GridColDef): string => {
	if (value === undefined) {
		return '';
	}
	if (value === null || typeof value === 'number' || typeof value === 'string' || value instanceof Date) {
		return timeUtils.dateTimeAbsolute(value);
	} else {
		throw new Error(`Invalid date rendering for column ${column.field}.`);
	}
};

export { getDateValue };

import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import { CreateReservationForm, reservationErrorToString } from './reservation-helper';
import { usePostV4ReservationsMutation } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

type CreateModalReservationActionProps = {
	close: () => void;
	disableSubmit: boolean;
	labelSubmit: string;
};

const ActionsReservationModal = ({ close, disableSubmit, labelSubmit }: CreateModalReservationActionProps) => {
	const { t } = useTranslation(['reservation']);
	const [loading, setLoading] = useState(false);
	const [postReservation] = usePostV4ReservationsMutation();
	const name = useWatch<CreateReservationForm, 'name'>({ name: 'name' });
	const description = useWatch<CreateReservationForm, 'description'>({ name: 'description' });
	const workspaceId = useWatch<CreateReservationForm, 'workspaceId'>({ name: 'workspaceId' });
	const startDate = useWatch<CreateReservationForm, 'startDate'>({ name: 'startDate' });
	const endDate = useWatch<CreateReservationForm, 'endDate'>({ name: 'endDate' });
	const neededWebvu = useWatch<CreateReservationForm, 'webVus.needed'>({ name: 'webVus.needed' });
	const neededSAPvu = useWatch<CreateReservationForm, 'sapVus.needed'>({ name: 'sapVus.needed' });
	const controllerZoneId = useWatch<CreateReservationForm, 'zones.controller'>({ name: 'zones.controller' });
	const lgCountByZoneId = useWatch<CreateReservationForm, 'zones.items'>({ name: 'zones.items' });
	const { setValue } = useFormContext<CreateReservationForm>();
	const { showInfo } = useSetSnackbars();
	const object: { [key: string]: number } = {};

	for (const element of lgCountByZoneId) {
		if (element.number > 0) {
			object[element.zoneId] = element.number;
		}
	}

	const createReservation = () => {
		setLoading(true);
		setValue('error', '');
		postReservation({
			body: {
				name: name,
				workspaceId: workspaceId ?? '',
				startDate: startDate,
				endDate: endDate,
				controllerZoneId: controllerZoneId,
				lgCountByZoneId: object,
				description: description,
				vuCountByVuType: {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					WEB: neededWebvu,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					SAP: neededSAPvu,
				},
			},
		})
			.unwrap()
			.then(() => {
				showInfo({ text: t('create.snackbars.success') });
				close();
			})
			.catch((error) => {
				const message = reservationErrorToString(error.data, t);
				setValue('error', message);
			})
			.finally(() => setLoading(false));
	};
	return (
		<DialogActions>
			<Button onClick={close} color='primary' data-trackingid='reservation-cancel'>
				{t('common:cancel')}
			</Button>
			<Button
				onClick={() => {
					createReservation();
				}}
				startIcon={loading && <CircularProgress size={24.5} color='inherit' />}
				disabled={disableSubmit || loading}
				variant='contained'
				data-trackingid='reservation-ok'
			>
				{labelSubmit}
			</Button>
		</DialogActions>
	);
};

export { ActionsReservationModal };

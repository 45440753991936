import { Chart, Point, TooltipFormatterCallbackFunction } from 'highcharts';
import { useEffect, useState, ReactNode } from 'react';
import ReactDOM from 'react-dom';

const generateTooltipId = (chartId: number) => `highcharts-custom-tooltip-${chartId}`;

export type TooltipContext = { currentPoint: Point; points: Point[] };

type CustomChartTooltipProps = {
	chart: Chart | null;
	children(context: TooltipContext): ReactNode;
};

export const CustomChartTooltip = ({ chart, children }: CustomChartTooltipProps) => {
	const [context, setContext] = useState<TooltipContext | null>(null);

	useEffect(() => {
		if (chart) {
			const formatter: TooltipFormatterCallbackFunction = function () {
				//this is what the doc is saying: https://github.com/highcharts/highcharts/blob/034454f3d23111e4451821f9f2bd965a9f9f75a2/ts/Core/Defaults.ts#L2158
				//eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-expect-error
				setContext(this ? { currentPoint: this, points: this.points } : null);
				return `<div id="${generateTooltipId(chart.index)}" role="tooltip"></div>`;
			};

			chart.update({
				tooltip: {
					formatter,
					style: {
						opacity: 0,
					},
				},
			});
		}
	}, [chart]);

	const node = chart && document.querySelector(`#${generateTooltipId(chart.index)}`);
	return node && context ? ReactDOM.createPortal(children(context), node) : null;
};

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import isURL from 'validator/lib/isURL';
import { useState } from 'react';
import { ExternalUrl } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

type ExternalUrlModalProps = {
	open: boolean;
	externalUrl?: ExternalUrl;
	onChange: (updatedExternalUrl: ExternalUrl) => Promise<void>;
	closeModal: () => void;
};

const DEFAULT_EXTERNAL_URL: ExternalUrl = { url: '', title: '' };

const ExternalUrlModal = ({ open, closeModal, externalUrl, onChange }: ExternalUrlModalProps) => {
	const { t } = useTranslation(['result']);
	const { showInfo, showError } = useSetSnackbars();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { formState, handleSubmit, register, reset, ...methods } = useForm<ExternalUrl>({
		mode: 'onChange',
		defaultValues: externalUrl ?? DEFAULT_EXTERNAL_URL,
	});

	const handleOnClose = (updatedExternalUrl?: ExternalUrl) => {
		closeModal();
		reset(updatedExternalUrl ?? externalUrl ?? DEFAULT_EXTERNAL_URL);
	};

	const onSubmit = async (updatedExternalUrl: ExternalUrl) => {
		setIsSubmitting(true);

		if (!updatedExternalUrl.title) {
			updatedExternalUrl.title = updatedExternalUrl.url;
		}

		await onChange(updatedExternalUrl)
			.then(
				() => {
					showInfo({ text: externalUrl ? t('externalUrl.edit.success') : t('externalUrl.add.success') });
					handleOnClose(updatedExternalUrl);
				},
				() => {
					showError({ text: externalUrl ? t('externalUrl.edit.error') : t('externalUrl.add.error') });
					handleOnClose();
				},
			)
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	return (
		<Dialog open={open} onClose={() => handleOnClose()} fullWidth>
			<FormProvider {...methods} formState={formState} handleSubmit={handleSubmit} reset={reset} register={register}>
				<form onSubmit={handleSubmit(onSubmit)} onReset={() => handleOnClose()}>
					<DialogTitle id='external-url-modal-title'>
						{externalUrl ? t('externalUrl.edit.title') : t('externalUrl.add.title')}
					</DialogTitle>
					<DialogContent>
						<TextField
							autoFocus
							label={t('externalUrl.url.label')}
							fullWidth
							margin='normal'
							required
							placeholder={t('externalUrl.url.placeholder')}
							error={!!formState.errors.url}
							helperText={formState.errors.url?.message}
							sx={{ marginBottom: 2 }}
							InputProps={{ inputProps: { maxLength: 256 } }}
							{...register('url', {
								required: t('externalUrl.url.required'),
								validate: {
									checkUrlFormat: (url) =>
										// eslint-disable-next-line @typescript-eslint/naming-convention
										isURL(url, { require_protocol: true }) || t('externalUrl.url.invalidFormat'),
								},
							})}
						/>
						<TextField
							label={t('externalUrl.title.label')}
							fullWidth
							margin='normal'
							placeholder={t('externalUrl.title.placeholder')}
							sx={{ marginBottom: 2 }}
							InputProps={{ inputProps: { maxLength: 256 } }}
							{...register('title')}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => handleOnClose()} color='primary' data-trackingid='external-url-cancel'>
							{t('common:cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							startIcon={isSubmitting ? <CircularProgress size={24.5} color='inherit' /> : null}
							disabled={!formState.isValid || Object.keys(formState.errors).length > 0 || isSubmitting}
							data-trackingid='external-url-ok'
						>
							{t('common:save')}
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
};

export { ExternalUrlModal };

import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { ValidationRequest, PostWebhookRequest, usePostV4WebhooksValidationMutation } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

type WebhookTestValidationButtonProps = {
	getFormValues: () => PostWebhookRequest;
	formIsValid: boolean;
};

const postWebhookRequestToPostExecution = (postRequest: PostWebhookRequest): ValidationRequest => {
	const { payload, contentType, url } = postRequest;
	return { payload, contentType, url };
};

export const WebhookTestValidationButton = ({ getFormValues, formIsValid }: WebhookTestValidationButtonProps) => {
	const { showError, showInfo, showWarning } = useSetSnackbars();
	const { t } = useTranslation('workspace', { keyPrefix: 'tabs.webhooks.executionTest' });
	const [postWebhookExecution, { isLoading: isPosting }] = usePostV4WebhooksValidationMutation();

	const testWebhookExecution = async () => {
		await postWebhookExecution({ validationRequest: postWebhookRequestToPostExecution(getFormValues()) })
			.unwrap()
			.then((response) => {
				if (response.httpStatusCode >= 200 && response.httpStatusCode < 300) {
					showInfo({ text: t('receivedSuccess', { code: response.httpStatusCode }) });
				} else {
					showWarning({ text: t('receivedFail', { code: response.httpStatusCode }) });
				}
			})
			.catch(() => {
				showError({ text: t('executionError') });
			});
	};

	return (
		<Button
			variant='outlined'
			disabled={!formIsValid || isPosting}
			onClick={testWebhookExecution}
			startIcon={isPosting ? <CircularProgress size={24.5} color='inherit' /> : undefined}
		>
			{t('testWebhook')}
		</Button>
	);
};

import { GridColDef, GridDensity, useGridApiRef } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { Datagrid } from '../../../common/data-grid/datagrid';
import { ResultsDataGridColumns } from '../../results/result-data-grid-columns';
import { DatagridAction } from '../../../common/data-grid/actions/data-grid-actions';
import { Spinner } from '../../../common/spinner';
import { DEFAULT_GRID_PROPS } from '../../../common/datagrid';
import { TestResult, TestResultSearchCriteria, useGetV4ResultsQuery } from '@neoload/api';
import { useCurrentWorkspace, useGetMe } from '@neoload/hooks';

const DISPLAYED_COLS = new Set(['name', 'testName', 'startDate', 'qualityStatus']);
const PAGE_SIZE = 12;
const density: GridDensity = 'compact';

type LatestResultsTileProps = {
	pollingIntervalDurationInMs: number;
};

export const LatestResultsTile = ({ pollingIntervalDurationInMs }: LatestResultsTileProps) => {
	const [{ id: workspaceId }, isCurrentWorkspaceSelected] = useCurrentWorkspace();
	const apiRef = useGridApiRef();
	const [{ isGuest }] = useGetMe();

	const { data, isFetching } = useGetV4ResultsQuery(
		{
			workspaceId,
			pageSize: PAGE_SIZE,
			sort: '-startDate',
		},
		{
			skip: !isCurrentWorkspaceSelected,
			pollingInterval: pollingIntervalDurationInMs,
		},
	);

	const actions: DatagridAction[] = [];
	const searchCriterias: TestResultSearchCriteria | undefined = undefined;
	const columns: GridColDef<TestResult>[] = ResultsDataGridColumns(actions, isGuest, searchCriterias)
		.filter(({ field }) => DISPLAYED_COLS.has(field))
		.map((column) => ({
			...column,
			sortable: false,
		}));

	return (
		<Box
			sx={{
				minHeight: 0,
				paddingTop: 2,
			}}
			style={{ display: 'flex', flexDirection: 'column' }}
		>
			{data ? (
				<Datagrid
					{...DEFAULT_GRID_PROPS}
					apiRef={apiRef}
					rows={data.items}
					loading={isFetching}
					columns={columns}
					density={density}
					sortingMode='server'
					checkboxSelection={false}
					pagination={false}
					hideFooter={true}
					disableChildrenSorting={true}
					disableMultipleColumnsSorting={true}
					disableColumnSelector={true}
					disableColumnReorder={true}
					disableRowSelectionOnClick={true}
					sx={{
						borderLeft: 0,
						borderRight: 0,
					}}
				/>
			) : (
				<Spinner />
			)}
		</Box>
	);
};

import { CustomSizing, DynamicZoneFields, FixedSizing } from '@neoload/api';

export type SizingValues = Omit<CustomSizing, 'type'>;

export const FIXED_SIZES: FixedSizing['size'][] = ['SMALL', 'MEDIUM', 'LARGE'];

export const MEDIUM_SIZING: SizingValues = {
	controllerCpu: 4,
	controllerMemoryMb: 8000,
	lgCpu: 2,
	lgMemoryMb: 3000,
};

const SMALL_SIZING: SizingValues = {
	controllerCpu: 2,
	controllerMemoryMb: 3000,
	lgCpu: 1,
	lgMemoryMb: 1500,
};

const LARGE_SIZING: SizingValues = {
	controllerCpu: 8,
	controllerMemoryMb: 16_000,
	lgCpu: 2,
	lgMemoryMb: 4000,
};

export const MINIMUM_SIZING: SizingValues = {
	controllerCpu: 1.5,
	controllerMemoryMb: 2000,
	lgCpu: 1,
	lgMemoryMb: 1500,
};

export const MAXIMUM_SIZING: SizingValues = {
	controllerCpu: 128,
	controllerMemoryMb: 128_000,
	lgCpu: 64,
	lgMemoryMb: 64_000,
};

export const DEFAULT_CREATION_FORM_VALUES: DynamicZoneFields = {
	type: 'DYNAMIC',
	name: '',
	providerId: '',
	sizing: {
		type: 'FIXED',
		size: 'SMALL',
	},
	controllerDockerImage: 'neotys/neoload-controller:latest',
	lgDockerImage: 'neotys/neoload-loadgenerator:latest',
	pullSecret: '',
};

export const getSizingValues = (size: FixedSizing['size']): SizingValues => {
	switch (size) {
		case 'SMALL': {
			return SMALL_SIZING;
		}
		case 'MEDIUM': {
			return MEDIUM_SIZING;
		}
		case 'LARGE': {
			return LARGE_SIZING;
		}
		default: {
			return MEDIUM_SIZING;
		}
	}
};

import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import { memo } from 'react';
import deepEqual from 'deep-equal';
import { atLeastOne, atMostMaxInteger } from './custom-scenarios-helpers';
import { useCustomScenario } from '@neoload/hooks';
import { ExecutionPolicies, RampUpPopulation, RampUpRange } from '@neoload/api';

type RampUpProps = {
	population: RampUpPopulation;
};

const shouldUpdate = (prevProps: RampUpProps, nextProps: RampUpProps) =>
	prevProps.population.name === nextProps.population.name &&
	deepEqual(prevProps.population.rampUp, nextProps.population.rampUp) &&
	prevProps.population.executionPolicy === nextProps.population.executionPolicy;

const RampUp = memo(
	({ population }: RampUpProps) => {
		const { t } = useTranslation(['test']);
		const { updatePopulation } = useCustomScenario();

		const users = population.rampUp.users;
		const rampUpDuration = population.rampUp.every.amount;
		const unit = population.rampUp.every.unit;

		const updateRampUpVus = (users: number) => {
			updatePopulation(population.name, { rampUp: { users: atMostMaxInteger(atLeastOne(users)) } }, true);
		};
		const updateRampUpEvery = (every: number) => {
			if (population.executionPolicy === ExecutionPolicies.DURATION) {
				updatePopulation(population.name, { rampUp: { every: { amount: atLeastOne(every), unit } } }, true);
			} else {
				updatePopulation(
					population.name,
					{
						rampUp: { every: { amount: atLeastOne(every), unit: RampUpRange.ITERATIONS } },
					},
					true,
				);
			}
		};

		const updateRampUpUnit = (unit: RampUpRange) => {
			updatePopulation(population.name, { rampUp: { every: { unit } } }, true);
		};

		return (
			<Stack direction='row' useFlexGap gap={1} alignItems='center'>
				{t('configuration.scenarios.custom.add')}
				<TextField
					InputProps={{
						endAdornment: <InputAdornment position='end'>{t('configuration.scenarios.custom.vus')}</InputAdornment>,
					}}
					size='small'
					value={users}
					onChange={(event) => updateRampUpVus(Number.parseInt(event.target.value))}
					sx={{ width: '120px' }}
					type='number'
					inputProps={{ 'aria-label': t('configuration.scenarios.custom.aria.rampUpVus') }}
				/>
				{t('configuration.scenarios.custom.every')}
				<TextField
					size='small'
					value={rampUpDuration}
					onChange={(event) => updateRampUpEvery(Number.parseInt(event.target.value))}
					sx={{ width: '100px' }}
					type='number'
					inputProps={{ 'aria-label': t('configuration.scenarios.custom.aria.rampUpEvery') }}
				/>
				{population.executionPolicy === ExecutionPolicies.DURATION ? (
					<Select
						value={unit}
						onChange={(value) => updateRampUpUnit(value.target.value as RampUpRange)}
						variant='outlined'
						size='small'
						data-trackingid='custom-scenario-rampup-range'
					>
						<MenuItem value={RampUpRange.SECONDS}>{t('configuration.scenarios.custom.rampUpValues.seconds')}</MenuItem>
						<MenuItem value={RampUpRange.MINUTES}>{t('configuration.scenarios.custom.rampUpValues.minutes')}</MenuItem>
						<MenuItem value={RampUpRange.HOURS}>{t('configuration.scenarios.custom.rampUpValues.hours')}</MenuItem>
					</Select>
				) : (
					<>{t('configuration.scenarios.custom.iterationS')}</>
				)}
			</Stack>
		);
	},
	(prevProps, nextProps) => shouldUpdate(prevProps, nextProps),
);

export { RampUp };

import Toolbar from '@tricentis/aura/components/Toolbar.js';
import { ComponentPropsWithoutRef, MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridSortItem, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridRowModel, GridRowSelectionModel } from '@mui/x-data-grid/models';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { useWebhooksDatagridColumns } from './use-webhooks-data-grid-columns';
import { WebhooksEmptyState } from './webhooks-empty-state';
import { WebhookFormModal } from './webhook-form-modal';
import { Datagrid } from '../../../common/data-grid/datagrid';
import { DEFAULT_GRID_PROPS, DEFAULT_PAGINATION_MODEL, onColumnChange } from '../../../common/datagrid';
import { Spinner } from '../../../common/spinner';
import {
	DatagridAction,
	filterToContextMenuItems,
	filterToSecondaryActions,
} from '../../../common/data-grid/actions/data-grid-actions';
import { ContextMenu, ContextMenuFuncts } from '../../../common/context-menu/context-menu';
import { CreateResourceButton } from '../../../common/button/create-resource-button';
import { useDeleteV4WebhooksByWebhookIdMutation, useGetV4WebhooksQuery, Webhook } from '@neoload/api';
import { useColumnsState, useConfirmModal, useSetSnackbars } from '@neoload/hooks';
import { createNeoLoadError } from '@neoload/utils';

const columnsStateKey = 'WEBHOOKS_COLUMNS_STATE';

type WebhooksDataGridProps = {
	workspaceId: string;
};

type FormModalState = {
	open: boolean;
	editedWebHook?: Webhook;
};

const WebhooksDataGrid = ({ workspaceId }: WebhooksDataGridProps) => {
	const { t } = useTranslation(['workspace']);
	const apiRef = useGridApiRef();
	const { showInfo, showError } = useSetSnackbars();
	const [selectedWebhookIds, setSelectedWebhookIds] = useState<GridRowSelectionModel>([]);
	const { openDeleteConfirmModal } = useConfirmModal();
	const [paginationModel, setPaginationModel] = useState(DEFAULT_PAGINATION_MODEL);
	const [formModalState, setFormModalState] = useState<FormModalState>({ open: false });
	const { data: webhookPage, isFetching, isLoading, error: webhooksError } = useGetV4WebhooksQuery({ workspaceId });
	const [removeWebhook] = useDeleteV4WebhooksByWebhookIdMutation();

	const contextMenu: MutableRefObject<ContextMenuFuncts | undefined> = useRef();

	const deleteWebhook = async (webhookId: string) => {
		await removeWebhook({ webhookId: webhookId })
			.unwrap()
			.then(() => showInfo({ text: t('tabs.webhooks.deleteSuccess') }))
			.catch((_error) => showError({ text: t('tabs.webhooks.deleteError') }));
	};

	if (webhooksError) {
		throw createNeoLoadError(webhooksError);
	}

	const closeModal = () => setFormModalState({ open: false });

	const openCreationModal = () => setFormModalState({ open: true });

	const openEditionModal = (editedWebHookId: string) => {
		const editedWebHook = webhookPage?.items?.find((item) => item.id === editedWebHookId);
		if (editedWebHook) {
			setFormModalState({ open: true, editedWebHook: editedWebHook });
		}
	};

	const defaultGridSort: GridSortItem = { field: 'name', sort: 'asc' };
	const initialState = {
		sorting: {
			sortModel: [defaultGridSort],
		},
	};

	const actions: DatagridAction[] = [
		{
			icon: <EditOutlined />,
			text: t('common:edit'),
			tooltip: t('common:edit'),
			disabled: selectedWebhookIds.length !== 1,
			action: () => {
				if (selectedWebhookIds.length === 1) {
					openEditionModal(selectedWebhookIds[0] as string);
				}
			},
			singleItem: (row: Webhook) => ({
				disabled: false,
				action: () => {
					openEditionModal(row.id);
				},
			}),
		},
		{
			icon: <DeleteOutlined />,
			color: 'error',
			text: t('common:delete'),
			tooltip: t('common:delete'),
			disabled: selectedWebhookIds.length !== 1,
			action: () => {
				if (webhookPage) {
					const webhookToDelete = webhookPage.items.find((webhook) => webhook.id === selectedWebhookIds[0]);
					if (webhookToDelete) {
						openConfirmationDeleteWebhookDialog(webhookToDelete);
					}
				}
			},
			singleItem: (webhook: Webhook) => ({
				disabled: false,
				action: () => {
					openConfirmationDeleteWebhookDialog(webhook);
				},
			}),
		},
	];

	const columns: GridColDef<Webhook>[] = useWebhooksDatagridColumns(actions);
	const { updatedInitialState, updatedColumns, storeColumnState } = useColumnsState(
		columnsStateKey,
		initialState,
		columns,
		apiRef,
	);

	const componentsProps: {
		toolbar: ComponentPropsWithoutRef<typeof Toolbar>;
		row: GridRowModel;
	} = {
		toolbar: {
			displaySearchBox: true,
			hideFiltersIcon: true,
			displayColumnOptions: false,
			syncLocalStorage: {
				datagridId: 'neoloadWebhooksDataGrid',
				isSyncEnabled: true,
			},
			title: t('tabs.webhooks.title'),
			secondaryActions: selectedWebhookIds.length === 1 ? filterToSecondaryActions(actions) : [],
			mainActions: [
				{
					children: (
						<CreateResourceButton onClick={() => openCreationModal()}>
							{t('tabs.webhooks.zero.button')}
						</CreateResourceButton>
					),
				},
			],
		},
		row: {
			onContextMenu: contextMenu.current?.openContextMenu,
		},
	};

	if (isLoading) {
		return <Spinner />;
	}

	function openConfirmationDeleteWebhookDialog(webhook: Webhook) {
		openDeleteConfirmModal({
			title: t('tabs.webhooks.deleteWebhookDialog.title'),
			content: t('tabs.webhooks.deleteWebhookDialog.label'),
			handleConfirm: () => deleteWebhook(webhook.id),
		});
	}

	return (
		<>
			<WebhookFormModal
				closeModal={closeModal}
				workspaceId={workspaceId}
				open={formModalState.open}
				editedWebHook={formModalState.editedWebHook}
			/>
			{webhookPage === undefined || (webhookPage.total === 0 && !isFetching) ? (
				<WebhooksEmptyState openModal={openCreationModal} />
			) : (
				<>
					<Datagrid
						{...DEFAULT_GRID_PROPS}
						{...onColumnChange(storeColumnState)}
						loading={isFetching}
						apiRef={apiRef}
						checkboxSelection={false}
						rows={webhookPage?.items ?? []}
						initialState={updatedInitialState}
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}
						onRowSelectionModelChange={(model) => setSelectedWebhookIds(model)}
						columns={updatedColumns}
						disableRowSelectionOnClick={false}
						slotProps={componentsProps}
						slots={{
							toolbar: Toolbar,
						}}
					/>
					<ContextMenu apiRef={apiRef} ref={contextMenu} contextMenuItemsList={filterToContextMenuItems(actions)} />
				</>
			)}
		</>
	);
};
export { WebhooksDataGrid };

import dayjs from 'dayjs';
import dayjsRelativeTime from 'dayjs/plugin/relativeTime';
import dayjsDuration from 'dayjs/plugin/duration';
import dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjsUpdateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(dayjsRelativeTime, {
	rounding: Math.floor,
});
dayjs.extend(dayjsDuration);
dayjs.extend(dayjsLocalizedFormat);
dayjs.extend(dayjsUpdateLocale);
dayjs.updateLocale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: 'less than a minute',
		m: '1 minute',
		mm: '%d minutes',
		h: '1 hour',
		hh: '%d hours',
		d: '1 day',
		dd: '%d days',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		M: '1 month',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		MM: '%d months',
		y: '1 year',
		yy: '%d years',
	},
});

type DurationFormat = string | number | Date | null;
/**
 * Format the date as absolute date and time full
 * @param date the date to format. Ex: 2023-04-04T15:51:33.593Z or a timestamp in milliseconds
 * @returns Apr 4, 2023 15:51:33 PM
 */
const dateTimeAbsolute = (date: DurationFormat, format = 'll LTS'): string => {
	const parsedDate: dayjs.Dayjs = dayjs(date);
	if (!parsedDate.isValid()) {
		return '';
	}
	return parsedDate.format(format);
};

const dayFormat = (date: DurationFormat): string => dateTimeAbsolute(date, 'MMM D, YYYY');

/**
 * Format the date with a relative time from now
 * @param date the date to format. Ex: 2023-04-04T15:51:33.593Z or a timestamp in milliseconds
 * @returns 13 days ago
 */
const dateRelative = (date: DurationFormat): string => {
	const parsedDate: dayjs.Dayjs = dayjs(date);
	if (!parsedDate.isValid()) {
		return '';
	}
	// Relative date and duration formats can be customized if needed : https://day.js.org/docs/en/customization/relative-time
	return parsedDate.fromNow();
};

const parseDuration = (duration: number | string): dayjsDuration.Duration =>
	dayjs.duration(typeof duration === 'string' ? asMilliseconds(duration) : duration);

/**
 * Format the duration human readable with full precision
 * @param duration the number of milliseconds Ex: 127_000, or a duration with ISO-8601 format Ex: PT10S
 * @returns 2min 7s
 */
const durationFull = (duration: number | string): string => {
	const parsedDuration = parseDuration(duration);
	let format;
	if (parsedDuration.asYears() >= 1) {
		format = 'Y[Y] M[M] D[d] H[h] m[m] s[s]';
	} else if (parsedDuration.asMonths() >= 1) {
		format = 'M[M] D[d] H[h] m[m] s[s]';
	} else if (parsedDuration.asDays() >= 1) {
		format = 'D[d] H[h] m[m] s[s]';
	} else if (parsedDuration.asHours() >= 1) {
		format = 'H[h] m[m] s[s]';
	} else if (parsedDuration.asMinutes() >= 1) {
		format = 'm[m] s[s]';
	} else {
		format = 's[s]';
	}

	// Removing month, days, hours, minutes and seconds if 0
	return parsedDuration
		.format(format)
		.replace(' 0M', '')
		.replace(' 0d', '')
		.replace(' 0h', '')
		.replace(' 0m', '')
		.replace(' 0s', '');
};

/**
 * Format the duration human readable with a short label (only one unit)
 * @param duration the number of milliseconds Ex: 127_000, or a duration with ISO-8601 format Ex: PT10S
 * @returns 2 minutes
 */
const durationShort = (duration: number | string): string => parseDuration(duration).humanize();

/**
 * Format the duration to a HH:mm:ss or D[d] HH:mm:ss if more than a day
 * @param duration the number of milliseconds Ex: 127_000, or a duration with ISO-8601 format Ex: PT10S
 * @returns 00:02:07
 */
const durationSeconds = (duration: number | string): string => {
	const parsedDuration = parseDuration(duration);
	if (parsedDuration.asDays() >= 1) {
		return parsedDuration.format('D[d] HH:mm:ss');
	}
	return parsedDuration.format('HH:mm:ss');
};

/**
 * Format the duration to a HH:mm:ss.SSS or D[d] HH:mm:ss.SSS if more than a day
 * @param duration the number of milliseconds Ex: 127_000, or a duration with ISO-8601 format Ex: PT10S
 * @returns 00:02:07.123
 */
const durationMillis = (duration: number | string): string => {
	const parsedDuration = parseDuration(duration);
	if (parsedDuration.asDays() >= 1) {
		return parsedDuration.format('D[d] HH:mm:ss.SSS');
	}
	return parsedDuration.format('HH:mm:ss.SSS');
};

/**
 * Format the duration to a DD[d] HH:mm:ss.SSS regardless if more than a day
 * @param duration the number of milliseconds Ex: 127_000, or a duration with ISO-8601 format Ex: PT10S
 * @returns 00:02:07.123
 */
const durationMillisFull = (duration: number | string): string => {
	const parsedDuration = parseDuration(duration);
	return parsedDuration.format('DD[d] HH:mm:ss.SSS');
};
/**
 * Formats a duration range (start - duration) to a single string, each time formatted
 * as {@link durationSeconds} is
 * @param start start time, in number of milliseconds or iso string format
 * @param duration duration, in number of milliseconds or iso string format
 * @returns formatted string, for example 10:10:00 - 20:10:00
 * */
const rangeTimeDurationMillis = (start: number | string, duration: number | string): string =>
	rangeTimeMillis(start, parseDuration(start).asMilliseconds() + parseDuration(duration).asMilliseconds());

/**
 * Formats a duration range (start - end) to a single string, each time formatted
 * as {@link durationSeconds} is
 * @param start start time, in number of milliseconds or iso string format
 * @param end end time, in number of milliseconds or iso string format
 * @returns formatted string, for example 10:10:000 - 20:10:000
 * */
const rangeTimeMillis = (start: number | string, end: number | string): string =>
	durationMillis(start) + ' - ' + durationMillis(end);

/**
 * Formats a duration range (start - end) to a single string, each time formatted
 * as {@link rangeTimeSeconds} is
 * @param start start time, in number of milliseconds or iso string format
 * @param end end time, in number of milliseconds or iso string format
 * @returns formatted string, for example 10:10 - 20:10
 * */
const rangeTimeSeconds = (start: number | string, end: number | string): string =>
	durationSeconds(start) + ' - ' + durationSeconds(end);

/**
 * Convert a duration into a number of milliseconds
 * @param duration a duration with ISO-8601 format Ex: PT10S
 * @returns the duration as milliseconds number
 */
const asMilliseconds = (duration: string): number => Number(dayjs.duration(duration).asMilliseconds().toFixed(0));

/**
 * Convert a duration into a number of days
 * @param duration a duration with ISO-8601 format Ex: PT10S
 * @returns the duration as days number
 */
const asDays = (duration: string): number => Number(dayjs.duration(duration).asDays().toFixed(0));

export type RunDurations = {
	elapsedTime: number;
	duration: number;
};

/**
 * Create a RunDurations object composed of the elapsed time in milliseconds from the start date until now
 * and convert the given duration into milliseconds
 * @param startDate the date to compute time elapsed from. Ex: 2023-04-04T15:51:33.593Z
 * @param duration a duration with ISO-8601 format Ex: PT10S
 */
const getRunDurations = (startDate: string, duration: string): RunDurations => ({
	elapsedTime: getElapsedTimeMillisUntilNow(startDate),
	duration: dayjs.duration(duration).asMilliseconds(),
});

/**
 * Compute and convert the time remaining from the given start date to its full duration
 * @param startDate the date from which is computed the time remaining. Ex: 2023-04-04T15:51:33.593Z
 * @param duration the duration with ISO-8601 format Ex: PT10S
 */
const getRemainingTime = (startDate: string, duration: string): string => {
	const { elapsedTime, duration: durationMilli } = getRunDurations(startDate, duration);
	return elapsedTime < durationMilli ? durationFull(durationMilli - elapsedTime) : '';
};
/**
 * Compute and convert the time remaining from the given start date to its duration in milliseconds
 * @param startDate the date from which is computed the time remaining. Ex: 2023-04-04T15:51:33.593Z
 * @param duration the duration with ISO-8601 format Ex: PT10S
 */
const getRemainingTimeInMillis = (startDate: string, duration: string): number => {
	const { elapsedTime, duration: durationMilli } = getRunDurations(startDate, duration);
	return elapsedTime < durationMilli ? durationMilli - elapsedTime : 0;
};

/**
 * Compute percent of completion from the given start date to its full duration
 * @param startDate the date from which is computed the time remaining. Ex: 2023-04-04T15:51:33.593Z
 * @param duration the duration with ISO-8601 format Ex: PT10S
 */
const getRemainingTimePercent = (startDate: string, duration: string): number | undefined => {
	const { elapsedTime, duration: durationMilli } = getRunDurations(startDate, duration);

	if (durationMilli === 0) {
		return undefined;
	}

	if (elapsedTime > durationMilli) {
		return 100;
	}

	return Math.round((Math.abs(elapsedTime) / durationMilli) * 100);
};

/**
 * Compute and convert the duration from the given start date to now
 * @param startDate the date from which is computed the elapsed time. Ex: 2023-04-04T15:51:33.593Z
 */
const getElapsedTimeUntilNow = (startDate: string): string => durationFull(-dayjs(startDate).diff());

/**
 * @param startDate the date from which is computed the elapsed time. Ex: 2023-04-04T15:51:33.593Z
 * @returns the number of milliseconds between startDate and now
 */
const getElapsedTimeMillisUntilNow = (startDate: string): number => -dayjs(startDate).diff();

/**
 * Rounds the duration to the closest second and returns it as an iso string
 * @param durationMillis the duration as millis
 */
const toIsoStringRoundedToSeconds = (durationMillis: number): string =>
	parseDuration(Math.round(durationMillis / 1000) * 1000).toISOString();

const isDatePast = (date: string | null): boolean => dayjs(date).isBefore(dayjs(), 'second');

const isDateArriveInNextMonth = (date: string): boolean => {
	const daysDiff = dayjs().diff(dayjs(date), 'day');
	return daysDiff >= -30 && daysDiff <= 0 && !isDatePast(date);
};

const setWeekStartOnMonday = () => {
	dayjs.updateLocale('en', {
		weekStart: 1,
	});
};

const timeUtils = {
	parseDuration,
	dateTimeAbsolute,
	dateRelative,
	durationFull,
	durationShort,
	rangeTimeMillis,
	rangeTimeDurationMillis,
	durationMillis,
	durationMillisFull,
	durationSeconds,
	asMilliseconds,
	asDays,
	getRemainingTime: getRemainingTime,
	getRemaining: getRemainingTimeInMillis,
	getRemainingTimePercent,
	getElapsedTimeUntilNow,
	getElapsedTimeMillisUntilNow,
	rangeTimeSeconds,
	toIsoStringRoundedToSeconds,
	isDatePast,
	isDateArriveInNextMonth,
	setWeekStartOnMonday,
	dayFormat,
};

export { timeUtils };

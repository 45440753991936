import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventType, useFormContext, WatchObserver } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import { useDebouncedCallback } from 'use-debounce';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { Form } from './allocate-dedicated-ips-modal';
import { COST_IN_CLOUD_CREDITS_PER_DAY_PER_IP } from './utils';
import { CloudZoneRead, usePatchV4ZonesByZoneIdMutation } from '@neoload/api';

type AllocateDedicatedIpsFormProps = {
	cloudZones: CloudZoneRead[];
	availableCloudCredits: number;
};
type Info = {
	name?: 'zoneId' | 'reservedIpsCount' | 'modificationCostInCloudCredits';
	type?: EventType;
	values?: unknown;
};
type FormValues = {
	zoneId?: string;
	reservedIpsCount?: number;
	modificationCostInCloudCredits?: number;
};

const CustomSpinner = () => (
	<Box
		sx={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}
	>
		<CircularProgress size={12} />
	</Box>
);

export const AllocateDedicatedIpsForm = ({ cloudZones, availableCloudCredits }: AllocateDedicatedIpsFormProps) => {
	const { t } = useTranslation(['zone']);
	const { register, watch, setValue, setError, trigger, formState, reset } = useFormContext<Form>();
	const theme = useTheme();

	const zoneId = watch('zoneId');
	const reservedIpsCountToSet = watch('reservedIpsCount');
	const modificationCostInCloudCredits = Number(watch('modificationCostInCloudCredits')) || 0;

	const zone = useMemo(() => cloudZones.find((cloudZone) => cloudZone.zoneId === zoneId), [zoneId, cloudZones]);
	const [patchPromise, setPatchPromise] = useState<ReturnType<typeof patchZone> | null>(null);
	const reservedIpsCount = zone?.dedicatedIpsDetails?.reservedIpsCount ?? 0;
	const availableIpsCount = zone?.dedicatedIpsDetails?.availableIpsCount ?? 0;
	const totalIpsCount = reservedIpsCount + availableIpsCount;
	const [isWaitingForDryRunPatch, setIsWaitingForDryRunPatch] = useState(false);

	const [patchZone] = usePatchV4ZonesByZoneIdMutation();

	useEffect(() => {
		if (cloudZones.length <= 0) {
			setError('zoneId', { message: t('cloudZone.dedicatedIps.allocationDialog.zoneSelectionError') });
		}
	}, [cloudZones.length, setError, t]);

	const fetchModificationCostInCloudCredits = useDebouncedCallback((values) => {
		const zone = patchZone({
			zoneId: values.zoneId,
			dryRun: true,
			patchZoneFields: {
				type: 'CLOUD',
				dedicatedIpsDetails: {
					reservedIpsCount: values.reservedIpsCount,
				},
			},
		});
		setPatchPromise(zone);
		zone
			.unwrap()
			.then((response) => {
				if (
					response?.type === 'CLOUD' &&
					typeof response.dedicatedIpsDetails?.modificationCostInCloudCredits === 'number'
				) {
					const costInCloudCredits = response.dedicatedIpsDetails.modificationCostInCloudCredits;
					setValue('modificationCostInCloudCredits', costInCloudCredits);
				} else {
					console.error('Could not fetch Cloud Credits estimation estimation');
					setError('modificationCostInCloudCredits', {
						message: 'Unable to determine the cost in cloud credits for this operation. Please try again later.',
					});
				}
			})
			.then(() => trigger('modificationCostInCloudCredits'))
			.catch((error) => {
				if (error.name === 'AbortError') {
					console.log('Request aborted due to a new request being triggered.');
				} else {
					console.error('Could not fetch Cloud Credits estimation', error);
					setError('modificationCostInCloudCredits', {
						message: 'Unable to determine the cost in cloud credits for this operation. Please try again later.',
					});
				}
			})
			.finally(() => {
				setPatchPromise(null);
				setIsWaitingForDryRunPatch(false);
			});
	}, 1000);

	function updateZoneInfo(zoneId: string) {
		const cloudZone = cloudZones.find((cloudZone) => cloudZone.zoneId === zoneId);
		if (cloudZone?.dedicatedIpsDetails) {
			fetchModificationCostInCloudCredits.cancel();
			setIsWaitingForDryRunPatch(false);
			if (patchPromise) {
				patchPromise.abort();
				setPatchPromise(null);
			}
			reset({
				zoneId,
				reservedIpsCount: cloudZone.dedicatedIpsDetails.reservedIpsCount,
				modificationCostInCloudCredits: 0,
			});
		}
	}
	useEffect(() => {
		const subscription = watch((values, info) => {
			if (shouldTriggerDryRun(info, values)) {
				fetchModificationCostInCloudCredits(values);
			}
		});
		return () => subscription.unsubscribe();
	}, [fetchModificationCostInCloudCredits, watch, zone?.dedicatedIpsDetails?.reservedIpsCount]);

	return (
		<Stack spacing={2} gap={2}>
			<Box>
				<FormControl required fullWidth>
					<InputLabel id='zone'>{t('cloudZone.dedicatedIps.allocationDialog.zone')}</InputLabel>
					<Select
						label={t('cloudZone.dedicatedIps.allocationDialog.zone')}
						labelId='zone'
						required={true}
						error={!!formState.errors.zoneId}
						{...register('zoneId', {
							required: true,
						})}
						value={zoneId}
						onChange={(event) => {
							updateZoneInfo(event.target.value);
						}}
						MenuProps={{
							style: {
								maxHeight: 250,
							},
						}}
						fullWidth
						size='small'
					>
						{cloudZones.map((cloudZone) => (
							<MenuItem key={cloudZone.zoneId} value={cloudZone.zoneId}>
								{cloudZone.name}
							</MenuItem>
						))}
					</Select>
					<FormHelperText error={!!formState.errors.zoneId}>
						{formState.errors.zoneId?.message ??
							t('cloudZone.dedicatedIps.allocationDialog.loadGeneratorUsage', {
								lgCount: zone?.loadGenerators.length ?? 0,
							})}
					</FormHelperText>
				</FormControl>
			</Box>
			<Box>
				<Stack spacing={2} direction='row' alignItems='center'>
					<FormControl required>
						<TextField
							label={t('cloudZone.dedicatedIps.allocationDialog.ipCount')}
							type='number'
							inputMode='numeric'
							inputProps={{
								min: 0,
							}}
							size='small'
							required={true}
							{...register('reservedIpsCount', {
								required: true,
								min: 0,
								max: totalIpsCount,
								valueAsNumber: true,
								onChange: (event) => {
									if (event.target.value === '') {
										fetchModificationCostInCloudCredits.cancel();
										setValue('modificationCostInCloudCredits', 0);
										setIsWaitingForDryRunPatch(false);
										return;
									}
									if (event.target.value > totalIpsCount) {
										setValue('reservedIpsCount', totalIpsCount);
									}
									setIsWaitingForDryRunPatch(true);
									setValue('modificationCostInCloudCredits', 0);
								},
							})}
							value={reservedIpsCountToSet}
							error={!!formState.errors.reservedIpsCount}
						/>
					</FormControl>
					<Box>
						<Typography>
							{t('cloudZone.dedicatedIps.allocationDialog.expirationDate', {
								date: formatDate(zone?.dedicatedIpsDetails?.expirationDate),
							})}
						</Typography>
					</Box>
				</Stack>
				<FormHelperText error={!!formState.errors.reservedIpsCount} sx={{ marginLeft: '14px' }}>
					{reservedIpsCountToSet ? `${reservedIpsCountToSet}/${totalIpsCount}` : `0/${totalIpsCount}`}
				</FormHelperText>
			</Box>
			<Box>
				<Box display='flex' flexDirection='row'>
					<input
						type='hidden'
						{...register('modificationCostInCloudCredits', {
							valueAsNumber: true,
							validate: {
								validateCloudCredits: (value) => {
									if (value === undefined) {
										return false;
									}
									return value <= availableCloudCredits;
								},
							},
							required: true,
						})}
					/>
					<Box display='flex' flexDirection='row' gap={1} whiteSpace='nowrap' alignItems='center'>
						{isWaitingForDryRunPatch ? (
							<Typography color={(theme) => theme.palette.text.secondary}>
								<Box display='flex' alignItems='center' flexDirection='row' gap={1}>
									{t('cloudZone.dedicatedIps.allocationDialog.cloudCreditsText')}
									<CustomSpinner />
									<Typography color={(theme) => theme.palette.text.primary}>
										{t('cloudZone.dedicatedIps.allocationDialog.fetching')}
									</Typography>
									{t('cloudZone.dedicatedIps.allocationDialog.cloudCreditsAvailable', {
										cloudCredits: availableCloudCredits,
									})}
								</Box>
							</Typography>
						) : (
							<Typography
								color={(theme) => theme.palette.text.secondary}
								display='flex'
								flexDirection='row'
								gap={1}
								alignItems='center'
							>
								{t('cloudZone.dedicatedIps.allocationDialog.cloudCreditsText')}
								<FormHelperText
									style={{
										fontSize: 'inherit',
										whiteSpace: 'nowrap',
										color: formState.errors.modificationCostInCloudCredits ? 'error' : theme.palette.text.primary,
										marginTop: 0,
									}}
									error={!!formState.errors.modificationCostInCloudCredits}
									data-testid='cloud-credits-modification-cost'
									component='span'
								>
									<Box display='flex' alignItems='center' gap={1} marginTop={0}>
										{formState.errors.modificationCostInCloudCredits && <ErrorOutline color='error' fontSize='small' />}
										{modificationCostInCloudCredits ?? 0}
									</Box>
								</FormHelperText>
								{t('cloudZone.dedicatedIps.allocationDialog.cloudCreditsAvailable', {
									cloudCredits: availableCloudCredits,
								})}
							</Typography>
						)}
						<Tooltip
							arrow
							placement='bottom'
							title={t('cloudZone.dedicatedIps.costPerDayPerIp', {
								costInCloudCreditsPerDayPerIp: COST_IN_CLOUD_CREDITS_PER_DAY_PER_IP,
							})}
						>
							<InfoOutlined fontSize='small' color='secondary' />
						</Tooltip>
					</Box>
				</Box>
				{formState.errors.modificationCostInCloudCredits && (
					<Typography variant='bodyRegular' fontStyle='inter' color='error'>
						{formState.errors.modificationCostInCloudCredits.message}
					</Typography>
				)}
			</Box>
		</Stack>
	);
};

function formatDate(date: string | undefined): string {
	if (!date) {
		return 'N/A';
	}
	return dayjs(date).format('MM/DD/YYYY');
}

type TriggeringFieldName = Parameters<WatchObserver<Form>>[1]['name'];

const TRIGGERING_FIELD_NAME_BLACKLIST: Set<TriggeringFieldName> = new Set(['modificationCostInCloudCredits', 'zoneId']);
const isTriggeredByUser = (type: EventType | undefined) => !!type;
const isTriggeringFieldNameValid = (name: TriggeringFieldName | undefined) =>
	!TRIGGERING_FIELD_NAME_BLACKLIST.has(name);

function shouldTriggerDryRun(info: Info, values: FormValues) {
	return (
		isTriggeredByUser(info.type) &&
		isTriggeringFieldNameValid(info.name) &&
		values.zoneId &&
		values.reservedIpsCount !== undefined &&
		values.reservedIpsCount >= 0
	);
}

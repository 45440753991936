import { useTranslation } from 'react-i18next';
import ZeroState from '@tricentis/aura/components/ZeroState.js';
import Typography from '@mui/material/Typography';
import { ButtonProps } from '@mui/material/Button';
import { Key, MouseEventHandler } from 'react';
import NoInfrastructureProvider from '../../../assets/NoInfrastructureProvider.svg';
import { CreateResourceButton } from '../../common/button/create-resource-button';
import { IllustrationWithBackground } from '../../common/illustration-with-background';

type ButtonPropsWithKey = ButtonProps & { key: Key };

type InfrastructureProviderEmptyStateProps = {
	createAction: MouseEventHandler<HTMLButtonElement>;
};

const InfrastructureProviderEmptyState = ({ createAction }: InfrastructureProviderEmptyStateProps) => {
	const { t } = useTranslation(['infrastructureProviders']);

	const actions: ButtonPropsWithKey[] = [
		{
			key: 'createProvider',
			variant: 'contained',
			onClick: createAction,
		},
	];

	const title = t('providers.zero.title');

	return (
		<ZeroState
			illustration={<IllustrationWithBackground image={NoInfrastructureProvider} title={title} />}
			title={title}
			actions={actions.map(({ key, ...others }) => (
				<CreateResourceButton {...others} key={key}>
					{t('providers.zero.button')}
				</CreateResourceButton>
			))}
			containerSx={{ backgroundColor: 'transparent' }}
		>
			<Typography variant='body2'>{t('providers.zero.content')}</Typography>
		</ZeroState>
	);
};

export { InfrastructureProviderEmptyState };

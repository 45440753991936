import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import i18n from 'i18next';
import { withLazyFetching, WithLazyFetchingProps } from './with-lazy-fetching';
import { useWidgetDataFetcher } from './hooks/use-widget-data-fetcher';
import { Spinner } from '../../../common/spinner';
import { DashboardTileErrorContent } from '../tiles/dashboard-tile-error-content';
import { Cell, TableGrid } from '../../../common/table-grid';
import { DashboardTileUnloadedContent } from '../tiles/dashboard-tile-unloaded-content';
import {
	ColumnDefinition,
	columnDefinitionsToColumns,
	sortToSortColumnIndex,
	sortToSortDirection,
} from '../dashboard-common';
import { CsvDownloadContext } from '../tiles/tile/csv-download-context';
import {
	ErrorCountByLg,
	ErrorsCountFilter,
	GetV4ResultsByResultIdEventsErrorsApiArg,
	GetV4ResultsByResultIdEventsErrorsApiResponse,
	useLazyGetV4ResultsByResultIdEventsErrorsQuery,
	WidgetDashboardTile,
} from '@neoload/api';

type ErrorsByLgFetcherProps = {
	widgetTile: WidgetDashboardTile;
} & WithLazyFetchingProps;
const columns = [
	{ label: i18n.t('errors.loadGenerator', { ns: 'dashboard' }) },
	{ label: i18n.t('errors.errorCount', { ns: 'dashboard' }) },
];
export const InternalErrorsByLgFetcher = ({ shouldStartFetching, widgetTile }: ErrorsByLgFetcherProps) => {
	const { t } = useTranslation(['dashboard']);
	const [triggerEventsErrorsByLg] = useLazyGetV4ResultsByResultIdEventsErrorsQuery();
	const { setDataToDownload } = useContext(CsvDownloadContext);
	const { pageSize, pageNumber, sort } = widgetTile.filter as ErrorsCountFilter;

	const [data, error, loadingState] = useWidgetDataFetcher<
		GetV4ResultsByResultIdEventsErrorsApiArg,
		GetV4ResultsByResultIdEventsErrorsApiResponse
	>(
		widgetTile,
		{
			errorsType: 'LG',
			resultId: widgetTile.resultId,
			pageSize,
			pageNumber,
			sort,
		},
		shouldStartFetching,
		triggerEventsErrorsByLg
	);

	useEffect(() => {
		if (loadingState === 'LOADED' && data) {
			setDataToDownload({
				columns,
				values: errorsByLgArrayToTable(data.items as ErrorCountByLg[]),
			});
		}
	}, [loadingState, data, setDataToDownload]);

	if (loadingState === 'UNLOADED') {
		return <DashboardTileUnloadedContent />;
	}
	if (loadingState === 'LOADING') {
		return <Spinner />;
	}
	if (error || !data) {
		if (error) {
			console.error(
				t('widget.error.global', {
					tileId: widgetTile.id,
				}),
				error
			);
		}
		return <DashboardTileErrorContent />;
	}

	const columnDefs: ColumnDefinition[] = [
		{
			name: 'LOADGENERATOR',
			i18nKey: 'errors.loadGenerator',
		},
		{
			name: 'COUNT',
			i18nKey: 'errors.errorCount',
			align: 'right',
		},
	];

	return (
		<TableGrid
			columns={columnDefinitionsToColumns(columnDefs, t)}
			sortColumnIndex={sortToSortColumnIndex(columnDefs, sort)}
			sortDirection={sortToSortDirection(sort)}
			values={errorsByLgArrayToTable(data.items as ErrorCountByLg[])}
		/>
	);
};

export const VisibleForTesting = InternalErrorsByLgFetcher;
export const ErrorsByLgFetcher = withLazyFetching(InternalErrorsByLgFetcher);

function errorsByLgArrayToTable(errorsByLgs: ErrorCountByLg[]): Cell[][] {
	const rows = [];
	for (const errorByLg of errorsByLgs) {
		rows.push(errorsByLgToTableRow(errorByLg));
	}
	return rows;
}

function errorsByLgToTableRow(errorsByLg: ErrorCountByLg): Cell[] {
	return [{ text: errorsByLg.lg }, { text: errorsByLg.count.toString() }];
}

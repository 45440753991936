import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import i18n from 'i18next';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useFormContext, useWatch } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import EditOutlined from '@mui/icons-material/EditOutlined';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import IconButton from '@mui/material/IconButton';
import { TestExecutionForm } from '../../types.d';

/* eslint-disable @typescript-eslint/naming-convention */
const I18N_KEYS_BY_VU_TYPE = {
	SAP: 'test:testExecution:sap',
	WEB: 'test:testExecution:web',
} as const;
const HOOK_VU_NEEDED_KEYS_BY_VU_TYPE = {
	SAP: 'resources.sapVu.needed',
	WEB: 'resources.webVu.needed',
} as const;
const HOOK_VU_AVAILABLE_KEYS_BY_VU_TYPE = {
	SAP: 'resources.sapVu.available',
	WEB: 'resources.webVu.available',
} as const;
/* eslint-enable @typescript-eslint/naming-convention */

type VusLicenseAllocationProps = {
	typeVu: 'WEB' | 'SAP';
};
const VusLicenseAllocation = ({ typeVu }: VusLicenseAllocationProps) => {
	const { t } = useTranslation(['test']);
	const theme = useTheme();
	const { setValue } = useFormContext<TestExecutionForm>();
	const [edit, setEdit] = useState(false);
	const errorsInFirstCall = useWatch<TestExecutionForm, 'errorsInFirstCall'>({ name: 'errorsInFirstCall' });
	const reservationId = useWatch<TestExecutionForm, 'resources.reservationId'>({ name: 'resources.reservationId' });
	const formatterValue = new Intl.NumberFormat(i18n.language, {
		compactDisplay: 'short',
		minimumIntegerDigits: 1,
	});
	//useWatch is not able to resolve value type because of the dynamic name. But these values are numbers.
	const vusNeeded = useWatch({ name: HOOK_VU_NEEDED_KEYS_BY_VU_TYPE[typeVu] }) as number;
	const vusAvailable = useWatch({ name: HOOK_VU_AVAILABLE_KEYS_BY_VU_TYPE[typeVu] }) as number;
	const vusNeededAsString = `${vusNeeded}`;

	const parseIntSafely = (value: string) => {
		const number = Number.parseInt(value);
		if (number < 0 || Number.isNaN(number)) {
			return 0;
		}
		if (number > vusAvailable) {
			return vusAvailable;
		}
		return number;
	};

	const [localValue, setLocalValue] = useState<string>(vusNeededAsString);

	const allowedEdition = !(errorsInFirstCall || vusAvailable === 0 || reservationId);
	return (
		<Stack useFlexGap marginBottom='6px' flexDirection='row' gap={theme.spacing(1)} alignItems='center'>
			<Typography color='text.secondary' variant='body2'>
				{t(I18N_KEYS_BY_VU_TYPE[typeVu])}
			</Typography>
			{edit ? (
				<TextField
					size='small'
					placeholder={vusNeededAsString}
					type='number'
					sx={{ width: '80px' }}
					onChange={(event) => setLocalValue(parseIntSafely(event.currentTarget.value).toString())}
					onBlur={(event) => {
						setLocalValue(parseIntSafely(event.currentTarget.value).toString());
						setValue(HOOK_VU_NEEDED_KEYS_BY_VU_TYPE[typeVu], parseIntSafely(event.currentTarget.value));
						setEdit(false);
					}}
					autoFocus={true}
					value={localValue}
					InputProps={{ inputProps: { min: 0, max: vusAvailable } }}
				/>
			) : (
				<Typography
					color='text.primary'
					variant='body2'
					aria-label={typeVu}
					onClick={() => {
						allowedEdition ? setEdit(true) : setEdit(false);
					}}
				>
					{vusNeeded}
				</Typography>
			)}
			{!reservationId && (
				<Typography paddingRight={1} color='text.secondary' variant='caption' aria-label={`${typeVu}-available`}>
					({formatterValue.format(vusAvailable)} {t('test:testExecution:available')})
				</Typography>
			)}
			{allowedEdition && !edit && (
				<Tooltip arrow title={t('common:edit')} sx={{ width: 4, height: 4 }}>
					<IconButton
						onClick={() => {
							setEdit(true);
						}}
					>
						<EditOutlined color='action' />
					</IconButton>
				</Tooltip>
			)}
		</Stack>
	);
};

export { VusLicenseAllocation };

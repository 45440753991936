const infrastructureProviders = {
	providers: {
		title: 'Infrastructure providers',
		columns: {
			name: {
				title: 'Name',
			},
			type: {
				title: 'Provider type',
			},
			url: {
				title: 'URL',
			},
			viewDetails: 'View details',
		},
		details: {
			title: 'Infrastructure provider details',
			namespace: 'Namespace',
			labels: 'Labels',
			nodeSelectors: 'Node selectors',
			annotations: 'Annotations',
			tolerations: 'Tolerations',
		},
		zero: {
			button: 'Add infrastructure provider',
			title: 'Create your first infrastructure provider',
			content: 'Infrastructure providers allow launching tests using resources dynamically deploy on Kubernetes',
		},
		delete: {
			title: 'Delete infrastructure provider?',
			label:
				"Deleting the infrastructure provider will permanently erase it from your data. It's not reversible, and you won't be able to retrieve it once removed.",
		},
		addProvider: 'Add provider',
	},
	delete: {
		success_one: 'Infrastructure provider successfully deleted',
		error_one: 'An unexpected error occurred',
		error405_one: 'Your license does not allow managing dynamic infrastructures.',
		error409_one: 'This provider cannot be deleted because it is used by a zone.',
	},
	modal: {
		creationTitle: 'Add infrastructure provider',
		editionTitle: 'Edit infrastructure provider',
		addProvider: 'Add provider',
		providerName: 'Provider name',
		apiUrl: 'API URL',
		urlFormat: 'Please enter a valid URL, example: https://my-server.com',
		apiToken: 'API token',
		namespace: 'Namespace',
		project: 'Project',
		showToken: 'Show token',
		hideToken: 'Hide token',
		type: 'Type',
		types: {
			/* eslint-disable @typescript-eslint/naming-convention*/
			KUBERNETES: 'Kubernetes',
			OPENSHIFT: 'OpenShift',
			/* eslint-enable @typescript-eslint/naming-convention */
		},
		additionalParameters: 'Additional parameters',
		labels: 'Labels',
		nodeSelectors: 'Node selectors',
		annotations: 'Annotations',
		tolerations: 'Tolerations',
		openHelp: 'Open online help',
		learnMore: 'Learn more',
		nameSpacePatternError: 'Invalid namespace pattern',
		projectPatternError: 'Invalid project name pattern',
		labelPatternError: 'Label must match the pattern: key1=value,key2=value',
		annotationsPatternError: 'Annotation must match the pattern: key1=value,key2=value',
		nodeSelectorsPatternError: 'Node selector must match the pattern: key1=value,key2=value',
		patternPlaceholderText: 'key1=value,key2=value',
		tolerationPlaceHolderText:
			'- key: "key1"\n' +
			'  operator: "Equal"\n' +
			'  value: "value1"\n' +
			'  effect: "NoExecute"\n' +
			'  tolerationSeconds: 3600',
		creationSuccessReady: 'Infrastructure provider {{name}} successfully created',
		creationSuccessNotReady:
			'Infrastructure provider {{name}} successfully created but connectivity check returned the following error : \n{{error}}',
		errors: {
			fail: 'Failed to {{action}}',
			failNotAllowed: 'Failed to {{action}}. Your license does not allow managing dynamic infrastructures.',
			action: {
				test: 'test connexion',
				create: 'create infrastructure provider',
				update: 'update infrastructure provider',
			},
		},
		providerCreated: 'Infrastructure provider {{name}} successfully created',
		providerUpdated: 'Infrastructure provider {{name}} successfully updated',
		invalidSchema: 'YAML schema does not respect toleration schema',
		invalidYaml: 'Input is not a valid YAML',
		hideDetails: 'Hide details',
		showDetails: 'Show details',
		connectivityNotReady: 'An error occurred. Verify your cluster is available and your parameters are correct.',
		save: 'Save',
		saveAnyway: 'Save anyway',
		add: 'Add',
		addAnyway: 'Add anyway',
		testConnection: 'Test connection',
		connectivityReady: 'The dynamic infrastructure is ready to use.',
	},
};

export { infrastructureProviders };

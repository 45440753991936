import { ChipProps } from '@mui/material/Chip';
import CachedOutlined from '@mui/icons-material/CachedOutlined';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ChipStatus from '@tricentis/aura/components/ChipStatus.js';
import { TFunction } from 'i18next';
import { styleTypo } from '../../features/results/details/styles-utils';

export type InitializingChipProps = {
	withLabel?: boolean;
	step?: number;
	stepName?: string;
	onClick?: MouseEventHandler<HTMLDivElement>;
	size?: ChipProps['size'];
};

const getLabel = (t: TFunction, step?: number) =>
	step === undefined ? t('resultStatus.initializing') : t('resultStatus.initializingWithStep', { step });

const InitializingChip = ({ withLabel = false, step, stepName, onClick, size = 'medium' }: InitializingChipProps) => {
	const { t } = useTranslation(['result']);

	return (
		<>
			<ChipStatus
				icon={
					<CachedOutlined
						sx={{
							animation: 'spin 2s linear infinite',
							'@keyframes spin': {
								'0%': {
									transform: 'rotate(360deg)',
								},
								'100%': {
									transform: 'rotate(0deg)',
								},
							},
						}}
					/>
				}
				label={withLabel ? getLabel(t, step) : null}
				onClick={onClick}
				clickable={!!onClick}
				size={size}
				status='Running'
				data-testid='test-result-init-chip'
			/>
			{stepName !== undefined && (
				<Typography variant='caption' sx={styleTypo} data-testid='test-result-init-step-name'>
					{stepName}
				</Typography>
			)}
		</>
	);
};

export { InitializingChip };

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { WebhookFilters } from '@neoload/api';

export const QualityStatusValueInput = () => {
	const { t } = useTranslation('workspace', { keyPrefix: 'tabs.webhooks.filters' });
	const { register, watch } = useFormContext<WebhookFilters>();
	const value = watch('byQualityStatus.value');
	const labelId = 'quality-status-value-label';

	return (
		<FormControl>
			<InputLabel id={labelId}>{t('value')}</InputLabel>
			<Select
				defaultValue={value}
				{...register('byQualityStatus.value')}
				size='small'
				label={t('value')}
				aria-labelledby={labelId}
				labelId={labelId}
			>
				{['PASSED', 'FAILED'].map((status) => (
					<MenuItem key={status} value={status}>
						{t(`status${status}`)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { useTranslation } from 'react-i18next';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { DatagridAction } from '../../common/data-grid/actions/data-grid-actions';
import { InfrastructureProvider, useDeleteV4InfrastructureProvidersByIdMutation } from '@neoload/api';
import { useConfirmModal, useCrudBatchMessage, useGetMe } from '@neoload/hooks';

export const useInfrastructureProviderDatagridActions = (
	editInfraProvider: (provider: InfrastructureProvider) => void,
	selectedProvider?: InfrastructureProvider
): DatagridAction[] => {
	const { openDeleteConfirmModal } = useConfirmModal();
	const [deleteInfrastructureProvider] = useDeleteV4InfrastructureProvidersByIdMutation();
	const [{ hasAdminPermissions }] = useGetMe();

	const { t } = useTranslation(['infrastructureProviders']);

	const { remove } = useCrudBatchMessage('infrastructureProviders');
	const deleteProvider = async (providerIds: string[]) => {
		await remove(providerIds, (providerId) => deleteInfrastructureProvider({ id: providerId }).unwrap());
	};

	const openConfirmationInfrastructureProviderDialog = (infrastructureProvider: InfrastructureProvider) => {
		const title = t('providers.delete.title', { name: infrastructureProvider.name });
		const content = t('providers.delete.label', { name: infrastructureProvider.name });
		openDeleteConfirmModal({
			title,
			content,
			handleConfirm: () => deleteProvider([infrastructureProvider.id]),
		});
	};

	if (!hasAdminPermissions) {
		return [];
	}

	return [
		{
			icon: <EditOutlined />,
			text: t('common:edit'),
			action: () => !!selectedProvider && editInfraProvider(selectedProvider),
			disabled: false,
			singleItem: (row: InfrastructureProvider) => ({
				disabled: false,
				action: () => {
					editInfraProvider(row);
				},
			}),
		},
		{
			icon: <DeleteOutlined />,
			text: t('common:delete'),
			color: 'error',
			action: () => !!selectedProvider && openConfirmationInfrastructureProviderDialog(selectedProvider),
			disabled: false,
			singleItem: (row: InfrastructureProvider) => ({
				disabled: false,
				action: () => {
					openConfirmationInfrastructureProviderDialog(row);
				},
			}),
		},
	];
};

import { ZonesRoutes } from './settings/zones';
import { WorkspaceRoutes } from './settings/workspace';

const patterns = {
	base: '/settings',
	generalSettings: '/settings/general',
	users: '/settings/users',
	infrastructureProviders: '/settings/infrastructure-providers',
	subscription: '/settings/subscription',
	license: '/settings/license',
	reservation: '/settings/reservations',
	usageHistory: '/settings/usage-history',
};

const settingsRoutes = {
	patterns: patterns,
	base: patterns.base,
	generalSettings: patterns.generalSettings,
	users: patterns.users,
	workspaces: WorkspaceRoutes,
	infrastructureProviders: patterns.infrastructureProviders,
	subscription: patterns.subscription,
	license: patterns.license,
	zones: ZonesRoutes,
	reservation: patterns.reservation,
	usageHistory: patterns.usageHistory,
};

export { settingsRoutes as SettingsRoutes };

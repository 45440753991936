import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { DatagridAction } from '../../common/data-grid/actions/data-grid-actions';
import { Repository, useDeleteV4ScmRepositoriesByRepositoryIdMutation } from '@neoload/api';
import { useConfirmModal, useCrudBatchMessage } from '@neoload/hooks';

export const useGitRepoDatagridActions = (
	selectedIds: GridRowSelectionModel,
	openEditModal: (repositoryId: string) => void,
): DatagridAction[] => {
	const { openDeleteConfirmModal } = useConfirmModal();
	const { remove } = useCrudBatchMessage('user', 'repository');
	const [deleteRepo] = useDeleteV4ScmRepositoriesByRepositoryIdMutation();
	const { t } = useTranslation(['user']);

	const deleteRows = async (reposToDelete: string[]) => {
		await remove(reposToDelete, (repoId) => deleteRepo({ repositoryId: repoId }).unwrap());
	};

	const openConfirmationRepoDialog = (repositoryId: string) => {
		const title = t('repository.deleteSingleRepoDialog.title');
		const content = t('repository.deleteSingleRepoDialog.label');
		openDeleteConfirmModal({
			title,
			content,
			handleConfirm: () => deleteRows([repositoryId]),
		});
	};

	const openDeleteMultipleDialog = () => {
		const title = t('repository.deleteMultipleRepoDialog.title', {
			repositoriesNumber: selectedIds.length.toString(),
		});
		const content = t('repository.deleteMultipleRepoDialog.label');
		openDeleteConfirmModal({
			title,
			content,
			handleConfirm: () => deleteRows(selectedIds.map((x) => x as string)),
		});
	};

	return [
		{
			icon: <EditOutlined />,
			text: t('common:edit'),
			tooltip: t('common:edit'),
			disabled: selectedIds.length !== 1,
			action: () => {
				if (selectedIds.length === 1) {
					openEditModal(selectedIds[0] as string);
				}
			},
			singleItem: (row: Repository) => ({
				disabled: false,
				action: () => {
					openEditModal(row.id);
				},
			}),
		},
		{
			icon: <DeleteOutlined />,
			text: t('common:delete'),
			tooltip: t('common:delete'),
			color: 'error',
			action: () => {
				if (selectedIds.length === 1) {
					openConfirmationRepoDialog(selectedIds[0] as string);
				} else {
					openDeleteMultipleDialog();
				}
			},
			disabled: selectedIds.length === 0,
			singleItem: (row: Repository) => ({
				disabled: false,
				action: () => {
					openConfirmationRepoDialog(row.id);
				},
			}),
		},
	];
};

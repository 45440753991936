import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SyncOutlined from '@mui/icons-material/SyncOutlined';
import { DynamicZoneFields } from '@neoload/api';

export const DockerImagesPanel = () => {
	const { t } = useTranslation(['zone']);

	const {
		register,
		resetField,
		formState: { errors, dirtyFields },
	} = useFormContext<DynamicZoneFields>();

	return (
		<Accordion
			disableGutters
			elevation={0}
			sx={{
				backgroundColor: 'transparent',
				// disable the grey divider line on the top of the accordion when folded
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:before': {
					display: 'none',
				},
			}}
		>
			<AccordionSummary sx={{ margin: 0, padding: 0 }} expandIcon={<ExpandMoreIcon />}>
				{t('dynamicEdition.dockerImages')}
				<Tooltip arrow placement='bottom' title={t('dynamicEdition.dockerImagesTooltip')}>
					<InfoOutlined
						sx={{
							marginLeft: '4px',
							alignSelf: 'center',
						}}
						fontSize='small'
						color='secondary'
					/>
				</Tooltip>
			</AccordionSummary>
			<AccordionDetails sx={{ margin: 0, padding: 0 }}>
				<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2, marginTop: 1 }}>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<TextField
							InputLabelProps={{ shrink: true }}
							{...register('pullSecret')}
							label={t('dynamicEdition.pullSecret')}
							required={false}
							size='small'
						/>
						<FormHelperText>{t('dynamicEdition.secretHelperText')}</FormHelperText>
					</Box>
					<TextField
						InputLabelProps={{ shrink: true }}
						placeholder={t('dynamicEdition.controllerImagePlaceHolder')}
						required
						{...register('controllerDockerImage', {
							required: t('common:input.required'),
							pattern: {
								value: /^(?!https?:\/\/).+$/,
								message: t('dynamicEdition.imagesPatternError'),
							},
						})}
						error={!!errors.controllerDockerImage}
						helperText={errors.controllerDockerImage?.message}
						label={t('dynamicEdition.controllerDockerImage')}
						size='small'
						InputProps={
							dirtyFields.controllerDockerImage
								? {
										endAdornment: (
											<InputAdornment position='end'>
												<Tooltip arrow title={t('dynamicEdition.resetToDefault')}>
													<IconButton
														aria-label={t('dynamicEdition.resetToDefault')}
														onClick={() => {
															resetField('controllerDockerImage');
														}}
													>
														<SyncOutlined />
													</IconButton>
												</Tooltip>
											</InputAdornment>
										),
									}
								: undefined
						}
					/>
					<TextField
						placeholder={t('dynamicEdition.lgImagePlaceHolder')}
						error={!!errors.lgDockerImage}
						helperText={errors.lgDockerImage?.message}
						required
						InputLabelProps={{ shrink: true }}
						{...register('lgDockerImage', {
							required: t('common:input.required'),
							pattern: {
								value: /^(?!https?:\/\/).+$/,
								message: t('dynamicEdition.imagesPatternError'),
							},
						})}
						label={t('dynamicEdition.lgDockerImage')}
						size='small'
					/>
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

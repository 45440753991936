import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router';
import { useTheme } from '@mui/material/styles';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import Stack from '@mui/material/Stack';
import ShareOutlined from '@mui/icons-material/ShareOutlined';
import Typography from '@mui/material/Typography';
import ShieldOutlined from '@mui/icons-material/ShieldOutlined';
import { DatagridAction } from '../../../common/data-grid/actions/data-grid-actions';
import { ActionsCellMenu } from '../../../common/data-grid/actions/actions-cell-menu';
import { ExternalUrlPlainLink } from '../../results/external-url/external-url-plain-link';
import { getDateValue } from '../../../common/data-grid/getters/get-date-value';
import { buildDashboardPublicSharedLink } from '../dashboard-common';
import { Dashboard } from '@neoload/api';
import { DashboardRoutes } from '@neoload/utils';

function useDashboardColumns(
	currentUserId: string | undefined,
	actions: DatagridAction[],
	resultId?: string,
): GridColDef<Dashboard>[] {
	const { t } = useTranslation(['dashboard']);
	const theme = useTheme();
	const columns: GridColDef<Dashboard>[] = [
		{
			field: 'name',
			headerName: t('columns.name.title'),
			flex: 1,
			minWidth: 300,
			sortable: true,
			renderCell: (props) => (
				<Link
					underline='hover'
					component={RouterLink}
					to={{ pathname: DashboardRoutes.dashboard(props.row.id), search: resultId ? `?fromResult=${resultId}` : '' }}
					title={props.value}
				>
					{props.value}
				</Link>
			),
		},
		{
			field: 'type',
			headerName: t('columns.type.title'),
			minWidth: 200,
			sortable: false,
			valueGetter: (_, row) => (row.resultId === undefined ? t('dashboardType.dashboard') : t('dashboardType.report')),
		},
		{
			field: 'shared',
			headerName: t('columns.shared.title'),
			minWidth: 200,
			sortable: false,
			renderCell: (props) => (
				<Stack direction='row' spacing={1} alignItems='center' sx={{ width: '100%', height: '100%' }}>
					{props.row.shareStatus === 'SHARED' ? (
						<>
							<ShareOutlined sx={{ color: theme.palette.text.secondary }} />
							<Typography title={t('sharedStateTrue')} variant='body2'>
								{t('sharedStateTrue')}
							</Typography>
						</>
					) : (
						<>
							<ShieldOutlined sx={{ color: theme.palette.text.secondary }} />
							<Typography title={t('sharedStateFalse')} variant='body2'>
								{t('sharedStateFalse')}
							</Typography>
						</>
					)}
				</Stack>
			),
		},
		{
			field: 'publicLink',
			headerName: t('columns.publicLink.title'),
			width: 60,
			sortable: false,
			resizable: false,
			filterable: false,
			align: 'center',
			headerAlign: 'center',
			renderHeader: () => (
				<Tooltip arrow title={t('columns.publicLink.title')}>
					<LinkOutlinedIcon style={{ color: theme.palette.action.active, fontSize: 'large' }} />
				</Tooltip>
			),
			renderCell: (props) =>
				props.row.publicToken && (
					<ExternalUrlPlainLink
						title={buildDashboardPublicSharedLink(props.row.publicToken)}
						href={buildDashboardPublicSharedLink(props.row.publicToken)}
					/>
				),
		},
		{
			field: 'owner',
			headerName: t('columns.owner.title'),
			minWidth: 200,
			sortable: false,
			valueGetter: (_, row) => (row.ownerId === currentUserId ? t('me') : row.ownerFullName),
		},
		{
			field: 'updatedAt',
			headerName: t('columns.lastUpdate.title'),
			minWidth: 180,
			sortable: true,
			valueFormatter: getDateValue,
		},
		{
			field: 'createdAt',
			headerName: t('columns.creation.title'),
			minWidth: 180,
			sortable: true,
			valueFormatter: getDateValue,
		},
		{
			field: 'actions',
			renderCell: (params: GridRenderCellParams<Dashboard, Dashboard, unknown>) => (
				<ActionsCellMenu rowData={params.row} actions={actions} />
			),
			sortable: false,
			resizable: false,
			disableReorder: true,
			type: 'actions',
			align: 'center',
		},
	];
	return columns;
}

export { useDashboardColumns };

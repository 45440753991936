import { useTranslation } from 'react-i18next';
import { UserDataGrid } from '@neoload/ui';
import { useCrudBatchMessage, useSetSnackbars } from '@neoload/hooks';
import {
	useDeleteV4UsersByUserIdMutation,
	useDeleteV4UsersByUserIdWorkspacesAndWorkspaceIdMutation,
	useGetV4UsersQuery,
	useGetV4WorkspacesQuery,
	usePatchV4UsersByUserIdMutation,
	usePutV4UsersByUserIdWorkspacesMutation,
	usePostV4UsersMutation,
	User,
	UserWorkspace,
	WorkspaceV4 as Workspace,
} from '@neoload/api';

const mapUserToResource = (user: User) => ({ name: user.email });

const UsersPage = () => {
	const { t } = useTranslation(['user']);
	const { showInfo, showError } = useSetSnackbars();
	const { update, create, remove } = useCrudBatchMessage('user');
	const [deleteUser] = useDeleteV4UsersByUserIdMutation();
	const [inviteUser] = usePostV4UsersMutation();
	const [updateUser] = usePatchV4UsersByUserIdMutation();
	const [putUserWorkspace] = usePutV4UsersByUserIdWorkspacesMutation();
	const [deleteUserWorkspace] = useDeleteV4UsersByUserIdWorkspacesAndWorkspaceIdMutation();
	const { data: workspaceData = { items: [] }, isFetching: isWorkspaceFetching } = useGetV4WorkspacesQuery();
	const { items: allWorkspaces } = workspaceData;
	const workspaces = allWorkspaces.filter((workspace: Workspace) => !workspace.enabledForAllUsers);
	const { data: userPageData = { items: [], total: 0 }, isFetching: isUserPageFetching } = useGetV4UsersQuery();

	type Role = 'ACCOUNT_ADMIN' | 'NEOLOAD_ADMIN' | 'TESTER' | 'GUEST' | 'NONE';

	async function changeRole(userId: string, role: Role) {
		const callback = (userId: string) =>
			updateUser({ userId: userId, userPatchRequest: { role: role } })
				.unwrap()
				.then(mapUserToResource);
		await update([userId], callback);
	}

	const changeWorkspaces = async (userId: string, workspaces: UserWorkspace[]) => {
		const user = userPageData.items.find((u) => u.id === userId);
		if (user == null) {
			throw new Error('User not found');
		}
		const userWorkspaces = user.workspaces;
		const updatedWorkspaceIds = new Set(workspaces.map((w) => w.id));
		const oldWorkspaceIds = new Set(userWorkspaces.map((w) => w.id));

		const workspacesToRemove = userWorkspaces.filter((w) => !updatedWorkspaceIds.has(w.id));
		const workspacesToAdd = workspaces.filter((w) => !oldWorkspaceIds.has(w.id));

		const deletesPromises = workspacesToRemove.map((workspace) =>
			deleteUserWorkspace({ userId: userId, workspaceId: workspace.id }),
		);
		const addPromises = workspacesToAdd.map((workspace) =>
			putUserWorkspace({ userId: userId, putUserWorkspace: { workspaceId: workspace.id } }).unwrap(),
		);

		await Promise.all(deletesPromises);
		await Promise.all(addPromises);
		showInfo({
			text: t('userWorkspacesChanged', { userName: user.email }),
		});
	};

	const deleteUsers = async (userIds: string[]) => {
		await remove(userIds, (userId) => deleteUser({ userId: userId }).unwrap());
	};

	const inviteUsers = async (emails: string[], role: Role) => {
		await create(emails, (email) =>
			inviteUser({ userCreationRequest: { email: email, role: role } })
				.unwrap()
				.then(mapUserToResource),
		);
	};

	const filterByUnusedEmail = (email: string) => !userPageData.items.some((user) => user.email === email);

	const inviteOnlyUsersWithUnusedEmails = async (emails: string[], role: Role) => {
		const unusedEmails = emails.filter(filterByUnusedEmail);

		const nbOfUsedEmails = emails.length - unusedEmails.length;
		if (nbOfUsedEmails > 0) {
			showError({
				text: t('create.error', {
					count: nbOfUsedEmails,
					cause: t('inviteError.emailUniqueness', { count: nbOfUsedEmails }),
				}),
			});
		}

		if (unusedEmails.length > 0) {
			await inviteUsers(unusedEmails, role);
		}
	};

	const resendInvitation = async (userId: string) => {
		const user = userPageData.items.find((user) => user.id === userId);
		if (!user) {
			return;
		}
		await deleteUser({ userId }).then(() => inviteUsers([user.email], user.role));
	};

	return (
		<UserDataGrid
			isLoading={isWorkspaceFetching || isUserPageFetching}
			userPage={userPageData}
			workspaces={workspaces}
			onRoleChange={changeRole}
			onWorkspacesChange={changeWorkspaces}
			onDeleteUsers={deleteUsers}
			onUserInvite={inviteOnlyUsersWithUnusedEmails}
			onResendInvitation={resendInvitation}
		/>
	);
};

export { UsersPage };

import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import deepEqual from 'deep-equal';
import { useDebounce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { ConfigurationFormData } from './types.d';
import { ScenarioTypes } from './scenarios/scenario-radio-group';
import { PatchTestInput, Test } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

const useConfigPatch = (
	patchTest: (patchTestInput: PatchTestInput) => Promise<Test | void>,
	defaultValues: ConfigurationFormData,
) => {
	const [patch, setPatch] = useState<PatchTestInput>({});
	const [previousPatch, setPreviousPatch] = useState<PatchTestInput>({});
	const [previous, setPrevious] = useState<ConfigurationFormData>(defaultValues);
	const value = useWatch<ConfigurationFormData>();
	const { setValue } = useFormContext<ConfigurationFormData>();
	const { showError } = useSetSnackbars();
	const { t } = useTranslation(['test']);

	useEffect(() => {
		setValue('test.isConfigured', defaultValues.test?.isConfigured);
	}, [defaultValues, setValue]);

	useEffect(() => {
		if (deepEqual(value, previous)) {
			return;
		}
		if (value.test?.isConfigured !== previous.test.isConfigured) {
			setPrevious((previous) => ({ ...previous, test: { ...previous.test, isConfigured: value.test?.isConfigured } }));
			return;
		}

		setValue('test.isConfigured', value.test?.isConfigured);
		const patch: PatchTestInput = {};
		patch.controllerZoneId = value.zones?.controller;
		patch.selectedScenarioName =
			value.scenario?.type === ScenarioTypes.FromProject ? value.scenario?.selected?.name : value.test?.name;
		patch.testResultNameTemplate = value.testResultMask;
		const { type, items: zones } = value.zones || {};
		if (zones !== undefined && type !== undefined) {
			patch.lgsByZone = {};
			for (const zone of zones) {
				if (zone?.number !== undefined && zone.zoneId !== undefined && zone.number > 0 && patch.lgsByZone) {
					patch.lgsByZone[zone.zoneId] = zone.number;
				}
			}
		}
		setPatch(patch);
		setPrevious(value as ConfigurationFormData);
	}, [previous, setValue, showError, t, value]);

	useDebounce(
		async () => {
			if (Object.keys(patch).length > 0 && Object.keys(previousPatch).length > 0 && !deepEqual(patch, previousPatch)) {
				await patchTest(patch).then((test) => {
					if (test) {
						setValue('test.isConfigured', test.isConfigured);
					}
				});
			}
			setPreviousPatch(patch);
		},
		500,
		[patch],
	);
};

export { useConfigPatch };

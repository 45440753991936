import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { GitCloneForm } from './types.d';
import { useGetV4ScmRepositoriesQuery } from '@neoload/api';

type AutocompleteType = { title: string; id: string };

const GitRepositoryAutocomplete = () => {
	const { t } = useTranslation(['test']);
	const { setValue, getValues } = useFormContext<GitCloneForm>();

	const { data: repositories, isLoading: isLoadingRepositories } = useGetV4ScmRepositoriesQuery();

	const [options, setOptions] = useState<AutocompleteType[]>([]);
	const repositoryId = useWatch<GitCloneForm>({ name: 'repositoryId' });
	const configuredRepositoryId = getValues('configuredRepositoryId');
	const error = useWatch<GitCloneForm>({ name: 'error' });

	const setRepository = (data: AutocompleteType | null) => {
		setValue('repositoryId', data?.id ?? '');
		setValue('repositoryName', data?.title ?? '');
		setValue('reference', '');
		setValue('error', undefined);
	};

	useEffect(() => {
		if (isLoadingRepositories || repositories?.items === undefined) {
			return undefined;
		}

		const items = repositories.items
			.map((repository) => ({ title: repository.name, id: repository.id }))
			.toSorted((first, second) => first.title.localeCompare(second.title));

		setOptions(items);
		setValue('reference', '');

		if (items.length === 1) {
			setValue('repositoryId', items[0].id);
			setValue('repositoryName', items[0].title);
		}

		const configItem = items.find((item) => item.id === configuredRepositoryId);
		if (configItem) {
			setValue('repositoryId', configItem.id);
			setValue('repositoryName', configItem.title);
		}
	}, [isLoadingRepositories, repositories, setValue, configuredRepositoryId]);

	const currentRepository =
		repositories?.items
			?.filter((repository) => repository.id === repositoryId)
			.map((repository) => ({ title: repository.name, id: repository.id }))[0] || null;

	return (
		<Autocomplete
			value={currentRepository}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			getOptionLabel={(option) => option.title}
			onChange={(_, data) => setRepository(data)}
			options={options}
			noOptionsText={t('configuration.project.git.modal.noRepositories')}
			loadingText={t('configuration.project.git.modal.loading')}
			loading={isLoadingRepositories}
			size='small'
			renderInput={(params) => (
				<TextField
					{...params}
					label={t('configuration.project.git.modal.repository')}
					error={error !== undefined}
					helperText={error}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{isLoadingRepositories ? <CircularProgress color='inherit' size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
		/>
	);
};

export { GitRepositoryAutocomplete };

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import deepmerge from 'deepmerge';
import { ScenarioAsCode } from '../../nlw-api-v4';

export enum RampUpRange {
	SECONDS = 's',
	MINUTES = 'm',
	HOURS = 'h',
	ITERATIONS = 'i',
}

export enum ExecutionPolicies {
	DURATION = 'duration',
	ITERATION = 'iteration',
}

export enum VuDistributions {
	CONSTANT = 'constant',
	RAMPUP = 'ramp_up',
}

type BasePopulation = {
	name: string;
	vus: number;
	stepRampUp: string;
	executionPolicy: ExecutionPolicies;
	duration: string;
};

export type RampUpPopulation = BasePopulation & {
	vuDistribution: VuDistributions.RAMPUP;
	rampUp: {
		users: number;
		every: { amount: number; unit: RampUpRange };
	};
};

export type ConstantPopulation = BasePopulation & {
	vuDistribution: VuDistributions.CONSTANT;
};

export type CustomPopulation = RampUpPopulation | ConstantPopulation;

type DeepPartial<T> = T extends object
	? {
			[P in keyof T]?: DeepPartial<T[P]>;
		}
	: T;

export type PartialCustomPopulation = DeepPartial<CustomPopulation>;

export type ScenarioAsCodePopulation = NonNullable<ScenarioAsCode['populations']>[number];

export type CustomPopulationState = {
	populations: CustomPopulation[];
	isUserChanged: boolean;
	unsupportedPopulations: ScenarioAsCodePopulation[];
};

const initialCustomPopulationState: CustomPopulationState = {
	populations: [],
	isUserChanged: false,
	unsupportedPopulations: [],
};

export const defaultConstantPopulation: Omit<ConstantPopulation, 'name'> = {
	vus: 10,
	stepRampUp: '0s',
	executionPolicy: ExecutionPolicies.DURATION,
	duration: '5m',
	vuDistribution: VuDistributions.CONSTANT,
};

const customPopulationSlice = createSlice({
	name: 'customPopulation',
	initialState: initialCustomPopulationState,
	reducers: {
		addCustomPopulation: (state, action: PayloadAction<{ population: CustomPopulation; isUser: boolean }>) => {
			if (!state.populations.some((s) => s.name === action.payload.population.name)) {
				state.populations.push(action.payload.population);
			}

			if (action.payload.isUser) {
				state.isUserChanged = true;
			}
		},
		addUnsupportedPopulations: (state, action: PayloadAction<ScenarioAsCodePopulation>) => {
			if (state.unsupportedPopulations !== undefined && action.payload !== undefined) {
				state.unsupportedPopulations.push(action.payload);
			}
		},
		updatePopulation: (
			state,
			action: PayloadAction<{ name: string; partialCustomPopulation: PartialCustomPopulation; isUser: boolean }>,
		) => {
			const index = state.populations.findIndex((s) => s.name === action.payload.name);
			if (index >= 0) {
				const mergePopulation = deepmerge<CustomPopulation, PartialCustomPopulation>(
					state.populations[index],
					action.payload.partialCustomPopulation,
				);
				if (mergePopulation.vuDistribution === VuDistributions.CONSTANT && 'rampUp' in mergePopulation) {
					delete mergePopulation.rampUp;
				}
				state.populations.splice(index, 1, mergePopulation);
			}
			if (action.payload.isUser) {
				state.isUserChanged = true;
			}
		},
		removeCustomPopulation: (state, action: PayloadAction<{ name: string; isUser: boolean }>) => {
			const populations = state.populations.filter((s) => s.name !== action.payload.name);

			return {
				isUserChanged: state.isUserChanged || action.payload.isUser,
				populations,
				unsupportedPopulations: state.unsupportedPopulations,
			};
		},
		removeAllCustomPopulation: (_state, action: PayloadAction<boolean>) => ({
			populations: [],
			isUserChanged: action.payload,
			unsupportedPopulations: [],
		}),
	},
});

const { reducer: customPopulationReducer, actions } = customPopulationSlice;
export const {
	addCustomPopulation,
	updatePopulation,
	removeCustomPopulation,
	removeAllCustomPopulation,
	addUnsupportedPopulations,
} = actions;

export { customPopulationReducer };

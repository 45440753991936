import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useGetLogs } from './use-get-logs';
import { Spinner } from '../../../common/spinner';
import { createNeoLoadError, isNotFoundError, timeUtils } from '@neoload/utils';
import { TestResult } from '@neoload/api';

export type ResultTabLogsProps = {
	result: Pick<TestResult, 'id' | 'status' | 'qualityStatus'>;
};

const ResultTabLogs = ({ result }: ResultTabLogsProps) => {
	const { response, waiting } = useGetLogs(result);
	const { data: logs, isLoading, error: logsError } = response;

	const itemList = logs?.items.map((item, index) => (
		<Typography
			key={item.date + item.line + index}
			fontFamily='monospace'
			fontSize={12}
			lineHeight='16px'
			color={item.level === 'ERROR' ? 'red' : 'body'}
		>
			{timeUtils.dateTimeAbsolute(item.date, 'DD.MM.YY hh:mm:ss A')} {' - '} {item.line}
		</Typography>
	));

	if (isLoading || waiting) {
		return <Spinner />;
	}
	if (!logs && !isNotFoundError(logsError)) {
		throw createNeoLoadError(logsError);
	}

	return (
		<Grid container direction='column' alignItems='left' justifyContent='center'>
			<Grid
				item
				alignItems='left'
				justifyContent='space-between'
				width='100%'
				sx={(theme) => ({
					paddingX: theme.spacing(2),
					paddingY: theme.spacing(1),
				})}
			>
				{itemList}
			</Grid>
		</Grid>
	);
};

export { ResultTabLogs };

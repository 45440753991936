import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { CreateReservationLayoutModal } from './modal/create-reservation-layout-modal';
import {
	CreateReservationForm,
	emptyCreateReservationForm,
	ReservationZoneFormData,
} from './modal/common/reservation-helper';
import { LoadingContentModal } from './modal/common/loading-content-modal';
import { Spinner } from '../../../common/spinner';
import { useGetV4ZonesQuery } from '@neoload/api';

type CreateReservationModalProps = {
	close: () => void;
};
const CreateReservationModal = ({ close }: CreateReservationModalProps) => {
	const { t } = useTranslation(['reservation']);

	const { data: zonesPageData = { items: [], total: 0 }, isLoading: isLoadingZones } = useGetV4ZonesQuery({});

	const buildZones = (): CreateReservationForm => {
		const zones: ReservationZoneFormData[] = zonesPageData.items.map(({ name, zoneId, type }) => ({
			zoneId,
			number: 0,
			type,
			name,
		}));

		return {
			...emptyCreateReservationForm,
			zones: {
				items: zones,
				type: 'CLOUD',
				controller: '',
			},
			startDate: dayjs().add(15, 'minutes').second(0).toISOString(),
		};
	};

	if (isLoadingZones) {
		return <Spinner />;
	}

	return (
		<Dialog open={true} maxWidth={false}>
			<DialogTitle>{t('create.title')}</DialogTitle>
			{isLoadingZones && <LoadingContentModal close={close} labelSubmit={t('create.confirm')} />}
			{!isLoadingZones && <CreateReservationLayoutModal close={close} defaultValue={buildZones()} />}
		</Dialog>
	);
};
export { CreateReservationModal };

import { useTranslation } from 'react-i18next';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useFormContext } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { FIXED_SIZES, getSizingValues, MEDIUM_SIZING, SizingValues } from './dynamic-zone-utils';
import { SizingTextfield } from './sizing-textfield';
import { DynamicZoneFields, FixedSizing, Sizing } from '@neoload/api';

type ComboboxOption = FixedSizing['size'] | 'CUSTOM';

const toComboOption = (size: Sizing): ComboboxOption => {
	if (size.type === 'FIXED') {
		return size.size;
	}
	return 'CUSTOM';
};

export const SizingPanel = () => {
	const { t } = useTranslation(['zone']);

	const { setValue, watch } = useFormContext<DynamicZoneFields>();

	const size = watch('sizing');

	const onSizeComboChange = (event: SelectChangeEvent<ComboboxOption>) => {
		const value = event.target.value;
		if (value === 'CUSTOM') {
			setValue('sizing', {
				type: 'CUSTOM',
				...MEDIUM_SIZING,
			});
		} else {
			setValue('sizing', { type: 'FIXED', size: value as FixedSizing['size'] });
		}
	};

	const formatSizeSecondaryText = (size: FixedSizing['size']) => {
		const values: SizingValues = getSizingValues(size);
		return `${t('sizes.controller')}: ${values.controllerMemoryMb} ${t('sizes.mb')}, ${values.controllerCpu} ${t(
			'sizes.cores',
		)} | ${t('sizes.lg')}: ${values.lgMemoryMb} ${t('sizes.mb')}, ${values.lgCpu} ${t('sizes.cores')}`;
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
			<FormControl fullWidth variant='outlined'>
				<InputLabel required={true}>{t('sizes.sizing')}</InputLabel>
				<Select<ComboboxOption>
					sx={{ width: '100%' }}
					value={toComboOption(size)}
					onChange={onSizeComboChange}
					renderValue={(option) => <ListItemText primary={t(`sizes.${option}`)} sx={{ margin: 0 }} />}
					label={t('sizes.sizing')}
					variant='outlined'
					size='small'
				>
					{FIXED_SIZES.map((option) => (
						<MenuItem key={option} value={option}>
							<ListItemText primary={t(`sizes.${option}`)} />
							<ListItemText
								secondary={formatSizeSecondaryText(option)}
								secondaryTypographyProps={{ marginLeft: '40px', align: 'right' }}
							/>
						</MenuItem>
					))}
					{
						<MenuItem key='CUSTOM' value='CUSTOM'>
							<ListItemText primary={t('sizes.CUSTOM')} />
						</MenuItem>
					}
				</Select>
				{size.type !== 'CUSTOM' && (
					<FormHelperText component='span'>{formatSizeSecondaryText(size.size)}</FormHelperText>
				)}
			</FormControl>
			{size.type === 'CUSTOM' && (
				<Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 4 }}>
					<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
						<Typography title={t('sizes.controller')}>{t('sizes.controller')}</Typography>
						<Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 2 }}>
							<SizingTextfield propertyName='controllerMemoryMb' fieldType='MEMORY' />
							<SizingTextfield propertyName='controllerCpu' fieldType='CPU' />
						</Box>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
						<Typography title={t('sizes.loadGenerator')}>{t('sizes.loadGenerator')}</Typography>
						<Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 2 }}>
							<SizingTextfield propertyName='lgMemoryMb' fieldType='MEMORY' />
							<SizingTextfield propertyName='lgCpu' fieldType='CPU' />
						</Box>
					</Box>
				</Box>
			)}
		</Box>
	);
};

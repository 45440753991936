import TableCell from '@mui/material/TableCell';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useGetLicenseWarningMessage } from './use-get-license-warning-message';

type LicenseVusTableCellProps = {
	vusValue?: number;
	isAdmin: boolean;
};

export const LicenseVusTableCell = ({ vusValue, isAdmin }: LicenseVusTableCellProps) => {
	const warningMessage = useGetLicenseWarningMessage(isAdmin);

	return (
		<TableCell align='right'>
			<Stack direction='row' gap={0.5}>
				{warningMessage && (
					<Tooltip arrow title={warningMessage}>
						<ErrorOutline color='error' />
					</Tooltip>
				)}
				{vusValue !== undefined && vusValue}
			</Stack>
		</TableCell>
	);
};

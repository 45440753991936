import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import isEmail from 'validator/lib/isEmail';
import { useForm } from 'react-hook-form';
import { UserRole } from '@neoload/api';
import { useGetMe } from '@neoload/hooks';

type FormProps = {
	emails: string;
	role: UserRole;
};

const defaultValues: FormProps = {
	emails: '',
	role: 'GUEST',
};

const EMAILS_SEPARATOR = ',';

const isEmailsInvalid = (emails: string): boolean => {
	const emailList = splitEmails(emails);
	return emailList.length === 0 || emailList.some((email) => !isEmail(email));
};

const splitEmails = (emails: string): string[] => {
	if (emails.length <= 0) {
		return [];
	}
	if (emails.indexOf(EMAILS_SEPARATOR) <= -1) {
		return [emails.trim()];
	}
	return [
		...new Set(
			emails
				.split(EMAILS_SEPARATOR)
				.map((email) => email.trim())
				.filter((email) => email.length > 0),
		),
	];
};

type UserInviteDialogProps = {
	isOpen: boolean;
	onClose: (okButton: boolean, emails: string[], role: UserRole) => void;
	roles: {
		value: UserRole;
		label: string;
	}[];
};

const UserInviteDialog = ({ isOpen, onClose, roles }: UserInviteDialogProps) => {
	const {
		register,
		reset,
		handleSubmit,
		formState: { isValid, errors },
	} = useForm<FormProps>({
		mode: 'onChange',
		defaultValues,
	});
	roles = roles.filter((role) => role.value !== 'NONE');
	const { t } = useTranslation();
	const [me] = useGetMe();

	return (
		<Dialog open={isOpen}>
			<DialogTitle>{t('user:inviteDialog.title', { accountName: me.isLoaded ? me.account.name : '' })}</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 3,
						marginTop: 1,
						width: 500,
					}}
				>
					<TextField
						{...register('emails', {
							required: {
								value: true,
								message: t('user:inviteDialog.emailsFieldError.invalid', {
									separator: EMAILS_SEPARATOR,
								}),
							},
							validate: (value) => {
								if (isEmailsInvalid(value)) {
									return t('user:inviteDialog.emailsFieldError.invalid', {
										separator: EMAILS_SEPARATOR,
									});
								}
								return true;
							},
						})}
						multiline={true}
						autoFocus={true}
						required={true}
						placeholder={
							t('user:inviteDialog.emailsFieldPlaceholder', {
								separator: EMAILS_SEPARATOR + ' ',
							}) ?? ''
						}
						label={t('user:inviteDialog.emailsField')}
						error={!!errors.emails}
						helperText={
							errors.emails
								? errors.emails.message
								: t('user:inviteDialog.emailsFieldHelper', {
										separator: EMAILS_SEPARATOR,
									})
						}
						size='small'
					/>
					<FormControl fullWidth required>
						<InputLabel id='role-label'>{t('user:inviteDialog.roleField')}</InputLabel>
						<Select
							{...register('role', {
								required: {
									value: true,
									message: t('user:inviteDialog.roleFieldError.required'),
								},
							})}
							label={t('user:inviteDialog.roleField')}
							aria-labelledby='role-label'
							error={!!errors.role}
							defaultValue={defaultValues.role}
							size='small'
						>
							{roles.map((role) => (
								<MenuItem key={role.value} value={role.value}>
									{role.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						onClose(false, [], 'GUEST');
						reset();
					}}
					color='primary'
					data-trackingid='user-invite-cancel'
					size='small'
				>
					{t('common:cancel')}
				</Button>
				<Button
					onClick={handleSubmit((form) => {
						onClose(true, splitEmails(form.emails), form.role);
						reset();
					})}
					disabled={!isValid}
					variant='contained'
					data-trackingid='user-invite-ok'
					size='small'
				>
					{t('user:inviteDialog.okButton')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export { UserInviteDialog };

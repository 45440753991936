import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Controller, DeepPartial, useFormContext } from 'react-hook-form';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Divider from '@mui/material/Divider';
import { SearchResultCombo } from '../../../../../../results/search-result-combo';
import { SearchIntervalCombobox } from '../../../../../../results/search-interval-combobox/search-interval-combobox';
import { ComparisonTileEditionFormFields } from '../../tile-edition-form';
import { Interval, TestResult } from '@neoload/api';
import { useUrlSearchParams } from '@neoload/hooks';

export type ComparisonColumnListItemProps = {
	index: number;
	initAsOpened?: boolean;
	removeColumn: (index: number) => void;
};

const ComparisonColumnListItem = ({ index, initAsOpened = false, removeColumn }: ComparisonColumnListItemProps) => {
	const { t } = useTranslation('dashboard');
	const [open, setOpen] = useState(initAsOpened);
	const theme = useTheme();
	const [{ fromResult }] = useUrlSearchParams('fromResult');

	const { watch, setValue, getValues, control } = useFormContext<DeepPartial<ComparisonTileEditionFormFields>>();

	const handleClick = () => {
		setOpen(!open);
	};

	const [columnName, resultId, intervalId] = watch([
		`columns.${index}.columnName`,
		`columns.${index}.resultId`,
		`columns.${index}.intervalId`,
	]);

	const searchResultCombo = useMemo(
		() => (
			<SearchResultCombo
				initialResultId={resultId ?? fromResult}
				onResultSelected={(selectedResult: TestResult) => {
					if (resultId === selectedResult.id) {
						const name = getValues(`columns.${index}.columnName`);
						if (name === undefined || name === '') {
							setValue(`columns.${index}.columnName`, selectedResult.name);
						}
					} else {
						setValue(`columns.${index}.resultId`, selectedResult.id);
						setValue(`columns.${index}.columnName`, selectedResult.name);
					}
				}}
			/>
		),
		[getValues, index, resultId, setValue, fromResult],
	);

	return (
		<Box
			sx={{
				borderRadius: '4px',
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor: theme.palette.divider,
			}}
		>
			<ListItemButton onClick={handleClick}>
				<ListItemIcon sx={{ minWidth: 0, width: '32px' }}>{open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
				<ListItemText primary={columnName} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} />
				<Tooltip arrow title={t('common:remove')}>
					<IconButton onClick={() => removeColumn(index)}>
						<DeleteOutlined />
					</IconButton>
				</Tooltip>
			</ListItemButton>
			<Collapse in={open} timeout='auto' unmountOnExit sx={{ padding: 2, paddingTop: 1 }}>
				<Stack sx={{ gap: 2 }}>
					{searchResultCombo}
					<SearchIntervalCombobox
						resultId={resultId}
						intervalId={intervalId}
						onIntervalSelected={(selectedInterval?: Interval) => {
							setValue(`columns.${index}.intervalId`, selectedInterval?.id);
							if (selectedInterval) {
								setValue(`columns.${index}.columnName`, selectedInterval.name);
							}
						}}
					/>
					<Divider />
					<Controller
						name={`columns.${index}.columnName`}
						control={control}
						defaultValue={columnName ?? ''}
						render={({ field }) => (
							<TextField
								{...field}
								required
								label={t('valuesComparison.columns.displayName')}
								// eslint-disable-next-line @typescript-eslint/naming-convention
								inputProps={{ 'data-testid': 'name-input', maxLength: 128 }}
							/>
						)}
					/>
				</Stack>
			</Collapse>
		</Box>
	);
};

export { ComparisonColumnListItem };

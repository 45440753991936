import { GridColDef, GridRowModel, GridRowSelectionModel } from '@mui/x-data-grid/models';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import { ComponentPropsWithoutRef, MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router';
import { GridRenderCellParams, GridSortItem, useGridApiRef } from '@mui/x-data-grid-pro';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { WorkspaceFormModal } from './workspace-form-modal';
import { WorkspacesDeleteDialog } from './workspaces-delete-dialog';
import { Datagrid } from '../../common/data-grid/datagrid';
import { DEFAULT_GRID_PROPS, DEFAULT_PAGINATION_MODEL, onColumnChange } from '../../common/datagrid';
import {
	DatagridAction,
	filterToContextMenuItems,
	filterToSecondaryActions,
} from '../../common/data-grid/actions/data-grid-actions';
import { ContextMenu, ContextMenuFuncts } from '../../common/context-menu/context-menu';
import { ActionsCellMenu } from '../../common/data-grid/actions/actions-cell-menu';
import { CreateResourceButton } from '../../common/button/create-resource-button';
import { useGetV4WorkspacesQuery, Workspace, WorkspacePage } from '@neoload/api';
import { useColumnsState, useGetMe } from '@neoload/hooks';
import { createNeoLoadError, WorkspaceRoutes } from '@neoload/utils';

const columnsStateKey = 'TOKENS_COLUMNS_STATE';
type DisplayedModal = 'NONE' | 'DELETE_WORKSPACE' | 'FORM_MODAL';
const defaultWorkspaceId = '5e3acde2e860a132744ca916';

const defaultPage: WorkspacePage = {
	total: 0,
	pageNumber: 0,
	pageSize: DEFAULT_PAGINATION_MODEL.pageSize,
	items: [],
};

type WorkspaceModalState = {
	workspace?: Pick<Workspace, 'id' | 'name' | 'description'>;
	displayedModal: DisplayedModal;
};

type WorkspacesDataGridProps = {
	onDeleteWorkspace: (workspaceId: string) => void;
	isDeleting: boolean;
};

const WorkspacesDataGrid = ({ onDeleteWorkspace, isDeleting }: WorkspacesDataGridProps) => {
	const { t } = useTranslation(['workspace']);
	const apiRef = useGridApiRef();
	const [paginationModel, setPaginationModel] = useState(DEFAULT_PAGINATION_MODEL);
	const [{ workspaces: userWorkspaces, hasAdminPermissions }] = useGetMe();
	const {
		data: workspacePage = defaultPage,
		isFetching,
		error: resultsError,
	} = useGetV4WorkspacesQuery(undefined, { skip: !hasAdminPermissions });
	const [selectedWorkspaceIds, setSelectedWorkspaceIds] = useState<GridRowSelectionModel>([]);
	const [workspaceModalState, setWorkspaceModalState] = useState<WorkspaceModalState>({
		displayedModal: 'NONE',
	});
	const workspaces = hasAdminPermissions ? workspacePage.items : userWorkspaces;

	const contextMenu: MutableRefObject<ContextMenuFuncts | undefined> = useRef();

	const createWorkspace = () =>
		setWorkspaceModalState({
			displayedModal: 'FORM_MODAL',
		});

	const closeModal = () =>
		setWorkspaceModalState({
			displayedModal: 'NONE',
		});

	const deleteAction: DatagridAction = {
		icon: <DeleteOutlined />,
		color: 'error',
		text: t('common:delete'),
		tooltip: t('common:delete'),
		hidden: !hasAdminPermissions,
		disabled:
			selectedWorkspaceIds.length !== 1 ||
			(selectedWorkspaceIds.length === 1 && selectedWorkspaceIds[0] === defaultWorkspaceId),
		action: () => {
			const workspaceToDelete = workspaces.find((workspace) => workspace.id === selectedWorkspaceIds[0]);
			if (workspaceToDelete) {
				setWorkspaceModalState({
					displayedModal: 'DELETE_WORKSPACE',
					workspace: workspaceToDelete,
				});
			}
		},
		singleItem: (workspace: Workspace) => ({
			disabled: workspace.id === defaultWorkspaceId,
			action: () => {
				setWorkspaceModalState({
					displayedModal: 'DELETE_WORKSPACE',
					workspace: workspace,
				});
			},
		}),
	};

	const editAction: DatagridAction = {
		icon: <EditOutlined />,
		text: t('common:edit'),
		tooltip: t('common:edit'),
		hidden: !hasAdminPermissions,
		action: () => {
			const workspaceToEdit = workspaces.find((workspace) => workspace.id === selectedWorkspaceIds[0]);
			if (workspaceToEdit) {
				setWorkspaceModalState({
					displayedModal: 'FORM_MODAL',
					workspace: workspaceToEdit,
				});
			}
		},
		singleItem: (workspace: Workspace) => ({
			action: () => {
				setWorkspaceModalState({
					displayedModal: 'FORM_MODAL',
					workspace: workspace,
				});
			},
			disabled: false,
		}),
	};

	const getActions = (workspaceIds: GridRowSelectionModel): DatagridAction[] =>
		workspaceIds.length === 1 ? [editAction, ...(workspaceIds[0] === defaultWorkspaceId ? [] : [deleteAction])] : [];

	const onWorkspaceDialogSubmit = (workspaceId: string): void => {
		onDeleteWorkspace(workspaceId);
		setSelectedWorkspaceIds([]);
		apiRef.current.setRowSelectionModel([]);
		closeModal();
	};

	const commonColumns: GridColDef<Workspace>[] = [
		{
			field: 'name',
			headerName: t('grid.name'),
			minWidth: 250,
			renderCell: (props: GridRenderCellParams) => (
				<Link
					underline='hover'
					component={RouterLink}
					to={WorkspaceRoutes.workspace(props.row.id)}
					sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
				>
					{props.value}
				</Link>
			),
		},
		{ field: 'description', headerName: t('grid.description'), flex: 1 },
	];
	const adminColumns: GridColDef<Workspace>[] = [
		{
			field: 'membersCount',
			headerName: t('grid.membersCount'),
			minWidth: 100,
			align: 'right',
			headerAlign: 'right',
			renderCell: (props) => (props.row.enabledForAllUsers ? t('grid.allUsers') : props.row.membersCount),
		},
		{
			field: 'actions',
			renderCell: (params: GridRenderCellParams) => (
				<ActionsCellMenu actions={getActions([params.row.id])} rowData={params.row} />
			),
			resizable: false,
			disableReorder: true,
			type: 'actions',
		},
	];
	const columns = [...commonColumns, ...(hasAdminPermissions ? adminColumns : [])];

	const defaultGridSort: GridSortItem = { field: 'name', sort: 'asc' };
	const initialState = {
		sorting: {
			sortModel: [defaultGridSort],
		},
	};

	const { updatedInitialState, updatedColumns, storeColumnState } = useColumnsState(
		columnsStateKey,
		initialState,
		columns,
		apiRef,
	);

	const componentsProps: {
		toolbar: ComponentPropsWithoutRef<typeof Toolbar>;
		row: GridRowModel;
	} = {
		toolbar: {
			displaySearchBox: true,
			hideFiltersIcon: true,
			displayColumnOptions: false,
			mainActions: hasAdminPermissions
				? [{ children: <CreateResourceButton onClick={createWorkspace}>{t('createWorkspace')}</CreateResourceButton> }]
				: [],
			syncLocalStorage: {
				datagridId: 'neoloadStaticZonesDataGrid',
				isSyncEnabled: true,
			},
			secondaryActions: filterToSecondaryActions(getActions(selectedWorkspaceIds)),
			title: t('grid.title'),
		},
		row: {
			onContextMenu: contextMenu.current?.openContextMenu,
		},
	};

	if (resultsError) {
		throw createNeoLoadError(resultsError);
	}

	const isMember = (workspaceId: string) => userWorkspaces.some((userWorkspace) => userWorkspace.id === workspaceId);

	return (
		<>
			<Datagrid
				{...DEFAULT_GRID_PROPS}
				{...onColumnChange(storeColumnState)}
				disableColumnMenu={false}
				checkboxSelection={false}
				apiRef={apiRef}
				rows={workspaces}
				loading={isFetching}
				initialState={updatedInitialState}
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				onRowSelectionModelChange={(model) => setSelectedWorkspaceIds(model)}
				columns={updatedColumns}
				disableRowSelectionOnClick={false}
				slotProps={componentsProps}
				slots={{
					toolbar: Toolbar,
				}}
			/>
			<WorkspaceFormModal
				opened={workspaceModalState.displayedModal === 'FORM_MODAL'}
				closeModal={closeModal}
				editedWorkspace={workspaceModalState.workspace}
			/>
			<ContextMenu
				apiRef={apiRef}
				ref={contextMenu}
				contextMenuItemsList={filterToContextMenuItems(getActions(selectedWorkspaceIds))}
			/>
			{workspaceModalState.workspace && (
				<WorkspacesDeleteDialog
					workspace={workspaceModalState.workspace}
					isMember={isMember(workspaceModalState.workspace.id)}
					isOpen={workspaceModalState.displayedModal === 'DELETE_WORKSPACE'}
					isDeleting={isDeleting}
					onSubmit={onWorkspaceDialogSubmit}
					onCancel={closeModal}
				/>
			)}
		</>
	);
};
export { WorkspacesDataGrid };

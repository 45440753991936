import { timeUtils } from '@neoload/utils';

export type DetailField = 'screenshotId' | 'transaction';

const isPending = (duration: string, type: string): boolean =>
	(['ALERT', 'SLA'].includes(type) && timeUtils.asMilliseconds(duration) === 0) ||
	(type === 'CUSTOM' && timeUtils.asMilliseconds(duration) < 0);

const isPunctual = (duration: string, type: string): boolean =>
	['ERROR', 'OBJECTIVE'].includes(type) || (type === 'CUSTOM' && timeUtils.asMilliseconds(duration) === 0);

const getDisplayedDuration = (offset: string, duration: string, type: string, pendingLabel: string): string => {
	if (isPunctual(duration, type)) {
		return timeUtils.durationMillis(offset);
	} else if (isPending(duration, type)) {
		return timeUtils.durationMillis(offset) + ' - ' + pendingLabel;
	}
	return timeUtils.rangeTimeDurationMillis(offset, duration);
};

export { getDisplayedDuration };

import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import ZeroState from '@tricentis/aura/components/ZeroState.js';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import WorkspaceMembersEmptyState from '../../../../assets/WorkspaceMembersEmptyState.svg';
import { CreateResourceButton } from '../../../common/button/create-resource-button';
import { IllustrationWithBackground } from '../../../common/illustration-with-background';

type WorkspaceMembersDatagridEmptyStateProps = {
	openAddMembersModal: () => void;
	includeAllUsers: () => void;
};

export const WorkspaceMembersDatagridEmptyState = ({
	openAddMembersModal,
	includeAllUsers,
}: WorkspaceMembersDatagridEmptyStateProps) => {
	const { t } = useTranslation(['workspace']);
	const title = t('members.emptyState.title');
	return (
		<Stack justifyContent='center' height='100%'>
			<ZeroState
				illustration={<IllustrationWithBackground image={WorkspaceMembersEmptyState} title={title} />}
				title={title}
				actions={
					<>
						<CreateResourceButton onClick={openAddMembersModal}>{t('members.addMembers')}</CreateResourceButton>
						<Button variant='outlined' onClick={includeAllUsers}>
							{t('members.includeAllMembers')}
						</Button>
					</>
				}
				containerSx={{ backgroundColor: 'transparent' }}
			>
				<Typography variant='body2'>{t('members.emptyState.body')}</Typography>
			</ZeroState>
		</Stack>
	);
};

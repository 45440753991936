import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { TestPage, useGetV4ResultsQuery, useGetV4TestsQuery, Workspace } from '@neoload/api';

type WorkspaceDeleteDialogProps = {
	workspace: Pick<Workspace, 'id' | 'name'>;
	isMember: boolean;
	isOpen: boolean;
	isDeleting: boolean;
	onSubmit: (workspaceId: string) => void;
	onCancel: () => void;
};

const defaultTestsPage: TestPage = {
	total: 0,
	pageNumber: 0,
	pageSize: 1,
	items: [],
};

const WorkspacesDeleteDialog = ({
	workspace,
	isMember,
	isOpen,
	isDeleting,
	onSubmit,
	onCancel,
}: WorkspaceDeleteDialogProps) => {
	const { t } = useTranslation(['workspace']);

	const {
		data: testPageData = defaultTestsPage,
		isFetching: isTestsFetching,
		isError: isTestsError,
	} = useGetV4TestsQuery(
		{
			workspaceId: workspace.id,
			pageSize: 1,
			pageNumber: 0,
		},
		{ skip: !isMember },
	);

	const {
		data: resultsPage = {
			items: [],
			total: 0,
			pageNumber: 0,
			pageSize: 1,
		},
		isFetching: isResultsFetching,
		isError: isResultsError,
	} = useGetV4ResultsQuery(
		{
			workspaceId: workspace.id,
			pageNumber: 0,
			pageSize: 1,
		},
		{ skip: !isMember },
	);

	const warningIntro = t('deleteDialog.warningIntro');
	const warningImportant = t('deleteDialog.warningImportant');
	const warningUsers = t('deleteDialog.warningUsers');
	const warningDelete = t('deleteDialog.warningToDelete');
	const warningFetching = t('deleteDialog.warningFetching');
	const warningError = t('deleteDialog.warningError');
	const warningCounts =
		t('deleteDialog.warningTestsCount', { count: testPageData.total }) +
		t('deleteDialog.warningResultsCount', { count: resultsPage.total });

	const helperText = t('deleteDialog.workspaceTypeConfirm', { workspaceName: workspace.name });

	const [canDelete, setCanDelete] = useState(false);

	function getWarningData() {
		if ((isTestsFetching || isResultsFetching) && !isTestsError && !isResultsError) {
			return (
				<>
					<CircularProgress size={16} sx={{ marginLeft: '5px', marginBottom: '-2px' }} /> {warningFetching}
				</>
			);
		} else if (isTestsError || isResultsError) {
			return warningError;
		}

		return warningCounts;
	}

	return (
		<Dialog open={isOpen} onClose={onCancel}>
			<DialogTitle>{t('deleteDialog.title')}</DialogTitle>
			<DialogContent>
				<Stack gap={2} sx={{ marginY: 3 }}>
					<Typography variant='body1'>{t('deleteDialog.label')}</Typography>
					<Alert severity='warning'>
						{warningIntro}
						<b>{warningImportant}</b>
						{warningUsers}
						<br />
						{isMember && warningDelete}
						{isMember && getWarningData()}
					</Alert>
					<TextField
						label={helperText}
						variant='outlined'
						fullWidth={true}
						onChange={(inputValue) => {
							setCanDelete(inputValue.target.value === workspace.name);
						}}
						autoFocus
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onCancel()} color='primary' data-trackingid='workspace-delete-cancel'>
					{t('common:cancel')}
				</Button>
				<Button
					onClick={() => onSubmit(workspace.id)}
					variant='contained'
					disabled={!canDelete}
					color='error'
					startIcon={isDeleting ? <CircularProgress size={24.5} color='inherit' /> : null}
					data-trackingid='workspace-delete-ok'
				>
					{t('common:delete')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export { WorkspacesDeleteDialog };

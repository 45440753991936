import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { useCallback } from 'react';
import { allowedFileUploadExtension, allowedFileUploadExtensionString, getReadableSize } from './utils';
import { ConfigurationFormData } from './../types.d';
import { configurationErrorToString, projectToConfigurationForm } from './../configuration-helpers';
import { useCustomScenario, useSetSnackbars } from '@neoload/hooks';
import { FileUpload, nlwApiV4, useAppDispatch } from '@neoload/api';

const checkFileUploadExtension = (fileName: string | undefined) => {
	if (fileName === undefined) {
		return false;
	}

	const fileExtension = fileName.split('.').at(-1);

	if (fileExtension === undefined) {
		return false;
	}

	return allowedFileUploadExtension.has(fileExtension);
};

const isFileSizeOk = (file: File, projectMaxSize: number) =>
	file.size > 0 && projectMaxSize && file.size < projectMaxSize;

const canUploadFile = (file: File, projectMaxSize: number) =>
	checkFileUploadExtension(file.name) && isFileSizeOk(file, projectMaxSize);

const useProjectUpload = () => {
	const { t } = useTranslation(['test']);
	const { showError, showInfo } = useSetSnackbars();
	const [uploadFile] = FileUpload.usePostV4TestsByTestIdProjectMutation();
	const { setValue } = useFormContext<ConfigurationFormData>();
	const testName = useWatch<ConfigurationFormData, 'test.name'>({ name: 'test.name' });
	const dispatch = useAppDispatch();
	const { removeAllCustomPopulation } = useCustomScenario();

	const postUploadFile = useCallback(
		async (uploadRequest: FileUpload.UploadRequest, testId: string, file: File): Promise<FileUpload.Project | void> => {
			if (testId) {
				showInfo({
					text: t('configuration.snackbar.uploading', { name: file.name }),
					id: 'upload',
					autoHideDuration: null,
				});
				return uploadFile({ testId: testId, uploadRequest: uploadRequest })
					.unwrap()
					.then((project) => {
						showInfo({ text: t('configuration.snackbar.uploadSuccess', { name: file.name }), id: 'upload' });
						return project;
					})
					.catch((error) => {
						showError({
							text: t('configuration.snackbar.uploadFail', {
								name: file.name,
								error: configurationErrorToString(error.data, t, testName),
							}),
							id: 'upload',
						});
					});
			}
		},
		[showError, showInfo, t, uploadFile, testName],
	);

	const updateConfiguration = useCallback(
		(project: FileUpload.Project) => {
			projectToConfigurationForm(setValue, project);
		},
		[setValue],
	);

	return useCallback(
		async (file: File, projectMaxSize: number, testId: string) => {
			if (canUploadFile(file, projectMaxSize)) {
				// Need to do this due to https://github.com/reduxjs/redux-toolkit/issues/3063
				const body = new FormData();
				body.append('file', file as Blob);
				await postUploadFile(body as unknown as FileUpload.UploadRequest, testId, file).then((project) => {
					if (project) {
						updateConfiguration(project);
						// @ts-expect-error Scenarios is a valid tag value
						dispatch(nlwApiV4.util.invalidateTags(['Scenarios']));
						removeAllCustomPopulation();
					}
				});
			} else {
				if (file.name !== undefined && !checkFileUploadExtension(file.name)) {
					showError({
						text: t('configuration.snackbar.fileExtensionsIncorrect', {
							extensions: allowedFileUploadExtensionString,
						}),
						id: 'upload',
					});
				}
				if (projectMaxSize && file.size !== undefined && !isFileSizeOk(file, projectMaxSize)) {
					showError({
						text: t('configuration.snackbar.fileTooLarge', {
							name: file.name,
							maxSize: getReadableSize(t, projectMaxSize),
						}),
						id: 'upload',
					});
				}
			}
		},
		[postUploadFile, updateConfiguration, dispatch, removeAllCustomPopulation, showError, t],
	);
};

export { useProjectUpload };

import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { SizingPanel } from './sizing-panel';
import { DockerImagesPanel } from './docker-images-panel';
import { SearchProviderCombobox } from './search-provider-combobox';
import { DynamicZoneFields } from '@neoload/api';

export const DynamicZoneFormModalContent = () => {
	const { t } = useTranslation(['zone']);
	const {
		watch,
		setValue,
		register,
		control,
		formState: { errors },
	} = useFormContext<DynamicZoneFields>();

	const providerId = watch('providerId');

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
				marginTop: 2,
			}}
		>
			<TextField
				size='small'
				InputLabelProps={{ shrink: true }}
				autoFocus
				label={t('common:name')}
				fullWidth
				margin='none'
				required
				error={!!errors.name}
				helperText={errors.name?.message}
				{...register('name', {
					required: t('common:input.required'),
					maxLength: { value: 150, message: t('dynamicEdition.nameTooLong') },
				})}
			/>
			<Controller
				name='providerId'
				control={control}
				rules={{ required: t('common:input.required') }}
				render={() => (
					<SearchProviderCombobox
						onSelectedProviderChange={(providerId) => setValue('providerId', providerId)}
						initialProviderId={providerId}
					/>
				)}
			/>
			<SizingPanel />
			<DockerImagesPanel />
		</Box>
	);
};

// We have a field named newPassword and don't want to disable a lot of times the rule
/* eslint-disable unicorn/no-keyword-prefix */
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import CheckOutlined from '@mui/icons-material/CheckOutlined';
import { FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import Tooltip from '@mui/material/Tooltip';
import {
	checkPasswordMinimumDigits,
	checkPasswordMinimumLength,
	checkPasswordMinimumSpecialCharacters,
	checkPasswordMinimumUpperCaseCharacters,
	checkPassworMinimumLowerCaseCharacters,
} from './change-password-utils';
import {
	isFetchBaseQueryError,
	PasswordPolicy,
	PutPasswordRequest,
	useGetV4SettingsQuery,
	usePutV4MePasswordMutation,
} from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

const FORM_DEFAULT_VALUES: PutPasswordRequest = {
	currentPassword: '',
	newPassword: '',
};
type ShowPasswordState = {
	current: boolean;
	new: boolean;
};

type ChangePasswordModalProps = {
	open: boolean;
	onClose: () => void;
};

const ChangePasswordModal = ({ open, onClose }: ChangePasswordModalProps) => {
	const { data: settings, error: getSettingsError } = useGetV4SettingsQuery();
	const [updatePassword, { isLoading: isUpdating, error: updateError, isSuccess: isUpdateSuccessful }] =
		usePutV4MePasswordMutation();
	const { showError, showInfo } = useSetSnackbars();
	const { t } = useTranslation('profile');
	const [showPasswords, setShowPasswords] = useState<ShowPasswordState>({
		current: false,
		new: false,
	});

	const {
		register,
		formState: { isDirty, isValid, errors, dirtyFields },
		handleSubmit,
		setError,
	} = useForm<PutPasswordRequest>({
		mode: 'onChange',
		defaultValues: FORM_DEFAULT_VALUES,
		criteriaMode: 'all',
	});

	useEffect(() => {
		if (getSettingsError) {
			console.error('Erros while retrieveing password policy.', getSettingsError);
			showError({ text: t('changePassword.failedToRetrievePasswordPolicy') });
			onClose();
		}
	}, [getSettingsError, showError, onClose, t]);

	useEffect(() => {
		if (updateError) {
			console.error('Error while updating password.', updateError);
			if (isFetchBaseQueryError(updateError)) {
				switch (updateError.status) {
					case 403: {
						setError('currentPassword', { message: t('changePassword.wrongCurrentPassword') });
						break;
					}
					case 400: {
						setError('newPassword', { message: t('changePassword.invalidNewPassword') });
						break;
					}
					default: {
						showError({ text: t('common:errors.otherError') });
						break;
					}
				}
			} else {
				showError({ text: t('common:errors.otherError') });
			}
		}
	}, [updateError, showError, t, setError]);

	useEffect(() => {
		if (isUpdateSuccessful) {
			showInfo({ text: t('changePassword.success') });
			onClose();
		}
	}, [isUpdateSuccessful, showInfo, onClose, t]);

	const togglePasswordVisibility = (which: keyof ShowPasswordState) => {
		setShowPasswords((old) => ({ ...old, [which]: !old[which] }));
	};

	const onSubmit = async (form: PutPasswordRequest) => {
		await updatePassword({ putPasswordRequest: form });
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth aria-labelledby='change-password-modal-title'>
			<DialogTitle id='change-password-modal-title'>
				<Stack useFlexGap justifyContent='space-between' flexDirection='row'>
					{t('changePassword.changePassword')}
				</Stack>
			</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogContent>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 3,
							marginTop: 1,
						}}
					>
						<TextField
							autoFocus
							{...register('currentPassword', {
								required: { value: true, message: t('changePassword.currentPasswordRequired') },
							})}
							label={t('changePassword.currentPassword')}
							required
							type={showPasswords['current'] ? 'text' : 'password'}
							error={!!errors.currentPassword}
							helperText={errors.currentPassword?.message}
							InputProps={{
								endAdornment: (
									<ShowPasswordIconButton
										visible={showPasswords['current']}
										toggleVisibility={() => togglePasswordVisibility('current')}
									/>
								),
							}}
							size='small'
						/>
						<TextField
							{...register('newPassword', {
								required: { value: true, message: t('changePassword.newPasswordRequired') },
								validate: settings?.passwordPolicy
									? {
											minimumLength: (value) =>
												isDirty && checkPasswordMinimumLength(value, settings.passwordPolicy.minimumLength),
											minimumDigits: (value) =>
												isDirty && checkPasswordMinimumDigits(value, settings.passwordPolicy.minimumDigits),
											minimumLowerCaseCharacters: (value) =>
												isDirty &&
												checkPassworMinimumLowerCaseCharacters(
													value,
													settings.passwordPolicy.minimumLowerCaseCharacters,
												),
											minimumUpperCaseCharacters: (value) =>
												isDirty &&
												checkPasswordMinimumUpperCaseCharacters(
													value,
													settings.passwordPolicy.minimumUpperCaseCharacters,
												),
											minimumSpecialCharacters: (value) =>
												isDirty &&
												checkPasswordMinimumSpecialCharacters(
													value,
													settings.passwordPolicy.specialCharacters,
													settings.passwordPolicy.minimumSpecialCharacters,
												),
										}
									: {},
							})}
							label={t('changePassword.newPassword')}
							required
							type={showPasswords['new'] ? 'text' : 'password'}
							disabled={!settings}
							helperText={
								<Helper
									policy={settings?.passwordPolicy}
									validation={errors.newPassword}
									neutral={!dirtyFields.newPassword}
									otherErrors={errors.newPassword?.message}
								/>
							}
							error={dirtyFields.newPassword && !!errors.newPassword}
							InputProps={{
								endAdornment: (
									<ShowPasswordIconButton
										visible={showPasswords['new']}
										toggleVisibility={() => togglePasswordVisibility('new')}
									/>
								),
							}}
							size='small'
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						disabled={isUpdating}
						onClick={onClose}
						aria-label={t('common:cancel')}
						data-trackingid='change-password-cancel'
					>
						{t('common:cancel')}
					</Button>
					<Button
						variant='contained'
						disabled={isUpdating || !isValid}
						startIcon={isUpdating ? <CircularProgress size={24.5} color='inherit' /> : null}
						aria-label={t('changePassword.changePassword')}
						type='submit'
						data-trackingid='change-password-ok'
					>
						{t('changePassword.changePassword')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

const ShowPasswordIconButton = ({ visible, toggleVisibility }: { visible: boolean; toggleVisibility: () => void }) => {
	const { t } = useTranslation('profile');
	const label = visible ? t('changePassword.hide') : t('changePassword.show');
	return (
		<InputAdornment position='end'>
			<Tooltip arrow title={label}>
				<IconButton aria-label={label} onClick={toggleVisibility}>
					{visible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
				</IconButton>
			</Tooltip>
		</InputAdornment>
	);
};

const Helper = ({
	policy,
	validation,
	neutral,
	otherErrors,
}: {
	policy: PasswordPolicy | undefined;
	validation: FieldError | undefined;
	neutral: boolean;
	otherErrors?: string;
}) => {
	const { t } = useTranslation('profile');
	return (
		<Stack component='span'>
			<HelperRow
				validation={!validation?.types?.minimumLength}
				text={t('changePassword.validation.minimumLength', { minimumLength: policy?.minimumLength })}
				loading={!policy}
				neutral={neutral}
			/>
			<HelperRow
				validation={!validation?.types?.minimumDigits}
				text={t('changePassword.validation.minimumDigits', { minimumDigits: policy?.minimumDigits })}
				loading={!policy}
				neutral={neutral}
			/>
			<HelperRow
				validation={!validation?.types?.minimumLowerCaseCharacters}
				text={t('changePassword.validation.minimumLowerCaseCharacters', {
					minimumLowerCaseCharacters: policy?.minimumLowerCaseCharacters,
				})}
				loading={!policy}
				neutral={neutral}
			/>
			<HelperRow
				validation={!validation?.types?.minimumUpperCaseCharacters}
				text={t('changePassword.validation.minimumUpperCaseCharacters', {
					minimumUpperCaseCharacters: policy?.minimumUpperCaseCharacters,
				})}
				loading={!policy}
				neutral={neutral}
			/>
			<HelperRow
				validation={!validation?.types?.minimumSpecialCharacters}
				text={t('changePassword.validation.minimumSpecialCharacters', {
					minimumSpecialCharacters: policy?.minimumSpecialCharacters,
					specialCharacters: policy?.specialCharacters,
				})}
				loading={!policy}
				neutral={neutral}
			/>
			<Typography variant='body2' color='error' component='span'>
				{otherErrors}
			</Typography>
		</Stack>
	);
};

type HelperRowProps = {
	validation: boolean | undefined;
	text: string;
	loading: boolean;
	neutral: boolean;
};

const getMessageColor = (neutral: boolean, validation: boolean | undefined) => {
	if (neutral) {
		return 'secondary';
	}
	return validation ? 'success.main' : 'error';
};

const HelperRow = ({ validation, text, loading, neutral }: HelperRowProps) => (
	<Stack direction='row' alignItems='center' gap={1} component='span'>
		{loading ? (
			<Skeleton width='100%' data-testid='skeleton' />
		) : (
			<>
				{!neutral &&
					(validation ? (
						<CheckOutlined color='success' fontSize='small' />
					) : (
						<CloseOutlined fontSize='small' color='error' />
					))}
				<Typography variant='body2' color={getMessageColor(neutral, validation)} component='span'>
					{text}
				</Typography>
			</>
		)}
	</Stack>
);

export { ChangePasswordModal };

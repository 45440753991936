import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { DownloadUsageHistory } from '@neoload/ui';
import { useDocumentTitleHandler } from '@neoload/hooks';

const UsageHistoryPage = () => {
	const { t } = useTranslation(['usageHistory']);
	useDocumentTitleHandler(t('title'));
	return (
		<>
			<Typography variant='subtitle1' p={3}>
				{t('title')}
			</Typography>
			<Divider />
			<DownloadUsageHistory />
		</>
	);
};

export { UsageHistoryPage };

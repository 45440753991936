import { useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { FormProvider, useForm } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { DedicatedIpsReleaseDateForm } from './dedicated-ips-release-date-form';
import { toPatchAllCloudZonesValidDateFormat } from '../utils';
import { CloudCredits, usePatchV4ZonesMutation } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

const PATCH_DEDICATED_IPS_RELEASE_DATE_DIALOG_SNACKBAR_ID = 'PATCH_DEDICATED_IPS_RELEASE_DATE_DIALOG_SNACKBAR_ID';

type DedicatedIpsReleaseDateModalProps = {
	open: boolean;
	doClose: () => void;
	expirationDate: string;
	cloudCredits: CloudCredits;
};

export type Form = {
	expirationDate: string;
	modificationCostInCloudCredits: number | null;
};

export const DedicatedIpsReleaseDateModal = ({
	open,
	doClose,
	expirationDate,
	cloudCredits,
}: DedicatedIpsReleaseDateModalProps) => {
	const { t } = useTranslation(['zone']);
	const { showInfo, showError } = useSetSnackbars();
	const [patchZones, patchZonesResult] = usePatchV4ZonesMutation();
	const { formState, reset, handleSubmit, ...methods } = useForm<Form>({
		defaultValues: {
			expirationDate,
			modificationCostInCloudCredits: null,
		},
		mode: 'onChange',
	});

	useEffect(() => {
		if (open) {
			reset({
				expirationDate,
				modificationCostInCloudCredits: null,
			});
		}
	}, [expirationDate, open, reset]);

	return (
		<FormProvider formState={formState} reset={reset} handleSubmit={handleSubmit} {...methods}>
			<Dialog open={open}>
				<DialogTitle>{t('cloudZone.dedicatedIps.releaseDateDialog.title')}</DialogTitle>
				<DialogContent>
					<DedicatedIpsReleaseDateForm cloudCredits={cloudCredits} />
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							doClose();
						}}
						variant='text'
						data-trackingid='dedicated-ips-release-date-cancel'
					>
						{t('common:cancel')}
					</Button>
					{patchZonesResult.isLoading ? (
						<IconButton disabled={true}>
							<CircularProgress size='1em' />
						</IconButton>
					) : (
						<Button
							color='primary'
							disabled={!formState.isValid}
							onClick={handleSubmit(async (form) => {
								await patchZones({
									patchAllZonesFields: {
										type: 'CLOUD',
										expirationDate: toPatchAllCloudZonesValidDateFormat(form.expirationDate),
									},
								})
									.unwrap()
									.then((_result) => {
										showInfo({
											id: PATCH_DEDICATED_IPS_RELEASE_DATE_DIALOG_SNACKBAR_ID,
											text: t('cloudZone.dedicatedIps.releaseDateDialog.patchSuccess'),
										});
									})
									.catch((error) => {
										console.error('An error occurred while updating the dedicated IPs release date', error);
										showError({
											id: PATCH_DEDICATED_IPS_RELEASE_DATE_DIALOG_SNACKBAR_ID,
											text: t('cloudZone.dedicatedIps.releaseDateDialog.patchFailure'),
										});
									});
								doClose();
							})}
							data-trackingid='dedicated-ips-release-date-ok'
						>
							{t('common:apply')}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</FormProvider>
	);
};

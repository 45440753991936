import { GridCellParams, GridColDef, GridInitialState, useGridApiRef } from '@mui/x-data-grid-pro';
import { ComponentPropsWithoutRef, useEffect, useMemo, useState } from 'react';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconEllipsisVertical from '@tricentis/aura/components/IconEllipsisVertical.js';
import IconButton from '@mui/material/IconButton';
import { ZoneDetails } from './zone-details';
import { CloudZoneWithAvailability, useWithUnavailableCloudZones } from './cloud/use-with-unavailable-cloud-zones';
import { ZoneDatagridZeroState } from './zone-datagrid-zero-state';
import { getPartialErrorZones } from './zone-helpers';
import { AllocateDedicatedIpsModal } from './cloud/dedicated-ips/allocate-dedicated-ips-modal';
import { DedicatedIpsReleaseDateInfo } from './cloud/dedicated-ips';
import { ReleaseAllIpsModal } from './cloud/dedicated-ips/release-all-ips-modal';
import { DEFAULT_GRID_PROPS, onColumnChange } from '../../common/datagrid';
import { DatagridWithDrawer } from '../../common/data-grid/datagrid-with-drawer';
import { Spinner } from '../../common/spinner';
import { DatagridAction } from '../../common/data-grid/actions/data-grid-actions';
import { CreateResourceButton } from '../../common/button/create-resource-button';
import { useSetSnackbars, useColumnsState } from '@neoload/hooks';
import { DynamicZoneRead, StaticZoneRead, useGetV4ZonesQuery, Zone } from '@neoload/api';

const CLIPBOARD_SUCCESS_COPY_ALL_IPS_SNACKBAR_ID = 'CLIPBOARD_SUCCESS_COPY_ALL_IPS_SNACKBAR_ID';
const GET_ZONES_ERROR_SNACKBAR_ID = 'GET_ZONES_ERROR_SNACKBAR_ID';

const columnsStateKeyPrefix = 'ZONES_COLUMNS_STATE';
const defaultInitialState: GridInitialState = {
	sorting: {
		sortModel: [{ field: 'name', sort: 'asc' }],
	},
};

export type OneOfZone = StaticZoneRead | DynamicZoneRead | CloudZoneWithAvailability;
export type ZoneDatagridActionGetter<T> = DatagridAction | ((zone: T) => DatagridAction | null);

type CommonZonesDatagridProps<T extends OneOfZone> = {
	type: Zone['type'];
	columns: GridColDef<T>[];
	getRowId?: (row: T) => string;
	disabledRow?: (row: T) => boolean;
	initialState?: GridInitialState;
	onAdd?: () => void;
	actionGetters?: ZoneDatagridActionGetter<OneOfZone>[];
};
const CommonZonesDatagrid = <T extends OneOfZone>({
	type,
	columns,
	getRowId = (row) => row.zoneId,
	disabledRow,
	initialState = defaultInitialState,
	onAdd,
}: CommonZonesDatagridProps<T>) => {
	const { t } = useTranslation(['zone']);
	const [selectedZoneId, setSelectedZoneId] = useState<string>();
	const { showInfo, showError } = useSetSnackbars();
	const [openAllocationDialog, setOpenAllocationDialog] = useState(false);
	const [openReleaseAllIpsDialog, setOpenReleaseAllIpsDialog] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const {
		data: zonesData = { items: [] },
		isLoading,
		error: zoneError,
	} = useGetV4ZonesQuery(
		{ type: type },
		{
			pollingInterval: 10_000,
		},
	);

	const zonesPage = useMemo(() => {
		const partialErrorZonesResult = getPartialErrorZones(zoneError);
		return partialErrorZonesResult ?? zonesData;
	}, [zoneError, zonesData]);

	const rows = useWithUnavailableCloudZones(zonesPage?.items || [], type);

	useEffect(() => {
		if (zoneError) {
			showError({ id: GET_ZONES_ERROR_SNACKBAR_ID, text: t('ncpErrorMessage'), autoHideDuration: 10_000 });
		}
	}, [showError, zoneError, t]);

	const isAllocating = rows?.some(
		(row) => 'dedicatedIpsDetails' in row && row.dedicatedIpsDetails?.status === 'ALLOCATING',
	);
	const isReleasing = rows?.some(
		(row) => 'dedicatedIpsDetails' in row && row.dedicatedIpsDetails?.status === 'RELEASING',
	);

	const isButtonDisabled = useMemo(
		() =>
			zoneError !== undefined ||
			rows?.some(
				(row): row is CloudZoneWithAvailability =>
					'dedicatedIpsDetails' in row && row.dedicatedIpsDetails?.status !== 'READY',
			) ||
			isAllocating ||
			isReleasing ||
			rows?.length === 0 ||
			!rows?.some((row) => row.type === 'CLOUD' && row.available),
		[zoneError, rows, isAllocating, isReleasing],
	);

	const handleOpenDialog = () => setOpenAllocationDialog(true);
	const handleCloseDialog = () => setOpenAllocationDialog(false);

	const handleConfirm = (error: boolean, message: string, id?: string) => {
		setOpenAllocationDialog(false);
		if (error) {
			showError({ text: t(message), id, autoHideDuration: 10_000 });
		} else {
			showInfo({ text: t(message), id, autoHideDuration: 10_000 });
		}
	};

	const copyAllIps = (zones: OneOfZone[] | undefined) => {
		if (!zones) {
			return;
		}
		const ipAddresses = zones
			.filter((zone) => zone.type === 'CLOUD')
			.flatMap((zone) => zone.dedicatedIpsDetails?.ipAddresses ?? []);
		navigator.clipboard
			.writeText(JSON.stringify(ipAddresses))
			.then(() => {
				showInfo({
					text: t('actions.copyAllIpAddressesToClipboardSuccess'),
					id: CLIPBOARD_SUCCESS_COPY_ALL_IPS_SNACKBAR_ID,
				});
			})
			.catch((error) => console.error('Unable to copy all IP addresses to clipboard.', error));
	};

	const releaseAllDedicatedIps = (zones: OneOfZone[] | undefined) => {
		if (!zones) {
			return;
		}
		setOpenReleaseAllIpsDialog(true);
	};

	const apiRef = useGridApiRef();
	const { updatedInitialState, updatedColumns, storeColumnState } = useColumnsState(
		`${columnsStateKeyPrefix}${type}`,
		initialState,
		columns,
		apiRef,
	);

	const getMainActions = () => {
		if (type === 'CLOUD') {
			return [{ children: renderCloudActions() }];
		}

		if ((type === 'DYNAMIC' || type === 'STATIC') && onAdd) {
			return [{ children: <CreateResourceButton onClick={() => onAdd()}>{t('createZone')}</CreateResourceButton> }];
		}

		return [];
	};

	const renderCloudActions = () => (
		<Stack direction='row' spacing={1} alignItems='center'>
			<DedicatedIpsReleaseDateInfo selectedZoneId={selectedZoneId} />
			<Divider orientation='vertical' />
			<Tooltip title={isButtonDisabled ? t('cloudZone.disabledManageIpsMessage') : ''} arrow>
				<span>
					<Button
						onClick={handleOpenDialog}
						variant='contained'
						color='primary'
						disabled={isButtonDisabled}
						size='small'
						sx={{
							height: '32px',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
						}}
						aria-label={isAllocating || isReleasing ? t('cloudZone.statusLoading') : t('cloudZone.allocate')}
					>
						{renderAllocateButtonContent()}
					</Button>
				</span>
			</Tooltip>
			{selectedZoneId ? (
				<div>
					<IconButton onClick={handleMenuOpen}>
						<IconEllipsisVertical sx={{ fontSize: '24px' }} />
					</IconButton>
					<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
						<MenuItem onClick={() => copyAllIps(rows)} disabled={zoneError !== undefined}>
							{t('cloudZone.copyAllIps')}
						</MenuItem>
						<MenuItem onClick={() => releaseAllDedicatedIps(rows)} disabled={isButtonDisabled}>
							{renderReleaseButtonContent()}
						</MenuItem>
					</Menu>
				</div>
			) : (
				<>
					<span>
						<Button
							variant='outlined'
							size='small'
							onClick={() => copyAllIps(rows)}
							disabled={zoneError !== undefined}
							sx={{
								height: '32px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
							data-testid='copy-all-ips-button'
						>
							{t('cloudZone.copyAllIps')}
						</Button>
					</span>
					<Tooltip title={isButtonDisabled ? t('cloudZone.disabledManageIpsMessage') : ''} arrow>
						<span>
							<Button
								variant='outlined'
								size='small'
								onClick={() => releaseAllDedicatedIps(rows)}
								disabled={isButtonDisabled}
								sx={{
									height: '32px',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
								}}
								aria-label={
									isAllocating || isReleasing ? t('cloudZone.statusLoading') : t('cloudZone.releaseAllDedicatedIps')
								}
							>
								{renderReleaseButtonContent()}
							</Button>
						</span>
					</Tooltip>
				</>
			)}
		</Stack>
	);

	const renderAllocateButtonContent = () => {
		if (isAllocating || isReleasing) {
			return (
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<CircularProgress size={16} sx={{ marginRight: 1 }} />
					<Typography variant='body2'>
						{isAllocating ? t('cloudZone.allocatingIps') : t('cloudZone.releasingIps')}
					</Typography>
				</Box>
			);
		}
		return t('cloudZone.allocate');
	};

	const renderReleaseButtonContent = () => {
		if (isAllocating || isReleasing) {
			return (
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<CircularProgress size={16} sx={{ marginRight: 1 }} />
					<Typography variant='body2'>
						{isAllocating ? t('cloudZone.allocatingIps') : t('cloudZone.releasingIps')}
					</Typography>
				</Box>
			);
		}
		return t('cloudZone.releaseAllDedicatedIps');
	};

	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const componentsProps: { toolbar: ComponentPropsWithoutRef<typeof Toolbar> } = {
		toolbar: {
			displaySearchBox: true,
			hideFiltersIcon: type !== 'CLOUD',
			displayColumnOptions: false,
			syncLocalStorage: {
				datagridId: `neoload${type}ZonesDataGrid`,
				isSyncEnabled: true,
			},
			mainActions: getMainActions(),
			title: t(`datagridTitles.${type}`),
		},
	};

	const drawer: ComponentPropsWithoutRef<typeof DatagridWithDrawer>['drawer'] = {
		onOpen: (params: GridCellParams) => setSelectedZoneId(params.row.zoneId),
		onClose: () => setSelectedZoneId(undefined),
		title: t('details.title'),
		selectedRowId: selectedZoneId,
	};

	if (isLoading) {
		return <Spinner />;
	}

	if (type === 'DYNAMIC' && zonesPage?.items.length === 0) {
		return <ZoneDatagridZeroState createZone={onAdd} />;
	}

	return (
		<>
			<DatagridWithDrawer
				{...DEFAULT_GRID_PROPS}
				{...onColumnChange(storeColumnState)}
				apiRef={apiRef}
				checkboxSelection={false}
				rows={rows}
				loading={isLoading}
				getRowId={getRowId}
				initialState={updatedInitialState}
				columns={updatedColumns}
				disableRowSelectionOnClick={false}
				slotProps={componentsProps}
				slots={{
					toolbar: Toolbar,
				}}
				drawer={drawer}
				getRowClassName={({ row }) => (disabledRow?.(row) ? 'datagrid-disabled-row' : '')}
			>
				{selectedZoneId && <ZoneDetails zoneId={selectedZoneId} />}
			</DatagridWithDrawer>
			{openAllocationDialog && (
				<AllocateDedicatedIpsModal
					open={openAllocationDialog}
					handleClose={handleCloseDialog}
					handleConfirm={handleConfirm}
				/>
			)}
			{openReleaseAllIpsDialog && (
				<ReleaseAllIpsModal open={openReleaseAllIpsDialog} handleClose={() => setOpenReleaseAllIpsDialog(false)} />
			)}
		</>
	);
};

export { CommonZonesDatagrid };

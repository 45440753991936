import React, { isValidElement, ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

type ValueLicenseType = string | number | ReactElement | undefined;

type ValuesInfoLicenseProps = {
	values: ValueLicenseType[];
};

const ValuesInfoLicense = ({ values }: ValuesInfoLicenseProps) => (
	<Stack direction='column' spacing={1}>
		{values.map((value, index) => (
			<Stack key={String(value) + index}>
				{isValidElement(value) ? value : <Typography variant='body2'>{value || '—'}</Typography>}
			</Stack>
		))}
	</Stack>
);

export { ValuesInfoLicense };

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import IconWarningOutlined from '@tricentis/aura/components/IconWarningOutlined.js';
import IconErrorOutlined from '@tricentis/aura/components/IconErrorOutlined.js';
import IconCheckCircleOutlined from '@tricentis/aura/components/IconCheckCircleOutlined.js';
import { timeUtils } from '@neoload/utils';

type SubscriptionPlanStatusProps = {
	expirationDate: string | null;
};

const SubscriptionStatusPlan = ({ expirationDate }: SubscriptionPlanStatusProps) => {
	const { t } = useTranslation(['subscription'], { keyPrefix: 'saasPlan' });
	const isExpired = timeUtils.isDatePast(expirationDate);
	const isExpiredOrNull = expirationDate === null || isExpired;

	return (
		<Stack spacing={2}>
			<Stack direction='row' alignItems='center' spacing={2}>
				<Typography variant='subtitle1'>{t('title')}</Typography>
				<Chip
					data-testid='status-plan'
					variant='filled'
					size='small'
					color={isExpiredOrNull ? 'error' : 'success'}
					label={isExpiredOrNull ? t('status.expired') : t('status.active')}
					icon={isExpiredOrNull ? <IconErrorOutlined /> : <IconCheckCircleOutlined />}
				/>
			</Stack>
			<Stack direction='row' spacing={1}>
				<Typography variant='body2' color='secondary'>
					{t('expirationDate')}
				</Typography>
				{expirationDate ? (
					<>
						{isExpired && <IconErrorOutlined color='error' />}
						{timeUtils.isDateArriveInNextMonth(expirationDate) && <IconWarningOutlined color='warning' />}
						<Typography variant='body2' color={isExpired ? 'error' : 'inherit'} data-testid='expiration-date-plan'>
							{timeUtils.dayFormat(expirationDate)}
						</Typography>
					</>
				) : (
					<Typography variant='body2' data-testid='expiration-date-plan'>
						{t('unknown')}
					</Typography>
				)}
			</Stack>
		</Stack>
	);
};

export { SubscriptionStatusPlan };

import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { usePatchV4WebhooksByWebhookIdMutation } from '@neoload/api';
import { useCrudBatchMessage } from '@neoload/hooks';

type EnableWebhookSwitchButtonProps = {
	enabled: boolean;
	webhookId: string;
};

export const EnableWebhookSwitchButton = ({ enabled, webhookId }: EnableWebhookSwitchButtonProps) => {
	const [patchWebhook, { isLoading: isPatching }] = usePatchV4WebhooksByWebhookIdMutation();
	const { t } = useTranslation('workspace', { keyPrefix: 'tabs.webhooks' });
	const { update } = useCrudBatchMessage('workspace', 'tabs.webhooks');

	const enableWebhook = async (enabled: boolean, webhookId: string) =>
		update([{ enabled }], (input) => patchWebhook({ webhookId, patchWebhookRequest: input }).unwrap());

	return (
		<Tooltip arrow title={t(enabled ? 'disable' : 'enable')}>
			<span>
				<Switch
					disabled={isPatching}
					checked={enabled}
					onClick={() => enableWebhook(!enabled, webhookId)}
					aria-label={t(enabled ? 'enabled' : 'disabled')}
				/>
			</span>
		</Tooltip>
	);
};

import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { usePatchV4ZonesMutation } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

export type ReleaseAllIpsModalProps = {
	open: boolean;
	handleClose: () => void;
};
const RELEASE_ALL_IPS_DIALOG_SNACKBAR_ID = 'RELEASE_ALL_IPS_DIALOG_SNACKBAR_ID';
const ReleaseAllIpsModal = ({ open = false, handleClose }: ReleaseAllIpsModalProps) => {
	const { t } = useTranslation(['zone']);
	const [patchZones] = usePatchV4ZonesMutation();

	const { showError, showInfo } = useSetSnackbars();

	const handleReleaseAllIps = async () => {
		await patchZones({
			patchAllZonesFields: {
				type: 'CLOUD',
				releaseAllReservedIps: true,
			},
		}).then((result) => {
			if (result.data?.type === 'CLOUD') {
				showInfo({
					text: t('cloudZone.dedicatedIps.releaseModal.success'),
					id: RELEASE_ALL_IPS_DIALOG_SNACKBAR_ID,
					autoHideDuration: 10_000,
				});
				handleClose();
			} else {
				showError({
					text: t('cloudZone.dedicatedIps.releaseModal.error'),
					id: RELEASE_ALL_IPS_DIALOG_SNACKBAR_ID,
					autoHideDuration: 10_000,
				});
				handleClose();
			}
		});
	};

	return (
		<Dialog open={open} onClose={handleClose} data-testid='release-all-ips-dialog'>
			<DialogTitle variant='h1' data-testid='release-all-ips-dialog-title'>
				{t('cloudZone.dedicatedIps.releaseModal.title')}
			</DialogTitle>
			<DialogContent>
				<Box>
					<Typography variant='bodyRegular'>{t('cloudZone.dedicatedIps.releaseModal.description')}</Typography>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					color='primary'
					data-testid='release-all-ips-dialog-cancel-button'
					onClick={handleClose}
					data-trackingid='release-all-ips-cancel'
				>
					{t('cloudZone.dedicatedIps.releaseModal.cancel')}
				</Button>
				<Button
					variant='contained'
					data-testid='release-all-ips-dialog-release-button'
					color='error'
					onClick={handleReleaseAllIps}
					data-trackingid='release-all-ips-ok'
				>
					{t('cloudZone.dedicatedIps.releaseModal.release')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export { ReleaseAllIpsModal };

import { useTranslation } from 'react-i18next';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import AddLinkIcon from '@mui/icons-material/AddLink';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { ExternalUrlModal } from './external-url-modal';
import { useGetMe } from '@neoload/hooks';
import { ExternalUrl } from '@neoload/api';

export type EmptyExternalUrlLinkProps = {
	onChange: (updatedExternalUrl: ExternalUrl) => Promise<void>;
};

const EmptyExternalUrlLink = ({ onChange }: EmptyExternalUrlLinkProps) => {
	const [{ hasTesterPermissions }] = useGetMe();
	const { t } = useTranslation(['result']);
	const [openModal, setOpenModal] = useState(false);

	if (hasTesterPermissions) {
		return (
			<>
				<Tooltip arrow title={t('externalUrl.add.title')}>
					<IconButton sx={{ flexGrow: 0 }} aria-label={t('externalUrl.add.title')} onClick={() => setOpenModal(true)}>
						<AddLinkIcon />
					</IconButton>
				</Tooltip>
				<ExternalUrlModal open={openModal} closeModal={() => setOpenModal(false)} onChange={onChange} />
			</>
		);
	}
	return null;
};

export { EmptyExternalUrlLink };

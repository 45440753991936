import { GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ReservationStatusChip } from './reservation-status-chip';
import { reservationsDateSorter } from './reservation-grid-helpers';
import { i18n } from '../../../i18n';
import { ActionsCellMenu } from '../../common/data-grid/actions/actions-cell-menu';
import { DatagridAction } from '../../common/data-grid/actions/data-grid-actions';
import { timeUtils } from '@neoload/utils';
import { Reservation } from '@neoload/api';

const useReservationsDataGridColumns = (actions: DatagridAction[]): GridColDef<Reservation>[] => {
	const { t } = useTranslation(['reservation']);
	const formatterValue = new Intl.NumberFormat(i18n.language, {
		compactDisplay: 'short',
		minimumIntegerDigits: 1,
	});

	return [
		{
			field: 'status',
			headerName: t('grid.header.status'),
			minWidth: 150,
			flex: 0.5,
			resizable: true,
			sortable: true,
			renderCell: (props) => <ReservationStatusChip status={props.row.status} />,
		},
		{
			field: 'name',
			headerName: t('grid.header.name'),
			flex: 0.5,
			minWidth: 300,
			resizable: true,
			sortable: true,
		},
		{
			field: 'startDate',
			headerName: t('grid.header.time'),
			flex: 1,
			minWidth: 400,
			resizable: true,
			sortable: true,
			type: 'string',
			valueGetter: (_, row) =>
				timeUtils.dateTimeAbsolute(row.startDate) + ' - ' + timeUtils.dateTimeAbsolute(row.endDate),
			sortComparator: (v1, v2) => {
				if (typeof v1 === 'string' && typeof v2 === 'string') {
					return reservationsDateSorter(v1, v2);
				}

				return 0;
			},
		},
		{
			field: 'lgCountByZoneId',
			headerName: t('grid.header.lgs'),
			flex: 1,
			minWidth: 100,
			resizable: true,
			sortable: false,
			type: 'number',
			valueGetter: (value: Reservation['lgCountByZoneId']) =>
				formatterValue.format(Object.entries(value).reduce((accumulator, [, lgs]) => accumulator + lgs, 0)),
		},
		{
			field: 'vuCountByVuType',
			headerName: t('grid.header.vus'),
			flex: 1,
			minWidth: 100,
			resizable: true,
			sortable: false,
			type: 'number',
			valueGetter: (value: Reservation['vuCountByVuType']) => {
				if (!value) {
					return 0;
				}
				return formatterValue.format(Object.entries(value).reduce((accumulator, [, vus]) => accumulator + vus, 0));
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			sortable: false,
			resizable: false,
			disableReorder: true,
			filterable: false,
			renderCell: (params) => (
				<>
					<ActionsCellMenu actions={actions} rowData={params.row} stopPropagation={true} />
					<Tooltip arrow title={t('common:datagrid.viewDetails')}>
						<IconButton size='small' aria-label={t('common:datagrid.viewDetails')}>
							<KeyboardArrowRightIcon />
						</IconButton>
					</Tooltip>
				</>
			),
		},
	];
};

export { useReservationsDataGridColumns };

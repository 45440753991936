import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import isURL from 'validator/lib/isURL';
import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';
import {
	ANNOTATIONS_LINK,
	LABELS_LINK,
	NODE_SELECTOR_LINK,
	TOLERATIONS_LINK,
} from './infrastructure-provider-form-modal';
import { InputWithInfoIcon } from './input-with-info-icon';
import { TolerationInput } from './toleration-input';
import { TokenTextField } from './token-text-field';
import { CreateProviderRequestWrite } from '@neoload/api';

type InfrastructureProviderFormContentProps = {
	isEdition: boolean;
};

export const InfrastructureProviderFormContent = ({ isEdition }: InfrastructureProviderFormContentProps) => {
	const { t } = useTranslation('infrastructureProviders');
	const {
		register,
		formState: { errors },
		watch,
	} = useFormContext<CreateProviderRequestWrite>();

	const type = watch('type');
	const nameSpaceLabel = type === 'OPENSHIFT' ? t('modal.project') : t('modal.namespace');

	return (
		<>
			<Stack gap={2} marginTop={1}>
				<TextField
					size='small'
					autoFocus
					label={t('modal.providerName')}
					fullWidth
					required
					error={!!errors.name}
					helperText={errors.name?.message}
					InputProps={{ inputProps: { maxLength: 140 } }}
					{...register('name', {
						required: t('common:input.required'),
					})}
				/>
				<FormControl fullWidth variant='outlined'>
					<InputLabel required={true}>{t('modal.type')}</InputLabel>
					<Select<CreateProviderRequestWrite['type']>
						{...register('type', { required: t('common:input.required') })}
						defaultValue={type}
						sx={{ width: '100%' }}
						renderValue={(option) => <ListItemText primary={t(`modal.types.${option}`)} />}
						label={t('modal.type')}
						variant='outlined'
						size='small'
					>
						{['KUBERNETES', 'OPENSHIFT'].map((option) => (
							<MenuItem key={option} value={option}>
								<ListItemText primary={t(`modal.types.${option}`)} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<TextField
					size='small'
					label={t('modal.apiUrl')}
					fullWidth
					required
					error={!!errors.apiUrl}
					helperText={errors.apiUrl?.message}
					{...register('apiUrl', {
						required: t('common:input.required'),
						validate: (value) =>
							/* eslint-disable @typescript-eslint/naming-convention*/
							isURL(value, {
								protocols: ['http', 'https'],
								require_protocol: true,
								require_tld: false,
							}) || t('modal.urlFormat'),
					})}
				/>
				<TokenTextField isEdition={isEdition} />
				<TextField
					size='small'
					label={nameSpaceLabel}
					fullWidth
					required
					error={!!errors.namespace}
					helperText={errors.namespace?.message}
					InputProps={{ inputProps: { maxLength: 63 } }}
					{...register('namespace', {
						required: t('common:input.required'),
						pattern: {
							value: /^[\da-z]([\da-z-]*[\da-z])?$/,
							message: type === 'OPENSHIFT' ? t('modal.projectPatternError') : t('modal.nameSpacePatternError'),
						},
					})}
				/>
			</Stack>
			<Accordion
				disableGutters
				elevation={0}
				sx={{
					backgroundColor: 'transparent',
					// disable the grey divider line on the top of the accordion when folded
					'&:before': {
						display: 'none',
					},
				}}
			>
				<AccordionSummary
					sx={{ marginTop: 2, padding: 0 }}
					expandIcon={<ExpandMoreIcon />}
					title={t('modal.additionalParameters')}
				>
					{t('modal.additionalParameters')}
				</AccordionSummary>
				<AccordionDetails sx={{ margin: 0, padding: 0 }}>
					<Stack gap={2}>
						<InputWithInfoIcon text={t('modal.labels')} url={LABELS_LINK}>
							<TextField
								size='small'
								InputLabelProps={{ shrink: true }}
								placeholder={t('modal.patternPlaceholderText')}
								label={t('modal.labels')}
								fullWidth
								error={!!errors.labels}
								helperText={errors.labels?.message}
								{...register('labels', {
									pattern: {
										value: /^([\dA-Za-z][\w./\\-]*=[^,]+)(,[\dA-Za-z][\w./\\-]*=[^,]+)*$/,
										message: t('modal.labelPatternError'),
									},
								})}
							/>
						</InputWithInfoIcon>
						<InputWithInfoIcon text={t('modal.annotations')} url={ANNOTATIONS_LINK}>
							<TextField
								placeholder={t('modal.patternPlaceholderText')}
								InputLabelProps={{ shrink: true }}
								size='small'
								label={t('modal.annotations')}
								fullWidth
								error={!!errors.annotations}
								helperText={errors.annotations?.message}
								{...register('annotations', {
									pattern: {
										value: /^([\dA-Za-z][\w./\\-]*=[^,]+)(,[\dA-Za-z][\w./\\-]*=[^,]+)*$/,
										message: t('modal.annotationsPatternError'),
									},
								})}
							/>
						</InputWithInfoIcon>
						<InputWithInfoIcon text={t('modal.nodeSelectors')} url={NODE_SELECTOR_LINK}>
							<TextField
								size='small'
								placeholder={t('modal.patternPlaceholderText')}
								InputLabelProps={{ shrink: true }}
								label={t('modal.nodeSelectors')}
								fullWidth
								error={!!errors.nodeSelectors}
								helperText={errors.nodeSelectors?.message}
								{...register('nodeSelectors', {
									pattern: {
										value: /^([\dA-Za-z][\w./\\-]*=[^,]+)(,[\dA-Za-z][\w./\\-]*=[^,]+)*$/,
										message: t('modal.nodeSelectorsPatternError'),
									},
								})}
							/>
						</InputWithInfoIcon>
						<InputWithInfoIcon text={t('modal.tolerations')} url={TOLERATIONS_LINK}>
							<TolerationInput />
						</InputWithInfoIcon>
					</Stack>
				</AccordionDetails>
			</Accordion>
		</>
	);
};

import React, { forwardRef, Ref, useState } from 'react';

type InputLicenseFileProps = {
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
const InputLicenseFile = forwardRef(({ onChange }: InputLicenseFileProps, ref: Ref<HTMLInputElement> | undefined) => {
	const [inputKey, setInputKey] = useState(Date.now());

	return (
		<input
			ref={ref}
			key={inputKey}
			name='license-file'
			data-testid='license-file-input'
			type='file'
			hidden
			accept='.lic'
			onChange={(event) => {
				onChange(event);
				setInputKey(Date.now());
			}}
		/>
	);
});

export { InputLicenseFile };

import { ReactNode, isValidElement } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export type LabelledItemProps = {
	id: string;
	displayLoading?: boolean;
	title: string;
	value?: string | ReactNode | number | object | object[];
};

const LabelledItem = ({ id, displayLoading = false, title, value }: LabelledItemProps) => {
	if (value === undefined || value === null) {
		return null;
	}
	const valuesAsArray = Array.isArray(value) ? value : [value];
	const lastValueIndex = valuesAsArray.length - 1;
	return (
		<>
			<Typography id={id} variant='body2' color='text.secondary' component='p' marginBottom={0.5}>
				{displayLoading ? <Skeleton sx={{ maxWidth: '10%' }} /> : title}
			</Typography>
			{isValidElement(value) ? (
				<Box marginBottom={2} aria-labelledby={id}>
					{value}
				</Box>
			) : (
				valuesAsArray.map((v, index) => (
					<Typography
						key={v.toString() + index}
						variant='body2'
						marginBottom={index === lastValueIndex ? 2 : 0.5}
						style={{ overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }}
						aria-labelledby={id}
					>
						{displayLoading ? <Skeleton sx={{ maxWidth: '60%' }} /> : v.toString()}
					</Typography>
				))
			)}
		</>
	);
};

export { LabelledItem };

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useCallback } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import dayjs, { UnitTypeShort } from 'dayjs';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import { TFunction } from 'i18next';
import { DurationPicker } from '../../../../../common/input/duration-picker';
import { PostOfflineLease } from '@neoload/api';

const DOCUMENTATION_LINK =
	'https://documentation.tricentis.com/nlweb/latest/en/content/reference_guide/settings_manage_workspace_subscription.htm?Highlight=offline';

type CreateOfflineLeaseModalContentProps = {
	maxWebVus: number;
	maxSapVus: number;
	maxConcurrentRuns?: number;
	licenseExpirationDate?: string;
};

const getMaximumDurationOfUnit = (unit: UnitTypeShort, expirationDate?: string) => {
	if (expirationDate) {
		const maxDuration = dayjs.duration(dayjs(expirationDate).diff(), 'ms');
		return Math.floor(maxDuration.as(unit));
	}
	return undefined;
};

const getDurationHelperText = (unit: UnitTypeShort, t: TFunction, expirationDate?: string) => {
	const maxDurationOfUnit = getMaximumDurationOfUnit(unit, expirationDate);
	return maxDurationOfUnit
		? t('leases.durationHelperText', { count: maxDurationOfUnit, unit: t(`common:duration.timeUnits.${unit}`) })
		: undefined;
};

const getMaxInputValue = (unit: UnitTypeShort, expirationDate?: string) => {
	const maxDurationOfUnit = getMaximumDurationOfUnit(unit, expirationDate);
	return maxDurationOfUnit ?? Number.MAX_SAFE_INTEGER;
};

export const CreateOfflineLeaseModalContent = ({
	maxConcurrentRuns,
	maxSapVus,
	maxWebVus,
	licenseExpirationDate,
}: CreateOfflineLeaseModalContentProps) => {
	const { t } = useTranslation(['workspace']);
	const {
		watch,
		register,
		setValue,
		setError,
		clearErrors,
		trigger,
		formState: { errors },
	} = useFormContext<PostOfflineLease>();

	const concurrentRunsEnabled = maxConcurrentRuns !== undefined;

	const description = watch('description');
	const getHelperText = (value: string | undefined, maxLength: number) =>
		`${value?.length ?? 0}/${maxLength} ${t('common:input.characters')}`;

	const clientIdError = !!errors.clientId?.message;

	const onDurationChanged = useCallback(
		async (inputNumber: number, unit: UnitTypeShort) => {
			const maxDuration = licenseExpirationDate ? dayjs.duration(dayjs(licenseExpirationDate).diff(), 'ms') : undefined;
			/*Cannot use dayjs.duration(inputNumber, unit).toISOString() here :
			Dayjs considers it is possible to have months / years in the duration, which is not the case for java 's Duration
			So any lease of > 1 month would fail*/
			const isoStringValue = unit === 'd' ? `P${inputNumber}D` : `PT${inputNumber}H`;
			setValue('duration', isoStringValue);
			const durationMillis = dayjs.duration(isoStringValue).as('ms');
			if (maxDuration && durationMillis > maxDuration.as('ms')) {
				setError('duration', { message: getDurationHelperText(unit, t, licenseExpirationDate) });
			} else if (Number.isNaN(durationMillis)) {
				setError('duration', { message: t('common:input.required') });
			} else if (durationMillis >= Number.MAX_SAFE_INTEGER) {
				setError('duration', { message: t('leases.maxDuration') });
			} else if (durationMillis <= 0) {
				setError('duration', { message: t('leases.minDuration') });
			} else {
				clearErrors('duration');
				//setErrors triggers form validation but clearErrors does not, we need to explicitely trigger validation
				await trigger('duration');
			}
		},
		[clearErrors, setError, setValue, t, licenseExpirationDate, trigger],
	);

	return (
		<Stack direction='column' gap={2} marginTop={2}>
			<FormControl>
				<TextField
					size='small'
					autoFocus
					label={t('leases.clientId')}
					fullWidth
					required
					error={!!errors.clientId}
					helperText={errors.clientId?.message}
					InputProps={{ inputProps: { maxLength: 140 } }}
					{...register('clientId', {
						required: t('common:input.required'),
					})}
				/>
				<FormHelperText error={clientIdError}>
					{clientIdError ?? (
						<Link href={DOCUMENTATION_LINK} underline='none' target='_blank' alignItems='center'>
							{t('leases.clientIdDocumentation')}
						</Link>
					)}
				</FormHelperText>
			</FormControl>

			<TextField
				rows={2}
				multiline={true}
				label={t('common:description')}
				fullWidth
				helperText={getHelperText(description, 500)}
				InputProps={{ inputProps: { maxLength: 500 } }}
				{...register('description')}
			/>
			<Stack direction='row' gap={2}>
				<Stack gap={2} direction='column' width='50%'>
					<Typography variant='h3'>{t('leases.duration')}</Typography>
					<DurationPicker
						initialValue={1}
						initialUnit='h'
						onChange={onDurationChanged}
						availableUnits={['d', 'h']}
						getHelperText={(unit) => errors.duration?.message ?? getDurationHelperText(unit, t, licenseExpirationDate)}
						error={!!errors.duration}
						getMaxInputValue={(unit) => getMaxInputValue(unit, licenseExpirationDate)}
						getMinInputValue={() => 0}
					/>
				</Stack>
				{concurrentRunsEnabled && (
					<Stack direction='column' width='50%'>
						<Typography variant='h3'>{t('leases.concurrentRuns')}</Typography>
						<TextField
							sx={{ width: '50%' }}
							size='small'
							autoFocus
							label={t('leases.concurrentRuns')}
							fullWidth
							margin='normal'
							type='number'
							required
							error={!!errors.concurrentRuns}
							helperText={
								errors.concurrentRuns?.message ?? t('leases.availableHelperText', { count: maxConcurrentRuns })
							}
							disabled={maxConcurrentRuns === 0}
							InputProps={{
								inputProps: { max: maxConcurrentRuns, min: 1 },
							}}
							{...register('concurrentRuns', {
								valueAsNumber: true,
								required: t('common:input.required'),
								max: {
									value: maxConcurrentRuns,
									message: t('leases.availableHelperText', { count: maxConcurrentRuns }),
								},
								min: { value: 1, message: t('leases.minimumOne') },
							})}
						/>
					</Stack>
				)}
			</Stack>
			<Stack direction='column' width='50%'>
				<Typography variant='h3'>{t('leases.virtualUsers')}</Typography>
				<Stack gap={2} direction='row'>
					<TextField
						type='number'
						size='small'
						autoFocus
						label={t('leases.webVus')}
						fullWidth
						margin='normal'
						required
						{...register('webVus', {
							valueAsNumber: true,
							required: t('common:input.required'),
							max: { value: maxWebVus, message: t('leases.availableHelperText', { count: maxWebVus }) },
							min: { value: 0, message: t('leases.positive') },
						})}
						error={!!errors.webVus}
						helperText={errors.webVus?.message ?? t('leases.availableHelperText', { count: maxWebVus })}
						disabled={maxWebVus === 0}
						InputProps={{
							inputProps: { max: maxWebVus, min: 0 },
						}}
					/>
					<TextField
						type='number'
						size='small'
						autoFocus
						label={t('leases.sapVus')}
						fullWidth
						margin='normal'
						required
						error={!!errors.sapVus}
						helperText={errors.sapVus?.message ?? t('leases.availableHelperText', { count: maxSapVus })}
						disabled={maxSapVus === 0}
						{...register('sapVus', {
							valueAsNumber: true,
							required: t('common:input.required'),
							max: { value: maxSapVus, message: t('leases.availableHelperText', { count: maxSapVus }) },
							min: { value: 0, message: t('leases.positive') },
						})}
						InputProps={{
							inputProps: { max: maxSapVus, min: 0 },
						}}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};

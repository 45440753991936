import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconOpenInNewOutlined from '@tricentis/aura/components/IconOpenInNewOutlined.js';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { ActivationDialog } from './activation-dialog/activation-dialog';
import { DeactivationDialog } from './deactivation-dialog/deactivation-dialog';
import { License, useGetV4LicenseLeasesQuery } from '@neoload/api';

const isLicenseVUsInUse = (license: License) =>
	license.sapVuQuota.available < license.sapVuQuota.total || license.webVuQuota?.available < license.webVuQuota.total;

type LicenseManagementMenuProps = {
	licenseInfos?: License;
	installNewLicense: (file: Blob) => void;
	onInstallLicense: () => void;
};

export const LicenseManagementMenu = ({
	licenseInfos,
	installNewLicense,
	onInstallLicense,
}: LicenseManagementMenuProps) => {
	const { t } = useTranslation(['license']);
	const { t: tExternalLinks } = useTranslation(['externalLinks']);
	const { data: leases } = useGetV4LicenseLeasesQuery({});
	const isLicenseInUse = (leases && leases.total !== 0) || (licenseInfos ? isLicenseVUsInUse(licenseInfos) : false);
	const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

	const onMoreMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget);
	};
	const handleMoreMenuClose = () => {
		setMenuAnchorEl(null);
	};

	return (
		<Stack direction='row' alignItems='center' spacing={1} marginRight={1}>
			{licenseInfos?.licenseStatus === 'NOT_ACTIVATED' && !licenseInfos.automaticallyInstalled && (
				<ActivationDialog onInstall={installNewLicense} />
			)}
			<Tooltip disableHoverListener={!isLicenseInUse} arrow title={t('license.deactivation.inactiveMessage')}>
				<div>
					<Button variant='outlined' onClick={onInstallLicense} size='small' disabled={isLicenseInUse}>
						{t('installLicense')}
					</Button>
				</div>
			</Tooltip>
			<DeactivationDialog
				isActive={licenseInfos?.licenseStatus === 'ACTIVE' && !licenseInfos.automaticallyInstalled}
				isLicenseInUse={isLicenseInUse}
			/>
			<IconButton onClick={onMoreMenuButtonClick}>
				<MoreVertOutlined />
			</IconButton>
			<Menu open={!!menuAnchorEl} anchorEl={menuAnchorEl} onClose={handleMoreMenuClose}>
				<MenuItem
					onClick={() => {
						window.open(tExternalLinks('customerArea'));
						handleMoreMenuClose();
					}}
					sx={{ gap: 1 }}
				>
					<ListItemText color='secondary'>{t('licenseManagement')}</ListItemText>
					<ListItemIcon style={{ minWidth: 0 }}>
						<IconOpenInNewOutlined />
					</ListItemIcon>
				</MenuItem>
			</Menu>
		</Stack>
	);
};

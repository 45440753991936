import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FileSaver from 'file-saver';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useSetSnackbars } from '@neoload/hooks';
import { usePostV4LicenseDeactivationRequestsOverrideMutation } from '@neoload/api';

type DeactivationDialogProps = {
	isActive: boolean;
	isLicenseInUse: boolean;
};

const DeactivationDialog = ({ isActive, isLicenseInUse }: DeactivationDialogProps) => {
	const { t } = useTranslation(['license'], { keyPrefix: 'license.deactivation' });
	const { t: tExternalLinks } = useTranslation(['externalLinks']);
	const [generateRequest] = usePostV4LicenseDeactivationRequestsOverrideMutation();
	const [isOpen, setIsOpen] = useState(false);
	const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
	const { showInfo, showError } = useSetSnackbars();

	const onCancel = () => {
		setIsOpen(false);
	};

	const onCancelConfirmDialog = () => {
		setConfirmDialogIsOpen(false);
	};

	const onDeactivate = () => {
		generateRequest()
			.then(({ data }) => {
				if (!data) {
					throw new Error('No Response Data while generating deactivation request');
				}

				FileSaver.saveAs(data.fileContent, data?.fileName ?? 'deactivation-request.req');
				showInfo({ text: t('generatedMessage') });
				setIsOpen(false);
				setConfirmDialogIsOpen(true);
			})
			.catch(() => showError({ text: t('errorMessage') }));
	};

	return (
		<>
			{isActive && (
				<Tooltip disableHoverListener={!isLicenseInUse} arrow title={t('inactiveMessage')}>
					<div>
						<Button
							color='error'
							variant='outlined'
							onClick={() => setIsOpen(true)}
							disabled={isLicenseInUse}
							sx={{ color: (theme) => theme.palette.error.main }}
							size='small'
						>
							{t('deactivateButton')}
						</Button>
					</div>
				</Tooltip>
			)}
			<Dialog open={isOpen} onClose={onCancel} fullWidth={true}>
				<DialogTitle>
					{' '}
					<Typography variant='h1'>{t('title')}</Typography>
				</DialogTitle>
				<DialogContent>
					<Stack direction='column' spacing={1}>
						<Typography variant='bodyRegular'>{t('firstMessage')}</Typography>
						<Typography variant='bodyRegular'>
							<Trans i18nKey='secondMessage' t={t}>
								A request file <strong>"license-deactivation.req"</strong> will be generated and needs to be sent to
								NeoLoad. This allows installation on another sever if needed.
							</Trans>
						</Typography>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={onCancel} size='small' data-trackingid='license-deactivation-cancel'>
						{t('cancelButton')}
					</Button>
					<Button
						variant='contained'
						color='error'
						size='small'
						onClick={onDeactivate}
						data-trackingid='license-deactivation-ok'
					>
						{t('confirmButton')}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={confirmDialogIsOpen} onClose={onCancelConfirmDialog} fullWidth={true}>
				<DialogTitle marginBottom={2}>{t('finalizeDeactivation.title')}</DialogTitle>
				<DialogContent>
					<Typography variant='bodyRegular'>
						<Trans i18nKey='finalizeDeactivation.message' t={t}>
							Continue in
							{}
							<a href={tExternalLinks('deactivationLicense')} target='_blank' rel='noreferrer'>
								license management
							</a>
							{}
							to finalize the deactivation process, or the license might not be re-installed on a different server.
						</Trans>
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant='contained' onClick={onCancelConfirmDialog} data-trackingid='license-deactivation-finalize'>
						{t('finalizeDeactivation.closeButton')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export { DeactivationDialog };

const menu = {
	home: 'Home',
	tests: 'Tests',
	results: 'Results',
	dashboards: 'Dashboards',
	settings: 'Settings',
	general: 'General',
	users: 'Users',
	zones: 'Zones',
	reservation: 'Reservations',
	workspaces: 'Workspaces',
	infrastructureProviders: 'Infrastructure providers',
	subscription: 'Subscription',
	license: 'License & Leases',
	profile: 'Profile',
	profileDetails: 'Profile details',
	logout: 'Sign out',
	myAccount: 'My account',
	accessTokens: 'Access tokens',
	gitRepositories: 'Git repositories',
	workspaceOverview: 'View all workspaces',
	usageHistory: 'Usage history',
	theme: { switchToDarkTheme: 'Switch to dark theme', switchToLightTheme: 'Switch to light theme' },
	section: {
		account: 'Account',
		resources: 'Resources',
	},
	menu: {
		user: 'User menu',
		account: 'Account settings',
		help: 'Help menu',
		workspaces: 'Select a workspace',
		home: 'Home',
	},
	help: {
		documentation: 'Documentation',
		api: 'API',
		support: 'Support',
		whatsNew: "What's new",
	},
};

export { menu };

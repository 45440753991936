import { overriddenApi } from './override-endpoints';

const TAGS = [
	'ElementPercentiles',
	'ElementTimeseries',
	'Features',
	'Dashboards',
	'DashboardTiles',
	'Interval',
	'Me',
	'MonitorElement',
	'MonitorTimeseries',
	'Preferences',
	'Repositories',
	'ResultTimeseries',
	'Scenarios',
	'Session',
	'TestResult',
	'Tests',
	'Token',
	'UserPathElement',
	'Users',
	'Values',
	'Trends',
	'TrendsConfiguration',
	'Workspaces',
	'WorkspaceMembers',
	'Webhooks',
	'Zones',
	'Leases',
	'InfrastructureProviders',
	'DeletionPolicies',
	'Subscriptions',
	'Settings',
	'Reservations',
	'License',
	'Checkouts',
] as const;

type Tag = (typeof TAGS)[number];

const enhancedApiV4 = overriddenApi.enhanceEndpoints({
	// add your tags here
	addTagTypes: TAGS,
	endpoints: {
		getV4Me: {
			providesTags: (result, _error, _arg) => [{ type: 'Me', id: result?.id }, 'Session'],
		},
		patchV4Me: { invalidatesTags: ['Me'] },
		getV4MePreferences: { providesTags: ['Preferences'] },
		patchV4MePreferences: { invalidatesTags: ['Preferences'] },
		getV4MeTokens: { providesTags: ['Token'] },
		postV4MeTokens: { invalidatesTags: ['Token'] },
		deleteV4MeTokensByToken: { invalidatesTags: ['Token'] },
		getV4UsersByUserIdWorkspaces: {
			providesTags: ['Session'],
		},
		patchV4WorkspacesByWorkspaceId: { invalidatesTags: ['Workspaces', 'WorkspaceMembers', 'Me', 'Subscriptions'] },
		postV4Workspaces: { invalidatesTags: ['Workspaces'] },
		getV4WorkspacesByWorkspaceId: { providesTags: ['Workspaces'] },
		getV4WorkspacesByWorkspaceIdMembers: { providesTags: ['WorkspaceMembers'] },
		postV4Sessions: { invalidatesTags: TAGS },
		deleteV4Sessions: { invalidatesTags: TAGS },
		getV4Results: { providesTags: ['TestResult'] },
		getV4ResultsByResultId: { providesTags: ['TestResult'] },
		patchV4ResultsByResultId: { invalidatesTags: ['TestResult'] },
		deleteV4ResultsByResultId: { invalidatesTags: ['TestResult'] },
		getV4ResultsByResultIdIntervals: { providesTags: ['Interval'] },
		postV4ResultsByResultIdIntervals: { invalidatesTags: ['Interval'] },
		deleteV4ResultsByResultIdIntervalsAndIntervalId: { invalidatesTags: ['Interval'] },
		patchV4ResultsByResultIdIntervalsAndIntervalId: { invalidatesTags: ['Interval'] },
		postV4ResultsByResultIdIntervalGeneration: { invalidatesTags: ['Interval'] },
		getV4Tests: { providesTags: ['Tests'] },
		getV4WorkspacesByWorkspaceIdSubscription: { providesTags: ['Subscriptions'] },
		getV4LicenseLeases: {
			providesTags: ['Leases'],
		},
		postV4LicenseLeases: {
			invalidatesTags: ['Leases', 'Subscriptions'],
		},
		postV4LicenseReleases: {
			invalidatesTags: ['Leases', 'Subscriptions'],
		},
		postV4TestExecutions: { invalidatesTags: (_, error) => (error ? [] : ['TestResult', 'Subscriptions']) },
		postV4Tests: { invalidatesTags: (_, error) => (error ? [] : ['Tests']) },
		deleteV4TestsByTestId: { invalidatesTags: ['Tests'] },
		patchV4TestsByTestId: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'Tests',
					id: arg.testId,
				},
				'Tests',
			],
		},
		getV4TestsByTestId: { providesTags: (_result, _error, arg) => [{ type: 'Tests', id: arg.testId }] },
		getV4Dashboards: { providesTags: ['Dashboards'] },
		postV4Dashboards: { invalidatesTags: ['Dashboards'] },
		getV4DashboardsByDashboardId: {
			providesTags: (_result, _error, arg) => [{ type: 'Dashboards', id: arg.dashboardId }],
		},
		patchV4DashboardsByDashboardId: { invalidatesTags: ['Dashboards'] },
		deleteV4DashboardsByDashboardId: { invalidatesTags: ['Dashboards'] },
		postV4DashboardsByDashboardIdDuplication: { invalidatesTags: ['Dashboards'] },
		getV4DashboardsByDashboardIdTiles: {
			providesTags: (_result, _error, arg) => [
				{ type: 'DashboardTiles', id: getDashboardTilesByDashboardIdKey(arg.dashboardId) },
			],
		},
		deleteV4DashboardsByDashboardIdTilesAndDashboardTileId: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'DashboardTiles',
					id: getDashboardTilesByDashboardIdKey(arg.dashboardId),
				},
			],
		},
		patchV4DashboardsByDashboardIdTiles: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'DashboardTiles',
					id: getDashboardTilesByDashboardIdKey(arg.dashboardId),
				},
			],
		},
		deleteV4DashboardsByDashboardIdTiles: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'DashboardTiles',
					id: getDashboardTilesByDashboardIdKey(arg.dashboardId),
				},
			],
		},
		postV4ResultsByResultIdReport: {
			invalidatesTags: ['Dashboards'],
		},
		patchV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesId: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'DashboardTiles',
					id: getDashboardTilesByDashboardIdKey(arg.dashboardId),
				},
			],
		},
		getV4ResultsByResultIdElementsValues: { providesTags: ['Values'] },
		getV4Users: { providesTags: ['Users'] },
		postV4Users: { invalidatesTags: ['Users'] },
		patchV4UsersByUserId: { invalidatesTags: ['Users'] },
		deleteV4UsersByUserId: { invalidatesTags: ['Users'] },
		postV4Checkouts: { invalidatesTags: ['Checkouts'] },
		putV4UsersByUserIdWorkspaces: {
			invalidatesTags: (_result, _error, arg) => [
				'Users',
				{ type: 'Me', id: arg.userId },
				'WorkspaceMembers',
				'Workspaces',
			],
		},
		deleteV4UsersByUserIdWorkspacesAndWorkspaceId: {
			invalidatesTags: (_result, _error, arg) => [
				'Users',
				{ type: 'Me', id: arg.userId },
				'WorkspaceMembers',
				'Workspaces',
			],
		},
		getV4ResultsByResultIdTimeseries: {
			providesTags: (result, _error, arg) => {
				if (arg.fixedWindowDuration || arg.requestToken || result?.nextRequestToken) {
					return []; // Do not cache partial series yet
				}
				return arg.series.map((serie) => ({
					type: 'ResultTimeseries',
					id: getResultTimeseriesKey(arg.resultId, serie, arg.intervalId),
				}));
			},
		},
		getV4ResultsByResultIdElementsAndElementIdTimeseries: {
			providesTags: (result, _error, arg) => {
				if (arg.requestToken || result?.nextRequestToken) {
					return []; // Do not cache partial series yet
				}
				return arg.statistics.map((statistic) => ({
					type: 'ElementTimeseries',
					id: getElementTimeseriesKey(arg.resultId, arg.elementId, statistic, arg.userPathId, arg.intervalId),
				}));
			},
		},
		getV4ResultsByResultIdMonitorsAndMonitorIdTimeseries: {
			providesTags: (result, _error, arg) => {
				if (arg.requestToken || result?.nextRequestToken) {
					return []; // Do not cache partial series yet
				}
				return [
					{
						type: 'MonitorTimeseries',
						id: getMonitorTimeseriesKey(arg.resultId, arg.monitorId, arg.intervalId),
					},
				];
			},
		},
		getV4ResultsByResultIdElements: {
			providesTags: ['UserPathElement'],
		},
		getV4ResultsByResultIdMonitors: {
			providesTags: ['MonitorElement'],
		},
		getV4ResultsByResultIdElementsAndElementIdPercentiles: {
			providesTags: (_result, _error, arg) => [
				{
					type: 'ElementPercentiles',
					id: getElementPercentilesKey(arg.resultId, arg.elementId, arg.userPathId, arg.intervalId),
				},
			],
		},
		patchV4DashboardsByDashboardIdTilesAndDashboardTileId: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'DashboardTiles',
					id: getDashboardTilesByDashboardIdKey(arg.dashboardId),
				},
			],
		},
		postV4DashboardsByDashboardIdTilesAndTileIdSeries: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'DashboardTiles',
					id: getDashboardTilesByDashboardIdKey(arg.dashboardId),
				},
			],
		},
		getV4MeFeatures: { providesTags: ['Features'] },
		getV4TestsByTestIdTrends: {
			providesTags: (_result, _error, arg) => [
				{
					type: 'Trends',
					id: arg.testId,
				},
			],
		},
		getV4TestsByTestIdTrendsConfiguration: {
			providesTags: (_result, _error, arg) => [
				{
					type: 'TrendsConfiguration',
					id: arg.testId,
				},
			],
		},
		patchV4TestsByTestIdTrendsConfiguration: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'Trends',
					id: arg.testId,
				},
				{
					type: 'TrendsConfiguration',
					id: arg.testId,
				},
			],
		},
		putV4TestsByTestIdTrendsConfiguration: {
			invalidatesTags: (_result, _error, arg) => {
				if (arg.dryRun === true) {
					return [];
				}
				return [
					{
						type: 'Trends',
						id: arg.testId,
					},
					{
						type: 'TrendsConfiguration',
						id: arg.testId,
					},
				];
			},
		},
		getV4TestsByTestIdScenariosAndScenarioName: {
			providesTags: ['Scenarios'],
		},
		putV4TestsByTestIdScenariosAndScenarioName: {
			invalidatesTags: ['Scenarios'],
		},
		postV4DashboardsByDashboardIdPublicToken: {
			invalidatesTags: ['Dashboards'],
		},
		deleteV4DashboardsByDashboardIdPublicToken: {
			invalidatesTags: ['Dashboards'],
		},
		getV4Workspaces: { providesTags: ['Workspaces'] },
		deleteV4WorkspacesByWorkspaceId: { invalidatesTags: ['Workspaces'] },
		getV4ScmRepositories: {
			providesTags: ['Repositories'],
		},
		postV4ScmRepositories: {
			invalidatesTags: ['Repositories'],
		},
		patchV4ScmRepositoriesByRepositoryId: {
			invalidatesTags: ['Repositories'],
		},
		deleteV4ScmRepositoriesByRepositoryId: {
			invalidatesTags: ['Repositories'],
		},
		getV4Zones: {
			providesTags: ['Zones'],
		},
		getV4ZonesByZoneId: {
			providesTags: ['Zones'],
		},
		postV4Zones: {
			invalidatesTags: ['Zones'],
		},
		putV4ZonesByZoneId: {
			invalidatesTags: ['Zones'],
		},
		deleteV4ZonesByZoneId: {
			invalidatesTags: ['Zones'],
		},
		getV4InfrastructureProviders: {
			providesTags: ['InfrastructureProviders'],
		},
		deleteV4InfrastructureProvidersById: {
			invalidatesTags: ['InfrastructureProviders'],
		},
		postV4InfrastructureProviders: {
			invalidatesTags: ['InfrastructureProviders'],
		},
		patchV4InfrastructureProvidersById: {
			invalidatesTags: ['InfrastructureProviders'],
		},
		postV4TestsByTestIdProjectPassword: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'Tests',
					id: arg.testId,
				},
			],
		},
		getV4Webhooks: {
			providesTags: ['Webhooks'],
		},
		postV4Webhooks: {
			invalidatesTags: ['Webhooks'],
		},
		patchV4WebhooksByWebhookId: {
			invalidatesTags: ['Webhooks'],
		},
		deleteV4WebhooksByWebhookId: { invalidatesTags: ['Webhooks'] },
		getV4DeletionPolicies: {
			providesTags: ['DeletionPolicies'],
		},
		postV4DeletionPolicies: {
			invalidatesTags: ['DeletionPolicies'],
		},
		patchV4DeletionPoliciesByDeletionPolicyId: {
			invalidatesTags: ['DeletionPolicies'],
		},
		deleteV4DeletionPoliciesByDeletionPolicyId: {
			invalidatesTags: ['DeletionPolicies'],
		},
		getV4Settings: {
			providesTags: ['Settings'],
		},
		patchV4Settings: {
			invalidatesTags: ['Settings'],
		},
		deleteV4ReservationsByReservationId: {
			invalidatesTags: ['Reservations'],
		},
		getV4Reservations: {
			providesTags: ['Reservations'],
		},
		postV4Reservations: {
			invalidatesTags: ['Reservations'],
		},
		patchV4ReservationsByReservationId: {
			invalidatesTags: ['Reservations'],
		},
		postV4LicenseForcedReleases: {
			invalidatesTags: ['Leases', 'License'],
		},
		getV4License: {
			providesTags: ['License'],
		},
		postV4License: {
			invalidatesTags: ['License'],
		},
		postV4LicenseDeactivationRequestsOverride: {
			invalidatesTags: ['License'],
		},
		patchV4ZonesByZoneId: {
			invalidatesTags: (_result, _error, args) => {
				if (args.dryRun === true) {
					return [];
				} else if (args.patchZoneFields.type === 'CLOUD') {
					return ['Zones', 'Subscriptions'];
				}
				return ['Zones'];
			},
		},
		patchV4Zones: {
			invalidatesTags: (_result, _error, args) => {
				if (args.dryRun === true) {
					return [];
				}
				const tags: Tag[] = ['Zones'];
				if (args.patchAllZonesFields.type === 'CLOUD') {
					tags.push('Subscriptions');
				}
				return tags;
			},
		},
	},
});

export const {
	useLazyGetV4LeasesByLeaseIdentifierFileQuery,
	useLazyGetV4ResultsByResultIdElementsAndElementIdTimeseriesQuery,
	useLazyGetV4ResultsByResultIdMonitorsAndMonitorIdTimeseriesQuery,
	useLazyGetV4ResultsByResultIdQuery,
	useLazyGetV4ResultsByResultIdStatisticsQuery,
	useLazyGetV4ResultsByResultIdElementsAndElementIdPercentilesQuery,
	useLazyGetV4ResultsByResultIdElementsValuesQuery,
	useLazyGetV4ResultsByResultIdEventsErrorsQuery,
	useLazyGetV4ResultsByResultIdSlasQuery,
	useLazyGetV4ResultsByResultIdElementsQuery,
	useLazyGetV4ResultsByResultIdMonitorsQuery,
	useLazyGetV4TestsByTestIdTrendsConfigurationQuery,
	useLazyGetV4ScmRepositoriesByRepositoryIdReferencesQuery,
	useLazyGetV4ResultsByResultIdMonitorsValuesQuery,
	useLazyGetV4TestsByTestIdProjectQuery,
	useLazyGetV4DashboardsByDashboardIdPdfQuery,
	useLazyGetV4WorkspacesByWorkspaceIdSubscriptionQuery,
	useLazyGetV4TestsQuery,
	useLazyGetV4TestsByTestIdQuery,
	usePostV4LicenseActivationRequestsOverrideMutation,
	usePostV4LicenseDeactivationRequestsOverrideMutation,
} = enhancedApiV4;

const getDashboardTilesByDashboardIdKey = (dashboardId: string) => `TILES-OF-DASHBOARD-${dashboardId}`;

const getResultTimeseriesKey = (resultId: string, serie: string, intervalId?: string) =>
	JSON.stringify([resultId, serie, intervalId]);

const getElementTimeseriesKey = (
	resultId: string,
	elementId: string,
	statistic: string,
	userPathId?: string,
	intervalId?: string,
) => JSON.stringify([resultId, elementId, statistic, userPathId, intervalId]);

const getMonitorTimeseriesKey = (resultId: string, monitorId: string, intervalId?: string) =>
	JSON.stringify([resultId, monitorId, intervalId]);

const getElementPercentilesKey = (resultId: string, elementId: string, userPathId?: string, intervalId?: string) =>
	JSON.stringify([resultId, elementId, userPathId, intervalId]);

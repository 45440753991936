import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconCheckCircleOutlined from '@tricentis/aura/components/IconCheckCircleOutlined.js';
import IconErrorOutlined from '@tricentis/aura/components/IconErrorOutlined.js';
import IconBlockOutlined from '@tricentis/aura/components/IconBlockOutlined.js';
import Chip, { ChipPropsColorOverrides } from '@mui/material/Chip';
import { TFunction } from 'i18next';
import { OverridableStringUnion } from '@mui/types';
import IconWarningOutlined from '@tricentis/aura/components/IconWarningOutlined.js';
import Grid from '@mui/material/Grid';
import { LabelsInfoLicense } from './labels-info-license';
import { ValuesInfoLicense } from './values-info-license';
import { License } from '@neoload/api';
import { timeUtils } from '@neoload/utils';

const chipLicenseStatus = (t: TFunction, status: string | undefined) => {
	const statusMap: Record<
		string,
		{
			label: string;
			color: OverridableStringUnion<
				'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
				ChipPropsColorOverrides
			>;
			icon: ReactElement;
		}
	> = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		ACTIVE: { label: t('status.active'), color: 'success', icon: <IconCheckCircleOutlined /> },
		// eslint-disable-next-line @typescript-eslint/naming-convention
		EXPIRED: { label: t('status.expired'), color: 'error', icon: <IconErrorOutlined /> },
		// eslint-disable-next-line @typescript-eslint/naming-convention
		NOT_ACTIVATED: { label: t('status.notActivated'), color: 'secondary', icon: <IconBlockOutlined /> },
		// eslint-disable-next-line @typescript-eslint/naming-convention
		DEACTIVATED: { label: t('status.deactivated'), color: 'error', icon: <IconBlockOutlined /> },
		// eslint-disable-next-line @typescript-eslint/naming-convention
		NO_LICENSE: { label: t('status.noLicense'), color: 'secondary', icon: <IconBlockOutlined /> },
	};
	const { label, color, icon } = statusMap[status ?? 'NO_LICENSE'];
	return <Chip data-testid='status-license' variant='filled' size='small' color={color} icon={icon} label={label} />;
};

const ExpirationDate = ({ licenseInfos }: { licenseInfos: License }) => (
	<Stack direction='row' spacing={1}>
		{licenseInfos.expirationDate && (
			<>
				{licenseInfos.licenseStatus === 'EXPIRED' && <IconErrorOutlined color='error' />}
				{timeUtils.isDateArriveInNextMonth(licenseInfos.expirationDate) && <IconWarningOutlined color='warning' />}
				<Typography variant='body2' color={licenseInfos.licenseStatus === 'EXPIRED' ? 'error' : 'inherit'}>
					{timeUtils.dayFormat(licenseInfos.expirationDate)}
				</Typography>
			</>
		)}
	</Stack>
);

type SubscriptionLicenseProps = {
	licenseInfos: License;
};

const LicenseDetails = ({ licenseInfos }: SubscriptionLicenseProps) => {
	const { t } = useTranslation(['license'], { keyPrefix: 'license' });

	return (
		<Stack p={2} spacing={3}>
			<Stack direction='row' alignItems='center' spacing={2}>
				<Typography variant='subtitle1'>{t('title')}</Typography>
				{chipLicenseStatus(t, licenseInfos?.licenseStatus)}
			</Stack>
			<Grid container columnGap={8} rowGap={1}>
				<Stack direction='row' gap={1}>
					<LabelsInfoLicense labels={['expirationDate', 'edition', 'version']} />
					<ValuesInfoLicense
						values={[
							<ExpirationDate licenseInfos={licenseInfos} key='expirationDate' />,
							licenseInfos.edition,
							licenseInfos.version,
						]}
					/>
				</Stack>
				<Stack direction='row' gap={1}>
					<LabelsInfoLicense labels={['webVuQuota', 'sapVuQuota', 'concurrentRuns']} />
					<ValuesInfoLicense values={[licenseInfos.webVuQuota.total, licenseInfos.sapVuQuota.total, licenseInfos.concurrentRuns]} />
				</Stack>
				<Stack direction='row' gap={1}>
					<LabelsInfoLicense labels={['name', 'company', 'address']} />
					<ValuesInfoLicense values={[licenseInfos.name, licenseInfos.company.trim(), licenseInfos.address.trim()]} />
				</Stack>
			</Grid>
		</Stack>
	);
};

export { LicenseDetails };

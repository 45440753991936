const workspaceSubscription = {
	forceRelease: 'Force release',
	forceReleaseModal: {
		clipboardSuccess: 'Lease identifier copied to clipboard.',
		leaseId: {
			info: 'For early release due to a major issue, contact NeoLoad Support with the following identifier.',
			label: 'Lease identifier',
			required: 'Lease identifier is required',
			invalid: 'Invalid lease identifier',
		},
		supportKey: {
			info: 'Enter the key sent by NeoLoad support.',
			label: 'Key',
			required: 'Support key is required',
		},
		delete: {
			success_one: 'Lease forced release successful',
			error_one: 'Failed to force release lease',
			error400_one: 'Invalid support response',
		},
	},
};
export { workspaceSubscription };

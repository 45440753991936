import Chip from '@mui/material/Chip';
import LockOutlined from '@mui/icons-material/LockOutlined';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useWatch } from 'react-hook-form';
import Tooltip from '@mui/material/Tooltip';
import { ConfigurationFormData } from '../../types';

type ChipProjectProtectedProps = {
	openModal: () => void;
};

const ChipProjectProtected = ({ openModal }: ChipProjectProtectedProps) => {
	const { t } = useTranslation(['test']);
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
	const securityStatus = useWatch<ConfigurationFormData, 'project.securityStatus'>({ name: 'project.securityStatus' });

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'pop-over-protected' : undefined;

	return (
		<>
			<Tooltip title={t('configuration.project.protectedDescription')} arrow>
				<Chip
					variant='outlined'
					label={t('configuration.project.protected')}
					icon={<LockOutlined fontSize='small' />}
					clickable={true}
					onClick={handleClick}
					size='small'
				/>
			</Tooltip>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				disableRestoreFocus
			>
				<Stack sx={{ padding: 2, width: '340px', gap: 2 }}>
					<Typography variant='body2'>{t('configuration.project.protectedDescription')}</Typography>
					{securityStatus === 'INVALID_PASSWORD' && (
						<Button
							variant='outlined'
							onClick={() => {
								openModal();
								handleClose();
							}}
						>
							{t('configuration.project.enterPassword')}
						</Button>
					)}
				</Stack>
			</Popover>
		</>
	);
};

export { ChipProjectProtected };

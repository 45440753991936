import ZeroState from '@tricentis/aura/components/ZeroState.js';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import { Key } from 'react';
import Stack from '@mui/material/Stack';
import TestCases from '../../../assets/TestCases.svg';
import { IllustrationWithBackground } from '../../common/illustration-with-background';
import { CommonRoutes } from '@neoload/utils';
import { useGetMe } from '@neoload/hooks';

type EmptyStateTestsProps = {
	openCreateModal: () => void;
};
type ButtonPropsWithKey = ButtonProps & { key: Key };

const EmptyStateTests = ({ openCreateModal }: EmptyStateTestsProps) => {
	const { t } = useTranslation(['test']);
	const [{ isGuest }] = useGetMe();
	const navigate = useNavigate();

	const actionsGuest: ButtonPropsWithKey[] = [
		{
			key: 'others',
			variant: 'contained',
			children: (
				<>
					<ArrowBackOutlined sx={{ marginRight: 1 }} />
					{t('zeroGuest.button')}
				</>
			),
			sx: { textTransform: 'none' },
			onClick: () => void navigate(CommonRoutes.home.base),
		},
	];

	const actionsOther: ButtonPropsWithKey[] = [
		{
			key: 'guest',
			variant: 'contained',
			children: (
				<>
					<AddIcon sx={{ marginRight: 1 }} />
					{t('zero.button')}
				</>
			),
			sx: { textTransform: 'none' },
			onClick: openCreateModal,
		},
	];

	const actions = isGuest ? actionsGuest : actionsOther;
	const title = isGuest ? t('zeroGuest.title') : t('zero.title');

	return (
		<Stack justifyContent='center' height='100%'>
			<ZeroState
				illustration={<IllustrationWithBackground image={TestCases} title={title} />}
				title={title}
				actions={actions.map(({ children, key, ...others }) => (
					<Button key={key} {...others}>
						{children}
					</Button>
				))}
				containerSx={{ backgroundColor: 'transparent' }}
			>
				<Typography variant='body2'>{t(`${isGuest ? 'zeroGuest' : 'zero'}.contentLine1`)}</Typography>
				<Typography variant='body2'>{t(`${isGuest ? 'zeroGuest' : 'zero'}.contentLine2`)}</Typography>
			</ZeroState>
		</Stack>
	);
};

export { EmptyStateTests };

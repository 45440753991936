const zone = {
	title: 'Zones',
	createZone: 'Create zone',
	allocateIps: 'Allocate IPs',
	createDynamicZone: 'Create dynamic zone',
	editDynamicZone: 'Edit dynamic zone',
	dynamicZoneDescription:
		'Dynamic zone allows defining a set of Controller Agents and Load Generator Agents that will be used to run a test from NeoLoad Web.',
	tabs: {
		static: 'Static ',
		dynamic: 'Dynamic',
		cloud: 'NeoLoad Cloud',
	},
	update: {
		success_one: 'Zone {{name}} successfully updated',
		error_one: 'Failed to update zone.',
		header: 'Update {{type}} zone',
	},
	create: {
		header: 'Add {{type}} zone',
		success_one: 'Zone {{name}} successfully created',
		error_one: 'Failed to create zone.',
	},
	dynamicEdition: {
		nameRequired: 'Name is required',
		nameTooLong: 'Name is too long',
		getZoneFailed: 'Failed to retrieve zone',
		dockerImages: 'Docker images',
		dockerImagesTooltip: 'Enter Docker images to select specific versions of the Controller or Load Generator.',
		loadGenerator: 'Load generator',
		pullSecret: 'Image pull secret',
		secretHelperText: 'Fill in if your images are on a private registry',
		controllerDockerImage: 'Controller image',
		lgDockerImage: 'Load generator image',
		imagesPatternError: 'Images must not start with https:// or http://',
		controllerImagePlaceHolder: 'neotys/neoload-controller:latest',
		lgImagePlaceHolder: 'neotys/neoload-loadgenerator:latest',
		provider: 'Provider',
		providerLoading: 'Loading providers...',
		providerLoadingError: 'Error loading providers',
		noProvidersError: 'No providers available',
		resetToDefault: 'Reset to default',
	},
	sizes: {
		/* eslint-disable @typescript-eslint/naming-convention*/
		SMALL: 'Small',
		MEDIUM: 'Medium',
		LARGE: 'Large',
		CUSTOM: 'Custom',
		MEMORY: 'Memory',
		CPU: 'CPU cores',
		/* eslint-enable @typescript-eslint/naming-convention*/
		controller: 'Controller',
		mb: 'MB',
		cores: 'cores',
		lg: 'LG',
		sizing: 'Resource sizing',
		loadGenerator: 'Load generator',
		integerError: 'Value must be an integer',
		floatError: 'Value must be an float',
	},
	emptyState: 'Create your first dynamic zone',
	datagridTitles: {
		/* eslint-disable @typescript-eslint/naming-convention*/
		STATIC: 'Static zones',
		DYNAMIC: 'Dynamic zones',
		CLOUD: 'NeoLoad Cloud zones',
		/* eslint-enable @typescript-eslint/naming-convention*/
	},
	columns: {
		name: 'Name',
		lgs: 'Load generators',
		controllers: 'Controllers',
		zoneId: 'Zone ID',
		cloudProvider: 'Provider',
		status: 'Status',
		viewDetails: 'View details',
		providerName: 'Provider',
		reservedIpsCount: 'Dedicated IPs',
		totalIps: 'Total IPs',
		unavailableReservedIpsCount: {
			value: 'N/A',
			message: 'This is unavailable because the dedicated IPs data could not be retrieved.',
		},
	},
	actions: {
		copyZoneIdToClipboard: 'Copy zone ID to clipboard',
		copyIpAddressesToClipboard: 'Copy IP addresses to clipboard',
		copyIpAddressesToClipboardSuccess: 'IP addresses successfully copied to clipboard',
		copyAllIpAddressesToClipboardSuccess: 'All IP addresses successfully copied to clipboard',
	},
	details: {
		title: 'Zone details',
		noResource: '—',
		resourceName: '{{name}} ({{version}}): ',
		resourceStatus: {
			/* eslint-disable @typescript-eslint/naming-convention */
			AVAILABLE: 'Available',
			RESERVED: 'Reserved',
			BUSY: 'Busy',
			/* eslint-enable @typescript-eslint/naming-convention */
		},
		clipboardSuccess: 'Zone ID copied to clipboard.',
		fetchFailed: 'Failed to fetch zone details.',
	},
	delete: {
		header: 'Delete {{type}} zone?',
		text: "Deleting the zone will permanently erase it from your data. It's not reversible, and you won't be able to retrieve it once removed.",
		busy: 'The Zone cannot be deleted because it contains resources',
		success_one: 'Zone {{name}} successfully deleted',
		error_one: 'Failed to delete zone.',
	},
	ncpErrorMessage: 'Error encountered while fetching data from the NeoLoad Cloud Platform',
	cloudZone: {
		allocate: 'Allocate IPs',
		allocatingIps: 'Allocating IPs...',
		releasingIps: 'Releasing IPs...',
		statusLoading: 'Loading...',
		available: 'Enabled',
		unAvailable: 'Disabled',
		enable: 'Enable cloud zone',
		disable: 'Disable cloud zone',
		createSuccessful: 'Cloud zone enabled',
		createFailed: 'Fail to enable cloud zone',
		deleteSuccessful: 'Cloud zone disabled',
		deleteFailed: 'Fail to disable cloud zone',
		deleteForbidden: 'Cannot disable cloud zone because there are connected resources',
		testerSwitchTooltip: 'Testers cannot enable or disable cloud zones',
		cloudCreditsUnavailable: 'Cloud credits are unavailable',
		cloudZonesUnavailable: 'Cloud zones are unavailable',
		disabledManageIpsMessage:
			'You can operate with the IPs once you have at least one cloud zone availaible or the allocation process is finished.',
		copyAllIps: 'Copy all IPs',
		releaseAllDedicatedIps: 'Release all IPs',
		dedicatedIps: {
			allocated: 'Dedicated IPs addresses allocated',
			released: 'Dedicated IPs released',
			allocateFailed: 'An error occurred while allocating dedicated IPs',
			releaseFailed: 'An error occurred while releasing dedicated IPs',
			allocateForbidden: 'This zone has dedicated IPs. Please release the IPs before allocation',
			copied: '{{IpAddresses}} Ip(s) copied to the clipboard',
			costPerDayPerIp: '{{costInCloudCreditsPerDayPerIp}} Cloud Credits per day per IP',
			editIps: 'Edit IPs',
			copyIps: 'Copy IPs',
			allocationDialog: {
				title: 'Allocate dedicated IPs',
				zone: 'Zone',
				loadGeneratorUsage: '{{lgCount}} load generator(s) across all tests and workspaces are using this zone',
				ipCount: 'Ip count',
				cloudCreditsModificationCost: 'Cloud credits modification cost: {{cost}} ({{cloudCredits}} available)',
				cloudCreditsError: 'Unable to determine the cost in cloud credits for this operation.',
				cloudCreditsText: 'Cloud credits modification cost : ',
				cloudCreditsAvailable: '({{cloudCredits}} available)',
				allocate: 'Allocate',
				release: 'Release ({{count}})',
				cancel: 'Cancel',
				fetching: 'Fetching... ',
				expirationDate: 'will be released on {{date}}',
				zoneSelectionError: 'A zone must be selected',
				confirmRelease: {
					title: 'Release dedicated IPs ?',
					text: 'If you release dedicated IPs, your firewall can block controllers or load generators so your test results might be incomplete.',
					confirm: 'Confirm',
				},
			},
			releaseDateInfo: {
				label: 'IPs release date',
				totalCostTooltip:
					'Total cost : {{totalCostInCloudCredits}} Cloud Credits ({{costInCloudCreditsPerDayPerIp}} per day per IP)',
				totalCostTooltipWithDescription:
					'IPs release date. Total cost : {{totalCostInCloudCredits}} Cloud Credits ({{costInCloudCreditsPerDayPerIp}} per day per IP)',
				editTooltip: 'Edit the release date for all dedicated IPs',
			},
			releaseDateDialog: {
				title: 'Edit Dedicated IPs release date',
				releaseDateField: {
					label: 'IPs release date',
					invalidError: 'The release date must be set in the future.',
				},
				patchSuccess: 'Dedicated IPs release date updated',
				patchFailure: 'An error occurred while updating the Dedicated IPs release date',
			},
			releaseModal: {
				title: 'Release all dedicated IPs ?',
				description:
					'If you release all dedicated IPs across all zones at once, your firewall can block controllers or load generators so your test results might be incomplete.',
				release: 'Release',
				cancel: 'Cancel',
				success: 'All dedicated IPs released',
				error: 'An error occurred while releasing dedicated IPs',
			},
		},
		zones: {
			/* eslint-disable @typescript-eslint/naming-convention */
			AWS_AF_SOUTH_1: { name: 'Africa South Africa (Cape Town)', provider: 'Amazon Web Services' },
			AWS_AP_EAST_1: { name: 'Asia-Pacific China (Hong Kong)', provider: 'Amazon Web Services' },
			AWS_AP_NORTHEAST_1: { name: 'Asia-Pacific Japan (Tokyo)', provider: 'Amazon Web Services' },
			AWS_AP_NORTHEAST_2: { name: 'Asia-Pacific South Korea (Seoul)', provider: 'Amazon Web Services' },
			AWS_AP_NORTHEAST_3: { name: 'Asia-Pacific Japan (Osaka)', provider: 'Amazon Web Services' },
			AWS_AP_SOUTH_1: { name: 'Asia-Pacific India (Mumbai)', provider: 'Amazon Web Services' },
			AWS_AP_SOUTH_2: { name: 'Asia-Pacific India (Hyderabad)', provider: 'Amazon Web Services' },
			AWS_AP_SOUTHEAST_1: { name: 'Asia-Pacific Singapore', provider: 'Amazon Web Services' },
			AWS_AP_SOUTHEAST_2: { name: 'Asia-Pacific Australia (Sydney)', provider: 'Amazon Web Services' },
			AWS_AP_SOUTHEAST_3: { name: 'Asia-Pacific Indonesia (Jakarta)', provider: 'Amazon Web Services' },
			AWS_AP_SOUTHEAST_4: { name: 'Asia-Pacific Australia (Melbourne)', provider: 'Amazon Web Services' },
			AWS_AP_SOUTHEAST_5: { name: 'Asia-Pacific Malaysia (Kuala Lumpur)', provider: 'Amazon Web Services' },
			AWS_CA_CENTRAL_1: { name: 'North-America Canada (Montreal)', provider: 'Amazon Web Services' },
			AWS_CA_WEST_1: { name: 'North-America Canada (Calgary)', provider: 'Amazon Web Services' },
			AWS_EU_CENTRAL_1: { name: 'Europe Germany (Frankfurt)', provider: 'Amazon Web Services' },
			AWS_EU_CENTRAL_2: { name: 'Europe Switzerland (Zurich)', provider: 'Amazon Web Services' },
			AWS_EU_NORTH_1: { name: 'Europe Sweden (Stockholm)', provider: 'Amazon Web Services' },
			AWS_EU_SOUTH_1: { name: 'Europe Italy (Milan)', provider: 'Amazon Web Services' },
			AWS_EU_SOUTH_2: { name: 'Europe Spain (Aragon)', provider: 'Amazon Web Services' },
			AWS_EU_WEST_1: { name: 'Europe Ireland (Dublin)', provider: 'Amazon Web Services' },
			AWS_EU_WEST_2: { name: 'Europe United Kingdom (London)', provider: 'Amazon Web Services' },
			AWS_EU_WEST_3: { name: 'Europe France (Paris)', provider: 'Amazon Web Services' },
			AWS_IL_CENTRAL_1: { name: 'Middle-East Israel (Tel Aviv)', provider: 'Amazon Web Services' },
			AWS_ME_CENTRAL_1: { name: 'Middle-East United Arab Emirates (Dubai)', provider: 'Amazon Web Services' },
			AWS_ME_SOUTH_1: { name: 'Middle-East Bahrain (Manama)', provider: 'Amazon Web Services' },
			AWS_SA_EAST_1: { name: 'South-America Brazil (Sao Paulo)', provider: 'Amazon Web Services' },
			AWS_US_EAST_1: { name: 'USA East - Virginia (Ashburn)', provider: 'Amazon Web Services' },
			AWS_US_EAST_2: { name: 'USA East - Ohio (Columbus)', provider: 'Amazon Web Services' },
			AWS_US_WEST_1: { name: 'USA East - California (San Jose)', provider: 'Amazon Web Services' },
			AWS_US_WEST_2: { name: 'USA East - Oregon (Portland)', provider: 'Amazon Web Services' },

			GCP_AFRICA_SOUTH1: { name: 'Africa South Africa (Johannesburg)', provider: 'Google Cloud Platform' },
			GCP_EUROPE_WEST10: { name: 'Europe Germany (Berlin)', provider: 'Google Cloud Platform' },
			GCP_EUROPE_WEST12: { name: 'Europe Italy (Turin)', provider: 'Google Cloud Platform' },
			GCP_ME_CENTRAL1: { name: 'Middle-East Qatar (Doha)', provider: 'Google Cloud Platform' },
			GCP_ME_CENTRAL2: { name: 'Middle-East Saudi Arabia (Dammam)', provider: 'Google Cloud Platform' },
			GCP_ME_WEST1: { name: 'Middle-East Israel (Tel Aviv)', provider: 'Google Cloud Platform' },
			GCP_US_EAST5: { name: 'USA East - Ohio (Columbus)', provider: 'Google Cloud Platform' },
			GCP_US_SOUTH1: { name: 'USA Central - Texas (Dallas)', provider: 'Google Cloud Platform' },
			AP_EAST_4: { name: 'Asia-Pacific China (Hong Kong)', provider: 'Google Cloud Platform' },
			AP_EAST_3: { name: 'Asia-Pacific Taiwan (Changhua County)', provider: 'Google Cloud Platform' },
			AP_NORTHEAST_7: { name: 'Asia-Pacific Japan (Tokyo)', provider: 'Google Cloud Platform' },
			AP_SOUTH_5: { name: 'Asia-Pacific India (Mumbai)', provider: 'Google Cloud Platform' },
			AP_SOUTH_6: { name: 'Asia-Pacific India (Delhi)', provider: 'Google Cloud Platform' },
			AP_SOUTHEAST_8: { name: 'Asia-Pacific Singapore', provider: 'Google Cloud Platform' },
			AP_SOUTHEAST_9: { name: 'Asia-Pacific Australia (Sydney)', provider: 'Google Cloud Platform' },
			AP_SOUTHWEST_11: { name: 'Asia-Pacific Australia (Melbourne)', provider: 'Google Cloud Platform' },
			EU_CENTER_4: { name: 'Europe Germany (Frankfurt)', provider: 'Google Cloud Platform' },
			EU_NORTH_2: { name: 'Europe Finland (Hamina)', provider: 'Google Cloud Platform' },
			EU_WEST_7: { name: 'Europe Belgium (St. Ghislain)', provider: 'Google Cloud Platform' },
			EU_WEST_15: { name: 'Europe United Kingdom (London)', provider: 'Google Cloud Platform' },
			EU_WEST_16: { name: 'Europe Netherlands (Eemshaven)', provider: 'Google Cloud Platform' },
			EU_WEST_18: { name: 'Europe France (Paris)', provider: 'Google Cloud Platform' },
			EU_SOUTH_2: { name: 'Europe Italy (Milan)', provider: 'Google Cloud Platform' },
			EU_SOUTHWEST_1: { name: 'Europe Spain (Madrid)', provider: 'Google Cloud Platform' },
			NA_NORTHEAST_8: { name: 'North-America Canada (Montreal)', provider: 'Google Cloud Platform' },
			SA_EAST_3: { name: 'South-America Brazil (Sao Paulo)', provider: 'Google Cloud Platform' },
			US_EAST_3: { name: 'USA East - South Carolina (Berkeley County)', provider: 'Google Cloud Platform' },
			US_EAST_9: { name: 'USA East - Virginia (Ashburn)', provider: 'Google Cloud Platform' },
			US_NORTHCENTRAL_4: { name: 'USA Central - Iowa (Council Bluffs)', provider: 'Google Cloud Platform' },
			NA_NORTHEAST_9: { name: 'North-America Canada (Toronto)', provider: 'Google Cloud Platform' },
			US_WEST_6: { name: 'USA West - Oregon (The Dalles)', provider: 'Google Cloud Platform' },
			US_WEST_10: { name: 'USA West - California (Los Angeles)', provider: 'Google Cloud Platform' },
			AP_NORTHEAST_8: { name: 'Asia-Pacific Japan (Osaka)', provider: 'Google Cloud Platform' },
			AP_NORTHEAST_9: { name: 'Asia-Pacific South Korea (Seoul)', provider: 'Google Cloud Platform' },
			AP_SOUTHEAST_10: { name: 'Asia-Pacific Indonesia (Jakarta)', provider: 'Google Cloud Platform' },
			EU_CENTER_1: { name: 'Europe Switzerland (Zurich)', provider: 'Google Cloud Platform' },
			EU_CENTER_5: { name: 'Europe Poland (Warsaw)', provider: 'Google Cloud Platform' },
			US_WEST_4: { name: 'USA West - Nevada (Las Vegas)', provider: 'Google Cloud Platform' },
			US_WEST_11: { name: 'USA West - Utah (Salt Lake City)', provider: 'Google Cloud Platform' },
			SA_WEST_1: { name: 'South-America Chile (Santiago)', provider: 'Google Cloud Platform' },
			/* eslint-enable @typescript-eslint/naming-convention */
		},
		providers: {
			aws: 'Amazon Web Services',
			gcp: 'Google Cloud Platform',
		},
	},
};

export { zone };

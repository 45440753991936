import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { DeletionPolicyExecution } from '@neoload/api';

type DeletionPolicyPreviewProps = {
	policyExecutionInfo: DeletionPolicyExecution;
	isExecuting: boolean;
	isEdit: boolean;
	isFormValid: boolean;
};

const DeletionPolicyPreview = ({
	policyExecutionInfo,
	isExecuting,
	isEdit,
	isFormValid,
}: DeletionPolicyPreviewProps) => {
	const { t } = useTranslation('workspace', { keyPrefix: 'deletionPolicies.create.execution' });

	const infoTextSuffix =
		policyExecutionInfo.totalResultNumber === 0 || !isFormValid
			? t('suffixZero')
			: t('suffix', {
					resultsDeleted: policyExecutionInfo.resultsDeleted,
					totalResultNumber: policyExecutionInfo.totalResultNumber,
				});
	return (
		<>
			<Divider sx={{ marginY: 2 }} />
			<Typography fontSize='small' variant='captionRegular' color='text.secondary' data-testid='execution-number'>
				{t('base')}
				{isExecuting ? <CircularProgress size={13} /> : infoTextSuffix}
			</Typography>
			{policyExecutionInfo && !isEdit && (
				<Typography fontSize='small' variant='captionRegular' color='text.secondary' data-testid='execution-hour'>
					<br />

					{t('create', {
						hour: dayjs().format('LT'),
					})}
				</Typography>
			)}
		</>
	);
};

export { DeletionPolicyPreview };

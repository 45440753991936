import Stack from '@mui/material/Stack';
import { useFormContext } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { useTranslation } from 'react-i18next';
import { ComparisonRowListAccordionItem } from './comparison-row-list-accordion-item';
import { ComparisonTileEditionFormFields } from '../../tile-edition-form';

type ComparisonRowListAccordionProps = {
	resultId: string;
	onEditRow: (index: number) => void;
	onAddRow: () => void;
};

export const ComparisonRowListAccordion = ({ resultId, onEditRow, onAddRow }: ComparisonRowListAccordionProps) => {
	const { watch } = useFormContext<ComparisonTileEditionFormFields>();
	const rows = watch('rows');
	const { t } = useTranslation(['dashboard']);

	return (
		<Stack spacing={1} maxWidth='100%'>
			<Typography variant='subtitle2'>{t('valuesComparison.rows.sectionTitle')}</Typography>
			<Typography paddingBottom={1} variant='body2'>
				{t('valuesComparison.rows.sectionDescription')}
			</Typography>
			{rows.map((row, index) => (
				<ComparisonRowListAccordionItem
					resultId={resultId}
					row={row}
					key={`${row.path.join('/')}${index}`}
					index={index}
					onEditRow={() => onEditRow(index)}
				/>
			))}
			<Button
				onClick={onAddRow}
				variant='outlined'
				startIcon={<AddOutlined />}
				data-testid='add-row-button'
				size='small'
			>
				{t('valuesComparison.rows.add')}
			</Button>
		</Stack>
	);
};

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { FormProvider, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import { MemberCombobox } from './combobox/member-combobox';
import { Spinner } from '../../../common/spinner';
import { useGetV4UsersQuery, usePutV4UsersByUserIdWorkspacesMutation, User, WorkspaceMemberPage } from '@neoload/api';
import { useCrudBatchMessage } from '@neoload/hooks';

type WorkspaceAddMemberModalProps = {
	opened: boolean;
	closeModal: () => void;
	workspaceId: string;
	workspaceMembers: WorkspaceMemberPage;
};

export type AddMembersFormFields = {
	selectedUsers: User[];
};

const WorkspaceAddMemberModal = ({
	opened,
	closeModal,
	workspaceId,
	workspaceMembers,
}: WorkspaceAddMemberModalProps) => {
	const { t } = useTranslation('workspace');
	const { update: updateMembers } = useCrudBatchMessage('workspace', 'members');
	const [putUserWorkspace] = usePutV4UsersByUserIdWorkspacesMutation();
	const [isUpdating, setIsUpdating] = useState(false);

	const { data: users = { items: [], total: 0 }, isLoading, isError: getUsersError } = useGetV4UsersQuery();

	const usersNotInWorkspaceMembers = useMemo(
		() =>
			users.items.filter(
				(member) => !workspaceMembers.items.some((workspaceMember) => workspaceMember.id === member.id),
			),
		[users.items, workspaceMembers.items],
	);

	useEffect(() => {
		if (getUsersError) {
			console.log(getUsersError);
		}
	}, [getUsersError]);

	const { formState, handleSubmit, reset, watch, ...methods } = useForm<AddMembersFormFields>({
		mode: 'onChange',
		defaultValues: {
			selectedUsers: [],
		},
	});

	if (isLoading) {
		return <Spinner />;
	}

	const selectedUsers = watch('selectedUsers');

	const addMembers = async (selectedUsers: User[]) => {
		setIsUpdating(true);
		await updateMembers(selectedUsers, async (user) =>
			putUserWorkspace({
				userId: user.id,
				putUserWorkspace: { workspaceId: workspaceId },
			}).unwrap(),
		).finally(() => setIsUpdating(false));
	};

	const submit = async () => {
		await addMembers(selectedUsers);
		reset();
		closeModal();
	};

	return (
		<Dialog open={opened} onClose={closeModal}>
			<FormProvider {...methods} formState={formState} handleSubmit={handleSubmit} watch={watch} reset={reset}>
				<form
					aria-label={t('members.addMembers')}
					onSubmit={handleSubmit(submit)}
					onReset={() => {
						reset();
						closeModal();
					}}
				>
					<DialogTitle>{t('members.addMembers')}</DialogTitle>
					<DialogContent sx={{ width: '600px' }}>
						<Box sx={{ marginTop: 3, marginBottom: 3 }}>
							<MemberCombobox availableUsers={usersNotInWorkspaceMembers} />
						</Box>
					</DialogContent>
					<DialogActions>
						<Button variant='text' type='reset' data-trackingid='workspace-add-member-cancel'>
							{t('common:cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							startIcon={isUpdating ? <CircularProgress size={24.5} color='inherit' /> : null}
							disabled={isUpdating || selectedUsers.length === 0}
							data-trackingid='workspace-add-member-ok'
						>
							{t('members.addMembers')}
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
};

export { WorkspaceAddMemberModal };

import { useEffect } from 'react';
import { useCurrentWorkspace } from '../workspaces/use-current-workspace';
import { useSetCurrentWorkspaceId } from '../workspaces/use-set-current-workspace-id';
import { useGetV4TestsByTestIdQuery } from '@neoload/api';

/**
 * Fetches the test and switches to its workspace if needed.
 * @param testId the test to query's id
 * @returns
 */
const useGetTest = (testId: string) => {
	const [{ id: currentWorkspaceId }] = useCurrentWorkspace();
	const setCurrentWorkspaceId = useSetCurrentWorkspaceId();
	const response = useGetV4TestsByTestIdQuery({ testId });
	const { data: test } = response;

	useEffect(() => {
		if (test && test.workspaceId !== currentWorkspaceId) {
			setCurrentWorkspaceId(test.workspaceId);
		}
	}, [test, currentWorkspaceId, setCurrentWorkspaceId]);

	return response;
};

export { useGetTest };

import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { LabelledItem } from '../../../../../common/labelled-item';
import { timeUtils } from '@neoload/utils';
import type { ErrorEventWithDetails } from '@neoload/api';

export type EventTabDetailsErrorProps = {
	event: ErrorEventWithDetails;
};

const EventTabDetailsError = ({ event }: EventTabDetailsErrorProps) => {
	const { t } = useTranslation(['result']);
	const { details: errorDetails = {} } = event;
	const { requestDuration, transaction, request, assertionResults = [] } = errorDetails;

	return (
		<>
			{requestDuration !== undefined && (
				<LabelledItem
					id='event-error-duration'
					value={timeUtils.durationMillis(requestDuration)}
					title={t('events.details.requestDuration')}
				/>
			)}
			<LabelledItem id='event-error-transaction' value={transaction} title={t('events.details.transaction')} />
			<LabelledItem id='event-error-request' value={request} title={t('events.details.request')} />
			{assertionResults.length > 0 && (
				<LabelledItem
					id='event-error-assertions'
					value={
						<Typography component='ul'>
							{assertionResults.map(({ localizedMessage }) => (
								<Typography key={localizedMessage} variant='body2' component='li'>
									{localizedMessage}
								</Typography>
							))}
						</Typography>
					}
					title={t('events.details.assertions')}
				/>
			)}
		</>
	);
};
export { EventTabDetailsError };

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Controller, useForm } from 'react-hook-form';
import { ProfileItem } from './profile-item';
import { ProfileTextField } from './profile-text-field';
import { useGetMe, useSetSnackbars } from '@neoload/hooks';
import { CurrentUser, usePatchV4MeMutation, USER_ROLE } from '@neoload/api';

type ProfileFormData = Pick<CurrentUser, 'firstName' | 'lastName'>;

const FORM_DEFAULT_VALUES: ProfileFormData = {
	firstName: '',
	lastName: '',
};

const ProfileForm = () => {
	const { t } = useTranslation('profile');
	const [{ isLoading, firstName, lastName, email, role, account }] = useGetMe();
	const [patchMe, { isLoading: isUpdating, error: updateError, isSuccess: isUpdateSuccessful }] =
		usePatchV4MeMutation();
	const { showError, showInfo } = useSetSnackbars();

	const {
		formState: { errors, isValid, isDirty },
		reset,
		control,
		handleSubmit,
	} = useForm<ProfileFormData>({
		mode: 'onChange',
		defaultValues: FORM_DEFAULT_VALUES,
	});

	useEffect(() => {
		if (firstName && lastName) {
			reset({ firstName: firstName, lastName: lastName });
		}
	}, [firstName, lastName, reset]);

	useEffect(() => {
		if (isUpdateSuccessful) {
			showInfo({ text: t('update.success') });
		}
	}, [isUpdateSuccessful, showInfo, t]);

	useEffect(() => {
		if (updateError) {
			console.error('Erros while updating profile', updateError);
			showError({ text: t('update.failure') });
		}
	}, [showError, updateError, t]);

	return (
		<Stack spacing={2} maxWidth='360px'>
			<Controller
				control={control}
				name='firstName'
				rules={{
					required: { value: true, message: t('firstNameRequired') },
				}}
				render={({ field }) => (
					<ProfileTextField
						{...field}
						label={t('firstName')}
						size='small'
						required
						error={!!errors.firstName}
						placeholder={t('firstName')}
						InputLabelProps={{ shrink: !!firstName }}
						loading={isLoading}
						helperText={errors.firstName?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name='lastName'
				rules={{
					required: { value: true, message: t('lastNameRequired') },
				}}
				render={({ field }) => (
					<ProfileTextField
						{...field}
						label={t('lastName')}
						size='small'
						required
						error={!!errors.lastName}
						placeholder={t('lastName')}
						InputLabelProps={{ shrink: !!lastName }}
						loading={isLoading}
						helperText={errors.lastName?.message}
					/>
				)}
			/>

			<ProfileItem name='email-address' label={t('emailAddress')} value={email} loading={isLoading} />
			<ProfileItem name='account' label={t('account')} value={account?.name} loading={isLoading} />
			<ProfileItem name='role' label={t('role')} value={role ? t(USER_ROLE[role]) : undefined} loading={isLoading} />

			<Stack direction='row' spacing={1}>
				<Button
					type='submit'
					color='primary'
					variant='contained'
					disabled={!isValid || !isDirty || isUpdating}
					onClick={handleSubmit(async (form) => {
						await patchMe({
							patchMeRequest: {
								...form,
							},
						});
					})}
					startIcon={isUpdating ? <CircularProgress size={24.5} color='inherit' /> : null}
					aria-label={t('common:save')}
					size='small'
				>
					{t('common:save')}
				</Button>
				{isDirty && (
					<Button
						type='reset'
						disabled={isUpdating}
						aria-label={t('common:cancel')}
						onClick={() => reset()}
						size='small'
					>
						{t('common:cancel')}
					</Button>
				)}
			</Stack>
		</Stack>
	);
};

export { ProfileForm };

import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import { useTranslation } from 'react-i18next';
import RadioGroup from '@mui/material/RadioGroup';
import { useState } from 'react';
import { DeletionSettingRadioButton } from './deletion-setting-radio-button';
import { SettingType } from './deletion-policies-utils';
import { DeletionPolicySetting } from '@neoload/api';
import { timeUtils } from '@neoload/utils';

type DeletionSettingSectionProps = {
	deletionSetting?: DeletionPolicySetting;
	onChange: (deletionSetting: DeletionPolicySetting | undefined) => void;
};

const DeletionSettingSection = ({ deletionSetting, onChange }: DeletionSettingSectionProps) => {
	const { t } = useTranslation('workspace', { keyPrefix: 'deletionPolicies.create.setting' });
	const [retentionDuration, setRetentionDuration] = useState<number>(
		deletionSetting?.type === 'DURATION' ? timeUtils.asDays(deletionSetting.retentionDuration) : 30,
	);
	const [retentionCount, setRetentionCount] = useState<number>(
		deletionSetting?.type === 'COUNT' ? deletionSetting.retentionCount : 10,
	);
	const [type, setType] = useState<SettingType>(deletionSetting?.type ?? 'DURATION');

	const isInvalid = (type: SettingType, retentionValue?: number) => {
		const value = retentionValue ?? (type === 'DURATION' ? retentionDuration : retentionCount);
		return value < 1 || value > 20_000 || !Number.isInteger(value);
	};

	const getDeletionSetting = (type: SettingType, value: number): DeletionPolicySetting | undefined => {
		if (isInvalid(type, value)) {
			return undefined;
		}

		return type === 'DURATION'
			? { type: 'DURATION', retentionDuration: `P${value}D` }
			: { type: 'COUNT', retentionCount: value };
	};

	const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const type = event.target.value as SettingType;
		setType(type);
		onChange(getDeletionSetting(type, type === 'DURATION' ? retentionDuration : retentionCount));
	};

	const handleRetentionCountChange = (count: number) => {
		setRetentionCount(count);
		onChange(getDeletionSetting('COUNT', count));
	};

	const handleRetentionDeletionChange = (duration: number) => {
		setRetentionDuration(duration);
		onChange(getDeletionSetting('DURATION', duration));
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 1,
				marginTop: 2,
			}}
		>
			<FormLabel>{t('label')}</FormLabel>
			<RadioGroup defaultValue={deletionSetting?.type ?? 'DURATION'} sx={{ marginLeft: 2 }} onChange={handleTypeChange}>
				<DeletionSettingRadioButton
					settingType='DURATION'
					onChange={handleRetentionDeletionChange}
					defaultValue={retentionDuration}
					disabled={type === 'COUNT'}
					invalid={isInvalid('DURATION')}
				/>
				<DeletionSettingRadioButton
					settingType='COUNT'
					onChange={handleRetentionCountChange}
					defaultValue={retentionCount}
					disabled={type === 'DURATION'}
					invalid={isInvalid('COUNT')}
				/>
			</RadioGroup>
		</Box>
	);
};

export { DeletionSettingSection };

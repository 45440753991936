import IconButton from '@mui/material/IconButton';
import AddOutlined from '@mui/icons-material/AddOutlined';
import Typography from '@mui/material/Typography';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useMemo, useState } from 'react';
import { DeepPartial, FieldNamesMarkedBoolean } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import deepmerge from 'deepmerge';
import Button from '@mui/material/Button';
import { SeriesListAccordion } from './series-list-accordion';
import { checkCompatible, checkDuplicate, seriesListNotDeleted } from './series-form-utils';
import { generateSeriesColor } from './generate-series-color';

import { matchAndReplaceWithI18N } from '../../../../dashboard-common';
import { AddSeriesForm, SeriesData } from '../../add-series';
import {
	FormTemplate,
	SeriesTileEditionFormFields,
	SeriesToEditFormFields,
	TileEditionFormBaseProps,
} from '../tile-edition-form';
import { useSetSnackbars } from '@neoload/hooks';
import {
	DashboardFilterRead,
	DashboardSeries,
	DashboardSeriesRead,
	DashboardUserPathElementPercentilesSeriesStatisticRead,
	DashboardUserPathElementTimeSeriesStatisticRead,
	getDashboardStatisticForMonitorElement,
	getDashboardStatisticForUserPathElementStatistic,
	SeriesDashboardTile,
	UserPathElementStatistic,
	UserPathElementTimeSeriesStatistic,
} from '@neoload/api';

export const SeriesForm = ({
	tile,
	onFormValuesChange,
	onSubmit,
	...rest
}: { tile: SeriesDashboardTile } & TileEditionFormBaseProps<SeriesTileEditionFormFields>) => {
	const [seriesList, setSeriesList] = useState<DashboardSeries[]>(tile.series);
	const [title, setTitle] = useState<string>(tile.title);
	const [dirtyLines, setDirtyLines] = useState<string[]>([]);
	const [formValues, setFormValues] = useState<DeepPartial<SeriesTileEditionFormFields>>({
		title: matchAndReplaceWithI18N(title),
		type: 'SERIES',
		series: seriesList.map((series) => ({
			...series,
			deleteOnSubmit: false,
		})),
	});

	const [addSeriesOpen, setAddSeriesOpen] = useState(false);
	const { t } = useTranslation(['dashboard']);
	const { showWarning } = useSetSnackbars();

	const exists = (count: number) => seriesList.some((item) => item.id === count.toString());

	const getNextId = () => {
		let count = 1;
		while (exists(count)) {
			count++;
		}
		return count.toString();
	};

	const onAddSeries = (seriesData: SeriesData<UserPathElementStatistic>) => {
		setAddSeriesOpen(false);

		const nextId = getNextId();
		const color = generateSeriesColor(seriesData, formValues.series);
		const addedSeries: DashboardSeriesRead = toDashboardSeries(seriesData, color, nextId, t);
		const seriesListToCompare = seriesListNotDeleted(seriesList, formValues.series);

		if (checkDuplicate(seriesListToCompare, addedSeries)) {
			showWarning({ text: t('tile.edition.addSeries.error.duplicate') });
			return;
		}
		if (!checkCompatible(seriesListToCompare, addedSeries)) {
			showWarning({ text: t('tile.edition.addSeries.error.notCompatible') });
			return;
		}
		setSeriesList((previousSeriesList) => [...previousSeriesList, addedSeries]);
		setFormValues((previousFormValues) => ({
			...previousFormValues,
			series: [...(previousFormValues.series ?? []), addedSeries],
		}));
		onFormValuesChange({ ...formValues, type: 'SERIES', series: [...seriesList, addedSeries] });
		// Mark lines as dirty
		setDirtyLines((previousDirtyLines) => [...previousDirtyLines, nextId]);
	};

	const seriesSection = useMemo(
		() => (
			<>
				<SeriesListAccordion seriesList={seriesList} />

				<Button
					sx={{
						color: (theme) => theme.palette.primary.main,
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'&.MuiButtonBase-root:hover': {
							bgcolor: 'transparent',
						},
						marginBottom: (theme) => theme.spacing(2),
						margin: (theme) => theme.spacing(2),
					}}
					onClick={() => setAddSeriesOpen(true)}
					variant='outlined'
					size='small'
				>
					<AddOutlined />
					<Typography>{t('tile.edition.addDataSeries')}</Typography>
				</Button>
			</>
		),
		[seriesList, t],
	);

	const saveFormState = (
		values: DeepPartial<SeriesTileEditionFormFields>,
		dirtyFields: DeepPartial<Readonly<FieldNamesMarkedBoolean<SeriesTileEditionFormFields>>>,
	) => {
		const updatedIndexes = dirtyFields.series
			? dirtyFields.series.map((value, index) => {
					if (value) {
						return index;
					}
					return null;
				})
			: [];
		const seriesChanged = values?.series?.filter((_value, index) => updatedIndexes.includes(index));
		// Mark lines as dirty
		setDirtyLines((previousDirtyLines) => {
			const seriesToAdd =
				seriesChanged
					?.filter(
						(series): series is DeepPartial<SeriesToEditFormFields> =>
							!!series && !!series.id && !previousDirtyLines.includes(series.id),
					)
					.map((series) => series.id)
					.filter((id): id is string => !!id) ?? [];
			return [...previousDirtyLines, ...seriesToAdd];
		});
		setSeriesList((previousSeries) =>
			previousSeries.map((series, index) => deepmerge(series, values?.series?.[index] ?? {})),
		);
		setFormValues(values);
		if (values.title) {
			setTitle(values.title);
		}
	};
	return (
		<>
			{!addSeriesOpen && (
				<FormTemplate<SeriesTileEditionFormFields>
					{...rest}
					onFormValuesChange={(values, dirtyValues) => {
						saveFormState(values, dirtyValues);
						onFormValuesChange?.(values);
					}}
					defaultValues={formValues}
					onSubmit={(form) => onSubmit(buildSeriesFields(form, dirtyLines))}
					mainForm={seriesSection}
					canApply={(form) => form.series.some((serie) => !serie.deleteOnSubmit)}
				/>
			)}
			{addSeriesOpen && (
				<>
					<Stack
						direction='row'
						sx={{ paddingX: (theme) => theme.spacing(2), position: 'absolute', top: (theme) => theme.spacing(1) }}
					>
						<IconButton title={t('tile.edit.button')} onClick={() => setAddSeriesOpen(false)}>
							<ArrowBackOutlined />
						</IconButton>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Typography variant='h6'>{t('tile.edition.addSeries.availableDataSeries')}</Typography>
						</Box>
					</Stack>
					<Box sx={{ height: '100%' }}>
						<AddSeriesForm
							type='SERIES'
							onApply={(data: SeriesData<UserPathElementTimeSeriesStatistic>) => onAddSeries(data)}
							onCancel={() => setAddSeriesOpen(false)}
						/>
					</Box>
				</>
			)}
		</>
	);
};

const toDashboardSeries = (
	seriesData: SeriesData<UserPathElementStatistic>,
	color: string,
	nextId: string,
	t: TFunction<[string], undefined>,
): DashboardSeriesRead => {
	if (seriesData.type === 'MONITOR') {
		return {
			id: nextId,
			legend: `${seriesData.monitor.path.join('/')}/${seriesData.monitor.name}`,
			resultName: seriesData.testResult.name,
			visible: true,
			resultId: seriesData.testResult.id,
			filter: {
				type: 'MONITORS_TIMESERIES',
				monitorId: seriesData.monitor.id,
				statistic: getDashboardStatisticForMonitorElement(seriesData.monitor),
			},
			color: color,
		};
	} else if (seriesData.type === 'USERPATH') {
		const userPathElementStatistic = seriesData.stats[0];
		const { type, ...statistic } = getDashboardStatisticForUserPathElementStatistic(userPathElementStatistic);
		const filter: DashboardFilterRead =
			type === 'PERCENTILES'
				? {
						type: 'ELEMENTS_PERCENTILES',
						elementId: seriesData.userPathElement.id,
						userPathId: seriesData.userPathId,
						statistic: statistic as DashboardUserPathElementPercentilesSeriesStatisticRead,
					}
				: {
						type: 'ELEMENTS_TIMESERIES',
						elementId: seriesData.userPathElement.id,
						userPathId: seriesData.userPathId,
						statistic: statistic as DashboardUserPathElementTimeSeriesStatisticRead,
					};
		return {
			id: nextId,
			legend: `${seriesData.userPathElement.name} - ${t('statistics.' + seriesData.stats[0])}`,
			resultName: seriesData.testResult.name,
			visible: true,
			resultId: seriesData.testResult.id,
			testId: seriesData.testResult?.test?.id,
			testName: seriesData.testResult?.test?.name,
			filter,
			color: color,
		};
	} else {
		throw new Error('Series type not recognized');
	}
};

const buildSeriesFields = (form: SeriesTileEditionFormFields, dirtyValueIds: string[]): SeriesTileEditionFormFields => {
	const series = form.series.filter((value) => dirtyValueIds.includes(value.id));
	return {
		type: 'SERIES',
		title: form.title,
		series,
	};
};

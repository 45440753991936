import { GridCellParams, GridInitialState, useGridApiRef } from '@mui/x-data-grid-pro';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import { ComponentPropsWithoutRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useInfrastructureProviderColumns } from './use-infrastructure-provider-columns';
import { InfrastructureProviderDrawerContent } from './infrastructure-provider-drawer-content';
import { InfrastructureProviderFormModal } from './form/infrastructure-provider-form-modal';
import { useInfrastructureProviderDatagridActions } from './use-infrastructure-provider-datagrid-actions';
import { InfrastructureProviderEmptyState } from './infrastructure-provider-empty-state';
import { DatagridWithDrawer } from '../../common/data-grid/datagrid-with-drawer';
import { DEFAULT_GRID_PROPS, onColumnChange } from '../../common/datagrid';
import { CreateResourceButton } from '../../common/button/create-resource-button';
import { filterToSecondaryActions } from '../../common/data-grid/actions/data-grid-actions';
import { Spinner } from '../../common/spinner';
import { useColumnsState, useGetMe } from '@neoload/hooks';
import { InfrastructureProvider } from '@neoload/api';

const columnsStateKey = 'PROVIDERS_COLUMNS_STATE';

type InfrastructureProviderDataGridProps = {
	providers?: InfrastructureProvider[];
	isLoading?: boolean;
};

type FormModalState = {
	editedProvider?: InfrastructureProvider;
	opened: boolean;
};

const InfrastructureProviderDataGrid = ({ providers = [], isLoading }: InfrastructureProviderDataGridProps) => {
	const [selectedProvider, setSelectedProvider] = useState<InfrastructureProvider>();
	const [{ hasAdminPermissions }] = useGetMe();
	const [formModalState, setFormModalState] = useState<FormModalState>({
		opened: false,
	});

	const openCreationModal = () => setFormModalState({ opened: true });
	const editInfraProvider = (provider: InfrastructureProvider) =>
		setFormModalState({ opened: true, editedProvider: provider });
	const closeModal = () => setFormModalState({ opened: false });

	const apiRef = useGridApiRef();
	const actions = useInfrastructureProviderDatagridActions(editInfraProvider, selectedProvider);
	const secondaryActions = selectedProvider ? actions : [];
	const columns = useInfrastructureProviderColumns(actions);
	const { t } = useTranslation(['infrastructureProviders']);

	const componentsProps: { toolbar: ComponentPropsWithoutRef<typeof Toolbar> } = {
		toolbar: {
			displayColumnOptions: false,
			hideFiltersIcon: true,
			title: t('providers.title'),
			secondaryActions: filterToSecondaryActions(secondaryActions),
			mainActions: hasAdminPermissions
				? [
						{
							children: (
								<CreateResourceButton onClick={openCreationModal} key='addProvider'>
									{t('providers.addProvider')}
								</CreateResourceButton>
							),
						},
					]
				: [],
		},
	};

	const initialState: GridInitialState = {
		sorting: {
			sortModel: [{ field: 'name', sort: 'asc' }],
		},
	};

	const { updatedInitialState, updatedColumns, storeColumnState } = useColumnsState(
		columnsStateKey,
		initialState,
		columns,
		apiRef,
	);

	const drawerProps: ComponentPropsWithoutRef<typeof DatagridWithDrawer>['drawer'] = {
		onOpen: (params: GridCellParams) => {
			setSelectedProvider(params.row);
		},
		onClose: () => setSelectedProvider(undefined),
		title: t('providers.details.title'),
		selectedRowId: selectedProvider?.id,
		minWidth: 400,
	};

	if (isLoading) {
		return <Spinner />;
	}

	if (providers.length === 0) {
		return (
			<Box sx={{ flexGrow: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<InfrastructureProviderEmptyState createAction={openCreationModal} />
				<InfrastructureProviderFormModal closeModal={closeModal} opened={formModalState.opened} />
			</Box>
		);
	}

	return (
		<>
			<DatagridWithDrawer
				{...DEFAULT_GRID_PROPS}
				{...onColumnChange(storeColumnState)}
				checkboxSelection={false}
				columns={updatedColumns}
				rows={providers}
				loading={isLoading}
				apiRef={apiRef}
				initialState={updatedInitialState}
				slotProps={componentsProps}
				slots={{
					toolbar: Toolbar,
				}}
				drawer={drawerProps}
			>
				{selectedProvider && <InfrastructureProviderDrawerContent provider={selectedProvider} />}
			</DatagridWithDrawer>
			<InfrastructureProviderFormModal
				closeModal={closeModal}
				opened={formModalState.opened}
				editedProvider={formModalState.editedProvider}
			/>
		</>
	);
};

export { InfrastructureProviderDataGrid };

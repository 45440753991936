import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { NumberTextfieldWithoutSpinners } from '../../../../../common/number-textfield-without-spinners';
import { WebhookFilters } from '@neoload/api';

const JAVA_MAX_INTEGER = 2_147_483_647;

export const MaxVusValueInput = () => {
	const { t } = useTranslation('workspace', { keyPrefix: 'tabs.webhooks.filters' });
	const {
		register,
		watch,
		formState: { errors },
	} = useFormContext<WebhookFilters>();

	const enabled = watch('byMaxVus.enabled');

	return (
		<NumberTextfieldWithoutSpinners
			{...register('byMaxVus.value', {
				setValueAs: (value) => Number.parseInt(value),
				valueAsNumber: true,
				required: {
					value: enabled,
					message: t('valueRequired'),
				},
				validate: (value) => {
					//custom validation because adding max / min rules conditionally is not interpreted correctly when enabling/disabling
					if (enabled) {
						if (!Number.isInteger(value)) {
							return t('valueInteger');
						}
						if (value < 0) {
							return t('valuePositive');
						}
						if (value >= JAVA_MAX_INTEGER) {
							return t('maxInt');
						}
					}
				},
			})}
			size='small'
			InputLabelProps={{ shrink: true }}
			autoFocus
			label={t('value')}
			required
			type='number'
			error={!!errors.byMaxVus?.value}
			helperText={errors.byMaxVus?.value?.message}
		/>
	);
};

import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useFormContext, useWatch } from 'react-hook-form';
import { CreateReservationForm } from './reservation-helper';

const VusReservationModal = () => {
	const { t } = useTranslation(['reservation']);
	const webAvailable = useWatch<CreateReservationForm, 'webVus.available'>({ name: 'webVus.available' });
	const sapAvailable = useWatch<CreateReservationForm, 'sapVus.available'>({ name: 'sapVus.available' });
	const webNeeded = useWatch<CreateReservationForm, 'webVus.needed'>({ name: 'webVus.needed' });
	const sapNeeded = useWatch<CreateReservationForm, 'sapVus.needed'>({ name: 'sapVus.needed' });
	const { setValue } = useFormContext<CreateReservationForm>();

	const parseIntSafely = (value: string, typeVus: string) => {
		const number = Number.parseInt(value);
		let vusAvailable = webAvailable;
		if (typeVus === 'SAP') {
			vusAvailable = sapAvailable;
		}
		if (number < 0 || Number.isNaN(number)) {
			return 0;
		}
		if (number > vusAvailable) {
			return vusAvailable;
		}
		return number;
	};

	return (
		<Stack gap={2}>
			<Typography variant='h3'>{t('common:virtualUser.plural.long')}</Typography>
			<Stack useFlexGap flexDirection='row' gap={2}>
				<TextField
					sx={{ width: '112px' }}
					size='small'
					type='number'
					helperText={t('create.available', { available: webAvailable })}
					onChange={(event) => {
						setValue('webVus.needed', parseIntSafely(event.currentTarget.value, 'WEB'));
					}}
					value={webNeeded}
					label={t('common:virtualUser.web')}
				/>
				<TextField
					sx={{ width: '112px' }}
					size='small'
					type='number'
					helperText={t('create.available', { available: sapAvailable })}
					onChange={(event) => {
						setValue('sapVus.needed', parseIntSafely(event.currentTarget.value, 'SAP'));
					}}
					value={sapNeeded}
					label={t('common:virtualUser.sap')}
				/>
			</Stack>
		</Stack>
	);
};

export { VusReservationModal };

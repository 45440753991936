import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { AccessTokenDataGrid, Spinner } from '@neoload/ui';
import {
	TokenPage,
	useDeleteV4MeTokensByTokenMutation,
	useGetV4InformationQuery,
	useGetV4MeTokensQuery,
	usePostV4MeTokensMutation,
} from '@neoload/api';
import { useConfirmModal, useCrudBatchMessage } from '@neoload/hooks';

const defaultPage: TokenPage = {
	items: [],
	pageNumber: 0,
	pageSize: 0,
	total: 0,
};

const AccessTokensPage = () => {
	const { t } = useTranslation(['user']);
	const { openDeleteConfirmModal } = useConfirmModal();
	const { create, remove } = useCrudBatchMessage('user', 'token.crud');
	const [deleteToken] = useDeleteV4MeTokensByTokenMutation();
	const [createToken] = usePostV4MeTokensMutation();
	const { data = defaultPage, isLoading, isFetching: tokensFetching } = useGetV4MeTokensQuery();
	const { data: informations, isLoading: isInfoLoading } = useGetV4InformationQuery();
	const [, copyToClipboard] = useCopyToClipboard();

	if (isLoading || isInfoLoading) {
		return <Spinner />;
	}

	const openDeleteModal = (tokens: GridRowSelectionModel) => {
		openDeleteConfirmModal({
			title: t('token.deleteDialogHeader', { count: tokens.length }),
			content: t('token.deleteDialogContent', { count: tokens.length }),
			handleConfirm: deleteTokenCallback(tokens),
		});
	};

	const deleteTokenCallback = (tokens: GridRowSelectionModel) => async () => {
		await remove<string, void>(
			tokens.map((t) => t.toString()),
			(token) => deleteToken({ token: token.toString() }).unwrap(),
		);
	};

	const generateAccessToken = async (tokenName: string) => {
		await create([tokenName], (tokenName) =>
			createToken({ createTokenRequest: { name: tokenName } })
				.unwrap()
				.then(({ token, name }) => {
					copyToClipboard(token);
					return { name };
				})
				.catch((error) => {
					console.error('Error creating token', error);
				}),
		);
	};

	return (
		<AccessTokenDataGrid
			accessTokensPage={data}
			publicApiUrl={informations?.publicApiUrl}
			onDeleteAccessTokens={openDeleteModal}
			onGenerateAccessToken={generateAccessToken}
			isFetching={tokensFetching}
		/>
	);
};

export { AccessTokensPage };

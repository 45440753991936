import { DataGridProProps, useGridApiRef } from '@mui/x-data-grid-pro';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import { useTranslation } from 'react-i18next';
import { ComponentPropsWithoutRef } from 'react';
import { SlasDatagridColumns } from './slas-datagrid-columns';
import { Datagrid } from '../../../common/data-grid/datagrid';
import { DEFAULT_GRID_PROPS, onColumnChange } from '../../../common/datagrid';
import { dataGridStyle } from '../values/values-data-grid-common';
import { useColumnsState } from '@neoload/hooks';
import { SlaPerInterval, SlaPerRun } from '@neoload/api';
import { Sla } from '@neoload/utils';

const slasColumnsKey = 'SLAS_COLUMNS_STATE';
export type SlaRow =
	| ({ rowCategory: 'PER_RUN' | 'GLOBAL' } & SlaPerRun)
	| ({ rowCategory: 'PER_TIME_INTERVAL' } & SlaPerInterval)
	| ({ rowCategory: 'TREE'; statuses: Record<Sla, number> } & {
			path: string;
	  });

export type SlasDataGridProps = {
	rows: SlaRow[];
	isFetching: boolean;
};

const initialState: ComponentPropsWithoutRef<typeof Datagrid>['initialState'] = {
	columns: {
		columnVisibilityModel: {
			type: false,
		},
	},
};

const componentsProps: { toolbar: ComponentPropsWithoutRef<typeof Toolbar> } = {
	toolbar: {
		displaySearchBox: true,
		hideFiltersIcon: false,
		displayColumnOptions: true,
		syncLocalStorage: {
			datagridId: 'neoloadSlasDataGrid',
			isSyncEnabled: true,
		},
	},
};

const SlasDatagrid = ({ rows, isFetching }: SlasDataGridProps) => {
	const apiRef = useGridApiRef();
	const { t } = useTranslation(['result']);
	const columns = SlasDatagridColumns();

	const { updatedColumns, updatedInitialState, storeColumnState } = useColumnsState(
		slasColumnsKey,
		initialState,
		columns,
		apiRef,
	);

	const groupingColDef: DataGridProProps['groupingColDef'] = {
		headerName: t('slas.category'),
		minWidth: 225,
		valueGetter: (_, row) =>
			row.rowCategory === 'TREE' && !row.path.includes('/') ? t(`slas.categories.${row.path}`) : '',
	};

	return (
		<Datagrid<SlaRow>
			treeData
			apiRef={apiRef}
			loading={isFetching}
			getTreeDataPath={(row) => row.path.split('/')}
			{...DEFAULT_GRID_PROPS}
			{...onColumnChange(storeColumnState)}
			checkboxSelection={false}
			rows={rows}
			pagination={false}
			getRowId={(row) => row.path}
			groupingColDef={groupingColDef}
			columns={updatedColumns}
			initialState={updatedInitialState}
			slots={{
				toolbar: Toolbar,
			}}
			slotProps={componentsProps}
			sx={{ ...dataGridStyle }}
		/>
	);
};

export { SlasDatagrid };

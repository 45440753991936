import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { UnitTypeShort } from 'dayjs';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

type DurationPickerProps = {
	initialValue: number;
	initialUnit: UnitTypeShort;
	onChange: (inputNumber: number, unit: UnitTypeShort) => void;
	availableUnits: Exclude<UnitTypeShort, 'D'>[];
	getHelperText?: (unit: UnitTypeShort) => string | undefined;
	error?: boolean;
	getMaxInputValue?: (unit: UnitTypeShort) => number;
	getMinInputValue?: (unit: UnitTypeShort) => number;
};

export const DurationPicker = ({
	initialUnit,
	initialValue,
	onChange,
	availableUnits,
	getHelperText,
	error,
	getMaxInputValue,
	getMinInputValue,
}: DurationPickerProps) => {
	const { t } = useTranslation('common');

	const [inputNumber, setInputNumber] = useState<number>(initialValue);
	const [inputUnit, setInputUnit] = useState<UnitTypeShort>(initialUnit);

	useEffect(() => {
		onChange(inputNumber, inputUnit);
	}, [inputNumber, inputUnit, onChange]);

	return (
		<Stack direction='column'>
			<FormControl>
				<Stack direction='row' gap={2}>
					<TextField
						label={t('duration.duration')}
						required
						sx={{ width: '50%' }}
						type='number'
						value={inputNumber}
						size='small'
						onChange={(event) => setInputNumber(Number.parseInt(event.currentTarget.value))}
						error={error}
						InputProps={{
							inputProps: {
								...(getMaxInputValue && { max: getMaxInputValue(inputUnit) }),
								...(getMinInputValue && { min: getMinInputValue(inputUnit) }),
							},
						}}
					/>
					<FormControl sx={{ width: '50%' }} variant='outlined'>
						<InputLabel required id='duration.unit'>
							{t('duration.unit')}
						</InputLabel>
						<Select<UnitTypeShort>
							label={t('duration.unit')}
							required
							size='small'
							value={inputUnit}
							onChange={(event) => setInputUnit(event.target.value as UnitTypeShort)}
						>
							{availableUnits.map((unit) => (
								<MenuItem key={unit} value={unit}>
									{t(`duration.timeUnits.${unit}`)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Stack>
				{getHelperText && <FormHelperText error={error}>{getHelperText(inputUnit)}</FormHelperText>}
			</FormControl>
		</Stack>
	);
};

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { TestHeader } from './test-header';
import { ConfigurationFormData } from './configuration/types.d';
import { CurrentConfigurationHeader } from './current-configuration-header';
import { TestTrendsGraphSection } from './test-trends-graph-section';
import { Spinner } from '../../common/spinner';
import { ResultDataGrid } from '../results/result-data-grid';
import { PatchTestInput, Test, useGetV4TestsByTestIdQuery, usePatchV4TestsByTestIdMutation } from '@neoload/api';
import { createNeoLoadError, TestGenericParams } from '@neoload/utils';

type TestViewFormProps = {
	patchTest: (patchTestInput: PatchTestInput) => Promise<Test | void>;
	defaultValues: ConfigurationFormData;
	test: Test;
};

type TestViewProps = {
	testInfos: ConfigurationFormData;
};

const TestViewForm = ({ patchTest, defaultValues, test }: TestViewFormProps) => {
	const { t } = useTranslation(['test']);
	const { testId } = useParams<TestGenericParams>();
	const { handleSubmit, watch, ...methods } = useForm<ConfigurationFormData>({
		mode: 'onChange',
		defaultValues,
	});
	const [isScrolling, setIsScrolling] = useState(false);
	const onScroll = (event: React.UIEvent) => setIsScrolling(event.currentTarget.scrollTop !== 0);

	return (
		<FormProvider {...methods} handleSubmit={handleSubmit} watch={watch}>
			<form
				aria-label={t('configuration.aria.configuration')}
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
				}}
				// onSubmit should not occur here
				onSubmit={(event) => event.preventDefault()}
			>
				<TestHeader patchTest={patchTest} isConfigurePage={false} description={test.description} />
				<CurrentConfigurationHeader />
				<Divider sx={{ margin: (theme) => theme.spacing(2, 0, 0, 0) }} />
				<Box
					sx={{
						flexGrow: 1,
						overflowY: 'auto',
						backgroundColor: (theme) => theme.palette.background.paper,
					}}
					onScroll={onScroll}
					boxShadow={isScrolling ? 'inset 0 4px 8px -8px' : ''}
				>
					{testId && (
						<TestTrendsGraphSection
							testId={testId}
							testName={defaultValues.test.name}
							testWorkspaceId={test.workspaceId}
						/>
					)}
					<Divider sx={{ margin: (theme) => theme.spacing(1, 0, 0, 0) }} />
					<ResultDataGrid testId={testId} isAutoHeight={true} />
				</Box>
			</form>
		</FormProvider>
	);
};

const TestView = ({ testInfos }: TestViewProps) => {
	const { testId = '' } = useParams<TestGenericParams>();
	const [patchTest] = usePatchV4TestsByTestIdMutation();
	const { data: test, error: testError, isLoading: isLoadingTest } = useGetV4TestsByTestIdQuery({ testId });

	if (isLoadingTest) {
		return <Spinner />;
	}
	if (testError) {
		throw createNeoLoadError(testError);
	}
	if (!test) {
		throw createNeoLoadError();
	}

	const patch = async (patchTestInput: PatchTestInput): Promise<Test | void> =>
		patchTest({ testId: testId, patchTestInput: patchTestInput }).unwrap();

	return <TestViewForm patchTest={patch} defaultValues={testInfos} test={test} />;
};

export { TestView };

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { HomeGrid, HomeGridProps, LatestResultsTile, RecommendationsTile, TestsKpiTile } from '@neoload/ui';
import { NO_WORKSPACE_ASSIGNED_REDIRECTION_KEY, useCurrentWorkspace, useSetSnackbars } from '@neoload/hooks';
import { ResultRoutes, TestRoutes } from '@neoload/utils';

type HomeTile = HomeGridProps['tiles'][number];

const pollingIntervalDuration = 10_000;
const HomePage = () => {
	const { t } = useTranslation(['home']);
	const { showWarning } = useSetSnackbars();
	const location = useLocation();
	const [, isCurrentWorkspaceSelected] = useCurrentWorkspace();

	const tiles: HomeTile[] = [];

	if (isCurrentWorkspaceSelected) {
		tiles.push(
			{
				title: t('grid.tile.header.recommendations'),
				x: 3,
				y: 0,
				w: 1,
				h: 3,
				header: true,
				children: <RecommendationsTile />,
			},
			{
				title: t('grid.tile.latestResults.title'),
				x: 0,
				y: 2,
				w: 2,
				h: 2,
				header: true,
				link: ResultRoutes.base,
				children: <LatestResultsTile pollingIntervalDurationInMs={pollingIntervalDuration} />,
			},
			{
				title: t('grid.tile.kpi.title'),
				x: 0,
				y: 1,
				w: 2,
				h: 1,
				header: true,
				link: TestRoutes.base,
				children: <TestsKpiTile pollingIntervalDurationInMs={pollingIntervalDuration} />,
			},
		);
	}

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		if (urlParams.has(NO_WORKSPACE_ASSIGNED_REDIRECTION_KEY)) {
			showWarning({
				id: NO_WORKSPACE_ASSIGNED_REDIRECTION_KEY,
				text: t('errors.noWorkspaceAssigned'),
				autoHideDuration: null,
			});
		}
	}, [t, showWarning, location.search]);

	return (
		<>
			<HomeGrid tiles={tiles} />
			<Footer />
		</>
	);
};

export { HomePage };

const Footer = () => {
	const { t } = useTranslation(['home', 'externalLinks']);
	return (
		<Stack alignItems='center' gap={2} marginBottom={1}>
			<Stack direction='row' gap={2}>
				{[
					{ i18nKey: 'footer.links.documentation', to: t('externalLinks:documentation') },
					{
						i18nKey: 'footer.links.generalTermsOfUse',
						to: t('externalLinks:termsOfUse'),
					},
					{
						i18nKey: 'footer.links.privacyPolicy',
						to: t('externalLinks:privacyPolicy'),
					},
					{
						i18nKey: 'footer.links.support',
						to: t('externalLinks:support'),
					},
				].map(({ i18nKey, to }) => (
					<FooterLink key={i18nKey} i18nKey={i18nKey} to={to} />
				))}
			</Stack>
			<Typography variant='caption' fontWeight={400}>
				{t('footer.credits')}
			</Typography>
		</Stack>
	);
};

type FooterLinkProps = {
	i18nKey: string;
	to: string;
};
const FooterLink = ({ i18nKey, to }: FooterLinkProps) => {
	const { t } = useTranslation(['home']);
	return (
		<Link variant='caption' href={to} underline='none' target='_blank' fontWeight={400} textTransform='uppercase'>
			{t(i18nKey)}
		</Link>
	);
};

import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDocumentTitleHandler } from '@neoload/hooks';
import { applicationsTitleList } from '@neoload/utils';

const DocumentTitleHandler = () => {
	const location = useLocation();
	const { t } = useTranslation(['menu']);
	const foundRoute = applicationsTitleList(t).find(
		(route) => location.pathname.toLowerCase() === route.path.toLowerCase()
	);

	useDocumentTitleHandler(foundRoute?.title);

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <></>;
};

export { DocumentTitleHandler };

import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { CreateReservationForm } from './reservation-helper';
import { WorkspaceReservationModal } from './workspace-reservation-modal';
import { InputDatePicker } from '../../../../../common/input/input-date-picker';
import { timeUtils } from '@neoload/utils';
import { useLazyGetV4WorkspacesByWorkspaceIdSubscriptionQuery, WorkspaceSubscription } from '@neoload/api';

const ReservationDetailsModal = () => {
	const { t } = useTranslation(['reservation']);
	const { setValue } = useFormContext<CreateReservationForm>();
	const startDate = useWatch<CreateReservationForm, 'startDate'>({ name: 'startDate' });
	const endDate = useWatch<CreateReservationForm, 'endDate'>({ name: 'endDate' });
	const workspaceId = useWatch<CreateReservationForm, 'workspaceId'>({ name: 'workspaceId' });
	const [lazyGetWorkspaceSubscription] = useLazyGetV4WorkspacesByWorkspaceIdSubscriptionQuery();

	const [duration, setDuration] = useState<number>(0);

	useEffect(() => {
		const getWorkspaceSubscription = async (workspaceId: string): Promise<WorkspaceSubscription | undefined> => {
			const { data: workspaceSubscription } = await lazyGetWorkspaceSubscription({
				workspaceId,
			});
			return workspaceSubscription;
		};
		if (workspaceId) {
			void getWorkspaceSubscription(workspaceId).then((workspaceSubscription) => {
				setValue('sapVus.needed', 0);
				setValue('sapVus.available', workspaceSubscription?.sapVu?.available ?? 0);
				setValue('webVus.needed', 0);
				setValue('webVus.available', workspaceSubscription?.webVu?.available ?? 0);
			});
		}
	}, [lazyGetWorkspaceSubscription, setValue, workspaceId]);

	useEffect(() => {
		const minute = dayjs(endDate).diff(dayjs(startDate), 'minute');
		if (dayjs().diff(dayjs(startDate), 'minute') > 0) {
			setValue('startDate', dayjs().add(15, 'minute').second(0).toISOString());
		}
		if (minute < 30) {
			const endDateFormated = dayjs(startDate).add(30, 'minute').second(0).toISOString();
			setValue('endDate', endDateFormated);
			setDuration(dayjs.duration(30, 'minute').asMilliseconds());
		} else {
			setDuration(dayjs.duration(minute, 'minute').asMilliseconds());
		}
	}, [startDate, endDate, setValue]);

	const onChangeStarDate = (date: dayjs.Dayjs | null) => {
		if (date?.isValid()) {
			setValue('startDate', date.toISOString());
		}
	};

	const onChangeEndDate = (date: dayjs.Dayjs | null) => {
		if (date?.isValid()) {
			setValue('endDate', date.toISOString());
		}
	};

	return (
		<Stack useFlexGap gap={1}>
			<Typography variant='h3'>{t('common:reservationDetails')}</Typography>
			<Stack useFlexGap gap={2}>
				<WorkspaceReservationModal />
				<Stack useFlexGap gap={1}>
					<Stack useFlexGap flexDirection='row' gap={1} alignItems='center'>
						<InputDatePicker
							value={startDate}
							onChange={onChangeStarDate}
							labelDate={t('create.startDate')}
							labelTime={t('create.startTime')}
							minDate={dayjs().toISOString()}
						/>
						<Typography>-</Typography>
						<InputDatePicker
							value={endDate}
							onChange={onChangeEndDate}
							labelDate={t('create.endDate')}
							labelTime={t('create.endTime')}
						/>
					</Stack>
					<Stack useFlexGap flexDirection='row' gap={1}>
						<Typography color='secondary' variant='bodyRegular'>
							{t('create.totalReservation')}
						</Typography>
						<Typography variant='bodyRegular'>{timeUtils.durationFull(duration)}</Typography>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export { ReservationDetailsModal };

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { DateRange, DateRangePicker, LocalizationProvider, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from '@mui/material/Button';
import dayjs, { Dayjs } from 'dayjs';
import { useGetV4InformationQuery } from '@neoload/api';
import { timeUtils } from '@neoload/utils';

timeUtils.setWeekStartOnMonday();

const DataOptions = ['LICENSING', 'RUNTIME', 'ALL'] as const;
type DataOption = (typeof DataOptions)[number];

const DownloadUsageHistory = () => {
	const [dataOption, setDataOption] = useState<DataOption>('LICENSING');
	const [fromDateTime, setFromDateTime] = useState(dayjs().subtract(1, 'month'));
	const [toDateTime, setToDateTime] = useState(dayjs());
	const { t, i18n } = useTranslation(['usageHistory']);
	const { data: { publicApiUrl } = { publicApiUrl: '' } } = useGetV4InformationQuery();
	const isRangeValid = toDateTime.isValid() && fromDateTime.isValid() && fromDateTime.isBefore(toDateTime);
	const downloadLink = () => {
		if (!isRangeValid) {
			return '';
		}
		let group: string;
		switch (dataOption) {
			case 'LICENSING': {
				group = 'Licensing';
				break;
			}
			case 'RUNTIME': {
				group = 'Runtime';
				break;
			}
			case 'ALL': {
				group = '';
				break;
			}
		}
		return `${publicApiUrl}/v3/usage?fromDateTime=${fromDateTime.startOf('day').toISOString()}&toDateTime=${toDateTime.endOf('day').toISOString()}&groups=${group}&format=CSV`;
	};
	const dateShortcuts: PickersShortcutsItem<DateRange<Dayjs>>[] = [
		{
			label: t('datePicker.lastMonth'),
			getValue: () => {
				const lastMonth = dayjs().subtract(1, 'month');
				return [lastMonth.startOf('month'), lastMonth.endOf('month')];
			},
		},
		{
			label: t('datePicker.lastWeek'),
			getValue: () => {
				const previousWeek = dayjs().subtract(7, 'day');
				return [previousWeek.startOf('week'), previousWeek.endOf('week')];
			},
		},
		{
			label: t('datePicker.last7Days'),
			getValue: () => {
				const today = dayjs();
				return [today.subtract(7, 'day'), today];
			},
		},

		{ label: t('datePicker.reset'), getValue: () => [dayjs().subtract(1, 'month'), dayjs()] },
	];

	return (
		<Box display='flex' alignItems='center' gap={2} padding={3}>
			<FormControl>
				<InputLabel>{t('data')}</InputLabel>
				<Select<DataOption>
					sx={{ width: 160 }}
					value={dataOption}
					onChange={(event) => setDataOption(event.target.value as DataOption)}
					label={t('data')}
					variant='outlined'
					size='small'
				>
					{DataOptions.map((option) => (
						<MenuItem key={option} value={option}>
							<ListItemText primary={t(`dataOptions.${option.toLowerCase()}`)} />
						</MenuItem>
					))}
				</Select>
			</FormControl>

			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
				<DateRangePicker
					sx={{ maxWidth: 320 }}
					slotProps={{
						shortcuts: {
							items: dateShortcuts,
							// prevents closing picker when selecting a shortcut
							changeImportance: 'set',
						},
						actionBar: { actions: [] },
					}}
					value={[fromDateTime, toDateTime]}
					onChange={(value) => {
						setFromDateTime(dayjs(value[0]));
						setToDateTime(dayjs(value[1]));
					}}
					localeText={{ start: t('datePicker.startDate'), end: t('datePicker.endDate') }}
					format={t('common:dateFormat')}
					calendars={2}
					closeOnSelect={false}
					disableFuture
				/>
			</LocalizationProvider>
			<Button disabled={!isRangeValid} href={downloadLink()} download variant='contained'>
				{t('downloadCsv')}
			</Button>
		</Box>
	);
};

export { DownloadUsageHistory };

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

type ZoneTypesProps = {
	disabled?: boolean;
};

const ZoneTypes = ({ disabled }: ZoneTypesProps) => {
	const { t } = useTranslation(['test']);

	return (
		<Controller
			name='zones.type'
			rules={{ required: true }}
			render={({ field }) => (
				<RadioGroup
					{...field}
					row
					sx={{
						marginBottom: 2,
						marginLeft: 2,
					}}
				>
					<FormControlLabel
						value='CLOUD'
						control={<Radio />}
						label={t('configuration.zones.types.ncp')}
						disabled={disabled}
						data-trackingid='zone-type-ncp'
					/>
					<FormControlLabel
						value='STATIC'
						control={<Radio />}
						label={t('configuration.zones.types.static')}
						disabled={disabled}
						data-trackingid='zone-type-static'
					/>
					<FormControlLabel
						value='DYNAMIC'
						control={<Radio />}
						label={t('configuration.zones.types.dynamic')}
						disabled={disabled}
						data-trackingid='zone-type-dynamic'
					/>
				</RadioGroup>
			)}
		/>
	);
};

export { ZoneTypes };
